<template lang="pug">
.modal-card
  header.modal-card-head
    p.modal-card-title {{ $t('ledger.cash_out_title') }}
    
  section.modal-card-body
    select-box(:data="types" v-model="type")
    b-field(v-if="type === 'expense'" :label="$t('ledger.staff_member_label')" :type="errors.has('beneficiary') ? 'is-danger': ''" :message="errors.has('beneficiary') ? errors.first('beneficiary') : ''")
      b-select(v-model="beneficiary" name="beneficiary" v-validate="'required'" data-vv-as="buyer" :placeholder="$t('ledger.staff_placeholder')")
        option(v-for="staff in staffMembers" :value="staff._id" :key="staff._id")
          staff-view(:id="staff._id")
    b-field(:label="$t('ledger.amount_label')"  :type="errors.has('amount') ? 'is-danger': ''" :message="errors.has('amount') ? errors.first('amount') : ''")
      b-field
        p.control
          span.button.is-static 
            currency
        b-input(v-model.number="amount" ref="amount" v-validate="'required|numeric'" name="amount" expanded :placeholder="$t('ledger.amount_placeholder')")
    b-field(v-if="type === 'expense'" :label="$t('ledger.reason_label')" :type="errors.has('reason') ? 'is-danger': ''" :message="errors.has('reason') ? errors.first('reason') : ''")
      b-input(v-model="notes" name="reason" v-validate="'required'" data-vv-as="reason" :placeholder="$t('ledger.reason_placeholder')")
    b-field(v-else :label="$t('ledger.notes_label')" :type="errors.has('notes') ? 'is-danger': ''" :message="errors.has('notes') ? errors.first('notes') : ''")
      b-input(v-model="notes" name="notes" data-vv-as="notes" :placeholder="$t('ledger.notes_placeholder')")
    note(type="is-warning") 
      p(v-t="'ledger.transaction_note'")
  footer.modal-card-foot
    .buttons
      action-button(@click="submit" title="actions.submit" :loading="loading")
      button.button.is-outlined(type="button" @click="$emit('close')" ) {{$t('actions.close')}}      
</template>

<script>
import {mapActions, mapGetters, mapState} from 'vuex'
import TRANSACTION_TYPES from '../../../constants/ledgerTransactions'

export default {
  data() {
    return {
      amount: undefined,
      loading: false,
      type: TRANSACTION_TYPES.EXPENSE,
      notes: undefined,
      beneficiary: undefined
    }
  },
  computed: {
    ...mapGetters('staff', ['activeMembersByLocation']),
    ...mapState('auth', ['user']),
    staffMembers() {
      return this.activeMembersByLocation(this.user.location)
    },
    types() {
      return [
        {
          title: this.$t('register_transactions.expense'),
          description: this.$t('register_transactions.expense_description'),
          value: TRANSACTION_TYPES.EXPENSE
        },
        {
          title: this.$t('register_transactions.out'),
          description: this.$t('register_transactions.out_description'),
          value: TRANSACTION_TYPES.OUT
        }
      ]
    }
  },
  created() {
    this.amount = this.due
  },
  mounted() {
    this.$refs.amount.focus()
  },
  methods: {
    ...mapActions('ledger', ['registerOut']),
    async submit() {
      let valid = await this.$validator.validateAll()
      if (!valid) {
        return
      }
      if (this.loading) {
        return
      }
      this.loading = true
      try {
        if (this.type === TRANSACTION_TYPES.EXPENSE) {
          await this.registerOut({
            amount: this.amount,
            beneficiary: this.beneficiary,
            notes: this.notes,
            type: this.type
          })
        } else if (this.type === TRANSACTION_TYPES.OUT) {
          await this.registerOut({amount: this.amount, type: this.type})
        }
        this.$emit('close')
      } catch (err) {
        this.$handleAPIError(err)
      }
      this.loading = false
    }
  }
}
</script>
