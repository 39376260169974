import Vue from 'vue'

export default {
  update(id, data) {
    return Vue.http.put(`customerCare/tickets/${id}`, data)
  },
  fetch(params) {
    return Vue.http.get('customerCare/tickets', {params})
  },
  getById(id) {
    return Vue.http.get(`customerCare/tickets/${id}`)
  }
}
