<template lang="pug">
.modal-card
  header.modal-card-head
    p.modal-card-title(data-qa="modal-header") 
      b(v-if="index === undefined" v-t="'rewards_program.settings.add_new_tier_title'")
      b(v-else v-t="'rewards_program.settings.edit_tier_title'")
    div
      a.button.is-white.is-small(@click="$emit('close')")
        span.icon
          i.mdi.mdi-close.mdi-24px
  section.modal-card-body
    .columns
      .column
        b-field(:label="$t('rewards_program.settings.card_image_label')", :type="errors.has('card') ? 'is-danger': ''" :message="errors.has('card') ? errors.first('card') : ''")
          .is-artwork
            image-preview(v-model="card" v-validate="'required'" name="card" data-vv-as="card image" category="marketing")
      .column
        b-field(:label="$t('rewards_program.settings.title_label')" :type="errors.has('title') ? 'is-danger': ''" :message="errors.has('title') ? errors.first('title') : ''")
          b-input(:placeholder="$t('rewards_program.settings.tier_title_placeholder')" data-vv-as="title" name="title" v-validate="'required'" icon="wallet-membership" v-model="title")
        
        b-field(:label="$t('rewards_program.settings.threshold_label')" :type="errors.has('threshold') ? 'is-danger': ''" :message="errors.has('threshold') ? errors.first('threshold') : ''")
          b-input(:placeholder="$t('rewards_program.settings.threshold_placeholder')" data-vv-as="threshold" name="threshold" v-validate="'required'" icon="arrow-expand-horizontal" v-model.number="threshold" numeric type="number")
        
        b-field(v-if="displayType=== 'percent'" :label="$t('rewards_program.settings.rate_label')" :type="errors.has('rate') ? 'is-danger': ''" :message="errors.has('rate') ? errors.first('rate') : ''")
          b-input(:placeholder="$t('rewards_program.settings.rate_placeholder')" data-vv-as="rate" name="rate" v-validate="'required'" icon="percent" v-model.number="rate" numeric type="number")
        
        b-field(v-if="displayType === 'spend'" :label="$t('rewards_program.settings.points_per_currency_label')" :type="errors.has('rate') ? 'is-danger': ''" :message="errors.has('rate') ? errors.first('rate') : ''")
          b-input(:placeholder="$t('rewards_program.settings.points_per_currency_placeholder')" data-vv-as="rate" name="rate" v-validate="'required'" icon="cash-multiple" v-model.number="rate" numeric type="number")
  footer.modal-card-foot
    .buttons
      action-button.is-primary(type="submit" @click="submit" :title="index === undefined ? $t('actions.add') : $t('actions.update')") 
      button.button.is-outlined(type="button" @click="cancel") {{$t('actions.cancel')}}
</template>

<script>
export default {
  name: 'MembershipCreateEditForm',

  props: {
    data: {type: Object, default: undefined},
    displayType: {type: String, required: true}
  },
  data() {
    return {
      index: undefined,
      card: undefined,
      rate: undefined,
      threshold: undefined,
      title: undefined
    }
  },
  created() {
    if (this.data) {
      this.index = this.data.index
      this.card = this.data.card
      if (this.displayType === 'spend') {
        this.rate = parseInt(100 * this.data.rate) / 100
      } else {
        this.rate = parseInt(this.data.rate * 100)
      }

      this.threshold = this.data.threshold
      this.title = this.data.title
    }
  },
  methods: {
    async submit() {
      const valid = await this.$validator.validateAll()
      if (!valid) {
        return
      }
      this.$emit('success', {
        level: {
          card: this.card,
          title: this.title,
          threshold: this.threshold,
          rate: this.displayType === 'spend' ? this.rate : this.rate / 100
        },
        index: this.index
      })
      this.$emit('close')
    },
    cancel() {
      this.$emit('close')
    }
  }
}
</script>
