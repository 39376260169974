<template lang="pug">
figure.image.customer-pic
  img.is-rounded(:src="customer.profilePic || require('@/assets/user.jpg')" @error="onInvalidImage")
  //div.loyalty-icon
    span.icon.has-text-danger(style="line-height: 28px")
      i.mdi.mdi-star.mdi-24px
</template>

<script>
export default {
  name: 'ProfilePic',
  props: {
    customer: {type: Object, required: true}
  },
  methods: {
    onInvalidImage(event) {
      event.target.src = require('@/assets/user.jpg')
    }
  }
}
</script>
<style scoped>
/* .customer-pic {
  border: 2px solid grey;
  border-radius: 9999px;
  position: relative;
  overflow: visible;
} */
.loyalty-icon {
  position: absolute;
  bottom: 0;
  right: 0;
  border-radius: 9999px;
  width: 28px;
  height: 28px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  background-color: yellow;
}
</style>
