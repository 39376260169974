<template lang="pug">
div
  screen-title(title="client_notifications.title")
  .box
    h2.title.is-5 {{$t('client_notifications.reminders_title')}}
    p.subtitle.is-6 {{$t('client_notifications.appt_reminders_explained')}}
    
    
    
    select-box(v-model="reminder" :data="getOptionTypes('reminders')" multichoice)
    .columns
      .column.is-4
        p.heading 
          b(v-t="'sms.templates_title'")
        SMSTemplatePreview(templateKey="sms.reminder")
    b-field(grouped)
      b-field(:label="$t('client_notifications.appt_reminder_first_label')")
        b-select(v-model="reminders.first")
          option(v-for="time in times" :value="time" :key="time") 
            duration-view(:value="time" granulize)
        
      b-field(:label="$t('client_notifications.appt_reminder_second_label')")
        b-select(v-model="reminders.second" :disabled="!reminders.first || !$rfSubscribed('sms_notifications')")
          option(:value="null") {{ $t('client_notifications.appt_no_reminder') }}
          option(v-for="time in times" :value="time" :key="time") 
            duration-view(:value="time" granulize)
        //b-checkbox(v-model="reminders.first" :native-value="1440") 
      //b-field(label="first reminder")
        b-checkbox(v-model="reminders.second" :native-value="120" :disabled="!$rfSubscribed('sms_notifications')") {{$t('client_notifications.reminder2h')}}
    b-field(:label="$t('client_notifications.appointment_type')" :type="errors.has('statusCases') ? 'is-danger': ''" :message="errors.has('statusCases') ? errors.first('statusCases') : ''")
      .field
        b-checkbox(v-model="remindForStatus" name="statusCases" v-validate="'required'" :native-value="APPOINTMENT_STATUS.NEW") {{$t('client_notifications.new')}}
        b-checkbox(v-model="remindForStatus" name="statusCases" v-validate="'required'" data-vv-as="status case" :native-value="APPOINTMENT_STATUS.CONFIRMED") {{$t('client_notifications.confirmed')}}
    hr
    note.is-outlined(type="is-info" size="small" v-if="reminders.first")
      p(v-html="$t('client_notifications.first_reminder_explained', {time: firstReminderWindow})") 
    note.is-outlined(type="is-info" size="small" v-if="reminders.second")
      p(v-html="$t('client_notifications.second_reminder_explained', {time: secondReminderWindow})") 
    
    
  .box
  
    h2.title.is-5 {{$t('client_notifications.appt_confirmation_title')}}
    p.subtitle.is-6 {{$t('client_notifications.confirmation_explained')}}
    select-box(v-model="confirmation" :data="getOptionTypes('confirmation')" multichoice)
    .columns
      .column.is-4
        p.heading 
          b(v-t="'sms.templates_title'")
        SMSTemplatePreview(templateKey="sms.appointment_confirmed")
  .box
    h2.title.is-5 {{$t('client_notifications.appt_update_title')}}
    p.subtitle.is-6 {{$t('client_notifications.appt_update_explained')}}
    select-box(v-model="reschedule" :data="getOptionTypes('reschedule')" multichoice)
    .columns
      .column.is-4
        p.heading 
          b(v-t="'sms.templates_title'")
        SMSTemplatePreview(templateKey="sms.appointment_rescheduled")
    //b-field(:label="$t('client_notifications.appt_update_window_label')")
      b-select(v-model="rescheduleWindow")
        option(v-for="time in rescheduleWindowOptions" :key="time" :value="time") 
          duration-view(:value="time")
  .box
    h2.title.is-5 {{$t('client_notifications.appt_cancelation_title')}}
    p.subtitle.is-6 {{$t('client_notifications.appt_cancelation_explained')}}
    select-box(v-model="canceling" :data="getOptionTypes('canceling')" multichoice)
    .columns
      .column.is-4
        p.heading 
          b(v-t="'sms.templates_title'")
        SMSTemplatePreview(templateKey="sms.appointment_canceled")
  .box
    h2.title.is-5 {{$t('client_notifications.sms_economy_title')}}
    p.subtitle.is-6 {{$t('client_notifications.sms_economy_explained')}}
    b-switch(v-model="economyMode") {{economyMode ? $t('misc.enabled'): $t('misc.disabled')}}
  .buttons
    action-button(title="actions.save" @click="submit" :loading="submitting" permission="REMINDERS_MANAGE" scope)
</template>

<script>
import APPOINTMENT_STATUS from '../../../constants/appointmentStatus'

import {mapState, mapActions} from 'vuex'
import durationMixin from '@/mixins/duration'
import cloneDeep from 'lodash/cloneDeep'
import {PRODUCTS} from '../../../constants/products'
import SMSTemplatePreview from '../shared/SMSTemplatePreview.vue'
export default {
  name: 'Notifications',
  components: {
    SMSTemplatePreview
  },
  mixins: [durationMixin],
  data() {
    return {
      submitting: false,
      economyMode: false,
      reminder: [],
      reminders: {first: undefined, second: undefined},
      confirmation: [],
      reschedule: [],
      rescheduleWindow: 30,
      canceling: [],
      remindForStatus: [APPOINTMENT_STATUS.NEW],
      APPOINTMENT_STATUS,
      templates: undefined,
      rescheduleWindowOptions: [0, 5, 10, 15, 20, 25, 30, 45, 60, 120],
      times: [120, 180, 240, 300, 360, 420, 480, 540, 600, 720, 1440, 2880, 4320, 5760, 7200]
    }
  },
  computed: {
    ...mapState('company', ['company']),
    firstReminderWindow() {
      return this.formatDuration(this.reminders.first * 1.5, true)
    },
    secondReminderWindow() {
      return this.formatDuration(this.reminders.second * 1.5, true)
    }
  },
  async created() {
    let notifications = cloneDeep(this.company.notifications)
    if (notifications) {
      this.reminder = notifications.reminder
      this.economyMode = notifications.economyMode
      this.reminders = {first: notifications.reminders[0], second: notifications.reminders[1]}
      this.remindForStatus = notifications.remindForStatus || [APPOINTMENT_STATUS.CONFIRMED]
      this.reschedule = notifications.reschedule
      this.rescheduleWindow = notifications.rescheduleWindow
      this.confirmation = notifications.confirmation
      this.canceling = notifications.canceling
    }
  },
  methods: {
    ...mapActions('company', ['update']),
    async submit() {
      const valid = await this.$validator.validateAll()
      if (!valid) {
        return
      }
      const notifications = {
        reminder: this.reminder,
        economyMode: this.economyMode,
        reminders: Object.values(this.reminders).sort((a, b) => b - a),
        remindForStatus: this.remindForStatus,
        reschedule: this.reschedule,
        rescheduleWindow: this.rescheduleWindow,
        confirmation: this.confirmation,
        canceling: this.canceling
      }

      this.submitting = true
      try {
        await this.update({notifications})
        this.$buefy.toast.open({message: this.$t('status.save_success'), type: 'is-success', queue: false})
      } catch (error) {
        this.$handleAPIError(error)
      }
      this.submitting = false
    },
    getOptionTypes(type) {
      return [
        {
          value: 'sms',
          title: this.$t(`client_notifications.${type}.sms.title`),
          //description: this.$t(`client_notifications.${type}.sms.desc`),
          permission: type === 'reminders' ? PRODUCTS.REMINDERS : PRODUCTS.ADVANCED_SMS_NOTIFICATIONS
        },
        {
          value: 'email',
          title: this.$t(`client_notifications.${type}.email.title`)
          //description: this.$t(`client_notifications.${type}.email.desc`)
        },
        {
          value: 'messenger',
          title: this.$t(`client_notifications.${type}.messenger.title`),
          disabled: !(this.company.messenger && this.company.messenger.fbId && this.company.messenger.active)
          //description: this.$t(`client_notifications.${type}.email.desc`)
        }
      ]
    }
  }
}
</script>
