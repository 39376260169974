import Vue from 'vue'

export default {
  search({query, sortBy, sortDir, page, perPage, type, lng}) {
    return Vue.http.get('coupons/templates', {
      params: {
        query,
        sortBy,
        sortDir,
        page,
        perPage,
        type,
        lng
      }
    })
  },
  getById(templateId) {
    return Vue.http.get(`coupons/templates/${templateId}`)
  },
  createOrUpdate(templateId, data) {
    if (templateId) {
      return Vue.http.put(`coupons/templates/${templateId}`, data)
    }
    return Vue.http.post(`coupons/templates`, data)
  }
}
