const COMPENSATION_TYPES = require('../constants/compensationTypes')
const SALE_ITEM = require('../constants/saleItemTypes')
const saleHelper = require('./saleHelper')
const TARGET_AVERAGES_TYPES = require('../constants/targetAveragesType')
const numeral = require('numeral')
const WEEKDAYS = ['sun', 'mon', 'tue', 'wed', 'thu', 'fri', 'sat']
class TargetsHelper {
  getTargets({
    compensationType,
    weeklyTarget,
    shifts,
    weeklyCost = 0,
    targetRatio = 0,
    retailTargetRatio = 0,
    commission
  }) {
    const {days, hours} = (shifts && this.getWeeklyOccupancy(shifts)) || {days: 0, hours: 0}

    let serviceCommission = (commission && commission.services) || 0
    let retailCommission = (commission && commission.retail) || 0
    let weeklyTotalTarget
    if (compensationType === COMPENSATION_TYPES.WAGE) {
      weeklyTotalTarget = (weeklyCost * targetRatio * 100) / (100 - serviceCommission)
    } else {
      weeklyTotalTarget = (weeklyTarget * 100) / (100 - serviceCommission)
    }

    retailTargetRatio = (retailTargetRatio * 100) / (100 - retailCommission)

    return {
      service: {
        hourly: Math.round(weeklyTotalTarget / hours),
        daily: Math.round(weeklyTotalTarget / days),
        weekly: Math.round(weeklyTotalTarget),
        monthly: Math.round((weeklyTotalTarget * 52) / 12),
        yearly: Math.round(weeklyTotalTarget * 52)
      },
      retail: {
        hourly: Math.round((weeklyTotalTarget * (retailTargetRatio / 100)) / hours),
        daily: Math.round((weeklyTotalTarget * (retailTargetRatio / 100)) / days),
        weekly: Math.round(weeklyTotalTarget * (retailTargetRatio / 100)),
        monthly: Math.round((weeklyTotalTarget * 52 * (retailTargetRatio / 100)) / 12),
        yearly: Math.round(weeklyTotalTarget * 52 * (retailTargetRatio / 100))
      }
    }
  }
  getWeeklyOccupancy(shifts) {
    let days = 0
    let mins = 0
    for (let day in shifts) {
      if (!WEEKDAYS.includes(day)) continue
      if (shifts[day] && shifts[day].length) {
        days++
        mins += shifts[day].reduce((sum, r) => {
          return sum + (r.end - r.start)
        }, 0)
      }
    }
    return {days, hours: Math.round((mins * 100) / 60) / 100}
  }
  // getWorkingDaysPerWeek(shifts) {
  //   if (!shifts) {
  //     return 5
  //   }
  //   let days = 0
  //   for (let day in shifts) {
  //     if (day === '_id') continue
  //     if (shifts[day] && shifts[day].length) {
  //       days++
  //     }
  //   }
  //   return days
  // }
  // getWorkingHoursPerWeek(shifts) {
  //   if (!shifts) {
  //     return 6
  //   }
  //   let mins = 0
  //   for (let day in shifts) {
  //     if (day === '_id') continue

  //     if (shifts[day] && shifts[day].length) {
  //       mins += shifts[day].reduce((sum, r) => {
  //         return sum + (r.end - r.start)
  //       }, 0)
  //     }
  //   }
  //   return Math.round((mins * 100) / 60) / 100
  // }
  generateRecommendations({
    //periodTime,
    actual,
    //retailRevenue,
    target,
    //retailTarget,
    bookedValue,
    avgServicesTarget,
    avgServices,
    avgSale,
    avgSaleTarget,
    avgType,
    currencyCode
  }) {
    let res = []
    const actualAverages = [
      TARGET_AVERAGES_TYPES.REALTIME,
      TARGET_AVERAGES_TYPES.PROJECTION,
      TARGET_AVERAGES_TYPES.ACTUAL
    ]
    const avgTypeActual = actualAverages.indexOf(avgType) !== -1

    if (avgTypeActual && avgServices && avgServices < avgServicesTarget) {
      res.push({
        title: 'low_avg_services',
        type: 'warning',
        actual: avgServices,
        actualFormatted: numeral(avgServices).format('0.0'),
        expected: avgServicesTarget,
        expectedFormatted: numeral(avgServicesTarget).format('0.0'),
        category: SALE_ITEM.SERVICE,
        icon: 'playlist-remove'
      })
    }

    if (avgTypeActual && avgSale && avgSale < avgSaleTarget) {
      res.push({
        title: 'low_avg_services_sale',
        type: 'warning',
        actual: avgSale,
        expected: avgSaleTarget,
        actualFormatted: saleHelper.formatCurrency(avgSale, currencyCode),
        expectedFormatted: saleHelper.formatCurrency(avgSaleTarget, currencyCode),
        currency: true,
        category: SALE_ITEM.SERVICE,
        icon: 'cart-minus'
      })
    }

    /** Customers needed to reach financial goal with current average sale */
    if (
      (avgType === TARGET_AVERAGES_TYPES.REALTIME || avgType === TARGET_AVERAGES_TYPES.PROJECTION) &&
      avgSale &&
      target - actual - bookedValue > 0
    ) {
      const customersNeeded = Math.round((target - actual - bookedValue) / avgSale)

      res.push({
        title: 'customers_financial_need',
        type: 'info',
        actual: customersNeeded,
        actualFormatted: customersNeeded,
        category: SALE_ITEM.SERVICE,
        icon: 'account-plus-outline'
      })
    }

    return res
  }
}

module.exports = new TargetsHelper()
