<template lang="pug">
  div(v-if="coupons")
    b-table(
      :data="coupons" 
      striped=true 
      class="is-vertically-aligned" 
      :sticky-header="stickyHeader"
      custom-row-key="_id"
      )
      
      b-table-column(field="artwork" label=" " custom-key="artwork" width="60" v-slot="props") 
        img.image.is-32x32(:src="props.row.coupon.artwork") 
      
      b-table-column(field="title" :label="$t('customer_coupons.coupon_heading')" v-slot="props") 
        p.heading {{$t(`coupons.${props.row.coupon.type}`)}}
        p.title.is-6(data-qa="coupon-title") {{props.row.coupon.title}}
      
      b-table-column(field="value" :label="$t('customer_coupons.service_heading')" v-slot="props") 
        product-name-view(:value="props.row.coupon.product" v-if="props.row.coupon.product")
        service-name-view(:value="props.row.coupon.service" v-if="props.row.coupon.service && props.row.coupon.service.service")
        
        p(v-for="item in props.row.coupon.package")
          service-name-view(:value="item")
      
      b-table-column(field="expire" :label="$t('customer_coupons.expiration_heading')" v-slot="props")
        date-view(:value="props.row.expire" format="D MMM, YYYY HH:mm")
      b-table-column(field="actions" custom-key="actions" width="50" v-slot="props")
        action-button.is-warning(v-if="showActions" @click="select(props.row)" title="customer_coupons.actions.apply" data-qa="action-apply-coupon") 
        action-button.is-small.is-warning(v-else-if="props.row.coupon.manualRedeem" @click="redeem(props.row)" title="customer_coupons.actions.redeem")
      template(slot="empty")
        empty-content(icon="close-circle-outline" title="customer_coupons.no_eligible_coupons")
    
</template>

<script>
export default {
  name: 'CustomerCouponsTable',
  props: {
    value: {type: Object, default: undefined},
    coupons: {type: Array, default: undefined},
    showActions: {type: Boolean, default: true},
    stickyHeader: {type: Boolean, default: true}
  },
  data() {
    return {
      selected: undefined
    }
  },
  methods: {
    select(coupon) {
      this.$emit('input', coupon)
      this.$emit('close')
    },
    redeem(customerCoupon) {
      this.$buefy.dialog.confirm({
        message: this.$t('customer_coupons.redeem_confirm.body', {title: customerCoupon.coupon.title}),
        title: this.$t('customer_coupons.redeem_confirm.title'),
        cancelText: this.$t('actions.cancel'),
        confirmText: this.$t('customer_coupons.actions.redeem'),
        type: 'is-black',
        onConfirm: () => {
          this.$emit('redeem', customerCoupon)
        }
      })
    }
  }
}
</script>
