<template lang="pug">
  .modal-card
    header.modal-card-head
      p.modal-card-title Reward details
    section.modal-card-body
      table.table.is-striped.is-fullwidth
        thead
          tr
            th Type
            td {{reward.type}}
          tr(v-if="reward.base > 0")
            th Base
            td 
              currency-view(:value="reward.base")
          tr(v-if="reward.points !== undefined")
            th Reward
            td
              strong(:class="reward.points > 0 ? 'has-text-success' : 'has-text-danger'") 
                rewards-view(:value="reward.points")      
          tr(v-if="reward.location")
            th Location
            td {{reward.location.title}}
          tr(v-if="reward.user")
            th Entered by
            td {{reward.user.name || `${reward.user.firstName} ${reward.user.lastName}`}} 
          tr
            th Entered on
            td {{reward.created | fulldate}}
    footer.modal-card-foot
      .buttons
        button.button(type="button" @click="$parent.close()" v-t="'actions.close'")
        action-button.is-danger(@click="remove" :loading="removing" permission="REWARDS_REMOVE" title="actions.delete")
</template>

<script>
import {mapState} from 'vuex'
import RewardsApi from '@/api/rewards'

export default {
  name: 'RewardDetails',
  props: {
    reward: {type: Object, required: true}
  },
  data() {
    return {
      removing: false
    }
  },
  computed: {
    ...mapState('auth', ['user'])
  },
  methods: {
    remove() {
      this.$buefy.dialog.confirm({
        message: 'Are you sure you want to remove the transaction?',
        onConfirm: async () => {
          this.removing = true
          try {
            await RewardsApi.remove(this.reward._id)
            this.$buefy.toast.open({
              message: 'The reward has been removed successfully',
              type: 'is-success',
              queue: false
            })
            this.$emit('removed', this.reward._id)
            this.$emit('close')
          } catch (error) {
            this.$handleAPIError(error)
          }
          this.removing = false
        }
      })
    }
  }
}
</script>
