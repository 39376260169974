<template lang="pug">
.modal-card
  header.modal-card-head
    p.modal-card-title(data-qa="modal-header") 
      b(v-t="'appointment.repeat_appt_title'")
    div
      a.button.is-white.is-small(@click="$emit('close')")
        span.icon
          i.mdi.mdi-close.mdi-24px
  
  section.modal-card-body
    b-field(:label="$t('appointment.repeat_appt_frequency_label')")
      b-select(v-model="frequency" expanded)
        option(v-for="option in FREQUENCY_OPTIONS" :key="option" :value="option") {{ $t(`appointment.repeat_appt_frequency.${option}`) }} 
    template(v-if="frequency !== 'no_repeat'")
      b-field(v-if="frequency === 'custom'" :label="$t('appointment.repeat_appt_custom_frequency_label')")
        b-field(grouped)
          b-field
            b-input(type="number" v-model="interval" min="1" max="365")
          b-field
            b-select(v-model="intervalUnit")
              option(value="day") {{ $t('appointment.repeat_appt_unit.day') }}
              option(value="week") {{ $t('appointment.repeat_appt_unit.week') }}
              option(value="month") {{ $t('appointment.repeat_appt_unit.month') }}
      b-field(:label="$t('appointment.repeat_appt_end_date_label')")
        b-select(v-model="endType" expanded)
          //option(value="never") {{ $t('appointment.repeat_appt_end.never') }}
          option(value="after") {{ $t('appointment.repeat_appt_end.after') }}
          option(value="on") {{ $t('appointment.repeat_appt_end.on') }}
      b-field(v-if="endType === 'after'" :label="$t('appointment.repeat_appt_end_after_label')")
        b-select(v-model="occurrences" expanded)
          option(v-for="i in 49" :key="i + 1" :value="i + 1")  {{ $t('appointment.repeat_appt_after_occurrences', {count: i+1}) }}
      b-field(v-if="endType === 'on'" :label="$t('appointment.repeat_appt_end_on_label')" :type="errors.has('endDate') ? 'is-danger': ''" :message="errors.has('endDate') ? errors.first('endDate') : ''")
        localized-datepicker(v-model="endDate" expanded append-to-body v-validate="'required'" :data-vv-as="$t('appointment.repeat_appt_end_on_label')" name="endDate" :selectable-dates="selectableDates" :dateFormatter="dateFormatter" :placeholder="$t('appointment.repeat_appt_end_on_placeholder')")
  footer.modal-card-foot
    .buttons
      action-button(title="actions.apply" @click="submit")
      button.button(@click="$emit('close')" v-t="'actions.cancel'")
</template>
<script>
import dayjs from 'dayjs'
const FREQUENCY_OPTIONS = ['no_repeat', 'day', 'week', 'month', 'custom']
export default {
  name: 'RepeatSelectorModal',
  props: {
    value: {type: Object, required: true},
    currentDate: {type: Date, required: true}
  },
  data() {
    return {
      frequency: 'no_repeat',
      interval: 1,
      intervalUnit: 'day',
      occurrences: 1,
      endType: 'after',
      endDate: undefined,
      FREQUENCY_OPTIONS
    }
  },
  computed: {
    selectableDates() {
      switch (this.frequency) {
        case 'day':
          return undefined
        case 'week':
          return Array.from({length: 52}, (_, i) =>
            dayjs(this.currentDate)
              .add(i, 'week')
              .toDate()
          )
        case 'month':
          return Array.from({length: 12}, (_, i) =>
            dayjs(this.currentDate)
              .add(i, 'month')
              .toDate()
          )

        case 'custom':
          return Array.from({length: 365}, (_, i) =>
            dayjs(this.currentDate)
              .add(i * this.interval, this.intervalUnit)
              .toDate()
          )
        default:
          return undefined
      }
    },
    normalizedEndDate() {
      let date = new Date(this.endDate)
      date.setHours(this.currentDate.getHours(), this.currentDate.getMinutes())
      return date
    }
  },
  created() {
    this.frequency = this.value.frequency || 'no_repeat'
    this.interval = this.value.interval || 1
    this.intervalUnit = this.value.intervalUnit || 'day'
    this.endType = this.value.endType || 'after'
    this.occurrences = this.value.occurrences || 2
    this.endDate = (this.value.endDate && new Date(this.value.endDate)) || undefined
  },
  methods: {
    async submit() {
      const valid = await this.$validator.validateAll()
      if (!valid) {
        return
      }
      if (this.frequency === 'no_repeat') {
        this.$emit('input', undefined)
      } else {
        this.$emit('input', {
          frequency: this.frequency,
          interval: this.frequency === 'custom' ? this.interval : 1,
          intervalUnit: this.frequency === 'custom' ? this.intervalUnit : this.frequency,
          occurrences: this.endType === 'after' ? this.occurrences : this.getOccurrencesFromEndDate(),
          endType: this.endType,
          endDate: this.endType === 'on' ? this.normalizedEndDate : this.getEndDateFromOccurrences()
        })
      }

      this.$emit('close')
    },
    getOccurrencesFromEndDate() {
      if (this.frequency === 'custom') {
        return dayjs(this.normalizedEndDate).diff(this.currentDate, this.intervalUnit) / this.interval
      }

      return dayjs(this.normalizedEndDate).diff(this.currentDate, this.frequency) + 1
    },
    getEndDateFromOccurrences() {
      if (this.frequency === 'custom') {
        return dayjs(this.currentDate)
          .add((this.occurrences - 1) * this.interval, this.intervalUnit)
          .toDate()
      }
      return dayjs(this.currentDate)
        .add(this.occurrences - 1, this.frequency)
        .toDate()
    },
    dateFormatter(date) {
      return dayjs(date).format('dddd, D MMM, YYYY')
    }
  }
}
</script>
