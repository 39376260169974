import Vue from 'vue'
export default {
  getSurveyOptions(type) {
    return Vue.http.get(`surveys/data/${type}/options`).then(
      res => res.body,
      res => {
        throw res
      }
    )
  },
  getSurveys({customerId, saleIds}) {
    return Vue.http.post(`surveys/list`, {saleIds, customerId}).then(
      res => res.body,
      res => {
        throw res
      }
    )
  }
}
