<template lang="pug">
  .modal-card
    header.modal-card-head
      p.modal-card-title(data-qa="modal-header") 
        b(v-if="!taxId" v-t="'tax_settings.new_tax_modal_header'")
        b(v-else v-t="'tax_settings.edit_tax_modal_header'")
      div
        a.button.is-white.is-small(@click="$emit('close')")
          span.icon
            i.mdi.mdi-close.mdi-24px
    section.modal-card-body
      b-field(:label="$t('tax_settings.add_tax.name_label')" :type="errors.has('name') ? 'is-danger': ''" :message="errors.has('name') ? errors.first('name') : ''" )
        b-input(v-model="name" name='name' v-validate="'required'")
      b-field(:label="$t('tax_settings.add_tax.rate_label')" expanded :type="errors.has('rate') ? 'is-danger': ''" :message="errors.has('rate') ? errors.first('rate') : ''" )
        b-field
          b-input(v-model.number="rate" expanded type="number" data-vv-as="rate" name="rate" numeric v-validate="'required|decimal:2|min_value:0'" :use-html5-validation="false" data-qa="tax-rate")
          p.control
            span.button.is-static %
    footer.modal-card-foot
      .buttons
        action-button(title="actions.confirm" @click="submit" :loading="submitting" data-qa="action-confirm")
        button.button(@click="$emit('close')" v-t="'actions.cancel'")  
    
</template>

<script>
import {mapActions} from 'vuex'

export default {
  name: 'TaxCreateEditModal',

  props: {
    taxId: {type: String, default: undefined}
  },
  data() {
    return {
      submitting: false,
      name: undefined,
      rate: undefined
    }
  },
  async created() {
    if (this.taxId) {
      let tax = await this.getById(this.taxId)
      this.name = tax.name
      this.rate = tax.rate * 100
    }
  },
  methods: {
    ...mapActions('tax', ['updateTax', 'createTax', 'getById']),
    async submit() {
      const valid = await this.$validator.validateAll()
      if (this.submitting || !valid) {
        return
      }
      this.submitting = true
      let data = {
        name: this.name,
        rate: this.rate / 100
      }
      try {
        if (this.taxId) {
          await this.updateTax({id: this.taxId, data})
        } else {
          await this.createTax(data)
        }

        this.$buefy.toast.open({message: this.$t('status.save_success'), type: 'is-success', queue: false})
        this.$emit('close')
      } catch (error) {
        this.$handleAPIError(error)
      }
      this.submitting = false
    },
    cancel() {
      this.$emit('close')
    }
  }
}
</script>
