<template lang="pug">
.modal-card(style="width: auto")
  header.modal-card-head
    p.modal-card-title
      b(v-t="'ledger.transactions_title'")
    div
      a.button.is-white.is-small(@click="$emit('close')")
        span.icon
          i.mdi.mdi-close.mdi-24px
  section.modal-card-body
    b-field(grouped)    
      b-field
        PeriodSelector(v-model="period")
      b-field
        LocationSelector(v-model="location")
    b-table(
        :data="data" 
        :loading="loading" 
        hoverable=true 
        striped=true 
        class="is-vertically-aligned" 
        custom-row-key="_id"
        paginated
        backend-pagination
        :total="total"
        :per-page="perPage"
        @page-change="onPageChange"

        backend-sorting
        :default-sort-direction="defaultSortOrder"
        :default-sort="[sortField, sortOrder]"
        @sort="onSort")
        template(slot="empty")
          empty-content(v-if="!loading" title="ledger.empty_search_result")
          
        b-table-column(field="operation" label="" v-slot="props")
          b-tooltip(v-if="props.row.operation  === 'in'" :label="$t('ledger.cash_in_title')" append-to-body)
            span.icon.has-text-success 
              i.mdi.mdi-arrow-down-bold-box-outline.mdi-24px
          b-tooltip(v-else-if="props.row.operation  === 'out'" :label="$t('ledger.cash_out_title')" append-to-body)
            span.icon.has-text-danger
              i.mdi.mdi-arrow-up-bold-box-outline.mdi-24px
          b-tooltip(v-else-if="props.row.operation  === 'due'" :label="$t('ledger.liabilities_heading')" append-to-body)
            span.icon.has-text-black 
              i.mdi.mdi-receipt.mdi-24px
        b-table-column(field="amount" :label="$t('ledger.amount_label')" v-slot="props" sortable )
          currency-view(:value="props.row.amount")
          template(v-if="props.row.paymentMethod")
            br
            small {{props.row.paymentMethod}}
        b-table-column(field="type" :label="$t('ledger.type_label')" sortable v-slot="props") 
          strong(v-t="`register_transactions.${props.row.type}`") 
          template(v-if="props.row.sale")
            br
            invoice-view(:value="props.row.sale" :invoice="props.row.invoice")
          template(v-if="props.row.notes")
            br
            small {{props.row.notes}}
        b-table-column(field="beneficiary" :label="$t('ledger.beneficiary_label')" sortable v-slot="props") 
          staff-view(:id="props.row.beneficiary" v-if="props.row.beneficiary")
        
        b-table-column(field="user" :label="$t('ledger.registered_by_label')" sortable v-slot="props") 
          staff-view(:id="props.row.user" v-if="props.row.user")
        b-table-column(field="location" :label="$t('ledger.location_label')" sortable v-slot="props" :visible="hasMultipleLocations") 
          location-view(:value="props.row.location")
        
        b-table-column(field="created" :label="$t('ledger.date_label')" sortable v-slot="props") 
          date-view(:value="props.row.created" format="LL LT")
    
  footer.modal-card-foot
    .buttons
      button.button.is-outlined(type="button" @click="$emit('close')" ) {{$t('actions.close')}}      
        
</template>

<script>
import {mapGetters} from 'vuex'
import LedgerApi from '@/api/ledger'
import PeriodSelector from '../helpers/PeriodSelector'
import LocationSelector from '../helpers/LocationSelector'
export default {
  name: 'LedgerTransaction',
  components: {
    PeriodSelector,
    LocationSelector
  },

  data() {
    return {
      data: [],
      loading: false,
      sortField: 'created',
      sortOrder: 'desc',
      total: 0,
      page: 1,
      perPage: 10,
      defaultSortOrder: 'desc',
      period: undefined,
      location: undefined
    }
  },
  computed: {
    ...mapGetters('location', ['hasMultipleLocations'])
  },
  watch: {
    period() {
      return this.fetchTransactions()
    },
    location() {
      return this.fetchTransactions()
    }
  },
  async created() {
    await this.fetchTransactions()
  },
  methods: {
    async fetchTransactions() {
      if (!this.period) {
        return
      }
      const query = {
        sortBy: this.sortField,
        sortDir: this.sortOrder,
        page: this.page,
        perPage: this.perPage,
        from: this.period.from,
        to: this.period.to,
        location: this.location
      }
      this.loading = true
      try {
        let {ledger, total} = await LedgerApi.getLedger(query)
        this.data = ledger
        this.total = total
      } catch (err) {
        this.$handleAPIError(err)
      }
      this.loading = false
    },
    async onSort(field, order) {
      this.sortField = field
      this.sortOrder = order
      await this.fetchTransactions()
    },
    async onPageChange(page) {
      this.page = page
      await this.fetchTransactions()
    }
  }
}
</script>
