<template lang="pug">
    div
      screen-title(title="services.title")
        action-button(@click="addCategory" title="services.actions.new_category" icon="plus" permission="SERVICES_ADD" data-qa="action-open-add-new-category-modal")
        action-button(@click="addService" title="services.actions.new_service" icon="plus" permission="SERVICES_ADD" data-qa="action-open-add-new-service-modal")
        a.button.is-uppercase.is-hidden-mobile(:href="exportLink" target="_blank" v-if="$rfHasPermission('SERVICES_EXPORT')") 
          span.icon
            i.mdi.mdi-download
          b {{$t('actions.export_data')}}
      .columns
        .column
          .box(v-if="!loading && !categories.length")
            .hero.is-medium
              .hero-body
                .container
                  h2.title.has-text-centered {{$t('services.intro.title')}}
                    p.subtitle.has-text-centered {{$t('services.intro.body')}}
                    .field
                      p.control.has-text-centered
                        action-button(title="actions.get_started" @click="addCategory" size="is-medium" permission="SERVICES_ADD")
          draggable(tag="div" v-model="categories" v-bind="dragOptions" :move="onMove" @start="isDragging=true" @end="onDragEnd" v-else) 
            .box(v-for="category in categories" :key="category._id" data-qa="category-box")
              .level.is-mobile.is-marginless
                .level-left
                  .level-item
                    h2.title.is-5.is-uppercase(data-qa="title") 
                      span.icon.is-draggable.has-text-grey-light.is-small
                        i.mdi.mdi-drag.mdi-16px
                      span {{category.name}}
                      span.icon(v-if="category.showInChat")
                        i.mdi.mdi-facebook-messenger
                .level-right
                  .level-item
                    b-dropdown(aria-role="list" position="is-bottom-left" )
                      button.button.is-white(slot="trigger" data-qa="action-open-category-actions")
                        span.icon.has-text-primary
                          i.mdi.mdi-24px.mdi-dots-horizontal
                      b-dropdown-item(aria-role="listitem" @click="editCategory(category)" data-qa="action-edit-category") 
                        span {{$t('services.actions.edit_category')}}
                      hr.dropdown-divider
                      b-dropdown-item(aria-role="listitem" @click="confirmRemove(category)" data-qa="action-delete-category") 
                        span.has-text-danger {{$t('services.actions.delete_category')}}
              p.is-size-6.pl-4(v-if="category.description") {{category.description}}
              CategoryServices(:categoryId="category._id")
              br
              button.button(@click="addService(category)" data-qa="action-new-service") {{$t('services.actions.new_service')}}
</template>
<script>
import {mapActions} from 'vuex'
import CreateEditCategoryForm from './CreateEditCategoryForm'
import draggable from 'vuedraggable'
import CategoryServices from './CategoryServices'
import CreateEditServiceForm from './CreateEditServiceForm.vue'

export default {
  name: 'Services',
  components: {
    CategoryServices,
    draggable
  },
  data() {
    return {
      loading: false,
      isDragging: false
    }
  },
  computed: {
    categories: {
      get() {
        return this.$store.getters['services/categories']
      },
      set(newValue) {
        return this.$store.dispatch('services/updateCategoriesOrder', newValue)
      }
    },
    dragOptions() {
      return {
        animation: 150,
        group: 'categories',
        ghostClass: 'category-ghost'
      }
    },
    exportLink() {
      let url = this.$url('services/export', {})
      return url
    }
  },
  async created() {
    await this.fetch()
  },
  methods: {
    ...mapActions('services', ['list', 'removeCategory']),
    async fetch() {
      this.loading = true
      try {
        await this.list(true)
      } catch (err) {
        this.$handleAPIError(err)
      }
      this.loading = false
    },
    onDragEnd() {
      this.isDragging = false
    },
    onMove({relatedContext, draggedContext}) {
      const relatedElement = relatedContext.element
      const draggedElement = draggedContext.element
      // this.update()
      return (!relatedElement || !relatedElement.fixed) && !draggedElement.fixed
    },
    addService(category) {
      this.$buefy.modal.open({
        parent: this,
        props: {categoryId: category && category._id},
        component: CreateEditServiceForm,
        hasModalCard: true,
        fullScreen: true,
        canCancel: ['escape']
      })
    },

    addCategory() {
      this.$buefy.modal.open({
        parent: this,
        width: 640,
        hasModalCard: true,
        component: CreateEditCategoryForm,
        canCancel: ['escape']
      })
    },
    editCategory(category) {
      this.$buefy.modal.open({
        parent: this,
        width: 640,
        hasModalCard: true,
        component: CreateEditCategoryForm,
        props: {categoryId: category._id},
        canCancel: ['escape']
      })
    },
    confirmRemove(category) {
      this.$buefy.dialog.confirm({
        message: this.$t('services.remove_confirm.body'),
        title: this.$t('services.remove_confirm.title'),
        type: 'is-danger',
        confirmText: this.$t('services.remove_confirm.action'),
        cancelText: this.$t('services.remove_confirm.cancel'),
        onConfirm: () => {
          this.remove(category)
        }
      })
    },
    async remove(category) {
      try {
        await this.removeCategory(category._id)
        this.$buefy.toast.open({message: this.$t('services.remove_confirm.success'), type: 'is-success', queue: false})
      } catch (err) {
        this.$handleAPIError(err)
      }
    }
  }
}
</script>
<style scoped>
.category-ghost {
  opacity: 0.4;
}
</style>
