module.exports = [
  'hairstylist',
  'shampooist',
  'colorist',
  'nail_technician',
  'manicurist',
  'pedicurist',
  'massage_therapist',
  'eyelash_technician',
  'eyebrow_specialist',
  'hairextensions_specialist',
  'podiatrists',
  'esthetician',
  'cosmetologist',
  'beautician',
  'masseur',
  'barber',
  'makeup_artist',
  'waxing_specialist',
  'yoga_instructor',
  'instructor',
  'tattoo_master',
  'doctor',
  'housemaid',
  'other'
]
