<template lang="pug">
div
  h2.is-4.title.is-size-5-mobile.page-title(data-qa="top-navbar-title") {{$t($route.meta.title)}}
  CashRegisterButton(v-if="company.cashRegister && $rfHasPermission('CASH_REGISTER_VIEW')")
  AccountCompleteness.is-hidden-mobile(v-else)
  
  .is-flex.is-align-items-center
    .control.is-clickable(@click="openSMSCenter" v-if="smsCredits")
      .tags.has-addons
        span.tag.is-dark SMS 
          span.is-hidden-mobile Credits
        number-view.tag.is-info(:value="transactionalSMSCredits") 
    
    b-dropdown(aria-role="list" position="is-bottom-left")
      a.button.is-white(slot="trigger" style="background: transparent") 
        span.is-hidden-mobile {{user.firstName}} {{user.lastName}}
        span.is-hidden-tablet.icon.is-medium
          i.mdi.mdi-account-outline.mdi-24px
      b-dropdown-item(aria-role="listitem" :has-link="true")
        router-link.is-size-4-mobile(to="/account/personal")
          span.icon
            i.mdi.mdi-account(aria-hidden="true")
          span {{$t('account.settings.settings_label')}}
      b-dropdown-item(aria-role="listitem" :has-link="true")
        router-link.is-size-4-mobile(to='/signout')
          span.icon
            i.mdi.mdi-logout
          span {{$t('actions.signout')}}
    div.is-hidden-mobile(v-if="company.country === 'AM'")
      b-tooltip(:label="$t('trainings.library_button_tooltip')" position="is-left")
        a(@click="openVideoLibrary()")
          span.icon.is-large.has-text-info-dark
            i.mdi.mdi-help-circle-outline.mdi-36px
    //EmlyButton(v-if="hasSpeechRecognitionAPI && $rfSubscribed('VOICE_ASSIST')")
        
</template>

<script>
import {mapState, mapGetters} from 'vuex'
import AccountCompleteness from '../completeness/AccountCompleteness'
import TrainingCenter from '../tutorials/TrainingCenter'
import SMSCenter from '../sms/SMSCenter'
import CashRegisterButton from '../CashRegisterButton'
import EmlyButton from '../../voi/EmlyButton'
import SMS_TYPES from '../../../constants/smsTypes'

export default {
  name: 'TopNavbar',
  components: {
    AccountCompleteness,
    CashRegisterButton,
    EmlyButton
  },
  computed: {
    ...mapState('auth', ['user']),
    ...mapState('company', ['company', 'smsCredits']),
    ...mapGetters('completeness', ['topTask']),
    hasSpeechRecognitionAPI() {
      return !!(window.SpeechRecognition || window.webkitSpeechRecognition)
    },
    transactionalSMSCredits() {
      return this.smsCredits[SMS_TYPES.TRANSACTION]
    }
  },
  methods: {
    openVideoLibrary() {
      this.$buefy.modal.open({
        parent: this,
        component: TrainingCenter,
        canCancel: ['outside', 'escape'],
        hasModalCard: true,
        fullScreen: this.isMobile(),
        width: 960
      })
    },
    openSMSCenter() {
      this.$buefy.modal.open({
        parent: this,
        component: SMSCenter,
        canCancel: ['outside', 'escape'],
        hasModalCard: true,
        fullScreen: this.isMobile(),
        width: 960
      })
    }
  }
}
</script>

<style scoped>
.page-title {
  margin-bottom: 0 !important;
  white-space: nowrap;
}
.help-button {
  position: fixed;
  bottom: 28px;
  right: 28px;
  z-index: 10000;
  text-align: center;
  background: white;
  width: 48px;
  height: 48px;
  border-radius: 24px;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.4);
}
</style>
