<template lang="pug">
  span {{description}}
</template>

<script>
import {mapState} from 'vuex'
import {TYPES as COUPON_TYPES} from '../../../constants/couponTypes'
import saleHelper from '../../../shared/saleHelper'
export default {
  props: {
    coupon: {type: Object, required: true}
  },
  computed: {
    ...mapState('company', ['company']),
    description() {
      switch (this.coupon.type) {
        case COUPON_TYPES.DISCOUNT:
        case COUPON_TYPES.CASHBACK:
          return this.$t(`coupons.${this.coupon.type}_preview`, {val: this.coupon.value})
        case COUPON_TYPES.OFF:
          return this.$t(`coupons.${this.coupon.type}_preview`, {
            val: saleHelper.formatCurrency(this.coupon.value, this.company.currency)
          })
        case COUPON_TYPES.SERVICES_COMBO:
          return this.$t(`coupons.${this.coupon.type}_preview`, {
            val: saleHelper.formatCurrency(this.coupon.value, this.company.currency),
            pack: this.coupon.coupon.package.length
          })
        default:
          return this.$t(`coupons.${this.coupon.type}_preview`, {val: this.coupon.value})
      }
    }
  }
}
</script>
