//const INDUSTRIES = require('./industries')
const PRODUCTS = {
  CRM_FREE: 'crm_free',
  CHATBOT: 'chatbot',
  DIRECTORY: 'directory',
  REWARDS_PROGRAM: 'rewards',
  SURVEYS: 'surveys',
  SOCIAL_MEDIA: 'smm',
  GIF_EXPRESSIONS: 'gifs',
  REVIEWS: 'reviews',
  REFERRAL_PROGRAM: 'referrals',
  REMINDERS: 'reminders',
  ADVANCED_SMS_NOTIFICATIONS: 'sms_notifications',
  STAFF_MANAGEMENT: 'staff_management',
  APPOINTMENTS: 'appointments',
  ONLINE_BOOKING: 'online_booking',
  POS: 'point_of_sale',
  MARKETING_TOOLS: 'marketing_tools',
  SERVICES: 'services',
  INVENTORY: 'inventory',
  REPORTS_BASIC: 'reports_basic',
  REPORTS_PRO: 'reports_pro',
  REPORTS_ADVANCED: 'reports_advanced',
  AUTOMATIONS: 'automations',
  CAMPAIGNS: 'campaigns',
  PROMOLINKS: 'promolinks',
  FOLLOWUPS: 'followups',
  FORMS: 'forms',
  CASH_REGISTER: 'cash_register',
  CRM_PRO: 'crm_pro',
  CHURN_PREDICTION: 'churn_prediction',
  PERMISSION_MANAGEMENT: 'permissions',
  COUPONS: 'coupons',
  VOUCHERS: 'vouchers',
  PAYMENTS: 'payments',
  DEPOSITS: 'deposits',
  EMLY_AI: 'emly_ai',
  MEMBERSHIPS: 'memberships',

  VOICE_ASSIST: 'voice_assist',
  EDUCATION: 'education',
  TARGETS: 'targets',

  /** CONSULTANCY AND SUPPORT PRODUCTS */
  SETUP_CONSULTANCY: 'setup_consultancy',
  MARKETING_SETUP: 'marketing_setup',
  BUSINESS_ANALYSIS: 'business_analysis',

  RF_FREE: 'rf_free',
  RF_STARTER: 'rf_starter',
  RF_PROFESSIONAL: 'rf_professional',
  RF_UNLIMITED: 'rf_unlimited',

  EM_FREE: 'em_free',
  EM_ENGAGER: 'em_engager',
  EM_PRO: 'em_pro'
}
const BUNDLES = {
  PROMO_SMS_BUNDLE: 'sms_bundle',
  TRANS_SMS_BUNDLE: 'trans_sms_bundle'
}

const PACKAGE_FREE = {
  plan: PRODUCTS.EM_FREE,
  name: 'Appointments',
  excludedRegion: ['AM'],
  highlights: [
    PRODUCTS.APPOINTMENTS,
    PRODUCTS.ONLINE_BOOKING,
    PRODUCTS.POS,
    PRODUCTS.STAFF_MANAGEMENT,
    PRODUCTS.SERVICES
  ],
  included: [
    PRODUCTS.CRM_FREE,
    PRODUCTS.CHATBOT,
    PRODUCTS.STAFF_MANAGEMENT,
    PRODUCTS.APPOINTMENTS,
    PRODUCTS.ONLINE_BOOKING,
    PRODUCTS.SERVICES,
    PRODUCTS.INVENTORY,
    PRODUCTS.REPORTS_BASIC
  ],
  free: true
}
const PACKAGE_EM_ENGAGER = {
  plan: PRODUCTS.EM_ENGAGER,
  name: 'Engager',
  recommended: true,
  excludedRegion: ['AM'],
  highlights: [
    PRODUCTS.MEMBERSHIPS,
    PRODUCTS.VOUCHERS,
    PRODUCTS.MARKETING_TOOLS,
    PRODUCTS.CASH_REGISTER,
    PRODUCTS.TARGETS,
    PRODUCTS.INVENTORY,
    PRODUCTS.SURVEYS,
    PRODUCTS.REPORTS_PRO,
    PRODUCTS.EMLY_AI,
    PRODUCTS.FORMS
  ],
  included: [
    ...PACKAGE_FREE.included,

    PRODUCTS.REFERRAL_PROGRAM,
    PRODUCTS.REVIEWS,
    PRODUCTS.SURVEYS,
    PRODUCTS.GIF_EXPRESSIONS,
    PRODUCTS.COUPONS,
    PRODUCTS.MEMBERSHIPS,
    PRODUCTS.FORMS,

    PRODUCTS.VOUCHERS,
    PRODUCTS.CASH_REGISTER,
    PRODUCTS.REWARDS_PROGRAM,

    PRODUCTS.CRM_PRO,
    PRODUCTS.REPORTS_PRO,
    PRODUCTS.REMINDERS,
    PRODUCTS.PERMISSION_MANAGEMENT,

    PRODUCTS.REPORTS_ADVANCED,
    PRODUCTS.ADVANCED_SMS_NOTIFICATIONS,
    PRODUCTS.CHURN_PREDICTION,
    PRODUCTS.AUTOMATIONS,
    PRODUCTS.CAMPAIGNS,
    PRODUCTS.PROMOLINKS,
    PRODUCTS.FOLLOWUPS,
    PRODUCTS.EMLY_AI,
    PRODUCTS.VOICE_ASSIST,
    PRODUCTS.TARGETS
  ]
}

const PACKAGE_EM_PRO = {
  plan: PRODUCTS.EM_PRO,
  name: 'Pro',
  highlights: [PRODUCTS.EDUCATION, PRODUCTS.BUSINESS_ANALYSIS],
  included: [...PACKAGE_EM_ENGAGER.included, PRODUCTS.EDUCATION],
  contactOnly: true,
  excludedRegion: ['AM']
}

const PACKAGE_STARTER = {
  plan: PRODUCTS.RF_STARTER,
  name: 'Starter',

  highlights: [
    PRODUCTS.APPOINTMENTS,
    PRODUCTS.ONLINE_BOOKING,
    PRODUCTS.POS,
    PRODUCTS.STAFF_MANAGEMENT,
    PRODUCTS.SERVICES,
    PRODUCTS.REPORTS_BASIC,
    PRODUCTS.REMINDERS
  ],
  included: [...PACKAGE_FREE.included, PRODUCTS.REMINDERS]
}
const PACKAGE_PRO = {
  plan: PRODUCTS.RF_PROFESSIONAL,
  name: 'Professional',
  recommended: true,

  highlights: [
    PRODUCTS.PAYMENTS,
    PRODUCTS.DEPOSITS,
    PRODUCTS.MEMBERSHIPS,

    PRODUCTS.MARKETING_TOOLS,
    PRODUCTS.PERMISSION_MANAGEMENT,
    PRODUCTS.INVENTORY,
    PRODUCTS.SURVEYS,
    PRODUCTS.REPORTS_PRO,
    PRODUCTS.VOUCHERS,
    PRODUCTS.CASH_REGISTER,
    PRODUCTS.FORMS
  ],
  included: [
    ...PACKAGE_STARTER.included,

    PRODUCTS.CRM_PRO,
    PRODUCTS.REPORTS_PRO,

    PRODUCTS.PERMISSION_MANAGEMENT,

    PRODUCTS.REWARDS_PROGRAM,
    PRODUCTS.REFERRAL_PROGRAM,
    PRODUCTS.REVIEWS,
    PRODUCTS.SURVEYS,
    PRODUCTS.GIF_EXPRESSIONS,
    PRODUCTS.COUPONS,
    PRODUCTS.MEMBERSHIPS,
    PRODUCTS.VOUCHERS,
    PRODUCTS.CASH_REGISTER,

    PRODUCTS.REPORTS_ADVANCED,
    PRODUCTS.ADVANCED_SMS_NOTIFICATIONS,
    PRODUCTS.CHURN_PREDICTION,
    PRODUCTS.AUTOMATIONS,
    PRODUCTS.CAMPAIGNS,
    PRODUCTS.PROMOLINKS,
    PRODUCTS.FOLLOWUPS,
    PRODUCTS.FORMS,

    PRODUCTS.SOCIAL_MEDIA
  ]
}

const PACKAGE_ULTIMATE = {
  plan: PRODUCTS.RF_UNLIMITED,
  name: 'Unlimited',
  limit: 0,
  highlights: [PRODUCTS.TARGETS, PRODUCTS.EDUCATION, PRODUCTS.EMLY_AI, PRODUCTS.BUSINESS_ANALYSIS],
  included: [...PACKAGE_PRO.included, PRODUCTS.SETUP_CONSULTANCY, PRODUCTS.MARKETING_SETUP, PRODUCTS.BUSINESS_ANALYSIS],
  contactOnly: true
}

const SETTINGS = {
  [PRODUCTS.EM_FREE]: PACKAGE_FREE,
  [PRODUCTS.EM_ENGAGER]: PACKAGE_EM_ENGAGER,
  [PRODUCTS.EM_PRO]: PACKAGE_EM_PRO,

  [PRODUCTS.RF_STARTER]: PACKAGE_STARTER,
  [PRODUCTS.RF_PROFESSIONAL]: PACKAGE_PRO,
  [PRODUCTS.RF_UNLIMITED]: PACKAGE_ULTIMATE
}

module.exports = {
  PRODUCTS,
  BUNDLES,
  SETTINGS
}
