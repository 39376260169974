import {SET_CUSTOMERS, SET_TOTAL_CUSTOMERS, SET_CUSTOMER, REMOVE_CUSTOMER, SET_CUSTOMERS_FILTER} from './mutationTypes'
import CustomersApi from '@/api/customers'
import Vue from 'vue'
// const _pending_promises = {}
export default {
  namespaced: true,
  state: {
    customersMap: {},
    total: 0,
    customersOrder: [],
    filter: {}
  },
  getters: {
    customers(state) {
      return state.customersOrder.map(c => state.customersMap[c])
    },
    customerById: state => customerId => {
      return state.customersMap[customerId]
    }
  },
  mutations: {
    [SET_CUSTOMERS](state, customers) {
      const mapped = {}
      for (let customer of customers) {
        mapped[customer._id] = customer
      }
      state.customersMap = mapped
      state.customersOrder = customers.map(c => c._id)
    },
    [SET_TOTAL_CUSTOMERS](state, total) {
      state.total = total
    },
    [SET_CUSTOMER](state, customer) {
      let current = state.customersMap[customer._id] || {}
      for (let prop in customer) {
        if (current[prop] === undefined) {
          Vue.set(current, prop, customer[prop])
        } else {
          current[prop] = customer[prop]
        }
      }
      Vue.set(state.customersMap, customer._id, current)
    },
    [SET_CUSTOMERS_FILTER](state, filter) {
      state.filter = filter
    },
    [REMOVE_CUSTOMER](state, id) {
      delete state.customersMap[id]
      const idx = state.customersOrder.indexOf(id)
      if (idx !== -1) {
        state.customersOrder.splice(idx, 1)
      }
    }
  },
  actions: {
    async search({commit, state}, filter) {
      if (!filter) {
        filter = state.filter
      }
      if (!filter.perPage) {
        return
      }
      const {body} = await CustomersApi.search(filter)
      commit(SET_CUSTOMERS, body.data)
      commit(SET_TOTAL_CUSTOMERS, body.total)
      commit(SET_CUSTOMERS_FILTER, filter)
    },
    async fetchCustomer({state, commit}, id) {
      if (!state.customersMap[id]) {
        // if (_pending_promises[id]) {
        //   return _pending_promises[id]
        // }
        // _pending_promises[id] = CustomersApi.getById(id)
        // const data = await _pending_promises[id]
        // delete _pending_promises[id]
        const data = await CustomersApi.getById(id)
        commit(SET_CUSTOMER, data)
      }
      return state.customersMap[id]
    },
    async freshFetchCustomer({state, commit}, id) {
      const data = await CustomersApi.getById(id)
      commit(SET_CUSTOMER, data)
      return state.customersMap[id]
    },
    async refreshCustomer({state, commit}, id) {
      if (state.customersMap[id]) {
        const data = await CustomersApi.getById(id)
        commit(SET_CUSTOMER, data)
      }
    },

    async remove({commit}, id) {
      await CustomersApi.remove(id)
      commit(REMOVE_CUSTOMER, id)
    },
    async onCustomerRemoved({dispatch, state}, id) {
      if (state.customersMap[id]) {
        await dispatch('search')
      }
    },
    async updateCustomer({commit}, data) {
      const {body} = await CustomersApi.update(data._id, data)
      commit(SET_CUSTOMER, body)
    },
    async updateCustomerTags({commit, dispatch}, data) {
      const {body} = await CustomersApi.updateTags(data.id, data.tags)
      commit(SET_CUSTOMER, body)
      dispatch('company/fetch', true, {root: true})
    },
    async createCustomer({commit, dispatch}, data) {
      const {body} = await CustomersApi.register(data)
      await dispatch('search')
      commit(SET_CUSTOMER, body)
      return body
    }
  }
}
