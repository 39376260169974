<template lang="pug">
  div
    b-field
      p.control(v-if="hasPreferred")
        b-tooltip(:label="$t('appointment.mark_as_preferred_tooltip')")
          button.button.px-4(@click="switchPreferred") 
            span.icon(v-if="!isPreferred")
              i.mdi.mdi-24px.mdi-heart-outline
            span.icon.has-text-danger(v-else)
              i.mdi.mdi-heart.mdi-24px
      b-select(v-model="selectedStaffId" @input="onChange" :expanded="expanded" :size="size"  :placeholder="$t('staff.staff_select_placeholder')" data-qa="select-staff-member")
        option(v-if="allowEmpty" :value="null") {{ $t('appointment.all_staff_label') }}
        template(v-if="activeNonEmptyLocations.length > 1")
          optgroup(v-for="loc in activeNonEmptyLocations" :label="`${loc.title}${locationId && loc._id === locationId ? ' - CURRENT' : ''}`")
            option(v-for="member in activeServiceMembersByMainLocation(loc._id)" :value="member._id" :key="member._id") {{ member.firstName }} {{ member.lastName }}
        template(v-else)
          option(v-for="member in activeServiceMembers" :value="member._id" :key="member._id") {{ member.firstName }} {{ member.lastName }}
        template(v-if="showArchived && nonActiveServiceMembers.length")
          optgroup(:label="$t('staff.archived_staff_label')")
            option(v-for="member in nonActiveServiceMembers" :value="member._id" :key="member._id") {{ member.firstName }} {{ member.lastName }}
    

</template>
<script>
import {mapState, mapGetters} from 'vuex'

export default {
  name: 'StaffSelect',
  props: {
    staffId: {type: String, default: undefined},
    preferred: {type: Boolean, default: false},
    expanded: {type: String, default: undefined},
    size: {type: String, default: undefined},
    locationId: {type: String, default: undefined},
    serviceId: {type: String, default: undefined},
    hasPreferred: {type: Boolean, default: true},
    allowEmpty: {type: Boolean, default: false},
    showArchived: {type: Boolean, default: false}
  },
  data() {
    return {
      selectedStaffId: undefined,
      isPreferred: false
    }
  },
  computed: {
    ...mapGetters('location', ['activeLocations']),
    ...mapState('auth', ['user']),
    ...mapGetters('staff', ['activeServiceMembers', 'activeServiceMembersByMainLocation', 'nonActiveServiceMembers']),
    ...mapGetters('services', ['serviceById']),
    activeNonEmptyLocations() {
      return this.activeLocations.filter(l => this.activeServiceMembersByMainLocation(l._id).length)
    }
  },
  watch: {
    staffId(newVal) {
      this.selectedStaffId = newVal
    },
    preferred(newVal) {
      this.isPreferred = newVal
    }
  },
  async created() {
    if (this.$rfProfileScope() && this.user.service) {
      this.selectedStaffId = this.user._id
      this.onChange()
    } else if (this.staffId) {
      this.selectedStaffId = this.staffId
      this.isPreferred = this.preferred
    } else if (!this.allowEmpty) {
      this.setRecommendedStaff()
      this.onChange()
    }
  },
  methods: {
    onChange() {
      this.$emit('input', {staffId: this.selectedStaffId, preferred: this.isPreferred})
    },
    setRecommendedStaff() {
      let activeMembers
      if (this.locationId) {
        activeMembers = this.activeServiceMembersByMainLocation(this.locationId)
      } else {
        activeMembers = this.activeServiceMembers
      }
      if (this.serviceId) {
        let serviceProviderMembers = activeMembers.filter(member =>
          this.serviceById(this.serviceId).staff.includes(member._id)
        )
        activeMembers = serviceProviderMembers.length ? serviceProviderMembers : activeMembers
      }

      this.selectedStaffId = (activeMembers.length && activeMembers[0]._id) || undefined
    },
    switchPreferred() {
      this.isPreferred = !this.isPreferred
      this.$emit('input', {staffId: this.selectedStaffId, preferred: this.isPreferred})
    }
  }
}
</script>
