<template lang="pug">
section.section
    screen-title(title="surveys.settings.title")
    .box
      b-field(:label="$t('rewards_program.settings.enabled_label')")
        b-switch(v-model="enabled" type="is-success") {{ enabled ? $t('misc.enabled') : $t('misc.disabled') }}
    template(v-if="enabled")
      .box
        h3.title.is-4 {{ $t('surveys.toimprove') }} 
        SurveyOptionSelector(v-model="feedbackReasons" question="toimprove")
      .box
        h3.title.is-4 {{ $t('surveys.mostliked') }}
        SurveyOptionSelector(v-model="mostlikedOptions" question="mostliked")
      .box
        h3.title.is-4 {{ $t('surveys.channel') }} 
        SurveyOptionSelector(v-model="channelOptions" question="channel")
    .buttons
      action-button(title="actions.save" :loading="submitting" @click="submit" permission="SURVEYS_MANAGE_SETTINGS" scope)
</template>

<script>
import {mapState} from 'vuex'
import SurveyOptionSelector from './SurveyOptionSelector'
import {PRODUCTS} from '../../../constants/products'
export default {
  name: 'SurveysSettings',
  components: {
    SurveyOptionSelector
  },
  data() {
    return {
      submitting: false,
      enabled: true,
      feedbackReasons: [],
      mostlikedOptions: [],
      channelOptions: []
    }
  },
  computed: {
    ...mapState('company', ['company'])
  },
  async created() {
    this.enabled = this.$rfEnabled(PRODUCTS.SURVEYS)
    this.feedbackReasons = [...this.company.feedbackReasons]
    this.channelOptions = [...this.company.channelOptions]
    this.mostlikedOptions = [...this.company.mostlikedOptions]
  },
  methods: {
    async submit() {
      const valid = await this.$validator.validateAll()
      if (!valid) {
        return
      }
      this.submitting = true
      const data = {
        feedbackReasons: this.feedbackReasons,
        channelOptions: this.channelOptions,
        mostlikedOptions: this.mostlikedOptions,
        apps: Object.assign({...this.company.apps}, {[PRODUCTS.SURVEYS]: this.enabled})
      }
      try {
        await this.$store.dispatch('company/update', data)
        this.$buefy.toast.open({message: this.$t('status.save_success'), type: 'is-success', queue: false})
      } catch (error) {
        this.$handleAPIError(error)
      }
      this.submitting = false
    }
  }
}
</script>
