<template lang="pug">
  .service-row(@click="editService")
    .is-flex.is-flex-direction-row
      div(style="flex: 4 1 0px")
        span.icon.is-draggable.has-text-grey-light.is-small
          i.mdi.mdi-drag.mdi-16px
        strong {{service.name}}
        span.icon(v-if="service.color" :style="{'color': `#${service.color}`}")
          i.mdi.mdi-circle
      .is-hidden-mobile(style="flex: 2 1 0px" )
        b-tooltip(:label="service.online ? $t('services.online_booking_enabled_tooltip') : $t('services.online_booking_disabled_tooltip')" type="is-dark")
          span.icon(:class="service.online? 'has-text-info' : 'has-text-grey'")
            i.mdi.mdi-web
        b-tooltip(:label="$t('services.staff_assigned_tooltip', {staff: service.staff.length})" v-if="service.staff && service.staff.length" type="is-dark")
          span.icon.has-text-success
            i.mdi.mdi-account-outline
        b-tooltip(:label="$t('services.no_staff_assigned_tooltip')" v-else type="is-dark")
          span.icon.has-text-danger
            i.mdi.mdi-account-off-outline
        b-tooltip(:label="$t('services.no_location_tooltip')" v-if="!service.locations || !service.locations.length" type="is-dark")
          span.icon.has-text-danger
            i.mdi.mdi-map-marker-off-outline
        b-tooltip(:label="$t('rewards_program.collect_rewards_on_sale')" type="is-dark" v-if="service.collectRewards")
          span.icon.has-text-success
            i.mdi.mdi-hand-coin-outline
        b-tooltip(:label="$t('services.messenger_active_tooltip')" v-if="service.showInChat" type="is-dark")
          span.icon.has-text-facebook
            i.mdi.mdi-facebook-messenger
        b-tooltip(:label="$t('services.has_picture_tooltip')" type="is-dark" v-if="service.picture")
          span.icon.has-text-primary
            i.mdi.mdi-image-outline
        b-tooltip(:label="$t('services.female_service_tooltip')" type="is-dark" v-if="service.gender === 'f'")
          span.icon
            i.mdi.mdi-gender-female
        b-tooltip(:label="$t('services.male_service_tooltip')" type="is-dark" v-if="service.gender === 'm'")
          span.icon
            i.mdi.mdi-gender-male
        b-tooltip(:label="$t('services.kids_service_tooltip')" type="is-dark" v-if="service.kids")
          span.icon
            i.mdi.mdi-human-child
        
        percentage-view.is-percent(:value="service.commissionRate/100" v-if="service.commissionRate !== undefined") 
      .has-text-right.is-hidden-mobile(style="flex: 1 1 0px")
        strong(v-if="service.pricingOptions.length === 1 && $rfSettings().services.duration")
          duration-view(:value="service.pricingOptions[0].duration")
      .has-text-right(style="flex: 1 1 0px")
        div(v-if="service.pricingOptions.length === 1")
          p.heading.mb-0
            b(v-if="getPrice(service.pricingOptions[0]).isFrom" v-t="'misc.from'") 
          strong
            currency-view(:value="getPrice(service.pricingOptions[0]).price")
            //span(v-if="service.pricingOptions[0].priceUpto")
              |  -  
              currency-view(:value="service.pricingOptions[0].priceUpto")
    
    .is-flex.is-flex-direction-row.py-3(v-if="service.pricingOptions.length > 1" v-for="(option, optIdx) in service.pricingOptions" :key="option._id")
      div(style="flex: 5 1 0px")
        .pl-4
          span(v-if="option.name") {{option.name}}
          span.has-text-grey(v-else) \#{{optIdx + 1}} option 
      .has-text-right.is-hidden-mobile(style="flex: 1 1 0px")
        strong(v-if="$rfSettings().services.duration")
          duration-view(:value="option.duration")
      .has-text-right(style="flex: 1 1 0px") 
        p.heading.mb-0
          b(v-if="getPrice(option).isFrom" v-t="'misc.from'")
        strong
          currency-view(:value="getPrice(option).price")
          //span(v-if="option.priceUpto")
            | -  
            currency-view(:value="option.priceUpto")
</template>
<script>
import saleHelper from '../../../shared/saleHelper'
import CreateEditServiceForm from './CreateEditServiceForm'
export default {
  props: {
    service: {type: Object, required: true}
  },

  methods: {
    editService() {
      this.$buefy.modal.open({
        parent: this,
        props: {serviceId: this.service._id},
        component: CreateEditServiceForm,
        hasModalCard: true,
        fullScreen: true,
        canCancel: ['escape']
      })
    },
    getPrice(option) {
      const {minPrice, maxPrice} = saleHelper.getPricingOption(option)
      return {price: minPrice, isFrom: maxPrice !== undefined && minPrice !== maxPrice}
    }
  }
}
</script>
<style scoped>
.service-row:first-of-type {
  border-top: 1px solid #dddddd;
}
.service-row {
  padding: 0.75rem;
  border-bottom: 1px solid #dddddd;
}
.service-row:hover {
  background-color: #f9f9f9;
  cursor: pointer;
}
</style>
