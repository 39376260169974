import Vue from 'vue'

export default {
  getDashboardReport(params) {
    return Vue.http.get(`chatbot/reports`, {params}).then(res => res.body)
  },
  getAllPersistentMenu() {
    return Vue.http.get(`chatbot/allPersistentMenu`).then(res => res.body)
  },
  updatePersistentMenu(data) {
    return Vue.http.put(`chatbot/persistentMenu`, data)
  }
}
