<template lang="pug">
div  
  h3.title.is-6.is-uppercase.has-text-darkgrey {{$t('surveys.rating.customer_satisfaction_header')}}
  h5.subtitle.is-7.mb-1 {{period && period.display}}
  .level(v-if="total") 
    .level-left
      .level-item
        div
          p.title.is-2.is-size-3-mobile.has-text-success.is-marginless        
            percentage-view(:value="happy/total")
          p.heading(v-t="'surveys.rating.wows_header'")
    .level-right
      .level-item
        div
          p.title.is-2.is-size-3-mobile.has-text-danger.is-marginless
            percentage-view(:value="unhappy/total")
          p.heading(v-t="'surveys.rating.complains_header'")
  p.title.is-2.is-size-3-mobile(v-else)
    span -
  p {{$t('surveys.rating.rated_visits')}} 
    b {{rated}}
  p {{$t('surveys.rating.total_visits')}} 
    b {{total}}
  p {{$t('surveys.rating.happy_visits')}}  
    b {{happy}}
  p {{$t('surveys.rating.unhappy_visits')}}   
    b {{unhappy}}
</template>

<script>
import ReportsApi from '@/api/reports'
export default {
  name: 'SatisfactionRate',
  props: {
    period: {type: Object, default: undefined},
    position: {type: String, default: undefined},
    employee: {type: String, default: undefined},
    location: {type: String, default: undefined}
  },
  data() {
    return {
      loading: false,
      total: 0,
      unhappy: 0,
      happy: 0,
      rated: 0
    }
  },
  watch: {
    period() {
      return this.fetchData()
    },
    location() {
      return this.fetchData()
    }
  },
  created() {
    if (this.period) {
      return this.fetchData()
    }
  },
  methods: {
    async fetchData() {
      this.loading = true
      try {
        const {total, rated, happy, unhappy} = await ReportsApi.getSatisfactionRate({
          from: this.period.from,
          to: this.period.to,
          location: this.location
        })
        this.total = total
        this.rated = rated
        this.happy = happy
        this.unhappy = unhappy
      } catch (error) {
        this.$handleAPIError(error)
      }
      this.loading = false
    }
  }
}
</script>
