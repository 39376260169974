const CORE_PERMISSION = {
  CLIENTS_VIEW: 'CLIENTS_VIEW',
  CLIENTS_ADD: 'CLIENTS_ADD',
  CLIENTS_REMOVE: 'CLIENTS_REMOVE',
  CLIENTS_EDIT: 'CLIENTS_EDIT',
  CLIENTS_MERGE: 'CLIENTS_MERGE',
  CLIENTS_EXPORT: 'CLIENTS_EXPORT',

  CLIENT_COMPOSE_SMS: 'CLIENT_COMPOSE_SMS',

  PRIVACY_VIEW_PHONE_NUMBERS: 'PRIVACY_VIEW_PHONE_NUMBERS',

  STAFF_VIEW: 'STAFF_VIEW',
  STAFF_ADD: 'STAFF_ADD',
  STAFF_REMOVE: 'STAFF_REMOVE',
  STAFF_EDIT: 'STAFF_EDIT',
  STAFF_SHIFTS_VIEW: 'STAFF_SHIFTS_VIEW',
  STAFF_SHIFTS_EDIT: 'STAFF_SHIFTS_EDIT',
  STAFF_PERMISSIONS_VIEW: 'STAFF_PERMISSIONS_VIEW',
  STAFF_PERMISSIONS_EDIT: 'STAFF_PERMISSIONS_EDIT',
  STAFF_PERFORMANCE_VIEW: 'STAFF_PERFORMANCE_VIEW',

  RESOURCES_VIEW: 'RESOURCES_VIEW',
  RESOURCES_MANAGE: 'RESOURCES_MANAGE',
  RESOURCES_SCHEDULE_VIEW: 'RESOURCES_SCHEDULE_VIEW',
  RESOURCES_SCHEDULE_EDIT: 'RESOURCES_SCHEDULE_EDIT',

  SERVICES_VIEW: 'SERVICES_VIEW',
  SERVICES_ADD: 'SERVICES_ADD',
  SERVICES_REMOVE: 'SERVICES_REMOVE',
  SERVICES_EDIT: 'SERVICES_EDIT',
  SERVICES_EXPORT: 'SERVICES_EXPORT',

  SALE_VIEW: 'SALE_VIEW',
  SALE_CREATE: 'SALE_CREATE',
  SALE_INVOICE_REMOVE: 'SALE_INVOICE_REMOVE',
  SALE_INVOICE_REFUND: 'SALE_INVOICE_REFUND',
  SALE_EXPORT: 'SALE_EXPORT',

  PAYMENT_EXPORT: 'PAYMENT_EXPORT',

  APPOINTMENT_VIEW: 'APPOINTMENT_VIEW',
  APPOINTMENT_CREATE: 'APPOINTMENT_CREATE',
  APPOINTMENT_EXPORT: 'APPOINTMENT_EXPORT',
  APPOINTMENT_EDIT_NOTES: 'APPOINTMENT_EDIT_NOTES',

  VOUCHERS_VIEW: 'VOUCHERS_VIEW',
  VOUCHERS_MANAGE: 'VOUCHERS_MANAGE',
  VOUCHERS_EXTEND: 'VOUCHERS_EXTEND',

  MEMBERSHIPS_VIEW: 'MEMBERSHIPS_VIEW',
  MEMBERSHIP_PACKAGES_MANAGE: 'MEMBERSHIP_PACKAGES_MANAGE',

  REMINDERS_VIEW: 'REMINDERS_VIEW',
  REMINDERS_MANAGE: 'REMINDERS_MANAGE',

  ANALYTICS: 'ANALYTICS',
  DASHBOARD_ANALYTICS: 'DASHBOARD_ANALYTICS',
  CLIENT_SALES_DATA: 'CLIENT_SALES_DATA',
  WEEKLY_REPORT: 'WEEKLY_REPORT',

  BILLING_VIEW: 'BILLING_VIEW',
  BILLING_MANAGE: 'BILLING_MANAGE',

  CASH_REGISTER_VIEW: 'CASH_REGISTER_VIEW',
  CASH_REGISTER_MANAGE: 'CASH_REGISTER_MANAGE',
  CASH_REGISTER_REPORT: 'CASH_REGISTER_REPORT',

  COMPANY_SETTINGS_VIEW: 'COMPANY_SETTINGS_VIEW',
  COMPANY_SETTINGS_EDIT: 'COMPANY_SETTINGS_EDIT',

  FORMS_VIEW: 'FORMS_VIEW',
  FORMS_MANAGE: 'FORMS_MANAGE',

  SETTINGS_LOCATIONS_VIEW: 'SETTINGS_LOCATIONS_VIEW',
  SETTINGS_LOCATIONS_MANAGE: 'SETTINGS_LOCATIONS_MANAGE',
  ONLINE_BOOKING_SETTINGS_VIEW: 'ONLINE_BOOKING_SETTINGS_VIEW',
  ONLINE_BOOKING_SETTINGS_MANAGE: 'ONLINE_BOOKING_SETTINGS_MANAGE',

  SETTINGS_LANGUAGES_VIEW: 'SETTINGS_LANGUAGES_VIEW',
  SETTINGS_LANGUAGES_MANAGE: 'SETTINGS_LANGUAGES_MANAGE',
  SETTINGS_PLATFORMS_VIEW: 'SETTINGS_PLATFORMS_VIEW',
  SETTINGS_PLATFORMS_MANAGE: 'SETTINGS_PLATFORMS_MANAGE',
  SETTINGS_API_VIEW: 'SETTINGS_API_VIEW',

  SETTINGS_CASH_REGISTER_MANAGE: 'SETTINGS_CASH_REGISTER_MANAGE'
}

module.exports = {
  core: CORE_PERMISSION,
  targets: require('../server/modules/targets/permissions'),
  surveys: require('../server/modules/surveys/permissions'),
  inventory: require('../server/modules/inventory/permissions'),
  rewards: require('../server/modules/rewards/permissions'),
  reviews: require('../server/modules/reviews/permissions'),
  coupons: require('../server/modules/coupons/permissions'),
  referrals: require('../server/modules/referrals/permissions'),
  chatbot: require('../server/modules/chatbot/permissions'),
  promolinks: require('../server/modules/promolinks/permissions'),
  automations: {
    AUTOMATIONS_VIEW: 'AUTOMATIONS_VIEW',
    AUTOMATIONS_ADD: 'AUTOMATIONS_ADD',
    AUTOMATIONS_REMOVE: 'AUTOMATIONS_REMOVE',
    AUTOMATIONS_EDIT: 'AUTOMATIONS_EDIT'
  },
  campaigns: {
    CAMPAIGNS_VIEW: 'CAMPAIGNS_VIEW',
    CAMPAIGNS_ADD: 'CAMPAIGNS_ADD',
    CAMPAIGNS_REMOVE: 'CAMPAIGNS_REMOVE',
    CAMPAIGNS_PUBLISH: 'CAMPAIGNS_PUBLISH',
    CAMPAIGNS_EDIT: 'CAMPAIGNS_EDIT'
  },
  followups: {
    FOLLOWUPS_VIEW: 'FOLLOWUPS_VIEW',
    FOLLOWUPS_ADD: 'FOLLOWUPS_ADD',
    FOLLOWUPS_REMOVE: 'FOLLOWUPS_REMOVE',
    FOLLOWUPS_EDIT: 'FOLLOWUPS_EDIT'
  },
  socialMedia: {
    SMM_VIEW: 'SMM_VIEW',
    SMM_MANAGE: 'SMM_MANAGE'
  }
}
