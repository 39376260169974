<template lang="pug">
  span {{stockAmount}}
</template>
<script>
import numeral from 'numeral'

export default {
  name: 'StockView',
  props: {
    amount: {type: Number, required: true},
    unit: {type: String, required: true},
    short: {type: Boolean, default: true}
  },
  computed: {
    stockAmount() {
      if (this.amount === 0) {
        return this.$t('inventory.out_of_stock')
      }
      const count = numeral(this.amount).format('0,0')
      const unitText = (this.unit && this.$t(`units.${this.unit}`)) || ''
      if (this.short) {
        return `${count} ${unitText}`
      }

      if (this.amount < 0) {
        return this.$t('inventory.stock_short', {
          count,
          unitText
        })
      }
      return this.$t('inventory.in_stock', {count, unitText})
    }
  }
}
</script>
