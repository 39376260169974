<template lang="pug">
span(v-if="value") {{formatted}}
</template>
<script>
import dayjs from 'dayjs'
export default {
  props: {
    value: {type: [Date, String, Number], default: undefined},
    format: {type: String, required: true},
    timezone: {type: String, default: undefined},
    parse: {type: String, default: undefined}
  },
  computed: {
    formatted() {
      if (this.timezone) {
        return dayjs.tz(this.value, this.timezone).format(this.format)
      }
      if (this.parse) {
        return dayjs('' + this.value, this.parse).format(this.format)
      }
      return dayjs(this.value).format(this.format)
    }
  }
}
</script>
