<template lang="pug">
  span(v-if="product") {{name}}
</template>

<script>
import {mapActions} from 'vuex'

export default {
  name: 'ProductNameView',
  props: {
    value: {type: String, required: true}
  },
  data() {
    return {
      product: undefined
    }
  },
  computed: {
    name() {
      if (!this.product) {
        return
      }
      let sku = this.product.sku ? ' · ' + this.product.sku : ''
      return `${this.product.name}${sku}`
    }
  },
  async created() {
    this.product = await this.fetchProduct(this.value)
  },
  methods: {
    ...mapActions('inventory', ['fetchProduct'])
  }
}
</script>
