<template lang="pug">
  article.msg.msg-left
    block input
</template>
<script>
import cloneDeep from 'lodash/cloneDeep'
export default {
  props: {
    value: {type: Object, required: true},
    advancedMode: {type: Boolean, default: false},
    readonly: {type: Boolean, default: false}
  },
  data() {
    return {
      message: undefined
    }
  },
  watch: {
    value(newVal) {
      this.message = cloneDeep(newVal)
    }
  },
  created() {
    this.message = cloneDeep(this.value)
    this.onInput()
  }
}
</script>
