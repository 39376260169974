<template lang="pug">
  b-loading(:active="active" :is-full-page="isFullPage")
    figure.image.is-48x48
      img(src='/img/loading.gif')
</template>
<script>
export default {
  props: {
    active: {type: Boolean, required: true},
    isFullPage: {type: Boolean, default: false}
  }
}
</script>
