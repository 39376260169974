<script>
import VueChart from 'vue-chartjs'
import ReportsApi from '@/api/reports'

export default {
  name: 'FeedbackDistribution',
  extends: VueChart.Line,
  props: {
    period: {type: Object, default: undefined},
    location: {type: String, default: undefined}
  },
  data() {
    return {
      loading: false
    }
  },
  watch: {
    period() {
      return this.fetchData()
    },
    location() {
      return this.fetchData()
    }
  },
  created() {
    if (this.period) {
      return this.fetchData()
    }
  },
  methods: {
    async fetchData() {
      this.loading = true
      try {
        const data = await ReportsApi.getFeedbackDistribution({
          from: this.period.from,
          to: this.period.to,
          location: this.location
        })

        this.$data._chart && this.$data._chart.destroy()
        this.renderChart(data, {
          legend: {
            display: false
          },
          title: {
            display: false,
            text: 'Feedback'
          },
          tooltips: {
            mode: 'index',
            intersect: false
          },
          responsive: true,
          maintainAspectRatio: false,
          scales: {
            xAxes: [
              {
                stacked: true
              }
            ],
            yAxes: [
              {
                stacked: true
              }
            ]
          }
        })
      } catch (error) {
        this.$handleAPIError(error)
      }
      this.loading = false
    }
  }
}
</script>
