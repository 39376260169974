<template lang="pug">
span {{repeatPattern}}
</template>
<script>
import dayjs from 'dayjs'
export default {
  name: 'RepeatPatternView',
  props: {
    value: {type: Object, default: undefined}
  },
  computed: {
    repeatPattern() {
      let pattern = ''
      switch (this.value && this.value.frequency) {
        case 'day':
          pattern = this.$t('appointment.repeat_appt_frequency.day')
          break
        case 'week':
          pattern = this.$t('appointment.repeat_appt_frequency.week')
          break
        case 'month':
          pattern = this.$t('appointment.repeat_appt_frequency.month')
          break
        case 'custom':
          pattern = this.$t('appointment.repeat_appt_custom_frequency', {
            count: this.value.interval,
            unit: this.$t(`appointment.repeat_appt_unit_plural.${this.value.intervalUnit}`).toLowerCase()
          })
          break
        default:
          return this.$t('appointment.repeat_appt_frequency.no_repeat')
      }
      pattern += ` ${this.$t('appointment.repeat_appt_end.until', {
        date: dayjs(this.value.endDate).format('D MMM YYYY')
      })} (${this.$t('appointment.repeat_appt_end.times', {count: this.value.occurrences})})`

      return pattern
    }
  }
}
</script>
