<template lang="pug">
div
  em-loading(:active="loading" :is-full-page="false")
  .card.p-2.is-printable(v-if="sale")
    .card-image.p-3
      figure.image.is-64x64.mb-3(v-if="company.logo" style="margin: 0 auto")
        img.is-contain(:src="company.logo")
      p.title.is-5.has-text-centered {{company.name}}
      p.subtitle.is-6.has-text-centered.mb-1(v-if="hasMultipleLocations")
        location-view(:value="sale.location")
      p.subtitle.is-7.has-text-centered.mb-3
        location-address-view(:location-id="sale.location")
      p.has-text-centered(v-if="sale.customer && company.crm.customerInReceipt" )
        CustomerContactView(:customerId="sale.customer")
    header.card-header.is-shadowless
      .card-header-title
        p.is-size-5(style="font-weight: 400")
          span(v-t="'sale.invoice_title'")
          |  &nbsp; &nbsp;
          b \#{{sale.invoice}}
        
      .card-header-icon(v-if="sale.status === SALE_STATUS.COMPLETED")
        PaymentStatusIcon.is-pulled-right(:status="sale.paymentStatus" :showText="true" :isTag="true" tagSize=" ")
      .card-header-icon(v-else)
        SaleStatusIcon(:status="sale.status" show-text is-tag)
      
    .card-content
      table.table.is-fullwidth.has-transparent-background
        tr(v-for="(item, idx) in sale.items" :key="item._id" :class="{'is-borderless': idx !== sale.items.length -1}")
          td.px-0
            SaleItem(:item="item")
          td.px-0.has-text-right
                p
                  strong
                    currency-view(:value="item.total" v-if="company.priceIncludesTax")
                    currency-view(:value="item.subtotal - item.discount" v-else)
                p.has-text-grey.is-contrasted(v-if="item.manualDiscount")
                  span.has-text-strikedout 
                    currency-view(:value="item.itemPrice * item.qty")
        
        tr(v-if="sale.customerCoupon")
          td.px-0
            p.is-size-5(v-t="'sale.coupon_label'")
          td.px-0.has-text-right
            customer-coupon-view(:value="sale.customerCoupon")
        tr.is-borderless
          td.px-0
            p.is-size-5(v-t="'sale.subtotal_price'")
          td.px-0.has-text-right
            p.is-size-5
              currency-view(:value="sale.subtotal" data-qa="subtotal-price")
        tr.is-borderless(v-if="sale.discount")
          td.px-0
            p.is-size-5(v-t="'sale.discount_label'")
          td.px-0.has-text-right
            p.is-size-5
              currency-view(:value="sale.discount" data-qa="discount-price")
        
        tr.is-borderless(v-if="sale.discounts.membership")
          td.p-0.pl-2
            p.is-size-7(v-t="'sale.discount_type.membership'")
          td.p-0.has-text-right
            p.is-size-7
              currency-view(:value="sale.discounts.membership")
        tr.is-borderless(v-if="sale.discounts.voucher")
          td.p-0.pl-2
            p.is-size-7(v-t="'sale.discount_type.voucher'")
          td.p-0.has-text-right
            p.is-size-7
              currency-view(:value="sale.discounts.voucher")
        tr.is-borderless(v-if="sale.discounts.coupon")
          td.p-0.pl-2
            p.is-size-7(v-t="'sale.discount_type.coupon'")
          td.p-0.has-text-right
            p.is-size-7
              currency-view(:value="sale.discounts.coupon")
        tr.is-borderless(v-if="sale.discounts.items")
          td.p-0.pl-2
            p.is-size-7(v-t="'sale.discount_type.items_discount'")
          td.p-0.has-text-right
            p.is-size-7
              currency-view(:value="sale.discounts.items")
        tr.is-borderless(v-if="sale.discounts.itemsCoupon")
          td.p-0.pl-2
            p.is-size-7(v-t="'sale.discount_type.items_coupon'")
          td.p-0.has-text-right
            p.is-size-7
              currency-view(:value="sale.discounts.itemsCoupon")

        tr.is-borderless
          td.px-0
            p.is-size-5(v-t="'sale.tax_label'")
            p.is-size-7.has-text-grey.is-contrasted(v-for="(tax, idx) in sale.taxes")
              tax-view(:value="tax.tax")
              |  
              currency-view(:value="tax.value")
          td.px-0.has-text-right
            p.is-size-5
              currency-view(:value="sale.tax" data-qa="tax-price")
        tr
          td.px-0
            p.is-size-5
              strong(v-t="'sale.total_price'")
          td.px-0.has-text-right
            p.is-size-5
              strong
                currency-view(:value="sale.total" data-qa="total-price")
        tr.is-borderless(v-for="payment in sale.payments" style="position: relative")
          td.px-0
            p.title.is-6
              payment-type-view(:value="payment.type")
              template(v-if="payment.voucher")
                br
                small
                  voucher-name-view(:value="payment.voucher" code style="font-weight: normal") 
              template(v-if="payment.payment")
                br
                small
                  payment-view(:value="payment.payment") 
            p.subtitle.is-size-7.has-text-grey.is-contrasted 
              date-view(:value="payment.ts" format="L LT") 
          td.px-0.has-text-right
            p
              strong
                currency-view(:value="payment.paid")
        tr(v-if="sale.notes") 
          em.is-size-7 {{sale.notes}}
        tr.is-borderless        
          td.px-0
            p.is-size-5
              span(v-t="'sale.balance_price'")
          td.px-0.has-text-right
            p.is-size-5
              span
                currency-view(:value="sale.balance" data-qa="balance-price")
        
      p.has-text-centered.help.has-text-grey.is-contrasted {{sale.payments.length && sale.payments[0].ts || sale.created || new Date() | fulldate}}
      p.has-text-centered.help(v-if="sale.user")
        staff-view(:id="sale.user")
      p.has-text-centered(v-if="sale.printFiscal === false") 
        span.tag.is-danger Անհրաժեշտ է տպել ՀԴՄ կտրոնը ձեռքով
      p.has-text-centered(v-if="sale.fiscal")
        span {{ $t('sale.fiscal_label') }} {{ sale.fiscal }} 
        span.tag.is-danger.ml-2(v-if="sale.fiscalType ==='return'") {{$t('sale_status.void')}}
      .p-4.is-hidden-print
        .buttons.is-centered
          //- a.button.is-outlined.is-dark(@click="print" ) 
          //-   span.icon
          //-     i.mdi.mdi-printer
          //-   span(v-t="'actions.print'")
          action-button.is-outlined.is-danger(v-if="voidReady" @click="startPaymentVoiding" type="is-dark" :loading="voidingPayment" cash-register :disabled="refunding" :bold="false" title="sale.actions.void_payment" icon="trash-can-outline" permission="SALE_INVOICE_REMOVE" data-qa="action-void-payment") 
          
          //action-button.is-outlined.is-danger(v-if="refundReady" type="is-dark" @click="startRefund" cash-register :loading="refunding" :disabled="voidingPayment" :bold="false" title="sale.actions.refund" icon="undo-variant" permission="SALE_INVOICE_REFUND" data-qa="action-refund") 
         
          action-button.is-outlined.is-success(v-if="checkoutReady" type="is-dark" @click="checkout" cash-register :loading="refunding" :disabled="voidingPayment" :bold="false" title="sale.actions.checkout" icon="basket-outline" permission="SALE_CREATE" data-qa="action-checkout") 
</template>

<script>
import {mapActions, mapGetters, mapState} from 'vuex'
import SaleItem from './SaleItem'
import PaymentStatusIcon from './PaymentStatusIcon'
import SaleStatusIcon from './SaleStatusIcon'
import Checkout from './Checkout'
import CustomerContactView from '../customers/CustomerContactView'

import SALE_STATUS from '../../../constants/saleStatus'
import PAYMENT_STATUS from '../../../constants/paymentStatus'

export default {
  name: 'Invoice',
  components: {
    SaleItem,
    SaleStatusIcon,
    PaymentStatusIcon,
    CustomerContactView
  },
  props: {
    saleId: {type: String, required: true}
    // autoprint: {type: Boolean, default: false}
  },
  data() {
    return {
      loading: false,
      voidingPayment: false,
      refunding: false,
      sale: undefined,
      SALE_STATUS
    }
  },

  computed: {
    ...mapState('company', ['company']),
    ...mapGetters('location', ['hasMultipleLocations']),
    checkoutReady() {
      return (
        this.sale.status === SALE_STATUS.COMPLETED &&
        (this.sale.paymentStatus === PAYMENT_STATUS.UNPAID || this.sale.paymentStatus === PAYMENT_STATUS.PARTIALLY_PAID)
      )
    },
    voidReady() {
      return this.sale.status === SALE_STATUS.COMPLETED || this.sale.status === SALE_STATUS.REFUNDED
    },
    refundReady() {
      return (
        this.sale.status === SALE_STATUS.COMPLETED &&
        (this.sale.paymentStatus === PAYMENT_STATUS.PAID || this.sale.paymentStatus === PAYMENT_STATUS.PARTIALLY_PAID)
      )
    }
  },
  // watch: {
  //   async sale() {
  //     if (!this.sale) return
  //     await this.$nextTick()
  //     setTimeout(() => {
  //       this.$emit('printReady', true)
  //     }, 500)
  //   }
  // },
  async created() {
    this.loading = true
    this.sale = await this.fetchSaleById({id: this.saleId})
    this.loading = false
    // if (this.autoprint) {
    //   this.print()
    // }
  },

  methods: {
    ...mapActions('sales', ['fetchSaleById', 'voidSale', 'refundSale']),
    checkout() {
      this.$emit('close')
      this.$buefy.modal.open({
        parent: this.$parent,
        props: {
          saleId: this.saleId
        },
        component: Checkout,
        hasModalCard: true,
        fullScreen: true,

        canCancel: ['escape', 'outside']
      })
    },
    startRefund() {
      if (this.voidingPayment) {
        return
      }
      this.$buefy.dialog.confirm({
        message: this.$t('sale.refund_confirm.body'),
        title: this.$t('sale.refund_confirm.title'),
        cancelText: this.$t('sale.void_payment_confirm.cancel'),
        confirmText: this.$t('sale.refund_confirm.action'),
        type: 'is-danger',
        onConfirm: () => {
          this.confirmRefund()
        }
      })
    },
    async confirmRefund() {
      try {
        this.refunding = true
        await this.refundSale(this.saleId)
      } catch (err) {
        this.$handleAPIError(err)
      }
      this.refunding = false
    },
    startPaymentVoiding() {
      if (this.voidingPayment) {
        return
      }
      this.$buefy.dialog.confirm({
        message: this.$t('sale.void_payment_confirm.body'),
        title: this.$t('sale.void_payment_confirm.title'),
        cancelText: this.$t('sale.void_payment_confirm.cancel'),
        confirmText: this.$t('sale.void_payment_confirm.action'),
        type: 'is-danger',
        onConfirm: () => {
          this.confirmPaymentVoiding()
        }
      })
    },
    async confirmPaymentVoiding() {
      try {
        this.voidingPayment = true
        await this.voidSale({
          saleId: this.saleId,
          customerId: this.sale.customer
        })
        this.$emit('voided')
      } catch (err) {
        this.$handleAPIError(err)
      }
      this.voidingPayment = false
    }
    // print() {
    //   const hideFrame = document.createElement('iframe')
    //   hideFrame.onload = function() {
    //     const closePrint = function() {
    //       document.body.removeChild(hideFrame)
    //     }
    //     this.contentWindow.onbeforeunload = closePrint
    //     this.contentWindow.onafterprint = closePrint
    //     //this.contentWindow.print()
    //   }
    //   hideFrame.style.display = 'none' // hide iframe
    //   hideFrame.src = `/invoice/${this.saleId}`
    //   document.body.appendChild(hideFrame)
    // }
  }
}
</script>
<style scoped>
.card:after {
  background: linear-gradient(-45deg, #ffffff 12px, transparent 0), linear-gradient(45deg, #ffffff 12px, transparent 0);
  background-position: left-bottom;
  background-repeat: repeat-x;
  background-size: 24px 24px;
  content: ' ';
  display: block;
  position: absolute;
  bottom: 0px;
  left: 0px;
  width: 100%;
  height: 24px;
}
.card {
  box-shadow: none;
  position: relative;
  padding-bottom: 1rem;
  background: #f1f1f1;
}
</style>
