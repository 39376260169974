<template lang="pug">
  div
    screen-title(title="crm_settings.customer_data_title")
    .box
      //h2.title.is-4 {{$t('crm_settings.customer_data_title')}}
      label.label(v-t="'crm_settings.customer_fields_label'")
      b-field
        b-checkbox(v-model="crmSettings.phoneRequired") {{$t('crm_settings.phone_required')}}
      b-field
        b-checkbox(v-model="crmSettings.emailRequired") {{$t('crm_settings.email_required')}}
      b-field
        b-checkbox(v-model="crmSettings.middleName") {{$t('crm_settings.middle_name_field')}}
      b-field
        b-checkbox(v-model="crmSettings.recommendationsField") {{$t('crm_settings.recommendations_field')}}
      b-field
        b-checkbox(v-model="crmSettings.altPhone") {{$t('crm_settings.alt_phone_field')}}
      b-field
        b-checkbox(v-model="crmSettings.customerInReceipt") {{$t('crm_settings.print_customer_in_receipt')}}
      b-field
        b-checkbox(v-model="crmSettings.addressFields") {{$t('crm_settings.address_fields')}}
      b-field(:label="$t('crm_settings.tags_label')")
        b-taginput(
            v-model="crmSettings.tags"
            attached
            autocomplete
            ellipsis
            allow-new
            maxlength="25"
            icon="label"
            :placeholder="$t('crm_settings.tags_placeholder')")
      
      
    .buttons
      action-button(title="actions.save" @click="submit" :loading="submitting" permission="REMINDERS_MANAGE" scope)
        
    //.box
      .hero
        .hero-body.has-text-centered
          span.is-large
            i.mdi.mdi-bell.mdi-48px
          h2.title.is-3 Status change notifications 
          p.subtitle Coming soon
</template>

<script>
import {mapState, mapActions} from 'vuex'

export default {
  name: 'CRMSettings',
  data() {
    return {
      submitting: false,
      crmSettings: {}
    }
  },
  computed: {
    ...mapState('company', ['company'])
  },
  created() {
    this.crmSettings = Object.assign({}, this.company.crm)
  },
  methods: {
    ...mapActions('company', ['update']),
    async submit() {
      const valid = await this.$validator.validateAll()
      if (!valid) {
        return
      }
      this.submitting = true
      try {
        await this.update({crm: this.crmSettings})
        this.$buefy.toast.open({message: this.$t('status.save_success'), type: 'is-success', queue: false})
      } catch (error) {
        this.$handleAPIError(error)
      }
      this.submitting = false
    }
  }
}
</script>
