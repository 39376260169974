<template lang="pug">
span.tag(:class="due.css") 
  span.icon
    i.mdi.mdi-clock-outline
  span {{due.text}}
</template>

<script>
import dayjs from 'dayjs'
export default {
  name: 'EstNextPurchase',
  props: {
    customer: {type: Object, required: true}
  },
  computed: {
    due() {
      if (!this.customer.lastVisit || !this.customer.stats || !this.customer.stats.tbp) {
        return {css: '', text: 'N/A'}
      }
      let due = dayjs(this.customer.lastVisit).add(this.customer.stats.tbp, 'days')
      let diffDays = due.diff(dayjs(), 'days')
      return {
        css: diffDays > 0 ? 'is-success' : diffDays > -this.customer.stats.tbp ? 'is-warning' : 'is-danger',
        text: due.format('D MMM')
      }
    }
  }
}
</script>
