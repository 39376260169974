<template lang="pug">
div(v-if="form")
  h2.title.is-4 {{ form.title }}
  h2.subtitle(v-if="form.subtitle") {{ form.subtitle }}
  .p-2(v-for="(field, idx) in form.questions" :key="field._id")
      b-field(v-if="field.type === FIELD_TYPES.TEXT" :type="errors.has(`${field._id}`) ? 'is-danger': ''" :message="errors.has(`${field._id}`) ? errors.first(`${field._id}`) : ''")
        template(#label)
          span {{ field.label }}
          span.has-text-danger(v-if="field.required") *
        b-input(v-model="field.value" v-validate="field.required? 'required': ''" :placeholder="$t('forms.field_placeholder')" :name="`${field._id}`" :data-vv-as="field.label")
      b-field(v-else-if="field.type === FIELD_TYPES.TEXTAREA" :type="errors.has(`${field._id}`) ? 'is-danger': ''" :message="errors.has(`${field._id}`) ? errors.first(`${field._id}`) : ''")
        template(#label)
          span {{ field.label }}
          span.has-text-danger(v-if="field.required") *
        b-input(type="textarea" v-model="field.value" v-validate="field.required? 'required': ''" :placeholder="$t('forms.field_placeholder')" :name="`${field._id}`" :data-vv-as="field.label")
      
      b-field(:label="field.label" v-else-if="field.type === FIELD_TYPES.SELECT")
        b-select(v-model="field.value" v-validate="field.required? 'required': ''" :placeholder="$t('forms.select_placeholder')" :name="`${field._id}`" :data-vv-as="field.label")
          option(v-for="opt in field.options" :key="opt") {{ opt }}
      div(v-else-if="field.type === FIELD_TYPES.RADIO")
        label.label 
          span {{field.label }} 
          span.has-text-danger(v-if="field.required") *
        .mb-2(v-for="opt in field.options" :key="`${field._id}${opt}`")
          b-radio(:native-value="opt" v-model="field.value" v-validate="field.required? 'required': ''" :name="`${field._id}radio`" :data-vv-as="field.label") {{ opt }} 
      div(v-else-if="field.type === FIELD_TYPES.CHECKBOXES")
        label.label 
          span {{field.label }} 
          span.has-text-danger(v-if="field.required") *
        .mb-2(v-for="opt in field.options" :key="`${field._id}${opt}`")
          b-checkbox(:native-value="opt" v-model="field.value" v-validate="field.required? 'required': ''" :name="`${field._id}${opt}`" :data-vv-as="field.label") {{ opt }} 
      b-field(:label="field.label" v-else-if="field.type === FIELD_TYPES.IMAGE")
        SimpleFileUpload(:ref="field._id" v-model="field.value" :uploadNumber="field.uploadNumber")
          
</template>
<script>
import cloneDeep from 'lodash/cloneDeep'
import {mapActions} from 'vuex'
import FIELD_TYPES from '../../../constants/formFieldTypes'
import SimpleFileUpload from '../shared/SimpleFileUpload'
export default {
  name: 'FormRenderer',
  components: {
    SimpleFileUpload
  },
  props: {
    formId: {type: String, required: true},
    customerId: {type: String, default: undefined},
    customerFormId: {type: String, default: undefined}
  },
  data() {
    return {
      form: undefined,
      FIELD_TYPES,
      loading: false
    }
  },
  async created() {
    this.loading = true
    let form = cloneDeep(await this.getFormById(this.formId))
    if (this.customerFormId) {
      this.formData = await this.fetchCustomerFormById(this.customerFormId)
    }

    form.questions.forEach(element => {
      let answer = this.formData && this.formData.answers.find(a => a.field === element._id)

      if (element.type === FIELD_TYPES.CHECKBOXES || element.type === FIELD_TYPES.IMAGE) {
        element.value = (answer && answer.value) || []
      } else {
        element.value = (answer && answer.value) || undefined
      }
    })
    this.form = form
    this.loading = false
  },
  methods: {
    ...mapActions('forms', ['getFormById', 'createCustomerForm', 'updateCustomerForm', 'fetchCustomerFormById']),
    async submit() {
      if (this.submitting) {
        return
      }
      const valid = await this.$validator.validateAll()
      if (!valid) {
        return
      }

      this.submitting = true

      try {
        let uploadPromises = this.form.questions
          .filter(q => q.type === FIELD_TYPES.IMAGE)
          .map(f => {
            return this.$refs[f._id][0].upload()
          })
        await Promise.all(uploadPromises)
        await this.$nextTick()
        let data = this.form.questions.map(q => {
          return {
            field: q._id,
            label: q.label,
            type: q.type,
            value: q.value
          }
        })
        if (this.customerFormId) {
          await this.updateCustomerForm({customerFormId: this.customerFormId, data})
        } else {
          await this.createCustomerForm({formId: this.formId, customerId: this.customerId, data})
        }
      } catch (err) {
        this.$handleAPIError(err)
      }
      this.submitting = false
    }
  }
}
</script>
