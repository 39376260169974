<template lang="pug">
  draggable(tag="div" v-model="services" v-bind="dragOptions" :move="onMove" @start="isDragging=true" @end="onDragEnd" v-if="services.length") 
    ServiceItem(:service="service" v-for="service in services" :key="service._id" data-qa="service-item")
  .notification(v-else style="margin-bottom: 0")
    p.subtitle.has-text-centered.is-4
      span {{$t('services.empty_category')}}
    p.has-text-centered
      action-button(@click="addService" title="services.actions.new_service" permission="SERVICES_ADD")

</template>

<script>
import ServiceItem from './ServiceItem'
import draggable from 'vuedraggable'
import CreateEditServiceForm from './CreateEditServiceForm.vue'

export default {
  name: 'CategoryServices',
  components: {
    ServiceItem,
    draggable
  },
  props: {
    categoryId: {type: String, required: true}
  },
  data() {
    return {
      isDragging: false
    }
  },
  computed: {
    services: {
      get() {
        return this.$store.getters['services/servicesByCategoryId'](this.categoryId)
      },
      set(newValue) {
        return this.$store.dispatch('services/updateServicesOrder', {services: newValue, categoryId: this.categoryId})
      }
    },
    dragOptions() {
      return {
        animation: 150,
        group: 'services',
        ghostClass: 'service-ghost'
      }
    }
  },
  methods: {
    onDragEnd() {
      this.isDragging = false
    },
    addService() {
      this.$buefy.modal.open({
        parent: this,
        props: {categoryId: this.categoryId},
        component: CreateEditServiceForm,
        hasModalCard: true,
        fullScreen: true,
        canCancel: ['escape']
      })
    },
    onMove({relatedContext, draggedContext}) {
      const relatedElement = relatedContext.element
      const draggedElement = draggedContext.element
      return (!relatedElement || !relatedElement.fixed) && !draggedElement.fixed
    }
  }
}
</script>
<style scoped>
.service-ghost {
  opacity: 0.4;
}
</style>
