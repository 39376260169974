<template lang="pug">
b-collapse(animation="slide" :open="open")
  template(#trigger="props") 
    .is-flex.is-align-items-center.mb-2
      .mr-1
        h3.title.is-5 
          span.icon
            i.mdi.mdi-chevron-up(v-if="props.open")
            i.mdi.mdi-chevron-down(v-else)
          span {{ title }}
      .is-flex-shrink-0
        .buttons
          slot(name="actions")
  div.pl-5 
    slot
</template>
<script>
export default {
  name: 'SectionActionTitle',
  props: {
    title: {type: String, required: true},
    open: {type: Boolean, default: true}
  }
}
</script>
