<template lang="pug">
.tags(@click="$rfHasPermission('CLIENTS_EDIT') && openTagEditor()")
  .tag.is-info.has-text-weight-semibold(v-for="tag in customer.tags" :key="tag") {{tag}}
  .tag
    span.icon
      i.mdi.mdi-tag-plus
    span(v-t="'customer.edit_tags'")
</template>

<script>
import CustomerTagsEditor from './CustomerTagsEditor'
export default {
  name: 'CustomerTagsPreview',
  props: {
    customer: {type: Object, required: true}
  },

  methods: {
    openTagEditor() {
      this.$buefy.modal.open({
        parent: this,
        component: CustomerTagsEditor,
        props: {customerId: this.customer._id},
        hasModalCard: true,
        canCancel: ['escape']
      })
    }
  }
}
</script>
<style scoped>
.tags {
  cursor: pointer;
}
</style>
