<template lang="pug">
  .modal-card(data-qa="category-form")
    header.modal-card-head
      p.modal-card-title 
        b {{$t(categoryId ? 'services.category.title_edit': 'services.category.title_new')}}
      div
        a.button.is-white.is-small(@click="$emit('close')")
          span.icon
            i.mdi.mdi-close.mdi-24px
    section.modal-card-body
      b-field(:label="$t('services.category.name_label')" :type="errors.has('name') ? 'is-danger': ''" :message="errors.has('name') ? errors.first('name') : ''")
        b-input(:placeholder="$t('services.category.name_placeholder')" ref="nameInput" data-vv-as="name" name="name" v-validate="'required'" icon="folder-text-outline" v-model="name" data-qa="name")
      b-field(:label="$t('services.category.description_label')" :type="errors.has('description') ? 'is-danger': ''" :message="errors.has('description') ? errors.first('description') : ''")
        b-input(type="textarea" v-model="description" :placeholder="$t('services.category.description_placeholder')" data-qa="description")
      hr
      h4.title.is-4 Chatbot
      //p.subtitle.is-6 Configure how this service category is displayed in the Facebook Messenger chat.
      b-field(:label="$t('services.category.show_in_chat_label')")
        b-switch(v-model="showInChat") {{showInChat ? $t('misc.yes') : $t('misc.no')}}
    footer.modal-card-foot
      .buttons
        action-button(@click="submit" :loading="submitting" title="actions.save" data-qa="action-save") 
        button.button.is-outlined(type="button" @click="$emit('close')") {{$t('actions.cancel')}}
    
</template>

<script>
import {mapActions} from 'vuex'

export default {
  name: 'CreateEditCategoryForm',
  props: {
    categoryId: {type: String, default: undefined}
  },
  data() {
    return {
      name: undefined,
      description: undefined,
      keywords: undefined,
      showInChat: true,
      order: undefined,
      color: undefined,
      submitting: false
    }
  },
  async created() {
    if (this.categoryId) {
      let category = await this.fetchCategory(this.categoryId)
      this.name = category.name
      this.keywords = category.keywords
      this.order = category.order
      this.color = category.color
      this.showInChat = category.showInChat
      this.description = category.description
    }
  },
  mounted() {
    this.$refs.nameInput.focus()
  },
  methods: {
    ...mapActions('services', ['createCategory', 'updateCategory', 'fetchCategory']),
    async submit() {
      if (this.submitting) {
        return
      }
      let valid = await this.$validator.validateAll()
      if (!valid) {
        return
      }
      this.submitting = true
      try {
        if (this.categoryId) {
          await this.updateCategory({
            _id: this.categoryId,
            name: this.name,
            keywords: this.keywords,
            order: this.order,
            color: this.color,
            showInChat: this.showInChat,
            description: this.description
          })
        } else {
          await this.createCategory({
            name: this.name,
            description: this.description
          })
        }
        this.$emit('close')
      } catch (err) {
        this.$handleAPIError(err)
      }
      this.submitting = false
    }
  }
}
</script>
