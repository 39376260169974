<template lang="pug">
div
  b-table(
      :data="data" 
      :loading="loading" 
      hoverable=true 
      striped=true 
      class="is-vertically-aligned" 
      custom-row-key="_id"
      paginated
      backend-pagination
      :total="total"
      :per-page="perPage"
      @page-change="onPageChange"

      backend-sorting
      :default-sort-direction="defaultSortOrder"
      :default-sort="[sortField, sortOrder]"
      @sort="onSort")
      template(slot="empty")
        empty-content(v-if="!loading" title="sms.empty_search_result")
        
      b-table-column(field="type" :label="$t('sms.type_heading')" v-slot="props")
        span.tag.is-primary {{ $t(`sms_type.${props.row.type}`) }} 
        

      b-table-column(field="status" :label="$t('sms.status_heading')" v-slot="props" centered)
        .tag.is-warning(v-if="props.row.remaining === 0") {{$t('validity.used')}}
        .tag.is-danger(v-else-if="new Date(props.row.expires) < new Date()") {{$t('validity.expired')}}
        .tag.is-success(v-else) {{$t('validity.valid')}}

      b-table-column(field="credits" :label="$t('sms.credits_heading')" v-slot="props" sortable numeric)
        number-view(:value="props.row.credits")
      
      b-table-column(field="remaining" :label="$t('sms.remaining_heading')" v-slot="props" sortable numeric)
        number-view(:value="props.row.remaining")

      b-table-column(field="expires" :label="$t('sms.expire_heading')" v-slot="props" sortable)
        date-view(:value="props.row.expires" format="L")

      b-table-column(field="created" :label="$t('ledger.purchased_heading')" sortable v-slot="props") 
        date-view(:value="props.row.created" format="L LT")
      

</template>
<script>
import SMSApi from '@/api/sms'

export default {
  name: 'SMSBundleTransactions',

  data() {
    return {
      data: [],
      loading: false,
      sortField: 'created',
      sortOrder: 'desc',
      total: 0,
      page: 1,
      perPage: 10,
      defaultSortOrder: 'desc'
    }
  },

  async created() {
    await this.fetchTransactions()
  },
  methods: {
    async fetchTransactions() {
      const query = {
        query: this.queryString,
        sortBy: this.sortField,
        sortDir: this.sortOrder,
        page: this.page,
        perPage: this.perPage
      }
      this.loading = true
      try {
        let {data, total} = await SMSApi.getSMSBundles(query)
        this.data = data
        this.total = total
      } catch (err) {
        this.$handleAPIError(err)
      }
      this.loading = false
    },
    async onSort(field, order) {
      this.sortField = field
      this.sortOrder = order
      await this.fetchTransactions()
    },
    async onPageChange(page) {
      this.page = page
      await this.fetchTransactions()
    }
  }
}
</script>
