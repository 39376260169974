<template lang="pug">
  span.tag.is-warning(v-if="churnProbability > 0.5 && churnProbability <=0.85") 
    b {{$t('loyalty.at_risk')}} - {{churnProbability | percentify}} 
  span.tag(v-else-if="churnProbability <= 0.5 && churnProbability >= 0.1") 
    b {{$t('loyalty.unknown')}}
  span.tag.is-danger(v-else-if="churnProbability > 0.85") 
    b {{$t('loyalty.lost')}} - {{churnProbability | percentify}}
  span.tag(v-else-if="churnProbability < 0.1" :class="loyalty.className") 
    b {{$t(`loyalty.${loyalty.status}`)}}
</template>

<script>
import {mapState} from 'vuex'
import customerHelper from '../../../shared/customerHelper'
export default {
  props: {
    customer: {type: Object, required: true}
  },
  computed: {
    ...mapState('company', ['company']),
    churnProbability() {
      return customerHelper.calculateChurnProb(this.customer, this.company)
    },
    loyalty() {
      switch (this.customer.visits) {
        case 0:
          return {status: 'prospect', className: 'is-info'}
        case 1:
          return {status: 'new'}
        case 2:
        case 3:
        case 4:
          return {status: 'repeat', className: 'is-success'}
        default:
          return {status: 'loyal', className: 'is-success'}
      }
    }
  }
}
</script>
