import Vue from 'vue'

export default {
  create(data) {
    return Vue.http.post('appointments', data).then(res => res.body)
  },
  update(appointmentId, data) {
    return Vue.http.put(`appointments/${appointmentId}`, data).then(res => res.body)
  },
  editNotes(appointmentId, notes) {
    return Vue.http.put(`appointments/${appointmentId}/notes`, {notes}).then(res => res.body)
  },
  changeStatus(appointmentId, status) {
    return Vue.http.put(`appointments/${appointmentId}/status`, {status}).then(res => res.body)
  },
  blockTime(data) {
    return Vue.http.post(`appointments/block`, data).then(res => res.body)
  },
  removeTimeBlock(id) {
    return Vue.http.delete(`appointments/block/${id}`).then(res => res.body)
  },
  terminate(appointmentId, data) {
    return Vue.http.put(`appointments/${appointmentId}/terminate`, data).then(res => res.body)
  },
  recover(appointmentId) {
    return Vue.http.put(`appointments/${appointmentId}/recover`).then(res => res.body)
  },
  list(params) {
    return Vue.http.get('appointments', {params}).then(res => res.body)
  },
  listExpanded(params) {
    return Vue.http.get('appointments/expanded', {params}).then(res => res.body)
  },
  getBookedSlotsByStaff(params) {
    return Vue.http.get('appointments/booked', {params}).then(res => res.body)
  },
  getStaffAvailableRanges(params) {
    return Vue.http.get('appointments/staffAvailableRanges', {params}).then(res => res.body)
  },
  getBookedSlotsByResource(params) {
    return Vue.http.get('appointments/bookedResources', {params}).then(res => res.body)
  },
  getById(appointmentId) {
    return Vue.http.get(`appointments/${appointmentId}`).then(res => res.body)
  },
  getLastAppointment(customerId, status) {
    return Vue.http.get(`appointments/last/${customerId}`, {params: {status}}).then(res => res.body)
  }
}
