<template lang="pug">
span(v-if="!skipNew || status !== 'new'" :class="[isTag ? 'tag is-rounded  ' : '', colorClass, tagSize]")
  span.icon(v-if="!showText")
    i.mdi(:class="[!isTag ? 'has-text-' + colorClass : '', 'mdi-' + icon]")
  | &nbsp;
  b.px-1(v-if="showText" style="text-transform: uppercase" v-t="`appointment_status.${status}`")
</template>

<script>
import APPT_STATUS_VISUALS from './appointmentStatusVisuals'

export default {
  props: {
    status: {type: String, required: true},
    showText: {type: Boolean, default: false},
    isTag: {type: Boolean, default: false},
    tagSize: {type: String, default: undefined},
    nocolor: {type: Boolean, default: false},
    skipNew: {type: Boolean, default: false}
  },
  computed: {
    colorClass() {
      if (this.nocolor) {
        return
      }
      return 'is-' + (APPT_STATUS_VISUALS[this.status].iconColor || APPT_STATUS_VISUALS[this.status].color)
    },
    icon() {
      return APPT_STATUS_VISUALS[this.status].icon
    }
  }
}
</script>
