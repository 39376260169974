import Vue from 'vue'
import {
  SET_RESOURCES,
  UPDATE_RESOURCE,
  RESET_RESOURCE,
  SET_RESOURCES_INITIALIZED,
  SET_RESOURCE,
  SET_RESOURCES_ORDER,
  SET_RESOURCES_LOADING
} from './mutationTypes'
import ResourceApi from '@/api/resources'
let __listPromise

export default {
  namespaced: true,
  state: {
    resourcesMap: {},
    resourcesOrder: [],
    initialized: false,
    loading: false
  },
  getters: {
    resources(state) {
      return state.resourcesOrder.map(c => state.resourcesMap[c]).sort((a, b) => (a.order || 0) - (b.order || 0))
    },
    hasResources(state) {
      return !!state.resourcesOrder.length
    },
    resourcesByLocation: state => locationId => {
      return state.resourcesOrder
        .map(c => state.resourcesMap[c])
        .filter(c => c.location === locationId)
        .sort((a, b) => (a.order || 0) - (b.order || 0))
    }
  },
  mutations: {
    [SET_RESOURCES_INITIALIZED](state, val) {
      state.initialized = val
    },
    [SET_RESOURCES](state, resources) {
      const mapped = {}
      for (const resource of resources) {
        mapped[resource._id] = resource
      }
      state.resourcesMap = mapped
      state.resourcesOrder = resources.map(c => c._id)
    },
    [UPDATE_RESOURCE](state, resource) {
      if (state.resourcesMap[resource._id]) {
        state.resourcesMap[resource._id] = resource
      } else {
        Vue.set(state.resourcesMap, resource._id, resource)
      }
    },
    [SET_RESOURCE](state, resource) {
      state.resourcesMap[resource._id] = resource
      state.resourcesOrder.push(resource._id)
    },
    [SET_RESOURCES_ORDER](state, order) {
      order.forEach((id, idx) => (state.resourcesMap[id].order = idx))
    },
    [RESET_RESOURCE](state) {
      state.resourcesMap = {}
      state.resourcesOrder = []
      state.initialized = false
    },
    [SET_RESOURCES_LOADING](state, loading) {
      state.loading = loading
    }
  },
  actions: {
    async fetchResources({commit, state}, force) {
      if (__listPromise) {
        return __listPromise
      }
      if (!state.initialized || force) {
        commit(SET_RESOURCES_LOADING, true)
        try {
          __listPromise = ResourceApi.getResources()
          const resources = await __listPromise
          __listPromise = undefined
          commit(SET_RESOURCES, resources)
          commit(SET_RESOURCES_INITIALIZED, true)
          commit(SET_RESOURCES_LOADING, false)
        } catch (err) {
          commit(SET_RESOURCES_LOADING, false)
          throw err
        }
      }
    },
    async createResource({dispatch, state}, data) {
      data.order = state.resourcesOrder.length
      const resource = await ResourceApi.createResource(data)
      await dispatch('fetchResources', true)
      return resource
    },
    async updateResource({commit}, {id, data}) {
      const resource = await ResourceApi.updateResource(id, data)
      commit(UPDATE_RESOURCE, resource)
      return resource
    },
    async getById({commit, state}, id) {
      if (!state.resourcesMap[id]) {
        const resource = await ResourceApi.getResourceById(id)
        commit('SET_RESOURCE', resource)
      }
      return state.resourcesMap[id]
    },
    async updateResourcesOrder({commit}, order) {
      commit(SET_RESOURCES_ORDER, order)
      await ResourceApi.updateOrder(order)
    },
    reset({commit}) {
      commit(RESET_RESOURCE)
    }
  }
}
