<template lang="pug">
  div
    screen-title(title="services.bulk_edit_title")
    .box
      em-loading(:active="loading" :is-full-page="false")
      b-field(:label="$t('services.language_label')")
        b-select(v-model="currentLng")
          option(v-for="lng in company.languages" :key="lng" :value="lng") {{$t(`languages.${lng}`)}} {{lng === company.defaultLanguage ? '(default)': ''}}
      .bodx.mb-5(v-for="cat in categoriesList" :key="cat._id")
        table.table.is-fullwidth
          thead
            tr
              th.is-size-5.is-borderless
                span {{cat.name}}
              td.is-size-5.is-borderless
                ContentEditable(v-model="cat.names[currentLng]" :placeholder="cat.name || $t('services.category_name_placeholder')")
              td.is-borderless
                ContentEditable(v-model="cat.descriptions[currentLng]" :placeholder="cat.description || $t('services.category_description_placeholder')")
            tr
              th(width="25%" v-t="'services.service_name_heading'")
              th(width="35%" v-t="'services.service_name_trans_heading'")
              th(width="40%" v-t="'services.service_desc_trans_heading'")
          tbody
            tr(v-for="service in servicesByCategoryId(cat._id)" :key="service._id")
              th
                span {{services[service._id].name}}
              td
                ContentEditable(v-model="services[service._id].names[currentLng]" :placeholder="services[service._id].name || $t('services.service_name_placeholder')")
                .list.mt-3.is-shadowless(style="border:1px solid #dedede" v-if="services[service._id].pricingOptions.length > 1")
                  .list-item(v-for="option in services[service._id].pricingOptions"  :key="option._id")
                    .columns.is-mobile
                      .column.is-3
                        currency-view(:value="option.price")
                      .column.is-3
                        duration-view(:value="option.duration")
                      .column.is-6
                        ContentEditable(v-model="option.names[currentLng]" :placeholder="option.name")
              td
                ContentEditable(v-model="services[service._id].descriptions[currentLng]" :placeholder="services[service._id].description || $t('services.service_description_placeholder')")
        hr.is-narrow
    .field
      .buttons
        action-button(title="actions.save" @click="submit" :loading="submitting" permission="SERVICES_EDIT" scope)
</template>
<script>
import cloneDeep from 'lodash/cloneDeep'
import {mapActions, mapGetters, mapState} from 'vuex'
import ContentEditable from '../shared/ContentEditable'

export default {
  components: {
    ContentEditable
  },
  data() {
    return {
      loading: false,
      submitting: false,
      services: {},
      categoriesList: [],
      currentLng: undefined
    }
  },
  computed: {
    ...mapState('company', ['company']),
    ...mapState('services', ['servicesMap']),
    ...mapGetters('services', ['categories', 'servicesByCategoryId'])
  },
  async created() {
    this.currentLng = this.company.defaultLanguage
    await this.fetch()
  },
  methods: {
    ...mapActions('services', ['list', 'removeCategory', 'bulkUpdate']),
    async fetch() {
      this.loading = true
      try {
        await this.list(true)

        this.categoriesList = cloneDeep(this.categories).map(c => {
          c.names = c.names || {}
          c.names[this.company.defaultLanguage] = c.names[this.company.defaultLanguage] || c.name
          c.descriptions = c.descriptions || {}
          c.descriptions[this.company.defaultLanguage] = c.descriptions[this.company.defaultLanguage] || c.description
          return c
        })

        let services = cloneDeep(this.servicesMap)
        for (let sId in services) {
          let service = services[sId]
          service.names = service.names || {}
          service.names[this.company.defaultLanguage] = service.names[this.company.defaultLanguage] || service.name
          service.descriptions = service.descriptions || {}
          service.descriptions[this.company.defaultLanguage] =
            service.descriptions[this.company.defaultLanguage] || service.description

          service.pricingOptions.forEach(o => {
            o.names = o.names || {}
            o.names[this.company.defaultLanguage] = o.names[this.company.defaultLanguage] || o.name
          })
        }
        this.services = services
      } catch (err) {
        this.$handleAPIError(err)
      }
      this.loading = false
    },
    async submit() {
      this.submitting = true
      try {
        let services = Object.values(this.services).map(s => {
          return {
            ...s,
            name: s.names[this.company.defaultLanguage],
            description: s.descriptions[this.company.defaultLanguage],
            pricingOptions: s.pricingOptions.map(o => {
              return {
                ...o,
                name: o.names[this.company.defaultLanguage]
              }
            })
          }
        })
        let categories = this.categoriesList.map(s => {
          return {
            ...s,
            name: s.names[this.company.defaultLanguage],
            description: s.descriptions[this.company.defaultLanguage]
          }
        })
        await this.bulkUpdate({services, categories})
        this.$buefy.toast.open({
          message: this.$t('status.save_success'),
          type: 'is-success'
        })
        await this.fetch()
      } catch (err) {
        this.$handleAPIError(err)
      }
      this.submitting = false
    },

    editService(service) {
      this.$router.push({name: 'editService', params: {serviceId: service._id}})
    }
  }
}
</script>
<style scoped>
table.table {
  background: transparent;
}
.is-borderless {
  border: none !important;
}
table.table th {
  cursor: default;
}
table.table td {
  background: white;
  cursor: text;
}
</style>
