<template lang="pug">
  .columns.is-multiline(v-if="amenities")
    .column.is-6(v-for="(value, key) in amenities")
      .field
        b-checkbox(v-model="selectedAmenities" :native-value="key" @input="onChange") {{$t(`amenities.${key}`)}}
</template>

<script>
import StaticApi from '@/api/static'
import {mapState} from 'vuex'

export default {
  name: 'LocationAmenitiesDetails',
  props: {
    value: {type: Array, default: () => []}
  },
  data() {
    return {
      amenities: undefined,
      selectedAmenities: []
    }
  },
  computed: {
    ...mapState('company', ['company'])
  },
  watch: {
    value(value) {
      if (value) {
        this.selectedAmenities = value
      }
    }
  },
  async created() {
    if (this.value) {
      this.selectedAmenities = this.value
    }
    await this.fetchDetails()
  },
  methods: {
    async fetchDetails() {
      try {
        this.amenities = await StaticApi.getAmenities()
      } catch (err) {
        this.$handleAPIError(err)
      }
    },
    onChange() {
      this.$emit('input', this.selectedAmenities)
    }
  }
}
</script>
