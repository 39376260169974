<template lang="pug">
span {{ addressLine }}
</template>
<script>
export default {
  name: 'AddressView',
  props: {
    address: {type: Object, required: true}
  },
  computed: {
    addressLine() {
      const address = this.address
      if (!address) {
        return
      }
      if (address.streets && address.streets[this.$i18n.locale]) {
        return address.streets[this.$i18n.locale]
      }
      const line = []
      address.street && line.push(address.street)
      address.streetAlt && line.push(address.streetAlt)
      address.city && line.push(address.city)
      return line.join(' ')
    }
  }
}
</script>
