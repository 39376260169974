import Vue from 'vue'

export default {
  getLocations() {
    return Vue.http.get('locations').then(res => res.body)
  },
  updateLocation(id, data) {
    return Vue.http.put(`locations/${id}`, data).then(res => res.body)
  },
  updateTaxDefaults(id, data) {
    return Vue.http.put(`locations/${id}/taxDefaults`, data).then(res => res.body)
  },
  createLocation(data) {
    return Vue.http.post('locations', data).then(res => res.body)
  },
  getLocationById(id) {
    return Vue.http.get(`locations/${id}`).then(res => res.body)
  }
}
