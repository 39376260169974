module.exports = {
  SUCCESS: 'success',
  DRAFT: 'draft',
  PENDING: 'pending',
  STATUS_CHECK: 'status_check',
  FAILED: 'failed',
  DECLINED: 'declined',
  REFUNDED: 'refunded',
  CANCELED: 'canceled',
  AUTHORIZED: 'authorized'
}
