<template lang="pug">
  span(v-if="hasMultipleLocations")
    span(v-if="settings === 'manual'" v-t="'tax_settings.custom_tax'") 
    span(v-else-if="settings === 'notax'" v-t="'tax_settings.no_tax'")
    span(v-else-if="settings === 'default'" v-t="'tax_settings.default_tax'") 
  span(v-else) 
    tax-view(:value="value && value[activeLocations[0]._id]")
</template>
<script>
import {mapGetters} from 'vuex'
import {fromPairs} from 'lodash'

export default {
  name: 'TaxMappingView',
  props: {
    value: {type: Object, default: undefined},
    type: {
      type: String,
      required: true,
      validator: value => {
        return ['services', 'products'].indexOf(value) !== -1
      }
    }
  },
  computed: {
    ...mapGetters('location', ['activeLocations', 'hasMultipleLocations']),
    taxDefaults() {
      return fromPairs(this.activeLocations.map(l => [l._id, l.taxDefaults && l.taxDefaults[this.type]]))
    },

    settings() {
      let isDefined = false
      for (let location of this.activeLocations) {
        let currentValue = (this.value && this.value[location._id]) || undefined
        let defaultValue = this.taxDefaults[location._id] || undefined
        isDefined = isDefined || !!currentValue
        if (defaultValue !== currentValue) {
          return 'manual'
        }
      }
      if (isDefined) {
        return 'default'
      }
      return 'notax'
    }
  }
}
</script>
