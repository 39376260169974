<template lang="pug">
div
  .columns.is-multiline
    .column.is-6(v-for="resource in availableResources" :key="resource._id")
      b-checkbox(v-model="selected" size="is-medium" :native-value="resource._id" @input="input" data-qa="service-resource" :disabled="($rfLocationScope() || $rfProfileScope()) && user.location !== resource.location") 
        p.title.is-6 {{resource.name}}
        p.subtitle.is-6 
          location-view(:value="resource.location")
</template>
<script>
import {mapGetters, mapState} from 'vuex'
import {isEqual} from 'lodash'
export default {
  name: 'MultipleResourceSelector',
  props: {
    value: {type: Array, default: undefined},
    locations: {type: Array, required: true}
  },
  data() {
    return {
      selected: []
    }
  },
  computed: {
    ...mapGetters('resources', ['resources']),
    ...mapState('auth', ['user']),
    allSelected() {
      return this.selected.length === this.resources.length ? true : this.selected.length === 0 ? false : undefined
    },
    availableResources() {
      return this.resources.filter(r => this.locations.includes(r.location))
    }
  },
  watch: {
    value() {
      if (isEqual(this.selected, this.value)) {
        return
      }
      this.setSelected()
    },
    locations() {
      this.filterSelected()
    }
  },
  async created() {
    this.setSelected()
  },
  methods: {
    setSelected() {
      if (this.value) {
        this.selected = this.value.slice()
        this.filterSelected()
      }
      this.input()
    },
    input() {
      this.$emit('input', this.selected)
    },
    filterSelected() {
      this.selected = this.selected.filter(id => !!this.availableResources.find(a => a._id === id))
    }
  }
}
</script>
