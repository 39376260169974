<template lang="pug">
  span(v-if="location") {{addressLine}}
  span.has-text-grey(v-else v-t="'locations_settings.no_address'")
</template>
<script>
import {mapActions} from 'vuex'
import locationHelper from '../../../shared/locationHelper'
export default {
  name: 'LocationAddressView',
  props: {
    locationId: {type: String, required: true}
  },
  data() {
    return {
      location: undefined
    }
  },
  computed: {
    addressLine() {
      if (!this.location) {
        return
      }
      return locationHelper.getFullAddress(this.location)
    }
  },
  async created() {
    this.location = await this.getById(this.locationId)
  },
  methods: {
    ...mapActions('location', ['getById'])
  }
}
</script>
