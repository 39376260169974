<template lang="pug">
.list.notification.p-0(v-if="viewMode === 'view'")
  .list-item.is-flex.is-justify-content-space-between.is-align-items-center
    div
      p.is-size-6
        span.icon.is-medium.mr-3
          img(:src="logo")
        |  
        b  ···· {{ paymentMethod.last4 }}
    div  
      button.button.is-outlined.is-dark(@click="editMode = true") {{ $t('billing.actions.update_card') }}
.list.notification.p-0(v-else) 
  .list-item.p-5
    .notification.is-danger(v-if="errorMsg")
      p {{errorMsg}}
    b-field(:label="$t('billing.update_payment_method.card_holder_label')" :type="errors.has('name') ? 'is-danger': ''" :message="errors.has('name') ? errors.first('name') : ''")
      b-input(v-model="name" v-validate="'required'" size="is-medium" name="name" :placeholder="$t('billing.update_payment_method.card_holder_placeholder')")
    credit-card-input.mb-4(@complete="onComplete")
    .buttons.mb-4(v-if="!needPayment")
      button.button.is-black.is-outlined.is-rounded(@click="editMode = false") 
        span.mr-3 Use   
        span.tag
          span.icon.is-medium.mr-3
            img(:src="logo")
          |  
          b  ···· {{ paymentMethod.last4 }}
    .field.help.has-text-centered
      p
        img(src="/img/powered_by_stripe@2x.png" style="height:20px")
  // .bordered-box.notification.is-white(v-else)
  //   h3.title.is-5(v-t="'billing.payment_method_title'")
  //   p.is-size-6(v-t="'billing.no_card_attached'")
  //   br
  //   .field 
  //     button.button.is-outlined.is-dark(@click="updatePaymentMethod" :class="chargable ? 'is-dark' : 'is-light' ") {{ $t('billing.actions.attach_card') }}
</template>

<script>
import {mapActions, mapState} from 'vuex'

import {createToken} from 'vue-stripe-elements-plus'
import CreditCardInput from './CreditCardInput'

export default {
  name: 'PaymentMethods',
  components: {
    CreditCardInput
  },
  data() {
    return {
      name: undefined,
      complete: false,
      editMode: false,
      errorMsg: undefined
    }
  },
  computed: {
    ...mapState('billing', ['paymentMethod', 'subscription']),
    viewMode() {
      if (this.needPayment) {
        return 'cardinput'
      }
      if (this.editMode) {
        return 'cardinput'
      }
      return 'view'
    },
    needPayment() {
      if (!this.paymentMethod) {
        return true
      }
      if (
        this.subscription &&
        (this.subscription.status === 'incomplete' ||
          this.subscription.status === 'incomplete_expired' ||
          this.subscription.status === 'past_due')
      ) {
        return true
      }
      return false
    },
    logo() {
      switch (this.paymentMethod.brand.toLowerCase()) {
        case 'visa':
          return require('@/assets/visa.svg')
        case 'mastercard':
          return require('@/assets/mastercard.svg')
        case 'ame':
          return require('@/assets/american_express.svg')
        default:
          return require('@/assets/mastercard.svg')
      }
    }
  },
  created() {
    this.fetch(true)
  },
  methods: {
    ...mapActions('billing', ['fetch']),
    async submit() {
      if (this.viewMode === 'view') {
        return this.$emit('input')
      }
      let valid = await this.$validator.validateAll()
      if (!valid || !this.complete) {
        throw new Error('incomplete form')
      }

      try {
        let data = await createToken({name: this.name})
        if (!data.token) {
          throw new Error('Unknown error ocurred with the card')
        }

        this.$emit('input', data)
      } catch (err) {
        if (err.body && err.body && err.body.code === 'INVALID_CARD') {
          this.errorMsg = err.body.message
          if (err.body.additionalMessage) {
            this.errorMsg += ' ' + err.body.additionalMessage
          }
        } else {
          this.$handleAPIError(err)
        }
        throw err
      }
    },
    onComplete(complete) {
      this.complete = complete
    }
  }
}
</script>
