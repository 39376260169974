<template lang="pug">
.tile
  .tile.is-parent
    .tile.is-child.box
        h3.title.is-6.is-uppercase(v-t="'rewards.unused_points_title'")
        h5.subtitle.is-7 {{period && period.display}}
        p.title.is-2.is-size-3-mobile 
          percentage-view(:value="outstanding/(collected + collectedOther)")
          //rewards-view(:value="outstanding" :currency="true")
        .list-item.px-0 {{ $t('rewards.from_sales_label') }} 
          b.is-pulled-right
            rewards-view(:value="collected" :currency="true")
        .list-item.px-0 {{ $t('rewards.gifted_label') }} 
          b.is-pulled-right
            rewards-view(:value="gift" :currency="true")
        .list-item.px-0 {{ $t('rewards.referrals_label') }} 
          b.is-pulled-right
            rewards-view(:value="referral" :currency="true")
        .list-item.px-0 {{ $t('rewards.referral_signups_label') }} 
          b.is-pulled-right
            rewards-view(:value="signup" :currency="true")
        .list-item.px-0 {{ $t('rewards.promotions_label') }} 
          b.is-pulled-right
            rewards-view(:value="promo" :currency="true")

  .tile.is-parent
    .tile.is-child.box
        h3.title.is-6.is-uppercase(v-t="'rewards.effective_discount_title'")
        h5.subtitle.is-7 {{period && period.display}}
        p.title.is-2.is-size-3-mobile
          percentage-view(:value="effectiveDiscount" :precision="2") 
          //rewards-view(:value="-redeemed")
        
        .list-item.px-0 {{ $t('rewards.collected_sales_label') }} 
          b.is-pulled-right
            rewards-view(:value="collected" :currency="true")
        .list-item.px-0 {{ $t('rewards.collected_other_label') }} 
          b.is-pulled-right
            rewards-view(:value="collectedOther" :currency="true")
        .list-item.px-0 {{ $t('rewards.redeemed_label') }} 
          b.is-pulled-right
            rewards-view(:value="redeemed" :currency="true")
        .list-item.px-0 {{ $t('rewards.outstanding_label') }} 
          b.is-pulled-right
            rewards-view(:value="outstanding" :currency="true")
        .list-item.px-0 {{ $t('rewards.base_sales_label') }} 
          b.is-pulled-right
            currency-view(:value="sales")
</template>

<script>
import ReportsApi from '@/api/reports'
import {mapState} from 'vuex'

export default {
  name: 'PointsBalance',
  props: {
    period: {type: Object, default: undefined},
    location: {type: String, default: undefined}
  },
  data() {
    return {
      loading: false,
      collected: 0,
      collectedOther: 0,
      redeemed: 0,
      outstanding: 0,
      sales: 0,
      gift: 0,
      referral: 0,
      signup: 0,
      promo: 0,
      effectiveDiscount: 0
    }
  },
  computed: {
    ...mapState('company', ['company'])
  },
  watch: {
    period() {
      return this.fetchData()
    },
    location() {
      return this.fetchData()
    }
  },
  created() {
    if (this.period) {
      return this.fetchData()
    }
  },
  methods: {
    async fetchData() {
      this.loading = true
      try {
        const data = await ReportsApi.getPointsBalance({
          from: this.period.from,
          to: this.period.to,
          location: this.location
        })

        this.collected = data.collect
        this.gift = data.gift
        this.referral = data.referral
        this.signup = data.signup
        this.promo = data.promo
        this.collectedOther = data.gift + data.promo + data.referral + data.signup
        this.redeemed = -data.redeem
        this.sales = data.sales
        this.outstanding = this.collected - this.redeemed
        this.effectiveDiscount = Math.abs(this.redeemed / this.company.membership.currencyWorth / this.sales)
      } catch (error) {
        this.$handleAPIError(error)
      }

      this.loading = false
    }
  }
}
</script>
