<template lang="pug">
  vue-tel-input(v-model="phone" ref="phoneInput" @input="onInput" :disabled="isFetching" mode="national" :styleClasses="wrapperClasses" :inputOptions="inputOptions" :defaultCountry="company && company.country")
</template>

<script>
import CustomersApi from '@/api/customers'
import {mapState} from 'vuex'

export default {
  props: {
    value: {type: String, default: undefined},
    fetchCustomer: {type: Boolean, default: false},
    size: {type: String, default: undefined},
    placeholder: {type: String, default: 'phone number'}
  },
  data() {
    return {
      phone: undefined,
      valid: undefined,
      phoneE164: undefined,
      isFetching: false,
      inputOptions: {
        placeholder: undefined,
        styleClasses: ['phoneInput', 'input']
      }
    }
  },

  computed: {
    ...mapState('company', ['company']),
    wrapperClasses() {
      if (this.valid === false) {
        return ['phoneInputWrapper', 'is-danger']
      }
      return 'phoneInputWrapper'
    }
  },
  watch: {
    value(val) {
      if (this.phoneE164 && this.phoneE164 === val) {
        return
      }
      this.phone = val
      // if (!val && this.phone && this.valid) {
      //   this.phone = this.phone.substring(0, this.phone.indexOf(' '))
      // }
    },
    placeholder() {
      this.inputOptions = {
        placeholder: this.placeholder,
        styleClasses: ['phoneInput', 'input']
      }
    }
  },
  created() {
    this.inputOptions.placeholder = this.placeholder
    this.phone = this.value
    if (this.size) {
      this.inputOptions.styleClasses.push(this.size)
    }
  },
  methods: {
    async onInput(data, {valid, number}) {
      this.valid = data ? valid : undefined
      if (!valid) {
        this.$emit('input', this.phone, valid)

        return
      }
      this.phoneE164 = number

      let customer
      if (this.fetchCustomer) {
        customer = await this.searchCustomer(this.phoneE164)
      }
      this.$emit('input', this.phoneE164, valid, customer)
    },
    async searchCustomer(phone) {
      this.isFetching = true
      try {
        let customers = await CustomersApi.findByPhone(phone)
        this.isFetching = false
        if (!customers.length) {
          return null
        }
        return customers[0]
      } catch (err) {
        this.isFetching = false
      }
    },
    focus() {
      this.$refs.phoneInput.$refs.input.focus()
    }
  }
}
</script>
