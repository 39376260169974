<template lang="pug">
b-dropdown(v-model="selected" @input="update") 
  template(#trigger)
    button.button.is-primary.is-outlined
      span.icon
        i.mdi(:class="iconSet[selected]")
      span {{ $t(`form_field_types.${selected}`) }}
      span.icon
        i.mdi.mdi-chevron-down
  b-dropdown-item(v-for="fieldType in fieldTypes" :value="fieldType" :key="fieldType")
    span.icon
      i.mdi(:class="iconSet[fieldType]")
    span {{ $t(`form_field_types.${fieldType}`) }}
</template>

<script>
import FIELD_TYPES from '../../../constants/formFieldTypes'
export default {
  name: 'FieldTypeDropdown',
  props: {
    value: {type: String, required: true}
  },
  data() {
    return {
      selected: undefined,
      fieldTypes: Object.values(FIELD_TYPES),
      iconSet: {
        [FIELD_TYPES.CHECKBOXES]: 'mdi-checkbox-marked-outline',
        [FIELD_TYPES.IMAGE]: 'mdi-image-outline',
        [FIELD_TYPES.RADIO]: 'mdi-circle-slice-8',
        [FIELD_TYPES.SELECT]: 'mdi-arrow-down-drop-circle-outline',
        [FIELD_TYPES.TEXT]: 'mdi-text-short',
        [FIELD_TYPES.TEXTAREA]: 'mdi-text-long'
      }
    }
  },
  created() {
    this.selected = this.value
  },
  methods: {
    update() {
      this.$emit('input', this.selected)
    }
  }
}
</script>
