<template lang="pug">
div
  p.title(:class="size === 'medium' ? 'is-5' : 'is-6'") 
    span(v-if="item.type === SALE_ITEM_TYPES.SERVICE")
      service-name-view(:value="{service: item.service, option: item.option}") 
      span  x  {{item.qty}}
    span(v-else-if="item.type === SALE_ITEM_TYPES.PRODUCT && product")
      product-name-view(:value="item.product")
      |  
      stock-view(:amount="item.qty" :unit="product.unit")
    span(v-else-if="item.type === SALE_ITEM_TYPES.VOUCHER") 
      voucher-name-view.has-text-underline(:value="item.voucher")
      span  x  {{item.qty}}
    span(v-else-if="item.type === SALE_ITEM_TYPES.MEMBERSHIP") 
      membership-package-name-view.has-text-underline(:value="item.membershipPackage")
      span  x {{item.qty}}
    span.has-text-grey.is-contrasted(v-else v-t="'sale.no_item_label'")
    
  p.subtitle.has-text-grey.is-contrasted(:class="size === 'medium' ? 'is-6' : 'is-7'")
    span(v-if="item.duration && $rfSettings().services.duration")
      duration-view(:value="item.duration") 
      |  ·  
    template(v-if="item.staff")
      staff-view(:id="item.staff" :preferred="item.preferred")
      |  ·  
    template(v-if="item.resource")
      resource-view(:value="item.resource" :preferred="item.preferredResource")
      |  ·  
    template(v-if="item.price !== undefined")
      currency-view(:value="item.price")
</template>

<script>
import {mapActions} from 'vuex'
import SALE_ITEM_TYPES from '../../../constants/saleItemTypes'

export default {
  name: 'SaleItem',
  props: {
    item: {type: Object, required: true},
    size: {type: String, default: 'small'}
  },
  data() {
    return {
      SALE_ITEM_TYPES,
      product: undefined
    }
  },
  async created() {
    if (this.item.product) {
      this.product = await this.fetchProduct(this.item.product)
    }
  },
  methods: {
    ...mapActions('inventory', ['fetchProduct'])
  }
}
</script>
<style scoped>
.title,
.subtitle {
  color: inherit;
}
</style>
