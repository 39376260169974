<template lang="pug">
  span(v-if="currentLocation") {{currentLocation && currentLocation.title}}
  span.has-text-grey(v-else-if="currentLocation === null") 
    i(v-t="'location.location_not_available'")
</template>
<script>
import {mapState} from 'vuex'
export default {
  name: 'LocationView',
  props: {
    value: {type: String, required: true}
  },

  computed: {
    ...mapState('location', ['locationsMap']),
    currentLocation() {
      return this.locationsMap[this.value]
    }
  }
}
</script>
