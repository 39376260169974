<template lang="pug">
  doctype html
  fullscreen-modal(title="checkout_popup.title" @close="$emit('close')" data-qa="checkout-popup")
    template(v-slot:footer)
      .bottom-navbar
        div(style="width: 100%; padding: 0.5rem")
            p.is-size-5.has-text-centered(style="margin-bottom: 0.5rem") 
              b {{$t('checkout_popup.due_label')}} 
                currency-view(:value="due")
            .buttons.is-centered(style="width: 100%")
              button.button.is-medium.is-success(type="submit", @click="confirm" :disabled="(!customer && !phoneValid) || submitting" :class="{'is-loading': submitting}" data-qa="action-checkout") 
                span.icon
                  i.mdi.mdi-basket-outline
                span {{$t('checkout_popup.actions.checkout')}}   
                | &nbsp;
                strong
                  currency-view(:value="totalSale || 0")
    .columns.is-centered
      .column.is-8
        section.section
          .container
            form(v-on:submit.prevent="confirm")
              label.label {{$t('checkout_popup.customer_label')}}
              .box
                .field(v-if="!customer && !phoneValid")
                  phone-input(v-model="phone" @input="onPhoneInput" :placeholder="$t('checkout_popup.phone_placeholder')" :fetch-customer="true" ref="phoneInput" size="is-medium" data-qa="phone")
                .field(v-if="!customer && phoneValid" style="position: relative")
                  p.is-size-5 {{$t('checkout_popup.new_customer_placeholder')}}
                    phone-view(:value="phone" size="is-large")
                  button.delete(type="button" @click="resetCustomer")
                customer-preview(v-if="customer" :customerId="customer._id" @delete="resetCustomer" :deletable="true")
              .notification.is-danger.is-hidden-mobile(v-if="customer && !customer.memberSince && $rfSubscribed('rewards')" style="margin-top:10px") {{customer.firstName}} is not a member. Kindly ask customer to use "Membership" button from the Messenger menu! 
              div(v-if="customer || phoneValid")
                .box
                  b-field(:label="$t('checkout_popup.sale_label')" :type="errors.has('totalSale') ? 'is-danger': ''" :message="errors.has('totalSale') ? errors.first('totalSale') : ''" expanded )
                    b-input(ref="totalSaleInput" type="number" :placeholder="$t('checkout_popup.sale_placeholder')" v-model.number="totalSale" name="totalSale" v-validate="'required|min_value:0'" data-vv-as="total sale" icon="inbox-arrow-down" size="is-medium" data-qa="total-price")
                  b-field(v-if="customer" :label="$t('checkout_popup.redeem_label')" :type="errors.has('redeemAmount') ? 'is-danger': ''" :message="errors.has('redeemAmount') ? errors.first('redeemAmount') : ''" expanded )
                    b-input(ref="redeemAmountInput" type="number" :placeholder="$t('checkout_popup.redeem_placeholder', {available: availableForRedeem})" v-model.number="redeemAmount" name="redeemAmount" v-validate="redeemRules" data-vv-as="redeem amount" icon="inbox-arrow-up" size="is-medium" data-qa="redeem-amount")
                .box(v-if="customer && hasCoupons")
                  .level.is-mobile(v-if="!customerCoupon")
                    .level-left
                      .level-item
                        h3.is-size-5(v-t="'sale.apply_coupon'")
                    .level-right
                      .level-item
                        button.button.is-pulled-right.is-primary(@click.stop="selectCoupon" v-t="'actions.select'")  
                  .level.is-mobile(v-else)
                    .level-left
                      .level-item
                        div
                          p.heading {{$t(`coupons.${customerCoupon.coupon.type}`)}}
                          p.title.is-5 {{customerCoupon.coupon.title}}
                    .level-right
                      .level-item
                        div
                          span.is-pulled-right
                            a(@click="customerCoupon = undefined")
                              span.icon.has-text-danger
                                i.mdi.mdi-close
                div(v-if="followups.length")
                  label.label {{$t('checkout_popup.followup_reminder_label')}}
                  .box
                    b-field(:type="errors.has('followup') ? 'is-danger': ''" :message="errors.has('followup') ? errors.first('followup') : ''")
                      b-select(v-model="followup" expanded ref="followupInput" v-validate="'required'" name="followup" :placeholder="$t('checkout_popup.followup_reminder_placeholder')" icon="alarm")
                        option(v-for="followup in followups" :value="followup._id") {{followup.name}} ({{followup.duration}} {{followup.durationUnit}})
                        option(:value="-1") {{$t('checkout_popup.no_reminder_option')}}
              div(v-show="customer || phoneValid")
                br
                template(v-if="hasServiceStaff")
                  label.label {{$t('checkout_popup.specialist_label')}}
                  .box
                    p.help.is-danger(v-show="!employeesValid") {{$t('checkout_popup.no_specialist_validation')}}
                    employee-selector(v-model="selectedEmployees" ref="specialistInput")
                location-select(v-model="location")
</template>

<script>
import {mapState, mapActions} from 'vuex'
import FollowupApi from '@/api/followup'
import StaffApi from '@/api/staff'
import SaleHelper from '../../../shared/saleHelper'
import SelectCoupon from '../sale/SelectCoupon'
import EmployeeSelector from './EmployeeSelector'
import PAYMENT_TYPES from '../../../constants/paymentTypes'
import SALE_ITEM_TYPES from '../../../constants/saleItemTypes'

export default {
  name: 'CheckoutPopup',
  components: {
    EmployeeSelector
  },
  props: {
    customerId: {type: String, default: undefined}
  },
  data() {
    return {
      phone: undefined,
      phoneValid: undefined,
      customer: undefined,
      totalSale: undefined,
      redeemAmount: undefined,
      customerCoupon: undefined,
      hasServiceStaff: undefined,
      hasCoupons: false,
      location: undefined,
      submitting: false,
      employeesValidDirty: false,
      selectedEmployees: [],
      followups: [],
      followup: undefined
    }
  },
  computed: {
    ...mapState('company', ['company']),
    redeemRules() {
      let maxValue = Math.min(
        Math.floor(((this.customer && this.customer.balance) || 0) / this.company.membership.currencyWorth),
        this.totalSale || 0
      )
      let minValue = Math.ceil((this.company.membership.redeemMin || 0) / this.company.membership.currencyWorth)
      return `max_value:${maxValue}|min_value:${minValue}`
    },
    availableForRedeem() {
      return SaleHelper.formatCurrency(
        Math.floor(((this.customer && this.customer.balance) || 0) / this.company.membership.currencyWorth),
        this.company.currency
      )
    },
    employeesValid() {
      return !this.employeesValidDirty || (this.selectedEmployees && this.selectedEmployees.length)
    },
    due() {
      let due = 0
      if (this.totalSale) {
        due = Math.max(this.totalSale - this.discount - (this.redeemAmount || 0), 0)
      }
      return due
    },
    discount() {
      if (!this.customerCoupon) {
        return 0
      }
      let discount = SaleHelper.calculateRewardsSaleDiscount({
        customerCoupon: this.customerCoupon,
        totalSale: this.totalSale
      })

      return discount
    }
  },
  async created() {
    this.hasServiceStaff = (await StaffApi.getActiveServiceList()).length
    if (this.customerId) {
      this.onCustomerSelect(await this.fetchCustomer(this.customerId))
    } else {
      this.$nextTick(() => {
        this.$refs.phoneInput.focus()
      })
    }
  },
  methods: {
    ...mapActions('customers', ['createCustomer', 'fetchCustomer']),
    ...mapActions('sales', ['checkout']),
    ...mapActions('customerCoupon', ['getCustomerCoupons']),
    onPhoneInput(phone, valid, customer) {
      this.phoneValid = valid
      this.customer = customer
      if (customer) {
        this.onCustomerSelect(customer)
      } else if (valid) {
        this.$nextTick(() => {
          this.$refs.totalSaleInput.focus()
        })
      }
    },
    async onCustomerSelect(customer) {
      this.customer = customer
      this.reset()
      this.fetchFollowups()
      this.$nextTick(() => {
        this.$refs.totalSaleInput.focus()
      })
      let availableCoupons = await this.getCustomerCoupons(this.customer._id)
      this.hasCoupons = !!(availableCoupons && availableCoupons.length)
    },
    async fetchFollowups() {
      this.followups = (await FollowupApi.list()).body
    },
    async confirm() {
      this.employeesValidDirty = true
      let valid = await this.$validator.validateAll()
      if (!valid) {
        const firstField = Object.keys(this.errors.collect())[0]
        this.$refs[`${firstField}Input`] && this.$refs[`${firstField}Input`].focus()
        return
      }
      if (this.hasServiceStaff && (!this.selectedEmployees || !this.selectedEmployees.length)) {
        this.$refs['specialistInput'].$el.scrollIntoView()
        return
      }
      this.submit()
    },
    async submit() {
      if (this.submitting) {
        return
      }
      this.submitting = true
      try {
        let customerId
        if (!this.customer) {
          let customer = await this.createCustomer({phone: this.phone, lng: this.company.defaultLanguage})
          customerId = customer._id
        } else {
          customerId = this.customer._id
        }
        let items
        if (this.hasServiceStaff) {
          let itemPrice = Math.round((100 * this.totalSale) / this.selectedEmployees.length) / 100
          items = this.selectedEmployees.map(e => {
            return {
              staff: e,
              price: itemPrice,
              finalPrice: itemPrice,
              type: SALE_ITEM_TYPES.UNTRACKED,
              itemPrice,
              subtotal: itemPrice,
              discount: 0,
              qty: 1,
              total: itemPrice
            }
          })
        } else {
          items = [
            {
              price: this.totalSale,
              finalPrice: this.totalSale,
              type: SALE_ITEM_TYPES.UNTRACKED,
              itemPrice: this.totalSale,
              subtotal: this.totalSale,
              discount: 0,
              qty: 1,
              total: this.totalSale
            }
          ]
        }

        let data = {
          customer: customerId,
          location: this.location,
          items,
          subtotal: this.totalSale,
          discount: this.redeemAmount + this.discount,
          discounts: {points: this.redeemAmount, coupon: this.discount},
          tax: 0,
          balance: 0,
          paid: this.due,
          total: this.due,
          payments: [
            {
              type: PAYMENT_TYPES.CASH,
              paid: this.due
            }
          ],
          customerCoupon: this.customerCoupon && this.customerCoupon._id
        }

        if (this.redeemAmount) {
          data.payments.push({
            type: PAYMENT_TYPES.POINTS,
            paid: this.redeemAmount,
            redeem: this.redeemAmount * this.company.membership.currencyWorth
          })
        }
        await this.checkout(data)
        this.$buefy.toast.open({message: this.$t('checkout_popup.collected_success'), type: 'is-success', queue: false})
        this.$emit('close')
        this.submitting = false
        this.customer = null
        this.reset()
      } catch (err) {
        this.submitting = false
        this.$handleAPIError(err)
      }
    },
    selectCoupon() {
      this.$buefy.modal.open({
        parent: this,
        props: {customerId: this.customer._id},
        component: SelectCoupon,
        width: 960,
        canCancel: ['escape', 'outside'],
        events: {
          input: coupon => {
            this.customerCoupon = coupon
          }
        }
      })
    },
    reset() {
      this.totalSale = undefined
      this.redeemAmount = undefined
      this.selectedEmployees = []
      this.followup = undefined
      this.customerCoupon = undefined
      this.hasCoupons = false
    },
    resetCustomer() {
      this.customer = undefined
      this.phone = undefined
      this.phoneValid = undefined
      this.customerCoupon = undefined
      this.hasCoupons = false
      this.$nextTick(() => {
        this.$refs.phoneInput.focus()
      })
    }
  }
}
</script>
<style scoped>
a.navbar-item.is-active {
  background-color: rgba(0, 0, 0, 0.4);
}
.checkout-footer {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 10;
  background-color: #f1f1f1;
  padding: 0.5em;
  box-shadow: 0px -1px 2px rgba(0, 0, 0, 0.2);
}
</style>
