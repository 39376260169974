<template lang="pug">
.modal-card
  header.modal-card-head
    p.modal-card-title(data-qa="modal-header") 
      b(v-t="'forms.select_form_title'")
    div
      a.button.is-white.is-small(@click="$emit('close')")
        span.icon
          i.mdi.mdi-close.mdi-24px
  section.modal-card-body
    template(v-if="forms.length")
      form-view.mb-3(v-for="form in forms" :key="form._id" @click="select(form._id)" :formId="form._id" large)
    .has-text-centered.p-5.has-text-grey.is-size-5(v-else v-t="'forms.no_forms_message'") 
    .buttons.is-centered.mt-5
      button.button.is-primary.is-outlined.is-rounded(@click="createEditForm()")
        span.icon
          i.mdi.mdi-plus-circle-outline
        span(v-t="'forms.actions.create_new_form'")
  footer.modal-card-foot
    .buttons
      button.button(@click="$emit('close')" v-t="'actions.cancel'") 
  </template>
<script>
import {mapActions, mapGetters} from 'vuex'
import CreateEditFormVue from './CreateEditForm.vue'
export default {
  name: 'SelectFormModal',
  computed: {
    ...mapGetters('forms', ['forms'])
  },
  created() {
    this.fetchForms()
  },
  methods: {
    ...mapActions('forms', ['fetchForms']),
    select(formId) {
      this.$emit('input', formId)
      this.$emit('close')
    },
    createEditForm(formId) {
      this.$buefy.modal.open({
        parent: this,
        component: CreateEditFormVue,
        hasModalCard: true,
        fullScreen: true,
        props: {formId},
        canCancel: ['escape']
      })
    }
  }
}
</script>
