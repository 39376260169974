<template lang="pug">
div
  h3.title.is-6.is-uppercase.has-text-darkgrey(v-t="'surveys.rating.average_rating'")
  h5.subtitle.is-7 {{period && period.display}}
  p.title.is-2.is-size-3-mobile(:class="feedbackColorClass" v-if="averageRating !== undefined")
    span {{formatToDecimals(averageRating)}}
  p.title.is-2.is-size-3-mobile(v-else) -
  p {{$t('surveys.rating.response_rate_visits')}} 
    b(v-if="total") 
      percentage-view(:value="ratings/total")
    b(v-else) -
  p {{$t('surveys.rating.response_rate_clients')}} 
    b(v-if="totalCustomers") 
      percentage-view(:value="ratedCustomers/totalCustomers")
    b(v-else) -
  p {{$t('surveys.rating.rated_visits')}} 
    b {{ratings}}
  p {{$t('surveys.rating.rated_clients')}} 
    b {{ratedCustomers}}
</template>

<script>
import ReportsApi from '@/api/reports'
import numeral from 'numeral'
export default {
  name: 'AverageRating',
  props: {
    period: {type: Object, default: undefined},
    location: {type: String, default: undefined}
  },
  data() {
    return {
      loading: false,
      total: 0,
      totalCustomers: 0,
      ratedCustomers: 0,
      averageRating: undefined,
      ratings: 0
    }
  },
  computed: {
    feedbackColorClass() {
      return 'feedback-' + numeral(this.averageRating).format('0') + '-text'
    }
  },
  watch: {
    period() {
      return this.fetchData()
    },
    location() {
      return this.fetchData()
    }
  },
  created() {
    if (this.period) {
      return this.fetchData()
    }
  },
  methods: {
    async fetchData() {
      this.loading = true
      try {
        const {total, totalCustomers, ratedCustomers, averageRating, ratings} = await ReportsApi.getAverageRating({
          from: this.period.from,
          to: this.period.to,
          location: this.location
        })
        this.total = total
        this.totalCustomers = totalCustomers
        this.ratedCustomers = ratedCustomers
        this.averageRating = averageRating
        this.ratings = ratings
      } catch (error) {
        this.$handleAPIError(error)
      }
      this.loading = false
    },
    formatToDecimals(number) {
      return numeral(number).format('0.[00]')
    }
  }
}
</script>
