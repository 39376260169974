<template lang="pug">
  .columns
    .column.is-2
      h3.title.is-6.is-uppercase.has-text-darkgrey NPS
      h5.subtitle.is-7 {{period && period.display}}
      h3.title.is-1 {{score}} 
    .column.is-10
      p.mb-3 
        b {{$t('surveys.nps.total_voices')}} {{voices}} 
        |  ·  
        span {{$t('surveys.nps.promoters')}} 
        b {{promoters}}
        |  ·  
        span {{$t('surveys.nps.neutrals')}} 
        b {{neutrals}}
        |  ·  
        span {{$t('surveys.nps.distractors')}} 
        b {{distractors}}
      .scale.is-flex.is-column
        .zone(style="width: 50%;")
          span.has-text-white.is-size-5 -100
          b.has-text-white {{$t('surveys.nps.score_minus_100')}}
        .zone(style="width: 15%; ")
          span.has-text-white.is-size-5 0
          b.has-text-white {{$t('surveys.nps.score_0')}}
        .zone(style="width: 20%; ")
          span.has-text-white.is-size-5 30
          b.has-text-white {{$t('surveys.nps.score_30')}}
        .zone(style="width: 15%;")
          span.has-text-white.is-size-5 70
          b.has-text-white {{$t('surveys.nps.score_70')}}
        .current-marker(:style="{left: `${(score + 100)/2}%`}")
      
      
</template>

<script>
import {mapState} from 'vuex'

import ReportsApi from '@/api/reports'
import NumberPreview from '../../shared/NumberPreview'

export default {
  name: 'NPSScore',
  components: {
    NumberPreview
  },
  props: {
    period: {type: Object, default: undefined},
    location: {type: String, default: undefined}
  },
  data() {
    return {
      loading: false,
      score: 0,
      distractors: 0,
      neutrals: 0,
      promoters: 0,
      voices: 0
    }
  },
  computed: {
    ...mapState('company', ['company'])
  },
  watch: {
    period() {
      return this.fetchData()
    },
    location() {
      return this.fetchData()
    }
  },
  created() {
    if (this.period) {
      return this.fetchData()
    }
  },
  methods: {
    async fetchData() {
      this.loading = true
      try {
        const {distractors, promoters, neutrals, nps} = await ReportsApi.getNPSScore({
          from: this.period.from,
          to: this.period.to,
          location: this.location
        })
        this.distractors = distractors
        this.promoters = promoters
        this.neutrals = neutrals
        this.voices = distractors + promoters + neutrals
        this.score = Math.round(nps || 0) //numeral(data.nps).format('0.0')
      } catch (error) {
        this.$handleAPIError(error)
      }
      this.loading = false
    }
  }
}
</script>
<style scoped>
.scale {
  width: 100%;
  position: relative;
  height: 40px;
  padding-top: 8px;
  background: linear-gradient(
    90deg,
    rgba(255, 30, 36, 1) 0%,
    rgba(255, 146, 29, 1) 50%,
    rgba(139, 198, 63, 1) 65%,
    rgba(34, 193, 107, 1) 85%,
    rgba(0, 147, 68, 1) 100%
  );
  border-radius: 6px;
}
.zone {
  display: flex;
  align-items: center;
  height: 24px;
  padding: 5px;
  border-left: 1px solid white;
  justify-content: space-between;
}
.zone:first-child {
  border: none;
}
.current-marker {
  position: absolute;
  width: 4px;
  background: rgb(14, 13, 88);
  height: 40px;
  top: 0px;
  border-radius: 1px;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.2);
}
.current-marker::before {
  content: '';
  position: absolute;
  top: -3px;
  transform: rotate(90deg);
  left: -8px;
  border: 10px solid transparent;
  border-left-color: rgb(14, 13, 88);
}
.current-marker::after {
  content: '';
  position: absolute;
  bottom: -5px;
  transform: rotate(-90deg);
  left: -8px;
  border: 10px solid transparent;
  border-left-color: rgb(14, 13, 88);
}
</style>
