const {SETTINGS, PRODUCTS} = require('../constants/products')
const dayjs = require('dayjs')
class BillingHelper {
  isSubscribed(product, plan, beta) {
    return SETTINGS[this.getEffectivePlan(plan)].included.includes(product) || (beta && beta.includes(product))
  }
  getPlanByProduct({product, country}) {
    return Object.values(SETTINGS).find(
      s => s.included && s.included.includes(product) && (!s.excludedRegion || !s.excludedRegion.includes(country))
    )
  }
  getActiveProducts(plan) {
    return SETTINGS[plan].included
  }
  getEffectivePlan(plan) {
    if (!plan.trialEnd || dayjs(plan.trialEnd).isAfter(new Date()) || plan.subscribed) {
      return plan.product
    }
    return PRODUCTS.EM_FREE
  }
  trialHasEnded({plan, country}) {
    if (country === 'AM') {
      if (plan.trialEnd && dayjs(plan.trialEnd).isBefore(new Date()) && !plan.subscribed) {
        return true
      }
      return false
    }

    if (
      plan.product !== PRODUCTS.EM_FREE &&
      plan.trialEnd &&
      dayjs(plan.trialEnd).isBefore(new Date()) &&
      !plan.subscribed
    ) {
      return true
    }
    return false
  }
  getPlanUsage(company) {
    if (!company.checkoutLimit) {
      return
    }
    return parseInt((company.checkoutsMTD * 100) / company.checkoutLimit)
  }
  isEnabled(product, company) {
    return !company.apps || company.apps[product] !== false
  }
}
module.exports = new BillingHelper()
