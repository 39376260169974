<template lang="pug">
.modal-card(data-qa="staff-member-form")
  header.modal-card-head
    p.modal-card-title(data-qa="modal-header") 
      b(v-if="staffId" v-t="'staff.details.title_edit'")
      b(v-else v-t="'staff.details.title_new'")
    div
      a.button.is-white.is-small(@click="$emit('close')")
        span.icon
          i.mdi.mdi-close.mdi-24px
  section.modal-card-body.has-lightgrey-background
    .columns.is-centered
      .column.is-8-desktop
        b-field(:label="$t('staff.details.account_type')" )
          select-box(v-model="service" :data="accountTypes" @input="onTypeChange")
        hr
        .box
          h3.title.is-4 {{$t('staff.details.tabs.details')}}
          //b-field(:label="$t('staff.details.account_type')" )
            select-box(v-model="service" :data="accountTypes" @input="onTypeChange")
          //hr
          b-field(grouped )
            b-field(:label="$t('staff.details.first_name_label')" expanded :type="errors.has('firstName') ? 'is-danger': ''" :message="errors.has('firstName') ? errors.first('firstName') : ''")
              b-input(:placeholder="$t('staff.details.first_name_placeholder')" data-vv-as="first name" name="firstName" v-validate="'required'" icon="account" v-model="firstName" data-qa="first-name")

            b-field(:label="$t('staff.details.last_name_label')" expanded :type="errors.has('lastName') ? 'is-danger': ''" :message="errors.has('lastName') ? errors.first('lastName') : ''")
              b-input(:placeholder="$t('staff.details.last_name_placeholder')" data-vv-as="last name" name="lastName" v-validate="'required'" icon="account" v-model="lastName" data-qa="last-name")
          b-field(grouped v-if="service")
            b-field(:label="$t('staff.details.service_role_label')" expanded  :type="errors.has('role') ? 'is-danger': ''" :message="errors.has('role') ? errors.first('role') : ''")
              b-select(v-model="role" expanded v-validate="serviceRoleRules" name="role" :placeholder="$t('staff.details.service_role_placeholder')" data-qa="service-role")
                option(v-for="role in STAFF_ROLES" :value="role") {{$t(`roles.${role}`)}}
            b-field(:label="$t('staff.details.level_label')" expanded :type="errors.has('level') ? 'is-danger': ''" :message="errors.has('level') ? errors.first('level') : ''")
              b-select(v-model="level" expanded  name="level" :placeholder="$t('staff.details.level_placeholder')" icon="chevron-triple-up" data-qa="level")
                option(:value="null") {{$t('staff.details.level_placeholder')}}
                option(v-for="level in LEVELS" :value="level") {{$t(`staff_levels.${level}`)}}
          //note.is-warning.is-outlined.mb-5(size="small" :has-icon="false") Experience level can be used to customize services prices for each level
          
          b-field(:label="$t('staff.details.location_label')" :type="errors.has('location') ? 'is-danger': ''" :message="errors.has('location') ? errors.first('location') : ''")
            b-select(
              v-model="location" 
              expanded 
              v-validate="'required'" 
              name="location" 
              :placeholder="$t('staff.details.location_placeholder')" 
              icon="map-marker-outline" 
              data-qa="location"
              :disabled="$rfProfileScope() || $rfLocationScope()"
              )
              option(v-for="location in activeLocations" :value="location._id") {{location.title}}
          b-field(:label="$t('business_settings.phone_label')", :type="errors.has('phone') || phoneValid === false ? 'is-danger': ''" :message="errors.has('phone') ? errors.first('phone') : (phoneValid === false ? 'The phone number is invalid': '')")
            phone-input(v-model="phone" @input="(phone, valid) => phone ? phoneValid = valid : phoneValid = undefined" name="phone" :placeholder="$t('staff.details.phone_placeholder')" data-qa="phone")
          b-field(grouped)
            b-field(:label="$t('staff.details.permission_label')" expanded :type="errors.has('access') ? 'is-danger': ''" :message="errors.has('access') ? errors.first('access') : ''")
              b-select(v-model="access" expanded icon="key" :placeholder="$t('staff.details.permission_placeholder')" name="access" v-validate="'required'" :disabled="staffId === user._id || !$rfHasPermission('STAFF_PERMISSIONS_EDIT')")
                option(v-for="perm in permissions" :value="perm" ) {{$t(`access.${perm}`)}}
            
          b-field(:label="$t('staff.details.scope_label')" expanded v-if="access !== 'no'")
            select-box(v-model="scope" :data="scopes" :disabled="staffId === user._id || !$rfHasPermission('STAFF_PERMISSIONS_EDIT')")
      
          b-field(:label="$t('staff.details.email_label')" :type="errors.has('email') ? 'is-danger': ''" :message="errors.has('email') ? errors.first('email') : ''")
            b-input(:placeholder="$t('staff.details.email_placeholder')" name="email" v-validate="emailRules" type="email" icon="email-outline" v-model="email" data-qa="email")
          p.has-text-primary.mb-4(v-if="invitationPending") 
            span.icon
              i.mdi.mdi-alert-circle-outline
            span  {{$t('staff.details.email_invitation_pending')}}
          
          //.box
          
          
          b-field(grouped v-if="company.languages.length > 1 && access !== 'no'" expanded)
            
            b-field(v-if="company.country === 'AM'" :label="$t('account.settings.locale')" expanded)
              b-select(v-model="locale" expanded name="locale" icon="earth")
                option(v-for="lang in locales" :value="lang") {{$t(`account.settings.languages.${lang}`)}}
            b-field(:label="$t('staff.details.localization_label')" expanded)
              b-select(v-model="lng" expanded)
                option(:value="null") {{$t(`languages.default`)}}
                option(v-for="language in company.languages" :key="language" :value="language") {{$t(`languages.${language}`)}}
          
          b-field(:label="$t('staff.details.picture_label')")
            .is-artwork
              image-preview(v-model="picture" :max-size="800" category="staff")
          
          b-field(:label="$t('staff.details.notes_label')")
            b-input(:placeholder="$t('staff.details.notes_placeholder')" type="textarea" v-model="notes" data-qa="note")  
        .box(v-if="hasMultipleLocations && service")
          h2.title.is-4 {{$t('staff.details.locations_title')}}
          p.subtitle.is-6 {{$t('staff.details.locations_subtitle')}}
          MultipleLocationSelector(v-model="locations")
        .box(v-if='service')
          h3.title.is-4 {{$t('staff.details.tabs.services')}}          
          b-field(:label="$t('staff.details.online_booking_label')")
            b-switch(v-model="online") {{ online ? $t('misc.enabled') : $t('misc.disabled') }}

          label.label {{ $t('staff.details.services_subtitle') }}
          ServicesSelector(:id="staffId" @input="onServicesSelect")
        
        .box(ref="compensation_block")
          h3.title.is-4 {{$t('staff.details.compensation_title')}}
          b-field(:label="$t('staff.details.compensation_type_label')")
            b-radio(v-model="compensationType" native-value="wage") {{ $t('compensation_type.wage') }}
            b-radio(v-model="compensationType" native-value="commission") {{ $t('compensation_type.commission') }}
          hr
          template(v-if="service && $rfSubscribed('targets')")
            h3.title.is-5 {{$t('staff.details.targets_title')}}
            template(v-if="!level")
              note.is-warning.is-outlined {{$t('staff.details.targets_level_warning')}}
            template(v-else)
              template(v-if="compensationType === 'wage'")
                b-field(:label="$t('staff.details.weekly_wage_label')", :type="errors.has('weeklyWage') ? 'is-danger': ''" :message="errors.has('weeklyWage') ? errors.first('weeklyWage') : ''")
                  b-input(v-model="weeklyWage" icon="currency-usd" v-validate="'numeric'" name="weeklyWage" :placeholder="$t('staff.details.weekly_wage_placeholder')" data-vv-as="weekly wage" )
                b-field(:label="$t('staff.details.weekly_cost_label')", :type="errors.has('weeklyCost') ? 'is-danger': ''" :message="errors.has('weeklyCost') ? errors.first('weeklyCost') : ''")
                  b-input(v-model="weeklyCost" icon="currency-usd" v-validate="'numeric'" name="weeklyCost" :placeholder="$t('staff.details.weekly_cost_placeholder')" data-vv-as="weekly cost" )

                

                b-field(:label="$t('staff.details.service_target_ratio_label')" message="Staff member\'s weekly service target is calculated with Total Cost x Ratio formula")
                  b-slider(v-model="targetRatio" :type="targetRatioType" :step="0.5" :min="0" :max="5" :ticks="true" :indicator="true" :custom-formatter="(v) => `${v}x`" size="is-medium" :tooltip="false") 
                    b-slider-tick(:value="BENCHMARKS.LEVEL_SERVICE_RATIOS[level].min") Min
                    b-slider-tick(:value="BENCHMARKS.LEVEL_SERVICE_RATIOS[level].recommended") Recommended
              template(v-else-if="compensationType === 'commission'")
                b-field(:label="$t('staff.details.weekly_target_label')", :type="errors.has('weeklyTarget') ? 'is-danger': ''" :message="errors.has('weeklyTarget') ? errors.first('weeklyTarget') : ''")
                  b-input(v-model="weeklyTarget" icon="currency-usd" v-validate="'numeric'" name="weeklyTarget" :placeholder="$t('staff.details.weekly_target_placeholder')" data-vv-as="weekly target" )
              b-field(:label="$t('staff.details.retail_target_ratio_label')" message="Staff member\'s weekly retail target is calculated with Service Target % Retail Ratio formula")
                b-slider(v-model="retailTargetRatio" :type="retailTargetRatioType" :step="5" :min="0" :max="100" :ticks="true" :indicator="true" :custom-formatter="(v) => `${v}%`" size="is-medium" :tooltip="false") 
                  b-slider-tick(:value="BENCHMARKS.LEVEL_RETAIL_RATIOS[level].min") Min
                  b-slider-tick(:value="BENCHMARKS.LEVEL_RETAIL_RATIOS[level].recommended") Recommended
              b-field(:label="$t('staff.details.avg_daily_customers_label')" message="Number of customers staff member should service during the average full day")
                b-slider(v-model="dailyCustomers" :type="dailyCustomersType" :step="1" :min="0" :max="BENCHMARKS.AVG_DAILY_CUSTOMERS[company.industry].max" :ticks="true" :indicator="true" size="is-medium" :tooltip="false") 
                  b-slider-tick(:value="BENCHMARKS.AVG_DAILY_CUSTOMERS[company.industry].min") Min
                  b-slider-tick(:value="BENCHMARKS.AVG_DAILY_CUSTOMERS[company.industry].recommended") Recommended
        .box
          h3.title.is-5 {{$t('staff.details.commission_title')}}
          p.subtitle.is-6 {{ $t('staff.details.commission_subtitle') }}
          b-field(:label="$t('staff.details.commissions.services_label')", :type="errors.has('servicesCommission') ? 'is-danger': ''" :message="errors.has('servicesCommission') ? errors.first('servicesCommission') : ''")
            b-input(v-model="commission.services" icon="percent" v-validate="'numeric'" name="servicesCommission" :placeholder="$t('staff.details.commissions.services_placeholder')" data-vv-as="services commission" :disabled="!service")
          b-field(:label="$t('staff.details.commissions.services_type_label')")
            b-radio(v-model="commission.servicesType" name="servicesType" :native-value="COMMISSION_BASE_TYPES.BEFORE_DISCOUNT") {{$t('commission_base_type.before_discount')}}
            b-radio(v-model="commission.servicesType" name="servicesType" :native-value="COMMISSION_BASE_TYPES.AFTER_DISCOUNT") {{$t('commission_base_type.after_discount')}}
              |  
              small
                i.has-text-primary(v-t="'misc.recommended'")
          hr
          b-field(:label="$t('staff.details.commissions.retail_label')", :type="errors.has('retailCommission') ? 'is-danger': ''" :message="errors.has('retailCommission') ? errors.first('retailCommission') : ''")
            b-input(v-model="commission.retail" icon="percent" v-validate="'numeric'" name="retailCommission" :placeholder="$t('staff.details.commissions.retail_placeholder')" data-vv-as="retail commission" )
          b-field(:label="$t('staff.details.commissions.retail_type_label')")
            b-radio(v-model="commission.retailType" name="retailType" :native-value="COMMISSION_BASE_TYPES.BEFORE_DISCOUNT") {{$t('commission_base_type.before_discount')}} 

            b-radio(v-model="commission.retailType"  name="retailType" :native-value="COMMISSION_BASE_TYPES.AFTER_DISCOUNT") {{$t('commission_base_type.after_discount')}}
              |  
              small
                i.has-text-primary(v-t="'misc.recommended'")
          
        .box(v-if="$rfSubscribed('targets')")
          h3.title.is-5 {{$t('staff.details.targets_breakdown_title')}}
          h4.title.is-5 Standard Weekly Roster
          p.subtitle.is-6 <b>{{weeklyOccupancy.days}}</b> days, <b>{{weeklyOccupancy.hours}}</b> hours
          h4.title.is-5.mb-2.mt-6 Service Targets
          .columns
            .column
              p.heading Hourly
              p.title.is-5 
                currency-view(:value="targets.service.hourly")
            .column
              p.heading Daily
              p.title.is-5 
                currency-view(:value="targets.service.daily")
            .column
              p.heading Weekly
              p.title.is-5 
                currency-view(:value="targets.service.weekly")
            .column
              p.heading Monthly
              p.title.is-5 
                currency-view(:value="targets.service.monthly")
            
          h4.title.is-5.mb-2 Retail Targets
          .columns
            .column
              p.heading Hourly
              p.title.is-5 
                currency-view(:value="targets.retail.hourly")
            .column
              p.heading Daily
              p.title.is-5 
                currency-view(:value="targets.retail.daily")
            .column
              p.heading Weekly
              p.title.is-5 
                currency-view(:value="targets.retail.weekly")
            .column
              p.heading Monthly
              p.title.is-5 
                currency-view(:value="targets.retail.monthly")
          

        .box
          h3.title.is-4 {{$t('staff.details.tabs.notifications')}}
          p.subtitle.is-6(v-t="'staff.details.notifications_subtitle'")
          b-field(horizontal  :label="$t(`notification_types.${key}`)" v-for="(means, key) in notifications" :key="key")
            b-checkbox(v-for="(value, mean) in means" :key="mean" v-model="notifications[key][mean]" :disabled="(mean === 'messenger' && !facebookProfile) || (mean === 'email' && !email)" :title="$t('staff.details.messenger_notifications_tooltip')") {{$t(`channel.${mean}`)}}

          hr
          label.label(v-t="'staff.details.facebook_profile_label'")
          p.subtitle.is-6 {{$t('staff.details.tabs.facebook_linking_desc')}}
          b-field(:message="$t('staff.details.facebook_profile_help')")
            CustomerSearch(v-model="facebookProfile" :placeholder="$t('staff.details.facebook_profile_placeholder')" v-if="!facebookProfile" category="facebook")
            
            customer-tiny-preview(:value="facebookProfile" v-else :closable="true" @close="facebookProfile = null")
          
        //.box(v-if="$rfSubscribed('surveys')")
          h3.title.is-4 {{$t('staff.details.tabs.expressions')}}
          p.subtitle.is-6 Customize GIFs that are being sent as a reply to the client after they rate the visit/purchase. 
          expression-editor(v-model="expressions")
        .box(v-if="service")
          h3.title.is-4 {{$t('staff.details.tabs.bio')}}
          label.label {{$t('staff.details.title_label')}}
            b-tabs
              b-tab-item(v-for="lng in company.languages" :key="lng" :label="$t(`languages.${lng}`)")
                b-input(v-model="title[lng]" :placeholder="$t('staff.details.title_placeholder')")
          label.label {{$t('staff.details.bio_label')}}
            b-tabs
              b-tab-item(v-for="lng in company.languages" :key="lng" :label="$t(`languages.${lng}`)")
                b-input(type="textarea" v-model="bio[lng]" :placeholder="$t('staff.details.bio_placeholder')")
          label.label {{$t('staff.details.works_label')}}
          SimpleFileUpload(v-model="works" category="staff" :uploadNumber="10" ref="worksUpload")
  footer.modal-card-foot
    // .is-flex.is-flex-direction-row.is-align-items-center.is-flex-grow-1 .is-flex-grow-1
    .buttons.is-flex-grow-1
      .is-flex-grow-1
        action-button(:loading="submitting" @click="update" title="actions.save" permission="STAFF_EDIT" data-qa="action-save")
        button.button.is-outlined(type="button" @click="cancel") {{$t('actions.cancel')}}
      action-button(v-if="staffId" :loading="removing" @click="remove" type="is-danger" title="actions.delete" permission="STAFF_REMOVE" data-qa="action-delete")    
</template>

<script>
import {mapActions, mapState, mapGetters} from 'vuex'
import StaticApi from '@/api/static'
import CustomerSearch from '../shared/CustomerSearch'
import ServicesSelector from '../services/ServicesSelector'
import ExpressionEditor from './ExpressionEditor'
import MultipleLocationSelector from '../services/MultipleLocationSelector'

import notificationConfig from '../../../constants/notificationsConfig'
import SPECIALIST_LEVELS from '../../../constants/specialistLevels'
import BENCHMARKS from '../../../constants/benchmarks'
import ACCESS_SCOPE from '../../../constants/accessScope'
import COMMISSION_BASE_TYPES from '../../../constants/commissionBaseTypes'
import STAFF_ROLES from '../../../constants/staffRoles'
import targetsHelper from '../../../shared/targetsHelper'
import compensationTypes from '../../../constants/compensationTypes'
import SimpleFileUpload from '../shared/SimpleFileUpload'

export default {
  name: 'CreateEditStaffForm',
  components: {
    CustomerSearch,
    ServicesSelector,
    ExpressionEditor,
    MultipleLocationSelector,
    SimpleFileUpload
  },
  props: {
    staffId: {type: String, default: undefined},
    focusBlock: {type: String, default: undefined}
  },

  data() {
    return {
      BENCHMARKS,
      LEVELS: Object.values(SPECIALIST_LEVELS),
      COMMISSION_BASE_TYPES,
      locales: ['hy', 'en', 'ru'],
      accountTypes: [
        {
          title: this.$t('staff_account_type.service_provider.title'),
          value: true,
          description: this.$t('staff_account_type.service_provider.description')
        },
        {
          title: this.$t('staff_account_type.operator.title'),
          value: false,
          description: this.$t('staff_account_type.operator.description')
        }
      ],
      scopes: [
        {
          title: this.$t('staff_access_scope.no_limit.title'),
          value: ACCESS_SCOPE.ALL,
          description: this.$t('staff_access_scope.no_limit.description')
        },
        {
          title: this.$t('staff_access_scope.profile.title'),
          value: ACCESS_SCOPE.PROFILE,
          description: this.$t('staff_access_scope.profile.description')
        },
        {
          title: this.$t('staff_access_scope.location.title'),
          value: ACCESS_SCOPE.LOCATION,
          description: this.$t('staff_access_scope.location.description')
        }
      ],
      member: undefined,
      firstName: null,
      lastName: null,
      phone: null,
      phoneValid: undefined,
      email: null,
      registeredEmail: null,
      role: null,
      access: 'no',
      lng: null,
      locale: undefined,

      scope: ACCESS_SCOPE.ALL,
      notes: null,
      picture: null,
      service: false,
      online: true,
      location: null,
      locations: [],
      notifications: {},
      facebookProfile: null,
      invitationPending: false,
      shifts: {},
      bio: {},
      title: {},
      works: [],
      commission: {
        services: 0,
        retail: 0,
        servicesType: COMMISSION_BASE_TYPES.AFTER_DISCOUNT,
        retailType: COMMISSION_BASE_TYPES.AFTER_DISCOUNT
      },
      compensationType: compensationTypes.WAGE,
      weeklyWage: undefined,
      weeklyCost: undefined,
      weeklyTarget: undefined,
      targetRatio: 3,
      retailTargetRatio: 20,
      dailyCustomers: undefined,
      level: null,
      expressions: {
        excited: [],
        thanks: [],
        misses: []
      },
      submitting: false,
      removing: false,
      STAFF_ROLES,
      selectedServices: []
    }
  },
  computed: {
    ...mapState('company', ['company']),
    ...mapState('auth', ['user']),
    ...mapGetters('location', ['activeLocations', 'hasMultipleLocations']),

    emailRules() {
      const otherEmail = !(this.registeredEmail === this.email)
      return this.access === 'no'
        ? `email${otherEmail ? '|emailAvailable' : ''}`
        : `required|email${otherEmail ? '|emailAvailable' : ''}`
    },
    serviceRoleRules() {
      return this.service ? 'required' : ''
    },
    targetRatioType() {
      if (this.targetRatio < BENCHMARKS.LEVEL_SERVICE_RATIOS[this.level].min) {
        return 'is-danger'
      }
      if (this.targetRatio >= BENCHMARKS.LEVEL_SERVICE_RATIOS[this.level].recommended) {
        return 'is-success'
      }
      if (this.targetRatio >= BENCHMARKS.LEVEL_SERVICE_RATIOS[this.level].min) {
        return 'is-warning'
      }
      return 'is-info'
    },
    retailTargetRatioType() {
      if (this.retailTargetRatio < BENCHMARKS.LEVEL_RETAIL_RATIOS[this.level].min) {
        return 'is-danger'
      }
      if (this.retailTargetRatio >= BENCHMARKS.LEVEL_RETAIL_RATIOS[this.level].recommended) {
        return 'is-success'
      }
      if (this.retailTargetRatio >= BENCHMARKS.LEVEL_RETAIL_RATIOS[this.level].min) {
        return 'is-warning'
      }
      return 'is-info'
    },
    dailyCustomersType() {
      if (this.dailyCustomers < BENCHMARKS.AVG_DAILY_CUSTOMERS[this.company.industry].min) {
        return 'is-danger'
      }
      if (this.dailyCustomers >= BENCHMARKS.AVG_DAILY_CUSTOMERS[this.company.industry].recommended) {
        return 'is-success'
      }
      if (this.dailyCustomers >= BENCHMARKS.AVG_DAILY_CUSTOMERS[this.company.industry].min) {
        return 'is-warning'
      }
      return 'is-info'
    },
    targets() {
      return targetsHelper.getTargets({
        compensationType: this.compensationType,
        weeklyTarget: this.weeklyTarget,
        shifts: this.member && this.member.shifts,
        weeklyCost: this.weeklyCost,
        targetRatio: this.targetRatio,
        retailTargetRatio: this.retailTargetRatio,
        commission: this.commission
      })
    },
    weeklyOccupancy() {
      if (this.member && this.member.shifts) {
        return targetsHelper.getWeeklyOccupancy(this.member.shifts)
      }
      return {days: 0, hours: 0}
    },
    permissions() {
      const permissions = StaticApi.getAccessPermissions()
      if (this.service) {
        return permissions
      }
      return permissions.slice(1)
    }
  },
  async created() {
    this.$validator.pause()
    if (this.staffId) {
      let member = await this.fetchMember(this.staffId)
      this.member = member
      this.firstName = member.firstName
      this.lastName = member.lastName
      this.access = member.access
      this.scope = member.scope || ACCESS_SCOPE.ALL
      this.role = member.role
      this.phone = member.phone
      this.picture = member.picture
      this.email = member.email
      this.registeredEmail = member.email
      this.notes = member.notes
      this.service = member.service
      this.online = member.online === undefined ? true : member.online
      this.lng = member.lng
      this.locale = member.locale
      this.compensationType = member.compensationType
      this.weeklyTarget = member.weeklyTarget
      this.commission = Object.assign({}, member.commission)
      this.commission.servicesType = this.commission.servicesType || COMMISSION_BASE_TYPES.AFTER_DISCOUNT
      this.commission.retailType = this.commission.retailType || COMMISSION_BASE_TYPES.AFTER_DISCOUNT
      this.weeklyWage = member.weeklyWage
      this.weeklyCost = member.weeklyCost
      this.targetRatio = member.targetRatio

      this.retailTargetRatio = member.retailTargetRatio
      this.dailyCustomers = member.dailyCustomers
      this.level = member.level || null
      this.invitationPending = member.invitationPending
      this.facebookProfile = member.personalProfile
      this.location = member.location
      this.locations = (member.locations && member.locations.length && [...member.locations]) || [member.location]
      this.shifts = member.shifts
      this.expressions = member.expressions
      this.bio = Object.assign({}, member.bio)
      this.title = Object.assign({}, member.title)
      this.works = [...(member.works || [])]
    } else {
      this.service = true
      if (this.$rfProfileScope() || this.$rfLocationScope()) {
        this.location = this.user.location
      } else {
        this.location = this.activeLocations.length > 1 ? undefined : this.activeLocations[0]._id
      }
    }
    this.initNotifications()
    switch (this.focusBlock) {
      case 'compensation': {
        await this.$nextTick()
        this.$refs.compensation_block.scrollIntoView({block: 'start', behavior: 'smooth'})
        break
      }
    }
    await this.$nextTick()
    this.$validator.resume()
  },

  methods: {
    ...mapActions('staff', ['fetchMember', 'updateMember', 'createMember', 'removeMember']),
    ...mapActions('services', ['updateStaffServices']),
    ...mapActions('location', ['fetchLocations']),

    async update() {
      let valid = await this.$validator.validateAll()

      if (!valid || this.phoneValid === false) {
        return
      }
      this.submitting = true
      if (this.$refs.worksUpload) {
        await this.$refs.worksUpload.upload()
        await this.$nextTick()
      }

      let data = {
        _id: this.staffId,
        firstName: this.firstName,
        lastName: this.lastName,
        access: this.access,
        scope: this.scope,
        phone: this.phone,
        email: this.email,
        role: (this.service && this.role) || undefined,
        active: this.active,
        picture: this.picture,
        service: this.service,
        online: this.online,
        lng: this.lng,
        locale: this.locale,

        compensationType: this.compensationType,
        weeklyTarget: this.weeklyTarget,
        commission: this.commission,
        weeklyWage: this.weeklyWage,
        weeklyCost: this.weeklyCost,
        targetRatio: this.targetRatio,
        retailTargetRatio: this.retailTargetRatio,
        dailyCustomers: this.dailyCustomers,
        level: this.level,
        notifications: this.notifications,
        notes: this.notes,
        location: this.location,
        locations: this.locations,
        shifts: this.shifts,
        expressions: this.expressions,
        bio: this.bio,
        title: this.title,
        works: this.works,
        personalProfile: this.facebookProfile && this.facebookProfile._id
      }
      try {
        let staffMember
        if (this.staffId) {
          staffMember = await this.updateMember(data)
        } else {
          staffMember = await this.createMember(data)
        }
        await this.updateStaffServices({
          staffId: staffMember._id,
          services: (this.service && this.selectedServices) || []
        })
        this.$buefy.toast.open({message: this.$t('staff.details.save_success'), type: 'is-success', queue: false})
        this.$emit('close')
      } catch (err) {
        this.$handleAPIError(err)
      }
      this.submitting = false
    },
    onServicesSelect(services) {
      this.selectedServices = services
    },
    remove() {
      this.$buefy.dialog.confirm({
        message: this.$t('staff.details.remove_confirm.body'),
        title: this.$t('staff.details.remove_confirm.title'),
        type: 'is-danger',
        confirmText: this.$t('staff.details.remove_confirm.action'),
        cancelText: this.$t('staff.details.remove_confirm.cancel'),
        onConfirm: () => {
          this.confirmRemove()
        }
      })
    },
    async confirmRemove() {
      if (this.removing) {
        return
      }
      this.removing = true
      try {
        await this.removeMember(this.staffId)
        this.$buefy.toast.open({
          message: this.$t('staff.details.remove_confirm.success'),
          type: 'is-success',
          queue: false
        })
        this.$emit('close')
      } catch (err) {
        this.$handleAPIError(err)
      }
      this.removing = false
    },
    cancel() {
      this.$emit('close')
    },
    onTypeChange() {
      if (!this.permissions.includes(this.access)) {
        this.access = undefined
      }
      if (!this.service) {
        this.commission.services = 0
        this.commission.servicesType = COMMISSION_BASE_TYPES.AFTER_DISCOUNT
      }
      this.initNotifications()
    },
    initNotifications() {
      let filtered = Object.keys(notificationConfig)
        .map(key => {
          return {
            ...notificationConfig[key],
            key
          }
        })
        .filter(n => n.recipient.includes(this.service ? 'specialist' : 'admin'))
      let initial = (this.member && this.member.notifications) || Object.assign({}, this.notifications)
      this.notifications = {}
      for (let type of filtered) {
        this.notifications[type.key] = {}
        for (let mean of type.means) {
          this.notifications[type.key][mean] =
            (!initial[type.key] && true) || (initial[type.key] && initial[type.key][mean]) || false
        }
      }
    }
  }
}
</script>
