<template lang="pug">

.bordered-box.box.is-thick.p-0( :class="[hasErrors ? 'is-danger': 'is-primary', isMobile() ? 'is-radiusless': '']" style="position: relative; display: block;")
  
  slot(name="header") 
  .is-flex.is-flex-direction-row.is-align-items-center.is-justify-content-space-between.has-border-bottom(style="align-self:stretch")
    .is-flex.is-align-items-center  
      timepicker.pl-4.py-2(v-model="item.start" @input="update" style="border: none")
      .has-border-blue &nbsp;
      p.pl-3
        duration-input.is-plain-select.tag.is-warning.px-1.is-medium(v-model="item.duration" expanded @input="update" :undefined-value="false")
        |  
        span(v-if="item.blockedTimeAfter") 
          b.has-text-danger +
            duration-view(:value="item.blockedTimeAfter")
          span.is-hidden-mobile  {{ $t('appointment.extra_time') }} 
        span(v-if="item.processingTimeAfter") 
          b.has-text-info +
            duration-view(:value="item.processingTimeAfter")
          span.is-hidden-mobile  {{ $t('appointment.processing_time') }} 
    .px-4(v-if="removable")
      span.icon.is-clickable(@click="$emit('remove')")
        i.mdi.mdi-trash-can-outline.mdi-24px
  .is-flex.is-flex-direction-row.is-align-items-center
    //.is-flex.is-flex-direction-column.is-align-items-center.p-4.is-justify-content-space-evenly.is-hidden-mobile(style="align-self:stretch")
      TimepickerBuefy(v-model="item.start" @input="update")
      p
        duration-input.is-plain-select.tag.px-1.is-medium(v-model="item.duration" expanded @input="update" :undefined-value="false")
        |  
        span(v-if="item.blockedTimeAfter") 
          b.has-text-danger +
            duration-view(:value="item.blockedTimeAfter")
          //span  {{ $t('appointment.extra_time') }} 
        span(v-if="item.processingTimeAfter") 
          b.has-text-primary +
            duration-view(:value="item.processingTimeAfter")
          //span  {{ $t('appointment.processing_time') }} 
    
    .is-flex-grow-1.px-4.py-2
      .is-flex.is-flex-direction-row.is-align-items-center
        //.has-border-blue &nbsp;
        .is-flex-grow-1.brand-bordered.px-2.py-1.is-clickable(@click="selectService")
          p.title.is-6.is-size-6-mobile
            service-name-view(:value="{service: item.service.serviceId, option: item.service.optionId}")
          p.subtitle.is-6.has-text-grey 
            category-name-view(v-if="service" :value="service.category")
            |  -  
            currency-view(:value="item.price")
            |  -  
            duration-view(:value="item.duration")
        //.has-border-blue &nbsp;
        
      .is-flex.is-flex-direction-row.is-align-items-center.mt-2
        .brand-bordered.px-2.py-1.mr-4(v-if="hasStaff" v-show="!$rfProfileScope()")
          StaffSelectDropdown(:staffId="item.staff" :preferred="item.preferred" expanded 
            @input="onStaffSelect" 
            :locationId="locationId" 
            :serviceId="item.service && item.service.serviceId" )
        .brand-bordered.px-2.py-1.mr-4(v-if="availableResources.length")
          ResourceSelectDropdown(:resourceId="item.resource" :preferredResource="item.preferredResource" expanded 
            @input="onResourceSelect" 
            :locationId="locationId" 
            :service="service" )
        .brand-bordered.px-2.py-1(v-if="rebooking && $rfCRMSettings().rebooking" v-show="!$rfProfileScope()")
          
              RebookingStaffSelectDropdown(:value="item.credit" expanded 
              @input="updateCreditedStaff" 
              :locationId="locationId" 
              )
    
  .has-background-light.px-4.py-2(v-if="hasErrors || hasInfo" style="border-radius: 0 0 6px 6px")
      p.has-text-info(v-for="(val, note) in appointmentNotes") 
        span.icon
          i.mdi.mdi-alert-circle-outline
        span(v-html="$t(`appointment.notes.${note}`, val)")
      p.has-text-danger(v-for="(val, error) in validationErrors") 
        span.icon
          i.mdi.mdi-alert-circle-outline
        span(v-html="$t(`appointment.validation.${error}`, val)")
          
</template>
<script>
import {mapActions, mapState, mapGetters} from 'vuex'
import AppointmentValidator from './AppointmentValidator'
import ServiceAutocomplete from '../services/ServiceAutocomplete'
import StaffSelect from '../staff/StaffSelect'
import RebookingStaffSelectDropdown from '../staff/RebookingStaffSelectDropdown'

import serviceExtraTimeTypes from '../../../constants/serviceExtraTimeTypes'
import formatter from '../../../shared/formatter'
import TimepickerBuefy from '../shared/TimepickerBuefy'
import StaffApi from '@/api/staff'
import StaffSelectDropdown from '../staff/StaffSelectDropdown'
import ResourceSelectDropdown from '../staff/ResourceSelectDropdown'
import cloneDeep from 'lodash/cloneDeep'
import isEqual from 'lodash/isEqual'
import uniq from 'lodash/uniq'
import dayjs from 'dayjs'
import saleHelper from '../../../shared/saleHelper'
import duration from '../../mixins/duration.vue'
import ServiceSelectModal from '../services/ServiceSelectModal.vue'

export default {
  name: 'AppointmentItemEdit',
  components: {
    ServiceAutocomplete,
    StaffSelect,
    StaffSelectDropdown,
    ResourceSelectDropdown,
    RebookingStaffSelectDropdown,

    TimepickerBuefy
  },
  mixins: [AppointmentValidator, duration],
  props: {
    value: {type: Object, required: true},
    locationId: {type: String, default: undefined},
    removable: {type: Boolean, default: true},
    date: {type: Date, required: true},
    rebooking: {type: Boolean, default: false}
  },

  data() {
    return {
      item: undefined,
      service: undefined,
      validationErrors: {},
      appointmentNotes: {}
    }
  },
  provide() {
    return {
      $validator: this.$validator
    }
  },
  computed: {
    ...mapState('auth', ['user']),
    ...mapState('company', ['company']),
    ...mapState('staff', ['staffMap']),
    ...mapGetters('resources', ['resourcesByLocation']),
    hasErrors() {
      return !!Object.keys(this.validationErrors).length
    },
    hasInfo() {
      return !!Object.keys(this.appointmentNotes).length
    },
    availableResources() {
      if (!this.service || !this.service.resources || !this.service.resources.length) {
        return []
      }
      return this.resourcesByLocation(this.locationId).filter(l => this.service.resources.includes(l._id))
    },
    hasStaff() {
      if (!this.service || !this.service.staff || !this.service.staff.length) {
        return false
      }
      return true
    }
  },
  watch: {
    value(newVal) {
      if (isEqual(newVal, this.item)) {
        return
      }
      this.init()
    },
    // ['value.start']() {
    //   if (this.item.start === this.value.start) {
    //     return
    //   }
    //   this.item.start = this.value.start
    //   this.validate()
    // },
    // ['value.duration']() {
    //   if (this.item.duration === this.value.duration) {
    //     return
    //   }
    //   this.item.duration = this.value.duration
    //   this.validate()
    // },
    // ['value.staff']() {
    //   if (this.item.staff === this.value.staff) {
    //     return
    //   }
    //   console.log(this.value.staff)
    //   this.onStaffSelect({staffId: this.value.staff})
    // },
    // ['value.resource']() {
    //   if (this.item.resource === this.value.resource) {
    //     return
    //   }
    //   this.onResourceSelect({resourceId: this.value.resource})
    // },
    locationId() {
      this.validate()
    },
    date() {
      this.validate()
    }
  },
  async created() {
    await this.init()
  },
  methods: {
    ...mapActions('services', ['fetchService']),
    async init() {
      this.item = cloneDeep(this.value)

      if (this.item.service && this.item.service.serviceId) {
        this.service = await this.fetchService(this.item.service.serviceId)
        if (this.item.duration === undefined) {
          await this.onServiceSelect({
            serviceId: this.item.service.serviceId,
            optionId: this.item.service.optionId,
            reinit: true
          })
        } else {
          await this.validate()
        }
      }
    },
    selectService() {
      return this.$buefy.modal.open({
        parent: this,
        hasModalCard: true,
        component: ServiceSelectModal,
        width: 960,
        fullScreen: this.isMobile(),
        canCancel: ['escape'],
        props: {
          quickSelect: true
        },
        events: {
          input: this.onServiceSelect
        }
      })
    },
    async onServiceSelect({serviceId, optionId, reinit}) {
      if (
        this.item.service &&
        this.item.service.serviceId === serviceId &&
        this.item.service.optionId === optionId &&
        !reinit
      ) {
        return
      }
      this.service = await this.fetchService(serviceId)
      this.item.service = {serviceId, optionId}

      if (this.availableResources.length) {
        if (!this.item.resource || !this.availableResources.find(r => r._id === this.item.resource)) {
          this.item.resource = this.availableResources[0]._id
        }
      } else {
        this.item.resource = undefined
      }

      this.item.blockedTimeAfter =
        (this.service.extraTime &&
          this.service.extraTime.afterType === serviceExtraTimeTypes.BLOCK &&
          (this.service.extraTime.after || 0)) ||
        0

      this.item.processingTimeAfter =
        (this.service.extraTime &&
          this.service.extraTime.afterType === serviceExtraTimeTypes.PROCESSING &&
          (this.service.extraTime.after || 0)) ||
        0
      if (this.service.staff && this.service.staff.length) {
        this.item.staff = this.item.staff || (await this.suggestStaffId(this.service.staff))
      } else {
        this.item.staff = undefined
      }
      this.recalculatePrice()
      this.update()
    },
    recalculatePrice() {
      if (!this.service) {
        return
      }
      const option = this.service.pricingOptions.find(s => s._id === this.item.service.optionId)
      if (!option) {
        this.$logWarning('OPTION NOT FOUND FOR SERVICE', {
          service: this.item.service.serviceId,
          option: this.item.service.optionId
        })
        return
      }
      const {duration, price, priceUpto} = saleHelper.getPricingOption(option, this.item.staff)
      this.item.duration = duration
      this.item.standardDuration = option.duration
      this.item.staffDuration = duration
      this.item.price = price
      this.item.priceUpto = priceUpto
    },
    async onStaffSelect({staffId, preferred}) {
      this.item.staff = staffId
      this.item.preferred = preferred
      this.recalculatePrice()
      this.update()
    },
    onResourceSelect({resourceId, preferredResource}) {
      this.item.resource = resourceId
      this.item.preferredResource = preferredResource
      this.recalculatePrice()
      this.update()
    },
    async suggestStaffId(staffIds) {
      if (this.$rfProfileScope()) {
        return this.user._id
      }
      let formattedDate = parseInt(dayjs(this.date).format('YYYYMMDD'))

      let shiftRanges = await StaffApi.getShiftRanges({
        location: this.locationId,
        from: formattedDate,
        to: formattedDate
      })
      let suggested = staffIds.find(sId => shiftRanges[sId] && shiftRanges[sId][formattedDate].length)
      if (!suggested) {
        suggested = staffIds.find(sId => this.staffMap[sId].location === this.locationId)
      }
      return suggested ? suggested : staffIds[0]
    },
    updateCreditedStaff(memberId) {
      this.item.credit = memberId
      this.update()
    },

    initNotifications() {
      this.appointmentNotes = {}
      if (!this.item.service || !this.item.staff) {
        return
      }

      const option = this.service.pricingOptions.find(s => s._id === this.item.service.optionId)
      if (option.override && option.override[this.item.staff]) {
        if (option.override[this.item.staff].duration !== undefined) {
          this.$set(this.appointmentNotes, 'duration_override', {
            duration: this.formatDuration(option.override[this.item.staff].duration)
          })
        }
        if (option.override[this.item.staff].price !== undefined) {
          this.$set(this.appointmentNotes, 'price_override', {
            price: saleHelper.formatCurrency(option.override[this.item.staff].price, this.company.currency)
          })
        }
      }
    },
    async validate() {
      if (!this.service) {
        return
      }
      this.validating = true
      try {
        this.initNotifications()
        this.item.invalid = false
        this.validationErrors = {}
        // if (this.item.staff && !this.validateStaffLocation({staffId: this.item.staff, locationId: this.locationId})) {
        //   this.$set(this.validationErrors, 'wrong_staff_location', true)
        // }

        if (
          this.item.service &&
          !this.validateServiceLocation({serviceId: this.item.service.serviceId, locationId: this.locationId})
        ) {
          this.$set(this.validationErrors, 'service_not_available_in_location', true)
        }
        if (
          this.item.staff &&
          this.item.service &&
          !this.validateStaffService({
            staffId: this.item.staff,
            serviceId: this.item.service.serviceId
          })
        ) {
          this.$set(this.validationErrors, 'doesnt_provide_service', true)
        }
        await Promise.all([this.validateStaff(), this.validateResource(), this.validateWorkingHours()])
        //this.item.invalid = this.hasErrors
      } catch (err) {
        console.log(err)
        this.$handleAPIError(err)
      }
      this.validating = false
    },
    async validateWorkingHours() {
      if (!this.item.staff || !this.item.service) {
        return
      }
      let outsideRange = await this.validateStaffWorkingHours({
        staffId: this.item.staff,
        date: this.date,
        start: this.item.start,
        locationId: this.locationId,
        duration: this.item.duration,
        blockedTimeAfter: this.item.blockedTimeAfter
      })
      if (outsideRange) {
        this.$set(this.validationErrors, 'outside_of_working_hours_or_location', outsideRange)
      }
    },
    async validateResource() {
      if (!this.item.resource) {
        return
      }

      let overlap = await this.findResourceOverlap({
        resourceId: this.item.resource,
        date: this.date,
        itemId: this.item._id,
        start: this.item.start,
        duration: this.item.duration,
        blockedTimeAfter: this.item.blockedTimeAfter,
        locationId: this.locationId
      })
      if (overlap) {
        this.$set(this.validationErrors, 'overlapping_resource', {
          from: formatter.formatTime(overlap.start, this.company.timeFormat),
          to: formatter.formatTime(overlap.end, this.company.timeFormat)
        })
      }
    },
    async validateStaff() {
      if (!this.item.staff || !this.item.service) {
        return
      }
      let locations = [this.staffMap[this.item.staff].location].concat(this.staffMap[this.item.staff].locations || [])
      locations = uniq(locations)

      let overlaps = await Promise.all(
        locations.map(locationId => {
          return this.findStaffOverlap({
            staffId: this.item.staff,
            date: this.date,
            itemId: this.item._id,
            start: this.item.start,
            duration: this.item.duration,
            blockedTimeAfter: this.item.blockedTimeAfter,
            locationId: locationId
          })
        })
      )
      overlaps = overlaps.filter(o => o)

      if (overlaps.length) {
        this.$set(this.validationErrors, 'overlapping_appointment', {
          from: formatter.formatTime(overlaps[0].start, this.company.timeFormat),
          to: formatter.formatTime(overlaps[0].end, this.company.timeFormat)
        })
      }
    },

    async update() {
      this.$emit('input', {...this.item})
      await this.validate()
    }
  }
}
</script>
<style scoped>
.has-border-blue {
  height: 100%;
  width: 1px;
  background: #ccc;
  margin: 0 1rem;
}
</style>
