<template lang="pug">
  .message-container    
    
    draggable(class="msg-block" tag="div" v-model="items" v-bind="dragOptions" :move="onMove" @start="isDragging=true" @end="onDragEnd")
      div(v-for='(item, index) in items' :key="index")
        .msg-element(:class="readonly ? 'readonly' : ''")
          TextElement(v-model="item.message" v-if="item.message.type === 'text'" @input="update" :advancedMode="advancedMode && hasButtons" :readonly="readonly")
          ImageElement(v-model="item.message" v-if="item.message.type === 'image'" @input="update" :advancedMode="advancedMode && hasButtons" :readonly="readonly")
          GIFElement(v-model="item.message" v-if="item.message.type === 'gif'" @input="update" :advancedMode="advancedMode && hasButtons" :readonly="readonly")
          CouponMessageElement(v-model="item.message" v-if="item.message.type === 'coupon'" @input="update" :advancedMode="advancedMode && hasButtons" :readonly="readonly")
          
          .msg-controls(v-if="advancedMode")
            span.icon.action-icon.has-text-primary(@click="remove(item)")
              i.mdi.mdi-close.mdi-24px
            br
            span.icon.action-icon.has-text-primary.move-icon(@click="moveUp(item)")
              i.mdi.mdi-chevron-up.mdi-24px
            br
            span.icon.action-icon.has-text-primary.move-icon(@click="moveDown(item)")
              i.mdi.mdi-chevron-down.mdi-24px
        div(style='clear:both;')
    .buttons(v-if="advancedMode")
      button.button.is-outlined.is-primary(@click="addText") 
        span.icon
          i.mdi.mdi-text
        span Text
      button.button.is-outlined.is-primary(@click="addImage") 
        span.icon
          i.mdi.mdi-image
        span Image
      button.button.is-outlined.is-primary(@click="addGif") 
        span.icon
          i.mdi.mdi-file-gif-box
        span GIF
      button.button.is-outlined.is-primary(@click="addCoupon")
        span.icon
          i.mdi.mdi-ticket
        span Coupon
</template>

<script>
import draggable from 'vuedraggable'
import ImageElement from './messageElements/ImageElement'
import TextElement from './messageElements/TextElement'
import SMSElement from './messageElements/SMSElement'
import GIFElement from './messageElements/GIFElement'
import CouponMessageElement from './messageElements/CouponMessageElement'
import GIFSelectModal from './GIFSelectModal'
import SelectCouponModal from '../coupon/SelectCouponModal'
import SMSComposer from '../sms/SMSComposer'
import {mapState} from 'vuex'
import cloneDeep from 'lodash/cloneDeep'
export default {
  components: {
    TextElement,
    ImageElement,
    GIFElement,
    CouponMessageElement,
    SMSComposer,
    SMSElement,
    draggable
  },
  props: {
    value: {type: Array, default: () => []},
    advancedMode: {type: Boolean, default: false},
    readonly: {type: Boolean, default: false},
    emptyCoupon: {type: Boolean, default: false},
    hasButtons: {type: Boolean, default: true}
  },
  data() {
    return {
      isDragging: false,
      delayedDragging: false,
      smsMessage: undefined,
      items: []
    }
  },
  computed: {
    ...mapState('company', ['company']),
    dragOptions() {
      return {
        animation: 0,
        group: 'description',
        disabled: !this.advancedMode,
        ghostClass: 'ghost'
      }
    }
  },
  watch: {
    isDragging(newValue) {
      if (newValue) {
        this.delayedDragging = true
        return
      }
      this.$nextTick(() => {
        this.delayedDragging = false
      })
    },
    value(val) {
      if (this.value === val) {
        return
      }
      this._initMessages(val)
    }
  },
  created() {
    this._initMessages(this.value)
  },
  methods: {
    _initMessages(val) {
      if (val.length && val[0].type === 'sms') {
        this.smsMessage = val.length ? {message: cloneDeep(val[0])} : undefined
        this.items = val.slice(1).map(m => {
          return {message: cloneDeep(m)}
        })
      } else {
        this.items = val.map(m => {
          return {message: cloneDeep(m)}
        })
      }
    },
    update() {
      this.$emit(
        'input',
        ((this.smsMessage && [this.smsMessage]) || []).concat(this.items).map(v => v.message)
      )
    },
    onMove({relatedContext, draggedContext}) {
      const relatedElement = relatedContext.element
      const draggedElement = draggedContext.element

      this.update()
      return (!relatedElement || !relatedElement.fixed) && !draggedElement.fixed
    },
    onDragEnd() {
      this.isDragging = false

      this.update()
    },
    convertToLines(text) {
      let lines = text.split('\n')
      let res = []
      for (let line of lines) {
        res.push({text: line})
        res.push({break: true})
      }
      return res
    },
    remove(item) {
      this.$buefy.dialog.confirm({
        message: `Are you sure you want to remove the message`,
        cancelText: 'Cancel',
        confirmText: 'Remove',
        type: 'is-danger',
        onConfirm: () => {
          if (item) {
            this.items.splice(this.items.indexOf(item), 1)
          } else {
            this.smsMessage = undefined
          }
          this.update()
        }
      })
    },
    moveUp(item) {
      let idx = this.items.indexOf(item)
      if (idx === 0) {
        return
      }
      this.messageMove(idx, idx - 1)
    },
    moveDown(item) {
      let idx = this.items.indexOf(item)
      if (idx === this.items.length - 1) {
        return
      }
      this.messageMove(idx, idx + 1)
    },
    messageMove(from, to) {
      if (to >= this.items.length) {
        let k = to - this.items.length + 1
        while (k--) {
          this.items.push(undefined)
        }
      }
      this.items.splice()
      this.items.splice(to, 0, this.items.splice(from, 1)[0])
    },

    addText() {
      this.items.push({message: {type: 'text'}})
      this.update()
    },
    addImage() {
      this.items.push({message: {type: 'image'}})
      this.update()
    },
    addGif() {
      this.$buefy.modal.open({
        parent: this,
        component: GIFSelectModal,
        canCancel: ['outside', 'escape'],
        width: 640,
        hasModalCard: true,
        events: {
          success: ({url, title, smallUrl, query}) => {
            this.items.push({message: {type: 'gif', url, title, smallUrl, query}})
            this.update()
          }
        }
      })
    },
    addCoupon() {
      if (this.emptyCoupon) {
        this.items.push({message: {type: 'coupon', ids: []}})
        return
      }
      this.$buefy.modal.open({
        parent: this,
        component: SelectCouponModal,
        width: 960,
        canCancel: ['outside', 'escape'],
        events: {
          success: coupon => {
            this.items.push({message: {type: 'coupon', ids: [coupon._id]}})
            this.update()
          }
        }
      })
    }
  }
}
</script>
