<template lang="pug">
  div
    screen-title(title="memberships.memberships_title")
    .box
        b-table(
          :data="memberships"
          :loading="searching" 
          class="is-vertically-aligned" 
          custom-row-key="_id"
          paginated
          backend-pagination
          :total="total"
          :per-page="perPage"
          @page-change="onPageChange"

          backend-sorting
          :default-sort-direction="defaultSortOrder"
          :default-sort="[sortField, sortOrder]"
          @sort="onSort"
          hoverable=true 
          @click="openDetails")
          b-table-column(field="name" :label="$t('memberships.name_heading')" v-slot="props" sortable)
            span {{props.row.name}}
          
          b-table-column(field="invoice" :label="$t('memberships.invoice_heading')" v-slot="props" sortable)
            invoice-view(:value="props.row.sale" :invoice="props.row.invoice")
          b-table-column(field="status" :label="$t('memberships.status_heading')" v-slot="props" centered)
            .tag.is-warning(v-if="props.row.remainingServices.length === 0") {{$t('validity.used')}}
            .tag.is-danger(v-else-if="new Date(props.row.expire) < new Date()") {{$t('validity.expired')}}
            .tag.is-success(v-else) {{$t('validity.valid')}}
          b-table-column(field="value" :label="$t('memberships.value_heading')" v-slot="props" sortable numeric)
            currency-view(:value="props.row.value")
          b-table-column(field="remaining" :label="$t('memberships.remaining_heading')" v-slot="props" sortable numeric)
            
          
          b-table-column(field="expire" :label="$t('memberships.expire_heading')" v-slot="props" sortable numeric)
            date-view(:value="props.row.expire" format="L")
          b-table-column(field="created" :label="$t('memberships.created_heading')" v-slot="props" sortable numeric)
            date-view(:value="props.row.created" format="L LT")
            
          template(slot="empty")
            empty-content(v-if="!searching" title="memberships.no_memberships")
</template>
<script>
import MembershipsApi from '@/api/memberships'
import MembershipPackageView from './MembershipPackageView'

export default {
  data() {
    return {
      memberships: [],
      total: 0,
      sortField: 'created',
      sortOrder: 'desc',
      page: 1,
      perPage: 10,
      defaultSortOrder: 'desc',
      searching: false
    }
  },

  created() {
    this.fetchData()
  },

  methods: {
    async fetchData() {
      this.searching = true
      try {
        const {total, data} = await MembershipsApi.search({
          sortBy: this.sortField,
          sortDir: this.sortOrder,
          page: this.page,
          perPage: this.perPage
        })
        this.memberships = data
        this.total = total
      } catch (err) {
        this.$handleAPIError(err)
      }
      this.searching = false
    },
    onSort(field, order) {
      this.sortField = field
      this.sortOrder = order
      this.fetchData()
    },
    onPageChange(page) {
      this.page = page
      this.fetchData()
    },
    onSearch() {
      this.page = 1
      this.fetchData()
    },

    openDetails(membership) {
      this.$buefy.modal.open({
        parent: this,
        width: 640,
        props: {value: membership},
        component: MembershipPackageView,
        canCancel: ['outside', 'escape', 'x']
      })
    }
  }
}
</script>
