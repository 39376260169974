<template lang="pug">
  b-field(v-if="dropdown" v-show="activeLocations.length > 1  && !$rfProfileScope()" :label="label" :type="errors.has('location') ? 'is-danger': ''" :message="errors.has('location') ? errors.first('location') : ''" )
    b-select(
      v-model="location" 
      v-validate="'required'" 
      name="location" 
      placeholder="Select a location" 
      icon="map-marker-outline" 
      @input="locationChange" 
      :size="size" 
      :disabled="$rfLocationScope()")
      option(v-for="location in activeLocations" :value="location._id") {{location.title}}
  a(v-else v-show="activeLocations.length > 1  && !$rfProfileScope()" @click="selectLocation") 
    span.icon
      i.mdi.mdi-map-marker-outline
    location-view(:value="location")

</template>

<script>
import {mapGetters, mapState} from 'vuex'
import LocationSelectModal from './LocationSelectModal'

export default {
  name: 'LocationSelect',
  props: {
    dropdown: {type: Boolean, default: true},
    value: {type: String, default: undefined},
    label: {type: String, default: undefined},
    size: {type: String, default: undefined}
  },
  inject: ['$validator'],
  data() {
    return {
      location: null
    }
  },
  computed: {
    ...mapState('auth', ['user']),
    ...mapGetters('location', ['activeLocations']),
    scopedLocation() {
      return this.$rfProfileScope() || this.$rfLocationScope()
    }
  },
  watch: {
    value(newVal) {
      if (newVal !== this.location) {
        this.updateLocation()
      }
    }
  },
  async created() {
    if (this.scopedLocation) {
      this.location = this.user.location
      this.locationChange()
    } else {
      this.updateLocation()
    }
  },
  methods: {
    updateLocation() {
      this.location =
        this.value ||
        (localStorage.getItem('preferred-location') !== 'undefined' && localStorage.getItem('preferred-location')) ||
        this.user.location ||
        this.activeLocations[0]._id
      let currentLocation = this.activeLocations.find(l => l._id === this.location)
      if (!currentLocation) {
        this.location = this.activeLocations[0]._id
      }
      this.locationChange()
    },
    locationChange() {
      if (this.location) {
        localStorage.setItem('preferred-location', this.location)
      }
      this.$emit('input', this.location)
    },
    selectLocation() {
      if (this.$rfLocationScope()) {
        return
      }
      this.$buefy.modal.open({
        parent: this.$parent,
        component: LocationSelectModal,
        hasModalCard: true,
        fullScreen: this.isMobile(),
        events: {
          input: location => {
            this.location = location
            this.locationChange()
          }
        },
        canCancel: ['escape', 'outside']
      })
    }
  }
}
</script>
