import Vue from 'vue'

export default {
  search(params) {
    return Vue.http.get('vouchers', {params}).then(res => res.body)
  },
  updateVoucher(id, data) {
    return Vue.http.put(`vouchers/${id}`, data).then(res => res.body)
  },
  createVoucher(data) {
    return Vue.http.post('vouchers', data).then(res => res.body)
  },
  checkCode(code) {
    return Vue.http.post('vouchers/code', {code})
  },
  getVoucherById(id) {
    return Vue.http.get(`vouchers/${id}`).then(res => res.body)
  }
}
