import Vue from 'vue'

export default {
  getLedger(params) {
    return Vue.http.get('ledger', {params}).then(res => res.body)
  },
  getSessions(params) {
    return Vue.http.get('ledger/sessions', {params}).then(res => res.body)
  },
  getRegisterSession(params) {
    return Vue.http.get('ledger/session', {params}).then(res => res.body)
  },
  getBalance(params) {
    return Vue.http.get('ledger/balance', {params}).then(res => res.body)
  },
  getLiabilities(params) {
    return Vue.http.get('ledger/liabilities', {params}).then(res => res.body)
  },
  registerPayout(data) {
    return Vue.http.post(`ledger/payout`, data).then(res => res.body)
  },
  registerIn(data) {
    return Vue.http.post(`ledger/in`, data).then(res => res.body)
  },
  registerOut(data) {
    return Vue.http.post(`ledger/out`, data).then(res => res.body)
  },
  openRegister(data) {
    return Vue.http.post(`ledger/open`, data).then(res => res.body)
  },
  closeRegister(data) {
    return Vue.http.post(`ledger/close`, data).then(res => res.body)
  }
}
