<template lang="pug">
span(v-if="name") 
  span {{ name }}
  b-tooltip(v-if="showIsNew && !customer.sales" append-to-body position="is-top" :label="$t('appointment.new_client')") 
    span.icon
      i.mdi.mdi-new-box
span.has-text-grey(v-else) {{ $t('misc.unknown') }} 
</template>
<script>
import {mapActions} from 'vuex'
export default {
  name: 'CustomerNameView',
  props: {
    value: {type: [Object, String], default: undefined},
    showIsNew: {type: Boolean, default: false},
    showPhone: {type: Boolean, default: false}
  },
  data() {
    return {
      customer: undefined
    }
  },
  computed: {
    name() {
      if (!this.customer) {
        return
      }
      if (!this.customer.firstName && !this.customer.lastName && this.showPhone && this.customer.phone) {
        return this.customer.phone
      }
      return [this.customer.firstName, this.customer.middleName, this.customer.lastName].filter(c => c).join(' ')
    }
  },
  watch: {
    value() {
      this.init()
    }
  },
  async created() {
    await this.init()
  },
  methods: {
    ...mapActions('customers', ['fetchCustomer']),
    async init() {
      if (this.value && typeof this.value === 'string') {
        this.customer = await this.fetchCustomer(this.value)
      } else {
        this.customer = this.value
      }
    }
  }
}
</script>
