<template lang="pug">
  figure.is-rounded.image(v-if="prod")
    img(:src="src" @error="onInvalidImage")
</template>

<script>
import {mapActions} from 'vuex'
export default {
  props: {
    product: {type: [Object, String], required: true}
  },
  data() {
    return {
      prod: undefined
    }
  },
  computed: {
    src() {
      return this.prod.picture || require('@/assets/noimage_placeholder.png')
    }
  },
  async created() {
    if (typeof this.product === 'string') {
      await this.fetchData()
    } else {
      this.prod = this.product
    }
  },
  methods: {
    ...mapActions('inventory', ['fetchProduct']),
    async fetchData() {
      try {
        this.prod = await this.fetchProduct(this.product)
      } catch (err) {
        this.$handleAPIError(err)
      }
    },
    onInvalidImage(event) {
      event.target.src = require('@/assets/noimage_placeholder.png')
    }
  }
}
</script>
