module.exports = {
  BEAUTY: 'beauty',
  HAIRDRESSING: 'hairdressing',
  SPA: 'spa',
  BARBERSHOP: 'barbershop',
  NAIL_SALON: 'nail_salon',
  HAIR_REMOVAL: 'hair_removal',
  TANNING_STUDIO: 'tanning_studio',
  MASSAGE_THERAPY: 'massage_therapy',
  MAKEUP_ARTISTRY: 'makeup_artistry',
  AESTHETIC_CLINIC: 'aesthetic_clinic',
  SPECIALIZED_CLINIC: 'specialized_clinic',
  YOGA_STUDIO: 'yoga_studio',
  FITNESS_GYM: 'fitness_gym',
  ACUPUNCTURE: 'acupuncture',
  CHIROPRACTIC: 'chiropractic',
  HOLISTIC_HEALTH: 'holistic_health',
  TATTOO_PARLOR: 'tattoo_parlor',
  PIERCING_STUDIO: 'piercing_studio',
  PHOTO_STUDIO: 'photo_studio',
  INDIVIDUAL_COUNSELING: 'individual_counseling',
  COACHING: 'coaching',
  PERSONAL_TRAINING: 'personal_training',
  LANGUAGE_CENTERS: 'language_center',
  OTHER: 'other'
}
