<template lang="pug">
  navigation-menu(:items="items")
</template>

<script>
import NavigationMenu from '../shared/NavigationMenu'
import PERMISSIONS from '../../../constants/permissions'

export default {
  components: {
    NavigationMenu
  },
  data() {
    return {
      items: [
        {
          path: '/marketing/rewards-program',
          icon: 'chart-box-outline',
          title: 'rewards_program.dashboard.title',
          permission: PERMISSIONS.rewards.VIEW,
          nested: [
            {
              path: '/marketing/rewards-program/settings',
              icon: 'cog-outline',
              title: 'rewards_program.settings.title',
              permission: PERMISSIONS.rewards.VIEW
            }
          ]
        },
        {
          path: '/marketing/referral-program',
          icon: 'account-plus',
          title: 'referral_program.title',
          permission: PERMISSIONS.referrals.VIEW,
          nested: [
            {
              path: '/marketing/referral-program/settings',
              icon: 'cog-outline',
              title: 'referral_program.settings.title',
              permission: PERMISSIONS.referrals.MANAGE_SETTINGS
            }
          ]
        },
        {
          path: '/marketing/reviews',
          icon: 'comment-question-outline',
          title: 'reviews.requests.title',
          'data-qa': 'tab-requests',
          permission: PERMISSIONS.reviews.ANALYTICS,
          nested: [
            {
              path: '/marketing/reviews/verified',
              icon: 'star-outline',
              title: 'reviews.verified_reviews.title',
              'data-qa': 'tab-verified',
              permission: PERMISSIONS.reviews.VERIFY
            },
            {
              path: '/marketing/reviews/platforms',
              icon: 'cog-outline',
              title: 'reviews.platform_settings.title',
              'data-qa': 'tab-platforms',
              permission: PERMISSIONS.reviews.VIEW
            }
          ]
        },
        {
          path: '/marketing/automation',
          icon: 'cogs',
          title: 'navbar.automations',
          permission: PERMISSIONS.automations.AUTOMATIONS_VIEW
        },
        {
          path: '/marketing/coupons',
          icon: 'wallet-giftcard',
          title: 'navbar.coupons',
          permission: PERMISSIONS.coupons.COUPONS_VIEW,
          nested: [
            {
              path: '/marketing/coupons/types',
              icon: 'cog-outline',
              title: 'coupons.coupon_types',
              permission: PERMISSIONS.coupons.EDIT
            }
          ]
        },
        {
          path: '/marketing/campaigns',
          icon: 'bullhorn-outline',
          title: 'navbar.broadcasts',
          permission: PERMISSIONS.campaigns.CAMPAIGNS_VIEW
        },
        {
          path: '/marketing/promolinks',
          icon: 'link-variant-plus',
          title: 'navbar.promolinks',
          permission: PERMISSIONS.promolinks.PROMOLINKS_VIEW
        }
      ]
    }
  }
}
</script>
