<template lang="pug">
 
</template>

<script>
export default {
  name: 'Signout',
  created() {
    this.$router.push('/signin')
    this.$store.dispatch('signout')
  }
}
</script>
