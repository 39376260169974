<template lang="pug">
  article.msg.msg-left.image-card(:class="!valid ? 'is-invalid' : ''")
    img.gif-preview(:src="message.url" @click="!readonly && changeGIF()")
    Buttons(v-model="message.buttons" :editable="advancedMode" @input="onInput")
</template>

<script>
import Buttons from './Buttons'
import BaseElement from './BaseElement'
import GIFSelectModal from '../GIFSelectModal'

export default {
  components: {
    Buttons
  },
  extends: BaseElement,
  computed: {
    valid() {
      return !!this.message.url
    }
  },
  methods: {
    onInput() {
      this.message.valid = this.valid
      this.$emit('input', this.message)
    },
    changeGIF() {
      this.$buefy.modal.open({
        parent: this,
        component: GIFSelectModal,
        canCancel: ['outside', 'escape'],
        hasModalCard: true,
        props: {
          current: {
            url: this.message.url,
            title: this.message.title,
            smallUrl: this.message.smallUrl,
            query: this.message.query
          }
        },
        width: 640,
        events: {
          success: ({url, title, smallUrl, query}) => {
            this.message.url = url
            this.message.title = title
            this.message.smallUrl = smallUrl
            this.message.query = query
            this.onInput()
          }
        }
      })
    }
  }
}
</script>

<style scoped>
.gif-preview {
  cursor: pointer;
}
</style>
