<template lang="pug">
div
  
  div(v-if="actualShifts.length")
    .notification.has-text-centered.mb-2(:class="custom ? 'is-warning': 'is-success'")
      p(v-for="shift in formattedShifts") {{shift}}
    .has-text-centered.is-size-7(v-if="hasMultipleLocations")
      span.tag(:class="actualLocation && actualLocation !== location ? 'is-warning': 'is-success'")
        span.icon
          i.mdi.mdi-map-marker
        location-view(:value="actualLocation || location")    
    .has-text-centered.is-size-7(v-else-if="actualLocation && actualLocation !== defaultLocation")
      span.tag.is-danger
        span.icon
          i.mdi.mdi-map-marker
        location-view(:value="actualLocation")
  .notification.is-warning.has-text-centered.mb-0(v-else-if="custom") 
    span.icon
      i.mdi.mdi-calendar-remove
  .notification.is-light.has-text-centered.mb-0(v-else) 
    span.icon
      i.mdi.mdi-plus
  

</template>
<script>
import {mapGetters, mapState} from 'vuex'
import formatter from '../../../shared/formatter'
export default {
  props: {
    shifts: {type: Array, default: () => []},
    location: {type: String, default: undefined},
    customShifts: {type: Array, default: () => []},
    customLocation: {type: String, default: undefined},
    defaultLocation: {type: String, default: undefined},
    custom: {type: Boolean, default: false}
  },
  computed: {
    ...mapState('company', ['company']),
    ...mapGetters('location', ['hasMultipleLocations']),
    actualShifts() {
      return this.custom ? this.customShifts : this.shifts
    },
    actualLocation() {
      return this.custom ? this.customLocation : this.location
    },
    formattedShifts() {
      return this.actualShifts.map(shift => {
        let start = formatter.formatTime(shift.start, this.company.timeFormat)
        let end = formatter.formatTime(shift.end, this.company.timeFormat)
        return `${start} - ${end}`
      })
    }
  }
}
</script>
<style scoped>
.notification {
  padding: 0.5rem;
}
.notification:hover {
  background-color: hsl(141, 53%, 53%);
}
</style>
