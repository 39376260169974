<template lang="pug">
  .box(v-if="upcomingInvoice")
    h3.title.is-5 Upcoming invoice
    p.subtitle.is-6 This is a preview of the invoice that will be billed on <strong>{{upcomingInvoice.created | timestampToDay}}</strong>. It may change if the subscription is updated.
    table.table.is-fullwidth
      tr
        th.has-text-right Amount
        th.has-text-centered Currency
        th.has-text-centered Status
        th.has-text-centered Period
        th.has-text-centered Invoice Number
      tr
        td.has-text-right 
          strong 
            invoice-currency(:value="upcomingInvoice.amount" :currencyCode="upcomingInvoice.currency")
        td.has-text-centered {{upcomingInvoice.currency.toUpperCase()}}
        td.has-text-centered
          invoice-status-tag(:status="upcomingInvoice.status")
        td.has-text-centered {{upcomingInvoice.periodStart | timestampToDay}} - {{upcomingInvoice.periodEnd | timestampToDay}}
        td.has-text-centered {{upcomingInvoice.number}}
</template>

<script>
import InvoiceStatusTag from './InvoiceStatusTag'
import InvoiceCurrency from './InvoiceCurrency'
import {mapState} from 'vuex'

export default {
  components: {
    InvoiceStatusTag,
    InvoiceCurrency
  },
  computed: {
    ...mapState('billing', ['upcomingInvoice', 'upcomingInvoiceLoading'])
  },
  async created() {
    await this.fetchData()
  },
  methods: {
    async fetchData() {
      try {
        await this.$store.dispatch('billing/fetchUpcomingInvoice')
      } catch (error) {
        this.$handleAPIError(error)
      }
    }
  }
}
</script>
<style scoped>
table.table th {
  background: #f1f1f1;
}
</style>
