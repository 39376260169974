<template lang="pug">
  div
    template(v-for="shift in shifts") 
      b-field(grouped)
        b-field(expanded)
          timepicker(v-model="shift.start" expanded :max-time="shift.end" @input="onShiftChange(shift, 'start')")
        b-field(expanded)
          timepicker(v-model="shift.end" expanded :min-time="shift.start" @input="onShiftChange(shift, 'end')")
        b-field(v-if="shifts.length > 1")
          button.button.is-white(@click="remove(shift)")
            span.icon.has-text-danger
              i.mdi.mdi-trash-can-outline.mdi-24px
    b-field
      a.button.is-outlined.is-primary.is-rounded(@click="addShift") 
        span.icon
          i.mdi.mdi-plus-circle-outline.mdi-24px
        span {{$t('staff.shift_edit.actions.add_new_shift')}}
</template>
<script>
export default {
  name: 'ShiftEditor',
  props: {
    value: {type: Array, default: () => []},
    suggestedStart: {type: Number, default: 9 * 60},
    suggestedEnd: {type: Number, default: 18 * 60}
  },
  data() {
    return {
      shifts: []
    }
  },
  created() {
    this.shifts = this.value.map(shift => {
      return {start: shift.start, end: shift.end}
    })
  },
  methods: {
    addShift() {
      if (this.shifts.length) {
        let previousShift = this.shifts[this.shifts.length - 1]
        this.shifts.push({
          start: Math.min(previousShift.end + 60, 23 * 60),
          end: Math.min(previousShift.end + 120, 24 * 60)
        })
      } else {
        this.shifts.push({
          start: this.suggestedStart,
          end: this.suggestedEnd
        })
      }
      this.update()
    },
    remove(shift) {
      this.shifts.splice(this.shifts.indexOf(shift), 1)
      this.update()
    },
    onShiftChange(shift, part) {
      if (shift.start <= shift.end) {
        return this.update()
      }
      if (part === 'start') {
        shift.end = shift.start
      } else {
        shift.start = shift.end
      }
      this.update()
    },
    update() {
      this.$emit('input', this.shifts)
    }
  }
}
</script>
