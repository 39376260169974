<template lang="pug">
.modal-card
  header.modal-card-head
    p.modal-card-title(data-qa="modal-header") 
      b(v-t="'ledger.cash_register_title'")
      span.px-3 | 
      b.has-text-success(v-if="session && !session.close") {{$t('ledger.status_open')}}
      
      b.has-text-danger(v-else) {{$t('ledger.status_closed')}}
      template(v-if="hasMultipleLocations") 
        span.px-3 | 
        b
          span.icon
            i.mdi.mdi-map-marker-outline 
          location-view(:value="user.location")
    div
      a.button.is-white.is-small(@click="$emit('close')")
        span.icon
          i.mdi.mdi-close.mdi-24px
  section.modal-card-body
    .container(v-if="!session && !loading")
      .hero.is-medium
        .hero-body.has-text-centered
          span.icon.is-large
            i.mdi.mdi-cash-register.mdi-36px
          h3.title(v-t="'ledger.start_title'")
          p.subtitle(v-t="'ledger.start_subtitle'")
          .buttons.is-centered
            button.button.is-medium.is-primary(@click="openCloseRegister" v-t="'ledger.actions.start'" :disabled="!$rfHasPermission('CASH_REGISTER_MANAGE')") 
    .container(v-else-if="session")
      .columns.is-centered
        .column.is-12
          //p.title.is-5.mb-2 {{$t('ledger.cash_register_header')}}
          .list.p-2
            .list-item
              .level
                .level-item.is-justify-content-left
                  div
                    p.heading {{$t('ledger.status_label')}}
                    h3.title.is-3.has-text-success(v-if="!session.close") {{$t('ledger.status_open')}}
                    h3.title.is-3.has-text-danger(v-else) {{$t('ledger.status_closed')}}
                .level-item.is-justify-content-left
                  div
                    p.heading {{$t('ledger.opened_by')}}
                    h3.title.is-6.mb-5
                      staff-view(:id="session.openBy")
                    p.subtitle.is-7
                      date-view(:value="session.open" format="LL LT")
                .level-item.is-justify-content-left(v-if="session.close")
                  div
                    p.heading {{$t('ledger.closed_by')}}
                    h3.title.is-6.mb-5
                      staff-view(:id="session.closeBy")
                    p.subtitle.is-7
                      date-view(:value="session.close" format="LL LT")
                .level-right
                  .level-item.is-justify-content-right
                    div
                      //p.heading Operation
                      button.button.is-success.is-medium(v-if="session.close" @click="openCloseRegister" v-t="'actions.open'" :disabled="!$rfHasPermission('CASH_REGISTER_MANAGE')")
                      button.button.is-danger.is-medium(v-else @click="openCloseRegister" v-t="'ledger.actions.close_day'" :disabled="!$rfHasPermission('CASH_REGISTER_MANAGE')")
                  .level-item
                    .buttons
                      button.button.is-medium(@click="openTransactions") 
                        span.icon
                          i.mdi.mdi-format-list-bulleted
                        span {{$t('ledger.actions.view_transaction')}}
                      button.button.is-medium(@click="openSessionHistory") 
                        span.icon
                          i.mdi.mdi-cash-register
                        span {{$t('ledger.actions.view_session_history')}}
      .columns(v-if="balance")
        .column.is-6
          p.title.is-5.mb-2(v-t="'ledger.balance_header'")
          .list.p-2
            .list-item
              .level
                .level-item.is-justify-content-left
                  div
                    p.heading(v-t="'ledger.cash_float'")
                    h3.title.is-3 
                      currency-view(:value="session.openBalance")
                .level-item.is-justify-content-left
                  div
                    p.heading(v-t="'ledger.current_balance'")
                    h3.title.is-3 
                      currency-view(:value="session.openBalance + balance.in.total - balance.out.total")
                .level-item.is-justify-content-left
                  div
                    p.heading(v-t="'ledger.net_income'")
                    h3.title.is-3 
                      currency-view(:value="balance.in.total - balance.out.total")
        .column.is-6(v-if="session.openDifference || session.closeDifference")
          p.title.is-5.mb-2(v-t="'ledger.deficit_header'")
          .columns
            .column.is-6(v-if="session.openDifference")
              .notification.is-danger.p-4
                p.heading(v-if="session.openDifference > 0" v-t="'ledger.float_surplus'")
                p.heading(v-else v-t="'ledger.float_deficit'")
                h3.title.is-3
                  currency-view(:value="session.openDifference")
            .column.is-6(v-if="session.closeDifference")
              .notification.is-danger.p-4
                p.heading(v-if="session.closeDifference > 0" v-t="'ledger.end_of_day_surplus'")
                p.heading(v-else v-t="'ledger.end_of_day_deficit'")
                h3.title.is-3
                  currency-view(:value="session.closeDifference")
      .columns.is-centered(v-if="balance")
        
      .columns(v-if="balance")
        .column
          .is-flex.is-align-items-center.mb-3
            .is-flex-grow-1
              h3.title.is-4.m-0 {{$t('ledger.cash_in_heading')}}
            .is-flex-shrink-0
              button.button.is-success(@click="registerCashIn" :disabled="!session || session.close || !$rfHasPermission('CASH_REGISTER_MANAGE')") 
                span.icon
                  i.mdi.mdi-arrow-down-bold-box-outline
                span {{$t('ledger.actions.cash_in')}}
          .list
            .list-item.is-size-5
              span {{$t('register_transactions.sale')}}
              span.is-pulled-right
                strong  
                  currency-view(:value="balance.in.sale")
            .list-item.is-size-6
              span.has-text-grey.pl-3 {{$t('register_transactions.sale_card')}}
              span.is-pulled-right
                span  
                  currency-view(:value="balance.noncash.card")
            .list-item.is-size-6
              span.has-text-grey.pl-3 {{$t('register_transactions.point_redeems')}}
              span.is-pulled-right
                span  
                  currency-view(:value="balance.noncash.points")
            .list-item.is-size-6
              span.has-text-grey.pl-3 {{$t('register_transactions.voucher_redeems')}}
              span.is-pulled-right
                span  
                  currency-view(:value="balance.noncash.voucher")
            .list-item.is-size-6
              span.has-text-grey.pl-3 {{$t('register_transactions.other_non_cash')}}
              span.is-pulled-right
                span  
                  currency-view(:value="balance.noncash.other")
            
            .list-item.is-size-5
              span {{$t('register_transactions.tip')}}
              span.is-pulled-right
                strong  
                  currency-view(:value="0")
            //.list-item.is-size-5
              span Sales non cash
              span.is-pulled-right
                strong  
                  currency-view(:value="342")
            //.list-item.is-size-5
              span Sales total
              span.is-pulled-right
                strong  
                  currency-view(:value="342")
            .list-item.is-size-5
              span {{$t('register_transactions.return')}}
              span.is-pulled-right
                strong  
                  currency-view(:value="balance.in.return + balance.in.change")
            .list-item.is-size-5
              span {{$t('register_transactions.addition')}}
              span.is-pulled-right
                strong  
                  currency-view(:value="balance.in.addition")
            .list-item.is-size-5
              strong {{$t('ledger.total_in_label')}}
              span.is-pulled-right
                strong  
                  currency-view(:value="balance.in.total")
          

        .column
          .is-flex.is-align-items-center.mb-3
            .is-flex-grow-1
              h3.title.is-4.m-0 {{$t('ledger.cash_out_heading')}}
            .is-flex-shrink-0
              button.button.is-warning(@click="registerExpense" :disabled="!session || session.close || !$rfHasPermission('CASH_REGISTER_MANAGE')") 
                span.icon
                  i.mdi.mdi-arrow-up-bold-box-outline
                span {{$t('ledger.actions.cash_out')}}
          .list
            .list-item.is-size-5
              span {{$t('register_transactions.expense')}}
              span.is-pulled-right
                strong  
                  currency-view(:value="balance.out.expense")
            .list-item.is-size-5
              span {{$t('register_transactions.stock')}}
              span.is-pulled-right
                strong  
                  currency-view(:value="balance.out.stock")
            .list-item.is-size-5
              span {{$t('register_transactions.out')}}
              span.is-pulled-right
                strong  
                  currency-view(:value="balance.out.out")
            .list-item.is-size-5
              span {{$t('register_transactions.refund')}}
              span.is-pulled-right
                strong  
                  currency-view(:value="balance.out.refund")
            .list-item.is-size-5(v-if="company.registerCommissions")
              span {{$t('register_transactions.payout')}}
              span.is-pulled-right
                strong  
                  currency-view(:value="balance.out.payout")
            .list-item.is-size-5
              strong {{$t('ledger.total_out_label')}}
              span.is-pulled-right
                strong  
                  currency-view(:value="balance.out.total")
        .column(v-if="company.registerCommissions")
          h3.title.is-4(v-t="'ledger.liabilities_heading'")
          .list(v-if="totalDue")
            .list-item.is-size-5(v-for="liab in liabilities" :key="liab.staff")
              .level.is-mobile
                .level-left
                  .level-item
                    staff-view(:id="liab.staff")
                .level-right
                  
                  .level-item
                    strong  
                      currency-view(:value="liab.due")
                  .level-item
                    button.button.is-primary(@click="payout(liab)" v-t="'ledger.actions.pay'" :disabled="!session || session.close || !$rfHasPermission('CASH_REGISTER_MANAGE')")
            .list-item.is-size-5
              strong(v-t="'ledger.total_liabilities_label'")
              span.is-pulled-right
                strong  
                  currency-view(:value="totalDue")
          .notification.p-5(v-else)
            p.title.is-5.has-text-centered.has-text-grey(v-t="'ledger.no_liabilities'")
      
   
      //.column.is-6
        h3.title.is-4 Payouts
</template>
<script>
import {mapActions, mapGetters, mapState} from 'vuex'

import OpenCloseRegister from './OpenCloseRegister'
import LiabilityPayout from './LiabilityPayout'
import Transactions from './Transactions'
import CashIn from './CashIn'
import CashOut from './CashOut'
import SessionHistory from './SessionHistory'

export default {
  name: 'CashRegister',
  data() {
    return {
      loading: false
    }
  },
  computed: {
    ...mapGetters('ledger', ['totalDue']),
    ...mapState('auth', ['user']),
    ...mapState('ledger', ['session', 'balance', 'liabilities']),
    ...mapState('company', ['company']),
    ...mapGetters('location', ['hasMultipleLocations'])
  },
  async created() {
    try {
      this.loading = true
      await Promise.all([this.fetchSession(), this.fetchLiabilities(), this.fetchBalance()])
    } catch (err) {
      this.$handleAPIError(err)
    }
    this.loading = false
  },
  methods: {
    ...mapActions('ledger', ['fetchSession', 'fetchBalance', 'fetchLiabilities']),

    openCloseRegister() {
      this.$buefy.modal.open({
        parent: this,
        component: OpenCloseRegister,
        hasModalCard: true,
        canCancel: ['escape', 'outside']
      })
    },
    payout({staff, due}) {
      this.$buefy.modal.open({
        parent: this,
        component: LiabilityPayout,
        hasModalCard: true,
        props: {staff, due},
        canCancel: ['escape', 'outside']
      })
    },
    openTransactions() {
      this.$buefy.modal.open({
        parent: this,
        component: Transactions,
        hasModalCard: true,
        width: 960,
        canCancel: ['escape', 'outside']
      })
    },
    openSessionHistory() {
      this.$buefy.modal.open({
        parent: this,
        component: SessionHistory,
        hasModalCard: true,
        width: 960,
        canCancel: ['escape', 'outside']
      })
    },
    registerCashIn() {
      this.$buefy.modal.open({
        parent: this,
        component: CashIn,
        hasModalCard: true,
        width: 960,
        canCancel: ['escape', 'outside']
      })
    },
    registerExpense() {
      this.$buefy.modal.open({
        parent: this,
        component: CashOut,
        hasModalCard: true,
        width: 960,
        canCancel: ['escape', 'outside']
      })
    }
  }
}
</script>
