<template lang="pug">
  div(:class="positionClass")
    h3.title.is-2(:class="textclass" v-if="format")  {{number | pointify}} 
    h3.title.is-2(:class="textclass" v-else)  {{number}} 
    h4.subtitle
    h5.heading {{heading}}
    div(v-if="subnumber !== undefined && subnumber !== ''")
      h3.title.is-3(:class="subtextclass" v-if="format")  {{subnumber | pointify}} 
      h3.title.is-3(:class="subtextclass" v-else)  {{subnumber}} 
      h4.subtitle
      h5.heading {{subheading}}
</template>

<script>
export default {
  name: 'NumberPreview',
  props: {
    number: {type: [String, Number], default: undefined},
    heading: {type: String, default: ''},
    textclass: {type: String, default: undefined},
    subnumber: {type: [String, Number], default: undefined},
    subheading: {type: String, default: ''},
    subtextclass: {type: String, default: undefined},
    format: {type: Boolean, default: false},
    position: {type: String, default: 'left', validator: val => !!~['center', 'right', 'left'].indexOf(val)}
  },
  computed: {
    positionClass() {
      if (this.position === 'right') {
        return 'has-text-right'
      } else if (this.position === 'center') {
        return 'has-text-centered'
      }
      return ''
    }
  }
}
</script>
