import Vue from 'vue'
import {SET_PAYMENTS, SET_PAYMENT, SET_CUSTOMER_PAYMENTS} from './mutationTypes'
import PaymentsApi from '@/api/payments'

export default {
  namespaced: true,
  state: {
    paymentsMap: {},
    paymentsOrder: [],
    customerPaymentsOrder: [],
    currentCustomerId: undefined,
    filter: {},
    total: 0,
    checkingOut: false
  },
  getters: {
    customerPayments(state) {
      return state.customerPaymentsOrder.map(c => state.paymentsMap[c])
    },
    payments(state) {
      return state.paymentsOrder.map(c => state.paymentsMap[c])
    }
  },
  mutations: {
    [SET_CUSTOMER_PAYMENTS](state, {payments, customerId}) {
      for (let payment of payments) {
        Vue.set(state.paymentsMap, payment._id, payment)
      }
      state.customerPaymentsOrder = payments.map(c => c._id)
      state.currentCustomerId = customerId
    },
    [SET_PAYMENTS](state, {payments, total, filter}) {
      for (let payment of payments) {
        Vue.set(state.paymentsMap, payment._id, payment)
      }
      state.paymentsOrder = payments.map(c => c._id)
      state.total = total
      state.filter = filter
    },

    [SET_PAYMENT](state, payment) {
      let current = state.paymentsMap[payment._id] || {}
      for (let prop in payment) {
        if (current[prop] === undefined) {
          Vue.set(current, prop, payment[prop])
        } else {
          current[prop] = payment[prop]
        }
      }
      state.paymentsMap[payment._id] = current
    }
  },
  actions: {
    async fetchCustomerPayments({commit, dispatch}, customerId) {
      commit(SET_CUSTOMER_PAYMENTS, {payments: [], customerId})
      let {payments} = await dispatch('_fetchPayments', {customer: customerId})
      commit(SET_CUSTOMER_PAYMENTS, {payments, customerId})
      return payments
    },

    async fetchPayments({commit, state, dispatch}, filter) {
      filter = filter || {...state.filter}
      let {payments, total} = await dispatch('_fetchPayments', filter)
      commit(SET_PAYMENTS, {payments, total, filter})
      return payments
    },

    async _fetchPayments(_context, params) {
      let {payments, total} = await PaymentsApi.list(params)
      if (!payments.length) {
        return {payments, total}
      }

      return {payments, total}
    },

    async fetchPaymentById({state, commit}, id) {
      if (!state.paymentsMap[id]) {
        let payment = await PaymentsApi.getById(id)
        commit(SET_PAYMENT, payment)
      }
      return state.paymentsMap[id]
    },
    async refreshPaymentStatus({state, commit}, id) {
      let payment = await PaymentsApi.refreshPaymentStatus(id)
      commit(SET_PAYMENT, payment)
      return state.paymentsMap[id]
    },

    async cancelPayment({commit, state}, paymentId) {
      let payment = await PaymentsApi.cancelPayment(paymentId)
      commit(SET_PAYMENT, payment)
      return state.paymentsMap[payment._id]
      //dispatch('refreshPaymentLists', customerId)
    },

    async refundPayment({commit, state}, paymentId) {
      let payment = await PaymentsApi.refundPayment(paymentId)
      commit(SET_PAYMENT, payment)
      return state.paymentsMap[payment._id]
    },

    onPaymentCreated({dispatch}, data) {
      dispatch('refreshPaymentLists', data.customer)
    },

    onPaymentUpdated({dispatch}, data) {
      dispatch('refreshPaymentLists', data.customer)
    },

    async refreshPaymentLists({dispatch, state}, customerId) {
      if (customerId && state.currentCustomerId === customerId) {
        await dispatch('fetchCustomerPayments', customerId)
      }

      if (Object.keys(state.filter).length) {
        await dispatch('fetchPayments')
      }
      // TODO check if there is a need to refresh payments list
    }
  }
}
