import Vue from 'vue'

export default {
  /** SURVEYS REPORTS */
  getFeedbackDistribution(params) {
    return Vue.http.get(`surveys/reports/feedbackDistribution`, {params}).then(res => res.body)
  },
  getDailyAverageFeedback(params) {
    return Vue.http.get('surveys/reports/dailyAverageFeedback', {params}).then(res => res.body)
  },
  getAverageRating(params) {
    return Vue.http.get('surveys/reports/averageRating', {params}).then(res => res.body)
  },
  getSatisfactionRate(params) {
    return Vue.http.get('surveys/reports/satisfactionRate', {params}).then(res => res.body)
  },
  getTopServices(params) {
    return Vue.http.get('surveys/reports/topServices', {params}).then(res => res.body)
  },
  getStaffAverageRating(params) {
    return Vue.http.get('surveys/reports/staffAverageRating', {params}).then(res => res.body)
  },
  getFeedbackReasons(params) {
    return Vue.http.get('surveys/reports/feedbackReasons', {params}).then(res => res.body)
  },
  getSurveysReport(survey, params) {
    return Vue.http.get('surveys/reports/surveys/' + survey, {params}).then(res => res.body)
  },
  getSurveysSpendingReport(survey, params) {
    return Vue.http.get('surveys/reports/surveys/' + survey + '/spending', {params}).then(res => res.body)
  },
  getSurveysAvgSalesReport(survey, params) {
    return Vue.http.get('surveys/reports/surveys/' + survey + '/avgSale', {params}).then(res => res.body)
  },
  getNPSScore(params) {
    return Vue.http.get('surveys/reports/nps', {params}).then(res => res.body)
  },

  /** REWARDS PROGRAM REPORTS */
  getMembersDistribution(params) {
    return Vue.http.get('rewards/reports/membersDistribution', {params}).then(res => res.body)
  },
  getMembershipStats(params) {
    return Vue.http.get('rewards/reports/stats', {params}).then(res => res.body)
  },
  getMembersSalesStats(params) {
    return Vue.http.get('rewards/reports/salesStats', {params}).then(res => res.body)
  },
  getDailyMemberships(params) {
    return Vue.http.get('rewards/reports/dailyMemberships', {params}).then(res => res.body)
  },
  getDailyPoints(params) {
    return Vue.http.get('rewards/reports/dailyPoints', {params}).then(res => res.body)
  },
  getPointsBalance(params) {
    return Vue.http.get('rewards/reports/pointsBalance', {params}).then(res => res.body)
  },
  /** REVIEWS */
  getSocialReviews(params) {
    return Vue.http.get('reviews/reports/clicks', {params}).then(res => res.body)
  },

  /** SALES REPORTS */
  getEstimatedRevenue(params) {
    return Vue.http.get('reports/estimatedRevenue', {params}).then(res => res.body)
  },
  getAverageSale(params) {
    return Vue.http.get('reports/averageSale', {params}).then(res => res.body)
  },
  getAverageAppointments(params) {
    return Vue.http.get('reports/averageAppointments', {params}).then(res => res.body)
  },
  getSalesStats(params) {
    return Vue.http.get('reports/sales', {params}).then(res => res.body)
  },
  getSalesReports(type, params) {
    return Vue.http.get(`reports/sales/${type}`, {params}).then(res => res.body)
  },
  getAppointmentsStats(params) {
    return Vue.http.get('reports/appointments', {params}).then(res => res.body)
  },
  getAppointmentsByStaffStats(params) {
    return Vue.http.get('reports/appointmentsByStaff', {params}).then(res => res.body)
  },
  getAppointmentsSourceStats(params) {
    return Vue.http.get('reports/appointments/source', {params}).then(res => res.body)
  },
  getTopSaleItems(type, params) {
    return Vue.http.get(`reports/top/${type}`, {params}).then(res => res.body)
  },
  getServicesStats(params) {
    return Vue.http.get(`reports/services`, {params}).then(res => res.body)
  },
  getRetailStats(params) {
    return Vue.http.get(`reports/retail`, {params}).then(res => res.body)
  },
  getOccupancy(params) {
    return Vue.http.get(`reports/occupancy`, {params}).then(res => res.body)
  },
  getDailySales(params) {
    return Vue.http.get('reports/dailySales', {params}).then(res => res.body)
  },
  getUpcomingAppointments(params) {
    return Vue.http.get('reports/upcomingAppointments', {params}).then(res => res.body)
  },

  /** CORE REPORTS */
  getRetentionMetrics(params) {
    return Vue.http.get('reports/retentionMetrics', {params}).then(res => res.body)
  },

  /** REFERRAL PROGRAM REPORTS */
  getReferralSignups(params) {
    return Vue.http.get('referrals/reports', {params}).then(res => res.body)
  },

  /** CUSTOMER CARE REPORTS */
  getOpenTickets(params) {
    return Vue.http.get('customerCare/reports/openTickets', {params}).then(res => res.body)
  }
}
