<template lang="pug">
  b-select(
    v-model="resource" 
    :placeholder="placeholder || $t('resources_settings.resource_select_placeholder')" 
    
    @input="onChange" 
    :size="size" 
    )
    option(v-if="allowEmpty" :value="null") {{ $t('appointment.all_resources_label') }}
    option(v-for="resource in availableResources" :value="resource._id" :key="resource._id") {{resource.name}}
</template>

<script>
import {mapGetters} from 'vuex'

export default {
  name: 'ResourceSelect',
  props: {
    value: {type: String, default: undefined},
    location: {type: String, default: undefined},
    service: {type: Object, default: undefined},
    size: {type: String, default: undefined},
    placeholder: {type: String, default: undefined},
    allowEmpty: {type: Boolean, default: false}
  },
  data() {
    return {
      resource: null
    }
  },
  computed: {
    ...mapGetters('resources', ['resources']),
    availableResources() {
      return this.resources.filter(
        s =>
          (!this.location || s.location === this.location) &&
          (!this.service || (this.service.resources && this.service.resources.includes(s._id)))
      )
    }
  },
  async created() {
    this.resource = this.value || null
  },
  methods: {
    onChange() {
      this.$emit('input', this.resource || undefined)
    }
  }
}
</script>
