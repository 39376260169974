<template lang="pug">
.upload-target.is-uploadable(:class="{empty: !url}", @click="changeAsset" :style="{'background-image': coverPreview ? `url('${url}')` : ''}")
  .empty-prompt.has-text-centered.has-text-grey(v-if="!url") 
    span.icon.is-large
      i.mdi.mdi-image-outline.mdi-48px
    br
    span(v-t="'assets_manager.upload'")
  img.image(:src="url" v-if="url && !coverPreview")
  button.delete(@click.stop="remove" v-if="url && removable")
</template>

<script>
import AssetsManager from './AssetsManager'

export default {
  name: 'ImagePreview',
  props: {
    value: {type: String, default: undefined},
    category: {type: String, default: 'assets'},
    coverPreview: {type: Boolean, default: false},
    removable: {type: Boolean, default: false},
    maxSize: {type: Number, default: 1000}
  },
  data() {
    return {
      url: undefined
    }
  },
  watch: {
    value(newVal) {
      this.url = newVal
    }
  },
  created() {
    this.url = this.value
  },
  methods: {
    changeAsset() {
      this.$buefy.modal.open({
        parent: this,
        props: {category: this.category, maxSize: this.maxSize},
        hasModalCard: true,
        fullScreen: true,
        component: AssetsManager,
        canCancel: ['outside', 'escape'],
        events: {
          success: asset => {
            this.url = asset.url
            this.$emit('input', this.url)
          }
        }
      })
    },
    remove() {
      this.url = undefined
      this.$emit('remove')
    }
  }
}
</script>
<style scoped>
.upload-target {
  display: flex;
  align-items: center;
  background-size: cover;
  background-position: center center;
  height: 100%;
  position: relative;
}
.empty {
  height: 100%;
  background-color: #f1f1f1;
}
.empty-prompt {
  width: 100%;
}
.upload-target:hover .empty-prompt {
  color: #333333 !important;
  transition: color linear 200ms;
}
</style>
