const ROLES = require('../constants/roles')
const PERMISSIONS = require('../constants/permissions')
const ALL_KEYS = Object.values(PERMISSIONS).reduce((arr, p) => {
  return arr.concat(Object.values(p))
}, [])

class PermissionHelper {
  hasPermission(permission, access, roleOverrides) {
    if (!ALL_KEYS.includes(permission)) {
      console.error('Invalid permission key', permission)
    }
    if (access === 'owner') {
      return true
    }

    if (roleOverrides && roleOverrides[access]) {
      let override = roleOverrides[access].find(r => r.key === permission)
      if (override) {
        return override.add
      }
    }
    return ROLES[access].includes(permission)
  }
  getRolesWithPermission({permission, roleOverrides}) {
    let roles = ['owner']

    for (let access of Object.keys(ROLES)) {
      if (roleOverrides && roleOverrides[access]) {
        let override = roleOverrides[access].find(r => r.key === permission)
        if ((override && override.add) || (!override && ROLES[access].includes(permission))) {
          roles.push(access)
        }
      }
    }
    return roles
  }
}
module.exports = new PermissionHelper()
