const SPECIALIST_LEVELS = require('./specialistLevels')
const INDUSTRIES = require('./industries')
const LEVEL_SERVICE_RATIOS = {
  [SPECIALIST_LEVELS.ART_DIRECTOR]: {
    min: 3.5,
    recommended: 4
  },
  [SPECIALIST_LEVELS.TOP_MASTER]: {
    min: 3,
    recommended: 3.5
  },
  [SPECIALIST_LEVELS.MASTER]: {
    min: 2.5,
    recommended: 3
  },
  [SPECIALIST_LEVELS.SENIOR]: {
    min: 2,
    recommended: 3
  },
  [SPECIALIST_LEVELS.JUNIOR]: {
    min: 2,
    recommended: 3
  },
  [SPECIALIST_LEVELS.APPRENTICE_3Y]: {
    min: 1.5,
    recommended: 2
  },
  [SPECIALIST_LEVELS.APPRENTICE_2Y]: {
    min: 1,
    recommended: 2
  },
  [SPECIALIST_LEVELS.APPRENTICE_1Y]: {
    min: 1,
    recommended: 1.5
  },
  [SPECIALIST_LEVELS.APPRENTICE]: {
    min: 1,
    recommended: 1.5
  }
}
const LEVEL_RETAIL_RATIOS = {
  [SPECIALIST_LEVELS.ART_DIRECTOR]: {
    min: 30,
    recommended: 40
  },
  [SPECIALIST_LEVELS.TOP_MASTER]: {
    min: 30,
    recommended: 40
  },
  [SPECIALIST_LEVELS.MASTER]: {
    min: 30,
    recommended: 40
  },
  [SPECIALIST_LEVELS.SENIOR]: {
    min: 25,
    recommended: 35
  },
  [SPECIALIST_LEVELS.JUNIOR]: {
    min: 20,
    recommended: 30
  },
  [SPECIALIST_LEVELS.APPRENTICE_3Y]: {
    min: 15,
    recommended: 25
  },
  [SPECIALIST_LEVELS.APPRENTICE_2Y]: {
    min: 10,
    recommended: 20
  },
  [SPECIALIST_LEVELS.APPRENTICE_1Y]: {
    min: 5,
    recommended: 15
  },
  [SPECIALIST_LEVELS.APPRENTICE]: {
    min: 5,
    recommended: 15
  }
}
const TARGET_RETAIL_RATIO = {
  [INDUSTRIES.HAIRDRESSING]: {
    min: 10,
    recommended: 30
  },
  [INDUSTRIES.SPA]: {
    min: 10,
    recommended: 30
  },
  [INDUSTRIES.BEAUTY]: {
    min: 10,
    recommended: 30
  },
  [INDUSTRIES.BARBERSHOP]: {
    min: 10,
    recommended: 30
  }
}

const AVG_DAILY_CUSTOMERS = {
  [INDUSTRIES.HAIRDRESSING]: {
    min: 4,
    recommended: 6,
    max: 15
  },
  [INDUSTRIES.SPA]: {
    min: 4,
    recommended: 6,
    max: 15
  },
  [INDUSTRIES.BEAUTY]: {
    min: 6,
    recommended: 8,
    max: 25
  },
  [INDUSTRIES.BARBERSHOP]: {
    min: 10,
    recommended: 18,
    max: 30
  }
}

const AVG_SERVICES_PER_VISIT = {
  [INDUSTRIES.HAIRDRESSING]: {
    min: 2,
    recommended: 3
  },
  [INDUSTRIES.SPA]: {
    min: 1.6,
    recommended: 2
  },
  [INDUSTRIES.BEAUTY]: {
    min: 3,
    recommended: 4
  },
  [INDUSTRIES.BARBERSHOP]: {
    min: 1.4,
    recommended: 2
  }
}

const REBOOKING_RATIO = {
  [INDUSTRIES.HAIRDRESSING]: {
    min: 80,
    recommended: 85
  },
  [INDUSTRIES.SPA]: {
    min: 60,
    recommended: 70
  },
  [INDUSTRIES.BEAUTY]: {
    min: 80,
    recommended: 85
  },
  [INDUSTRIES.BARBERSHOP]: {
    min: 80,
    recommended: 85
  }
}

module.exports = {
  LEVEL_SERVICE_RATIOS,
  LEVEL_RETAIL_RATIOS,
  TARGET_RETAIL_RATIO,
  AVG_SERVICES_PER_VISIT,
  AVG_DAILY_CUSTOMERS,
  REBOOKING_RATIO
}
