import Vue from 'vue'

export default {
  search({query, sortBy, sortDir, page, perPage, category}) {
    return Vue.http.get('customers', {
      params: {
        query,
        sortBy,
        sortDir,
        page,
        perPage,
        category
      }
    })
  },
  findByPhone(phone) {
    return Vue.http.get('customers/phone', {params: {phone}}).then(res => res.body)
  },
  update(customerId, data) {
    return Vue.http.put(`customers/${customerId}`, data)
  },
  updateTags(customerId, tags) {
    return Vue.http.put(`customers/${customerId}/tags`, {tags})
  },
  remove(customerId) {
    return Vue.http.delete(`customers/${customerId}`)
  },
  getById(customerId) {
    return Vue.http.get(`customers/${customerId}`).then(res => res.body)
  },
  getCustomerMonthlySales(customerId) {
    return Vue.http.get(`customers/${customerId}/monthlySales`)
  },
  register(data) {
    return Vue.http.post(`customers`, data)
  },
  merge(customer, customerWith) {
    return Vue.http.post('customers/merge', {customer: customer._id, customerWith: customerWith._id})
  },
  sendInvitationSMS(customerId) {
    return Vue.http.post(`customers/${customerId}/smsinvite`).then(
      res => res.body,
      res => {
        throw res
      }
    )
  },
  fetchNotes(customerId) {
    return Vue.http.get(`customers/${customerId}/notes`).then(res => res.body)
  },
  addNote({customerId, data}) {
    return Vue.http.post(`customers/${customerId}/notes`, data).then(res => res.body)
  },
  deleteNote({customerId, noteId}) {
    return Vue.http.delete(`customers/${customerId}/notes/${noteId}`).then(res => res.body)
  }
}
