<template lang="pug">
  b-field
    b-select(v-model="status" name="status" :placeholder="$t('sale.payment_status_select_placeholder')" icon="label-variant-outline" @input="onChange" )
      option(value) {{$t('sale_status.all')}}
      option(v-for="st in statuses" :value="st") {{$t(`payment_status.${st}`)}}
</template>

<script>
import PAYMENT_STATUS from '../../../constants/paymentStatus'
export default {
  name: 'PaymentStatusSelect',
  props: {
    value: {type: String, default: undefined}
  },
  data() {
    return {
      status: undefined,
      statuses: [PAYMENT_STATUS.PAID, PAYMENT_STATUS.UNPAID, PAYMENT_STATUS.REFUNDED]
    }
  },
  watch: {
    value(newVal) {
      this.status = newVal
    }
  },
  created() {
    this.status = this.value
  },
  methods: {
    onChange() {
      this.$emit('input', this.status)
    }
  }
}
</script>
