import Vue from 'vue'

export default {
  refundSale(saleId) {
    return Vue.http.put(`sales/${saleId}/refund`).then(res => res.body)
  },
  checkout(data) {
    return Vue.http.post('sales', data).then(res => res.body)
  },
  depositDeposit(paymentId) {
    return Vue.http.post('sales/depositDeposit', {payment: paymentId}).then(res => res.body)
  },
  list(params) {
    return Vue.http.get('sales', {params}).then(res => res.body)
  },
  getById(saleId) {
    return Vue.http.get(`sales/${saleId}`).then(res => res.body)
  },
  remove(saleId) {
    return Vue.http.delete(`sales/${saleId}`)
  }
}
