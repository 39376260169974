import {SET_USER, LOGIN_SUCCESS, LOGOUT} from './mutationTypes'
import AuthApi from '@/api/auth'
import {loadLanguageAsync} from '../i18n'

export default {
  namespaced: true,
  state: {
    user: null,
    token: localStorage.getItem('x-auth-token'),
    pending: false
  },
  mutations: {
    [SET_USER](state, user) {
      state.user = user
    },

    [LOGIN_SUCCESS](state, token) {
      state.token = token
      localStorage.setItem('x-auth-token', token)
      state.pending = false
    },
    [LOGOUT](state) {
      state.token = null
      localStorage.removeItem('x-auth-token')
      state.pending = false
      state.user = null
    }
  },
  getters: {
    isLoggedIn: state => {
      return !!state.user
    },
    isPending: state => state.pending
  },
  actions: {
    async signin({commit}, credentials) {
      let res = await AuthApi.signin(credentials)
      commit(LOGIN_SUCCESS, res.headers.get('X-AUTH-TOKEN'))
      commit(SET_USER, res.body)
      await loadLanguageAsync(res.body.locale)
    },
    async signinWithFacebook({commit}, userAccessToken) {
      let res = await AuthApi.signinWithFacebook(userAccessToken)
      commit(LOGIN_SUCCESS, res.headers.get('X-AUTH-TOKEN'))
      commit(SET_USER, res.body)
      await loadLanguageAsync(res.body.locale)
    },
    async signupUser({commit}, data) {
      let res = await AuthApi.signupUser(data)
      commit(LOGIN_SUCCESS, res.headers.get('X-AUTH-TOKEN'))
      commit(SET_USER, res.body)
    },
    async createBusiness({commit}, data) {
      let res = await AuthApi.createBusiness(data)
      commit(LOGIN_SUCCESS, res.headers.get('X-AUTH-TOKEN'))
      commit(SET_USER, res.body)
    },
    requestOTP(_ctx, data) {
      return AuthApi.requestOTP(data)
    },
    async verifyEmail({commit}, key) {
      let res = await AuthApi.verifyEmail(key)
      commit(LOGIN_SUCCESS, res.headers.get('X-AUTH-TOKEN'))
      commit(SET_USER, res.body)
    },
    async fetch({commit, state}, force) {
      if (!state.token) {
        return
      }
      if (state.user && !force) {
        return
      }
      try {
        let {body} = await AuthApi.getCurrentAccount()
        commit(SET_USER, body)
        await loadLanguageAsync(body.locale)
      } catch (err) {
        if (err.status === 401) {
          commit(LOGOUT)
        }
        throw err
      }
    },
    async updateDetails({commit}, data) {
      const {body} = await AuthApi.updateDetails(data._id, data)
      commit(SET_USER, body)
      await loadLanguageAsync(body.locale)
    },
    async updatePassword(_, data) {
      await AuthApi.updatePassword(data._id, data)
    },
    async setPassword(_, data) {
      await AuthApi.requestPasswordReset(data)
    },
    signout({commit}) {
      commit(LOGOUT)
    }
  }
}
