<template lang="pug">
.modal-card
  header.modal-card-head
    p.modal-card-title {{ $t('ledger.cash_in_title') }}
    
  section.modal-card-body
    select-box(:data="types" v-model="type")
    b-field(v-if="type === 'return'" :label="$t('ledger.return_by_label')" :type="errors.has('returnedBy') ? 'is-danger': ''" :message="errors.has('returnedBy') ? errors.first('returnedBy') : ''")
      b-select(v-model="returnedBy" name="returnedBy" v-validate="'required'" data-vv-as="returned by"  :placeholder="$t('ledger.staff_placeholder')")
        option(v-for="staff in staffMembers" :value="staff._id" :key="staff._id")
          staff-view(:id="staff._id")
    
    b-field(:label="$t('ledger.amount_label')" :type="errors.has('amount') ? 'is-danger': ''" :message="errors.has('amount') ? errors.first('amount') : ''")
      b-field
        p.control
          span.button.is-static 
            currency
        b-input(v-model.number="amount" ref="amount"  expanded v-validate="'required'" name="amount" :placeholder="$t('ledger.amount_placeholder')")
    b-field(:label="$t('ledger.notes_label')" :type="errors.has('notes') ? 'is-danger': ''" :message="errors.has('notes') ? errors.first('notes') : ''")
      b-input(v-model="notes" name="notes" data-vv-as="notes" :placeholder="$t('ledger.notes_placeholder')")
    note(type="is-warning") 
      p(v-t="'ledger.transaction_note'")
  footer.modal-card-foot
    .buttons
      action-button(@click="submit" title="actions.submit" :loading="loading")
      button.button.is-outlined(type="button" @click="$emit('close')" ) {{$t('actions.close')}}      
</template>

<script>
import {mapActions, mapGetters, mapState} from 'vuex'
import TRANSACTION_TYPES from '../../../constants/ledgerTransactions'

export default {
  data() {
    return {
      amount: undefined,
      notes: undefined,
      loading: false,
      type: TRANSACTION_TYPES.RETURN,
      returnedBy: undefined
    }
  },
  computed: {
    ...mapGetters('staff', ['activeMembersByLocation']),
    ...mapState('auth', ['user']),
    staffMembers() {
      return this.activeMembersByLocation(this.user.location)
    },
    types() {
      return [
        {
          title: this.$t('register_transactions.return'),
          description: this.$t('register_transactions.return_description'),
          value: TRANSACTION_TYPES.RETURN
        },
        {
          title: this.$t('register_transactions.addition'),
          description: this.$t('register_transactions.addition_description'),
          value: TRANSACTION_TYPES.ADDITION
        }
      ]
    }
  },
  created() {
    this.amount = this.due
  },
  mounted() {
    this.$refs.amount.focus()
  },
  methods: {
    ...mapActions('ledger', ['registerIn']),
    async submit() {
      let valid = await this.$validator.validateAll()
      if (!valid) {
        return
      }
      if (this.loading) {
        return
      }
      this.loading = true
      try {
        if (this.type === TRANSACTION_TYPES.RETURN) {
          await this.registerIn({amount: this.amount, payer: this.returnedBy, type: this.type, notes: this.notes})
        } else if (this.type === TRANSACTION_TYPES.ADDITION) {
          await this.registerIn({amount: this.amount, type: this.type, notes: this.notes})
        }

        this.$emit('close')
      } catch (err) {
        this.$handleAPIError(err)
      }
      this.loading = false
    }
  }
}
</script>
