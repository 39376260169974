<template lang="pug">
a(@click.stop="openSaleDetails") 
  span(v-if="invoice") {{$t('sale.invoice_number', {invoice})}} 
  span(v-else v-t="'sale.invoice_title'")
</template>
<script>
import SaleDetails from './SaleDetails.vue'

export default {
  name: 'InvoiceView',
  props: {
    value: {type: String, required: true},
    invoice: {type: [String, Number], default: undefined}
  },
  methods: {
    openSaleDetails() {
      this.$buefy.modal.open({
        parent: this,
        props: {saleId: this.value},
        component: SaleDetails,
        fullScreen: true,
        hasModalCard: true,
        canCancel: ['escape']
      })
    }
  }
}
</script>
