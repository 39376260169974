import {
  SET_CUSTOMER_COUPONS,
  SET_TOTAL_CUSTOMER_COUPONS,
  SET_CUSTOMER_COUPONS_FILTER,
  SET_CUSTOMER_COUPON
} from './mutationTypes'
import CustomerCouponApi from '@/api/customerCoupon'

export default {
  namespaced: true,
  state: {
    customerCouponsMap: {},
    total: 0,
    customerCouponsOrder: [],
    filter: {}
  },
  getters: {
    customerCoupons(state) {
      return state.customerCouponsOrder.map(c => state.customerCouponsMap[c])
    }
  },
  mutations: {
    [SET_CUSTOMER_COUPONS](state, customerCoupons) {
      const mapped = {}
      for (let customerCoupon of customerCoupons) {
        mapped[customerCoupon._id] = customerCoupon
      }
      state.customerCouponsMap = mapped
      state.customerCouponsOrder = customerCoupons.map(c => c._id)
    },
    [SET_CUSTOMER_COUPON](state, customerCoupon) {
      state.customerCouponsMap[customerCoupon._id] = customerCoupon
      state.customerCouponsOrder.push(customerCoupon._id)
    },
    [SET_TOTAL_CUSTOMER_COUPONS](state, total) {
      state.total = total
    },
    [SET_CUSTOMER_COUPONS_FILTER](state, filter) {
      state.filter = filter
    }
  },
  actions: {
    async list({commit, state}, filter) {
      if (!filter) {
        filter = state.filter
      }
      if (!filter.perPage) {
        return
      }
      const {body} = await CustomerCouponApi.list(filter)
      commit(SET_CUSTOMER_COUPONS, body.data)
      commit(SET_TOTAL_CUSTOMER_COUPONS, body.total)
      commit(SET_CUSTOMER_COUPONS_FILTER, filter)
    },

    getCustomerCoupons(_, customerId) {
      return CustomerCouponApi.getCustomerCoupons(customerId)
    },
    async getCustomerCouponById({commit, state}, customerCouponId) {
      if (!state.customerCouponsMap[customerCouponId]) {
        const customerCoupon = await CustomerCouponApi.getCustomerCouponById(customerCouponId)
        commit(SET_CUSTOMER_COUPON, customerCoupon)
      }
      return state.customerCouponsMap[customerCouponId]
    },
    async sendGiftCoupon(_, data) {
      await CustomerCouponApi.sendGiftCoupon(data)
    },
    async redeemCoupon(_, customerCouponId) {
      await CustomerCouponApi.redeemCoupon(customerCouponId)
    }
  }
}
