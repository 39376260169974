<template lang="pug">
  navigation-menu(:items="items")
</template>

<script>
import NavigationMenu from '../shared/NavigationMenu'
import PERMISSIONS from '../../../constants/permissions'
import {PRODUCTS} from '../../../constants/products'
export default {
  components: {
    NavigationMenu
  },
  data() {
    return {
      items: [
        {path: '/settings/business', icon: 'store', title: 'business_settings.title'},

        {
          path: '/settings/online',
          icon: 'web',
          title: 'online_booking_settings.title',
          permission: PERMISSIONS.core.ONLINE_BOOKING_SETTINGS_VIEW,
          hidden: !this.$rfSettings().calendar
        },
        {
          path: '/settings/notifications',
          icon: 'message-outline',
          title: 'client_notifications.title',
          permission: PERMISSIONS.core.COMPANY_SETTINGS_EDIT,
          hidden: !this.$rfSettings().calendar,
          product: PRODUCTS.CRM_PRO
        },
        {
          path: '/settings/calendar',
          icon: 'calendar-outline',
          title: 'calendar_settings.title',
          permission: PERMISSIONS.core.COMPANY_SETTINGS_EDIT,
          hidden: !this.$rfSettings().calendar
        },
        {
          path: '/settings/crm',
          icon: 'account-multiple-outline',
          title: 'crm_settings.customer_data_title',
          permission: PERMISSIONS.core.COMPANY_SETTINGS_EDIT,
          product: PRODUCTS.CRM_PRO
        },

        {
          path: '/settings/taxes',
          icon: 'percent-box-outline',
          title: 'tax_settings.title',
          permission: PERMISSIONS.core.COMPANY_SETTINGS_EDIT
          // nested: [
          //   // {
          //   //   path: '/settings/pos',
          //   //   icon: 'cart',
          //   //   title: 'tax_settings.title',
          //   //   permission: PERMISSIONS.core.COMPANY_SETTINGS_EDIT
          //   // },

          // ]
        },
        {
          path: '/settings/paymentTypes',
          icon: 'credit-card-plus-outline',
          title: 'payment_types_settings.title',
          permission: PERMISSIONS.core.COMPANY_SETTINGS_EDIT
        },
        {
          path: '/settings/cashregister',
          icon: 'cash-register',
          title: 'ledger_settings.title',
          permission: PERMISSIONS.core.COMPANY_SETTINGS_EDIT
        },
        {
          path: '/settings/platforms',
          icon: 'view-module',
          title: 'platforms_settings.title',
          permission: PERMISSIONS.core.SETTINGS_PLATFORMS_VIEW
        },

        {
          path: '/settings/languages',
          icon: 'comment-text-multiple-outline',
          title: 'languages_settings.title',
          permission: PERMISSIONS.core.SETTINGS_LANGUAGES_VIEW
        },
        {
          path: '/settings/chatbot/settings',
          icon: 'facebook-messenger',
          title: 'navbar.chatbot',
          permission: PERMISSIONS.chatbot.MANAGE_SETTINGS
        },

        {
          path: '/settings/api',
          icon: 'api',
          title: 'api_access.title',
          permission: PERMISSIONS.core.SETTINGS_API_VIEW
        },
        {separator: true},
        {
          path: '/settings/billing',
          icon: 'credit-card',
          title: 'billing.title',
          permission: PERMISSIONS.core.BILLING_VIEW
        }
      ]
    }
  }
}
</script>
