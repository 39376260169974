<template lang="pug">
  b-select(v-model="timezone" :placeholder="placeholder" icon="web" @input="onChange" :size="size" :expanded="expanded")
    option(value='Pacific/Pago_Pago') (GMT -11:00) Pago Pago
    option(value='Pacific/Niue') (GMT -11:00) Niue
    option(value='Pacific/Midway') (GMT -11:00) Midway
    option(value='Pacific/Rarotonga') (GMT -10:00) Rarotonga
    option(value='Pacific/Tahiti') (GMT -10:00) Tahiti
    option(value='America/Adak') (GMT -10:00) Adak
    option(value='Pacific/Honolulu') (GMT -10:00) Honolulu
    option(value='Pacific/Marquesas') (GMT -09:30) Marquesas
    option(value='Pacific/Gambier') (GMT -09:00) Gambier
    option(value='America/Anchorage') (GMT -09:00) Anchorage
    option(value='America/Juneau') (GMT -09:00) Juneau
    option(value='America/Sitka') (GMT -09:00) Sitka
    option(value='America/Metlakatla') (GMT -09:00) Metlakatla
    option(value='America/Yakutat') (GMT -09:00) Yakutat
    option(value='America/Nome') (GMT -09:00) Nome
    option(value='America/Vancouver') (GMT -08:00) Vancouver
    option(value='America/Whitehorse') (GMT -08:00) Whitehorse
    option(value='America/Dawson') (GMT -08:00) Dawson
    option(value='America/Tijuana') (GMT -08:00) Tijuana
    option(value='Pacific/Pitcairn') (GMT -08:00) Pitcairn
    option(value='America/Los_Angeles') (GMT -08:00) Los Angeles
    option(value='America/Edmonton') (GMT -07:00) Edmonton
    option(value='America/Cambridge_Bay') (GMT -07:00) Cambridge Bay
    option(value='America/Yellowknife') (GMT -07:00) Yellowknife
    option(value='America/Inuvik') (GMT -07:00) Inuvik
    option(value='America/Creston') (GMT -07:00) Creston
    option(value='America/Dawson_Creek') (GMT -07:00) Dawson Creek
    option(value='America/Fort_Nelson') (GMT -07:00) Fort Nelson
    option(value='America/Mazatlan') (GMT -07:00) Mazatlan
    option(value='America/Chihuahua') (GMT -07:00) Chihuahua
    option(value='America/Ojinaga') (GMT -07:00) Ojinaga
    option(value='America/Hermosillo') (GMT -07:00) Hermosillo
    option(value='America/Bahia_Banderas') (GMT -07:00) Bahia Banderas
    option(value='America/Denver') (GMT -07:00) Denver
    option(value='America/Boise') (GMT -07:00) Boise
    option(value='America/Phoenix') (GMT -07:00) Phoenix
    option(value='America/Belize') (GMT -06:00) Belize
    option(value='America/Winnipeg') (GMT -06:00) Winnipeg
    option(value='America/Rainy_River') (GMT -06:00) Rainy River
    option(value='America/Resolute') (GMT -06:00) Resolute
    option(value='America/Rankin_Inlet') (GMT -06:00) Rankin Inlet
    option(value='America/Regina') (GMT -06:00) Regina
    option(value='America/Swift_Current') (GMT -06:00) Swift Current
    option(value='Pacific/Easter') (GMT -06:00) Easter
    option(value='America/Costa_Rica') (GMT -06:00) Costa Rica
    option(value='Pacific/Galapagos') (GMT -06:00) Galapagos
    option(value='America/Guatemala') (GMT -06:00) Guatemala
    option(value='America/Tegucigalpa') (GMT -06:00) Tegucigalpa
    option(value='America/Mexico_City') (GMT -06:00) Mexico City
    option(value='America/Merida') (GMT -06:00) Merida
    option(value='America/Monterrey') (GMT -06:00) Monterrey
    option(value='America/Matamoros') (GMT -06:00) Matamoros
    option(value='America/Managua') (GMT -06:00) Managua
    option(value='America/El_Salvador') (GMT -06:00) El Salvador
    option(value='America/Chicago') (GMT -06:00) Chicago
    option(value='America/Indiana/Tell_City') (GMT -06:00) Tell City, Indiana
    option(value='America/Indiana/Knox') (GMT -06:00) Knox, Indiana
    option(value='America/Menominee') (GMT -06:00) Menominee
    option(value='America/North_Dakota/Center') (GMT -06:00) Center, North Dakota
    option(value='America/North_Dakota/New_Salem') (GMT -06:00) New Salem, North Dakota
    option(value='America/North_Dakota/Beulah') (GMT -06:00) Beulah, North Dakota
    option(value='America/Eirunepe') (GMT -05:00) Eirunepe
    option(value='America/Rio_Branco') (GMT -05:00) Rio Branco
    option(value='America/Nassau') (GMT -05:00) Nassau
    option(value='America/Toronto') (GMT -05:00) Toronto
    option(value='America/Nipigon') (GMT -05:00) Nipigon
    option(value='America/Thunder_Bay') (GMT -05:00) Thunder Bay
    option(value='America/Iqaluit') (GMT -05:00) Iqaluit
    option(value='America/Pangnirtung') (GMT -05:00) Pangnirtung
    option(value='America/Atikokan') (GMT -05:00) Atikokan
    option(value='America/Bogota') (GMT -05:00) Bogota
    option(value='America/Havana') (GMT -05:00) Havana
    option(value='America/Guayaquil') (GMT -05:00) Guayaquil
    option(value='America/Port-au-Prince') (GMT -05:00) Port-au-Prince
    option(value='America/Jamaica') (GMT -05:00) Jamaica
    option(value='America/Cayman') (GMT -05:00) Cayman
    option(value='America/Cancun') (GMT -05:00) Cancun
    option(value='America/Panama') (GMT -05:00) Panama
    option(value='America/Lima') (GMT -05:00) Lima
    option(value='America/Grand_Turk') (GMT -05:00) Grand Turk
    option(value='America/New_York') (GMT -05:00) New York
    option(value='America/Detroit') (GMT -05:00) Detroit
    option(value='America/Kentucky/Louisville') (GMT -05:00) Louisville, Kentucky
    option(value='America/Kentucky/Monticello') (GMT -05:00) Monticello, Kentucky
    option(value='America/Indiana/Indianapolis') (GMT -05:00) Indianapolis, Indiana
    option(value='America/Indiana/Vincennes') (GMT -05:00) Vincennes, Indiana
    option(value='America/Indiana/Winamac') (GMT -05:00) Winamac, Indiana
    option(value='America/Indiana/Marengo') (GMT -05:00) Marengo, Indiana
    option(value='America/Indiana/Petersburg') (GMT -05:00) Petersburg, Indiana
    option(value='America/Indiana/Vevay') (GMT -05:00) Vevay, Indiana
    option(value='America/Antigua') (GMT -04:00) Antigua
    option(value='America/Anguilla') (GMT -04:00) Anguilla
    option(value='America/Aruba') (GMT -04:00) Aruba
    option(value='America/Barbados') (GMT -04:00) Barbados
    option(value='America/St_Barthelemy') (GMT -04:00) St Barthelemy
    option(value='Atlantic/Bermuda') (GMT -04:00) Bermuda
    option(value='America/Boa_Vista') (GMT -04:00) Boa Vista
    option(value='America/Blanc-Sablon') (GMT -04:00) Blanc-Sablon
    option(value='America/Campo_Grande') (GMT -04:00) Campo Grande
    option(value='America/Cuiaba') (GMT -04:00) Cuiaba
    option(value='America/Curacao') (GMT -04:00) Curacao
    option(value='America/Dominica') (GMT -04:00) Dominica
    option(value='America/Grenada') (GMT -04:00) Grenada
    option(value='America/Guadeloupe') (GMT -04:00) Guadeloupe
    option(value='America/Guyana') (GMT -04:00) Guyana
    option(value='America/Glace_Bay') (GMT -04:00) Glace Bay
    option(value='America/Goose_Bay') (GMT -04:00) Goose Bay
    option(value='America/Kralendijk') (GMT -04:00) Kralendijk
    option(value='America/La_Paz') (GMT -04:00) La Paz
    option(value='America/Manaus') (GMT -04:00) Manaus
    option(value='America/Moncton') (GMT -04:00) Moncton
    option(value='America/Porto_Velho') (GMT -04:00) Porto Velho
    option(value='America/Halifax') (GMT -04:00) Halifax
    option(value='America/Santiago') (GMT -04:00) Santiago
    option(value='America/Santo_Domingo') (GMT -04:00) Santo Domingo
    option(value='America/Thule') (GMT -04:00) Thule
    option(value='America/St_Kitts') (GMT -04:00) St Kitts
    option(value='America/St_Lucia') (GMT -04:00) St Lucia
    option(value='America/Marigot') (GMT -04:00) Marigot
    option(value='America/Martinique') (GMT -04:00) Martinique
    option(value='America/Montserrat') (GMT -04:00) Montserrat
    option(value='America/Puerto_Rico') (GMT -04:00) Puerto Rico
    option(value='America/Asuncion') (GMT -04:00) Asuncion
    option(value='America/Lower_Princes') (GMT -04:00) Lower Princes
    option(value='America/Port_of_Spain') (GMT -04:00) Port of Spain
    option(value='America/St_Vincent') (GMT -04:00) St Vincent
    option(value='America/Caracas') (GMT -04:00) Caracas
    option(value='America/Tortola') (GMT -04:00) Tortola
    option(value='America/St_Thomas') (GMT -04:00) St Thomas
    option(value='America/St_Johns') (GMT -03:30) St Johns
    option(value='Antarctica/Palmer') (GMT -03:00) Palmer
    option(value='Antarctica/Rothera') (GMT -03:00) Rothera
    option(value='America/Argentina/Buenos_Aires') (GMT -03:00) Buenos Aires
    option(value='America/Argentina/Cordoba') (GMT -03:00) Cordoba
    option(value='America/Argentina/Salta') (GMT -03:00) Salta
    option(value='America/Argentina/Jujuy') (GMT -03:00) Jujuy
    option(value='America/Argentina/Tucuman') (GMT -03:00) Tucuman
    option(value='America/Argentina/Catamarca') (GMT -03:00) Catamarca
    option(value='America/Argentina/La_Rioja') (GMT -03:00) La Rioja
    option(value='America/Argentina/San_Juan') (GMT -03:00) San Juan
    option(value='America/Argentina/Mendoza') (GMT -03:00) Mendoza
    option(value='America/Argentina/San_Luis') (GMT -03:00) San Luis
    option(value='America/Argentina/Rio_Gallegos') (GMT -03:00) Rio Gallegos
    option(value='America/Argentina/Ushuaia') (GMT -03:00) Ushuaia
    option(value='America/Belem') (GMT -03:00) Belem
    option(value='America/Fortaleza') (GMT -03:00) Fortaleza
    option(value='America/Recife') (GMT -03:00) Recife
    option(value='America/Araguaina') (GMT -03:00) Araguaina
    option(value='America/Maceio') (GMT -03:00) Maceio
    option(value='America/Bahia') (GMT -03:00) Bahia
    option(value='America/Sao_Paulo') (GMT -03:00) Sao Paulo
    option(value='America/Santarem') (GMT -03:00) Santarem
    option(value='America/Punta_Arenas') (GMT -03:00) Punta Arenas
    option(value='Atlantic/Stanley') (GMT -03:00) Stanley
    option(value='America/Cayenne') (GMT -03:00) Cayenne
    option(value='America/Godthab') (GMT -03:00) Godthab
    option(value='America/Miquelon') (GMT -03:00) Miquelon
    option(value='America/Paramaribo') (GMT -03:00) Paramaribo
    option(value='America/Montevideo') (GMT -03:00) Montevideo
    option(value='America/Noronha') (GMT -02:00) Noronha
    option(value='Atlantic/South_Georgia') (GMT -02:00) South Georgia
    option(value='Atlantic/Cape_Verde') (GMT -01:00) Cape Verde
    option(value='America/Scoresbysund') (GMT -01:00) Scoresbysund
    option(value='Atlantic/Azores') (GMT -01:00) Azores
    option(value='Antarctica/Troll') (GMT +00:00) Troll
    option(value='Africa/Ouagadougou') (GMT +00:00) Ouagadougou
    option(value='Africa/Abidjan') (GMT +00:00) Abidjan
    option(value='Africa/El_Aaiun') (GMT +00:00) El Aaiun
    option(value='Atlantic/Canary') (GMT +00:00) Canary
    option(value='Atlantic/Faroe') (GMT +00:00) Faroe
    option(value='Europe/London') (GMT +00:00) London
    option(value='Europe/Guernsey') (GMT +00:00) Guernsey
    option(value='Africa/Accra') (GMT +00:00) Accra
    option(value='America/Danmarkshavn') (GMT +00:00) Danmarkshavn
    option(value='Africa/Banjul') (GMT +00:00) Banjul
    option(value='Africa/Conakry') (GMT +00:00) Conakry
    option(value='Africa/Bissau') (GMT +00:00) Bissau
    option(value='Europe/Dublin') (GMT +00:00) Dublin
    option(value='Europe/Isle_of_Man') (GMT +00:00) Isle of Man
    option(value='Atlantic/Reykjavik') (GMT +00:00) Reykjavik
    option(value='Europe/Jersey') (GMT +00:00) Jersey
    option(value='Africa/Monrovia') (GMT +00:00) Monrovia
    option(value='Africa/Casablanca') (GMT +00:00) Casablanca
    option(value='Africa/Bamako') (GMT +00:00) Bamako
    option(value='Africa/Nouakchott') (GMT +00:00) Nouakchott
    option(value='Europe/Lisbon') (GMT +00:00) Lisbon
    option(value='Atlantic/Madeira') (GMT +00:00) Madeira
    option(value='Atlantic/St_Helena') (GMT +00:00) St Helena
    option(value='Africa/Freetown') (GMT +00:00) Freetown
    option(value='Africa/Dakar') (GMT +00:00) Dakar
    option(value='Africa/Sao_Tome') (GMT +00:00) Sao Tome
    option(value='Africa/Lome') (GMT +00:00) Lome
    option(value='Europe/Andorra') (GMT +01:00) Andorra
    option(value='Europe/Tirane') (GMT +01:00) Tirane
    option(value='Africa/Luanda') (GMT +01:00) Luanda
    option(value='Europe/Vienna') (GMT +01:00) Vienna
    option(value='Europe/Sarajevo') (GMT +01:00) Sarajevo
    option(value='Europe/Brussels') (GMT +01:00) Brussels
    option(value='Africa/Porto-Novo') (GMT +01:00) Porto-Novo
    option(value='Africa/Kinshasa') (GMT +01:00) Kinshasa
    option(value='Africa/Bangui') (GMT +01:00) Bangui
    option(value='Africa/Brazzaville') (GMT +01:00) Brazzaville
    option(value='Europe/Zurich') (GMT +01:00) Zurich
    option(value='Africa/Douala') (GMT +01:00) Douala
    option(value='Europe/Prague') (GMT +01:00) Prague
    option(value='Europe/Berlin') (GMT +01:00) Berlin
    option(value='Europe/Busingen') (GMT +01:00) Busingen
    option(value='Europe/Copenhagen') (GMT +01:00) Copenhagen
    option(value='Africa/Algiers') (GMT +01:00) Algiers
    option(value='Europe/Madrid') (GMT +01:00) Madrid
    option(value='Africa/Ceuta') (GMT +01:00) Ceuta
    option(value='Europe/Paris') (GMT +01:00) Paris
    option(value='Africa/Libreville') (GMT +01:00) Libreville
    option(value='Europe/Gibraltar') (GMT +01:00) Gibraltar
    option(value='Africa/Malabo') (GMT +01:00) Malabo
    option(value='Europe/Zagreb') (GMT +01:00) Zagreb
    option(value='Europe/Budapest') (GMT +01:00) Budapest
    option(value='Europe/Rome') (GMT +01:00) Rome
    option(value='Europe/Vaduz') (GMT +01:00) Vaduz
    option(value='Europe/Luxembourg') (GMT +01:00) Luxembourg
    option(value='Europe/Monaco') (GMT +01:00) Monaco
    option(value='Europe/Podgorica') (GMT +01:00) Podgorica
    option(value='Europe/Skopje') (GMT +01:00) Skopje
    option(value='Europe/Malta') (GMT +01:00) Malta
    option(value='Africa/Niamey') (GMT +01:00) Niamey
    option(value='Africa/Lagos') (GMT +01:00) Lagos
    option(value='Europe/Amsterdam') (GMT +01:00) Amsterdam
    option(value='Europe/Oslo') (GMT +01:00) Oslo
    option(value='Europe/Warsaw') (GMT +01:00) Warsaw
    option(value='Europe/Belgrade') (GMT +01:00) Belgrade
    option(value='Europe/Stockholm') (GMT +01:00) Stockholm
    option(value='Europe/Ljubljana') (GMT +01:00) Ljubljana
    option(value='Arctic/Longyearbyen') (GMT +01:00) Longyearbyen
    option(value='Europe/Bratislava') (GMT +01:00) Bratislava
    option(value='Europe/San_Marino') (GMT +01:00) San Marino
    option(value='Africa/Ndjamena') (GMT +01:00) Ndjamena
    option(value='Africa/Tunis') (GMT +01:00) Tunis
    option(value='Europe/Vatican') (GMT +01:00) Vatican
    option(value='Europe/Mariehamn') (GMT +02:00) Mariehamn
    option(value='Europe/Sofia') (GMT +02:00) Sofia
    option(value='Africa/Bujumbura') (GMT +02:00) Bujumbura
    option(value='Africa/Gaborone') (GMT +02:00) Gaborone
    option(value='Africa/Lubumbashi') (GMT +02:00) Lubumbashi
    option(value='Asia/Nicosia') (GMT +02:00) Nicosia
    option(value='Asia/Famagusta') (GMT +02:00) Famagusta
    option(value='Europe/Tallinn') (GMT +02:00) Tallinn
    option(value='Africa/Cairo') (GMT +02:00) Cairo
    option(value='Europe/Helsinki') (GMT +02:00) Helsinki
    option(value='Europe/Athens') (GMT +02:00) Athens
    option(value='Asia/Jerusalem') (GMT +02:00) Jerusalem
    option(value='Asia/Amman') (GMT +02:00) Amman
    option(value='Asia/Beirut') (GMT +02:00) Beirut
    option(value='Africa/Maseru') (GMT +02:00) Maseru
    option(value='Europe/Vilnius') (GMT +02:00) Vilnius
    option(value='Europe/Riga') (GMT +02:00) Riga
    option(value='Africa/Tripoli') (GMT +02:00) Tripoli
    option(value='Europe/Chisinau') (GMT +02:00) Chisinau
    option(value='Africa/Blantyre') (GMT +02:00) Blantyre
    option(value='Africa/Maputo') (GMT +02:00) Maputo
    option(value='Africa/Windhoek') (GMT +02:00) Windhoek
    option(value='Asia/Gaza') (GMT +02:00) Gaza
    option(value='Asia/Hebron') (GMT +02:00) Hebron
    option(value='Europe/Bucharest') (GMT +02:00) Bucharest
    option(value='Europe/Kaliningrad') (GMT +02:00) Kaliningrad
    option(value='Africa/Kigali') (GMT +02:00) Kigali
    option(value='Africa/Khartoum') (GMT +02:00) Khartoum
    option(value='Asia/Damascus') (GMT +02:00) Damascus
    option(value='Africa/Mbabane') (GMT +02:00) Mbabane
    option(value='Europe/Kiev') (GMT +02:00) Kiev
    option(value='Europe/Uzhgorod') (GMT +02:00) Uzhgorod
    option(value='Europe/Zaporozhye') (GMT +02:00) Zaporozhye
    option(value='Africa/Johannesburg') (GMT +02:00) Johannesburg
    option(value='Africa/Lusaka') (GMT +02:00) Lusaka
    option(value='Africa/Harare') (GMT +02:00) Harare
    option(value='Antarctica/Syowa') (GMT +03:00) Syowa
    option(value='Asia/Bahrain') (GMT +03:00) Bahrain
    option(value='Europe/Minsk') (GMT +03:00) Minsk
    option(value='Africa/Djibouti') (GMT +03:00) Djibouti
    option(value='Africa/Asmara') (GMT +03:00) Asmara
    option(value='Africa/Addis_Ababa') (GMT +03:00) Addis Ababa
    option(value='Asia/Baghdad') (GMT +03:00) Baghdad
    option(value='Africa/Nairobi') (GMT +03:00) Nairobi
    option(value='Indian/Comoro') (GMT +03:00) Comoro
    option(value='Asia/Kuwait') (GMT +03:00) Kuwait
    option(value='Indian/Antananarivo') (GMT +03:00) Antananarivo
    option(value='Asia/Qatar') (GMT +03:00) Qatar
    option(value='Europe/Moscow') (GMT +03:00) Moscow
    option(value='Europe/Simferopol') (GMT +03:00) Simferopol
    option(value='Europe/Volgograd') (GMT +03:00) Volgograd
    option(value='Europe/Kirov') (GMT +03:00) Kirov
    option(value='Asia/Riyadh') (GMT +03:00) Riyadh
    option(value='Africa/Mogadishu') (GMT +03:00) Mogadishu
    option(value='Africa/Juba') (GMT +03:00) Juba
    option(value='Europe/Istanbul') (GMT +03:00) Istanbul
    option(value='Africa/Dar_es_Salaam') (GMT +03:00) Dar es Salaam
    option(value='Africa/Kampala') (GMT +03:00) Kampala
    option(value='Asia/Aden') (GMT +03:00) Aden
    option(value='Indian/Mayotte') (GMT +03:00) Mayotte
    option(value='Asia/Tehran') (GMT +03:30) Tehran
    option(value='Asia/Dubai') (GMT +04:00) Dubai
    option(value='Asia/Yerevan') (GMT +04:00) Yerevan
    option(value='Asia/Baku') (GMT +04:00) Baku
    option(value='Asia/Tbilisi') (GMT +04:00) Tbilisi
    option(value='Indian/Mauritius') (GMT +04:00) Mauritius
    option(value='Asia/Muscat') (GMT +04:00) Muscat
    option(value='Indian/Reunion') (GMT +04:00) Reunion
    option(value='Europe/Astrakhan') (GMT +04:00) Astrakhan
    option(value='Europe/Saratov') (GMT +04:00) Saratov
    option(value='Europe/Ulyanovsk') (GMT +04:00) Ulyanovsk
    option(value='Europe/Samara') (GMT +04:00) Samara
    option(value='Indian/Mahe') (GMT +04:00) Mahe
    option(value='Asia/Kabul') (GMT +04:30) Kabul
    option(value='Antarctica/Mawson') (GMT +05:00) Mawson
    option(value='Asia/Aqtobe') (GMT +05:00) Aqtobe
    option(value='Asia/Aqtau') (GMT +05:00) Aqtau
    option(value='Asia/Atyrau') (GMT +05:00) Atyrau
    option(value='Asia/Oral') (GMT +05:00) Oral
    option(value='Indian/Maldives') (GMT +05:00) Maldives
    option(value='Asia/Karachi') (GMT +05:00) Karachi
    option(value='Asia/Yekaterinburg') (GMT +05:00) Yekaterinburg
    option(value='Indian/Kerguelen') (GMT +05:00) Kerguelen
    option(value='Asia/Dushanbe') (GMT +05:00) Dushanbe
    option(value='Asia/Ashgabat') (GMT +05:00) Ashgabat
    option(value='Asia/Samarkand') (GMT +05:00) Samarkand
    option(value='Asia/Tashkent') (GMT +05:00) Tashkent
    option(value='Asia/Kolkata') (GMT +05:30) Kolkata
    option(value='Asia/Colombo') (GMT +05:30) Colombo
    option(value='Asia/Kathmandu') (GMT +05:45) Kathmandu
    option(value='Antarctica/Vostok') (GMT +06:00) Vostok
    option(value='Asia/Dhaka') (GMT +06:00) Dhaka
    option(value='Asia/Thimphu') (GMT +06:00) Thimphu
    option(value='Asia/Urumqi') (GMT +06:00) Urumqi
    option(value='Indian/Chagos') (GMT +06:00) Chagos
    option(value='Asia/Bishkek') (GMT +06:00) Bishkek
    option(value='Asia/Almaty') (GMT +06:00) Almaty
    option(value='Asia/Qyzylorda') (GMT +06:00) Qyzylorda
    option(value='Asia/Omsk') (GMT +06:00) Omsk
    option(value='Indian/Cocos') (GMT +06:30) Cocos
    option(value='Asia/Yangon') (GMT +06:30) Yangon
    option(value='Antarctica/Davis') (GMT +07:00) Davis
    option(value='Indian/Christmas') (GMT +07:00) Christmas
    option(value='Asia/Jakarta') (GMT +07:00) Jakarta
    option(value='Asia/Pontianak') (GMT +07:00) Pontianak
    option(value='Asia/Phnom_Penh') (GMT +07:00) Phnom Penh
    option(value='Asia/Vientiane') (GMT +07:00) Vientiane
    option(value='Asia/Hovd') (GMT +07:00) Hovd
    option(value='Asia/Novosibirsk') (GMT +07:00) Novosibirsk
    option(value='Asia/Barnaul') (GMT +07:00) Barnaul
    option(value='Asia/Tomsk') (GMT +07:00) Tomsk
    option(value='Asia/Novokuznetsk') (GMT +07:00) Novokuznetsk
    option(value='Asia/Krasnoyarsk') (GMT +07:00) Krasnoyarsk
    option(value='Asia/Bangkok') (GMT +07:00) Bangkok
    option(value='Asia/Ho_Chi_Minh') (GMT +07:00) Ho Chi Minh
    option(value='Australia/Perth') (GMT +08:00) Perth
    option(value='Asia/Brunei') (GMT +08:00) Brunei
    option(value='Asia/Shanghai') (GMT +08:00) Shanghai
    option(value='Asia/Hong_Kong') (GMT +08:00) Hong Kong
    option(value='Asia/Makassar') (GMT +08:00) Makassar
    option(value='Asia/Ulaanbaatar') (GMT +08:00) Ulaanbaatar
    option(value='Asia/Choibalsan') (GMT +08:00) Choibalsan
    option(value='Asia/Macau') (GMT +08:00) Macau
    option(value='Asia/Kuala_Lumpur') (GMT +08:00) Kuala Lumpur
    option(value='Asia/Kuching') (GMT +08:00) Kuching
    option(value='Asia/Manila') (GMT +08:00) Manila
    option(value='Asia/Irkutsk') (GMT +08:00) Irkutsk
    option(value='Asia/Singapore') (GMT +08:00) Singapore
    option(value='Asia/Taipei') (GMT +08:00) Taipei
    option(value='Asia/Pyongyang') (GMT +08:30) Pyongyang
    option(value='Australia/Eucla') (GMT +08:45) Eucla
    option(value='Asia/Jayapura') (GMT +09:00) Jayapura
    option(value='Asia/Tokyo') (GMT +09:00) Tokyo
    option(value='Asia/Seoul') (GMT +09:00) Seoul
    option(value='Pacific/Palau') (GMT +09:00) Palau
    option(value='Asia/Chita') (GMT +09:00) Chita
    option(value='Asia/Yakutsk') (GMT +09:00) Yakutsk
    option(value='Asia/Khandyga') (GMT +09:00) Khandyga
    option(value='Asia/Dili') (GMT +09:00) Dili
    option(value='Australia/Broken_Hill') (GMT +09:30) Broken Hill
    option(value='Australia/Adelaide') (GMT +09:30) Adelaide
    option(value='Australia/Darwin') (GMT +09:30) Darwin
    option(value='Antarctica/DumontDUrville') (GMT +10:00) Dumont D&apos;Urville
    option(value='Australia/Hobart') (GMT +10:00) Hobart
    option(value='Australia/Currie') (GMT +10:00) Currie
    option(value='Australia/Melbourne') (GMT +10:00) Melbourne
    option(value='Australia/Sydney') (GMT +10:00) Sydney
    option(value='Australia/Brisbane') (GMT +10:00) Brisbane
    option(value='Australia/Lindeman') (GMT +10:00) Lindeman
    option(value='Pacific/Chuuk') (GMT +10:00) Chuuk
    option(value='Pacific/Guam') (GMT +10:00) Guam
    option(value='Pacific/Saipan') (GMT +10:00) Saipan
    option(value='Pacific/Port_Moresby') (GMT +10:00) Port Moresby
    option(value='Asia/Vladivostok') (GMT +10:00) Vladivostok
    option(value='Asia/Ust-Nera') (GMT +10:00) Ust-Nera
    option(value='Australia/Lord_Howe') (GMT +10:30) Lord Howe
    option(value='Antarctica/Casey') (GMT +11:00) Casey
    option(value='Antarctica/Macquarie') (GMT +11:00) Macquarie
    option(value='Pacific/Pohnpei') (GMT +11:00) Pohnpei
    option(value='Pacific/Kosrae') (GMT +11:00) Kosrae
    option(value='Pacific/Noumea') (GMT +11:00) Noumea
    option(value='Pacific/Norfolk') (GMT +11:00) Norfolk
    option(value='Pacific/Bougainville') (GMT +11:00) Bougainville
    option(value='Asia/Magadan') (GMT +11:00) Magadan
    option(value='Asia/Sakhalin') (GMT +11:00) Sakhalin
    option(value='Asia/Srednekolymsk') (GMT +11:00) Srednekolymsk
    option(value='Pacific/Guadalcanal') (GMT +11:00) Guadalcanal
    option(value='Pacific/Efate') (GMT +11:00) Efate
    option(value='Antarctica/McMurdo') (GMT +12:00) McMurdo
    option(value='Pacific/Fiji') (GMT +12:00) Fiji
    option(value='Pacific/Tarawa') (GMT +12:00) Tarawa
    option(value='Pacific/Majuro') (GMT +12:00) Majuro
    option(value='Pacific/Kwajalein') (GMT +12:00) Kwajalein
    option(value='Pacific/Nauru') (GMT +12:00) Nauru
    option(value='Pacific/Auckland') (GMT +12:00) Auckland
    option(value='Asia/Kamchatka') (GMT +12:00) Kamchatka
    option(value='Asia/Anadyr') (GMT +12:00) Anadyr
    option(value='Pacific/Funafuti') (GMT +12:00) Funafuti
    option(value='Pacific/Wake') (GMT +12:00) Wake
    option(value='Pacific/Wallis') (GMT +12:00) Wallis
    option(value='Pacific/Chatham') (GMT +12:45) Chatham
    option(value='Pacific/Enderbury') (GMT +13:00) Enderbury
    option(value='Pacific/Fakaofo') (GMT +13:00) Fakaofo
    option(value='Pacific/Tongatapu') (GMT +13:00) Tongatapu
    option(value='Pacific/Apia') (GMT +13:00) Apia
    option(value='Pacific/Kiritimati') (GMT +14:00) Kiritimati
</template>

<script>
import {mapState} from 'vuex'
import dayjs from 'dayjs'
export default {
  name: 'TimezoneSelect',
  props: {
    value: {type: String, default: undefined},
    size: {type: String, default: undefined},
    placeholder: {type: String, default: undefined},
    expanded: {type: String, default: undefined}
  },
  inject: ['$validator'],
  data() {
    return {
      timezone: null
    }
  },
  computed: {
    ...mapState('company', ['company'])
  },
  watch: {
    value(newVal) {
      if (newVal !== this.timezone) {
        this.timezone = this.value
      }
    }
  },
  async created() {
    if (this.value) {
      this.timezone = this.value
    } else if (this.company.timezone) {
      this.timezone = this.company.timezone
    } else {
      this.timezone = dayjs.tz.guess()
    }
    this.onChange()
  },
  methods: {
    onChange() {
      this.$emit('input', this.timezone)
    }
  }
}
</script>
