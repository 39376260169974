<template lang="pug">
  .modal-card
    header.modal-card-head
      p.modal-card-title
        b(v-t="'customer.edit_tags_header'")
      div
        a.button.is-white.is-small(@click="$emit('close')")
          span.icon
            i.mdi.mdi-close.mdi-24px
    section.modal-card-body(style="min-height: 300px")
      b-field(:label="$t('customer.tags_label')")
        b-taginput(
            v-model="tags"
            :data="company.crm.tags"
            type="is-primary"
            attached
            autocomplete
            ellipsis
            open-on-focus
            maxtags="20"    
            icon="label"
            :placeholder="$t('customer.tags_placeholder')")
      p {{ $t('customer.tags_help') }} 
        router-link(to="/settings/crm" target="_blank") {{ $t('misc.here') }}
    footer.modal-card-foot
      .buttons
        action-button(@click="save" title="actions.save"  :loading="loading" permission="CLIENTS_EDIT" )
        button.button(@click="$emit('close')") {{$t('actions.cancel')}}
</template>

<script>
import {mapActions, mapState} from 'vuex'

export default {
  name: 'CustomerTagsEditor',
  props: {
    customerId: {type: String, required: true}
  },
  data() {
    return {
      tags: [],
      loading: false
    }
  },
  computed: {
    ...mapState('company', ['company'])
  },
  async created() {
    const customer = await this.fetchCustomer(this.customerId)
    this.tags = customer.tags || []
  },
  methods: {
    ...mapActions('customers', ['updateCustomerTags', 'fetchCustomer']),
    async save() {
      this.loading = true
      try {
        await this.updateCustomerTags({id: this.customerId, tags: this.tags})
        this.$emit('close')
      } catch (error) {
        this.$handleAPIError(error)
      }
      this.loading = false
    }
  }
}
</script>
