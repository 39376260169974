<template lang="pug">
  section
    screen-title(title="rewards_program.dashboard.title")
    .field.is-grouped
      PeriodSelector(v-model="period" initial="alltime")
      LocationSelector(v-model="location")
    .tile.is-ancestor
      .tile
        .tile.is-parent
          .tile.is-child.box
            MembersStats(:period="period" :location="location")
        .tile.is-parent
          .tile.is-child.box
            MembersSalesStats(:period="period" :location="location")
      PointsBalance(:period="period" :location="location")
    h2.title.is-4(v-t="'rewards.members_per_tier_title'")
    .tile.is-ancestor
      MembersDistribution
      //.tile
        .tile.is-parent  
          .tile.is-child.box
            h3.title.is-6.is-uppercase.has-text-darkgrey Reward Collected Vs Redeemed
            h5.subtitle.is-7.has-text-grey {{period && period.display}}
            DailyPoints(:style="{width: '100%', height: '300px'}", :period="period" :location="location")
        //.tile.is-parent
          .tile.is-child.box
            h3.title.is-6.is-uppercase.has-text-darkgrey Sales
            h5.subtitle.is-7.has-text-grey {{period && period.display}}
            DailySales(:style="{width: '100%', height: '200px'}", :period="period" :location="location")
        .tile.is-parent  
          .tile.is-child.box
            h3.title.is-6.is-uppercase.has-text-darkgrey New Members
            h5.subtitle.is-7.has-text-grey {{period && period.display}}
            DailyMemberships(:style="{width: '100%', height: '200px'}", :period="period" :location="location")
    .mb-5
      h3.title.is-4(v-t="'rewards.new_members_title'")
      p.subtitle.is-7 {{period && period.display}}
      DailyMemberships(:style="{width: '100%', height: '200px'}", :period="period" :location="location")
    div
      h3.title.is-4(v-t="'rewards.rewards_collected_redeemed_title'")
      p.subtitle.is-7 {{period && period.display}}
      DailyPoints(:style="{width: '100%', height: '300px'}", :period="period" :location="location")
      //.tile
        .tile.is-parent  
          .tile.is-child.box
            h3.title.is-6.is-uppercase.has-text-darkgrey Reward Collected Vs Redeemed
            h5.subtitle.is-7.has-text-grey {{period && period.display}}
            DailyPoints(:style="{width: '100%', height: '300px'}", :period="period" :location="location")
</template>

<script>
import PointsBalance from './analytics/PointsBalance'
import MembersSalesStats from './analytics/MembersSalesStats'
import MembersStats from './analytics/MembersStats'
import MembersDistribution from './analytics/MembersDistribution'
import PeriodSelector from '../helpers/PeriodSelector'
import DailyMemberships from './analytics/DailyMembershipsLineChart'
import DailyPoints from './analytics/DailyPoints'
import LocationSelector from '../helpers/LocationSelector'

export default {
  name: 'RewardsProgramDashboard',
  components: {
    PeriodSelector,
    PointsBalance,
    MembersSalesStats,
    MembersStats,
    MembersDistribution,
    DailyMemberships,
    DailyPoints,
    LocationSelector
  },
  data() {
    return {
      location: undefined,
      period: undefined
    }
  }
}
</script>
