<template lang="pug">
  span(v-if="customer.birthdate") 
    span.is-small(:class="{'is-success': hasNearBirthday, 'tag': tag}" )
      span.icon.is-small
        i.mdi.mdi-cake-variant
      span {{formattedBirthday}}
</template>

<script>
import dayjs from 'dayjs'

export default {
  props: {
    customer: {type: Object, required: true},
    tag: {type: Boolean, default: true}
  },
  computed: {
    formattedBirthday() {
      let value = '' + this.customer.birthdate
      value = value.length === 3 ? '0' + value : value
      if (value === dayjs().format('MMDD')) {
        return this.$t('periods.today')
      }

      let assumedComingDay = dayjs(dayjs().year() + value)
      if (assumedComingDay.isBefore(dayjs())) {
        assumedComingDay = assumedComingDay.add(1, 'year')
      }
      return assumedComingDay.format('D MMM')
    },
    hasNearBirthday() {
      let today = parseInt(dayjs().format('MMDD'))
      return Math.abs(today - this.customer.birthdate) <= 7
    }
  }
}
</script>
