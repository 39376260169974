<template lang="pug">
.modal-card
  header.modal-card-head.is-hidden-print
    p.modal-card-title(data-qa="modal-header") 
      b(v-if="form") {{ form.name }}
    div
      a.button.is-white.is-small(@click="$emit('close')")
        span.icon
          i.mdi.mdi-close.mdi-24px
  section.modal-card-body.is-fullwidth-print
    em-loading(:active="loading" :is-full-page="false")  
    .is-printable.is-fullwidth-print(v-if="form")
      .columns.has-background-light.mb-4
        .column.is-6 
          p.heading(v-t="'forms.customer_label'")
          customer-tiny-preview(:value="customer" v-if="customer")
        .column.is-6
          p.heading(v-t="'forms.created_heading'")
          p
            date-view(:value="customerForm.created" format="L LT")
          p(v-if="customerForm.user")
            staff-view(:id="customerForm.user")
      
      h2.title.is-4 {{ form.title }}
      h2.subtitle(v-if="form.subtitle") {{ form.subtitle }}
      
      .mb-4(v-for="(field, idx) in customerForm.answers" :key="field._id")
        template(v-if="field.type === FIELD_TYPES.TEXT")
          label.label {{field.label }}
          p(v-if="field.value") {{ field.value }}
          p.has-text-grey(v-else v-t="'forms.field_no_value'") 
        template(v-if="field.type === FIELD_TYPES.TEXTAREA")
          label.label {{field.label }}
          p(v-if="field.value") {{ field.value }}
          p.has-text-grey(v-else v-t="'forms.field_no_value'") 
        template(v-if="field.type === FIELD_TYPES.SELECT")
          label.label {{field.label }}
          p(v-if="field.value") {{ field.value }}
          p.has-text-grey(v-else v-t="'forms.field_no_value'") 
        template(v-if="field.type === FIELD_TYPES.RADIO")
          label.label {{field.label }}
          p(v-if="field.value") {{ field.value }}
          p.has-text-grey(v-else v-t="'forms.field_no_value'") 
        template(v-if="field.type === FIELD_TYPES.CHECKBOXES")
          label.label {{field.label }}
          p(v-if="field.value") {{ field.value.join(', ') }}
          p.has-text-grey(v-else v-t="'forms.field_no_value'") 
        template(v-if="field.type === FIELD_TYPES.IMAGE")
          label.label {{field.label }}
          .columns.is-multiline
            .column.is-6(v-for="url in field.value" :key="url")
              img(:src="url"  style="width:100%")
      
  footer.modal-card-foot.is-hidden-print
    .buttons
      action-button(@click="edit" title="actions.edit") 
      button.button(@click="$emit('close')" v-t="'actions.close'") 

</template>
<script>
import {mapActions} from 'vuex'
import FIELD_TYPES from '../../../constants/formFieldTypes'
import FormPreview from './FormPreview.vue'
export default {
  name: 'CustomerForm',
  props: {
    customerFormId: {type: String, required: true}
  },
  data() {
    return {
      customer: undefined,
      customerForm: undefined,
      form: undefined,
      loading: false,
      FIELD_TYPES
    }
  },
  async created() {
    this.loading = true
    try {
      this.customerForm = await this.fetchCustomerFormById(this.customerFormId)
      this.form = await this.getFormById(this.customerForm.form)
      this.customer = await this.fetchCustomer(this.customerForm.customer)
    } catch (err) {
      this.$handleAPIError(err)
    }
    this.loading = false
  },
  methods: {
    ...mapActions('forms', ['fetchCustomerFormById', 'getFormById']),
    ...mapActions('customers', ['fetchCustomer']),
    edit() {
      this.$buefy.modal.open({
        parent: this,
        component: FormPreview,
        hasModalCard: true,
        fullScreen: false,
        props: {
          formId: this.form._id,
          customerFormId: this.customerFormId,
          customerId: this.customerForm.customer
        },
        canCancel: ['escape']
      })
      this.$emit('close')
    }
  }
}
</script>
