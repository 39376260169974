<template lang="pug">
span(v-if="service && !twoLines")
  span(:class="{'has-text-grey': service.archived}") 
    span {{name}}
    span.icon.is-small.ml-1(v-if="service.color" :style="{'color': `#${service.color}`}")
      i.mdi.mdi-circle
div(v-else-if="service && twoLines" ) 
  p.title.is-6(:class="{'has-text-grey': service.archived}") 
    span {{ serviceName }}
    span.icon.is-small.ml-1(v-if="service.color" :style="{'color': `#${service.color}`}")
      i.mdi.mdi-circle
  p.subtitle.is-6.has-text-grey
    span(v-if="optionName") {{ optionName }}
    category-name-view(v-else :value="service.category")
    |  
    duration-view(v-if="option" :value="option.duration")
    
</template>

<script>
import {mapActions, mapState} from 'vuex'

export default {
  name: 'ServiceNameView',
  props: {
    value: {type: Object, required: true},
    twoLines: {type: Boolean, default: false}
  },
  data() {
    return {
      service: undefined,
      option: undefined
    }
  },
  computed: {
    ...mapState('auth', ['user']),
    name() {
      if (!this.service) {
        return undefined
      }

      return `${this.serviceName}${this.optionName ? ` / ${this.optionName}` : ''}`
    },
    serviceName() {
      let serviceName = this.service.name
      if (this.user.lng) {
        serviceName = (this.service.names && this.service.names[this.user.lng]) || serviceName
      }
      return serviceName
    },
    optionName() {
      let optionName = ''
      if (this.option) {
        optionName = this.option.name
      }
      return optionName
    }
  },
  watch: {
    value() {
      this.init()
    }
  },
  async created() {
    this.init()
  },
  methods: {
    ...mapActions('services', ['fetchServiceById']),
    async init() {
      this.service = await this.fetchServiceById(this.value.service)
      if (this.value.option) {
        this.option = this.service.pricingOptions.find(o => o._id === this.value.option)
      }
    }
  }
}
</script>
