<template lang="pug">
Invoice(:saleId="saleId" @printReady="print")
</template>
<script>
import Invoice from './Invoice'
export default {
  components: {
    Invoice
  },
  props: {
    saleId: {type: [String, Number], required: true}
  },

  methods: {
    print() {
      window.print()
    }
  }
}
</script>
