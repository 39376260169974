<template lang="pug">
b-collapse(:class="['box']" animation="slide" v-model="expanded" style="position: relative")
  template(#trigger)  
    .media.is-align-items-center()
      .media-left
        span.is-medium
          i.mdi.mdi-chevron-right.mdi-24px(v-if="!expanded || disabled")
          i.mdi.mdi-chevron-down.mdi-24px(v-else)
      .media-body(style="flex: 1 1 auto" v-if="item.type === 'service'")
        p.title.is-5.is-size-5-mobile   
          service-name-view(:value="{service: item.service, option: item.option}")
          |  
          MembershipNameView(:value="appliedMembership" v-if="appliedMembership") 
          
        p.subtitle.is-6
          template(v-if="item.staff")
            staff-view(:id="item.staff")
            |  -  
          template(v-if="item.resource")
            resource-view(:value="item.resource")
            |  -  
          template(v-if="item.staff")
            b-tooltip(:label="item.commissionRate ? `${item.commissionRate}%` : 'no commission'")
              currency-view(:value="item.commission")
            |  -  
          duration-view(:value="item.duration")
          
      .media-body(style="flex: 1 1 auto" v-else-if="item.type === 'product'")
        p.title.is-5.is-size-5-mobile
          product-name-view(:value="item.product")
          small(v-if="product")  -   
            stock-view(:amount="item.qty || 0" :unit="product.unit")
        p.subtitle.is-6(v-if="isRetail")
          staff-view(v-if="item.staff" :id="item.staff")
          |  -  
          b-tooltip(:label="item.commissionRate ? `${item.commissionRate}%` : 'no commission'")
            currency-view(:value="item.commission")
      
      .media-body(style="flex: 1 1 auto" v-else-if="item.type === 'voucher'")
        p.title.is-5.is-size-5-mobile(v-if="item.voucherData")
          span {{item.voucherData.name}}   
          template(v-if="item.voucherData.code") 
            |  
            span {{item.voucherData.code}}
            
          |   
          currency-view(:value="item.voucherData.value")
        p.title.is-5.is-size-5-mobile(v-else)
          voucher-name-view(:value="item.voucher" showNominalValue)
        p.subtitle.is-6.has-text-grey
          staff-view(v-if="item.staff" :id="item.staff")
          |  -  
          b-tooltip(:label="item.commissionRate ? `${item.commissionRate}%` : 'no commission'")
            currency-view(:value="item.commission")
      
      .media-body(style="flex: 1 1 auto" v-else-if="item.type === 'membership'")
        p.title.is-5.is-size-5-mobile
          membership-package-name-view(:value="item.membershipPackage")
        p.subtitle.is-6.has-text-grey
          staff-view(v-if="item.staff" :id="item.staff")
          |  -  
          b-tooltip(:label="item.commissionRate ? `${item.commissionRate}%` : 'no commission'")
            currency-view(:value="item.commission")
          
      .media-right.pr-4
        p.is-size-5.has-text-right(v-if="isRetail")
          strong
            currency-view(:value="item.total" v-if="priceIncludesTax")
            currency-view(:value="item.subtotal - item.discount" v-else)
        p.has-text-grey.is-size-6.has-text-right(v-if="item.manualDiscount")
          span.has-text-strikedout 
            currency-view(:value="item.itemPrice * item.qty")
        button.delete(@click="$emit('remove')" v-if="removable")
  
  .pt-4(v-if="!disabled")
    b-field(grouped group-multiline)
      b-field(:label="$t('sale.price_label')" v-show="isRetail && discountable" :type="errors.has('price') ? 'is-danger': ''" :message="errors.has('price') ? errors.first('price') : ''")
        b-field
          p.control
            span.button.is-static 
              currency
          b-input(v-model.number="item.manualPrice" @input="update" type="number"  data-vv-as="price" name="price" numeric v-validate="'required|decimal:2|min_value:0'" :use-html5-validation="false")
      b-field(v-if="item.type === 'product'")
        b-field(:label="$t('sale.qty_label')")
          b-input(v-model.number="item.qty" @input="update"  style="width: 80px")
          p.control
            span.button.is-static(v-if="product && product.unit") {{$t(`units.${product.unit}`)}}
              
      b-field(:label="$t('sale.staff_label')" expanded v-if="item.staff")
        StaffSelect(
          :staffId="item.staff" :preferred="item.preferred" 
          expanded 
          :has-preferred="item.type === SALE_ITEM_TYPES.SERVICE"
          @input="updateStaff" 
          :locationId="locationId" 
          :serviceId="item.service"
          )
</template>
<script>
import {mapActions, mapState} from 'vuex'
import saleHelper from '../../../shared/saleHelper'

import SALE_ITEM_TYPES from '../../../constants/saleItemTypes'
import PRODUCT_TYPES from '../../../constants/productTypes'

import StaffSelect from '../staff/StaffSelect'
import MembershipNameView from '../membership/MembershipNameView'
import ProductPic from '../inventory/ProductPic'

export default {
  name: 'SaleItemEdit',
  components: {
    StaffSelect,
    ProductPic,
    MembershipNameView
  },
  props: {
    value: {type: Object, required: true},
    locationId: {type: String, required: true},
    removable: {type: Boolean, default: true},
    saleItems: {type: Array, required: true},
    customerCoupon: {type: Object, default: undefined},
    memberships: {type: Array, default: undefined},
    collapsed: {type: Boolean, default: true},
    disabled: {type: Boolean, default: false}
  },
  data() {
    return {
      SALE_ITEM_TYPES,
      item: undefined,
      expanded: false,
      product: undefined
    }
  },
  provide() {
    return {
      $validator: this.$validator
    }
  },
  computed: {
    ...mapState('tax', ['taxesMap']),
    ...mapState('company', ['company']),
    ...mapState('location', ['locationsMap']),
    priceIncludesTax() {
      return this.company.priceIncludesTax
    },
    isRetail() {
      return !this.product || this.product.type === PRODUCT_TYPES.RETAIL || this.product.isAddon
    },
    discountable() {
      return (
        this.item.type === SALE_ITEM_TYPES.SERVICE ||
        this.item.type === SALE_ITEM_TYPES.PRODUCT ||
        this.item.type === SALE_ITEM_TYPES.VOUCHER
      )
    },
    appliedMembership() {
      if (this.item.type !== SALE_ITEM_TYPES.SERVICE || !this.memberships) {
        return
      }
      return saleHelper.findEligibleMembership({memberships: this.memberships, item: this.item})
    }
  },
  watch: {
    async value(newVal) {
      if (this.item === newVal) {
        return
      }
      this.item = Object.assign({}, newVal)
      await this.init()
      await this.update()
    },
    customerCoupon() {
      this.update()
    },
    memberships() {
      this.update()
    }
  },
  async created() {
    this.item = Object.assign({}, this.value)
    this.expanded = !this.collapsed || this.item.type === SALE_ITEM_TYPES.PRODUCT
    await this.init()
    await this.update()
  },
  methods: {
    ...mapActions('services', ['fetchServiceById']),
    ...mapActions('membershipPackages', ['getMembershipPackageById']),
    ...mapActions('inventory', ['fetchProduct']),
    ...mapActions('staff', ['fetchStaffById']),
    async update() {
      await this.recalculate()
      this.$emit('input', this.item)
    },
    async init() {
      if (this.item.type === SALE_ITEM_TYPES.PRODUCT) {
        this.product = await this.fetchProduct(this.item.product)
      }
    },
    async updateStaff({staffId, preferred}) {
      this.item.staff = staffId
      this.item.preferred = preferred
      if (this.item.type === SALE_ITEM_TYPES.SERVICE) {
        let service = await this.fetchServiceById(this.item.service)
        let option = service.pricingOptions.find(o => o._id === this.item.option)
        const {price} = saleHelper.getPricingOption(option, this.item.staff)
        if (this.item.price !== price) {
          const formattedPrice = saleHelper.formatCurrency(price, this.company.currency)
          const {firstName, lastName} = await this.fetchStaffById(this.item.staff)
          this.$buefy.dialog.confirm({
            title: this.$t('sale.staff_change_price_warning.title'),
            type: 'is-black',
            message: this.$t('sale.staff_change_price_warning.body', {firstName, lastName, price: formattedPrice}),
            cancelText: this.$t('misc.no'),
            confirmText: this.$t('misc.yes'),
            onConfirm: () => {
              this.item.price = price
              this.item.manualPrice = price
              this.item.priceAdjustment = 0
              this.update()
            }
          })
        }
      }
      this.update()
    },
    async recalculate() {
      let taxes = await this.getItemTaxRates()
      const {commissionRate, commissionBase} = await this.getCommissionCriteria()
      saleHelper.recalculate({
        item: this.item,
        items: this.saleItems,
        taxes,
        priceIncludesTax: this.company.priceIncludesTax,
        customerCoupon: this.customerCoupon,
        commissionType: this.company.commissionType,
        commissionRate,
        commissionBase,
        memberships: this.memberships,
        rewardsEligible: await this.getRewardsEligibility()
      })
    },
    async getRewardsEligibility() {
      if (!this.company.membership.collectFrom || !this.company.membership.collectFrom.includes(this.item.type)) {
        return false
      }
      if (this.item.type === SALE_ITEM_TYPES.SERVICE) {
        let service = await this.fetchServiceById(this.item.service)
        return service.collectRewards !== false
      } else if (this.item.type === SALE_ITEM_TYPES.PRODUCT) {
        let product = await this.fetchProduct(this.item.product)
        return product.collectRewards !== false
      } else if (this.item.type === SALE_ITEM_TYPES.MEMBERSHIP) {
        let membershipPackage = await this.getMembershipPackageById(this.item.membershipPackage)
        return membershipPackage.collectRewards !== false
      }
      return true
    },
    async getCommissionCriteria() {
      let staffMember = this.item.staff && (await this.fetchStaffById(this.item.staff))
      let commissionRate = 0
      let commissionBase = 0
      if (staffMember && staffMember.commission) {
        if (this.item.type === SALE_ITEM_TYPES.SERVICE && staffMember.service) {
          let service = await this.fetchServiceById(this.item.service)
          if (service.commissionRate !== undefined) {
            commissionRate = service.commissionRate
            commissionBase = service.commissionType || staffMember.commission.servicesType
          } else {
            commissionRate = staffMember.commission.services || 0
            commissionBase = staffMember.commission.servicesType
          }
        } else if (this.item.type === SALE_ITEM_TYPES.PRODUCT && this.product.commission) {
          let product = await this.fetchProduct(this.item.product)

          if (product.commissionRate !== undefined) {
            commissionRate = product.commissionRate
            commissionBase = product.commissionType || staffMember.commission.retailType
          } else {
            commissionRate = staffMember.commission.retail || 0
            commissionBase = staffMember.commission.retailType
          }
        } else if (this.item.type === SALE_ITEM_TYPES.MEMBERSHIP) {
          let membershipPackage = await this.getMembershipPackageById(this.item.membershipPackage)

          if (membershipPackage.commissionRate !== undefined) {
            commissionRate = membershipPackage.commissionRate
            commissionBase = membershipPackage.commissionType || staffMember.commission.servicesType
          } else {
            commissionRate = staffMember.commission.services || 0
            commissionBase = staffMember.commission.servicesType
          }
        }
      }

      return {commissionRate, commissionBase}
    },
    async getItemTaxRates() {
      let taxId
      if (this.item.service) {
        let service = await this.fetchServiceById(this.item.service)
        taxId = service.taxes && service.taxes[this.locationId]
      } else if (this.item.product) {
        let product = await this.fetchProduct(this.item.product)
        taxId = product.taxes && product.taxes[this.locationId]
      } else if (this.item.type === SALE_ITEM_TYPES.VOUCHER) {
        let taxDefaults = this.locationsMap[this.locationId].taxDefaults
        if (taxDefaults && taxDefaults.services) {
          taxId = taxDefaults.services
        }
      } else if (this.item.type === SALE_ITEM_TYPES.MEMBERSHIP) {
        let taxDefaults = this.locationsMap[this.locationId].taxDefaults
        if (taxDefaults && taxDefaults.services) {
          taxId = taxDefaults.services
        }
      }

      if (taxId) {
        let tax = this.taxesMap[taxId]
        let items = (tax.items && tax.items.length && tax.items.map(tId => this.taxesMap[tId])) || [tax]
        return items.map(i => {
          return {rate: i.rate, tax: i._id, name: i.name}
        })
      }
      return []
    }
  }
}
</script>
