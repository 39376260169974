import Vue from 'vue'
import VueI18n from 'vue-i18n'

import dayjs from 'dayjs'
Vue.use(VueI18n)

const loadedLanguages = ['en']

export const i18n = new VueI18n({
  fallbackLocale: 'en',
  locale: 'en',
  messages: {
    en: require('./locales/en.json')
  }
})

function setI18nLanguage(lang) {
  i18n.locale = lang
  localStorage.setItem('ui-locale', lang)
  initDayjsLocale(lang)
  document.querySelector('html').setAttribute('lang', lang)
  return lang
}
const DAYJS_LOCALES = {
  hy: 'hy-am',
  en: 'en',
  ru: 'ru'
}
function initDayjsLocale(locale = 'en') {
  dayjs.locale(DAYJS_LOCALES[locale])
}

export async function loadLanguageAsync(lang = 'en') {
  // If the same language
  if (i18n.locale === lang) {
    return setI18nLanguage(lang)
  }

  // If the language was already loaded
  if (loadedLanguages.includes(lang)) {
    return setI18nLanguage(lang)
  }
  try {
    let messages = await import(/* webpackChunkName: "lang-[request]" */ `@/locales/${lang}.json`)
    i18n.setLocaleMessage(lang, messages)
    loadedLanguages.push(lang)
    return setI18nLanguage(lang)
  } catch (err) {
    console.log(err)
  }
  // If the language hasn't been loaded yet
}
