<template lang="pug">
button.button(@click.stop="onRebook()" v-if="hasPreviousAppointment" :class="loading && 'is-loading'") 
  span.icon
    i.mdi.mdi-refresh
  slot
</template>
<script>
import AppointmentsApi from '@/api/appointments'
import APPOINTMENT_STATUS from '../../../constants/appointmentStatus'
import dayjs from 'dayjs'
export default {
  name: 'RepeatLastAppointmentButton',
  props: {customer: {type: Object, required: true}},
  data() {
    return {
      loading: false
    }
  },
  computed: {
    hasPreviousAppointment() {
      return this.customer.visits > 0
    }
  },
  methods: {
    async onRebook() {
      this.loading = true
      try {
        let lastAppt = await AppointmentsApi.getLastAppointment(this.customer._id, APPOINTMENT_STATUS.COMPLETED)
        if (lastAppt) {
          this.$emit('click', {
            customerId: this.customer._id,
            rebookingId: lastAppt._id,
            date: this.customer.nextVisitDue
              ? dayjs(this.customer.nextVisitDue).format('YYYY-MM-DD')
              : dayjs(lastAppt.start)
                  .add(2, 'weeks')
                  .format('YYYY-MM-DD')
          })
        }
      } catch (err) {
        console.log(err)
      }
      this.loading = false
    }
  }
}
</script>
