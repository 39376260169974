<template lang="pug">
.is-flex
  .bordered-box.is-primary.p-5
    div
      p.heading {{ $t('billing.current_plan_heading') }}
      p.title.is-size-4 {{$t(`products.${currentPlanSettings.plan}`)}} 
        
      template(v-if="company.manualPayment")
        p.heading(v-t="'billing.price_heading'") 
        h3.title.is-5(v-t="'billing.manual_payment'")
      template(v-else)
        template(v-if="currentPlanSettings.free")
          p.subtitle You are using a forever free plan. 
            br
            | Subscribe to premium plans to unlock full potential of emly.
          //h3.title.is-5.is-uppercase {{ $t('billing.free') }}
        template(v-if="!currentPlanSettings.free")
          template(v-if="subscription.amount")
            //p.heading(v-t="'billing.price_heading'")
            h3.title.is-5 
              InvoiceCurrency(:value="subscription.amount * subscription.quantity" :currencyCode="subscription.currency")
              |  {{ $t('misc.month') }}
            p.subtitle.is-6.has-text-grey
              span {{ $t('billing.locations_number', {count: subscription.quantity}) }}
              br
              span(v-t="'billing.price_per_location'")
              |  
              InvoiceCurrency(:value="subscription.amount" :currencyCode="subscription.currency")
              |  {{ $t('misc.month') }}
              br
              
            
          
          //p.heading(v-t="'billing.subscription_status_heading'")
          template(v-if="subscription.status === 'active' && subscription.cancelAtPeriodEnd")
            hr
            h3.title.is-5.has-text-danger.is-uppercase {{ $t('billing.status.canceling') }}
            p.subtitle.is-6 {{ $t('billing.subscription_canceling') }} 
              b 
                date-view(:value="new Date(subscription.currentPeriod.end * 1000)" format="LL")
          //h3.title.is-5.has-text-success(v-else-if="subscription.status === 'active'") {{ $t('billing.status.active') }}
          template(v-else-if="subscription.status === 'incomplete'")
            hr
            h3.title.is-5.has-text-danger.is-uppercase {{ $t('billing.status.incomplete') }}
            p.subtitle.is-6 {{ $t('billing.initial_payment_failed') }}
          template(v-else-if="subscription.status === 'incomplete_expired'")
            hr
            h3.title.is-5.has-text-danger.is-uppercase {{ $t('billing.status.expired') }}
            p.subtitle.is-6 {{ $t('billing.initial_payment_failed') }}
          template(v-else-if="subscription.status !== 'active' && currentPlanItem.trialEnd")
            hr
            h3.title.is-6.has-text-danger.mb-2.is-uppercase(v-if="currentPlanItem.canceled") {{ $t('billing.status.canceled') }}
            h3.title.is-6.has-text-danger.mb-2.is-uppercase(v-else-if="isInPast(currentPlanItem.trialEnd)") {{ $t('billing.status.trial_ended') }}
            h3.title.is-6.has-text-primary.mb-2(v-else) {{ $t('billing.status.free_trial') }} ({{ $t('billing.free_trial_left', {days: remainingDays(currentPlanItem.trialEnd)}) }})
            .buttons.mt-4
              action-button.is-fullwidth.is-primary(@click="subscribeNow" title="billing.actions.subscribe_now") 
          template(v-else-if="subscription.status === 'active'")
            p.mb-3
              span.has-text-black {{ $t('billing.renews_on') }} 
                b
                  date-view(:value="new Date(subscription.currentPeriod.end*1000)" format="LL")
        //template(v-if="currentPlanItem.price")
          p.heading {{ $t('billing.price_heading') }}
          template(v-if="currentPlanItem.price.billing_scheme === 'tiered'")
            span(v-if="!currentPlanItem.price.tiers.find(t => t.up_to === currentPlanItem.quantity).unit_amount") -
            InvoiceCurrency(v-else :value="currentPlanItem.price.tiers.find(t => t.up_to === currentPlanItem.quantity).unit_amount" :currencyCode="currentPlanItem.price.currency")
          template(v-else)
            InvoiceCurrency(:value="currentPlanItem.price.unit_amount" :currencyCode="currentPlanItem.price.currency")
            |  x  
            span  {{currentPlanItem.quantity}}
      
      .buttons(v-if="!company.manualPayment")
        action-button.is-fullwidth.is-outlined(v-if="subscription.status === 'active' && subscription.cancelAtPeriodEnd" @click="resumeSubscription" title="actions.resume" :loading="resuming" icon="play") 
        action-button.is-fullwidth.is-outlined(v-else-if="subscription.status" @click="changePlan()" title="billing.actions.change_plan") 
        action-button.is-fullwidth(v-else-if="currentPlanSettings.free" @click="changePlan()" title="billing.actions.upgrade_to_premium")
      .buttons(v-else)
        a.button.is-primary.is-uppercase 
          b {{ $t('product_teaser.contact_sales') }}
</template>

<script>
import dayjs from 'dayjs'
import {mapActions, mapGetters, mapState} from 'vuex'
import {SETTINGS} from '../../../constants/products'
import saleHelper from '../../../shared/saleHelper'
import InvoiceCurrency from './InvoiceCurrency.vue'

import SubscribeToPaidPlan from './SubscribeToPaidPlan'
import Pricing from './Pricing'

export default {
  name: 'SubscriptionStatus',
  components: {
    InvoiceCurrency
  },
  data() {
    return {
      resuming: false
    }
  },
  computed: {
    ...mapState('billing', ['subscription']),
    ...mapState('company', ['company']),
    ...mapGetters('company', ['currentPlan']),
    currentPlanSettings() {
      return SETTINGS[this.currentPlan]
    },
    currentPlanItem() {
      return this.subscription.plan
    }
  },
  methods: {
    ...mapActions('billing', ['fetchProducts']),
    async fetchPlans() {
      this.loading = true
      try {
        this.plans = await this.fetchProducts()
      } catch (error) {
        this.$handleAPIError(error)
      }
      this.loading = false
    },
    formatPrice(plan) {
      if (plan.free) {
        return
      }
      return saleHelper.formatCurrency(plan.prices.monthly[0].unit_amount / 100, plan.prices.monthly[0].currency)
    },
    isInPast(date) {
      return dayjs(date).isBefore(new Date())
    },
    remainingDays(date) {
      return dayjs(date).diff(new Date(), 'day')
    },
    async resumeSubscription() {
      this.resuming = true
      try {
        await this.$store.dispatch('billing/resumeSubscription')
      } catch (error) {
        this.$handleAPIError(error)
      }
      this.resuming = false
    },
    subscribeNow() {
      this.$buefy.modal.open({
        parent: this,
        component: SubscribeToPaidPlan,
        hasModalCard: true,
        props: {
          planCode: this.currentPlan
        },
        canCancel: ['escape', 'outside']
      })
    },
    changePlan() {
      this.$buefy.modal.open({
        parent: this,
        component: Pricing,
        fullScreen: true,
        hasModalCard: true,
        props: {
          country: this.company.country,
          industry: this.company.industry
        },
        canCancel: ['escape', 'outside']
      })
    }
  }
}
</script>
