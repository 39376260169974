<template lang="pug">
  b-table.is-vertically-aligned(
    :data="data" 
    :loading="loading" 
    hoverable=true 
    :mobile-cards="false"
    @click="showDetails" 
    :row-class="getTodayHighlightClass"
    )
    
    b-table-column(field="invoice" :label="$t('sale.list_invoice_id_heading')"  v-slot="props")      
      //
      span {{$t('sale.invoice_number', {invoice: props.row.invoice})}} 
      
  
  
    //b-table-column(field="discount" :label="$t('sale.discount')" numeric v-slot="props" :visible="!isMobile()") 
      currency-view(:value="props.row.discount") 
    b-table-column(field="status" :label="$t('sale.list_status_heading')" v-slot="props") 
      SaleStatusIcon(:status="props.row.status" show-text is-tag)
    b-table-column(field="total" :label="$t('sale.list_total_heading')" numeric v-slot="props") 
      strong(data-qa="total-sale")
        currency-view(:value="props.row.total")
  
    b-table-column(field="created" :label="$t('sale.list_created_heading')" numeric v-slot="props" ) 
      date-view(:value="props.row.created" format="L LT")
    template(slot="empty")
      empty-content(v-if="!loading" title="sale.list_no_recent_activity")
      empty-content(v-else title="sale.loading")
</template>

<script>
import SaleDetails from './SaleDetails'
import SaleItem from './SaleItem'
import SaleStatusIcon from './SaleStatusIcon'
import PaymentStatusIcon from './PaymentStatusIcon'
import dayjs from 'dayjs'
export default {
  name: 'SalesList',
  components: {
    SaleItem,
    SaleStatusIcon,
    PaymentStatusIcon
  },
  props: {
    data: {type: Array, default: () => []},
    loading: {type: Boolean, default: false},
    date: {type: String, default: 'absolute'},
    showCustomerColumn: {type: Boolean, default: false},
    highlightToday: {type: Boolean, default: false}
  },
  methods: {
    showDetails(sale) {
      this.$buefy.modal.open({
        parent: this,
        props: {saleId: sale._id},
        component: SaleDetails,
        fullScreen: true,
        hasModalCard: true,
        canCancel: ['escape', 'outside']
      })
    },
    getTodayHighlightClass(sale) {
      if (!this.highlightToday) {
        return ''
      }
      const today = dayjs(sale.created).format('YYYYMMDD') === dayjs().format('YYYYMMDD')
      if (today) {
        return 'is-highlighted'
      }
    }
  }
}
</script>
