import Navbar from '@/components/Navbar'

const Container = () => import(/* webpackChunkName: "smm" */ '@/components/smm/Container')
const Menu = () => import(/* webpackChunkName: "smm" */ '@/components/smm/Menu')
const Dashboard = () => import(/* webpackChunkName: "smm" */ '@/components/smm/Dashboard')

//import {PRODUCTS} from '../../constants/products'

export default [
  {
    path: '/social',
    name: 'SMM',
    meta: {
      requiresAuth: true,
      title: 'navbar.smm'
    },
    components: {
      default: Container,
      navbar: Navbar,
      menu: Menu
    },
    children: [
      {
        path: 'dashboard',
        component: Dashboard,
        meta: {
          // product: PRODUCTS.SOCIAL_MEDIA,
          title: 'navbar.smm',
          pageTitle: 'smm.dashboard.title',
          requiresAuth: true
        }
      }
    ]
  }
]
