<template lang="pug">
  div(v-if="coupon" style="display: inline-block")
    b-tooltip(size="medium" :label="description")
      p.heading(v-if="detailed") {{description}}
      p(data-qa="coupon-title" :class="detailed ? 'title is-6' : ''") {{coupon.coupon.title}}
      template(v-if="detailed")
        p.subtitle.is-7.has-text-grey(v-if="coupon.coupon.service && coupon.coupon.service.service")
          service-name-view(:value="coupon.coupon.service")
        p.subtitle.is-7.has-text-grey(v-if="coupon.coupon.product")
          product-name-view(:value="coupon.coupon.product")



</template>

<script>
import {mapActions, mapState} from 'vuex'
import CouponMixin from './CouponMixin'
export default {
  name: 'CustomerCouponView',
  mixins: [CouponMixin],
  props: {
    value: {type: [String, Object], required: true},
    detailed: {type: Boolean, default: true}
  },
  data() {
    return {
      coupon: undefined
    }
  },
  computed: {
    ...mapState('company', ['company'])
  },
  async created() {
    if (typeof this.value === 'string') {
      this.coupon = await this.getCustomerCouponById(this.value)
    } else {
      this.coupon = this.value
    }
  },
  methods: {
    ...mapActions('customerCoupon', ['getCustomerCouponById'])
  }
}
</script>
