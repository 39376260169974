class Utils {
  roundDecimal(num) {
    var p = 100
    var n = num * p
    var f = n - Math.floor(n)
    var e = Number.EPSILON * n

    // Determine whether this fraction is a midpoint value.
    return f >= 0.5 - e ? Math.ceil(n) / p : Math.floor(n) / p

    //return Math.round(100 * (num + Number.EPSILON)) / 100
  }
}

module.exports = new Utils()
