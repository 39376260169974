<template lang="pug">
div
  screen-title(title="billing.title")

  SubscriptionStatus
  template(v-if="!company.manualPayment && subscription.status")
    hr
    .box
      h4.title.is-5(v-t="'billing.customer_portal_title'")
      p.subtitle.is-6(v-t="'billing.customer_portal_subtitle'")
      form(method="POST" :action="`/api/v1/billing/portal?auth_token=${encodeURIComponent(token)}`")
        .buttons
          button.button.is-primary(type="submit") 
            span.icon 
              i.mdi.mdi-cog-outline
            span {{ $t('billing.actions.manage_subscription') }}
  //- hr
  //- payment-methods
  //- hr
  //- upcoming-invoice
  
  //- invoices
  //div(v-if="subscription.status")
    hr
    .buttons.is-right(v-if="!subscriptionInactive && !subscription.cancelAtPeriodEnd")
      button.button.is-danger.is-outlined(@click="cancelSubscription(false)") Cancel subscription
      button.button.is-danger.is-outlined(@click="cancelSubscription(true)") Cancel subscription now
</template>

<script>
import Invoices from '../billing/Invoices'
import UpcomingInvoice from '../billing/UpcomingInvoice'
import PaymentMethods from '../billing/PaymentMethods'
import SubscriptionStatus from '../billing/SubscriptionStatus'

import {mapState, mapGetters} from 'vuex'
export default {
  name: 'BillingSettings',
  components: {
    UpcomingInvoice,
    Invoices,
    PaymentMethods,
    SubscriptionStatus
  },
  computed: {
    ...mapState('billing', ['subscription']),
    ...mapState('company', ['company']),
    ...mapState('auth', ['token']),
    ...mapGetters('billing', ['subscriptionInactive'])
  },
  methods: {
    // cancelSubscription(now) {
    //   this.$buefy.dialog.confirm({
    //     title: 'Cancel Subscription',
    //     message: 'Are you sure you want to <b>cancel</b> the subscription?',
    //     confirmText: 'Cancel Subscription',
    //     type: 'is-danger',
    //     onConfirm: async () => {
    //       try {
    //         await this.$store.dispatch('billing/cancelSubscription', now)
    //       } catch (error) {
    //         this.$handleAPIError(error)
    //       }
    //     }
    //   })
    // }
  }
}
</script>

<style scoped>
table.table th {
  background: #f1f1f1;
}
</style>
