import Navbar from '@/components/Navbar'
import RewardsDashboard from '@/components/rewards/Dashboard'
import RewardsProgramSettings from '@/components/rewards/RewardsProgramSettings'

const ReferralProgram = () => import(/* webpackChunkName: "referrals" */ '@/components/referral/Dashboard')
const ReferralProgramSettings = () => import(/* webpackChunkName: "referrals" */ '@/components/referral/Settings')

const RequestsList = () => import(/* webpackChunkName: "reviews" */ '@/components/reviews/RequestsList')
const ReviewsList = () => import(/* webpackChunkName: "reviews" */ '@/components/reviews/ReviewsList')
const PlatformSettings = () => import(/* webpackChunkName: "reviews" */ '@/components/reviews/PlatformSettings')

const Automation = () => import(/* webpackChunkName: "automation" */ '@/components/automation/List')
const AutomationEdit = () => import(/* webpackChunkName: "automation" */ '@/components/automation/AutomationEdit')

const CouponTypesList = () => import(/* webpackChunkName: "coupons" */ '@/components/coupon/CouponTypesList')
const IssuedCoupons = () => import(/* webpackChunkName: "coupons" */ '@/components/coupon/IssuedCoupons')

const Campaigns = () => import(/* webpackChunkName: "campaigns" */ '@/components/marketing/Campaigns')
const CreateEditCampaign = () => import(/* webpackChunkName: "campaigns" */ '@/components/marketing/CreateEditCampaign')

const Promolinks = () => import(/* webpackChunkName: "promolinks" */ '@/components/promolinks/Promolinks')
const CreateEditPromolink = () =>
  import(/* webpackChunkName: "promolinks" */ '@/components/promolinks/CreateEditPromolink')

import Menu from '@/components/marketing/Menu'
import Container from '@/components/marketing/Container'
import {PRODUCTS} from '../../constants/products'

export default [
  {
    path: '/marketing',
    meta: {
      requiresAuth: true,
      title: 'navbar.marketing'
    },
    components: {
      default: Container,
      navbar: Navbar,
      menu: Menu
    },
    children: [
      {
        path: 'rewards-program',
        component: RewardsDashboard,
        meta: {
          product: PRODUCTS.REWARDS_PROGRAM,
          requiresAuth: true,
          title: 'navbar.marketing',
          pageTitle: 'rewards_program.dashboard.title'
        }
      },
      {
        path: 'rewards-program/settings',
        component: RewardsProgramSettings,
        meta: {
          product: PRODUCTS.REWARDS_PROGRAM,
          requiresAuth: true,
          title: 'navbar.marketing',
          pageTitle: 'rewards_program.settings.title'
        }
      },
      {
        path: 'referral-program',
        component: ReferralProgram,
        meta: {
          product: PRODUCTS.REFERRAL_PROGRAM,
          requiresAuth: true,
          title: 'navbar.marketing',
          pageTitle: 'referral_program.title'
        }
      },
      {
        path: 'referral-program/settings',
        component: ReferralProgramSettings,
        meta: {
          product: PRODUCTS.REFERRAL_PROGRAM,
          requiresAuth: true,
          title: 'navbar.marketing',
          pageTitle: 'referral_program.settings.title'
        }
      },
      {
        path: 'reviews',
        component: RequestsList,
        meta: {
          pageTitle: 'reviews.requests.title',
          product: PRODUCTS.REVIEWS,
          requiresAuth: true,
          title: 'navbar.marketing'
        }
      },
      {
        path: 'reviews/verified',
        component: ReviewsList,
        meta: {
          pageTitle: 'reviews.verified_reviews.title',
          product: PRODUCTS.REVIEWS,
          requiresAuth: true,
          title: 'navbar.marketing'
        }
      },
      {
        path: 'reviews/platforms',
        component: PlatformSettings,
        meta: {
          pageTitle: 'reviews.platform_settings.title',
          product: PRODUCTS.REVIEWS,
          requiresAuth: true,
          title: 'navbar.marketing'
        }
      },

      {
        path: 'automation',
        component: Automation,
        meta: {
          pageTitle: 'automation.title',
          product: PRODUCTS.AUTOMATIONS,
          requiresAuth: true,
          title: 'navbar.marketing'
        }
      },
      {
        path: 'coupons',
        component: IssuedCoupons,
        meta: {
          product: PRODUCTS.COUPONS,
          requiresAuth: true,
          title: 'navbar.marketing',
          pageTitle: 'coupons.customer_coupons'
        }
      },
      {
        path: 'coupons/types',
        component: CouponTypesList,
        meta: {
          product: PRODUCTS.COUPONS,
          requiresAuth: true,
          title: 'navbar.marketing',
          pageTitle: 'coupons.coupon_types'
        }
      },
      {
        path: 'campaigns',
        component: Campaigns,
        meta: {
          pageTitle: 'campaign.list_title',
          product: PRODUCTS.CAMPAIGNS,
          requiresAuth: true,
          title: 'navbar.marketing'
        }
      },
      {
        path: 'promolinks',
        component: Promolinks,
        meta: {
          pageTitle: 'promolink.list_title',
          product: PRODUCTS.PROMOLINKS,
          requiresAuth: true,
          title: 'navbar.marketing'
        }
      }
    ]
  },
  {
    path: '/marketing/automations/:id',
    name: 'automationEdit',
    props: {default: true, navbar: false},
    components: {
      default: AutomationEdit,
      navbar: Navbar
    },
    meta: {
      requiresAuth: true,
      title: 'Edit Automation',
      product: PRODUCTS.AUTOMATIONS
    }
  },
  {
    path: '/marketing/campaigns/create/:channel',
    name: 'createCampaign',
    props: {default: true, navbar: false},
    components: {
      default: CreateEditCampaign,
      navbar: Navbar
    },
    meta: {
      requiresAuth: true,
      title: 'campaign.create_title',
      product: PRODUCTS.CAMPAIGNS
    }
  },
  {
    path: '/marketing/campaigns/edit/:id',
    name: 'campaignEdit',
    props: {default: true, navbar: false},
    components: {
      default: CreateEditCampaign,
      navbar: Navbar
    },
    meta: {
      requiresAuth: true,
      title: 'campaign.edit_title',
      product: PRODUCTS.CAMPAIGNS
    }
  },
  {
    path: '/promolinks/create',
    name: 'createPromolink',
    props: {default: true, navbar: false},
    components: {
      default: CreateEditPromolink,
      navbar: Navbar
    },
    meta: {
      requiresAuth: true,
      title: 'promolink.create_title',
      product: PRODUCTS.PROMOLINKS
    }
  },
  {
    path: '/promolinks/edit/:id',
    name: 'editPromolink',
    props: {default: true, navbar: false},
    components: {
      default: CreateEditPromolink,
      navbar: Navbar
    },
    meta: {
      requiresAuth: true,
      title: 'promolink.edit_title',
      product: PRODUCTS.PROMOLINKS
    }
  }
]
