module.exports = {
  COLLECT: 'collect',
  REDEEM: 'redeem',
  GIFT: 'gift',
  PROMO_SIGNUP: 'promo',
  REFERENT_SIGNUP: 'signup',
  REFERRER_REWARD: 'referral',
  AFFILIATE_REWARD: 'affiliate',
  COUPON_REDEEM: 'coupon-redeem',
  ADJUST: 'adjust'
}
