const PERMISSIONS = require('./permissions')

module.exports = {
  low: [
    PERMISSIONS.core.CLIENTS_ADD,
    PERMISSIONS.core.CLIENTS_MERGE,
    PERMISSIONS.core.CLIENTS_VIEW,
    PERMISSIONS.core.CLIENTS_EDIT,

    PERMISSIONS.core.SALE_VIEW,
    PERMISSIONS.core.SALE_CREATE,
    PERMISSIONS.core.SALE_INVOICE_REMOVE,
    PERMISSIONS.core.SALE_INVOICE_REFUND,

    PERMISSIONS.core.VOUCHERS_VIEW,
    PERMISSIONS.core.MEMBERSHIPS_VIEW,

    PERMISSIONS.core.APPOINTMENT_VIEW,
    PERMISSIONS.core.APPOINTMENT_CREATE,
    PERMISSIONS.core.APPOINTMENT_EDIT_NOTES,

    PERMISSIONS.core.PRIVACY_VIEW_PHONE_NUMBERS,

    PERMISSIONS.targets.VIEW
  ],
  med: [
    PERMISSIONS.core.CLIENTS_ADD,
    PERMISSIONS.core.CLIENTS_MERGE,
    PERMISSIONS.core.CLIENTS_VIEW,
    PERMISSIONS.core.CLIENTS_EDIT,
    PERMISSIONS.core.CLIENT_COMPOSE_SMS,

    PERMISSIONS.core.SALE_VIEW,
    PERMISSIONS.core.SALE_CREATE,
    PERMISSIONS.core.SALE_INVOICE_REMOVE,
    PERMISSIONS.core.SALE_INVOICE_REFUND,

    PERMISSIONS.core.VOUCHERS_VIEW,
    PERMISSIONS.core.MEMBERSHIPS_VIEW,

    PERMISSIONS.core.APPOINTMENT_VIEW,
    PERMISSIONS.core.APPOINTMENT_CREATE,
    PERMISSIONS.core.APPOINTMENT_EDIT_NOTES,

    PERMISSIONS.core.PRIVACY_VIEW_PHONE_NUMBERS,

    PERMISSIONS.core.REMINDERS_VIEW,

    PERMISSIONS.core.SERVICES_ADD,
    PERMISSIONS.core.SERVICES_EDIT,
    PERMISSIONS.core.SERVICES_VIEW,

    PERMISSIONS.core.STAFF_ADD,
    PERMISSIONS.core.STAFF_EDIT,
    PERMISSIONS.core.STAFF_VIEW,
    PERMISSIONS.core.STAFF_PERMISSIONS_VIEW,
    PERMISSIONS.core.STAFF_SHIFTS_VIEW,
    PERMISSIONS.core.STAFF_SHIFTS_EDIT,

    PERMISSIONS.core.RESOURCES_VIEW,
    PERMISSIONS.core.RESOURCES_MANAGE,
    PERMISSIONS.core.RESOURCES_SCHEDULE_VIEW,
    PERMISSIONS.core.RESOURCES_SCHEDULE_EDIT,

    PERMISSIONS.core.SETTINGS_LOCATIONS_VIEW,
    PERMISSIONS.core.ONLINE_BOOKING_SETTINGS_VIEW,

    PERMISSIONS.core.SETTINGS_LANGUAGES_VIEW,
    PERMISSIONS.core.SETTINGS_PLATFORMS_VIEW,
    PERMISSIONS.core.COMPANY_SETTINGS_VIEW,

    PERMISSIONS.core.DASHBOARD_ANALYTICS,
    PERMISSIONS.core.CLIENT_SALES_DATA,

    PERMISSIONS.targets.ANALYTICS,
    PERMISSIONS.targets.VIEW,

    PERMISSIONS.inventory.INVENTORY_VIEW,

    PERMISSIONS.followups.FOLLOWUPS_VIEW,
    PERMISSIONS.followups.FOLLOWUPS_ADD,
    PERMISSIONS.followups.FOLLOWUPS_EDIT,

    PERMISSIONS.automations.AUTOMATIONS_VIEW,

    PERMISSIONS.campaigns.CAMPAIGNS_VIEW,

    PERMISSIONS.promolinks.VIEW,

    PERMISSIONS.rewards.VIEW,
    PERMISSIONS.rewards.MEMBERSHIP_UPGRADE,

    PERMISSIONS.coupons.VIEW,
    PERMISSIONS.surveys.VIEW,
    PERMISSIONS.surveys.VIEW_TICKETS,
    PERMISSIONS.surveys.MANAGE_TICKETS,
    PERMISSIONS.referrals.VIEW,
    PERMISSIONS.chatbot.VIEW,
    PERMISSIONS.reviews.VIEW
  ],
  high: [
    PERMISSIONS.core.CLIENTS_ADD,
    PERMISSIONS.core.CLIENTS_VIEW,
    PERMISSIONS.core.CLIENTS_EDIT,
    PERMISSIONS.core.CLIENTS_MERGE,
    PERMISSIONS.core.CLIENTS_REMOVE,
    PERMISSIONS.core.CLIENT_COMPOSE_SMS,

    PERMISSIONS.core.SALE_VIEW,
    PERMISSIONS.core.SALE_CREATE,
    PERMISSIONS.core.SALE_INVOICE_REMOVE,
    PERMISSIONS.core.SALE_INVOICE_REFUND,

    PERMISSIONS.core.VOUCHERS_VIEW,
    PERMISSIONS.core.VOUCHERS_MANAGE,
    PERMISSIONS.core.VOUCHERS_EXTEND,
    PERMISSIONS.core.MEMBERSHIPS_VIEW,
    PERMISSIONS.core.MEMBERSHIP_PACKAGES_MANAGE,

    PERMISSIONS.core.APPOINTMENT_VIEW,
    PERMISSIONS.core.APPOINTMENT_CREATE,
    PERMISSIONS.core.APPOINTMENT_EDIT_NOTES,
    PERMISSIONS.core.APPOINTMENT_EXPORT,

    PERMISSIONS.core.PRIVACY_VIEW_PHONE_NUMBERS,

    PERMISSIONS.core.REMINDERS_VIEW,
    PERMISSIONS.core.REMINDERS_MANAGE,

    PERMISSIONS.core.SERVICES_ADD,
    PERMISSIONS.core.SERVICES_EDIT,
    PERMISSIONS.core.SERVICES_REMOVE,
    PERMISSIONS.core.SERVICES_VIEW,
    PERMISSIONS.core.SERVICES_EXPORT,

    PERMISSIONS.core.STAFF_ADD,
    PERMISSIONS.core.STAFF_EDIT,
    PERMISSIONS.core.STAFF_REMOVE,
    PERMISSIONS.core.STAFF_VIEW,
    PERMISSIONS.core.STAFF_PERMISSIONS_VIEW,
    PERMISSIONS.core.STAFF_PERFORMANCE_VIEW,
    PERMISSIONS.core.STAFF_SHIFTS_VIEW,
    PERMISSIONS.core.STAFF_SHIFTS_EDIT,

    PERMISSIONS.core.RESOURCES_VIEW,
    PERMISSIONS.core.RESOURCES_MANAGE,
    PERMISSIONS.core.RESOURCES_SCHEDULE_VIEW,
    PERMISSIONS.core.RESOURCES_SCHEDULE_EDIT,

    PERMISSIONS.core.CASH_REGISTER_MANAGE,
    PERMISSIONS.core.CASH_REGISTER_VIEW,

    PERMISSIONS.core.COMPANY_SETTINGS_EDIT,
    PERMISSIONS.core.COMPANY_SETTINGS_VIEW,

    PERMISSIONS.core.ANALYTICS,
    PERMISSIONS.core.BILLING_VIEW,

    PERMISSIONS.core.DASHBOARD_ANALYTICS,
    PERMISSIONS.core.CLIENT_SALES_DATA,

    PERMISSIONS.core.ONLINE_BOOKING_SETTINGS_VIEW,
    PERMISSIONS.core.ONLINE_BOOKING_SETTINGS_MANAGE,
    PERMISSIONS.core.SETTINGS_LOCATIONS_VIEW,
    PERMISSIONS.core.SETTINGS_LOCATIONS_MANAGE,

    PERMISSIONS.core.SETTINGS_LANGUAGES_VIEW,
    PERMISSIONS.core.SETTINGS_LANGUAGES_MANAGE,
    PERMISSIONS.core.SETTINGS_PLATFORMS_VIEW,
    PERMISSIONS.core.SETTINGS_PLATFORMS_MANAGE,

    PERMISSIONS.inventory.INVENTORY_VIEW,
    PERMISSIONS.inventory.INVENTORY_MANAGE,

    PERMISSIONS.targets.ANALYTICS,
    PERMISSIONS.targets.VIEW,

    PERMISSIONS.inventory.INVENTORY_MANAGE,

    PERMISSIONS.automations.AUTOMATIONS_VIEW,
    PERMISSIONS.automations.AUTOMATIONS_ADD,
    PERMISSIONS.automations.AUTOMATIONS_REMOVE,
    PERMISSIONS.automations.AUTOMATIONS_EDIT,

    PERMISSIONS.followups.FOLLOWUPS_VIEW,
    PERMISSIONS.followups.FOLLOWUPS_ADD,
    PERMISSIONS.followups.FOLLOWUPS_REMOVE,
    PERMISSIONS.followups.FOLLOWUPS_EDIT,

    PERMISSIONS.campaigns.CAMPAIGNS_VIEW,
    PERMISSIONS.campaigns.CAMPAIGNS_ADD,
    PERMISSIONS.campaigns.CAMPAIGNS_REMOVE,
    PERMISSIONS.campaigns.CAMPAIGNS_PUBLISH,
    PERMISSIONS.campaigns.CAMPAIGNS_EDIT,

    PERMISSIONS.promolinks.VIEW,
    PERMISSIONS.promolinks.ADD,
    PERMISSIONS.promolinks.REMOVE,
    PERMISSIONS.promolinks.EDIT,

    PERMISSIONS.rewards.VIEW,
    PERMISSIONS.rewards.MEMBERSHIP_UPGRADE,
    PERMISSIONS.rewards.MANAGE_SETTINGS,
    PERMISSIONS.rewards.GIFT_CREATE,
    PERMISSIONS.rewards.REWARDS_REMOVE,
    PERMISSIONS.rewards.ANALYTICS,

    PERMISSIONS.coupons.VIEW,
    PERMISSIONS.coupons.ANALYTICS,
    PERMISSIONS.coupons.ADD,
    PERMISSIONS.coupons.EDIT,
    PERMISSIONS.coupons.REMOVE,

    PERMISSIONS.surveys.VIEW,
    PERMISSIONS.surveys.MANAGE_SETTINGS,
    PERMISSIONS.surveys.ANALYTICS,

    PERMISSIONS.surveys.VIEW_TICKETS,
    PERMISSIONS.surveys.MANAGE_TICKETS,

    PERMISSIONS.referrals.VIEW,
    PERMISSIONS.referrals.MANAGE_SETTINGS,
    PERMISSIONS.referrals.ANALYTICS,

    PERMISSIONS.chatbot.VIEW,
    PERMISSIONS.chatbot.MANAGE_SETTINGS,
    PERMISSIONS.chatbot.ANALYTICS,

    PERMISSIONS.reviews.VIEW,
    PERMISSIONS.reviews.ANALYTICS,
    PERMISSIONS.reviews.VERIFY,
    PERMISSIONS.reviews.MANAGE_SETTINGS
  ]
}
