<template lang="pug">
.modal-card
  header.modal-card-head
    p.modal-card-title(data-qa="modal-header") 
      b(v-t="'sms.template_edit_title'")
    div
      a.button.is-white.is-small(@click="$emit('close')")
        span.icon
          i.mdi.mdi-close.mdi-24px
  section.modal-card-body

    div(v-if="content")
      .tabs.is-toggle.is-small.mb-2(v-if="company.languages.length > 1")
        ul
          li(v-for="(lng, idx) in company.languages" :key="lng" :class="[!idx ? 'ml-0': '', active===lng ? 'is-active': '']")
            a(@click="active = lng" style="font-size: 0.8em") {{ $t(`languages.${lng}`) }}
      div(v-for="lng in company.languages" :key="lng" v-show="active === lng")
        SMSComposer(v-model="content[lng]" @input="onInput" transactional no-help :dynamicFields="dynamicFields" :showLabel="false" )
        
        hr
        .buttons
          button.button(@click="reset(lng)" v-t="'actions.reset_to_defaults'") 
  footer.modal-card-foot
    .buttons
      action-button(@click="save" title="actions.save" :loading="submitting")
      button.button(@click="$emit('close')" v-t="'actions.cancel'")  
</template>
<script>
import {mapState} from 'vuex'
import SMSApi from '@/api/sms'
import pick from 'lodash/pick'

import SMSComposer from '../sms/SMSComposer'
export default {
  name: 'SMSTemplateEditor',
  components: {
    SMSComposer
  },
  props: {
    templateKey: {type: String, required: true}
  },
  data() {
    return {
      content: {},
      loading: false,
      active: undefined,
      submitting: false,
      dynamicFields: []
    }
  },
  computed: {
    ...mapState('company', ['company'])
  },
  async created() {
    this.loading = true
    try {
      let templates = await SMSApi.getTemplates({key: this.templateKey, lngs: this.company.languages})
      for (let lng of this.company.languages) {
        this.$set(this.content, `${lng}_default`, templates[`${lng}_default`].replace(/\{\{-\s*/g, '{{'))
        this.$set(this.content, lng, templates[lng] || this.content[`${lng}_default`])
      }

      this.dynamicFields = templates[`${this.company.defaultLanguage}_default`]
        .replace(/\{\{-\s*/g, '{{')
        .match(/\{\{.*?\}\}/g)
      this.active = this.company.defaultLanguage
    } catch (err) {
      this.$handleAPIError(err)
    }
    this.loading = false
  },
  methods: {
    async save() {
      if (this.submitting) {
        return
      }
      this.submitting = true
      try {
        let templates = pick(this.content, this.company.languages)
        await SMSApi.updateTemplate({key: this.templateKey, templates})
        this.$emit('input', templates)
        this.$emit('close')
      } catch (err) {
        this.$handleAPIError(err)
      }
      this.submitting = false
    },
    reset(lng) {
      //this.$set(this.content, lng, this.content[`${lng}_default`])
      this.content[lng] = this.content[`${lng}_default`]
      //console.log(this.content[lng])
    }
  }
}
</script>
