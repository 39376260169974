<template lang="pug">
  article.msg.msg-left.image-card(:class="!valid ? 'is-invalid' : ''")
    img.is-clickable(:src="artwork" @click="!readonly && changeCoupons()")
    .card-text-body 
      p.title.is-6 {{title}}
      p.subtitle.is-6(v-if="validity") {{validity}}
      p.subtitle.is-6(v-else) 
        coupon-terms(:coupon="coupon" v-if="coupon")
    Buttons(v-model="message.buttons" :editable="advancedMode" :title-editable="true" @input="onInput")
</template>

<script>
import {mapActions} from 'vuex'
import Buttons from './Buttons'
import BaseElement from './BaseElement'
import SelectCouponModal from '../../coupon/SelectCouponModal'
import CouponTerms from '../../coupon/CouponTerms'
import CouponMixin from '../../coupon/CouponMixin'

export default {
  components: {
    Buttons,
    CouponTerms
  },
  extends: BaseElement,
  mixins: [CouponMixin],
  data() {
    return {
      coupon: undefined
    }
  },
  computed: {
    valid() {
      return !!(this.message.ids && this.message.ids.length)
    }
  },
  created() {
    this.fetchArtwork()
  },
  methods: {
    ...mapActions('coupon', ['getById']),
    onInput() {
      this.message.valid = this.valid
      this.$emit('input', this.message)
    },
    async fetchArtwork() {
      try {
        if (this.message.ids && this.message.ids.length) {
          this.coupon = await this.getById(this.message.ids[0])
        }
        this.onInput()
      } catch (err) {
        this.$handleAPIError(err)
      }
    },
    changeCoupons() {
      this.$buefy.modal.open({
        parent: this,
        component: SelectCouponModal,
        width: 960,
        canCancel: ['outside', 'escape'],
        events: {
          success: coupon => {
            this.message.ids = [coupon._id]
            this.fetchArtwork()
          }
        }
      })
    }
  }
}
</script>
