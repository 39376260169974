import Vue from 'vue'
import {SET_SALES, SET_SALE, SET_CUSTOMER_SALES, SET_CHECKING_OUT} from './mutationTypes'
import SalesApi from '@/api/sales'
import SurveysApi from '@/api/surveys'
import SALE_STATUS from '../../constants/saleStatus'

export default {
  namespaced: true,
  state: {
    salesMap: {},
    salesOrder: [],
    customerSalesOrder: [],
    currentCustomerId: undefined,
    filter: {},
    total: 0,
    checkingOut: false
  },
  getters: {
    customerSales(state) {
      return state.customerSalesOrder.map(c => state.salesMap[c])
    },
    sales(state) {
      return state.salesOrder.map(c => state.salesMap[c])
    }
  },
  mutations: {
    [SET_CUSTOMER_SALES](state, {sales, customerId}) {
      for (let sale of sales) {
        Vue.set(state.salesMap, sale._id, sale)
      }
      state.customerSalesOrder = sales.map(c => c._id)
      state.currentCustomerId = customerId
    },
    [SET_SALES](state, {sales, total, filter}) {
      for (let sale of sales) {
        Vue.set(state.salesMap, sale._id, sale)
      }
      state.salesOrder = sales.map(c => c._id)
      state.total = total
      state.filter = filter
    },

    [SET_SALE](state, sale) {
      let current = state.salesMap[sale._id] || {}
      for (let prop in sale) {
        if (current[prop] === undefined) {
          Vue.set(current, prop, sale[prop])
        } else {
          current[prop] = sale[prop]
        }
      }
      state.salesMap[sale._id] = current
    },
    [SET_CHECKING_OUT](state, value) {
      state.checkingOut = value
    }
  },
  actions: {
    async fetchCustomerSales({commit, dispatch}, customerId) {
      commit(SET_CUSTOMER_SALES, {sales: [], customerId})
      let {sales} = await dispatch('_fetchSales', {customer: customerId})
      commit(SET_CUSTOMER_SALES, {sales, customerId})
      return sales
    },
    async fetchSales({commit, state, dispatch}, filter) {
      filter = filter || {...state.filter}
      let {sales, total} = await dispatch('_fetchSales', filter)
      commit(SET_SALES, {sales, total, filter})
      return sales
    },

    async _fetchSales(_context, params) {
      let {sales, total} = await SalesApi.list(params)
      if (!sales.length) {
        return {sales, total}
      }
      let saleIds = sales
        .filter(s => s.customer && (s.status === SALE_STATUS.COMPLETED || s.status === SALE_STATUS.REFUNDED))
        .map(s => s._id)

      if (saleIds.length) {
        let surveys = await SurveysApi.getSurveys({
          customerId: params.customer,
          saleIds,
          type: 'feedback'
        })
        for (let sale of sales) {
          sale.surveys = surveys.filter(s => s.sale === sale._id)
        }
      }

      return {sales, total}
    },

    async fetchSaleById({state, commit}, {id, force}) {
      if (!state.salesMap[id] || force) {
        let sale = await SalesApi.getById(id)
        let surveys
        if ((sale.status === SALE_STATUS.COMPLETED || sale.status === SALE_STATUS.REFUNDED) && sale.customer) {
          surveys = await SurveysApi.getSurveys({customerId: sale.customer, saleIds: [id]})
        } else {
          surveys = []
        }
        sale.surveys = surveys
        commit(SET_SALE, sale)
      }
      return state.salesMap[id]
    },
    async voidSale({dispatch, commit}, {saleId, customerId}) {
      let sale = await SalesApi.remove(saleId)
      commit(SET_SALE, sale)
      dispatch('refreshSaleLists', customerId)
    },
    async refundSale({commit, state}, saleId) {
      let sale = await SalesApi.refundSale(saleId)
      commit(SET_SALE, sale)
      return state.salesMap[sale._id]
    },
    async checkout({state, commit}, data) {
      commit(SET_CHECKING_OUT, true)
      try {
        let sale = await SalesApi.checkout(data)
        commit(SET_SALE, sale)
        commit(SET_CHECKING_OUT, false)
        return state.salesMap[sale._id]
      } catch (e) {
        commit(SET_CHECKING_OUT, false)
        throw e
      }
    },
    async depositDeposit({state, commit}, paymentId) {
      let sale = await SalesApi.depositDeposit(paymentId)
      commit(SET_SALE, sale)
      return state.salesMap[sale._id]
    },
    onSaleCreated({dispatch, commit}, data) {
      commit(SET_SALE, data)
      dispatch('refreshSaleLists', data.customer)
    },
    onSaleUpdated({dispatch, commit}, data) {
      commit(SET_SALE, data)
      dispatch('refreshSaleLists', data.customer)
    },
    async refreshSaleLists({dispatch, state}, customerId) {
      if (customerId && state.currentCustomerId === customerId) {
        await dispatch('fetchCustomerSales', customerId)
      }

      if (Object.keys(state.filter).length) {
        await dispatch('fetchSales')
      }
      // TODO check if there is a need to refresh sales list
    }
  }
}
