<template lang="pug">
span(:class="[isTag ? 'tag' : '', colorClass, tagSize]" :title="$t(`sale_status.${status}`)")
  span.icon
    i.mdi(:class="[!isTag ? 'has-text-' + colorClass : '', 'mdi-' + icon]")
  | &nbsp;
  span(v-if="showText" v-t="`sale_status.${status}`") 
</template>

<script>
import SALE_STATUS_VISUALS from './saleStatusVisuals'

export default {
  props: {
    status: {type: String, required: true},
    showText: {type: Boolean, default: false},
    isTag: {type: Boolean, default: false},
    tagSize: {type: String, default: undefined},
    nocolor: {type: Boolean, default: false}
  },
  computed: {
    colorClass() {
      if (this.nocolor) {
        return
      }

      return (
        (this.isTag ? 'is-' : 'has-text-') +
        (SALE_STATUS_VISUALS[this.status] && SALE_STATUS_VISUALS[this.status].color)
      )
    },
    icon() {
      return SALE_STATUS_VISUALS[this.status] && SALE_STATUS_VISUALS[this.status].icon
    }
  }
}
</script>
