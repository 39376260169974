<template lang="pug">
  b-autocomplete.service-autocomplete(v-if="autocomplete && (!isMobile() || services.length > 25)" v-model="query" :data="filteredServices" :open-on-focus="true" :placeholder="$t(placeholder)" icon="tag-outline" @keyup.native="search" :custom-formatter="displayFormatter" @select="select" ref="searchInput" :size="size" :expanded="expanded")
    template(slot-scope="props")
      .service-search-item.py-2
        .service-search-left
          h2.title.is-6.is-size-6-mobile {{localizeName(props.option)}}
          p.subtitle.is-6.has-text-grey
            span(v-if="props.option.pricingOptions.name") {{localizeName(props.option.pricingOptions)}} 
            duration-view(:value="props.option.pricingOptions.duration" v-if="props.option.pricingOptions.duration && $rfSettings().services.duration")
        .service-search-price
          strong.is-size-6.is-size-6-mobile
            currency-view(:value="props.option.pricingOptions.minPrice || props.option.pricingOptions.price")
            template(v-if="props.option.pricingOptions.priceUpto || props.option.pricingOptions.maxPrice")
              |  -  
              currency-view(:value="props.option.pricingOptions.maxPrice || props.option.pricingOptions.priceUpto")
    template(slot="empty")
      empty-content(title="services.selector.no_results" size="is-small" icon="magnify-remove-outline")
      //p.has-text-centered(v-t="'services.selector.no_results'")
  b-select(v-else v-model="service" @input="select" :placeholder="$t(placeholder)" icon="tag-outline" :size="size" :expanded="expanded")
    option(v-for="serviceOption in services" :key="serviceOption.pricingOptions._id" :value="serviceOption") 
      span {{localizeName(serviceOption)}} 
      span(v-if="serviceOption.pricingOptions.name")  /  {{localizeName(serviceOption.pricingOptions)}}
      template(v-if="serviceOption.pricingOptions.duration && $rfSettings().services.duration")
        |  (
        duration-view(:value="serviceOption.pricingOptions.duration")
        | )  
      |   -   
      currency-view(:value="serviceOption.pricingOptions.minPrice || serviceOption.pricingOptions.price")
      template(v-if="serviceOption.pricingOptions.priceUpto || serviceOption.pricingOptions.maxPrice")
        |  -  
        currency-view(:value="serviceOption.pricingOptions.maxPrice || serviceOption.pricingOptions.priceUpto")
</template>
<script>
import {mapActions, mapState} from 'vuex'
import saleHelper from '../../../shared/saleHelper'
import durationMixin from '@/mixins/duration'

export default {
  name: 'ServiceAutocomplete',
  mixins: [durationMixin],
  props: {
    value: {type: Object, default: undefined},
    placeholder: {type: String, default: 'services.selector.placeholder'},
    size: {type: String, default: undefined},
    expanded: {type: String, default: undefined},
    clearAfterSelect: {type: Boolean, default: false},
    autocomplete: {type: Boolean, default: true}
    // locationId: {type: String, default: undefined}
  },
  data() {
    return {
      filteredServices: [],
      query: '',
      service: undefined
    }
  },
  computed: {
    ...mapState('services', ['unwindedServices']),
    ...mapState('company', ['company']),
    ...mapState('auth', ['user']),
    services() {
      return this.unwindedServices.filter(
        s =>
          // (!this.locationId || s.locations.includes(this.locationId)) &&
          !this.$rfProfileScope() || s.staff.includes(this.user._id)
      )
    }
  },
  watch: {
    value(newVal) {
      if (
        newVal &&
        this.service &&
        newVal.serviceId === this.service._id &&
        newVal.optionId === this.service.pricingOptions._id
      ) {
        return
      }
      if (newVal) {
        this.service = this.services.find(s => s._id === newVal.serviceId && s.pricingOptions._id === newVal.optionId)
      } else {
        this.service = undefined
      }
      if (this.$refs.searchInput) {
        this.$refs.searchInput.setSelected(this.service)
      }
    }
  },
  async created() {
    await this.list()
    this.filteredServices = this.services
    if (this.value) {
      this.service = this.services.find(
        s => s._id === this.value.serviceId && s.pricingOptions._id === this.value.optionId
      )
      if (this.$refs.searchInput) {
        this.$refs.searchInput.setSelected(this.service)
      }
    }
  },
  methods: {
    ...mapActions('services', ['searchService', 'list']),
    async search() {
      if (!this.query || !this.query.length) {
        this.filteredServices = this.services
        return
      }
      this.filteredServices = await this.searchService({
        query: this.query,
        // locationId: this.locationId,
        staffId: this.$rfProfileScope() ? this.user._id : undefined
      })
    },
    select(option) {
      if (!option) {
        return
      }
      this.service = option
      this.$emit('input', {
        serviceId: this.service._id,
        optionId: this.service.pricingOptions._id,
        price: this.service.pricingOptions.price,
        priceUpto: this.service.pricingOptions.priceUpto,
        duration: this.service.pricingOptions.duration,
        override: this.service.pricingOptions.override
      })

      if (this.clearAfterSelect) {
        this.$refs.searchInput.setSelected(undefined)
        this.query = undefined
      }
    },
    localizeName(option) {
      if (!this.user.lng) {
        return option.name
      }
      return (option.names && option.names[this.user.lng]) || option.name
    },

    displayFormatter(service) {
      let optionName = service.pricingOptions.name ? ' / ' + this.localizeName(service.pricingOptions) : ''
      let {duration, price, priceUpto} = saleHelper.getPricingOption(service.pricingOptions)

      let formattedDuration = this.$rfSettings().services.duration ? ` (${this.formatDuration(duration)})` : ''

      let formattedPrice = saleHelper.formatCurrency(price, this.company.currency)
      let formattedPriceUpto =
        priceUpto !== undefined ? saleHelper.formatCurrency(priceUpto, this.company.currency) : undefined
      return `${this.localizeName(service)}${optionName}${formattedDuration} ${formattedPrice}${
        formattedPriceUpto ? ' - ' + formattedPriceUpto : ''
      }`
    },
    focus() {
      this.$refs.searchInput.focus()
    }
  }
}
</script>
<style>
.service-search-item {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.service-search-left {
  flex: 1 1 0px;
  overflow: hidden;
}
.service-search-left * {
  overflow: hidden;
  text-overflow: ellipsis;
}
.service-search-price {
  padding-left: 1rem;
  flex: 0 0 0px;
  word-break: keep-all;
  text-align: right;
}
.service-autocomplete .dropdown-item {
  padding-right: 1rem !important;
}
</style>
