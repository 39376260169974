<template lang="pug">
  div
      screen-title(title="business_settings.title")
      .box
        //h2.title.is-4(v-t="'business_settings.general_heading'")
        
        b-field(:label="$t('business_settings.business_name_label')", :type="errors.has('name') ? 'is-danger': ''" :message="errors.has('name') ? errors.first('name') : ''")
          b-input(v-model="name" icon="store" v-validate="{required: true, regex:/^[\x00-\x7F]*$/ }" name="name" :placeholder="$t('business_settings.business_name_placeholder')" data-vv-as="business name" expanded)
        b-field(:label="$t('business_settings.phone_label')", :type="errors.has('phone') || phoneValid === false ? 'is-danger': ''" :message="errors.has('phone') ? errors.first('phone') : (phoneValid === false ? 'The phone number is invalid': '')")
          phone-input(v-model="phone" @input="(phone, valid) => phone ? phoneValid = valid : phoneValid = undefined" v-validate="'required'" name="phone" :placeholder="$t('business_settings.phone_placeholder')")
        
        b-field(:label="$t('business_settings.email_label')", :type="errors.has('email') ? 'is-danger': ''" :message="errors.has('email') ? errors.first('email') : ''")
          b-input(v-model="email" icon="email-outline" v-validate="'email|required'" name="email" :placeholder="$t('business_settings.email_placeholder')" data-vv-as="email" expanded)

        
        b-field(:label="$t('locations_settings.details.timezone_label')" expanded :type="errors.has('timezone') ? 'is-danger': ''" :message="errors.has('timezone') ? errors.first('timezone') : ''" )
          TimezoneSelect(v-model="timezone" data-vv-as="timezone" name="timezone" v-validate="'required'" expanded)
        
        b-field(grouped)
          b-field(:label="$t('business_settings.currency_label')" expanded :type="errors.has('currency') ? 'is-danger': ''" :message="errors.has('currency') ? errors.first('currency') : ''" )
            CurrencySelect(v-model="currency" :placeholder="$t('business_settings.currency_placeholder')" name="currency" v-validate="'required'" data-vv-as="currency" expanded)
          b-field(:label="$t('locations_settings.details.country_label')" expanded)
            CountrySelect(v-model="country" name="country" disabled expanded)
          
        
        b-field(:label="$t('business_settings.fiscal_year_start_label')")
          b-select(v-model="fiscalYearStart" expanded)
            option(v-for="(month, idx) in months" :value="idx" :key="idx") {{month}}
        b-field(grouped)
          b-field(:label="$t('business_settings.time_format_label')" expanded)
            b-select(v-model="timeFormat" expanded)
              option(value="12" v-t="'business_settings.time_format_12'")
              option(value="24" v-t="'business_settings.time_format_24'")
          b-field(:label="$t('business_settings.week_start_label')" expanded)
            b-select(v-model.number="weekStart" expanded)
              option(value="0" v-t="'working_hours.weekdays.sun'")
              option(value="1" v-t="'working_hours.weekdays.mon'") 
              
        .field
          label.label {{$t('business_settings.logo_label')}}
          .is-artwork
            image-preview(v-model="logo" category="assets")
        
       
      .box
        h2.title.is-4(v-t="'business_settings.links_heading'") 
        b-field(:label="$t('business_settings.menu_url_label')", :type="errors.has('menuUrl') ? 'is-danger': ''" :message="errors.has('menuUrl') ? errors.first('menuUrl') : ''")
          b-input(v-model="menuUrl" icon="web" v-validate="{url: {require_protocol: true }}" name="menuUrl" :placeholder="$t('business_settings.menu_url_placeholder')" data-vv-as="menu URL" expanded)
        b-field(:label="$t('business_settings.facebook_label')")
          b-input(v-model="facebookPage" icon="facebook" readonly expanded)
        b-field(:label="$t('business_settings.instagram_label')", :type="errors.has('instagramHandle') ? 'is-danger': ''" :message="errors.has('instagramHandle') ? errors.first('instagramHandle') : ''")
          b-input(v-model="instagramHandle" icon="instagram" name="instagramHandle" :placeholder="$t('business_settings.instagram_placeholder')" data-vv-as="Instagram handle" expanded)
        b-field(:label="$t('business_settings.website_label')", :type="errors.has('website') ? 'is-danger': ''" :message="errors.has('website') ? errors.first('website') : ''")
          b-input(v-model="website" icon="web" v-validate="{url: {require_protocol: true }}" name="website" :placeholder="$t('business_settings.website_placeholder')" data-vv-as="website URL" expanded)
        
      .box
        h2.title.is-4(v-t="'business_settings.other_heading'")
        b-field(:label="$t('business_settings.categories_label')")
          CategoriesSelector(v-model="categories" :placeholder="$t('business_settings.categories_placeholder')")
        label.label(v-t="'business_settings.about_label'")
        LocalizedEditor(v-model="aboutMap" type="textarea" :placeholder="$t('business_settings.about_placeholder')")
        
      .field
        .control
          action-button(title="actions.save" @click="submit" :loading="submitting" permission="COMPANY_SETTINGS_EDIT" scope)
</template>

<script>
import {mapState} from 'vuex'

import CategoriesSelector from './CategoriesSelector'
import CurrencySelect from '../shared/CurrencySelect'
import TimezoneSelect from '../shared/TimezoneSelect'
import CountrySelect from '../shared/CountrySelect'
import LocalizedEditor from '../shared/LocalizedEditor'

import dayjs from 'dayjs'
export default {
  name: 'BusinessSettings',
  components: {
    CategoriesSelector,
    CurrencySelect,
    TimezoneSelect,
    CountrySelect,
    LocalizedEditor
  },
  data() {
    return {
      submitting: false,
      name: undefined,
      currency: undefined,
      timezone: undefined,
      country: undefined,
      weekStart: 1,
      timeFormat: '12',
      fiscalYearStart: 0,
      phone: undefined,
      phoneValid: undefined,
      menuUrl: undefined,
      categories: undefined,
      facebookPage: undefined,
      instagramHandle: undefined,
      website: undefined,
      email: undefined,
      logo: null,
      aboutMap: {},
      months: dayjs.months()
    }
  },
  computed: {
    ...mapState('company', ['company'])
  },
  created() {
    this.name = this.company.name
    this.timezone = this.company.timezone
    this.fiscalYearStart = this.company.fiscalYearStart
    this.currency = this.company.currency
    this.weekStart = this.company.weekStart !== undefined ? this.company.weekStart : this.weekStart
    this.timeFormat = this.company.timeFormat || this.timeFormat
    this.menuUrl = this.company.menuUrl
    this.phone = this.company.phone
    this.logo = this.company.logo
    this.categories = this.company.categories
    this.facebookPage = this.company.messenger.username
    this.instagramHandle = this.company.instagram
    this.website = this.company.website
    this.email = this.company.email
    this.country = this.company.country
    this.aboutMap = Object.assign({}, this.company.about)
  },
  methods: {
    async submit() {
      const valid = await this.$validator.validateAll()
      if (!valid || this.phoneValid === false) {
        return
      }
      this.submitting = true
      const data = {
        name: this.name,
        currency: this.currency,
        weekStart: this.weekStart,
        timeFormat: this.timeFormat,
        timezone: this.timezone,
        fiscalYearStart: this.fiscalYearStart,
        phone: this.phone,
        menuUrl: this.menuUrl,
        logo: this.logo,
        about: this.aboutMap,
        categories: this.categories,
        website: this.website,
        email: this.email,
        instagram: this.instagramHandle,
        _updateType: 'details'
      }
      try {
        await this.$store.dispatch('company/update', data)
        this.$buefy.toast.open({message: this.$t('status.save_success'), type: 'is-success', queue: false})
        this.$emit('saved')
      } catch (error) {
        this.$handleAPIError(error)
      }
      this.submitting = false
    }
  }
}
</script>
