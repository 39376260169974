<template lang="pug">
div
  draggable(tag="div" v-model="data" @input="update" v-bind="{animation: 150, ghostClass: 'option-ghost'}" ) 
    .py-2(v-for="(item, idx) in data" :key="item.id")
      .is-flex.is-align-items-center
        div(style="cursor: grab;" v-show="inFocus") 
          span.icon.has-text-grey
            i.mdi.mdi-drag
        .mr-3(v-if="type === FIELD_TYPES.SELECT") 
          span.icon.is-medium {{ idx + 1 }}.
        .mr-3(v-if="type === FIELD_TYPES.CHECKBOXES") 
          span.icon.has-text-grey.is-medium
            i.mdi.mdi-square-outline.mdi-24px
        .mr-3(v-if="type === FIELD_TYPES.RADIO") 
          span.icon.has-text-grey.is-medium
            i.mdi.mdi-circle-outline.mdi-24px


        .is-flex-grow-1.p-1(:class="inFocus ? 'is-hoverable': ''")
          ContentEditable(v-model="item.value" @input="update") 
        .px-2(v-show="inFocus")
          span.icon(@click="remove(idx)")  
            i.mdi.mdi-close.mdi-24px
  .py-2(@click="addNew" v-show="inFocus")
    .is-flex.is-align-items-center
      div
        span.icon.has-text-grey(style="opacity: 0")
          i.mdi.mdi-drag
      .mr-3(v-if="type === FIELD_TYPES.SELECT") 
        span.icon.is-medium {{ data.length + 1}}.
      .mr-3(v-if="type === FIELD_TYPES.CHECKBOXES") 
        span.icon.has-text-grey.is-medium
          i.mdi.mdi-square-outline.mdi-24px
      .mr-3(v-if="type === FIELD_TYPES.RADIO") 
        span.icon.has-text-grey.is-medium
          i.mdi.mdi-circle-outline.mdi-24px
      .is-flex-grow-1
        span.has-text-grey(v-t="'forms.actions.add_option'")
</template>

<script>
import draggable from 'vuedraggable'
import ContentEditable from '../shared/ContentEditable'
import FIELD_TYPES from '../../../constants/formFieldTypes'
export default {
  name: 'FormValueEditor',
  components: {
    ContentEditable,
    draggable
  },
  props: {
    value: {type: Array, default: undefined},
    type: {type: String, required: true},
    inFocus: {type: Boolean, default: true}
  },
  data() {
    return {
      data: [],
      idx: 0,
      FIELD_TYPES
    }
  },

  async created() {
    if (this.value) {
      this.data = this.value.map((v, idx) => {
        return {
          value: v,
          id: idx
        }
      })
      this.idx = this.data.length
    }
  },
  methods: {
    update() {
      this.$emit(
        'input',
        this.data.map(v => v.value)
      )
    },
    addNew() {
      this.data.push({value: `${this.$t('forms.option')} ${this.idx + 1}`, id: this.idx++})
      this.update()
    },
    remove(idx) {
      this.data.splice(idx, 1)
      this.update()
    }
  }
}
</script>
<style scoped>
.is-hoverable {
  border-bottom: 1px solid transparent;
  transition: border ease-in-out 200ms;
}
.is-hoverable:hover {
  border-bottom: 1px solid blue;
}
.option-ghost {
  opacity: 0.4;
  background: #f1f1f1;
}
</style>
