<template lang="pug">
  span(:class="[isTag ? 'tag' : '', colorClass, tagSize]" :title="$t(`card_payment_status.${status}`)")
    span.icon
      i.mdi(:class="[!isTag ? 'has-text-' + colorClass : '', 'mdi-' + icon]")
    | &nbsp;
    span(v-if="showText" v-t="`card_payment_status.${status}`") 
</template>

<script>
import PAYMENT_STATUS from '../../../constants/cardPaymentStatus'
const VISUALS = {
  [PAYMENT_STATUS.DRAFT]: {
    icon: 'help-circle-outline',
    color: 'grey'
  },
  [PAYMENT_STATUS.REFUNDED]: {
    icon: 'undo-variant',
    color: 'warning'
  },
  [PAYMENT_STATUS.SUCCESS]: {
    icon: 'check',
    color: 'success'
  },
  [PAYMENT_STATUS.PENDING]: {
    icon: 'clock-outline',
    color: 'info'
  },
  [PAYMENT_STATUS.FAILED]: {
    icon: 'alert-circle-outline',
    color: 'danger'
  },
  [PAYMENT_STATUS.DECLINED]: {
    icon: 'alert-circle-outline',
    color: 'danger'
  },
  [PAYMENT_STATUS.CANCELED]: {
    icon: 'close',
    color: 'warning'
  }
}

export default {
  props: {
    status: {type: String, required: true},
    showText: {type: Boolean, default: true},
    isTag: {type: Boolean, default: false},
    tagSize: {type: String, default: undefined},
    nocolor: {type: Boolean, default: false}
  },
  computed: {
    colorClass() {
      if (this.nocolor) {
        return
      }

      return (this.isTag ? 'is-' : 'has-text-') + (VISUALS[this.status] && VISUALS[this.status].color)
    },
    icon() {
      return VISUALS[this.status] && VISUALS[this.status].icon
    }
  }
}
</script>
