<template lang="pug">
  span.tag.is-warning(v-if="!nextVisit") 
    span.icon 
      i.mdi.mdi-clock-alert-outline
    span(v-t="'misc.no_booking'")
  span.tag.is-success(v-else) 
    span.icon 
      i.mdi.mdi-clock-check-outline
    span {{nextVisit}}
</template>

<script>
import dayjs from 'dayjs'
export default {
  name: 'NextPurchase',
  props: {
    customer: {type: Object, required: true}
  },
  computed: {
    nextVisit() {
      if (!this.customer.nextVisit || new Date(this.customer.nextVisit) < new Date()) {
        return
      }
      return dayjs(this.customer.nextVisit).format('D MMM')
    }
  }
}
</script>
