<template lang="pug">
div
  h3.title.is-6.is-uppercase.has-text-darkgrey(v-t="'surveys.top_services_header'")
  h5.subtitle.is-7.mb-1 {{period && period.display}}
  ul(style="width: 100%" v-if="list.length")
    li.list-item.px-0(v-for="(service, idx) in list" :key="service._id") 
      .is-flex.is-flex-direction-row.is-align-items-center
        div(style="flex: 0 0 32px;word-break: normal")
          p.title.is-marginless.is-5 \#{{idx+1}}
        div(style="flex: 1 1 auto; overflow: hidden;line-height: 1.3")
          p(style="overflow:hidden; text-overflow: ellipsis; white-space: nowrap")
            service-name-view(:value="{service: service._id}" )
          p.has-text-grey
            small {{$t('misc.times', {count: service.feedbacks})}} 
        div(style="flex: 0 0 auto")
          b {{formatDecimals(service.average)}}
  .p-5.has-text-centered.has-text-grey(v-else)
    p.mt-5 
      span.icon.is-medium
        i.mdi.mdi-close-circle-outline.mdi-36px
    p(v-t="'misc.not_enough_data'")
  p.has-text-right(v-if="expandable")
    a(@click="collapsed = false" v-if="collapsed") 
      span.icon
        i.mdi.mdi-chevron-down
      span(v-t="'actions.see_all'")
    a(@click="collapsed = true" v-if="!collapsed") 
      span.icon
        i.mdi.mdi-chevron-up
      span(v-t="'actions.show_less'")
</template>
<script>
import ReportsApi from '@/api/reports'
import numeral from 'numeral'
export default {
  name: 'TopServices',
  props: {
    period: {type: Object, default: undefined},
    location: {type: String, default: undefined}
  },
  data() {
    return {
      data: [],
      loading: false,
      collapsed: true
    }
  },
  computed: {
    expandable() {
      return this.data.length > 5
    },
    list() {
      if (this.collapsed) {
        return this.data.slice(0, 5)
      }
      return this.data
    }
  },
  watch: {
    period() {
      return this.fetchData()
    },
    location() {
      return this.fetchData()
    }
  },
  created() {
    if (this.period) {
      return this.fetchData()
    }
  },

  methods: {
    async fetchData() {
      this.loading = true
      try {
        this.data = await ReportsApi.getTopServices({
          from: this.period.from,
          to: this.period.to,
          location: this.location
        })
      } catch (error) {
        this.$handleAPIError(error)
      }
      this.loading = false
    },
    formatDecimals(number) {
      if (!number) {
        return '-'
      }
      return numeral(number).format('0.00')
    }
  }
}
</script>
