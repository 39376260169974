<template lang="pug">
div
  h3.title.is-4(v-t="'rewards_programs.examples_title'")
  template(v-if="levels && levels.length")
    div(v-for="(example, idx) in examples" :key="idx")
      h3.title.is-5 Example {{idx+1}}
      p.subtitle.is-6(v-html="example") 
      hr(v-if="idx !== examples.length - 1")
</template>
<script>
import {mapState} from 'vuex'
import saleHelper from '../../../shared/saleHelper'
export default {
  name: 'RewardsProgramExamples',
  props: {
    levels: {type: Array, required: true},
    currencyWorth: {type: Number, required: true}
  },
  computed: {
    ...mapState('company', ['company']),
    examples() {
      let exmp = [
        this.$t('rewards_program.examples.1', {
          tier: this.levels[0].title,
          sale: saleHelper.formatCurrency(120, this.company.currency),
          points: saleHelper.formatPoints(this.levels[0].rate * 120),
          worth: saleHelper.formatCurrency((this.levels[0].rate * 120) / this.currencyWorth, this.company.currency)
        }),
        this.$t('rewards_program.examples.2', {
          tier: this.levels[0].title,
          sale: saleHelper.formatCurrency(180, this.company.currency),
          points: saleHelper.formatPoints(this.levels[0].rate * 160),
          redeem: saleHelper.formatCurrency(20, this.company.currency),
          redeemWorth: saleHelper.formatPoints(20 * this.currencyWorth),
          worth: saleHelper.formatCurrency((this.levels[0].rate * 160) / this.currencyWorth, this.company.currency)
        })
      ]
      if (this.levels.length > 1) {
        exmp.push(
          this.$t('rewards_program.examples.3', {
            tier: this.levels[0].title,
            threshold: saleHelper.formatCurrency(this.levels[1].threshold, this.company.currency),
            nextTier: this.levels[1].title
          })
        )
        exmp.push(
          this.$t('rewards_program.examples.1', {
            tier: this.levels[1].title,
            sale: saleHelper.formatCurrency(85, this.company.currency),
            points: saleHelper.formatPoints(this.levels[1].rate * 85),
            worth: saleHelper.formatCurrency((this.levels[1].rate * 85) / this.currencyWorth, this.company.currency)
          })
        )
      }
      if (this.levels.length > 2) {
        exmp.push(
          this.$t('rewards_program.examples.1', {
            tier: this.levels[2].title,
            sale: saleHelper.formatCurrency(200, this.company.currency),
            points: saleHelper.formatPoints(this.levels[2].rate * 200),
            worth: saleHelper.formatCurrency((this.levels[2].rate * 200) / this.currencyWorth, this.company.currency)
          })
        )
      }
      return exmp
    }
  }
}
</script>
