<template lang="pug">
  article.msg.msg-left.image-card(:class="!valid ? 'is-invalid' : ''")
    image-preview(v-model="message.url" @input="onInput" category="marketing")
    Buttons(v-model="message.buttons" :editable="advancedMode" @input="onInput")

</template>
<script>
import Buttons from './Buttons'
import BaseElement from './BaseElement'

export default {
  name: 'ImageElement',
  components: {
    Buttons
  },
  extends: BaseElement,
  computed: {
    valid() {
      return !!this.message.url
    }
  },
  methods: {
    onInput() {
      this.message.valid = this.valid
      this.$emit('input', this.message)
    }
  }
}
</script>
