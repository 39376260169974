<template lang="pug">
  .box
    h3.title.is-4.has-text-centered Merge customer accounts into one
    .columns.is-multiline
      .column.is-12
          .level
            .level-left
              .level-item
                figure.image.is-48x48
                    img(:src="mergingCustomer.profilePic || require('@/assets/user.jpg')" alt="Image")
              .level-item
                p.title.is-6.is-marginless 
                  customer-name(:value="mergingCustomer")
            .level-right
              .level-item(v-if="mergeWithCustomer && mergeWithCustomer !== mergingCustomer")
                p.title.is-6.is-marginless 
                  customer-name(:value="mergeWithCustomer")
              .level-item(v-if="mergeWithCustomer && mergeWithCustomer !== mergingCustomer")
                figure.image.is-48x48
                    img(:src="mergeWithCustomer.profilePic || require('@/assets/user.jpg')" alt="Image")
              .level-item(v-else)
                p.has-text-grey Select customer to merge with
      .column.is-12
        .buttons.is-centered
          action-button(type="is-danger" @click="confirmMerge" :disabled="!mergingCustomer || !mergeWithCustomer" title="customers.actions.merge_account" icon="call-merge" permission="CLIENTS_MERGE")
          button.button(@click="cancelMerge" v-t="'actions.cancel'")
        
</template>

<script>
import CustomersApi from '@/api/customers'

export default {
  name: 'CustomerMerger',
  props: {
    mergingCustomer: {type: Object, default: undefined},
    mergeWithCustomer: {type: Object, default: undefined}
  },
  methods: {
    cancelMerge() {
      this.$emit('cancel')
    },
    confirmMerge() {
      if (!this.mergingCustomer || !this.mergeWithCustomer || this.mergingCustomer === this.mergeWithCustomer) {
        return
      }
      this.$buefy.dialog.confirm({
        title: 'Merging customer accounts',
        message: `This will merge "${this.mergingCustomer.firstName} ${this.mergingCustomer.lastName}" ${
          this.mergingCustomer.sender ? 'Messenger' : 'manual'
        } account with "${this.mergeWithCustomer.firstName} ${this.mergeWithCustomer.lastName}" ${
          this.mergeWithCustomer.sender ? 'Messenger' : 'manual'
        } account into one. This operation cannot be undone. Do you want to continue?`,
        type: 'is-danger',
        confirmText: 'Merge',
        onConfirm: async () => {
          try {
            await CustomersApi.merge(this.mergingCustomer, this.mergeWithCustomer)
            this.$buefy.toast.open({message: 'Merging successful', type: 'is-success', queue: false})
            this.$emit('merged')
          } catch (error) {
            this.$handleAPIError(error)
            this.$emit('mergeFailed')
          }
        }
      })
    }
  }
}
</script>
