import Navbar from '@/components/Navbar'

// const Container = () => import(/* webpackChunkName: "dashboard" */ '@/components/dashboard/Container')
// const Tabs = () => import(/* webpackChunkName: "dashboard" */ '@/components/dashboard/Tabs')
const Overview = () => import(/* webpackChunkName: "dashboard" */ '@/components/dashboard/Overview')
// const Targets = () => import(/* webpackChunkName: "dashboard" */ '@/components/targets/Targets')

export default [
  {
    path: '/dashboard',
    meta: {
      requiresAuth: true,
      title: 'dashboard.overview_title'
    },
    components: {
      default: Overview,
      navbar: Navbar
    }
    // children: [
    //   {
    //     path: 'overview',
    //     name: 'Dashboard',
    //     component: Overview,
    //     meta: {
    //       title: 'dashboard.overview_title'
    //     }
    //   },
    //   {
    //     path: 'targets',
    //     component: Targets,
    //     meta: {
    //       title: 'dashboard.targets_title'
    //     }
    //   }
    // ]
  }
]
