<template lang="pug">
  span(v-if="staff" :class="{'has-text-grey': !staff.active}") {{name}}
    b-tooltip(:label="$t('staff.preferred_tooltip')" v-if="preferred")
      span.icon.has-text-danger.is-small
        i.mdi.mdi-heart
    span.icon(v-if="!staff.active")
      i.mdi.mdi-account-alert-outline
</template>
<script>
import {mapActions} from 'vuex'
export default {
  props: {
    id: {type: String, default: undefined},
    short: {type: Boolean, default: false},
    preferred: {type: Boolean, default: false}
  },
  data() {
    return {
      staff: undefined
    }
  },
  computed: {
    name() {
      let firstName = this.staff.firstName || ''
      let lastName = this.staff.lastName || ''
      if (this.short && lastName) {
        lastName = `${lastName[0]}.`
      }

      return `${firstName} ${lastName}`.trim()
    }
  },
  watch: {
    id() {
      this.init()
    }
  },
  async created() {
    await this.init()
  },
  methods: {
    ...mapActions('staff', ['fetchStaffById']),
    async init() {
      if (this.id) {
        this.staff = await this.fetchStaffById(this.id)
      } else {
        this.staff = undefined
      }
    }
  }
}
</script>
