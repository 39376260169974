import APPOINTMENT_STATUS from '../../../constants/appointmentStatus'
export default {
  [APPOINTMENT_STATUS.CONFIRMED]: {
    icon: 'check',
    color: 'success'
  },
  [APPOINTMENT_STATUS.DRAFT]: {
    icon: 'pencil-box-outline',
    color: 'danger',
    text: 'white'
  },
  [APPOINTMENT_STATUS.INCOMPLETE]: {
    icon: 'calendar-remove',
    color: 'danger',
    text: 'white'
  },
  [APPOINTMENT_STATUS.NEW]: {
    icon: 'new-box',
    color: 'light',
    iconColor: 'info',
    text: 'black'
  },
  [APPOINTMENT_STATUS.COMPLETED]: {
    icon: 'basket-outline',
    color: 'grey'
  },
  [APPOINTMENT_STATUS.CANCELED]: {
    icon: 'cancel',
    color: 'danger',
    text: 'white'
  },
  [APPOINTMENT_STATUS.ARRIVED]: {
    icon: 'location-enter',
    color: 'warning'
  },
  [APPOINTMENT_STATUS.STARTED]: {
    icon: 'clock-outline',
    color: 'warning'
  },
  [APPOINTMENT_STATUS.NOSHOW]: {
    icon: 'account-off-outline',
    color: 'danger',
    text: 'white'
  }
}
