<template lang="pug">
span {{duration}}
</template>
<script>
import durationMixin from '@/mixins/duration'
export default {
  mixins: [durationMixin],
  props: {
    value: {type: Number, default: undefined},
    granulize: {type: Boolean, default: false}
  },
  computed: {
    duration() {
      return this.formatDuration(this.value, this.granulize)
    }
  }
}
</script>
