<template lang="pug">
navigation-menu(:items="items")
</template>

<script>
import NavigationMenu from '../shared/NavigationMenu'
import PERMISSIONS from '../../../constants/permissions'
import {PRODUCTS} from '../../../constants/products'
import {mapState} from 'vuex'
export default {
  components: {
    NavigationMenu
  },
  computed: {
    ...mapState('company', ['company']),
    items() {
      return [
        {
          path: '/company/team',
          title: 'staff.title_members',
          icon: 'account-multiple',
          permission: PERMISSIONS.core.STAFF_VIEW,
          nested: [
            {
              path: '/company/team/shifts',
              title: 'staff.title_shifts',
              icon: 'calendar-clock',
              permission: PERMISSIONS.core.STAFF_SHIFTS_VIEW
            },
            {
              path: '/company/team/permissions',
              title: 'staff.title_permissions',
              icon: 'lock-outline',
              product: PRODUCTS.PERMISSION_MANAGEMENT,
              permission: PERMISSIONS.core.STAFF_PERMISSIONS_VIEW
            }
          ]
        },
        {
          path: '/company/services',
          icon: 'format-list-bulleted',
          title: 'services.title',
          permission: PERMISSIONS.core.SERVICES_VIEW,
          nested: [
            {
              path: '/company/services/edit',
              icon: 'clipboard-edit-outline',
              title: 'services.bulk_edit_title',
              permission: PERMISSIONS.core.SERVICES_VIEW,
              hidden: this.company.languages.length < 2
            },
            {
              path: '/company/services/hsCode',
              icon: 'clipboard-list-outline',
              title: 'services.hs_code_edit_title',
              permission: PERMISSIONS.core.SERVICES_EDIT,
              hidden: this.company.country !== 'AM'
            }
          ]
        },

        {
          path: '/company/resources',
          title: 'resources_settings.title',
          icon: 'package-variant-closed',
          permission: PERMISSIONS.core.RESOURCES_VIEW,
          nested: [
            {
              path: '/company/resources/schedules',
              title: 'resources_settings.title_schedules',
              icon: 'calendar-clock',
              permission: PERMISSIONS.core.RESOURCES_SCHEDULE_VIEW
            }
          ]
        },
        {
          path: '/company/locations',
          icon: 'map-marker-outline',
          title: 'locations_settings.title',
          permission: PERMISSIONS.core.SETTINGS_LOCATIONS_VIEW
        },
        {
          path: '/company/forms',
          icon: 'list-box-outline',
          title: 'forms.title',
          product: PRODUCTS.FORMS,
          permission: PERMISSIONS.core.FORMS_VIEW
        }

        // {
        //   path: '/staff/performance',
        //   title: 'staff.title_performance',
        //   icon: 'chart-areaspline',
        //   permission: PERMISSIONS.core.STAFF_PERFORMANCE_VIEW
        // },
      ]
    }
  }
}
</script>
