<template lang="pug">
.modal-card
  header.modal-card-head
    p.modal-card-title(data-qa="modal-header") 
      b(v-t="'billing.subscribe.title'")
    div
      a.button.is-white.is-small(@click="$emit('close')")
        span.icon
          i.mdi.mdi-close.mdi-24px
  section.modal-card-body.p-6(style="width: auto" v-if="!success")
    em-loading(:active="loading" :is-full-page="false")
    div(v-if="plan")
      .bordered-box.is-primary.p-5.is-block.mb-4
        div
          h3.heading {{ $t('billing.plan_name_heading') }}
          p.title.is-size-4 {{$t(`products.${planSettings.plan}`)}} 
          //h3.title.is-5 
            invoice-currency(:value="subtotalPrice" :currency-code="currency") 
            |  {{ $t('misc.month') }}
          //- p.subtitle.is-6.has-text-grey
          //-   span {{ $t('billing.locations_number', {count: activeLocations.length}) }}
          //-   br
          //-   span(v-t="'billing.price_per_location'")
          //-   |  {{ formattedPrice }} {{ $t('misc.month') }}
          //- p.subtitle.is-5 
          //-   span(v-if="plan.limit === 0" v-html="$t('billing.unlimited_checkouts')") 
          //-   span(v-if="plan.limit" v-html="$t('billing.customer_checkouts', [plan.limit])")
          p.list-item
            span {{ $t('billing.plan_name_heading') }}
            b.is-size4.is-pulled-right {{$t(`products.${planSettings.plan}`)}} 
          p.list-item
            span(v-t="'billing.per_month_label'")
            b.is-pulled-right {{ formattedPrice }}
          p.list-item
            span.is-size5 {{ $t('billing.locations_label') }}
            span.is-size5.is-pulled-right {{activeLocations.length}}
          p.list-item
            span.is-size5 {{ $t('billing.subtotal_label') }}
            span.is-size5.is-pulled-right(v-if="currency")
              invoice-currency(:value="subtotalPrice" :currency-code="currency")
          p.list-item(v-if="coupon")
            span {{ $t('billing.coupon_label') }}  
            span.is-pulled-right
              span {{coupon.name}} 
              em ({{coupon.id}})
          p.list-item(v-if="coupon && coupon.duration !== 'forever'")
            span {{ $t('billing.coupon_validity_label') }}
            span.is-pulled-right
              span(v-if="coupon.duration === 'repeating'")
                span {{coupon.durationInMonths}} 
                span {{ $t('misc.months') }}
              span(v-if="coupon.duration === 'once'")
                span {{ $t('misc.one_time_only') }}
          p.list-item(v-if="discount") 
            span {{ $t('billing.discount_label') }}
            span.is-pulled-right
              invoice-currency(:value="discount" :currency-code="currency")
          p.list-item
            strong.is-size5 {{ $t('billing.total_label') }} 
            strong.is-size5.is-pulled-right(v-if="currency")
              invoice-currency(:value="totalPrice" :currency-code="currency") 
      coupon-input.mb-4(@input="onCoupon" v-if="!subscription.status")
      .notification.is-danger.mb-4(v-if="errorMsg")
        p {{errorMsg}}
        
      PaymentMethods(ref="paymentMethod" @input="onCardToken")
    
  
  section.modal-card-body(v-else)
    success-screen
      p.title.is-3(v-t="'misc.thank_you'")
      p.subtitle.is-5(v-t="'billing.subscription.success'")
  footer.modal-card-foot
    .buttons
        action-button.is-primary(@click='purchase'  icon="lock" title="billing.actions.subscribe" :loading="submitting" v-if="!success") 
        button.button(@click="$emit('close')" v-t="'actions.close'")   
</template>

<script>
import PlanDetails from './PlanDetails'
import {mapState, mapActions, mapGetters} from 'vuex'
import CouponInput from './CouponInput'
import PaymentMethods from './PaymentMethods'
import InvoiceCurrency from './InvoiceCurrency'
import {SETTINGS} from '../../../constants/products'
import saleHelper from '../../../shared/saleHelper'

export default {
  name: 'StartSubscriptionForm',
  components: {
    PlanDetails,
    CouponInput,
    PaymentMethods,
    InvoiceCurrency
  },
  props: {
    planCode: {type: String, required: true}
  },
  data() {
    return {
      name: undefined,
      submitting: false,
      coupon: undefined,
      errorMsg: undefined,
      plan: undefined,
      loading: false,
      success: false
    }
  },
  computed: {
    ...mapState('billing', ['subscription']),
    ...mapState('company', ['company']),
    ...mapGetters('location', ['activeLocations']),

    planTerms() {
      return this.data[0].prices.monthly[0]
    },
    planSettings() {
      return SETTINGS[this.planCode]
    },
    formattedPrice() {
      return saleHelper.formatCurrency(
        this.plan.prices.monthly[0].unit_amount / 100,
        this.plan.prices.monthly[0].currency
      )
    },

    currency() {
      if (!this.plan) {
        return
      }
      return this.plan.prices.monthly[0].currency
    },
    subtotalPrice() {
      return this.plan.prices.monthly[0].unit_amount * this.activeLocations.length
    },
    discount() {
      if (this.coupon) {
        if (this.coupon.amountOff) {
          return this.coupon.amountOff
        } else {
          return (this.subtotalPrice * this.coupon.percentOff) / 100
        }
      }
      return 0
    },
    totalPrice() {
      return this.subtotalPrice - this.discount
    }
  },
  async created() {
    await this.fetchPlan()
  },
  methods: {
    ...mapActions('billing', ['createSubscription', 'fetchProducts']),
    async fetchPlan() {
      this.loading = true
      try {
        this.plan = (await this.fetchProducts()).find(p => p.plan === this.planCode)
      } catch (error) {
        this.$handleAPIError(error)
      }
      this.loading = false
    },

    async purchase() {
      if (this.submitting) {
        return
      }
      this.submitting = true
      try {
        await this.$refs.paymentMethod.submit()
      } catch (err) {
        this.submitting = false
      }
    },

    async onCardToken(data) {
      try {
        await this.createSubscription({
          plan: this.plan.plan,
          price: this.plan.prices.monthly[0].id,
          token: data && data.token
        })
        this.success = true
      } catch (err) {
        if (err.body && err.body.code === 'INVALID_CARD') {
          this.errorMsg = err.body.message
          if (err.body.additionalMessage) {
            this.errorMsg += ' ' + err.body.additionalMessage
          }
          return
        }
        this.$handleAPIError(err)
      }
      this.submitting = false
    },

    onCoupon(coupon) {
      this.coupon = coupon
    }
  }
}
</script>

<style>
.security-badge {
  height: 80px;
}
</style>
