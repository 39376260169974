<template lang="pug">
.section.is-flex-grow-1
  em-loading(:active="loading" :is-full-page="false")
  .box
    .columns.is-centered(v-if="plan")
      .column.is-8
          .p-6
            .has-text-centered
              //img(:src="IMAGES[product]" v-if="IMAGES[product]" style="width: 45%")
              //p.heading.has-text-primary.is-size-4(v-t="'product_teaser.upgrade_intro_text'")
              h1.title.is-3 {{$t(`products.${product}`)}}
              p.subtitle.is-5 {{$t(`product_desc.${product}`)}}
            hr
            .bordered-box.is-primary.p-5.is-justify-content-left
              div
                p.heading {{ $t('billing.plan_heading') }}
                h3.title.is-4.has-text-black.has-text-uppercase {{$t(`products.${plan.plan}`)}}
                p.subtitle.is-size-6.has-text-grey {{ $t(`billing.plans.${plan.plan}`) }}
                p.is-size-3.mb-4
                  invoice-currency(:value="plan.prices.monthly[0].unit_amount" :currency-code="plan.prices.monthly[0].currency")
                  |  
                  small.has-text-grey(style="font-size: 1rem;" v-t="'billing.per_month_label'") 
                .buttons(v-if="!company.manualPayment")
                  action-button(@click="confirmSubscribe" title="product_teaser.upgrade_now")
                .buttons(v-else)
                  a.button.is-medium.is-success(href="tel:+37455520005") {{$t('product_teaser.contact_sales')}}

              
              
              //.notification.is-grey.p-5
                p.title.is-5(v-t="'product_teaser.upgrade_intro_text'")
                ul.mb-3
                  li.list-item(v-for="product in productsAfterUpgrade") 
                    span.icon
                      i.mdi.mdi-check
                    strong {{$t(`products.${product}`)}}
          
      
      
          
</template>

<script>
import {mapActions, mapGetters, mapState} from 'vuex'
import InvoiceCurrency from './InvoiceCurrency'
import SubscribeToPaidPlan from './SubscribeToPaidPlan'
import {PRODUCTS, SETTINGS} from '../../../constants/products'
import billingHelper from '../../../shared/billingHelper'
import saleHelper from '../../../shared/saleHelper'
import {difference} from 'lodash'
const IMAGES = {
  [PRODUCTS.REVIEWS]: 'https://1206091307.rsc.cdn77.org/landing/review_screens.png',
  [PRODUCTS.AUTOMATIONS]: 'https://1206091307.rsc.cdn77.org/landing/marketing_hero.png',
  [PRODUCTS.COUPONS]: 'https://1206091307.rsc.cdn77.org/landing/coupons.png',
  [PRODUCTS.SURVEYS]: 'https://1206091307.rsc.cdn77.org/landing/negative_feedback.png',
  [PRODUCTS.REFERRAL_PROGRAM]: 'https://1206091307.rsc.cdn77.org/landing/referrals_how.png',
  [PRODUCTS.REWARDS_PROGRAM]: 'https://1206091307.rsc.cdn77.org/landing/rewards_program.png',
  [PRODUCTS.FOLLOWUPS]: 'https://1206091307.rsc.cdn77.org/landing/rebooking_reminders.png'
}
export default {
  name: 'ProductTeaser',
  components: {
    InvoiceCurrency
  },
  props: {
    product: {type: String, required: true}
  },
  data() {
    return {
      loading: false,
      plan: undefined,
      IMAGES
    }
  },
  computed: {
    ...mapState('company', ['company']),
    ...mapGetters('company', ['currentPlan']),
    hasFreeTrial() {
      return !this.company.plan.trialStart
    },
    formattedPrice() {
      if (!this.plan) {
        return
      }
      return saleHelper.formatCurrency(
        this.plan.prices.monthly[0].unit_amount / 100,
        this.plan.prices.monthly[0].currency
      )
    },
    productsAfterUpgrade() {
      let current = SETTINGS[this.currentPlan].included || []
      let potential = this.plan.included
      return difference(potential, current)
    }
  },
  watch: {
    product() {
      return this.fetchProduct()
    }
  },
  created() {
    return this.fetchProduct()
  },
  methods: {
    ...mapActions('billing', ['fetchProducts', 'startFreeTrial']),
    async fetchProduct() {
      this.loading = true
      try {
        let plan = billingHelper.getPlanByProduct({product: this.product, country: this.company.country})

        this.plan = (await this.fetchProducts()).find(p => p.product === plan.plan)
      } catch (error) {
        this.$handleAPIError(error)
      }
      this.loading = false
    },
    confirmSubscribe() {
      this.$buefy.modal.open({
        parent: this,
        component: SubscribeToPaidPlan,
        width: 960,
        hasModalCard: true,
        props: {
          planCode: this.plan.plan
        },
        canCancel: ['escape']
      })
    },
    confirmTrial() {
      this.$buefy.dialog.confirm({
        title: this.$t('product_teaser.trial_confirm.title'),
        message: this.$t('product_teaser.trial_confirm.body'),
        cancelText: this.$t('actions.cancel'),
        confirmText: this.$t('actions.confirm'),
        type: 'is-success',
        onConfirm: async () => {
          try {
            await this.startFreeTrial(this.plan.plan)
            this.$buefy.toast.open({
              message: this.$t('product_teaser.trial_confirm.success'),
              type: 'is-success',
              duration: 5000,
              queue: false
            })
          } catch (err) {
            this.$handleAPIError(err)
          }
        }
      })
    }
  }
}
</script>
