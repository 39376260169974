import Vue from 'vue'

export default {
  list(params) {
    return Vue.http.get('employees', {params}).then(res => res.body)
  },
  getActiveServiceList() {
    if (!this._activeServicePromise) {
      this._activeServicePromise = this.list({active: true, service: true})
    }
    return this._activeServicePromise
  },

  getPerformance(params) {
    return Vue.http.get('employees/performance', params).then(res => res.body)
  },

  getById(id) {
    return Vue.http.get(`employees/${id}`)
  },
  update(id, data) {
    this._activeServicePromise = null
    return Vue.http.put(`employees/${id}`, data)
  },
  create(data) {
    this._activeServicePromise = null
    return Vue.http.post(`employees`, data)
  },
  remove(id) {
    this._activeServicePromise = null
    return Vue.http.delete(`employees/${id}`)
  },
  batchAddServiceStaff(data) {
    return Vue.http.post(`employees/batch`, data)
  },
  updateOrder(data) {
    return Vue.http.put(`employees/order`, data)
  },
  checkEmail(email) {
    return Vue.http.post(`employees/email`, {email})
  },
  getShifts(params) {
    return Vue.http.get(`employees/shifts`, {params}).then(res => res.data)
  },
  getShiftRanges(params) {
    return Vue.http.get(`employees/shiftRanges`, {params}).then(res => res.data)
  },
  setStaffShift(id, data) {
    return Vue.http.post(`employees/shifts/${id}`, data).then(res => res.data)
  },
  removeStaffShift(id, day) {
    return Vue.http.delete(`employees/shifts/${id}/${day}`).then(res => res.data)
  }
}
