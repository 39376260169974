<template lang="pug">
  div
    screen-title(title="memberships.membership_packages_title")
      action-button(@click="addOrEdit" title="memberships.actions.add_package" icon="plus-circle-outline" permission="MEMBERSHIP_PACKAGES_MANAGE" scope)
    .columns.is-multiline
      .column.is-6(v-for="memPack in membershipPackages" :key="memPack._id")
        .is-clickable(@click="addOrEdit(memPack)")
          MembershipPackageView(:value="memPack._id" )

</template>
<script>
import {mapGetters} from 'vuex'
import CreateEditMembershipPackage from './CreateEditMembershipPackage'
import MembershipPackageView from './MembershipPackageView'
export default {
  name: 'MembershipPackages',
  components: {
    MembershipPackageView
  },
  computed: {
    ...mapGetters('membershipPackages', ['membershipPackages'])
  },

  methods: {
    addOrEdit(membership) {
      this.$buefy.modal.open({
        parent: this,
        hasModalCard: true,
        fullScreen: this.isMobile(),
        props: {id: membership && membership._id},
        component: CreateEditMembershipPackage,
        canCancel: ['escape']
      })
    }
  }
}
</script>
