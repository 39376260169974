<template lang="pug">
  div
    b-checkbox(v-model="parking.lot" @input="onChange") {{$t('parking_options.lot')}}
    b-checkbox(v-model="parking.street" @input="onChange") {{$t('parking_options.street')}}
    b-checkbox(v-model="parking.valet" @input="onChange") {{$t('parking_options.valet')}}
</template>

<script>
export default {
  name: 'LocationParkingDetails',
  props: {
    value: {type: Object, default: undefined}
  },
  data() {
    return {
      parking: {
        lot: false,
        street: false,
        valet: false
      }
    }
  },
  watch: {
    value(value) {
      if (value) {
        this.parking = Object.assign(this.parking, value)
      }
    }
  },
  async created() {
    if (this.value) {
      this.parking = Object.assign(this.parking, this.value)
    }
  },
  methods: {
    onChange() {
      this.$emit('input', this.parking)
    }
  }
}
</script>
