<template lang="pug">
div
  ul
    li.list-item.px-0(v-for="({service, count, last}, idx) in list" style="") 
      .is-flex.is-flex-direction-row.is-align-items-center
        div(style="flex: 0 0 32px;word-break: normal")
          p.title.is-marginless.is-5 \#{{idx+1}}
        div(style="flex: 1 1 auto; overflow: hidden;line-height: 1.3")
          p(style="overflow:hidden; text-overflow: ellipsis; white-space: nowrap")
            service-name-view(:value="{service}" )
          p.has-text-grey
            small {{$t('misc.times', {count})}}
            |  ·  
            small {{last | fromNow}}
        //div(style="flex: 0 0 auto")
          button.button.is-small.is-primary.is-outlined repeat
  p.has-text-right(v-if="expandable")
    a(@click="collapsed = false" v-if="collapsed") 
      span.icon
        i.mdi.mdi-chevron-down
      span(v-t="'actions.see_all'")
    a(@click="collapsed = true" v-if="!collapsed") 
      span.icon
        i.mdi.mdi-chevron-up
      span(v-t="'actions.show_less'")
</template>

<script>
export default {
  name: 'FavoriteServices',
  props: {
    customer: {type: Object, required: true}
  },
  data() {
    return {
      collapsed: true
    }
  },
  computed: {
    expandable() {
      return this.customer.favServices.length > 3
    },
    list() {
      if (this.collapsed) {
        return this.customer.favServices.slice(0, 3)
      }
      return this.customer.favServices
    }
  }
}
</script>
