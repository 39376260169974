<template lang="pug">
  div
    screen-title(title="locations_settings.title")
      action-button(@click="openAddLocationModal" title="locations_settings.actions.add_location" icon="map-marker-plus" permission="SETTINGS_LOCATIONS_MANAGE" scope)
    note.mb-4(type="is-warning")
      p 
        strong(v-t="'locations_settings.billing_change_title'")  
      p(v-t="'locations_settings.billing_change_subtitle'")
    LocationCard.mb-5(v-for="location in activeLocations" :location="location" :key="location._id" @click="edit(location)")
    h3.title.is-4(v-t="'locations_settings.inactive_locations_title'" v-if="inactiveLocations.length")
    LocationCard.mb-5(v-for="location in inactiveLocations" size="small" :location="location" :key="location._id" @click="edit(location)")
</template>

<script>
import {mapActions, mapGetters} from 'vuex'
import LocationCreateEditForm from './LocationCreateEditForm'
import LocationCard from '../location/LocationCard'
export default {
  components: {
    LocationCard
  },
  data() {
    return {
      loading: false
    }
  },
  computed: {
    ...mapGetters('location', ['activeLocations', 'inactiveLocations'])
  },
  async created() {
    await this.fetch()
  },
  methods: {
    ...mapActions('location', ['fetchLocations']),
    async fetch() {
      this.loading = true
      try {
        await this.fetchLocations(true)
      } catch (error) {
        this.$handleAPIError(error)
      }
      this.loading = false
    },
    edit(location) {
      this.$buefy.modal.open({
        parent: this,
        component: LocationCreateEditForm,
        fullScreen: true,
        hasModalCard: true,
        canCancel: ['escape'],
        props: {locationId: location._id}
      })
    },
    openAddLocationModal() {
      this.$buefy.modal.open({
        parent: this,
        component: LocationCreateEditForm,
        fullScreen: true,
        hasModalCard: true,
        canCancel: ['escape']
      })
    }
  }
}
</script>
