import {SET_TASKS, REMOVE_TASK, SET_COMPLETENESS} from './mutationTypes'
import CompletenessApi from '@/api/completeness'

export default {
  namespaced: true,
  state: {
    tasksOrder: [],
    tasksMap: {},
    completeness: null
  },
  getters: {
    tasks(state) {
      return state.tasksOrder.map(c => state.tasksMap[c])
    },
    topThreeTasks(state) {
      return state.tasksOrder.slice(0, 3).map(c => state.tasksMap[c])
    },
    topTask(state) {
      if (!state.tasksOrder.length) {
        return
      }
      return state.tasksMap[state.tasksOrder[0]]
    }
  },
  mutations: {
    [SET_TASKS](state, tasks) {
      let mapped = {}
      for (let task of tasks) {
        mapped[task._id] = task
      }
      state.tasksMap = mapped
      state.tasksOrder = tasks.map(c => c._id)
    },
    [REMOVE_TASK](state, id) {
      delete state.tasksMap[id]
      let idx = state.tasksOrder.indexOf(id)
      if (idx !== -1) {
        state.tasksOrder.splice(idx, 1)
      }
    },
    [SET_COMPLETENESS](state, value) {
      state.completeness = value
    }
  },
  actions: {
    async fetch({commit}) {
      let {body} = await CompletenessApi.getCompletenessTasks()
      commit(SET_TASKS, body.tasks)
      commit(SET_COMPLETENESS, body.completeness)
    },
    async markDone({commit}, task) {
      await CompletenessApi.setTaskComplete(task)
      commit(REMOVE_TASK, task)
    }
  }
}
