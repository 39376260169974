import {SET_REVIEWS} from './mutationTypes'
import ReviewsApi from '@/api/review'

export default {
  namespaced: true,
  state: {
    reviews: []
  },
  mutations: {
    [SET_REVIEWS](state, list) {
      state.reviews = list
    }
  },
  actions: {
    async fetch({commit}) {
      const list = await ReviewsApi.list()
      commit(SET_REVIEWS, list)
    },
    fetchVerifiedList() {
      return ReviewsApi.listVerified()
    },
    async validateReviewRequest({dispatch}, {id, data}) {
      await ReviewsApi.updateReview(id, data)
      await dispatch('fetch')
    }
  }
}
