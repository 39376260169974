<template lang="pug">
div
  h3.title.is-6.is-uppercase.has-text-darkgrey(v-t="'rewards.signup_ratio'")
  h5.subtitle.is-7 {{period && period.display}}
  p.title.is-2.is-size-3-mobile 
    percentage-view(:value="memberCustomers/customers")
  p.subtitle.is-6(v-t="'rewards.ration_explaination'")
  .list-item.px-0 {{ $t('rewards.customer_members_label') }}  
    b.is-pulled-right 
      rewards-view(:value="memberCustomers")
  .list-item.px-0 {{ $t('rewards.members_label') }} 
    b.is-pulled-right 
      rewards-view(:value="members")
  .list-item.px-0 {{ $t('rewards.customers_label') }}  
    b.is-pulled-right 
      rewards-view(:value="customers")
  .list-item.px-0 {{ $t('rewards.total_contacts_label') }}  
    b.is-pulled-right 
      rewards-view(:value="total")
</template>

<script>
import ReportsApi from '@/api/reports'

export default {
  name: 'MembersStats',
  props: {
    period: {type: Object, default: undefined},
    location: {type: String, default: undefined}
  },
  data() {
    return {
      loading: false,
      members: 0,
      customers: 0,
      memberCustomers: 0,
      total: 0,
      averages: {
        members: {sales: 0, visits: 0, ltv: 0},
        customers: {sales: 0, visits: 0, ltv: 0}
      }
    }
  },
  watch: {
    period() {
      return this.fetchData()
    },
    location() {
      return this.fetchData()
    }
  },
  created() {
    if (this.period) {
      return this.fetchData()
    }
  },
  methods: {
    async fetchData() {
      this.loading = true
      try {
        const data = await ReportsApi.getMembershipStats({
          from: this.period.from,
          to: this.period.to,
          location: this.location
        })
        this.members = data.members
        this.total = data.total
        this.customers = data.customers
        this.memberCustomers = data.memberCustomers
      } catch (error) {
        this.$handleAPIError(error)
      }
      this.loading = false
    }
  }
}
</script>
