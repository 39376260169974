<template lang="pug">
.is-flex.is-flex-direction-column.is-align-items-stretch(style="overflow: hidden")
  .is-flex-grow-1.is-flex.is-flex-direction-column.is-align-items-flex-end.p-4.is-relative(style="overflow: scroll" ref="container")
    .message-cont.mb-3(v-for="message in messages" :key="message._id")
      .message-text {{message.text}}
      p.help.has-text-right 
        date-view(:value="message.created" format="L LT")
        |  -  
        span {{message.status}}
        
    em-loading(:active="loading" :is-full-page="false")
  .is-flex-shrink-0.p-4.has-border-top
    SMSComposer(v-model="text" :transactional="true" :limit-to-one="company.smsProvider && !company.ownSMSProvider" noHelp :show-count="false")
    .buttons.is-right.mt-3
      action-button.is-small.is-outlined(@click="submit" icon="send" title="sms_composer.actions.send" :loading="submitting" product="sms_notifications" :disabled="!customer.phone ||!company.smsProvider" permission="CLIENT_COMPOSE_SMS")
</template>
<script>
import SMSComposer from '../sms/SMSComposer'
import SMSApi from '@/api/sms'
import {mapState} from 'vuex'
export default {
  components: {
    SMSComposer
  },
  props: {
    customer: {type: Object, required: true}
  },
  data() {
    return {
      text: undefined,
      submitting: false,
      loading: false,
      messages: undefined
    }
  },
  computed: {
    ...mapState('company', ['company'])
  },
  async mounted() {
    await this.fetchMessages()
  },
  methods: {
    async fetchMessages() {
      this.loading = true
      try {
        this.messages = await SMSApi.getCustomerMessages(this.customer._id)

        await this.$nextTick()
        let el = this.$refs.container
        el.scrollTop = el.scrollHeight
      } catch (err) {
        this.$handleAPIError(err)
      }
      this.loading = false
    },
    async submit() {
      const valid = await this.$validator.validateAll()
      if (!valid) {
        return
      }
      if (this.submitting) {
        return
      }
      this.submitting = true
      try {
        await SMSApi.sendMessage({customerId: this.customer._id, text: this.text})
        this.text = undefined
        this.$buefy.toast.open({message: this.$t('sms_composer.send_success'), type: 'is-success', queue: false})
        this.fetchMessages()
      } catch (err) {
        this.$handleAPIError(err)
      }
      this.submitting = false
    }
  }
}
</script>
<style scoped>
.message-cont {
  width: 75%;
}
.message-text {
  background: rgb(54, 101, 255);
  padding: 0.5rem;
  border-radius: 12px;
  color: #fff;
}
</style>
