import {SET_COUPON, REMOVE_COUPON, SET_COUPONS, SET_TOTAL_COUPONS} from './mutationTypes'
import CouponApi from '@/api/coupon'

export default {
  namespaced: true,
  state: {
    couponsMap: {},
    couponsOrder: [],
    total: 0
  },
  getters: {
    coupons(state) {
      return state.couponsOrder.map(c => state.couponsMap[c])
    }
  },
  mutations: {
    [SET_COUPONS](state, coupons) {
      const mapped = {}
      for (const coupon of coupons) {
        mapped[coupon._id] = coupon
      }
      state.couponsMap = mapped
      state.couponsOrder = coupons.map(c => c._id)
    },
    [SET_COUPON](state, coupon) {
      state.couponsMap[coupon._id] = coupon
      state.couponsOrder.push(coupon._id)
    },
    [REMOVE_COUPON](state, couponId) {
      delete state.couponsMap[couponId]
      const idx = state.couponsOrder.indexOf(couponId)
      if (idx !== -1) {
        state.couponsOrder.splice(idx, 1)
      }
    },
    [SET_TOTAL_COUPONS](state, total) {
      state.total = total
    }
  },
  actions: {
    async list({commit}, params) {
      const {body} = await CouponApi.list(params)
      commit(SET_COUPONS, body.data)
      commit(SET_TOTAL_COUPONS, body.total)
    },
    async update({commit}, {id, data}) {
      const {body} = await CouponApi.update(id, data)
      commit(SET_COUPON, body)
      return body
    },
    async create({commit}, data) {
      const {body} = await CouponApi.create(data)
      commit(SET_COUPON, body)
      return body
    },
    async getById({commit, state}, id) {
      if (state.couponsMap[id]) {
        return state.couponsMap[id]
      }
      const coupon = await CouponApi.getById(id)
      commit(SET_COUPON, coupon)
      return coupon
    },
    async removeCoupon({commit, state}, couponId) {
      if (state.couponsMap[couponId]) {
        commit(REMOVE_COUPON, couponId)
      }
      await CouponApi.remove(couponId)
    },
    getTypes() {
      return CouponApi.getTypes()
    }
  }
}
