<template lang="pug">
figure.image(:title="name")
  img(:src="picture || require('@/assets/location.png')" @error="onInvalidImage")
</template>

<script>
import {mapActions} from 'vuex'
export default {
  name: 'ResourcePic',
  props: {
    value: {type: [Object, String], required: true}
  },
  data() {
    return {
      resource: undefined
    }
  },
  computed: {
    picture() {
      if (typeof this.value === 'string') {
        return (this.resource && this.resource.picture) || undefined
      }
      return this.value.picture
    },
    name() {
      if (!this.resource) {
        return
      }
      return this.resource.name
    }
  },
  watch: {
    async resource() {
      if (typeof this.value === 'string') {
        this.resource = await this.getById(this.value)
      }
    }
  },
  async created() {
    if (typeof this.value === 'string') {
      this.resource = await this.getById(this.value)
    }
  },
  methods: {
    ...mapActions('resources', ['getById']),
    onInvalidImage(event) {
      event.target.src = require('@/assets/user.jpg')
    }
  }
}
</script>
