class LocationHelper {
  formatLocationName({location, company, short = true}) {
    if (short) {
      if (location.title) {
        return location.title
      }
      return location.address ? `${company.name} - ${location.address.street}` : company.name
    }
    return location.title
      ? `${location.title} `
      : '' + (location.address ? `${company.name} - ${location.address.street}` : company.name)
  }
  getStreetAddress({location, company}) {
    if (location.address?.street) {
      return location.address.street
    }
    return location.title || company.name
  }

  getFullAddress(location) {
    if (!location.address) {
      return
    }
    let address = location.address
    let tokens = []
    if (address.street) {
      tokens.push(address.street)
    }
    if (address.streetAlt) {
      tokens.push(address.streetAlt)
    }
    if (address.city) {
      tokens.push(address.city)
    }
    if (address.zip) {
      tokens.push(address.zip)
    }
    if (address.state) {
      tokens.push(address.state)
    }
    if (address.country) {
      tokens.push(address.country)
    }
    return tokens.join(', ')
  }
}

module.exports = new LocationHelper()
