import {SET_OPEN_TICKETS, SET_DONE_TICKETS, SET_TICKET, SET_SORT_BY} from './mutationTypes'
import TicketsApi from '@/api/tickets'
import Vue from 'vue'

export default {
  namespaced: true,
  state: {
    sortBy: 'newest',
    ticketsMap: {},
    openTicketsOrder: [],
    doneTicketsOrder: []
  },
  getters: {
    openTickets(state) {
      return state.openTicketsOrder.map(c => state.ticketsMap[c])
    },
    doneTickets(state) {
      return state.doneTicketsOrder.map(c => state.ticketsMap[c])
    }
  },
  mutations: {
    [SET_SORT_BY](state, order) {
      state.sortBy = order
    },
    [SET_OPEN_TICKETS](state, tickets) {
      let mapped = {}
      for (let ticket of tickets) {
        mapped[ticket._id] = ticket
      }
      state.ticketsMap = mapped
      state.openTicketsOrder = tickets.map(c => c._id)
    },
    [SET_DONE_TICKETS](state, tickets) {
      let mapped = {}
      for (let ticket of tickets) {
        mapped[ticket._id] = ticket
      }
      state.ticketsMap = mapped
      state.doneTicketsOrder = tickets.map(c => c._id)
    },
    [SET_TICKET](state, ticket) {
      let current = state.ticketsMap[ticket._id] || {}
      for (let prop in ticket) {
        if (current[prop] === undefined) {
          Vue.set(current, prop, ticket[prop])
        } else {
          current[prop] = ticket[prop]
        }
      }
      let openTicketIdx = state.openTicketsOrder.indexOf(ticket._id)
      let doneTicketIdx = state.doneTicketsOrder.indexOf(ticket._id)

      if (ticket.status === 'done') {
        if (openTicketIdx !== -1) {
          state.openTicketsOrder.splice(openTicketIdx, 1)
        }
        if (doneTicketIdx === -1) {
          state.doneTicketsOrder.unshift(ticket._id)
        }
      } else {
        if (doneTicketIdx !== -1) {
          state.doneTicketsOrder.splice(doneTicketIdx, 1)
        }
        if (openTicketIdx === -1) {
          state.openTicketsOrder.unshift(ticket._id)
        }
      }
      state.ticketsMap[ticket._id] = current
    }
  },
  actions: {
    async fetchOpenTickets({commit, state}) {
      let {body} = await TicketsApi.fetch({status: 'open', sortBy: state.sortBy})
      commit(SET_OPEN_TICKETS, body)
    },
    async fetchDoneTickets({commit, state}) {
      let {body} = await TicketsApi.fetch({status: 'done', sortBy: state.sortBy})
      commit(SET_DONE_TICKETS, body)
    },
    async fetchTicket({state, commit, dispatch}, id) {
      if (!state.ticketsMap[id]) {
        let {body} = await TicketsApi.getById(id)
        commit(SET_TICKET, body)
      }
      if (!state.ticketsMap[id].read) {
        await dispatch('setTicketRead', id)
      }
      return state.ticketsMap[id]
    },
    async refreshTicket({state, commit}, id) {
      if (state.ticketsMap[id]) {
        let {body} = await TicketsApi.getById(id)
        commit(SET_TICKET, body)
      }
    },
    async resolveTicket({dispatch}, id) {
      return dispatch('updateTicket', {_id: id, action: 'resolve'})
    },
    async reopenTicket({dispatch}, id) {
      return dispatch('updateTicket', {_id: id, action: 'reopen'})
    },
    async addTicketNote({dispatch}, {id, note}) {
      return dispatch('updateTicket', {_id: id, action: 'note', note})
    },
    async setTicketRead({dispatch}, id) {
      return dispatch('updateTicket', {_id: id, action: 'read'})
    },
    async setTicketPriority({dispatch}, {id, value}) {
      return dispatch('updateTicket', {_id: id, action: 'priority', value})
    },
    async updateTicket({commit, state}, data) {
      let {body} = await TicketsApi.update(data._id, data)
      commit(SET_TICKET, body)
      return state.ticketsMap[body._id]
    },
    setSortBy({commit}, order) {
      commit(SET_SORT_BY, order)
    }
  }
}
