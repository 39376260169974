<template lang="pug">
  div(data-qa="staff-members")
    screen-title(title="staff.title_shifts")
    b-field(grouped)
      b-field
        localized-datepicker(v-model="startOfWeek" :week-start-mode="true" :date-formatter="dateFormatter")
    .columns
      .column
        em-loading(:active="submitting" :is-full-page="false")
        table.table.is-fullwidth
          thead
            tr
              th(width="200") {{$t('staff.staff_heading')}}
              td.has-text-centered(v-for="{weekday, date} in weekdays" width="160") 
                strong {{$t(`working_hours.weekdays.${weekday}`)}}
                br
                small 
                  date-view(:value="date" format="DD MMM")
          tbody
            tr(v-for="member in staffMembers")
              th
                p.title.is-6 {{member.firstName}} {{member.lastName}} 
                p.subtitle.is-7 {{$t(`roles.${member.role}`)}}
              td(v-for="{weekday, date} in weekdays" @click="$rfHasPermission('STAFF_SHIFTS_EDIT') && editSchedule({member, weekday, date})") 
                ShiftDayView(v-bind="getActualShift({member, date, weekday})" )
        p.title.is-6
          span.icon.has-text-success
            i.mdi.mdi-square
          span(v-t="'working_hours.repeat_schedule_legend'")
          span.icon.has-text-warning
            i.mdi.mdi-square
          span(v-t="'working_hours.that_day_only_legend'")
</template>
<script>
import ShiftDayView from './ShiftDayView'
import StaffApi from '@/api/staff'
import {mapGetters, mapActions, mapState} from 'vuex'
import EditStaffShift from './EditStaffShift'
import dayjs from 'dayjs'
const WEEKDAYS = ['sun', 'mon', 'tue', 'wed', 'thu', 'fri', 'sat']
export default {
  name: 'Shifts',
  components: {
    ShiftDayView
  },
  data() {
    return {
      submitting: false,
      customShifts: [],
      loading: false,
      startOfWeek: dayjs()
        .startOf('week')
        .toDate()
    }
  },
  computed: {
    ...mapGetters('staff', ['activeServiceMembers', 'activeServiceMembersByMainLocation']),
    ...mapState('auth', ['user']),
    staffMembers() {
      if (this.$rfLocationScope() || this.$rfProfileScope()) {
        return this.activeServiceMembersByMainLocation(this.user.location)
      }
      return this.activeServiceMembers
    },
    weekdays() {
      let days = []
      for (let i = 0; i < 7; i++) {
        let day = dayjs(this.startOfWeek).add(i, 'days')

        days.push({
          weekday: WEEKDAYS[day.day()],
          date: day.toDate()
        })
      }
      return days
    }
  },
  created() {
    this.fetchData()
  },
  methods: {
    ...mapActions('staff', ['list', 'updateMember']),
    async fetchData() {
      this.loading = true
      try {
        await this.list(true)
        this.customShifts = await StaffApi.getShifts({weekStart: parseInt(dayjs(this.startOfWeek).format('YYYYMMDD'))})
      } catch (err) {
        this.$handleAPIError(err)
      }
      this.loading = false
    },
    getCustomShift(member, date) {
      return this.customShifts.find(shift => {
        return shift.user === member._id && shift.day === parseInt(dayjs(date).format('YYYYMMDD'))
      })
    },
    getActualShift({member, weekday, date}) {
      let custom = this.getCustomShift(member, date)

      return {
        shifts: member.shifts && member.shifts[weekday],
        location: (member.shifts && member.shifts[`${weekday}Location`]) || member.location,
        customShifts: custom && custom.shifts,
        customLocation: custom && custom.location,
        defaultLocation: member.location,
        custom: !!custom,
        staffId: member._id
      }
    },
    editSchedule({member, weekday, date}) {
      let {shifts, location, customShifts, customLocation} = this.getActualShift({member, weekday, date})
      this.$buefy.modal.open({
        parent: this,
        component: EditStaffShift,
        props: {
          value: {
            shifts,
            location,
            customShifts,
            customLocation
          },

          staffId: member._id,
          weekday,
          date
        },
        hasModalCard: true,
        events: {
          input: async ({shifts, location, customShifts, customLocation}) => {
            this.updateShift({member, weekday, date, shifts, location, customShifts, customLocation})
          }
        },
        canCancel: ['escape']
      })
    },
    async updateShift({member, weekday, date, shifts, location, customShifts, customLocation}) {
      this.submitting = true
      try {
        let data = {...member}
        data.shifts = data.shifts || {}
        data.shifts[weekday] = shifts
        data.shifts[`${weekday}Location`] = location

        await this.updateMember(data)
        if (customShifts) {
          await StaffApi.setStaffShift(member._id, {
            day: parseInt(dayjs(date).format('YYYYMMDD')),
            location: customLocation,
            shifts: customShifts
          })
        } else {
          await StaffApi.removeStaffShift(member._id, parseInt(dayjs(date).format('YYYYMMDD')))
        }

        await this.fetchData()

        this.$buefy.toast.open({
          message: this.$t('status.save_success'),
          type: 'is-success',
          queue: false
        })
      } catch (err) {
        this.$handleAPIError(err)
      }
      this.submitting = false
    },
    dateFormatter(date) {
      return `${dayjs(date).format('MMM D')} - ${dayjs(date)
        .add(6, 'days')
        .format('MMM D')}`
    }
  }
}
</script>
<style scoped>
table.table {
  background: transparent;
}
table.table td,
table.table th {
  vertical-align: middle;
}
table.table td {
  background: white;
  cursor: pointer;
}
</style>
