<template lang="pug">

div(v-if="customerPayments && customerPayments.length")
  .list-item.is-flex.is-align-items-center.p-3.is-clickable(@click="showDetails(payment)" v-for="payment in customerPayments")
    .is-flex-shrink-0.has-text-centered.p-3.mr-2(style="width: 96px")
      p.title.is-6.m-0(style="white-space: nowrap") 
        currency-view(:value="payment.amount")
    .is-flex-grow-1
      CardPaymentStatusIcon(:status="payment.status" is-tag)
      p
        date-view(:value="payment.created" format="dddd, D MMM YYYY,")
        span {{formatTs(payment)}}
      p.is-size-7 
        span {{payment.cardHolder}} 
        |  
        span.tag(v-if="payment.card") 
          span.icon
            i.mdi.mdi-credit-card-outline
          b *{{payment.card.slice(-4)}}
      p.subtitle.is-7 
section.section(v-else-if="!loading")
  .hero
    .hero-body
      h3.is-size-5.has-text-centered(v-t="'payment.customer_dont_have_payments'")
section.section.is-relative(v-else)
  .hero
    .hero-body
      em-loading(:active="true" :is-full-page="false")
    
</template>
<script>
import {mapActions, mapGetters, mapState} from 'vuex'
import formatter from '../../../shared/formatter'
import CardPaymentDetails from './CardPaymentDetails'
import CardPaymentStatusIcon from './CardPaymentStatusIcon'

export default {
  name: 'CustomerPayments',
  components: {
    CardPaymentStatusIcon
  },
  props: {
    customerId: {type: String, required: true}
  },
  data() {
    return {
      loading: false
    }
  },
  computed: {
    ...mapGetters('payments', ['customerPayments']),
    ...mapState('company', ['company'])
  },
  created() {
    return this.fetchData()
  },
  methods: {
    ...mapActions('payments', ['fetchCustomerPayments']),
    async fetchData() {
      this.loading = true
      try {
        await this.fetchCustomerPayments(this.customerId)
      } catch (err) {
        this.$handleAPIError(err)
      }
      this.loading = false
    },
    formatTs(payment) {
      return formatter.formatTime(payment.created, this.company.timeFormat)
    },
    showDetails(payment) {
      this.$buefy.modal.open({
        parent: this,
        component: CardPaymentDetails,
        hasModalCard: true,
        props: {
          paymentId: payment._id
        },
        canCancel: ['escape']
      })
    }
  }
}
</script>
<style scoped>
.list-item:hover {
  background-color: #f1f1f1;
}
</style>
