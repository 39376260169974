<template lang="pug">
div(v-if="customerAppointments && customerAppointments.length")
  AppointmentCard(v-for="appt in customerAppointments" :key="appt._id" @click="showDetails(appt)" :value="appt")
  
section.section(v-else-if="!loading")
  .hero
    .hero-body
      h3.is-size-5.has-text-centered(v-t="'appointments.customer_dont_have_appt'")
section.section.is-relative(v-else)
  .hero
    .hero-body
      em-loading(:active="true" :is-full-page="false")
  
</template>

<script>
import APPOINTMENT_STATUS from '../../../constants/appointmentStatus'
import AppointmentCard from './AppointmentCard'

import {mapActions, mapGetters, mapState} from 'vuex'
import AppointmentDetails from './AppointmentDetails'
import CreateEditAppointment from './CreateEditAppointment'

export default {
  name: 'CustomerAppointments',
  components: {
    AppointmentCard
  },
  props: {
    customerId: {type: String, required: true}
  },
  data() {
    return {
      loading: false,
      apptModal: undefined
    }
  },
  computed: {
    ...mapState('company', ['company']),
    ...mapGetters('appointments', ['customerAppointments'])
  },
  watch: {
    'customer.updated'() {
      return this.fetchAppointments()
    }
  },
  created() {
    return this.fetchAppointments()
  },
  methods: {
    ...mapActions('appointments', ['fetchCustomerAppointments']),
    async fetchAppointments() {
      this.loading = true
      try {
        await this.fetchCustomerAppointments(this.customerId)
      } catch (err) {
        this.$handleAPIError(err)
      }
      this.loading = false
    },
    showDetails(appointment) {
      if (
        appointment.status === APPOINTMENT_STATUS.COMPLETED ||
        appointment.status === APPOINTMENT_STATUS.NOSHOW ||
        appointment.status === APPOINTMENT_STATUS.INCOMPLETE ||
        appointment.status === APPOINTMENT_STATUS.CANCELED
      ) {
        this.$buefy.modal.open({
          parent: this.$parent,
          props: {appointmentId: appointment._id},
          component: AppointmentDetails,
          fullScreen: true,
          canCancel: ['escape', 'outside']
        })
      } else {
        if (this.apptModal) {
          this.apptModal.close()
        }
        this.apptModal = this.$buefy.modal.open({
          parent: this.$parent,
          props: {appointmentId: appointment._id, customerId: appointment.customer},
          component: CreateEditAppointment,
          customClass: !this.isMobile() ? 'half-modal' : '',
          animation: !this.isMobile() ? 'slide-right' : 'zoom-out',
          hasModalCard: this.isMobile(),
          fullScreen: true,
          canCancel: ['escape', 'outside']
        })
      }
    }
  }
}
</script>
<style scoped>
.list-item:hover {
  background-color: #f1f1f1;
}
</style>
