<template lang="pug">
div
  screen-title(title="services.hs_code_edit_title")
  .box
    em-loading(:active="loading" :is-full-page="false")
    .bodx.mb-5(v-for="cat in categoriesList" :key="cat._id")
      table.table.is-fullwidth
        thead
          tr
            th.is-size-5.is-borderless
              span {{cat.name}}
            td.is-size-5.is-borderless
              
          tr
            th(v-t="'services.service_name_heading'")
            th(width="25%" v-t="'services.service_hs_code_label'")
        tbody
          tr(v-for="service in servicesByCategoryId(cat._id)" :key="service._id")
            th
              span {{services[service._id].name}}
            td
              b-input(v-model="services[service._id].hsCode" :placeholder="$t('services.service_hs_code_placeholder')")
      hr.is-narrow
  .field
    .buttons
      action-button(title="actions.save" @click="submit" :loading="submitting" permission="SERVICES_EDIT" scope)
</template>
<script>
import cloneDeep from 'lodash/cloneDeep'
import {mapActions, mapGetters, mapState} from 'vuex'

export default {
  name: 'ServiceHSCodeEditor',
  data() {
    return {
      loading: false,
      submitting: false,
      services: {},
      categoriesList: []
    }
  },
  computed: {
    ...mapState('company', ['company']),
    ...mapState('services', ['servicesMap']),
    ...mapGetters('services', ['categories', 'servicesByCategoryId'])
  },
  async created() {
    await this.fetch()
  },
  methods: {
    ...mapActions('services', ['list', 'bulkUpdate']),
    async fetch() {
      this.loading = true
      try {
        await this.list(true)
        this.categoriesList = cloneDeep(this.categories)
        this.services = cloneDeep(this.servicesMap)
      } catch (err) {
        this.$handleAPIError(err)
      }
      this.loading = false
    },
    async submit() {
      if (this.submitting) {
        return
      }
      this.submitting = true
      try {
        await this.bulkUpdate({services: Object.values(this.services)})
        this.$buefy.toast.open({
          message: this.$t('status.save_success'),
          type: 'is-success'
        })
        await this.fetch()
      } catch (err) {
        this.$handleAPIError(err)
      }
      this.submitting = false
    }
  }
}
</script>
<style scoped>
table.table {
  background: transparent;
}
.is-borderless {
  border: none !important;
}
table.table th {
  cursor: default;
}
table.table td {
  background: white;
  cursor: text;
}
</style>
