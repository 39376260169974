<template lang="pug">
  div
    .modal-card
      header.modal-card-head
        p.modal-card-title 
          b(v-t="'gift.title'")
      section.modal-card-body
        customer-preview.mb-5(:customerId="customerId" )
        note.is-outlined.mb-5(v-if="customer && !customer.phone" type="is-warning") 
          span(v-html="$t('gift.no_phone_warning')")
        b-field(:label="$t('gift.type_label')")
          b-radio(v-model="type" name="type" native-value="points") {{$t('gift.type_points')}} 
          b-radio(v-model="type" name="type" native-value="coupon" v-if="$rfSubscribed(PRODUCTS.COUPONS)") {{$t('gift.type_coupon')}} 
        form(v-on:submit.prevent="confirm")
          b-field(v-if="type === 'points'" :label="$t('gift.points_label')" :type="errors.has('points') ? 'is-danger': ''" :message="errors.has('points') ? errors.first('points') : ''" )
            b-input(v-model.number="points" ref="pointsInput" icon="currency-usd" v-validate="'required|numeric'" name="points" :placeholder="$t('gift.points_placeholder')" data-vv-as="points" type="number")
          
          
          b-field(v-if="type === 'coupon'" :type="errors.has('coupon') ? 'is-danger': ''" :message="errors.has('coupon') ? errors.first('coupon') : ''" )
            CouponElement(v-model="coupon" v-validate="'required'" name="coupon" style="max-width:200px")
          b-field(:label="$t('gift.reason_label')")
            b-input(type="textarea" v-model="reason" :placeholder="$t('gift.reason_placeholder')")
      footer.modal-card-foot
        .buttons
          action-button(@click="confirm" :loading="submitting" title="actions.send" permission="REWARDS_GIFT_CREATE")
          button.button.is-outlined(type="button" @click="cancel" v-t="'actions.cancel'")
</template>

<script>
import {mapActions} from 'vuex'
import RewardsApi from '@/api/rewards'
import MessengerPreview from '../shared/MessengerPreview'
import CouponElement from '../coupon/CouponElement.vue'
import {PRODUCTS} from '../../../constants/products'

export default {
  name: 'GiftForm',
  components: {
    MessengerPreview,
    CouponElement
  },
  props: {
    customerId: {type: String, required: true}
  },
  data() {
    return {
      points: undefined,
      coupon: undefined,
      reason: undefined,
      submitting: false,
      type: 'points',

      PRODUCTS,
      customer: undefined
    }
  },
  async created() {
    this.customer = await this.fetchCustomer(this.customerId)
  },
  mounted() {
    this.$refs.pointsInput.focus()
  },
  methods: {
    ...mapActions('customerCoupon', ['sendGiftCoupon']),
    ...mapActions('customers', ['fetchCustomer']),
    async confirm() {
      let valid = await this.$validator.validateAll()
      if (!valid) {
        return
      }
      if (this.submitting) {
        return
      }
      this.$buefy.dialog.confirm({
        title: this.$t('gift.confirm_gift.title'),
        message: this.$t('gift.confirm_gift.message'),
        cancelText: this.$t('actions.cancel'),
        confirmText: this.$t('actions.confirm'),
        type: 'is-success',
        onConfirm: () => this.submit()
      })
    },
    cancel() {
      this.$emit('close')
    },
    async submit() {
      if (this.submitting) {
        return
      }
      this.submitting = true
      try {
        if (this.type === 'points') {
          await RewardsApi.addGiftPoints(this.customerId, {points: this.points, reason: this.reason})
          this.$buefy.toast.open({message: this.$t('gift.points_sent_success'), type: 'is-success', queue: false})
        } else {
          await this.sendGiftCoupon({customerId: this.customerId, coupon: this.coupon, reason: this.reason})
          this.$buefy.toast.open({message: this.$t('gift.coupon_sent_success'), type: 'is-success', queue: false})
        }

        this.$emit('close')
      } catch (err) {
        this.$handleAPIError(err)
      }
      this.submitting = false
    }
  }
}
</script>
