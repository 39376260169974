<template lang="pug">
  .columns
    .column
      screen-title(title="rewards_program.settings.title")
        action-button(title="actions.save" :loading="submitting" @click="submit" permission="REWARDS_MANAGE_SETTINGS" scope)
      .box
        h3.title.is-4(v-t="'rewards_program.settings.general_title'")
        b-field(:label="$t('rewards_program.settings.enabled_label')")
          b-switch(v-model="enabled" type="is-success") {{ enabled ? $t('misc.enabled') : $t('misc.disabled') }}
        b-field(:label="$t('rewards_program.settings.after_feedback')" v-if="enabled")
          b-switch(v-model="afterFeedback" type="is-success") {{ afterFeedback ? $t('misc.yes') : $t('misc.no') }}
        b-field(:label="$t('rewards_program.settings.collect_from_sale_type_label')" v-if="enabled")
          b-checkbox(v-for="type in SALE_ITEM_TYPES" v-model="collectFrom" :native-value="type" :key="type") {{$t(`sale_item_types.${type}`)}}
        
      .columns(v-if="enabled")
        .column.is-9
          .box
            .level
              .level-left
                .level-item
                  h3.title.is-4(v-t="'rewards_program.settings.membership_tiers'")
              .level-left
                .level-item
                  .field
                    .control
                      action-button.is-success(title="rewards_program.settings.add_tier" icon="plus" @click="addLevel" permission="REWARDS_MANAGE_SETTINGS" scope)
            .notification.is-danger.content(v-if="!levelsValid")
              p 
                strong(v-t="'rewards_program.settings.invalid_configuration_title'") 
              ul(v-html="$t('rewards_program.settings.invalid_configuration_subtitle')")
            b-table(:data="levels" hoverable @click="edit" striped clickable)
              
                b-table-column(field="level" :label="$t('rewards_program.settings.level_label')" width="20" v-slot="props") 
                  strong \#{{props.index + 1}}
                b-table-column(field="cardImage" :label="$t('rewards_program.settings.card_image_label')" width="150" v-slot="props") 
                  img.image.has-small-height(:src="props.row.card")
                b-table-column(field="title" :label="$t('rewards_program.settings.title_label')" width="200" v-slot="props")
                  strong {{props.row.title}}
                  span.has-text-danger(v-if="maxUpgrade && props.index >= maxUpgrade") 
                    span.icon
                      i.mdi.mdi-alert
                    span(v-t="'rewards_program.settings.manual_only'")
                
                b-table-column(field="rate" :label="displayType === 'spend' ? 'Reward' : $t('rewards_program.settings.rate_label')" v-slot="props") 
                  strong(:class="{'has-text-danger': props.index && (props.row.rate <= levels[props.index - 1].rate)}") 
                    span(v-if="displayType === 'spend'") 
                      currency-view(:value="1")
                      |  = {{props.row.rate}} points
                    span(v-if="displayType === 'percent'") {{props.row.rate | percentify}} of the sale
                b-table-column(field="threshold" :label="$t('rewards_program.settings.threshold_label')" numeric v-slot="props") 
                  strong(:class="{'has-text-danger': props.index && (props.row.threshold == levels[props.index - 1].threshold)}") 
                    currency-view(:value="props.row.threshold")
                b-table-column(field="actions" width="20" v-slot="props") 
                  .field.is-grouped
                    p.control
                      button.button(type="button", @click.stop="removeLevel(props.index)") 
                        b-icon(icon="delete" type="is-danger")
                        span(v-t="'actions.remove'")
            hr
            
            b-field(:label="$t('rewards_program.settings.display_type_label')", :type="errors.has('displayType') ? 'is-danger': ''" :message="errors.has('displayType') ? errors.first('displayType') : ''")
              b-field
                b-radio(v-model="displayType" v-validate="'required'" name="displayType" data-vv-as="rewards format" native-value="spend") {{ $t('rewards_program.settings.for_each_spent', {currency: formattedCurrencyUnit}) }}
                b-radio(v-model="displayType" v-validate="'required'" name="displayType" data-vv-as="rewards format" native-value="percent") {{ $t('rewards_program.settings.percentage_of_sale') }}

            b-field(:label="$t('rewards_program.settings.reward_type_label')", :type="errors.has('rewardType') ? 'is-danger': ''" :message="errors.has('rewardType') ? errors.first('rewardType') : ''")
                b-select(v-model="rewardType" icon="cash" v-validate="'required'" name="rewardType" :placeholder="$t('rewards_program.settings.reward_type_placeholder')" data-vv-as="reward type" expanded)
                    option(v-for="type in REWARD_UNIT_TYPES" :value="type") {{$t(`reward_unit_type.${type}`)}}
            
            b-field(:label="$t('rewards_program.settings.currency_points_label', {currency: formattedCurrencyUnit})", :type="errors.has('currencyWorth') ? 'is-danger': ''" :message="errors.has('currencyWorth') ? errors.first('currencyWorth') : ''" v-if="rewardType === 'points'")
                b-input(v-model="currencyWorth" icon="currency-usd" v-validate="'required|decimal:2|is_not:0|min_value:0'" name="currencyWorth" :placeholder="$t('rewards_program.settings.currency_points_placeholder')" data-vv-as="currency worth" expanded )

            b-field(:label="$t('rewards_program.settings.loyalty_program_name_label')" :message="$t('rewards_program.settings.loyalty_program_name_message')")
              b-input(v-model="name" icon="label" name="name" :placeholder="$t('rewards_program.settings.loyalty_program_name_placeholder')" expanded)
          .box
            b-field(:label="$t('rewards_program.settings.redeem_minimum_label')", :type="errors.has('redeemMin') ? 'is-danger': ''" :message="errors.has('redeemMin') ? errors.first('redeemMin') : ''")
                b-input(v-model="redeemMin" icon="arrow-expand-horizontal" v-validate="'required|numeric'" name="redeemMin" :placeholder="$t('rewards_program.settings.redeem_minimum_placeholder')" data-vv-as="redeem minimum" expanded type="number")

            b-field(:label="$t('rewards_program.settings.membership_notification_label')", :type="errors.has('upgradeThreshold') ? 'is-danger': ''" :message="errors.has('upgradeThreshold') ? errors.first('upgradeThreshold') : ''")
                b-input(v-model="upgradeThreshold" icon="arrow-expand-horizontal" v-validate="'required|numeric'" name="upgradeThreshold" :placeholder="$t('rewards_program.settings.membership_notification_placeholder')" data-vv-as="notification threshold" expanded type="number")

            b-field(:label="$t('rewards_program.settings.prompt_on_greeting_label')" :message="$t('rewards_program.settings.prompt_on_greeting_message')")
              b-switch(v-model="promptOnGreeting") {{ promptOnGreeting ? $t('rewards_program.settings.switch_actions.show') : $t('rewards_program.settings.switch_actions.hide') }}
          
          .box
            h3.title.is-4(v-t="'sms.templates_title'")
            .columns
              .column.is-3
                p.heading {{ $t('rewards_program.sms.rewards_collect') }}
                SMSTemplatePreview(templateKey="rewards:collect")
              .column.is-3
                p.heading {{ $t('rewards_program.sms.feedback_before_collect') }}
                SMSTemplatePreview(templateKey="surveys:sms_request_rewards")
              .column.is-3
                p.heading {{ $t('rewards_program.sms.rewards_redeem') }}
                SMSTemplatePreview(templateKey="rewards:redeem")
              .column.is-3
                p.heading {{ $t('rewards_program.sms.rewards_gift') }}
                SMSTemplatePreview(templateKey="rewards:gift")
                
          .box
            b-field(:label="$t('rewards_program.settings.promo_signup_label')" :message="$t('rewards_program.settings.promo_signup_message')")
              b-switch(v-model="promoSignupEnabled") {{ promoSignupEnabled ? $t('rewards_program.settings.switch_actions.active') : $t('rewards_program.settings.switch_actions.inactive') }}

            div(v-if="promoSignupEnabled")
              b-field(:label="$t('rewards_program.settings.promo_signup_bonus_label')", :type="errors.has('promoSignupBonus') ? 'is-danger': ''" :message="errors.has('promoSignupBonus') ? errors.first('promoSignupBonus') : ''")
                b-input(v-model="promoSignupBonus" icon="gift" v-validate="'required|numeric'" name="promoSignupBonus" :placeholder="$t('rewards_program.settings.promo_signup_bonus_placeholder')" data-vv-as="signup bonus" expanded type="number" )
              b-field(:label="$t('rewards_program.settings.signup_url_label')")
                b-field
                  b-input(:value="signupURL" id="signupUrlElement" icon="link"  @focus="$event.target.select()" readonly expanded)
                  p.control
                    button.button.is-light(@click="copyToClipboard('signupUrlElement')")
                      span.icon
                        i.mdi.mdi-content-copy
                      span {{$t('actions.copy')}}
        .column.is-3
          .box
            RewardsProgramExamples(v-if="currencyWorth" :levels="levels" :currencyWorth="currencyWorth")
    
      
      
      .field
        .control
          action-button(title="actions.save" :loading="submitting" @click="submit" permission="REWARDS_MANAGE_SETTINGS" scope)
</template>

<script>
import {mapState} from 'vuex'
import MembershipCreateEditForm from './MembershipCreateEditForm'
import RewardsProgramExamples from './RewardsProgramExamples'
import SMSTemplatePreview from '../shared/SMSTemplatePreview'
import CURRENCIES from '../../../constants/currencies'
import REWARD_UNIT_TYPES from '../../../constants/rewardUnit'
import {PRODUCTS} from '../../../constants/products'
import cloneDeep from 'lodash/cloneDeep'
import saleItemTypes from '../../../constants/saleItemTypes'
import {formatCurrency} from '../../../shared/saleHelper'

export default {
  name: 'RewardsProgramSettings',
  components: {
    MembershipCreateEditForm,
    RewardsProgramExamples,
    SMSTemplatePreview
  },
  data() {
    return {
      SALE_ITEM_TYPES: Object.values(saleItemTypes),
      submitting: false,
      upgradeThreshold: 0,
      redeemMin: 0,
      maxUpgrade: 0,
      currencies: undefined,
      currencyWorth: undefined,
      rewardType: undefined,
      REWARD_UNIT_TYPES,
      promoSignupBonus: undefined,
      promoSignupEnabled: undefined,
      promptOnGreeting: undefined,
      displayType: undefined,
      enabled: true,
      afterFeedback: true,
      name: null,
      levels: [],
      collectFrom: []
    }
  },
  computed: {
    ...mapState('company', ['company']),
    levelsValid() {
      if (!this.levels.length || this.levels[0].threshold !== 0) {
        return false
      }
      for (let i = 1; i < this.levels.length; i++) {
        let level = this.levels[i]
        if (level.rate <= this.levels[i - 1].rate || level.threshold === this.levels[i - 1].threshold) {
          return false
        }
      }
      return true
    },
    signupURL() {
      return `https://emly.am/b/${this.company.shortId || this.company._id}/account?promo=yes`
    },
    currency() {
      if (!this.company.currency) {
        return ''
      }
      return CURRENCIES[this.company.currency].value
    },
    formattedCurrencyUnit() {
      return formatCurrency(1, this.company.currency)
    }
  },
  async created() {
    this.$validator.pause()
    this.currencies = Object.values(CURRENCIES)

    let membership = cloneDeep(this.company.membership)
    this.levels = membership.titles.map((value, idx) => {
      return {
        title: value,
        card: membership.cards[idx],
        threshold: membership.limits[idx],
        rate: membership.pointRates[idx]
      }
    })
    this.collectFrom = membership.collectFrom
    this.currencyWorth = membership.currencyWorth

    this.upgradeThreshold = membership.upgradeThreshold
    this.redeemMin = membership.redeemMin
    this.maxUpgrade = membership.maxUpgrade
    this.name = membership.name
    this.rewardType = membership.rewardType
    this.promoSignupBonus = membership.promoSignupBonus
    this.promoSignupEnabled = !!membership.promoSignupEnabled
    this.promptOnGreeting = !!membership.promptOnGreeting
    this.displayType = membership.displayType
    this.afterFeedback = membership.afterFeedback

    this.enabled = this.$rfEnabled(PRODUCTS.REWARDS_PROGRAM)
    this.$nextTick(() => {
      this.$validator.resume()
    })
  },
  methods: {
    addLevel() {
      this.$buefy.modal.open({
        parent: this,
        width: 960,
        hasModalCard: true,
        component: MembershipCreateEditForm,
        canCancel: ['outside', 'escape'],
        props: {data: {}},
        events: {
          success: ({level}) => {
            this.updateLevels(level)
          }
        }
      })
    },
    edit(level) {
      this.$buefy.modal.open({
        parent: this,
        width: 960,
        hasModalCard: true,
        component: MembershipCreateEditForm,
        canCancel: ['outside', 'escape'],
        props: {data: {index: this.levels.indexOf(level), ...level}, displayType: this.displayType},
        events: {
          success: ({level, index}) => {
            this.updateLevels(level, index)
          }
        }
      })
    },
    removeLevel(idx) {
      this.levels.splice(idx, 1)
    },
    updateLevels(level, index) {
      if (index !== undefined) {
        Object.assign(this.levels[index], level)
      } else {
        this.levels.push(level)
      }

      this.levels.sort((a, b) => {
        return a.threshold > b.threshold
      })
    },
    copyToClipboard(element) {
      document.getElementById(element).select()
      document.execCommand('copy')
      this.$buefy.toast.open({
        message: this.$t('rewards_program.settings.copied_message'),
        type: 'is-success',
        queue: false
      })
    },

    async submit() {
      if (!this.levelsValid) {
        return
      }
      const valid = await this.$validator.validateAll()
      if (!valid) {
        return
      }
      this.submitting = true
      const data = {
        membership: {
          titles: this.levels.map(l => l.title),
          limits: this.levels.map(l => l.threshold),
          pointRates: this.levels.map(l => l.rate),
          cards: this.levels.map(l => l.card),
          collectFrom: this.collectFrom,
          upgradeThreshold: this.upgradeThreshold,
          redeemMin: this.redeemMin,
          name: this.name,
          afterFeedback: this.afterFeedback,
          maxUpgrade: this.maxUpgrade,
          displayType: this.displayType,
          currencyWorth: this.rewardType === 'currency' ? 1 : this.currencyWorth,
          rewardType: this.rewardType,
          promoSignupBonus: this.promoSignupBonus,
          promoSignupEnabled: this.promoSignupEnabled,
          promptOnGreeting: this.promptOnGreeting
        },
        apps: Object.assign({...this.company.apps}, {[PRODUCTS.REWARDS_PROGRAM]: this.enabled}),
        _updateType: 'loyalty'
      }
      try {
        await this.$store.dispatch('company/update', data)
        this.$buefy.toast.open({message: this.$t('status.save_success'), type: 'is-success', queue: false})
      } catch (error) {
        this.$handleAPIError(error)
      }
      this.submitting = false
    }
  }
}
</script>

<style scoped>
.has-small-width {
  max-width: 80px;
}
table.is-vertically-middle td {
  vertical-align: middle;
}
</style>
