<template lang="pug">
  .modal-card
    header.modal-card-head 
      p.modal-card-title
        b(v-t="'customer_select.modal_title'")
      div
        a.button.is-white.is-small(@click="$emit('close')")
          span.icon
            i.mdi.mdi-close.mdi-24px
    section.modal-card-body.is-paddingless.is-flex
      CustomerSelect(v-model="customer" @input="onInput" no-label)
    footer.modal-card-foot
      .buttons
        button.button(@click="$emit('close')" v-t="'actions.cancel'")   
</template>

<script>
import CustomerSelect from './CustomerSelect'

export default {
  name: 'CustomerSelectModalWrapper',
  components: {
    CustomerSelect
  },
  props: {
    value: {type: Object, default: undefined}
  },
  data() {
    return {
      customer: null
    }
  },
  watch: {
    value(val) {
      this.customer = val
    }
  },
  created() {
    this.customer = this.value
  },
  methods: {
    onInput(customer) {
      this.$emit('close')
      this.$emit('input', customer)
    }
  }
}
</script>
