<script>
import dayjs from 'dayjs'
import {mapGetters, mapState} from 'vuex'
import AppointmentsApi from '@/api/appointments'
import StaffApi from '@/api/staff'

export default {
  computed: {
    ...mapState('staff', ['staffMap']),
    ...mapGetters('services', ['serviceById'])
  },
  methods: {
    validateStaffLocation({staffId, locationId}) {
      let staff = this.staffMap[staffId]
      return staff.location === locationId || (staff.locations && staff.locations.includes(locationId))
    },
    validateServiceLocation({serviceId, locationId}) {
      return this.serviceById(serviceId).locations.includes(locationId)
    },
    validateStaffService({staffId, serviceId}) {
      let serviceStaff = this.serviceById(serviceId).staff
      if (!serviceStaff || !serviceStaff.length) {
        return true
      }
      return serviceStaff.includes(staffId)
    },
    async validateStaffWorkingHours({staffId, date, start, locationId, duration, blockedTimeAfter}) {
      try {
        const day = dayjs(date).format('YYYYMMDD')
        let ranges = await StaffApi.getShiftRanges({
          staff: staffId,
          location: locationId,
          from: day,
          to: day
        })
        let range =
          ranges[staffId] &&
          ranges[staffId][day].find(
            range => range.start <= start && range.end >= start + duration + (blockedTimeAfter || 0)
          )
        if (!range) {
          return ranges[staffId][day]
        }
      } catch (err) {
        this.$handleAPIError(err)
      }
    },
    async findStaffOverlap({date, staffId, itemId, duration, start, blockedTimeAfter, locationId}) {
      try {
        const day = dayjs(date).format('YYYYMMDD')
        let ranges = await AppointmentsApi.getBookedSlotsByStaff({
          location: locationId,
          staff: staffId,
          from: day,
          to: dayjs(date)
            .add(1, 'day')
            .format('YYYYMMDD')
        })
        if (!ranges[staffId] || !ranges[staffId][day]) {
          return
        }

        const overlap = ranges[staffId][day].find(range => {
          let itemEnd = start + duration + (blockedTimeAfter || 0)
          return (
            range._id !== itemId &&
            ((range.start <= start && range.end > start) ||
              (range.start < itemEnd && range.end >= itemEnd) ||
              (range.start >= start && range.end <= itemEnd))
          )
        })
        return overlap
      } catch (err) {
        this.$handleAPIError(err)
      }
    },
    async findResourceOverlap({date, resourceId, itemId, duration, start, blockedTimeAfter, locationId}) {
      try {
        const day = dayjs(date).format('YYYYMMDD')
        let ranges = await AppointmentsApi.getBookedSlotsByResource({
          location: locationId,
          resource: resourceId,
          from: day,
          to: dayjs(date)
            .add(1, 'day')
            .format('YYYYMMDD')
        })
        if (!ranges[resourceId] || !ranges[resourceId][day]) {
          return
        }

        let overlap = ranges[resourceId][day].find(range => {
          let itemEnd = start + duration + (blockedTimeAfter || 0)
          return (
            range._id !== itemId &&
            ((range.start <= start && range.end > start) ||
              (range.start < itemEnd && range.end >= itemEnd) ||
              (range.start >= start && range.end <= itemEnd))
          )
        })
        return overlap
      } catch (err) {
        this.$handleAPIError(err)
      }
    }
  }
}
</script>
