<template lang="pug">
.is-flex
  .pt-6.is-flex-shrink-0.secondary-menu.is-hidden-touch
    .menu
      ul.menu-list
        li(v-for="item in visibleItems" :class="{'is-active': $route.path === item.path}" :data-qa="item['data-qa']")
          template(v-if="item.separator") 
            .separator 
              hr.is-narrow
          template(v-else)
            router-link(:to='item.path' :title="$t(item.title)")
              span.icon
                i.mdi(aria-hidden="true" :class="`mdi-${item.icon}`")
              span(v-t="item.title")
            transition(name="slide")
              ul(v-if="item.nested && $route.path.includes(item.path) && (!$route.meta.product || $rfSubscribed($route.meta.product))")
                li(v-for="nestedItem in item.nested" :class="{'is-active': $route.path === nestedItem.path}" :data-qa="nestedItem['data-qa']")
                  router-link(:to='nestedItem.path')
                    span.icon
                      i.mdi(aria-hidden="true" :class="`mdi-${nestedItem.icon}`")
                    span(v-t="nestedItem.title")
  .p-2.is-hidden-desktop.is-flex-grow-1.has-border-bottom(style="background: white;")
    b-dropdown(aria-role="list" expanded)
      template(#trigger="{active}")
        a.button.is-fullwidth.is-medium(style="justify-content:space-between;background:transparent;border:none")
          b {{$t($route.meta.pageTitle)}}
          span.icon
            i.mdi.mdi-menu 
            //(:class="active ? 'mdi-menu-up' : 'mdi-menu-down'")
      b-dropdown-item(aria-role="listitem" :has-link="true" v-for="item in linksOnly" :key="item.path" :class="{'is-active': $route.path === item.path}" :data-qa="item['data-qa']")
        router-link(:to='item.path')
          span.icon
            i.mdi(aria-hidden="true" :class="`mdi-${item.icon}`")
          span(v-t="item.title")

    //.dropdown(v-else)
      .dropdown-trigger
        button.button menu
      .dropdown-menu
        .dropdown-content

</template>
<script>
import {mapState} from 'vuex'
export default {
  props: {
    items: {type: Array, required: true}
  },
  computed: {
    ...mapState('auth', ['user']),

    visibleItems() {
      return this.items.filter(item => {
        return (
          (!item.permission || this.$rfHasPermission(item.permission)) &&
          (!item.product || this.$rfSubscribed(item.product)) &&
          !item.hidden
        )
      })
    },
    linksOnly() {
      return this.visibleItems.filter(i => !i.separator)
    }
  }
}
</script>

<style lang="scss" scoped>
.menu-list {
  a {
    border-radius: 9999px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
.secondary-menu {
  width: 280px;
  transition: width, ease-in-out 200ms;
  overflow-y: scroll;
  padding-left: 1rem !important;
  padding-right: 1rem !important;
}

@media screen and (max-width: 1280px) {
  .secondary-menu {
    width: 200px;
    overflow-y: scroll;
    padding-left: 0.75rem !important;
    padding-right: 0.75rem !important;
  }
}
@media screen and (max-width: 1024px) {
  .secondary-menu {
    width: 160px;
    overflow-y: scroll;
    padding-left: 0.5rem !important;
    padding-right: 0.5rem !important;
  }
}
</style>
