<template lang="pug">
  span {{formattedValue}}
</template>

<script>
import saleHelper from '../../../shared/saleHelper'
export default {
  name: 'RewardsViewNoState',
  props: {
    value: {type: Number, default: undefined},
    currency: {type: String, required: true},
    rewardType: {type: String, required: true}
  },
  computed: {
    formattedValue() {
      return saleHelper.formatPoints(this.value, this.rewardType, this.currency)
    }
  }
}
</script>
