<template lang="pug">
div
  .list-item.p-0.is-clickable(v-for="location in activeLocations" :key="location._id" @click="select(location)") 
    LocationCard.is-shadowless(:location="location" size="small")

</template>
<script>
import {mapGetters} from 'vuex'
import LocationCard from './LocationCard'
export default {
  components: {
    LocationCard
  },
  computed: {
    ...mapGetters('location', ['activeLocations'])
  },
  methods: {
    select(location) {
      this.$emit('input', location._id)
    }
  }
}
</script>
