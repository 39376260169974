import Navbar from '@/components/Navbar'
import Signout from '@/components/account/Signout'
const Tabs = () => import(/* webpackChunkName: "account" */ '@/components/account/Tabs')
const Container = () => import(/* webpackChunkName: "account" */ '@/components/account/Container')
const PersonalSettings = () => import(/* webpackChunkName: "account" */ '@/components/account/PersonalSettings')
const SecuritySettings = () => import(/* webpackChunkName: "account" */ '@/components/account/SecuritySettings')
const SigninForm = () => import(/* webpackChunkName: "account" */ '@/components/account/signup/SigninForm')
// const Signup = () => import(/* webpackChunkName: "account" */ '@/components/account/signup/Signup')

const UserSignup = () => import(/* webpackChunkName: "account" */ '@/components/account/signup/UserSignup')
const BusinessSetup = () => import(/* webpackChunkName: "account" */ '@/components/account/signup/BusinessSetup')

const AccountSetup = () => import(/* webpackChunkName: "account" */ '@/components/account/AccountSetup')
const EmailVerify = () => import(/* webpackChunkName: "account" */ '@/components/account/EmailVerify')
const ResetPassword = () => import(/* webpackChunkName: "account" */ '@/components/account/ResetPassword')

const OnboardingWizard = () => import(/* webpackChunkName: "onboarding_wizard" */ '@/components/onboarding/Wizard')

export default [
  {
    path: '/signin',
    name: 'Signin',
    components: {
      default: SigninForm
    }
  },
  {
    path: '/signout',
    name: 'Signout',
    components: {
      default: Signout
    },
    meta: {
      requiresAuth: true,
      cleanview: true
    }
  },
  {
    path: '/signup',
    name: 'UserSignup',
    components: {
      default: UserSignup
    },
    props: {default: true}
  },
  {
    path: '/signup/business',
    name: 'BusinessSetup',
    components: {
      default: BusinessSetup
    },
    meta: {
      requiresAuth: true,
      cleanview: true
    }
  },
  {
    path: '/join/:invitationkey',
    name: 'AccountSetup',
    components: {
      default: AccountSetup
    }
  },
  {
    path: '/reset/:key',
    name: 'ResetPassword',
    components: {
      default: ResetPassword
    }
  },
  {
    path: '/verify/:userId/:verificationKey',
    name: 'EmailVerify',
    props: {
      default: true
    },
    components: {
      default: EmailVerify
    },
    meta: {
      doNotRedirect: true
    }
  },
  {
    path: '/onboarding',
    name: 'Onboarding',
    components: {
      default: OnboardingWizard
    },
    meta: {
      requiresAuth: true,
      cleanview: true
    }
  },

  {
    path: '/account',
    name: 'Account',
    meta: {
      requiresAuth: true,
      title: 'Account'
    },
    components: {
      default: Container,
      navbar: Navbar,
      tabsmenu: Tabs
    },
    children: [
      {
        path: 'personal',
        component: PersonalSettings,
        meta: {
          title: 'account.tabs.personal'
        }
      },
      {
        path: 'security',
        component: SecuritySettings,
        meta: {
          title: 'account.tabs.security'
        }
      }
    ]
  }
]
