<template lang="pug">
.is-flex.is-flex-direction-column.is-align-items-stretch
  .p-4.is-flex-shrink-0.has-background-light
    form(v-on:submit.prevent="search")
      button.is-hidden(type="submit")
      b-field(expanded)
        b-input(v-model="query" size="is-medium" ref="search" @input="search" expanded icon="magnify" :placeholder="$t('services.search_placeholder')" icon-right="close-circle-outline"
          icon-right-clickable
          @icon-right-click="clearSearch")

  .is-flex-grow-1(style="overflow: auto")
    .p-5(v-if="!categories.length" style="min-height: 200px")
      p.has-text-centered(v-t="'services.selector.no_results'")
    div(v-if="!!query") 
      .list-item.py-4.is-flex.is-flex-direction-row.is-align-items-center.is-clickable(v-for="service in filteredServices" :key="service.pricingOptions._id" @click="select(service)") 
        span.icon.is-medium.mr-2
          i.mdi.mdi-24px.mdi-circle-slice-8.has-text-primary(v-if="selected.includes(service)")
          i.mdi.mdi-24px.mdi-checkbox-blank-circle-outline(v-else)
        .is-flex-grow-1
          p.title.is-5
            service-name-view(:value="{service: service._id, option: service.pricingOptions._id}")
          p.subtitle.is-6
            duration-view(:value="service.pricingOptions.duration")
        strong.is-size-5.is-flex-shrink-0.has-text-right
          p.heading.mb-0(v-if="getPrice(service.pricingOptions).isFrom" v-t="'misc.from'")
          currency-view(:value="getPrice(service.pricingOptions).price")
          //template(v-if="service.pricingOptions.maxPrice !== undefined || service.pricingOptions.priceUpto !== undefined")
              |  -  
              currency-view(:value="service.pricingOptions.maxPrice || service.pricingOptions.priceUpto" )
    b-collapse.list-item.p-0( v-for="cat in categories" :key="cat._id" animation="slide" v-else :open="false") 
      template(#trigger="props")
        .card-header.is-shadowless.is-marginless.p-2(role="button" aria-controls="contentIdForA11y3" :aria-expanded="props.open")
          p.card-header-title.is-size-5(v-if="cat._id") 
            category-name-view(:value="cat._id")
          a.card-header-icon
            span.icon
              i.mdi(:class="props.open ? 'mdi-chevron-down' : 'mdi-chevron-up'")
      .list-item.py-4.is-flex.is-flex-direction-row.is-align-items-center.is-clickable(v-for="service in cat.services" :key="service.pricingOptions._id" @click="select(service)") 
          span.icon.is-medium.mr-2
            i.mdi.mdi-24px.mdi-circle-slice-8.has-text-primary(v-if="selected.includes(service)")
            i.mdi.mdi-24px.mdi-checkbox-blank-circle-outline(v-else)
          .is-flex-grow-1
            p.title.is-5
              service-name-view(:value="{service: service._id, option: service.pricingOptions._id}")
            p.subtitle.is-6
              duration-view(:value="service.pricingOptions.duration")
          strong.is-size-5.is-flex-shrink-0.has-text-right
            p.heading.mb-0(v-if="getPrice(service.pricingOptions).isFrom" v-t="'misc.from'")
            currency-view(:value="getPrice(service.pricingOptions).price")
            //template(v-if="service.pricingOptions.maxPrice !== undefined || service.pricingOptions.priceUpto !== undefined")
                |  -  
                currency-view(:value="service.pricingOptions.maxPrice || service.pricingOptions.priceUpto" )
    
</template>
<script>
import {mapActions, mapGetters, mapState} from 'vuex'
import debounce from 'lodash/debounce'
import EXTRA_TIME_TYPES from '../../../constants/serviceExtraTimeTypes'
import saleHelper from '../../../shared/saleHelper'
export default {
  name: 'ServiceListSelect',
  props: {
    locationId: {type: String, default: undefined},
    multiselect: {type: Boolean, default: false},
    quickSelect: {type: Boolean, default: false}
  },
  data() {
    return {
      selected: [],
      query: undefined,
      filteredServices: []
    }
  },
  computed: {
    ...mapState('auth', ['user']),
    ...mapState('services', ['unwindedServices']),
    ...mapGetters('services', ['nonEmptyCategories']),
    services() {
      return this.unwindedServices.filter(
        s =>
          (!this.locationId || s.locations.includes(this.locationId)) &&
          (!this.$rfProfileScope() || s.staff.includes(this.user._id))
      )
    },
    categories() {
      if (this.filteredServices === this.services) {
        return this.nonEmptyCategories
          .map(c => {
            return {...c, services: this.filteredServices.filter(s => s.category === c._id)}
          })
          .filter(c => c.services.length)
      }
      return [{_id: 'searchres', service: this.filteredServices}]
    }
  },
  created() {
    this.filteredServices = this.services
  },
  mounted() {
    this.$refs.search.focus()
  },
  methods: {
    ...mapActions('services', ['searchService']),
    select(service) {
      if (this.multiselect) {
        if (this.selected.includes(service)) {
          this.selected.splice(this.selected.indexOf(service), 1)
        } else {
          this.selected.push(service)
        }
      } else {
        this.selected = [service]
      }
      let res = this.selected.map(s => {
        return {
          serviceId: s._id,
          optionId: s.pricingOptions._id,
          price: s.pricingOptions.price,
          priceUpto: s.pricingOptions.priceUpto,
          duration: s.pricingOptions.duration,
          override: s.pricingOptions.override,
          blockedTimeAfter:
            (s.extraTime && s.extraTime.afterType === EXTRA_TIME_TYPES.BLOCK && (s.extraTime.after || 0)) || 0,
          processingTimeAfter:
            (s.extraTime && s.extraTime.afterType === EXTRA_TIME_TYPES.PROCESSING && (s.extraTime.after || 0)) || 0
        }
      })

      this.$emit('input', this.multiselect ? res : res[0])
    },

    search: debounce(async function() {
      if (!this.query || !this.query.length) {
        this.filteredServices = this.services
        return
      }
      this.filteredServices = await this.searchService({
        query: this.query,
        locationId: this.locationId,
        staffId: this.$rfProfileScope() ? this.user._id : undefined
      })
    }, 200),
    clearSearch() {
      this.query = undefined
    },
    getPrice(option) {
      const {minPrice, maxPrice} = saleHelper.getPricingOption(option)
      return {price: minPrice, isFrom: maxPrice !== undefined && minPrice !== maxPrice}
    }
  }
}
</script>
