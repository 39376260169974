<template lang="pug">
.modal-card
  header.modal-card-head.px-4.py-3
    p.modal-card-title(data-qa="modal-header") 
      b(v-t="'appointment.abort_title'")
    div
      a.button.is-white.is-small(@click="$emit('close')")
        span.icon
          i.mdi.mdi-close.mdi-24px
  section.modal-card-body
    h3.title.is-6(v-t="'appointment.abort_reason_heading'")
    select-box(:data="options" v-model="type")
  footer.modal-card-foot
    .buttons 
      action-button.is-danger(title="actions.confirm" @click="submit" :loading="loading")
      button.button(@click="$emit('close')") {{ $t('actions.close') }}
  //b-dropdown.is-size-5(append-to-body)
    template(#trigger)
      button.button.is-danger.is-outlined
        span.icon
          i.mdi.mdi-dots-horizontal.mdi-24px
    b-dropdown-item.is-size-5.has-text-danger(@click="markAsNoshow")
        span.icon
          i.mdi.mdi-calendar-remove-outline
        span(v-t="'appointment.actions.noshow'")
    b-dropdown-item.is-size-5.has-text-danger(@click="cancelSale")
        span.icon
          i.mdi.mdi-minus-circle-outline
        span(v-t="'appointment.actions.cancel'" data-qa="action-status-cancel")
</template>

<script>
import {mapActions} from 'vuex'
import APPOINTMENT_STATUS from '../../../constants/appointmentStatus'
import CardPaymentDetails from '../sale/CardPaymentDetails'

export default {
  name: 'AppointmentAbortActions',
  props: {
    appointmentId: {type: String, required: true},
    paymentId: {type: String, default: undefined}
  },
  data() {
    return {
      loading: false,
      type: APPOINTMENT_STATUS.CANCELED
    }
  },
  computed: {
    options() {
      return [
        {
          title: this.$t('appointment.actions.cancel'),
          description: this.$t('appointment.cancel_description'),
          value: APPOINTMENT_STATUS.CANCELED
        },
        {
          title: this.$t('appointment.actions.noshow'),
          description: this.$t('appointment.noshow_description'),
          value: APPOINTMENT_STATUS.NOSHOW
        }
      ]
    }
  },
  methods: {
    ...mapActions('appointments', ['terminateAppointment']),
    submit() {
      if (this.type === APPOINTMENT_STATUS.CANCELED) {
        this.cancelSale()
      } else if (this.type === APPOINTMENT_STATUS.NOSHOW) {
        this.markAsNoshow()
      }
    },
    async confirmTerminate(status) {
      if (!this.appointmentId || !status || this.loading) {
        return
      }
      this.loading = true
      try {
        await this.terminateAppointment({appointmentId: this.appointmentId, data: {status}})
        this.$buefy.toast.open({
          message: this.$t(`appointment.update_success.${status}`),
          type: 'is-success',
          queue: false
        })
        if (this.paymentId) {
          this.$buefy.modal.open({
            parent: this.$parent,
            component: CardPaymentDetails,
            hasModalCard: true,
            props: {
              terminationStatus: status,
              paymentId: this.paymentId
            },
            canCancel: []
          })
        }

        this.$emit('terminated')
        this.$emit('close')
      } catch (err) {
        this.$handleAPIError(err)
      }
      this.loading = false
    },
    markAsNoshow() {
      this.$buefy.dialog.confirm({
        message: this.$t('appointment.noshow_confirm.body'),
        title: this.$t('appointment.noshow_confirm.title'),
        cancelText: this.$t('appointment.noshow_confirm.cancel'),
        confirmText: this.$t('appointment.noshow_confirm.action'),
        type: 'is-danger',
        onConfirm: () => {
          this.confirmTerminate(APPOINTMENT_STATUS.NOSHOW)
        }
      })
    },
    cancelSale() {
      this.$buefy.dialog.confirm({
        message: this.$t('appointment.cancel_confirm.body'),
        title: this.$t('appointment.cancel_confirm.title'),
        cancelText: this.$t('misc.no'),
        confirmText: this.$t('misc.yes'),
        type: 'is-danger',
        onConfirm: () => {
          this.confirmTerminate(APPOINTMENT_STATUS.CANCELED)
        }
      })
    }
  }
}
</script>
