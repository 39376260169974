import Vue from 'vue'

export default {
  search({type, customer, location, sortBy, sortDir, page, perPage}) {
    return Vue.http
      .get('memberships', {
        params: {
          type,
          customer,
          location,
          sortBy,
          sortDir,
          page,
          perPage
        }
      })
      .then(res => res.body)
  },
  getCustomerMemberships(customerId) {
    return Vue.http
      .get('memberships', {
        params: {
          customer: customerId,
          sortBy: 'expire',
          sortDir: 'asc',
          active: true,
          perPage: 1000
        }
      })
      .then(res => res.body.data)
  },
  getMembershipPackages() {
    return Vue.http.get('memberships/packages').then(res => res.body)
  },
  updateMembershipPackage(id, data) {
    return Vue.http.put(`memberships/packages/${id}`, data).then(res => res.body)
  },
  createMembershipPackage(data) {
    return Vue.http.post('memberships/packages', data).then(res => res.body)
  },
  archiveMembershipPackage(id) {
    return Vue.http.delete(`memberships/packages/${id}`)
  },
  getMembershipById(id) {
    return Vue.http.get(`memberships/${id}`).then(res => res.body)
  },
  getMembershipPackageById(id) {
    return Vue.http.get(`memberships/packages/${id}`).then(res => res.body)
  }
}
