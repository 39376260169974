<template lang="pug">
  span.tag.is-rounded(:class="statusClass") {{status}}
</template>

<script>
export default {
  props: {
    status: {type: String, required: true}
  },
  computed: {
    statusClass() {
      if (this.status === 'paid') {
        return 'is-success'
      }
      if (this.status === 'draft') {
        return 'is-warning'
      }
      if (this.status === 'open') {
        return 'is-info'
      }
      return 'is-danger'
    }
  }
}
</script>
