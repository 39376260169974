<template lang="pug">
  span.is-inline-flex.is-align-items-center.is-clickable.has-tiny-border(v-if="payment" @click="showDetails" :class="payment.status !== 'success' ? 'is-danger': ''")  
    span.icon.has-text-danger(v-if="payment.status !== 'success'")
      i.mdi.mdi-alert-circle-outline
    span.icon.mr-2
      img(src="@/assets/cc.png" style="vertical-align: 10px")
    b
      currency-view(:value="payment.amount")
    div.ml-1(v-if="!payment.sale && showStatus") 
      small.has-text-primary(v-t="'payment_types.deposit'") 
</template>

<script>
import {mapActions} from 'vuex'
import CardPaymentDetails from './CardPaymentDetails'

export default {
  name: 'PaymentView',
  props: {
    value: {type: String, required: true},
    showStatus: {type: Boolean, default: false}
  },
  data() {
    return {
      payment: undefined
    }
  },
  watch: {
    value() {
      this.fetchData()
    }
  },
  async created() {
    await this.fetchData()
  },
  methods: {
    ...mapActions('payments', ['fetchPaymentById']),
    async fetchData() {
      this.loading = true
      try {
        this.payment = await this.fetchPaymentById(this.value)
      } catch (err) {
        this.$handleAPIError(err)
      }
      this.loading = false
    },
    showDetails() {
      this.$buefy.modal.open({
        parent: this,
        component: CardPaymentDetails,
        hasModalCard: true,
        fullScreen: this.isMobile(),
        props: {
          paymentId: this.value
        },
        canCancel: ['escape']
      })
    }
  }
}
</script>
