<template lang="pug">
  section.section
    screen-title(title="surveys.dashboard.title")
    .level
      .level-left
        .level-item 
          div 
            PeriodSelector(v-model="period")
        .level-item 
          div 
            LocationSelector(v-model="location")
    
    h2.title.is-4(v-t="'surveys.nps_title'")
    p.subtitle.is-6.mb-1(v-t="'surveys.nps_subtitle'")
    .box
      NPSScore(:period="period" :location="location" position="left")
    hr  
    h2.title.is-4(v-t="'surveys.customer_feedback_title'")
    p.subtitle.is-6(v-t="'surveys.customer_feedback_subtitle'")
    .tile.is-ancestor
      .tile.is-parent
        .tile.is-child.box 
          AverageFeedback(:period="period" :location="location")
      .tile.is-parent
        .tile.is-child.box 
          SatisfactionRate(:period="period" :location="location")
      .tile.is-parent
        .tile.is-child.box
          TopStaff(:period="period" :location="location")
      .tile.is-parent
        .tile.is-child.box
          TopServices(:period="period" :location="location")
    
    h3.title.is-4(v-t="'surveys.feedback_daily_header'")
    FeedbackDistribution(:period="period" :location="location")
    hr
    h2.title.is-4(v-t="'surveys.worst_vs_best_header'")
    p.subtitle.is-6(v-t="'surveys.worst_vs_best_subtitle'")
    .tile.is-ancestor
      .tile.is-parent
        .tile.is-child.box
          h3.title.is-6.is-uppercase.has-text-darkgrey(v-t="'surveys.worst_vs_best_needs_improvement'")
          h5.subtitle.is-7.mb-1 {{period && period.display}}
          SurveysBarChart(survey="toimprove" type="count" :period="period" :location="location" :color="CHART_COLORS.RED")
      .tile.is-parent
        .tile.is-child.box
          h3.title.is-6.is-uppercase.has-text-darkgrey(v-t="'surveys.worst_vs_best_liked_most'")
          h5.subtitle.is-7.mb-1 {{period && period.display}}
          SurveysBarChart(survey="mostliked" type="count" :period="period" :location="location" :color="CHART_COLORS.GREEN")
    hr
    h2.title.is-4(v-t="'surveys.acquisition_channel_title'")
    p.subtitle.is-6(v-t="'surveys.acquisition_channel_subtitle'")
    .tile.is-ancestor
      .tile.is-parent
        .tile.is-child.box
          h3.title.is-6.is-uppercase.has-text-darkgrey(v-t="'surveys.acquisition_clients_per_channel'")
          h5.subtitle.is-7.mb-1 {{period && period.display}}
          SurveysBarChart(survey="channel" type="count" :period="period" :location="location") 
      .tile.is-parent
        .tile.is-child.box
          h3.title.is-6.is-uppercase.has-text-darkgrey(v-t="'surveys.acquisition_avg_sale_per_channel'")
          h5.subtitle.is-7.mb-1 {{period && period.display}}
          SurveysPieChart(survey="channel" type="avgSale" :period="period" :location="location")    
</template>

<script>
import PeriodSelector from '../helpers/PeriodSelector'
import LocationSelector from '../helpers/LocationSelector'
import NPSScore from './analytics/NPSScore'
import AverageFeedback from './analytics/AverageFeedback'
import SatisfactionRate from './analytics/SatisfactionRate'
import TopStaff from './analytics/TopStaff'
import TopServices from './analytics/TopServices'
import FeedbackDistribution from './analytics/FeedbackDistribution'
import SurveysBarChart from './analytics/SurveysBarChart'
import SurveysPieChart from './analytics/SurveysPieChart'
import CHART_COLORS from '../helpers/chartColors'
export default {
  name: 'SurveysDashboard',
  components: {
    PeriodSelector,
    LocationSelector,
    AverageFeedback,
    SatisfactionRate,
    TopStaff,
    TopServices,
    FeedbackDistribution,
    NPSScore,
    SurveysBarChart,
    SurveysPieChart
  },
  data() {
    return {
      CHART_COLORS,
      location: undefined,
      period: undefined
    }
  }
}
</script>
