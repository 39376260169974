import Vue from 'vue'
import {SET_FORMS, UPDATE_FORM, RESET_FORMS, SET_FORMS_INITIALIZED, SET_FORM} from './mutationTypes'

import FormApi from '@/api/forms'
let __listPromise

export default {
  namespaced: true,
  state: {
    formsMap: {},
    formsOrder: [],
    initialized: false
  },
  getters: {
    forms(state) {
      return state.formsOrder.map(c => state.formsMap[c])
    },
    hasForms(state) {
      return !!state.formsOrder.length
    }
  },
  mutations: {
    [SET_FORMS_INITIALIZED](state, val) {
      state.initialized = val
    },
    [SET_FORMS](state, forms) {
      const mapped = {}
      for (const form of forms) {
        mapped[form._id] = form
      }
      state.formsMap = mapped
      state.formsOrder = forms.map(c => c._id)
    },
    [UPDATE_FORM](state, form) {
      if (state.formsMap[form._id]) {
        state.formsMap[form._id] = form
      } else {
        Vue.set(state.formsMap, form._id, form)
      }
    },
    [SET_FORM](state, form) {
      state.formsMap[form._id] = form
      if (!state.formsOrder.includes(form._id)) {
        state.formsOrder.push(form._id)
      }
    },
    [RESET_FORMS](state) {
      state.formsMap = {}
      state.formsOrder = []
      state.initialized = false
    }
  },
  actions: {
    async fetchForms({commit, state}, force) {
      if (__listPromise) {
        return __listPromise
      }
      if (!state.initialized || force) {
        __listPromise = FormApi.list()
        const {data} = await __listPromise
        __listPromise = undefined
        commit(SET_FORMS, data)
        commit(SET_FORMS_INITIALIZED, true)
      }
    },
    async createForm({dispatch}, data) {
      await FormApi.create(data)
      await dispatch('fetchForms', true)
    },
    async updateForm({commit}, {id, data}) {
      const form = await FormApi.update(id, data)
      commit(UPDATE_FORM, form)
    },
    async archiveForm({dispatch}, id) {
      await FormApi.archive(id)
      await dispatch('fetchForms', true)
    },
    async getFormById({commit, state}, id) {
      if (!state.formsMap[id]) {
        const form = await FormApi.getFormById(id)
        commit(SET_FORM, form)
      }
      return state.formsMap[id]
    },
    async createCustomerForm(_ctx, data) {
      return FormApi.createCustomerForm(data)
    },
    async updateCustomerForm(_ctx, {customerFormId, data}) {
      return FormApi.updateCustomerForm({customerFormId, data})
    },
    async fetchCustomerForms(_ctx, customerId) {
      return FormApi.fetchCustomerForms(customerId)
    },
    async fetchCustomerFormById(_ctx, customerFormId) {
      return FormApi.fetchCustomerFormById(customerFormId)
    },
    reset({commit}) {
      commit(RESET_FORMS)
    }
  }
}
