<template lang="pug">
  span
    RewardsViewNoState(:value="value" :currency="company.currency" :reward-type="company.membership.rewardType")
    template(v-if="value && currency && company.membership.currencyWorth !== 1 && company.membership.rewardType === 'points'")
      span  ≈  
      small(style="font-size: 0.7em")
        currency-view(:value="value/company.membership.currencyWorth")
</template>

<script>
import {mapState} from 'vuex'
import RewardsViewNoState from './RewardsViewNoState'
export default {
  name: 'RewardsView',
  components: {
    RewardsViewNoState
  },
  props: {
    value: {type: Number, default: undefined},
    currency: {type: Boolean, default: false}
  },
  computed: {
    ...mapState('company', ['company'])
  }
}
</script>
