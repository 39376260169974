<template lang="pug">
.modal-card
  header.modal-card-head
    p.modal-card-title(data-qa="modal-header") 
      b(v-if="id" v-t="'coupons.edit_coupon'")
      b(v-else v-t="'coupons.create_coupon'")
    div
      a.button.is-white.is-small(@click="$emit('close')")
        span.icon
          i.mdi.mdi-close.mdi-24px
  section.modal-card-body.has-lightgrey-background
  
        .columns.is-centered
          .column.is-8
            .box
              b-field(label=" ", :type="errors.has('artwork') ? 'is-danger': ''" :message="errors.has('artwork') ? errors.first('artwork') : ''")
                .coupon-artwork
                  image-preview(v-model="artwork" v-validate="'required'" name="artwork" data-vv-as="cover image" category="marketing" :cover-preview="true")
      
              b-field(label="Title", :type="errors.has('title') ? 'is-danger': ''" :message="errors.has('title') ? errors.first('title') : ''")
                b-input(v-model="title" icon="file-document-outline" v-validate="{required: true, regex:/^[\x00-\x7F]*$/ }" name="title" placeholder="e.g Get 30% back")

              b-field(label="Type" :type="errors.has('type') ? 'is-danger': ''" :message="errors.has('type') ? errors.first('type') : ''")
                b-select(v-model="type" icon="wallet-giftcard" v-validate="'required'" name="type" placeholder="Select a type" data-vv-as="type" expanded)
                  option(v-for="type in couponTypes" :value="type.value") {{type.title}}

              template(v-if="type === TYPES.CASHBACK")        
                b-field(label="Cashback in %", :type="errors.has('value') ? 'is-danger': ''" :message="errors.has('value') ? errors.first('value') : ''")
                  b-input(v-model="value" icon="percent" v-validate="'required|numeric'" name="value" placeholder="Cashback percentage" data-vv-as="cashback percentage" )
              
              template(v-if="type === TYPES.DISCOUNT")
                b-field(label="Discount in %", :type="errors.has('value') ? 'is-danger': ''" :message="errors.has('value') ? errors.first('value') : ''")
                  b-input(v-model="value" icon="percent" v-validate="'required|numeric'" name="value" placeholder="Discount percentage" data-vv-as="discount percentage" )
                
              template(v-if="type === TYPES.OFF")
                b-field(label="Amount off", :type="errors.has('value') ? 'is-danger': ''" :message="errors.has('value') ? errors.first('value') : ''")
                  b-field
                    p.control
                      span.button.is-static 
                        currency
                    b-input(v-model="value" v-validate="'required|numeric'" name="value" placeholder="Amount to be discounted in your currency" data-vv-as="amount off" )
              
              template(v-if="type === TYPES.OFF || type === TYPES.DISCOUNT")
                .field
                  b-radio(v-model="eligibility" native-value="all" @input="setEligibility") All services
                  b-radio(v-model="eligibility" native-value="service" @input="setEligibility") Specific service
                  b-radio(v-model="eligibility" native-value="product" @input="setEligibility") Specific product
                b-field(label="Service" v-if="eligibility === 'service'")
                  ServiceAutocomplete(v-model="service")
                b-field(label="Product" v-if="eligibility === 'product'")
                  ProductInput(v-model="productId")
              template(v-if="type === TYPES.SERVICES_COMBO")
                label.label Services
                .list
                  .list-item(v-for="packageItem in package" style="position: relative;padding-right: 3rem;")
                    button.delete(@click="removePackageItem(packageItem)" v-if="package.length > 2")              
                    ServiceAutocomplete(v-model="packageItem.service")
                .field
                  button.button(@click="package.push({service: {}})" type="button") Add service
                b-field(grouped)
                  b-field(label="Regular price")
                    b-field
                      b-input(:value="packagePrice" readonly)
                      p.control
                        span.button.is-static 
                          currency
                  b-field(label="Package price", :type="errors.has('value') ? 'is-danger': ''" :message="errors.has('value') ? errors.first('value') : ''")
                    b-field
                      b-input(v-model="value" v-validate="'required|numeric'" name="value" placeholder="Discounted price" data-vv-as="package price" )
                      p.control
                        span.button.is-static 
                          currency
              
              template(v-if="type === TYPES.POINTS")
                b-field(label="Points amount", :type="errors.has('value') ? 'is-danger': ''" :message="errors.has('value') ? errors.first('value') : ''")
                  b-input(v-model="value" icon="currency-usd" v-validate="'required|numeric'" name="value" placeholder="Amount of points gifted" data-vv-as="amount of points" )
              
              template(v-if="type === TYPES.SERVICE || type === TYPES.PRODUCT")
                b-field(label="Redeem type")
                  b-field
                    b-radio(v-model="manualRedeem" :native-value="true") Anytime
                    b-radio(v-model="manualRedeem" :native-value="false") During checkout
              template(v-if="type === TYPES.SERVICE")
                b-field(label="Service")
                  ServiceAutocomplete(v-model="service")
              template(v-if="type === TYPES.PRODUCT")
                b-field(label="Product")
                  ProductInput(v-model="productId")
            .box(v-if="type !== TYPES.POINTS")
              b-field(label="Coupon has expiration")
                b-switch(v-model="expires" type="is-success" @input="expirationChange") {{ expires ? 'Yes' : 'No' }}
              b-field(label="Should be redeemed before (optional)" v-if="expires" message="This is the maximum date after which coupons will not be valid")
                localized-datepicker(v-model="validUntil" placeholder="Clear the field if you don't want to have maximum validity date" :clearable="true")
                  
              b-field(message="This is the timeframe customer has after receiving the coupon to redeem it")
                b-field(grouped group-multiline v-if="expires" )
                  b-field(label="Valid for days/weeks/months", :type="errors.has('validity') ? 'is-danger': ''" :message="errors.has('validity') ? errors.first('validity') : ''")
                    b-input(v-model="validity" icon="calendar" v-validate="'required|numeric'" name="validity" placeholder="number of days, weeks or months")

                  b-field(label="Time unit", :type="errors.has('validityUnit') ? 'is-danger': ''" :message="errors.has('validityUnit') ? errors.first('validityUnit') : ''")
                    b-select(v-model="validityUnit" v-validate="'required'" name="validityUnit" placeholder="Select a time unit" data-vv-as="unit" icon="calendar")
                        option(v-for="value in validityUnits" :value="value") {{value}}
  footer.modal-card-foot
      .buttons
        action-button(@click="save" :loading="submitting" permission="COUPONS_EDIT" title="actions.save")
        button.button(type="button" @click.prevent="$emit('close')" v-t="'actions.cancel'")
</template>

<script>
import {mapActions} from 'vuex'
import dayjs from 'dayjs'
import {TYPES} from '../../../constants/couponTypes'
import ServiceAutocomplete from '../services/ServiceAutocomplete'
import ProductInput from '../inventory/ProductInput'
export default {
  name: 'CreateEditCoupon',
  components: {
    ServiceAutocomplete,
    ProductInput
  },
  props: {
    id: {type: String, default: undefined},
    template: {type: Object, default: undefined}
  },
  data() {
    return {
      submitting: false,
      validUntil: undefined,
      validity: null,
      validityUnit: null,
      validityUnits: ['days', 'weeks', 'months'],
      eligibility: 'all',
      service: {},
      productId: undefined,
      package: [{service: {}}, {service: {}}],
      value: undefined,
      type: undefined,
      title: undefined,
      artwork: undefined,
      manualRedeem: true,
      expires: false,
      couponTypes: [],
      TYPES
    }
  },
  computed: {
    packagePrice() {
      if (!this.package.length) {
        return 0
      }
      return this.package.reduce((sum, item) => sum + (item.service.price || 0), 0)
    }
  },
  async created() {
    this.couponTypes = await this.getTypes()
    if (this.id) {
      const coupon = await this.getById(this.id)

      this.title = coupon.title
      this.artwork = coupon.artwork
      this.value = coupon.value
      this.validUntil = coupon.validity && coupon.validity.until && new Date(coupon.validity.until)
      this.validity = coupon.validity && coupon.validity.value
      this.validityUnit = coupon.validity && coupon.validity.unit
      this.expires = !!this.validity
      this.type = coupon.type
      this.manualRedeem = !!coupon.manualRedeem
      this.service = {
        serviceId: coupon.service && coupon.service.service,
        optionId: coupon.service && coupon.service.option
      }
      this.productId = coupon.product
      this.eligibility = coupon.service && coupon.service.service ? 'service' : coupon.product ? 'product' : 'all'
      if (coupon.package) {
        this.package = coupon.package.map(item => {
          return {service: {serviceId: item.service, optionId: item.option}}
        })
      }
    } else if (this.template) {
      this.title = this.template.title
      this.artwork = this.template.artwork
      this.value = this.template.value
      this.manualRedeem = !!this.template.manualRedeem
      this.validity = this.template.validity && this.template.validity.value
      this.validityUnit = this.template.validity && this.template.validity.unit
      this.expires = !!this.validity
      this.type = this.template.type
    }
  },
  methods: {
    ...mapActions('coupon', ['update', 'create', 'getById', 'getTypes']),
    async save() {
      if (this.submitting) {
        return
      }
      const valid = await this.$validator.validateAll()
      if (!valid) {
        return
      }
      let data = {
        title: this.title,
        artwork: this.artwork,
        value: this.value,
        type: this.type
      }
      if (this.expires) {
        data.validity = {
          unit: this.validityUnit,
          value: this.validity,
          until:
            this.validUntil &&
            dayjs(this.validUntil)
              .endOf('day')
              .toISOString()
        }
      } else {
        data.validity = undefined
      }
      if (
        this.type === TYPES.DISCOUNT ||
        this.type === TYPES.OFF ||
        this.type === TYPES.SERVICE ||
        this.type === TYPES.PRODUCT
      ) {
        if (this.service) {
          data.service = {
            service: this.service.serviceId,
            option: this.service.optionId
          }
        }
        if (this.productId) {
          data.product = this.productId
        }
      }
      if (this.type === TYPES.SERVICES_COMBO) {
        data.package = this.package.map(item => {
          return {
            service: item.service.serviceId,
            option: item.service.optionId
          }
        })
      }
      if (this.type === TYPES.SERVICE || this.type === TYPES.PRODUCT) {
        data.manualRedeem = this.manualRedeem
      }
      this.submitting = true
      try {
        let coupon
        if (this.id) {
          coupon = await this.update({id: this.id, data})
        } else {
          coupon = await this.create(data)
        }
        this.$buefy.toast.open({message: 'The coupon has been saved successfully', type: 'is-success', queue: false})
        this.$emit('close')
        this.$emit('success', coupon)
      } catch (err) {
        this.$handleAPIError(err)
      }
      this.submitting = false
    },
    expirationChange(value) {
      if (!value) {
        this.validity = undefined
        this.validityUnit = undefined
      }
    },
    removePackageItem(item) {
      let idx = this.package.indexOf(item)
      this.package.splice(idx, 1)
    },
    setEligibility(val) {
      if (val === 'service') {
        this.productId = undefined
      } else if (val === 'product') {
        this.service = undefined
      } else {
        this.service = undefined
        this.productId = undefined
      }
    }
  }
}
</script>

<style scoped>
.coupon-artwork {
  width: 240px;
  height: 240px;
  margin: 0 auto;
}
.coupon-artwork .upload-target {
  border-radius: 16px;
  overflow: hidden;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.3);
}
</style>
