import {SET_COMPANY, UPDATE_COMPANY, RESET_COMPANY, SET_SMS_CREDITS} from './mutationTypes'
import CompanyApi from '@/api/company'
import TaxApi from '@/api/tax'
import SMSApi from '@/api/sms'
import extend from 'lodash/extend'
import dayjs from 'dayjs'
import billingHelper from '../../shared/billingHelper'
import Vue from 'vue'
export default {
  namespaced: true,
  state: {
    company: null,
    smsCredits: undefined
  },
  getters: {
    subscriptionIncomplete: state => {
      return (
        state.company &&
        (state.company.subscriptionStatus === 'incomplete' ||
          state.company.subscriptionStatus === 'past_due' ||
          state.company.subscriptionStatus === 'unpaid')
      )
    },
    currentPlan: state => state.company && billingHelper.getEffectivePlan(state.company.plan),
    trialHasEnded: state => billingHelper.trialHasEnded({plan: state.company.plan, country: state.company.country}),
    currentFiscalYear: state => {
      let year = dayjs().get('year')
      return dayjs().get('month') >= state.company.fiscalYearStart ? year : year - 1
    }
  },
  mutations: {
    [SET_COMPANY](state, company) {
      state.company = company
    },
    [UPDATE_COMPANY](state, data) {
      // TODO - fix this, when unseting field it will remain until refresh as we are using extend instead of replacing
      extend(state.company, data)
      state.company.updated = new Date()
    },
    [RESET_COMPANY](state) {
      state.company = null
      state.smsCredits = undefined
    },
    [SET_SMS_CREDITS](state, smsCredits) {
      Vue.set(state, 'smsCredits', smsCredits)
      //state.smsCredits = smsCredits
    }
  },
  actions: {
    async fetch({commit, state, dispatch}, force) {
      if (state.company && !force) {
        return Promise.resolve()
      }
      let data = await CompanyApi.getSettings()
      commit(SET_COMPANY, data)
      if (!data.ownSMSProvider) {
        dispatch('fetchSMSCredits')
      }
    },
    async updateTaxCalculation({dispatch}, data) {
      await TaxApi.updateTaxCalculation(data)
      await dispatch('fetch', true)
    },
    async update({commit}, data) {
      let res = await CompanyApi.updateSettings(data)
      commit(UPDATE_COMPANY, res)
    },
    reset({commit}) {
      commit(RESET_COMPANY)
    },
    async fetchSMSCredits({commit}) {
      let credits = await SMSApi.getCredits()
      commit(SET_SMS_CREDITS, credits)
    }
  }
}
