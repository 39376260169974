<template lang="pug">
  generic-modal(title="actions_library_title" @close="$emit('close')")
    template(v-slot:footer)
      .buttons
        action-button(@click="onSelect" :disabled="!selected" title="actions.select")
        button.button(@click="$emit('close')" v-t="'actions.cancel'")
    .tile.is-ancestor
      .tile
        .tile.is-parent(v-for="group in groups" v-if="group.display")
          .box.tile.is-child
            h4.title.is-5 {{group.name}}
            p.subtitle.is-6 {{group.description}}
            .buttons
              button.button(v-for="(button, index) in group.buttons" :key="index" @click="select(button)" :class="selected === button ? 'is-info' : ''") {{button.title}}
    
</template>

<script>
const types = {
  SIGNUP_URL: 'signup_url',
  UPDATE_URL: 'update_url',
  DETAILS_URL: 'details_url',
  BOOK_NOW: 'booknow',
  CALL_NOW: 'callnow',
  CONTINUE: 'continue'
}
const ACTIONS = {
  REMIND_TOMORROW: 'remindTomorrow'
}

export default {
  name: 'SelectButtonTypeModal',
  props: {
    current: {type: Object, default: undefined},
    showReminders: {type: Boolean, default: true}
  },
  data() {
    return {
      query: undefined,
      groups: [
        {
          display: true,
          name: 'Book appointment',
          description: 'Clients can easily book appointment by clicking this button',
          buttons: [
            {type: types.BOOK_NOW, title: '📅 Book now'},
            {type: types.BOOK_NOW, title: '📅 Reserve now'},
            {type: types.BOOK_NOW, title: '📅 Make an appt'}
          ]
        },
        {
          display: true,
          name: 'Call now',
          description: 'Clients can call your business with this button',
          buttons: [
            {type: types.CALL_NOW, title: '☎️ Call Us'},
            {type: types.CALL_NOW, title: '☎️ Call now'},
            {type: types.CALL_NOW, title: '☎️ Call'}
          ]
        },
        {
          display: true,
          name: 'Continue the flow',
          description: 'This button type is used to send the promotional part',
          buttons: [
            {type: types.CONTINUE, title: '🎁 Claim my gift'},
            {type: types.CONTINUE, title: '🎁 Gift please'},
            {type: types.CONTINUE, title: '💡 Learn more'},
            {type: types.CONTINUE, title: '📢️ Show more'},
            {type: types.CONTINUE, title: '✅ Next please'}
          ]
        },
        {
          display: this.showReminders,
          name: 'Remind later (followups only)',
          description: 'These buttons are used for followup reminders and reschedule reminders 24h later',
          buttons: [
            {action: ACTIONS.REMIND_TOMORROW, title: '⏰ Remind later'},
            {action: ACTIONS.REMIND_TOMORROW, title: '⏰ Snooze'},
            {action: ACTIONS.REMIND_TOMORROW, title: '⏰ Remind tomorrow'}
          ]
        }
      ],
      selected: undefined
    }
  },
  created() {
    if (this.current) {
      this.selected = this.buttons.find(b => {
        return b.type === this.current.type || b.action === this.current.action
      })
    }
  },
  methods: {
    onSelect() {
      let res = {
        type: this.selected.type,
        action: this.selected.action,
        title: this.selected.title
      }
      this.$emit('selected', res)
      this.$emit('close')
    },
    select(button) {
      this.selected = button
    }
  }
}
</script>
