<template lang="pug">
.tile(v-if="membership")
  .tile.is-parent(v-for="(level, idx) in membership.titles")
    .tile.is-child.box
      h3.title.is-6.is-uppercase.has-text-darkgrey {{membership.titles[idx]}} {{ $t('rewards.tier_short') }}
      h5.subtitle.is-7.has-text-grey(v-t="'periods.atm'")
      p.title.is-2 
        span {{membersMap[idx] || 0}} 
        percentage-view.is-percent.is-size-5(:value="(membersMap[idx] || 0)/total")
</template>

<script>
import ReportsApi from '@/api/reports'
import {mapState} from 'vuex'

export default {
  name: 'MembersDistribution',
  data() {
    return {
      loading: false,
      membersMap: {},
      membership: undefined,
      total: 0
    }
  },
  computed: {
    ...mapState('company', ['company'])
  },
  async created() {
    this.membership = this.company.membership
    await this.fetchData()
  },
  methods: {
    async fetchData() {
      this.loading = true
      try {
        const data = await ReportsApi.getMembersDistribution()
        data.forEach(level => {
          this.membersMap[level.membership] = level.count
          this.total += level.count
        })
      } catch (error) {
        this.$handleAPIError(error)
      }
      this.loading = false
    }
  }
}
</script>
