import Vue from 'vue'
import {SET_LOCATIONS, UPDATE_LOCATION, RESET_LOCATION, SET_LOCATIONS_INITIALIZED, SET_LOCATION} from './mutationTypes'
import LocationApi from '@/api/location'
let __listPromise

export default {
  namespaced: true,
  state: {
    locationsMap: {},
    locationsOrder: [],
    initialized: false
  },
  getters: {
    locations(state) {
      return state.locationsOrder.map(c => state.locationsMap[c])
    },
    activeLocations(state) {
      return state.locationsOrder.map(c => state.locationsMap[c]).filter(c => c.active === true)
    },
    inactiveLocations(state) {
      return state.locationsOrder.map(c => state.locationsMap[c]).filter(c => !c.active)
    },
    hasMultipleLocations(state) {
      return state.locationsOrder.map(c => state.locationsMap[c]).filter(c => c.active === true).length > 1
    }
  },
  mutations: {
    [SET_LOCATIONS_INITIALIZED](state, val) {
      state.initialized = val
    },
    [SET_LOCATIONS](state, locations) {
      const mapped = {}
      for (const location of locations) {
        mapped[location._id] = location
      }
      state.locationsMap = mapped
      state.locationsOrder = locations.map(c => c._id)
    },
    [UPDATE_LOCATION](state, location) {
      if (state.locationsMap[location._id]) {
        state.locationsMap[location._id] = location
      } else {
        Vue.set(state.locationsMap, location._id, location)
      }
    },
    [SET_LOCATION](state, location) {
      state.locationsMap[location._id] = location
      state.locationsOrder.push(location._id)
    },
    [RESET_LOCATION](state) {
      state.locationsMap = {}
      state.locationsOrder = []
      state.initialized = false
    }
  },
  actions: {
    async fetchLocations({commit, state}, force) {
      if (__listPromise) {
        return __listPromise
      }
      if (!state.initialized || force) {
        __listPromise = LocationApi.getLocations()
        const locations = await __listPromise
        __listPromise = undefined
        if (!locations) {
          return
        }
        commit(SET_LOCATIONS, locations)
        commit(SET_LOCATIONS_INITIALIZED, true)
      }
    },
    async createLocation({dispatch}, data) {
      await LocationApi.createLocation(data)
      await dispatch('fetchLocations', true)
      await dispatch('billing/refresh', undefined, {root: true})
    },
    async updateLocation({commit, dispatch}, {id, data}) {
      const location = await LocationApi.updateLocation(id, data)
      commit(UPDATE_LOCATION, location)
      await dispatch('billing/refresh', undefined, {root: true})
    },
    async updateTaxDefaults({commit}, {id, data}) {
      const location = await LocationApi.updateTaxDefaults(id, data)
      commit(UPDATE_LOCATION, location)
    },
    async getById({commit, state}, id) {
      if (!state.locationsMap[id]) {
        const location = await LocationApi.getLocationById(id)
        commit('SET_LOCATION', location)
      }

      return state.locationsMap[id]
    },
    reset({commit}) {
      commit(RESET_LOCATION)
    }
  }
}
