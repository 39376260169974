<template lang="pug">
.columns.is-multiline.is-mobile
  .column.is-6
    h3.mb-1.heading.is-size-7(v-t="'customer.loyalty_heading'")
    CustomerLoyalty(:customer="customer" :class="size")
  .column.is-6(v-if="$rfSubscribed('surveys')")
    h3.mb-1.heading.is-size-7(v-t="'customer.satisfaction_heading'")
    CustomerSatisfaction(:customer="customer" :class="size")
  .column.is-6(v-if="!$rfRewardsOnly()")
    h3.mb-1.heading.is-size-7(v-t="'customer.next_appt_heading'")
    NextPurchase(:customer="customer" :class="size")
  .column.is-6
    h3.mb-1.heading.is-size-7(v-t="'customer.est_appt_heading'")
    EstNextPurchase(:customer="customer" :class="size")
  


</template>

<script>
import CustomerLoyalty from './CustomerLoyalty'
import CustomerSatisfaction from './CustomerSatisfaction'

import EstNextPurchase from './EstNextPurchase'
import NextPurchase from './NextPurchase'
export default {
  name: 'CustomerLoyaltyStats',
  components: {
    EstNextPurchase,
    NextPurchase,
    CustomerLoyalty,
    CustomerSatisfaction
  },
  props: {
    customer: {type: Object, required: true},
    size: {type: String, default: ''}
  }
}
</script>
