<script>
import VueChart from 'vue-chartjs'
import CustomersApi from '@/api/customers'

export default {
  name: 'MonthlySalesLineChart',
  extends: VueChart.Line,
  props: {
    customerId: {type: String, required: true}
  },
  data() {
    return {
      loading: false
    }
  },
  async created() {
    await this.fetchData()
  },
  methods: {
    async fetchData() {
      this.loading = true
      try {
        const {body} = await CustomersApi.getCustomerMonthlySales(this.customerId)
        this.$data._chart && this.$data._chart.destroy()

        this.renderChart(body, {
          legend: {
            display: false
          },
          title: {
            display: false,
            text: 'Sales'
          },
          tooltips: {
            mode: 'nearest',
            intersect: false
          },
          responsive: true,
          maintainAspectRatio: false,
          scales: {
            xAxes: [
              {
                display: false
              }
            ],
            yAxes: [
              {
                display: false,
                scaleLabel: {
                  display: false,
                  labelString: 'Sales'
                },
                ticks: {
                  min: 0,
                  beginAtZero: true,
                  precision: 0
                }
              }
            ]
          }
        })
      } catch (error) {
        this.$handleAPIError(error)
      }
      this.loading = false
    }
  }
}
</script>
