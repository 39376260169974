<template lang="pug">
  b-select(v-model.number="time" :placeholder="placeholder" :icon="icon" @input="onChange" :size="size" :expanded="expanded")
    option(v-for="time in times" :disabled="time < minTime || time > maxTime" :value="time") {{formatTime(time)}}  
</template>

<script>
import {mapState} from 'vuex'
import formatter from '../../../shared/formatter'

const TIMES = [
  0,
  5,
  10,
  15,
  20,
  25,
  30,
  35,
  40,
  45,
  50,
  55,
  60,
  65,
  70,
  75,
  80,
  85,
  90,
  95,
  100,
  105,
  110,
  115,
  120,
  125,
  130,
  135,
  140,
  145,
  150,
  155,
  160,
  165,
  170,
  175,
  180,
  185,
  190,
  195,
  200,
  205,
  210,
  215,
  220,
  225,
  230,
  235,
  240,
  245,
  250,
  255,
  260,
  265,
  270,
  275,
  280,
  285,
  290,
  295,
  300,
  305,
  310,
  315,
  320,
  325,
  330,
  335,
  340,
  345,
  350,
  355,
  360,
  365,
  370,
  375,
  380,
  385,
  390,
  395,
  400,
  405,
  410,
  415,
  420,
  425,
  430,
  435,
  440,
  445,
  450,
  455,
  460,
  465,
  470,
  475,
  480,
  485,
  490,
  495,
  500,
  505,
  510,
  515,
  520,
  525,
  530,
  535,
  540,
  545,
  550,
  555,
  560,
  565,
  570,
  575,
  580,
  585,
  590,
  595,
  600,
  605,
  610,
  615,
  620,
  625,
  630,
  635,
  640,
  645,
  650,
  655,
  660,
  665,
  670,
  675,
  680,
  685,
  690,
  695,
  700,
  705,
  710,
  715,
  720,
  725,
  730,
  735,
  740,
  745,
  750,
  755,
  760,
  765,
  770,
  775,
  780,
  785,
  790,
  795,
  800,
  805,
  810,
  815,
  820,
  825,
  830,
  835,
  840,
  845,
  850,
  855,
  860,
  865,
  870,
  875,
  880,
  885,
  890,
  895,
  900,
  905,
  910,
  915,
  920,
  925,
  930,
  935,
  940,
  945,
  950,
  955,
  960,
  965,
  970,
  975,
  980,
  985,
  990,
  995,
  1000,
  1005,
  1010,
  1015,
  1020,
  1025,
  1030,
  1035,
  1040,
  1045,
  1050,
  1055,
  1060,
  1065,
  1070,
  1075,
  1080,
  1085,
  1090,
  1095,
  1100,
  1105,
  1110,
  1115,
  1120,
  1125,
  1130,
  1135,
  1140,
  1145,
  1150,
  1155,
  1160,
  1165,
  1170,
  1175,
  1180,
  1185,
  1190,
  1195,
  1200,
  1205,
  1210,
  1215,
  1220,
  1225,
  1230,
  1235,
  1240,
  1245,
  1250,
  1255,
  1260,
  1265,
  1270,
  1275,
  1280,
  1285,
  1290,
  1295,
  1300,
  1305,
  1310,
  1315,
  1320,
  1325,
  1330,
  1335,
  1340,
  1345,
  1350,
  1355,
  1360,
  1365,
  1370,
  1375,
  1380,
  1385,
  1390,
  1395,
  1400,
  1405,
  1410,
  1415,
  1420,
  1425,
  1430,
  1435,
  1440
]
export default {
  name: 'Timepicker',
  props: {
    value: {type: Number, default: undefined},
    size: {type: String, default: undefined},
    placeholder: {type: String, default: undefined},
    icon: {type: String, default: undefined},
    expanded: {type: String, default: undefined},
    maxTime: {type: Number, default: 1440},
    minTime: {type: Number, default: 0},
    allowEmpty: {type: Boolean, default: false}
  },
  inject: ['$validator'],
  data() {
    return {
      time: null,
      times: TIMES
    }
  },
  computed: {
    ...mapState('company', ['company'])
  },
  watch: {
    value(newVal) {
      if (newVal !== this.time) {
        this.time = this.value
      }
    }
  },
  async created() {
    if (this.value !== undefined) {
      this.time = this.value
    } else if (!this.allowEmpty) {
      let now = new Date()
      now.setMinutes(parseInt(now.getMinutes() / 5) * 5, 0, 0)
      this.time = now.getHours() * 60 + now.getMinutes()
      this.onChange()
    }
  },
  methods: {
    formatTime(time) {
      return formatter.formatTime(time, this.company.timeFormat)
    },
    onChange() {
      this.$emit('input', this.time)
    }
  }
}
</script>
