import Vue from 'vue'
import Vuex from 'vuex'
import auth from './auth'
import company from './company'
import tax from './tax'
import billing from './billing'
import customers from './customers'
import services from './services'
import inventory from './inventory'
import inventoryCategories from './inventoryCategories'
import inventoryBrands from './inventoryBrands'
import staff from './staff'
import completeness from './completeness'
import tickets from './tickets'
import sales from './sales'
import payments from './payments'
import appointments from './appointments'
import chatbot from './chatbot'
import reviews from './reviews'
import coupon from './coupon'
import customerCoupon from './customerCoupon'
import location from './location'
import resources from './resources'
import targets from './targets'
import ledger from './ledger'
import forms from './forms'
import membershipPackages from './membershipPackages'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    auth,
    company,
    tax,
    billing,
    customers,
    services,
    inventory,
    inventoryBrands,
    inventoryCategories,
    staff,
    completeness,
    tickets,
    sales,
    payments,
    appointments,
    chatbot,
    reviews,
    coupon,
    customerCoupon,
    location,
    resources,
    targets,
    ledger,
    forms,
    membershipPackages
  },
  state: {},
  mutations: {},
  getters: {},
  actions: {
    socket_companyUpdated({dispatch}) {
      dispatch('company/fetch', true)
    },
    socket_newCustomer({dispatch}) {
      dispatch('customers/search')
    },
    socket_customerRemoved({dispatch}, {customerId}) {
      dispatch('customers/onCustomerRemoved', customerId)
    },
    socket_updateCustomer({dispatch}, data) {
      dispatch('customers/refreshCustomer', data.customerId)
    },
    socket_ticketAdded({dispatch}, data) {
      dispatch('tickets/fetchOpenTickets', data.ticketId)
    },
    socket_ticketUpdated({dispatch}, data) {
      dispatch('tickets/refreshTicket', data.ticketId)
    },
    socket_taskDone({dispatch}) {
      dispatch('completeness/fetch')
    },
    socket_saleCreated({dispatch}, data) {
      dispatch('sales/onSaleCreated', data)
    },
    socket_saleUpdated({dispatch}, data) {
      dispatch('sales/onSaleUpdated', data)
    },
    socket_paymentCreated({dispatch}, data) {
      dispatch('payments/onPaymentCreated', data)
    },
    socket_paymentUpdated({dispatch}, data) {
      dispatch('payments/onPaymentUpdated', data)
    },
    socket_appointmentCreated({dispatch}, data) {
      dispatch('appointments/onAppointmentCreated', data)
    },
    socket_appointmentUpdated({dispatch}, data) {
      dispatch('appointments/onAppointmentUpdated', data)
    },
    socket_targetPeriodUpdated({dispatch}, data) {
      dispatch('targets/onPeriodUpdate', data)
    },
    socket_connect({dispatch}) {
      dispatch('ledger/fetchSession', true)
    },
    socket_smsConsumed({commit}, credits) {
      commit('company/SET_SMS_CREDITS', credits)
    },
    signout({dispatch}) {
      dispatch('auth/signout')
      dispatch('company/reset')
      dispatch('location/reset')
      dispatch('resources/reset')
      dispatch('billing/reset')
      dispatch('services/reset')
      dispatch('inventory/reset')
      dispatch('ledger/reset')
      dispatch('membershipPackages/reset')
    },
    async fetchMainData({dispatch, state}) {
      await Promise.all([dispatch('auth/fetch'), dispatch('company/fetch'), dispatch('location/fetchLocations')])
      if (!state.auth.user.company) {
        return
      }
      return Promise.all([
        dispatch('resources/fetchResources'),
        dispatch('billing/fetch'),
        dispatch('staff/list'),
        dispatch('services/list'),
        dispatch('tax/fetchTaxes'),
        dispatch('ledger/fetchSession'),
        dispatch('membershipPackages/fetchMembershipPackages')
      ])
    }
  }
})
