<template lang="pug">
div
  screen-title(title="payment_types_settings.title")
    action-button(@click="addEditPaymentType" title="payment_types_settings.actions.add" icon="plus" permission="COMPANY_SETTINGS_EDIT")
  .box
    b-table(:data="paymentTypes" hoverable=true class="is-vertically-aligned" @click="addEditPaymentType" custom-row-key="_id")
      b-table-column(field="name" width="50%" :label="$t('payment_types_settings.name_heading')" v-slot="props") {{props.row.name}}
      template(slot="empty")
        empty-content(title="payment_types_settings.no_custom_methods")
          action-button(@click="addEditPaymentType" title="payment_types_settings.actions.add" icon="plus" permission="COMPANY_SETTINGS_EDIT")
</template>
<script>
import {mapState} from 'vuex'
import PaymentTypeCreateEditModal from './PaymentTypeCreateEditModal'
export default {
  name: 'PaymentTypes',
  computed: {
    ...mapState('company', ['company']),
    paymentTypes() {
      if (this.company.customPaymentTypes) {
        return this.company.customPaymentTypes
      }
      return []
    }
  },
  methods: {
    addEditPaymentType(row) {
      this.$buefy.modal.open({
        parent: this,
        component: PaymentTypeCreateEditModal,
        hasModalCard: true,
        props: {paymentTypeId: row && row._id},
        canCancel: ['escape']
      })
    }
  }
}
</script>
