<template lang="pug">
  span {{channel}}
</template>
<script>
export default {
  props: {
    customer: {type: Object, required: true}
  },
  computed: {
    channel() {
      let channel
      if (this.customer.referredBy) {
        channel = 'referred'
      } else if (this.customer.affiliatedBy) {
        channel = 'affiliated'
      } else if (this.customer.signupBonus) {
        channel = 'promo signup'
      } else if (this.customer.surveys && this.customer.surveys.channel && this.customer.surveys.channel.value) {
        channel = this.customer.surveys.channel.value
      } else {
        channel = '-'
      }
      return channel
    }
  }
}
</script>
