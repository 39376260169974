<template lang="pug">
  .box(style="background-color:#ffffff")
    h2.title.is-4 Coupon Templates Library
    .columns.is-multiline
      .column.is-4
        .box.coupon-container
          .template-artwork()
          .text-container
            p.title.is-6.is-ellipsis Blank coupon
            p.subtitle.is-6.is-ellipsis Start with blank template
          p.control
            button.button.is-fullwidth.is-primary(@click="select()") Start with blank
      .column.is-4(v-for="template in templates")
        .box.coupon-container
          .template-artwork(:style="{'background-image' : `url(${template.artwork})`}")
          .text-container
            p.title.is-6.is-ellipsis {{template.title}}
            p.subtitle.is-6.is-ellipsis 
              coupon-terms(:coupon="template")
          p.control
            button.button.is-fullwidth.is-primary.is-outlined(@click="select(template)") Customize
    br
    .field.is-grouped
      p.control
        button.button(@click="$emit('close')" v-t="'actions.cancel'")
</template>

<script>
import CouponTerms from './CouponTerms'
import CouponTemplatesApi from '@/api/couponTemplates'

export default {
  components: {
    CouponTerms
  },
  data() {
    return {
      templates: [],
      loading: false,
      selected: undefined,
      total: 0
    }
  },
  async created() {
    await this.fetch()
  },
  methods: {
    async fetch() {
      this.loading = true
      try {
        const {body} = await CouponTemplatesApi.search({})
        this.templates = body.data
        this.total = body.total
      } catch (error) {
        this.total = 0
        this.$handleAPIError(error)
      }
      this.loading = false
    },
    cancel() {
      this.$emit('close')
    },
    select(template) {
      this.$emit('success', template)
      this.$emit('close')
    }
  }
}
</script>
<style scoped>
.coupon-container {
  padding: 1rem;
}
.template-artwork {
  margin-bottom: 10px;
  width: 100%;
  padding-top: 100%;
  background-size: cover;
  background-position: center center;
  border-radius: 16px;
  border: 1px solid #dedede;
  background-color: #f1f1f1;
}
.text-container {
  padding: 0 0.5rem;
  margin-bottom: 0.5rem;
}
</style>
