<template lang="pug">
div
  b-field
    b-checkbox(:value="allSelected" size="is-medium" @input="switchSelection" :indeterminate="allSelected === undefined") {{$t('actions.select_all')}}
  .columns.is-multiline
    .column.is-6(v-for="location in activeLocations")
      b-checkbox(v-model="selected" size="is-medium" :native-value="location._id" @input="input" data-qa="service-location" :disabled="($rfLocationScope() || $rfProfileScope()) && user.location !== location._id") 
        .media 
          .media-left
            figure.image.is-48x48.is-rounded
              img(:src="location.images && location.images[0] || require('@/assets/location.png')")
          .media-content 
            p.title.is-6 {{location.title}}
            p.subtitle.is-6 
              location-address-view(:location-id="location._id")
</template>
<script>
import {mapGetters, mapState} from 'vuex'
import {isEqual} from 'lodash'
export default {
  name: 'MultipleLocationSelector',
  props: {
    value: {type: Array, required: true}
  },
  data() {
    return {
      selected: []
    }
  },
  computed: {
    ...mapGetters('location', ['activeLocations']),
    ...mapState('auth', ['user']),
    allSelected() {
      return this.selected.length === this.activeLocations.length
        ? true
        : this.selected.length === 0
        ? false
        : undefined
    }
  },
  watch: {
    value() {
      if (isEqual(this.selected, this.value)) {
        return
      }
      this.setSelected()
    }
  },
  async created() {
    this.setSelected()
  },
  methods: {
    setSelected() {
      this.selected = this.value.slice()
      this.selected = this.selected.filter(id => !!this.activeLocations.find(a => a._id === id))
      this.input()
    },
    switchSelection() {
      if (this.allSelected) {
        this.selected = []
      } else {
        this.selected = this.activeLocations.map(l => l._id)
      }
      this.input()
    },
    input() {
      this.$emit('input', this.selected)
    }
  }
}
</script>
