<template lang="pug">
  article.msg.msg-left(:class="!valid ? 'is-invalid' : ''")
    ContentEditable.msg-text(v-model="message.content" placeholder="Enter message content here" @input="onInput" :editable="!readonly")
    Buttons(v-model="message.buttons" :editable="advancedMode" @input="onInput")
</template>
<script>
import ContentEditable from '../ContentEditable'
import Buttons from './Buttons'
import BaseElement from './BaseElement'

export default {
  name: 'TextElement',
  components: {
    ContentEditable,
    Buttons
  },
  extends: BaseElement,
  computed: {
    valid() {
      return !!this.message.content
    }
  },
  methods: {
    onInput() {
      this.message.valid = this.valid
      this.$emit('input', this.message)
    }
  }
}
</script>
