import {
  SET_SERVICES,
  SET_CATEGORIES,
  SET_SERVICE,
  SET_CATEGORY,
  REMOVE_CATEGORY,
  SET_SERVICES_IN_CATEGORY,
  SET_SERVICES_SEARCH_DIRECTORY,
  SET_SERVICES_INITIALIZED
} from './mutationTypes'
import ServiceApi from '@/api/service'
import Vue from 'vue'
import Fuse from 'fuse.js'
let __listPromise

let _fuse
export default {
  namespaced: true,
  state: {
    servicesMap: {},
    categoriesMap: {},
    total: 0,
    categoriesOrder: [],
    servicesOrder: {},
    _fuse: undefined,
    unwindedServices: [],
    initialized: false
  },
  getters: {
    categories(state) {
      return state.categoriesOrder.map(c => state.categoriesMap[c])
    },
    nonEmptyCategories(state) {
      return state.categoriesOrder
        .map(c => state.categoriesMap[c])
        .filter(c => state.servicesOrder[c._id] && state.servicesOrder[c._id].length)
    },
    servicesByCategoryId: state => id => {
      return state.servicesOrder[id].map(s => state.servicesMap[s])
    },
    serviceById: state => id => {
      return state.servicesMap[id]
    }
  },
  mutations: {
    [SET_SERVICES_INITIALIZED](state, val) {
      state.initialized = val
    },
    [SET_SERVICES](state, services) {
      for (let service of services) {
        state.servicesMap[service._id] = service
      }
      let activeServices = services.filter(s => !s.archived)
      for (let catId of state.categoriesOrder) {
        Vue.set(
          state.servicesOrder,
          catId,
          activeServices.filter(s => s.category === catId).map(s => s._id)
        )
      }

      let unwindedServices = []
      for (let service of activeServices) {
        unwindedServices = unwindedServices.concat(
          service.pricingOptions.map(option => {
            let cloned = Object.assign({}, service)
            cloned.pricingOptions = option
            return cloned
          })
        )
      }
      state.unwindedServices = unwindedServices
    },
    [SET_SERVICES_SEARCH_DIRECTORY](state, {services, languages}) {
      let options = {
        keys: [
          {
            name: 'name',
            weight: 0.2
          },
          {
            name: 'pricingOptions.name',
            weight: 0.1
          },
          {
            name: 'description',
            weight: 0.1
          },
          {
            name: 'keywords',
            weight: 0.1
          }
        ]
      }
      for (let lng of languages) {
        options.keys.push({name: `names.${lng}`, weight: Math.round((100 * 0.2) / languages.length) / 100})
        options.keys.push({name: `descriptions.${lng}`, weight: Math.round((100 * 0.2) / languages.length) / 100})
      }

      _fuse = new Fuse(services, options)
    },
    [SET_SERVICES_IN_CATEGORY](state, {services, categoryId}) {
      Vue.set(
        state.servicesOrder,
        categoryId,
        services.map(s => s._id)
      )
      for (let serviceId of state.servicesOrder[categoryId]) {
        state.servicesMap[serviceId].category = categoryId
      }
    },
    [SET_CATEGORIES](state, categories) {
      for (let cat of categories) {
        state.categoriesMap[cat._id] = cat
      }
      state.categoriesOrder = categories.map(c => c._id)
    },
    [SET_SERVICE](state, service) {
      let current = state.servicesMap[service._id] || {}
      for (let prop in service) {
        if (current[prop] === undefined) {
          Vue.set(current, prop, service[prop])
        } else {
          current[prop] = service[prop]
        }
      }
      state.servicesMap[service._id] = current
    },
    [SET_CATEGORY](state, category) {
      let current = state.categoriesMap[category._id] || {}
      for (let prop in category) {
        if (current[prop] === undefined) {
          Vue.set(current, prop, category[prop])
        } else {
          current[prop] = category[prop]
        }
      }
      Vue.set(state.categoriesMap, category._id, current)
    },

    [REMOVE_CATEGORY](state, id) {
      delete state.categoriesMap[id]
      delete state.servicesOrder[id]
      let idx = state.categoriesOrder.indexOf(id)
      if (idx !== -1) {
        state.categoriesOrder.splice(idx, 1)
      }
    },
    RESET(state) {
      state.servicesMap = {}
      state.categoriesMap = {}
      state.total = 0
      state.categoriesOrder = []
      state.servicesOrder = {}
      _fuse = undefined
      state.unwindedServices = []
      state.initialized = false
    }
  },
  actions: {
    async list({commit, state, rootState}, force) {
      if (__listPromise) {
        return __listPromise
      }
      if (!state.initialized || force) {
        __listPromise = Promise.all([ServiceApi.list({all: true}), ServiceApi.listCategories()])
        const [services, categories] = await __listPromise
        __listPromise = undefined
        commit(SET_SERVICES_INITIALIZED, true)
        commit(SET_CATEGORIES, categories)
        commit(SET_SERVICES, services)
        commit(SET_SERVICES_SEARCH_DIRECTORY, {
          services: state.unwindedServices,
          languages: rootState.company.company.languages
        })
      }
    },
    async fetchService({state, commit}, id) {
      if (!state.servicesMap[id]) {
        let {body} = await ServiceApi.getById(id)
        commit(SET_SERVICE, body)
      }
      return state.servicesMap[id]
    },
    searchService(_ctx, {query, locationId, staffId}) {
      let res = _fuse
        .search(query)
        .map(res => res.item)
        .filter(s => (!locationId || s.locations.includes(locationId)) && (!staffId || s.staff.includes(staffId)))
      return res
    },
    async refreshService({state, commit}, id) {
      if (state.servicesMap[id]) {
        let {body} = await ServiceApi.getById(id)
        commit(SET_SERVICE, body)
      }
    },
    async fetchServiceById({state, dispatch}, id) {
      if (!state.servicesMap[id]) {
        await dispatch('list', !__listPromise)
      }
      return state.servicesMap[id]
    },
    async removeService({dispatch}, id) {
      await ServiceApi.remove(id)
      await dispatch('list', true)
    },
    async updateService({dispatch}, data) {
      await ServiceApi.update(data._id, data)
      // commit(SET_SERVICE, body)
      await dispatch('list', true)
    },
    async createService({dispatch}, data) {
      await ServiceApi.create(data)
      //commit(SET_SERVICE, body)
      await dispatch('list', true)
    },
    async fetchCategory({state, commit}, id) {
      if (!state.categoriesMap[id]) {
        let {body} = await ServiceApi.getCategoryById(id)
        commit(SET_CATEGORY, body)
      }
      return state.categoriesMap[id]
    },
    async createCategory({dispatch, state}, data) {
      data.order = state.categoriesOrder.length
      await ServiceApi.createCategory(data)
      await dispatch('list', true)
    },
    async updateCategory({dispatch}, data) {
      await ServiceApi.updateCategory(data._id, data)
      await dispatch('list', true)
    },
    async removeCategory({commit, state}, id) {
      if (state.servicesOrder[id].length) {
        throw new Error('Please remove services first')
      }
      await ServiceApi.removeCategory(id)
      commit(REMOVE_CATEGORY, id)
    },
    async updateCategoriesOrder({commit, state}, categories) {
      commit(SET_CATEGORIES, categories)
      await ServiceApi.updateCategoriesOrder(state.categoriesOrder)
    },
    async updateServicesOrder({commit, state}, {services, categoryId}) {
      commit(SET_SERVICES_IN_CATEGORY, {services, categoryId})
      await ServiceApi.updateServicesOrder(categoryId, state.servicesOrder[categoryId])
    },
    async updateStaffServices({dispatch}, {staffId, services}) {
      await ServiceApi.updateStaffServices({staffId, services})
      await dispatch('list', true)
    },
    async updateResourceServices({dispatch}, {resourceId, services}) {
      await ServiceApi.updateResourceServices({resourceId, services})
      await dispatch('list', true)
    },
    async bulkUpdate({dispatch}, services) {
      await ServiceApi.serviceBulkUpdate(services)
      await dispatch('list', true)
    },
    reset({commit}) {
      commit('RESET')
    }
  }
}
