<template lang="pug">
  .columns.is-multiline
    .column.is-6
      h3.heading.is-7(v-t="'appointments.visits_heading'")
      p.title.is-5 {{customer.visits}}
    .column.is-6
      h3.heading.is-7(v-t="'appointments.no_shows_heading'")
      p.title.is-5.has-text-danger {{customer.noshows || '-'}}
    
    .column.is-6(v-if="!$rfRewardsOnly() && $rfSubscribed(PRODUCTS.CHURN_PREDICTION)")
      h3.heading.is-7(v-t="'customer.next_appt_heading'")
      NextPurchase(:customer="customer")
    .column.is-6(v-if="$rfSubscribed(PRODUCTS.CHURN_PREDICTION)")
      h3.heading.is-7(v-t="'customer.est_appt_heading'")
      EstNextPurchase(:customer="customer")
    
    
    //.column.is-4
      h3.heading.is-7(v-t="'appointments.cancelations_heading'")
      p.title.is-5.has-text-danger {{customer.cancelations || '-'}}
</template>

<script>
import {PRODUCTS} from '../../../constants/products'
import EstNextPurchase from '../customers/EstNextPurchase'
import NextPurchase from '../customers/NextPurchase'
import RepeatLastAppointmentButton from './RepeatLastAppointmentButton'

export default {
  name: 'CustomerAppointmentsStats',
  components: {
    RepeatLastAppointmentButton,
    EstNextPurchase,
    NextPurchase
  },
  props: {
    customer: {type: Object, required: true}
  },
  data() {
    return {
      PRODUCTS
    }
  },
  methods: {
    onRebook({customerId, rebookingId}) {
      this.$emit('rebook', {customerId, rebookingId})
    }
  }
}
</script>
