<template lang="pug">
.field
  div(v-for="role in employees")
    label.label {{$t(`roles.${role.role}`)}}
    b-field(grouped group-multiline)
      b-checkbox-button(v-for="member in role.members" @input="onSelect" :key="member._id" v-model="selectedEmployees" :native-value="member._id" type="is-success" data-qa="service-staff") 
        b-icon(icon="account") 
        | {{member | nameShortify}}
</template>

<script>
import StaffApi from '@/api/staff'

export default {
  name: 'EmployeeSelector',
  props: {
    value: {type: Array, default: undefined}
  },
  data() {
    return {
      employees: [],
      selectedEmployees: []
    }
  },
  watch: {
    value(val) {
      this.selectedEmployees = val
    }
  },
  async created() {
    if (this.value) {
      this.selectedEmployees = this.value
    }
    try {
      this.employees = await StaffApi.getActiveServiceList()
    } catch (error) {
      this.$handleAPIError(error)
    }
  },
  methods: {
    onSelect() {
      this.$emit('input', this.selectedEmployees)
    }
  }
}
</script>
