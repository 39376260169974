<template lang="pug">
  .box.is-relative(v-if="subscription.status")
    em-loading(:active="invoicesLoading" :is-full-page="false")
    h3.title.is-5 Invoices
    p.subtitle.is-6 Displaying last 10 invoices
    b-table(:data="invoices" hoverable=true)
      b-table-column(field="amount" label="Amount" width="10" :numeric="true" v-slot="props") 
        strong 
          invoice-currency(:value="props.row.amount" :currencyCode="props.row.currency")
      b-table-column(field="currency" label="Currency" width="10" :centered="true" v-slot="props") {{props.row.currency.toUpperCase()}}
      b-table-column(field="status" label="Status" width="10" :centered="true" v-slot="props") 
        invoice-status-tag(:status="props.row.status")
      b-table-column(field="due" label="Due" width="20" :centered="true" v-slot="props") {{props.row.due | timestampToDate}}
      b-table-column(field="created" label="Created" width="20" :centered="true" v-slot="props") {{props.row.created | timestampToDate}}
      b-table-column(field="period" label="Period" width="20" :centered="true" v-slot="props") {{props.row.periodStart | timestampToDay}} - {{props.row.periodEnd | timestampToDay}}
      b-table-column(field="invoiceNumber" label="Invoice Number" width="20" :centered="true" v-slot="props") {{props.row.number}}
      b-table-column(field="actions" label=" " width="20" :centered="true" v-slot="props") 
        a(:href="props.row.pdf" target="_blank" v-if="props.row.pdf") 
          span.tag.is-light
            span.icon.has-text-danger
              i.mdi.mdi-file-pdf
            span PDF
</template>

<script>
import InvoiceStatusTag from './InvoiceStatusTag'
import InvoiceCurrency from './InvoiceCurrency'
import {mapState} from 'vuex'

export default {
  name: 'InvoiceList',
  components: {
    InvoiceStatusTag,
    InvoiceCurrency
  },
  computed: {
    ...mapState('billing', ['invoices', 'invoicesLoading', 'subscription'])
  },
  async created() {
    await this.fetchInvoices()
  },
  methods: {
    async fetchInvoices() {
      try {
        await this.$store.dispatch('billing/fetchInvoices')
      } catch (error) {
        this.$handleAPIError(error)
      }
    }
  }
}
</script>

<style scoped>
table.table th {
  background: #f1f1f1;
}
</style>
