<template lang="pug">
  section.section
    .hero(:class="size")
      .hero-body
        p.has-text-centered(v-if="icon")
          span.icon.is-large
            i.mdi.mdi-36px(:class="`mdi-${icon}`")
        h3.has-text-centered(v-t="title" :class="size ==='is-small'?'is-size-4 is-size-5-mobile':'is-size-3 is-size-4-mobile'")
        p.is-size-5.has-text-centered(v-if="subtitle" v-t="subtitle" :class="size ==='is-small'?'is-size-6':'is-size-5'")
        .buttons.mt-4.is-centered
          slot
</template>
<script>
export default {
  name: 'EmptyContent',
  props: {
    title: {type: String, required: true},
    subtitle: {type: String, default: undefined},
    icon: {type: String, default: undefined},
    size: {type: String, default: undefined}
  }
}
</script>
