import Vue from 'vue'

export default {
  list(params) {
    return Vue.http.get('forms', {params}).then(res => res.body)
  },
  update(id, data) {
    return Vue.http.put(`forms/${id}`, data).then(res => res.body)
  },
  create(data) {
    return Vue.http.post('forms', data).then(res => res.body)
  },
  getFormById(id) {
    return Vue.http.get(`forms/${id}`).then(res => res.body)
  },
  archive(id) {
    return Vue.http.delete(`forms/${id}`).then(res => res.body)
  },
  createCustomerForm({formId, customerId, data}) {
    return Vue.http.post(`forms/${formId}/customer/${customerId}`, data).then(res => res.body)
  },
  fetchCustomerForms(customerId) {
    return Vue.http.get(`forms/customer/${customerId}`).then(res => res.body)
  },
  fetchCustomerFormById(id) {
    return Vue.http.get(`forms/customerForms/${id}`).then(res => res.body)
  },
  updateCustomerForm({customerFormId, data}) {
    return Vue.http.put(`forms/customerForms/${customerFormId}`, data).then(res => res.body)
  }
}
