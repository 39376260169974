const CLIENT_TOKEN = 'VPFS6N2PS2STSQTHKRIKQ5ZQYWFXFEUE'
const GOOGLE_TOKEN = process.env.GCLOUD_TOKEN

export default {
  process(utterance) {
    if (!utterance) {
      return
    }
    return fetch(`https://api.wit.ai/message?v=20210414&q=${utterance}`, {
      headers: {Authorization: `Bearer ${CLIENT_TOKEN}`}
    }).then(response => response.json())
  },
  syntezise(text) {
    return fetch(`https://texttospeech.googleapis.com/v1/text:synthesize`, {
      headers: {
        Authorization: `Bearer ${GOOGLE_TOKEN}`,
        'Content-Type': 'application/json; charset=utf-8'
      },
      method: 'POST',
      body: JSON.stringify({
        input: {
          text
        },
        voice: {
          languageCode: 'en-AU',
          name: 'en-AU-Wavenet-C',
          ssmlGender: 'FEMALE'
        },
        audioConfig: {
          audioEncoding: 'MP3'
        }
      })
    }).then(response => response.json())
  }
}
