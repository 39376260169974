import {
  SET_PRODUCTS,
  SET_TOTAL_PRODUCTS,
  SET_PRODUCT,
  REMOVE_PRODUCT,
  SET_PRODUCTS_FILTER,
  SET_CATEGORY_PRODUCTS,
  RESET_CATEGORY_PRODUCTS
} from './mutationTypes'
import InventoryApi from '@/api/inventory'
import Vue from 'vue'

export default {
  namespaced: true,
  state: {
    categoryProductsMap: {},
    productsMap: {},
    productsBarcodeMap: {},
    productsOrder: [],
    total: 0,
    filter: {}
  },
  getters: {
    products(state) {
      return state.productsOrder.map(c => state.productsMap[c])
    }
  },
  mutations: {
    [SET_PRODUCTS](state, products) {
      for (let product of products) {
        state.productsMap[product._id] = product
        if (product.barcode) {
          state.productsBarcodeMap[product.barcode] = product
        }
      }
      state.productsOrder = products.map(c => c._id)
    },
    [SET_CATEGORY_PRODUCTS](state, {categoryId, products}) {
      state.categoryProductsMap[categoryId] = products.map(p => p._id)
      for (let product of products) {
        state.productsMap[product._id] = product
      }
    },
    [RESET_CATEGORY_PRODUCTS](state, {categoryId}) {
      if (categoryId) {
        delete state.categoryProductsMap[categoryId]
        return
      }
      state.categoryProductsMap = {}
    },
    [SET_TOTAL_PRODUCTS](state, total) {
      state.total = total
    },
    [SET_PRODUCT](state, product) {
      let current = state.productsMap[product._id] || {}
      for (let prop in product) {
        if (current[prop] === undefined) {
          Vue.set(current, prop, product[prop])
        } else {
          current[prop] = product[prop]
        }
      }
      for (let prop in current) {
        if (product[prop] === undefined) {
          current[prop] = undefined
        }
      }

      Vue.set(state.productsMap, product._id, current)
      if (product.barcode) {
        Vue.set(state.productsBarcodeMap, product.barcode, current)
      }
    },
    [SET_PRODUCTS_FILTER](state, filter) {
      state.filter = filter
    },
    [REMOVE_PRODUCT](state, id) {
      let product = state.productsMap[id]
      if (!product) {
        return
      }
      if (product.barcode) {
        delete state.productsBarcodeMap[product.barcode]
      }
      delete state.productsMap[id]
      const idx = state.productsOrder.indexOf(id)
      if (idx !== -1) {
        state.productsOrder.splice(idx, 1)
      }
    },
    RESET(state) {
      state.categoryProductsMap = {}
      state.productsMap = {}
      state.productsBarcodeMap = {}
      state.productsOrder = []
      state.total = 0
      state.filter = {}
    }
  },
  actions: {
    async search({commit, state}, filter) {
      if (!filter) {
        filter = state.filter
      }
      if (!filter.perPage) {
        return
      }
      const {data, total} = await InventoryApi.searchProducts(filter)
      commit(SET_PRODUCTS, data)
      commit(SET_TOTAL_PRODUCTS, total)
      commit(SET_PRODUCTS_FILTER, filter)
    },
    reset({commit}) {
      commit('RESET')
    },
    async searchDirectory(_ctx, {query, categoryId}) {
      const {data} = await InventoryApi.searchProducts({
        category: categoryId,
        query
      })
      return data
    },
    async fetchProductsByCategory({state, commit}, categoryId) {
      if (!state.categoryProductsMap[categoryId]) {
        const {data} = await InventoryApi.searchProducts({category: categoryId, perPage: 1000})
        commit(SET_CATEGORY_PRODUCTS, {categoryId, products: data})
      }
      return state.categoryProductsMap[categoryId].map(pId => state.productsMap[pId])
    },
    async fetchProduct({state, commit}, id) {
      if (!state.productsMap[id]) {
        const body = await InventoryApi.getProductById(id)
        commit(SET_PRODUCT, body)
      }
      return state.productsMap[id]
    },
    async fetchProductByBarcode({commit, state}, barcode) {
      if (!barcode) {
        return
      }
      if (!state.productsBarcodeMap[barcode]) {
        const {data, total} = await InventoryApi.searchProducts({barcode})
        if (total) {
          commit(SET_PRODUCT, data[0])
        }
      }
      return state.productsBarcodeMap[barcode]
    },
    async fetchInventory(_context, {productId, locationId, filter}) {
      return InventoryApi.getInventoryHistory({productId, locationId, filter})
    },
    async increaseStock({dispatch}, params) {
      await InventoryApi.increaseStock(params.productId, params)
      return dispatch('refreshProduct', params.productId)
    },
    async decreaseStock({dispatch}, params) {
      await InventoryApi.decreaseStock(params.productId, params)
      return dispatch('refreshProduct', params.productId)
    },
    async refreshProduct({state, commit}, id) {
      if (state.productsMap[id]) {
        const body = await InventoryApi.getProductById(id)
        commit(SET_PRODUCT, body)
      }
    },
    async removeProduct({commit, state}, id) {
      await InventoryApi.removeProduct(id)
      let prod = state.productsMap[id]
      if (prod) {
        commit(RESET_CATEGORY_PRODUCTS, prod.category || 'uncategorized')
      }
      commit(REMOVE_PRODUCT, id)
    },
    async onProductRemoved({dispatch, state}, id) {
      if (state.productsMap[id]) {
        await dispatch('search')
      }
    },
    async updateProduct({commit, state}, data) {
      const body = await InventoryApi.updateProduct(data._id, data)
      let initialCategory = state.productsMap[data._id] && state.productsMap[data._id].category
      if (initialCategory !== data.category) {
        commit(RESET_CATEGORY_PRODUCTS, initialCategory || 'uncategorized')
      }
      commit(SET_PRODUCT, body)
      commit(RESET_CATEGORY_PRODUCTS, body.category || 'uncategorized')
    },

    async updateProductsBulk({dispatch}, products) {
      await InventoryApi.updateProductsBulk(products)
      await dispatch('search')
    },
    async createProduct({commit, dispatch}, data) {
      const body = await InventoryApi.createProduct(data)
      await dispatch('search')
      commit(SET_PRODUCT, body)
      commit(RESET_CATEGORY_PRODUCTS, data.category || 'uncategorized')
      return body
    }
  }
}
