<template lang="pug">
  div
  
    screen-title(title="api_access.heading")
    .box
      b-field(:label="$t('api_access.token_label')")
        b-field
          b-input(:value="token" id="tokenElement" icon="api" @focus="$event.target.select()" readonly expanded)
          p.control
            button.button.is-light(@click="copyToClipboard('tokenElement')")
              span.icon
                i.mdi.mdi-content-copy                
      p.help(v-t="'api_access.token_help'")
</template>
<script>
import AuthApi from '@/api/auth'
export default {
  name: 'APIAccess',
  data() {
    return {
      token: undefined
    }
  },
  async created() {
    let {token} = await AuthApi.getAPIAccessToken()
    this.token = token
  },
  methods: {
    copyToClipboard(element) {
      document.getElementById(element).select()
      document.execCommand('copy')
      this.$buefy.toast.open({
        message: this.$t('rewards_program.settings.copied_message'),
        type: 'is-success',
        queue: false
      })
    }
  }
}
</script>
