import Vue from 'vue'
import {
  SET_MEMBERSHIP_PACKAGES,
  UPDATE_MEMBERSHIP_PACKAGE,
  RESET_MEMBERSHIP_PACKAGE,
  SET_MEMBERSHIP_PACKAGES_INITIALIZED,
  SET_MEMBERSHIP_PACKAGE
} from './mutationTypes'
import MembershipsApi from '@/api/memberships'
let __listPromise

export default {
  namespaced: true,
  state: {
    membershipPackagesMap: {},
    membershipPackagesOrder: [],
    initialized: false
  },
  getters: {
    membershipPackages(state) {
      return state.membershipPackagesOrder.map(c => state.membershipPackagesMap[c]).filter(m => !m.archived)
    }
  },
  mutations: {
    [SET_MEMBERSHIP_PACKAGES_INITIALIZED](state, val) {
      state.initialized = val
    },
    [SET_MEMBERSHIP_PACKAGES](state, membershipPackages) {
      const mapped = {}
      for (const membershipPackage of membershipPackages) {
        mapped[membershipPackage._id] = membershipPackage
      }
      state.membershipPackagesMap = mapped
      state.membershipPackagesOrder = membershipPackages.map(c => c._id)
    },
    [UPDATE_MEMBERSHIP_PACKAGE](state, membershipPackage) {
      let current = state.membershipPackagesMap[membershipPackage._id]
      if (current) {
        Object.assign(current, membershipPackage)
      } else {
        Vue.set(state.membershipPackagesMap, membershipPackage._id, membershipPackage)
      }
      for (let prop in current) {
        if (membershipPackage[prop] === undefined) {
          current[prop] = undefined
        }
      }
    },
    [SET_MEMBERSHIP_PACKAGE](state, membershipPackage) {
      state.membershipPackagesMap[membershipPackage._id] = membershipPackage
      if (!state.membershipPackagesOrder.includes(membershipPackage._id)) {
        state.membershipPackagesOrder.push(membershipPackage._id)
      }
    },
    [RESET_MEMBERSHIP_PACKAGE](state) {
      state.membershipPackagesMap = {}
      state.membershipPackagesOrder = []
      state.initialized = false
    }
  },
  actions: {
    async fetchMembershipPackages({commit, state}, force) {
      if (__listPromise) {
        return __listPromise
      }
      if (!state.initialized || force) {
        __listPromise = MembershipsApi.getMembershipPackages()
        const membershipPackages = await __listPromise
        __listPromise = undefined
        commit(SET_MEMBERSHIP_PACKAGES, membershipPackages)
        commit(SET_MEMBERSHIP_PACKAGES_INITIALIZED, true)
      }
    },
    async createMembershipPackage({dispatch}, data) {
      await MembershipsApi.createMembershipPackage(data)
      await dispatch('fetchMembershipPackages', true)
    },
    async updateMembershipPackage({commit}, {id, data}) {
      const membershipPackage = await MembershipsApi.updateMembershipPackage(id, data)
      commit(UPDATE_MEMBERSHIP_PACKAGE, membershipPackage)
    },
    async archiveMembershipPackage({dispatch}, id) {
      await MembershipsApi.archiveMembershipPackage(id)
      await dispatch('fetchMembershipPackages', true)
    },
    async getMembershipPackageById({commit, state}, id) {
      if (__listPromise) {
        await __listPromise
      }
      if (!state.membershipPackagesMap[id]) {
        const membershipPackage = await MembershipsApi.getMembershipPackageById(id)
        commit('SET_MEMBERSHIP_PACKAGE', membershipPackage)
      }
      return state.membershipPackagesMap[id]
    },
    reset({commit}) {
      commit(RESET_MEMBERSHIP_PACKAGE)
    }
  }
}
