<template lang="pug">
  .chat-container.box.p-0(v-if="active")
    .chat-header.p-4.py-5.is-relative
      .media
        .media-left
          .box.p-2(style="border-radius: 50%; width: 48px; height: 48px")
            img(src="@/assets/symbol_color.svg" )
        .media-body
          h2.title.is-5.has-text-white 
            span emly 
            span.icon.is-clickable(v-if="speechEnabled" @click="speechEnabled = false")
              i.mdi.mdi-volume-high
            span.icon.is-clickable(v-else @click="speechEnabled = true")
              i.mdi.mdi-volume-off
          p.subtitle.has-text-white.is-6 Your virtual assistant
        
      span.icon.is-clickable(@click="active = false" style="position: absolute; right: 1rem; top: 1rem")
        i.mdi.mdi-chevron-down.mdi-24px
    .chat-body.p-4#chat-texts.is-flex.is-flex-direction-column
      .has-text-centered.px-6(v-if="!history.length")
        p.is-size-6.has-text-grey.mb-3 Sample questions
        p.is-size-7.sample-msg.mb-2.is-clickable(@click="quickQuestion(sample)" v-for="sample in samples") {{sample}}
      .chat-msg.mb-4(v-for="(msg, idx) in history" :key="idx" :class="msg.role" v-html="msg.displayContent") 
      transition(name="fade")
        .chat-msg.mb-4.assistant.p-0.px-3(v-if="loading") 
          img(src="@/assets/typing_indicator.gif" style="width: 28px; height: 28px; display:block")
        

    .chat-composer.p-4
      form(@submit.prevent="askQuestion")
        b-field(grouped)
          button.button.is-white(@click="toggle" v-if="recognition")
            span.icon.is-medium.has-text-danger(v-if="microphoneActive")
              i.mdi.mdi-microphone.mdi-24px
            span.icon.is-medium.has-text-grey(v-else)
              i.mdi.mdi-microphone-off.mdi-24px
          b-input.mr-0.chat-input(expanded type="textarea" v-model="prompt" @keydown.native="onKeydown" ref="mainInput" placeholder="ask something" rounded )  
          button.button.is-white(@click="askQuestion" type="submit") 
            span.icon
              i.mdi.mdi-send-outline
  .chat-container.box.chat-button(v-else @click="active = true")
    img(src="@/assets/symbol_white.svg" )
</template>
<script>
import AssistantApi from '@/api/assistant'
const page_mapping = {
  calendar: 'calendar',
  clients: 'customers',
  marketing: 'marketing/rewards-program',
  dashboard: 'dashboard',
  analytics: 'analytics/overview',
  reports: 'analytics/reports',
  sales: 'sales/list',
  targets: 'targets',
  inventory: 'inventory/products',
  staff: 'company/team',
  roster: 'company/team/shifts',
  services: 'company/services',
  resources: 'company/resources',
  locations: 'company/locations',
  education: 'education/overview',
  settings: 'settings/business',
  online_booking_settings: 'settings/online',
  client_notifications: 'settings/notifications',
  taxes_settings: 'settings/taxes'
}
export default {
  name: 'AssistantPopup',
  data() {
    return {
      history: [],
      prompt: undefined,
      loading: false,
      active: false,
      samples: [
        'What is my average check for the last week?',
        'How many bookings do we have for the upcoming 4 days?',
        'What is the average number of services per appointment in the last month?',
        'How many clients did we service in the last 60 days?',
        'What is the online booking percentage for the last week?',
        'How much revenue did we generate in the last quarter?'
      ],
      recognition: undefined,
      microphoneActive: false,
      speechEnabled: true
    }
  },
  watch: {
    async active() {
      if (this.active) {
        await this.$nextTick()
        this.$refs.mainInput && this.$refs.mainInput.focus()
      }
    }
  },
  mounted() {
    this.$refs.mainInput && this.$refs.mainInput.focus()

    const SpeechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition
    if (SpeechRecognition) {
      this.recognition = new SpeechRecognition()
      this.recognition.continuous = true
      this.recognition.lang = 'en-US'
      this.recognition.interimResults = true
      this.recognition.maxAlternatives = 1

      this.recognition.onresult = this.onVoice
    }
  },
  methods: {
    async askQuestion() {
      if (!this.prompt || !this.prompt.trim()) {
        return
      }
      let query = this.prompt
      this.history.push({role: 'user', content: query, displayContent: query})
      this.prompt = undefined
      this.loading = true
      this.scrollDown()
      try {
        let response = await AssistantApi.submitQuestion(
          this.history.map(h => {
            return {
              role: h.role,
              content: h.content
            }
          })
        )
        if (response.action) {
          this.loading = false
          this.$refs.mainInput.focus()
          return this.processAction(response)
        }

        this.history.push({
          role: 'assistant',
          content: response.content,
          displayContent: response.displayContent.replace(/\n/g, '<br/>')
        })
        if (this.speechEnabled) {
          this.playVoice(response.voice)
        }
      } catch (err) {
        this.prompt = query
        this.$handleAPIError(err)
      }
      this.loading = false

      this.$refs.mainInput.focus()
      this.scrollDown()
    },
    onKeydown(event) {
      if ((event.metaKey || event.ctrlKey) && event.keyCode === 13) {
        this.askQuestion()
      }
    },
    toggle() {
      if (this.microphoneActive) {
        this.recognition.abort()
      } else {
        this.recognition.start()
      }
      this.microphoneActive = !this.microphoneActive
    },
    async onVoice(event) {
      let utteranceList = event.results
      let latestUtterance = utteranceList[utteranceList.length - 1]
      let speechRecognition = latestUtterance[latestUtterance.length - 1]

      // Update text object with speech recognition transcription
      let transcript = speechRecognition.transcript.toLowerCase()
      this.prompt = transcript
      if (latestUtterance.isFinal) {
        // Exit the function if the wake word was not triggered to respect user privacy
        this.toggle()
        this.askQuestion()
      }
    },
    playVoice(data) {
      new Audio('data:audio/wav;base64,' + data).play()
    },
    quickQuestion(sample) {
      this.prompt = sample
      this.askQuestion()
    },
    async scrollDown() {
      await this.$nextTick()
      let el = document.getElementById('chat-texts')
      el.scrollTop = el.scrollHeight
    },
    processAction({action, page}) {
      switch (action) {
        case 'open':
          this.$router.push(`/${page_mapping[page]}`)
      }
    }
  }
}
</script>
<style scoped></style>
