<template lang="pug">
  div
    template(v-if="fbInitializing")
      h5 please hold while loading ...
    template(v-else)
      .has-text-centered(v-if="hasPagesListPermission === false")
          .notification(v-if="isLoggedIn")
            span.icon.is-left.has-text-warning
              i.mdi.mdi-alert
            span(v-t="'platforms_settings.permission_warning'")
          .notification(v-else)
            span.icon.is-left.has-text-warning
              i.mdi.mdi-alert
            span(v-t="'platforms_settings.page_admin_warning'")
          b-field
            button.button.is-medium.is-facebook(type="button" @click="connectFacebook")
              span.icon
                i.mdi.mdi-facebook
              span {{facebookButton}}
      div(v-else-if="hasPagesListPermission")
        b-notification(type="is-warning" :closable="false" :has-icon="true" v-if="selectedPage && !isPageAdmin(selectedPage)")
          p(v-html="$t('platforms_settings.not_page_admin_error', [selectedPage.name])")

        label.label(v-t="'platforms_settings.facebook_page'")
        b-field(grouped)  
          b-field(:type="errors.has('selectedPage') ? 'is-danger': ''" :message="errors.has('selectedPage') ? errors.first('selectedPage') : ''" expanded)
            b-select(:disabled="isSelectBoxDisabled" expanded v-model="selectedPage" v-validate="'required'" name="selectedPage" data-vv-as="Facebook page")
              //- option(value="" v-t="'platforms_settings.select_page_placeholder'")
              option(:value="page" v-for="page in pages") {{isPageAdmin(page) ? '' : '[NOT ADMIN]'}} {{page.name}}
          b-field
            p.control
              button.button.is-success(type="button" @click="selectPage" :class="submitting ? 'is-loading': ''") {{ $t('platforms_settings.actions.connect_page') }}
</template>

<script>
import FBHelper from '@/api/facebook'

export default {
  name: 'FacebookConnect',
  props: {
    submitting: {type: Boolean, default: false}
  },
  data() {
    return {
      hasPagesListPermission: null,
      isLoggedIn: null,
      fbInitializing: true,
      isSelectBoxDisabled: false,
      facebookButton: this.$t('platforms_settings.actions.connect_with_facebook'),
      pages: [],
      selectedPage: '',
      userAccessToken: undefined
    }
  },

  async created() {
    try {
      const state = await FBHelper.getLoginState()
      await this.processFBState(state)
    } catch (error) {
      this.$handleAPIError(error)
    }
  },

  methods: {
    isPageAdmin(page) {
      return page && page.tasks && page.tasks.indexOf('MANAGE') !== -1
    },
    async processFBState(state) {
      if (state.auth === 'connected') {
        this.isLoggedIn = true
        try {
          const token = await FBHelper.getUserAccessToken()

          if (!state.missingPermissions.length) {
            this.hasPagesListPermission = true
            this.userAccessToken = token.userAccessToken
            await this._processPages(token.userAccessToken)
          } else {
            this.hasPagesListPermission = false
            this.facebookButton = this.$t('platforms_settings.actions.grant_missing_permission')
            this.fbInitializing = false
          }
        } catch (error) {
          this.$handleAPIError(error)
        }
      } else {
        this.fbInitializing = false
        this.isLoggedIn = false
        this.hasPagesListPermission = false
      }
    },

    async connectFacebook() {
      try {
        const state = await FBHelper.login()
        await this.processFBState(state)
      } catch (error) {
        this.$handleAPIError(error)
      }
    },
    async selectPage() {
      const valid = await this.$validator.validateAll()
      if (!valid) {
        return
      }
      if (this.submitting) {
        return
      }
      // if (!this.isPageAdmin(this.selectedPage)) {
      //   return
      // }
      if (typeof this.selectedPage === 'object') {
        this.$emit('select', {
          userAccessToken: this.userAccessToken,
          pageAccessToken: this.selectedPage.access_token,
          page: this.selectedPage
        })
      }
    },
    changeSelectBoxState(state, clearSelection) {
      switch (state) {
        case 'enabled':
          this.isSelectBoxDisabled = false
          break
        case 'disabled':
          this.isSelectBoxDisabled = true
          break
      }
      if (clearSelection) {
        this.selectedPage = undefined
      }
    },

    async _processPages() {
      try {
        const pageList = await FBHelper.getFBPages()

        this.pages = pageList
        this.fbInitializing = false
        if (this.pages.length === 0) {
          this.changeSelectBoxState('disabled', true)
        }
      } catch (error) {
        this.$handleAPIError(error)
      }
    }
  }
}
</script>
