<template lang="pug">
  div
    b-field(grouped group-multiline)
      b-checkbox-button(v-model="selected" v-for="option in options" :key="option.value" :native-value="option.value" type="is-success" @input="onInput") 
        span.icon
          i.mdi.mdi-check(v-show="selected.indexOf(option.value) >= 0")
          i.mdi.mdi-close(v-show="selected.indexOf(option.value) < 0")
        span {{option.title}}
 
</template>

<script>
import SurveysApi from '@/api/surveys'

export default {
  name: 'SurveyOptionSelector',
  props: {
    value: {type: Array, required: true},
    question: {type: String, required: true}
  },
  data() {
    return {
      options: [],
      selected: []
    }
  },
  async created() {
    this.options = await SurveysApi.getSurveyOptions(this.question)
    this.selected = [...this.value]
  },
  methods: {
    onInput() {
      this.$emit('input', this.selected)
    }
  }
}
</script>
