<template lang="pug">
  .icon.is-large.has-text-danger.is-clickable(@click="toggle")
    i.mdi.mdi-36px(:class="active? 'mdi-circle':'mdi-circle-outline'")
</template>
<script>
import witaiAPI from '@/api/witai'
import ReportsApi from '@/api/reports'
import dayjs from 'dayjs'
import {formatCurrency} from '../../shared/saleHelper'
import {mapState} from 'vuex'
import numeral from 'numeral'

// Set your wake word
const WAKE_WORD = 'emily'
export default {
  data() {
    return {
      active: false,
      text: undefined,
      recognition: undefined
    }
  },
  computed: {
    ...mapState('company', ['company'])
  },
  mounted() {
    const SpeechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition
    this.recognition = new SpeechRecognition()
    this.recognition.continuous = true
    this.recognition.lang = 'en-US'
    this.recognition.interimResults = true
    this.recognition.maxAlternatives = 1

    this.recognition.onresult = this.onVoice
  },
  methods: {
    toggle() {
      if (this.active) {
        this.recognition.abort()
      } else {
        this.recognition.start()
      }
      this.active = !this.active
    },
    async onVoice(event) {
      let utteranceList = event.results
      let latestUtterance = utteranceList[utteranceList.length - 1]
      let speechRecognition = latestUtterance[latestUtterance.length - 1]

      // Update text object with speech recognition transcription
      let transcript = speechRecognition.transcript.toLowerCase()

      if (latestUtterance.isFinal) {
        // Exit the function if the wake word was not triggered to respect user privacy
        if (!transcript.includes(`hey`)) {
          // Provide the user with a suggestion on voice commands they can say
          this.showVoiceCommandText(
            `Try saying: 'Hey ${WAKE_WORD}, what is the number of appointments for the last week'`
          )
          return
        }
        this.showVoiceCommandText(transcript)
        // Extract the utterance from the wake word
        let utterance = transcript.split(`hey ${WAKE_WORD}`)[1]

        // Send the user's utterance to Wit.ai API for NLU inferencing
        try {
          let json = await witaiAPI.process(utterance)
          let mainIntent = json.intents && json.intents.length && json.intents[0]
          this.executeCommand(mainIntent, json.entities)
        } catch (err) {
          console.error(err)
        }
      }
    },
    showVoiceCommandText(text) {
      this.$buefy.snackbar.open({
        message: text,
        type: 'is-info',
        position: 'is-bottom-right',
        duration: 2000,
        queue: false
      })
    },
    async showVoiceResult(text) {
      try {
        let voice = await witaiAPI.syntezise(text)
        new Audio('data:audio/wav;base64,' + voice.audioContent).play()
      } catch (err) {
        console.log(err)
      }
      this.$buefy.snackbar.open({
        message: text,
        type: 'is-warning',
        position: 'is-bottom-left',
        duration: 10000,
        queue: false
      })
    },
    async executeCommand(intent, entities) {
      let periods =
        entities && entities['wit$datetime:datetime'] && this.parseDatePeriod(entities['wit$datetime:datetime'])
      switch (intent.name) {
        case 'check_customer_visits':
          this.checkAppointments(periods)
          break
        case 'navigate_education':
          this.$router.push('/education/overview')
          break
        case 'navigate_targets':
          this.$router.push('/targets')
          break
        case 'check_average_sale':
          this.checkAverageSale(periods)
          break
        case 'check_average_services':
          this.checkAverageServices(periods)
          break
      }
    },
    async checkAppointments(periods) {
      const {completed, canceled} = await ReportsApi.getAppointmentsStats({
        from: periods && periods.period.from,
        to: periods && periods.period.to
      })
      let text
      let canceledValue = formatCurrency(canceled.value, this.company.currency)
      if (canceled.count) {
        text = `You had ${completed.count} completed appointments and {canceled.count} canceled appointments with a total value of ${canceledValue}`
      } else {
        text = `You had ${completed.count} completed appointments and no cancellations`
      }
      this.showVoiceResult(text)
    },
    async _getAverages(periods) {
      const [averageSale, previousAverageSale] = await Promise.all([
        ReportsApi.getAverageSale({
          from: periods && periods.period.from,
          to: periods && periods.period.to
        }),
        ReportsApi.getAverageSale({
          from: periods && periods.previousPeriod.from,
          to: periods && periods.previousPeriod.to
        })
      ])
      return {averageSale, previousAverageSale}
    },
    formatToDecimals(number) {
      return numeral(number).format('0.[00]')
    },
    async checkAverageServices(periods) {
      const {averageSale, previousAverageSale} = await this._getAverages(periods)
      let avgServices = this.formatToDecimals(averageSale.servicesPerSale)
      let text = `Average number of services per appointment is ${avgServices}.`

      let diff = averageSale.servicesPerSale > previousAverageSale.servicesPerSale
      let diffFormatted = Math.abs(this.formatToDecimals(averageSale.servicesPerSale))
      text += ` ${
        diff < 0 ? "The good news is that it's higher" : 'It is lower'
      } by ${diffFormatted} compared to the equal length period before`
      this.showVoiceResult(text)
    },
    async checkAverageSale(periods) {
      const {averageSale, previousAverageSale} = await this._getAverages(periods)
      let text = `Average sale value for the period is ${formatCurrency(
        averageSale.averageSale,
        this.company.currency
      )}.`
      let diff = previousAverageSale.averageSale - averageSale.averageSale
      let diffCurrency = formatCurrency(Math.abs(Math.round(diff)), this.company.currency)

      text += ` It is ${diff < 0 ? 'higher' : 'lower'} by ${diffCurrency} compared to the equal period before`

      this.showVoiceResult(text)
    },
    parseDatePeriod(entities) {
      if (entities.length === 1) {
        let period
        if (entities[0].from && entities[0].to) {
          period = {
            from: dayjs(entities[0].from.value).toDate(),
            to: dayjs(entities[0].to.value).toDate()
          }
        } else {
          period = {
            from: dayjs(entities[0].value).toDate(),
            to: dayjs(entities[0].value)
              .add(1, entities[0].grain)
              .toDate()
          }
        }

        let previousPeriod = {
          from: dayjs(period.from)
            .subtract(dayjs(period.to).diff(period.from, 'days'), 'days')
            .toDate(),
          to: period.from
        }
        return {period, previousPeriod}
      }
    }
  }
}
</script>
