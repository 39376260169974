<template lang="pug">
.is-height-100
  .columns.is-marginless.is-height-100
    .column.is-4.has-white-background.list-container.has-border-right
        .list-filters.has-border-bottom
          b-dropdown(aria-role="list" v-model="currentFolder" @change="setFolder")
            button.button.is-outlined(slot="trigger")
              span.icon
                i.mdi(:class="`mdi-${folders.find(f => f.value === currentFolder).icon}`")
              span {{folders.find(f => f.value === currentFolder).title}}    
              span.icon
                i.mdi.mdi-menu-down 
            b-dropdown-item(v-for="folder in folders" aria-role="listitem" :value="folder.value" :key="folder.value")
              span.icon
                i.mdi(:class="`mdi-${folder.icon}`")
              span {{folder.title}}

          b-dropdown(aria-role="list" :value="sortBy" @change="setSorting")
            button.button.is-white(slot="trigger")
              span {{sortByOptions.find(s => s.value === sortBy).title}}    
              span.icon
                i.mdi.mdi-menu-down 
            b-dropdown-item(v-for="sortByOption in sortByOptions" aria-role="listitem" :value="sortByOption.value" :key="sortByOption.value")
              span {{sortByOption.title}}
        scroll-bar.is-height-100.ticket-list
          em-loading(:active="loading" :is-full-page="false")
          .ticket-list-item.has-border-bottom(v-for="ticket in tickets", :class="ticket === selectedTicket ? 'is-selected': ''", @click="selectTicket(ticket)")
            .media
              .media-left
                ProfilePic.is-48x48(:customer="ticket.customer")
              .media-content
                div
                  p.title.is-6(v-if="ticket.customer") 
                    customer-name(:value="ticket.customer")
                  p.subtitle.is-6
                    span rated the visit with
                    |  
                    strong(v-for="(empl, idx) in ticket.employees") {{empl | nameShortify}}
                      span(v-if="idx !== ticket.employees.length - 1") ,  
                    |  
                    span.feedback(v-if="ticket.survey", :class="'feedback-' + ticket.survey.value") {{ticket.survey.value}}
              .media-right
                small.ticket-ts {{ticket.created | fromNowShort}}
                p.has-text-right
                  span.icon.has-text-warning(v-if="ticket.priority")
                    i.mdi.mdi-star.mdi-24px
                  span.icon.has-text-info(v-else-if="!ticket.read")
                    i.mdi.mdi-circle-medium.mdi-24px
          .pt-3(v-if="!loading && !tickets.length")
            section.section
              div.content.has-text-grey.has-text-centered(v-if="currentFolder === 'open'")
                p
                  b-icon(icon="emoticon-excited-outline" size="is-large")              
                p Hurray! You have no open customer care tickets.
              div.content.has-text-grey.has-text-centered(v-if="currentFolder === 'done'")
                p
                  b-icon(icon="close-circle-outline" size="is-large")              
                p You have no closed customer care tickets.

    .ticket-details-container.column.is-8
      TicketPreview(v-if="selectedTicket", :ticketId='selectedTicket._id')
</template>

<script>
import EmployeesTagList from '../shared/EmployeesTagList'
import TicketPreview from './TicketPreview'
import ProfilePic from '../customers/ProfilePic'
import {mapState, mapGetters, mapActions} from 'vuex'

export default {
  name: 'CustomerCare',
  components: {
    EmployeesTagList,
    TicketPreview,
    ProfilePic
  },
  data() {
    return {
      loading: false,
      currentFolder: 'open',
      selectedTicket: undefined,
      folders: [
        {value: 'open', title: 'Open', icon: 'inbox'},
        {value: 'done', title: 'Done', icon: 'check'}
      ],
      sortByOptions: [
        {value: 'newest', title: 'Newest'},
        {value: 'oldest', title: 'Oldest'},
        {value: 'priority', title: 'Priority first'}
      ]
    }
  },
  computed: {
    ...mapState('company', ['company']),
    ...mapState('tickets', ['sortBy']),
    ...mapGetters('tickets', ['openTickets', 'doneTickets']),
    tickets() {
      if (this.currentFolder === 'open') {
        return this.openTickets
      } else {
        return this.doneTickets
      }
    }
  },
  async created() {
    await this.fetchData('open')
  },
  methods: {
    ...mapActions('tickets', ['fetchOpenTickets', 'fetchDoneTickets', 'setSortBy']),
    async setFolder(folder) {
      await this.fetchData(folder)
    },
    async setSorting(order) {
      this.setSortBy(order)
      await this.fetchData(this.currentFolder)
    },
    async fetchData(status) {
      try {
        this.loading = true
        if (status === 'open') {
          await this.fetchOpenTickets()
        } else {
          await this.fetchDoneTickets()
        }
        this.selectedTicket = this.tickets[0]
      } catch (err) {
        this.$handleAPIError(err)
      }
      this.loading = false
    },
    async selectTicket(ticket) {
      this.selectedTicket = ticket
    }
  }
}
</script>

<style scoped>
.ticket-list-item {
  padding: 1rem;
  border-left: 2px solid transparent;
}
.ticket-list-item:hover {
  cursor: pointer;
  background: #f0f4fa;
}

.ticket-list-item.is-selected {
  background: #e8f0fe;
  border-left: 2px solid #315faa;
}
.list-container {
  padding: 0;
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}
.list-filters {
  padding: 1rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.ticket-list {
  flex-grow: 1;
}
.ticket-details-container {
  height: 100%;
  overflow: hidden;
  padding: 0;
}
.ticket-ts {
  vertical-align: 2px;
}
</style>
