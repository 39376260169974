import Vue from 'vue'

export default {
  list(customerId) {
    return Vue.http.get(`rewards/${customerId}`)
  },
  makeMember(customerId, data) {
    return Vue.http.put(`rewards/${customerId}/member`, data)
  },
  setMembershipTier(customerId, tier) {
    return Vue.http.put(`rewards/${customerId}/membership`, {tier})
  },
  addGiftPoints(customerId, {points, reason}) {
    return Vue.http.post(`rewards/${customerId}/gift`, {points, reason})
  },
  remove(rewardId) {
    return Vue.http.delete(`rewards/${rewardId}`)
  }
}
