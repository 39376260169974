<template lang="pug">
.modal-card
  header.modal-card-head
    p.modal-card-title
      b(v-t="'rewards.tier_upgrade.title'")
    div
      a.button.is-white.is-small(@click="$emit('close')")
        span.icon
          i.mdi.mdi-close.mdi-24px
  section.modal-card-body(v-if="customer")
    div(v-if="!canUpgradeMembership") 
      p(v-t="'rewards.tier_upgrade.already_top'")
    form(v-on:submit.prevent="submit" v-else)
      b-field(:label="$t('rewards.tier_label')")
        b-select(v-model="currentMembership" )
          option(v-for="(title, idx) in company.membership.titles" :value="idx" v-if="idx >= (customer.effectiveMembership !== undefined ? customer.effectiveMembership : customer.membership)") 
            |  {{title}} 
            percentage-view(:value="company.membership.pointRates[idx]")
  footer.modal-card-foot
    .buttons
      action-button(@click="submit" :disabled="!canUpgradeMembership" :loading="submitting" title="actions.save" permission="REWARDS_MEMBERSHIP_UPGRADE")
      button.button.is-outlined(type="button", @click="cancel" v-t="'actions.cancel'")
</template>

<script>
import {mapState, mapActions} from 'vuex'
import RewardsApi from '@/api/rewards'

export default {
  name: 'MembershipEditForm',
  props: {
    customerId: {type: String, required: true}
  },
  data() {
    return {
      currentMembership: undefined,
      submitting: false,
      customer: undefined
    }
  },
  computed: {
    ...mapState('company', ['company']),
    canUpgradeMembership() {
      if (!this.customer) {
        return false
      }
      let membership =
        this.customer.effectiveMembership !== undefined ? this.customer.effectiveMembership : this.customer.membership
      return membership < this.company.membership.titles.length - 1
    }
  },
  async created() {
    this.customer = await this.fetchCustomer(this.customerId)
    this.currentMembership = this.customer.membership
  },
  methods: {
    ...mapActions('customers', ['fetchCustomer']),
    cancel() {
      this.$emit('close')
    },
    async submit() {
      if (this.submitting) {
        return
      }
      this.submitting = true
      try {
        await RewardsApi.setMembershipTier(this.customerId, this.currentMembership)
        this.$emit('close')
      } catch (err) {
        this.$handleAPIError(err)
      }
      this.submitting = false
    }
  }
}
</script>
