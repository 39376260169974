<template lang="pug">
.box.voucher.has-text-centered
  p.title.is-4 {{voucher.name}}
  p.subtitle.has-text-almost-white.is-6.mb-3(v-t="'vouchers.multiple_use_label'")
  p.mb-3.is-size-5 
    b
      currency-view.is-size-3(:value="voucher.remaining")  
    | /
    currency-view(:value="voucher.value")  
  p.has-text-semi-white(v-t="'vouchers.coupon_code_label'")
  .mb-3
    p.mb-0
      b.is-size-4 {{voucher.code}}
    p.help {{$t(`sale.invoice_number`, {invoice: voucher.invoice})}}
  hr
  .has-text-almost-white
    p.mb-2(v-if="voucher.notes") {{voucher.notes}}
    b-collapse(animation="slide" v-if="voucher.services.length" :open="false")
      template(#trigger="props")
        a.has-text-white.has-text-underline {{$tc('vouchers.number_of_services',voucher.services.length)}}
      .list-item(v-for="{service, option} in voucher.services")
        p 
          service-name-view(:value="{service, option}")
    span(v-else) {{$tc('vouchers.number_of_services',voucher.services.length)}}

  p.mt-3.has-text-almost-white 
    span(v-if="new Date(voucher.expire) > new Date()")
      span(v-t="'misc.valid_til'")  
      |  
      date-view.has-text-almost-white(:value="voucher.expire" format="D MMM YYYY")  
    template(v-else)
      span.tag.is-danger
        span(v-t="'validity.expired'") 
      template(v-if="$rfHasPermission('VOUCHERS_EXTEND')")
        br
        a.has-text-white.has-text-underline(@click="extend") {{$t('actions.extend')}}
</template>
<script>
import VouchersApi from '@/api/vouchers'
import dayjs from 'dayjs'
import cloneDeep from 'lodash/cloneDeep'
export default {
  name: 'VoucherView',
  props: {
    value: {type: Object, required: true},
    expandServices: {type: Boolean, default: false}
  },
  data() {
    return {
      voucher: undefined
    }
  },
  watch: {
    value(val) {
      this.voucher = cloneDeep(val)
    }
  },
  created() {
    this.voucher = cloneDeep(this.value)
  },
  methods: {
    async extend() {
      this.$buefy.dialog.confirm({
        title: this.$t('vouchers.extend_confirm.title'),
        message: this.$t('vouchers.extend_confirm.body'),
        confirmText: this.$t('actions.confirm'),
        cancelText: this.$t('actions.cancel'),
        onConfirm: async () => {
          try {
            let voucher = await VouchersApi.updateVoucher(this.voucher._id, {
              expire: dayjs().add(1, 'month')
            })
            Object.assign(this.voucher, voucher)
            this.$buefy.toast.open({
              message: this.$t('status.save_success'),
              queue: false,
              type: 'is-success',
              position: 'is-top'
            })
            this.$emit('change', this.voucher)
            this.$emit('close')
          } catch (err) {
            this.$handleAPIError(err)
          }
        }
      })
    }
  }
}
</script>
<style scoped>
.voucher {
  max-width: 300px;
  border-radius: 16px;
  background-color: #21d4fd;
  background-image: linear-gradient(19deg, #21d4fd 0%, #b721ff 100%);
}
.voucher hr {
  background-color: rgba(255, 255, 255, 0.5);
}
.voucher p {
  color: white;
}
</style>
