<template lang="pug">
  div(data-qa="staff-resources")
    screen-title(title="resources_settings.title_schedules")
    b-field(grouped)
      b-field
        localized-datepicker(v-model="startOfWeek" :week-start-mode="true" :date-formatter="dateFormatter")
    .columns
      .column
        em-loading(:active="submitting" :is-full-page="false")
        table.table.is-fullwidth
          thead
            tr
              th(width="200") {{$t('resources_settings.name_heading')}}
              td.has-text-centered(v-for="{weekday, date} in weekdays" width="160") 
                strong {{$t(`working_hours.weekdays.${weekday}`)}}
                br
                small 
                  date-view(:value="date" format="DD MMM")
          tbody
            tr(v-for="resource in resources")
              th
                p.title.is-6 {{resource.name}} 
                p.subtitle.is-7 
                  location-view(:value="resource.location") 
              td(v-for="{weekday, date} in weekdays" @click="$rfHasPermission('RESOURCES_SCHEDULE_EDIT') && editSchedule({resource, weekday, date})") 
                ScheduleDayView(v-bind="getActualSchedule({resource, date, weekday})" )
        p.title.is-6
          span.icon.has-text-success
            i.mdi.mdi-square
          span(v-t="'working_hours.repeat_schedule_legend'")
          span.icon.has-text-warning
            i.mdi.mdi-square
          span(v-t="'working_hours.that_day_only_legend'")
</template>
<script>
import ScheduleDayView from './ScheduleDayView'
import ResourcesApi from '@/api/resources'
import {mapGetters, mapActions} from 'vuex'
import EditResourceSchedule from './EditResourceSchedule'
import dayjs from 'dayjs'
const WEEKDAYS = ['sun', 'mon', 'tue', 'wed', 'thu', 'fri', 'sat']
export default {
  name: 'Schedules',
  components: {
    ScheduleDayView
  },
  data() {
    return {
      submitting: false,
      customSchedule: [],
      loading: false,
      startOfWeek: dayjs()
        .startOf('week')
        .toDate()
    }
  },
  computed: {
    ...mapGetters('resources', ['resources']),
    weekdays() {
      let days = []
      for (let i = 0; i < 7; i++) {
        let day = dayjs(this.startOfWeek).add(i, 'days')

        days.push({
          weekday: WEEKDAYS[day.day()],
          date: day.toDate()
        })
      }
      return days
    }
  },
  created() {
    this.fetchData()
  },
  methods: {
    ...mapActions('resources', ['fetchResources', 'updateResource']),
    async fetchData() {
      this.loading = true
      try {
        await this.fetchResources(true)
        this.customSchedule = await ResourcesApi.getSchedules({
          weekStart: parseInt(dayjs(this.startOfWeek).format('YYYYMMDD'))
        })
      } catch (err) {
        this.$handleAPIError(err)
      }
      this.loading = false
    },
    getCustomShift(resource, date) {
      return this.customSchedule.find(schedule => {
        return schedule.resource === resource._id && schedule.day === parseInt(dayjs(date).format('YYYYMMDD'))
      })
    },
    getActualSchedule({resource, weekday, date}) {
      let custom = this.getCustomShift(resource, date)

      return {
        schedule: (resource.schedule && resource.schedule[weekday]) || [],
        customSchedule: custom && custom.schedule,
        custom: !!custom,
        resourceId: resource._id
      }
    },
    editSchedule({resource, weekday, date}) {
      let {schedule, customSchedule} = this.getActualSchedule({resource, weekday, date})
      this.$buefy.modal.open({
        parent: this,
        component: EditResourceSchedule,
        props: {
          value: {
            schedule,
            customSchedule
          },
          weekday,
          date
        },
        hasModalCard: true,
        events: {
          input: async ({schedule, customSchedule}) => {
            this.updateSchedule({resource, weekday, date, schedule, customSchedule})
          }
        },
        canCancel: ['escape']
      })
    },
    async updateSchedule({resource, weekday, date, schedule, customSchedule}) {
      this.submitting = true
      try {
        let data = {...resource}
        data.schedule = data.schedule || {}
        data.schedule[weekday] = schedule

        await this.updateResource({id: resource._id, data})
        if (customSchedule) {
          await ResourcesApi.setResourceSchedule(resource._id, {
            day: parseInt(dayjs(date).format('YYYYMMDD')),
            schedule: customSchedule
          })
        } else {
          await ResourcesApi.removeResourceSchedule(resource._id, parseInt(dayjs(date).format('YYYYMMDD')))
        }

        await this.fetchData()

        this.$buefy.toast.open({
          message: this.$t('status.save_success'),
          type: 'is-success',
          queue: false
        })
      } catch (err) {
        this.$handleAPIError(err)
      }
      this.submitting = false
    },
    dateFormatter(date) {
      return `${dayjs(date).format('MMM D')} - ${dayjs(date)
        .add(6, 'days')
        .format('MMM D')}`
    }
  }
}
</script>
<style scoped>
table.table {
  background: transparent;
}
table.table td,
table.table th {
  vertical-align: middle;
}
table.table td {
  background: white;
  cursor: pointer;
}
</style>
