<template lang="pug">
  div
    article.msg.msg-left(:class="!valid ? 'is-invalid' : ''" style="width: 100%")
      ContentEditable.msg-text(v-model="message.content" placeholder="Enter message content here" @input="onInput" :editable="!readonly")
    p.pr-3.has-text-right.is-size-7(v-if="message.content")
      span {{smsCharacters.length}} / {{maxLength}}  
      b-tooltip(label="Unicode text" v-if="smsCharacters.encoding === 'UTF16'") 
        span.has-text-danger unicode
</template>
<script>
import ContentEditable from '../ContentEditable'
import BaseElement from './BaseElement'
import SmsCounter from '../../../../shared/smsHelper'
export default {
  name: 'SMSElement',
  components: {
    ContentEditable
  },
  extends: BaseElement,
  props: {
    limitToOne: {type: Boolean, default: false},
    bridgeLink: {type: String, default: ''}
  },
  computed: {
    valid() {
      return this.message.content && this.message.content.length <= this.maxLength
    },

    smsCharacters() {
      if (this.message.content) {
        return SmsCounter.count(
          this.message.content
            .trim()
            .replace(/[ \u00A0\u2028\u2029]+/g, ' ')
            .replace(/{{\s*link\s*}}/, this.bridgeLink)
        )
      }
      return undefined
    },
    maxLength() {
      let multiplier
      if (!this.message.content) {
        return undefined
      }
      if (this.limitToOne) {
        multiplier = 1
      } else {
        multiplier = this.smsCharacters.remaining === 0 ? this.smsCharacters.messages + 1 : this.smsCharacters.messages
      }

      return this.smsCharacters.per_message * multiplier
    },
    linkLength() {
      if (!this.message.content) {
        return
      }
      let linkPlaceholder = this.message.content.match(/{{\s*link\s*}}/g)
      if (linkPlaceholder) {
        return (this.bridgeLink ? ' ' + this.bridgeLink : '').length - linkPlaceholder[0].length
      }
      return 0
    }
  },
  methods: {
    onInput() {
      this.message.bridgeLink = this.message.content && !!this.message.content.match(/{{\s*link\s*}}/g)
      this.message.valid = this.valid

      this.$emit('input', this.message)
    }
  }
}
</script>
