<template lang="pug">
  div
    screen-title(title="languages_settings.title")
    .box
      form(v-on:submit.prevent="submit")
        b-field(:label="$t('languages_settings.tone_of_voice_label')", :type="errors.has('chatTone') ? 'is-danger': ''" :message="errors.has('chatTone') ? errors.first('chatTone') : ''")
          b-select(v-model="chatTone" icon="emoticon-excited-outline" v-validate="'required'" name="chatTone" placeholder="Select a tone of voice" data-vv-as="tone of voice" expanded)
              option(v-for="tone in chatTones" :value="tone.value") {{tone.title}}

        b-tabs(v-model="activeExampleTab" v-if="chatTone")
          b-tab-item(v-for="examples in chatToneExamples" :key="examples.lng" :label="$t(`languages.${examples.lng}`)")
            .p-4
              .columns.messages-examples
                .column(v-for="sample in examples.texts")
                  span.messenger-buble {{sample}}

        b-field(:label="$t('languages_settings.enabled_languages_label')")
          b-taginput(v-model="selectedLanguages" :data="filteredLanguages" autocomplete :allow-new="false" field="title" icon="label" :placeholder="$t('languages_settings.select_language_placeholder')" @typing="getFilteredTags" @remove="languageRemoved" type="is-primary")
        
        b-field(:label="$t('languages_settings.default_language_label')", :type="errors.has('defaultLanguage') ? 'is-danger': ''" :message="errors.has('defaultLanguage') ? errors.first('defaultLanguage') : ''")
          b-select(v-model="defaultLanguage" icon="earth" v-validate="'required'" name="defaultLanguage" placeholder="Select a default language" data-vv-as="default language" expanded)
              option(v-for="language in selectedLanguages" :value="language.value") {{language.title}}
    .buttons
        action-button(title="actions.save" :loading="submitting" @click="submit" permission="SETTINGS_LOCATIONS_MANAGE" scope)
</template>

<script>
import StaticApi from '@/api/static'
import {mapState} from 'vuex'

export default {
  name: 'LanguagesSettings',
  data() {
    return {
      submitting: false,
      chatTones: [],
      languages: [],
      filteredLanguages: [],
      activeExampleTab: 0,
      chatTone: '',
      defaultLanguage: '',
      selectedLanguages: []
    }
  },
  computed: {
    ...mapState('company', ['company']),
    chatToneExamples() {
      if (this.chatTone) {
        return this.chatTones.find(el => el.value === this.chatTone).examples
      }
      return []
    }
  },
  async created() {
    this.chatTones = await StaticApi.getChatTones()
    this.languages = (await StaticApi.getLanguages()).map(lng => {
      return {
        value: lng,
        title: this.$t(`languages.${lng}`)
      }
    })
    this.selectedLanguages = this.languages.filter(option => {
      return this.company.languages.indexOf(option.value) >= 0
    })
    this.defaultLanguage = this.company.defaultLanguage
    this.chatTone = this.company.chatTone
  },
  methods: {
    getFilteredTags(text) {
      this.filteredLanguages = this.languages.filter(option => {
        return (
          option.title
            .toString()
            .toLowerCase()
            .indexOf(text.toLowerCase()) >= 0
        )
      })
    },
    languageRemoved(lng) {
      if (this.defaultLanguage === lng.value) {
        this.defaultLanguage = undefined
      }
    },
    async submit() {
      const valid = await this.$validator.validateAll()
      if (!valid) {
        return
      }
      this.submitting = true
      const data = {
        chatTone: this.chatTone,
        languages: this.selectedLanguages.map(l => l.value),
        defaultLanguage: this.defaultLanguage,
        _updateType: 'chatbot'
      }
      try {
        await this.$store.dispatch('company/update', data)
        this.$buefy.toast.open({message: this.$t('status.save_success'), type: 'is-success', queue: false})
      } catch (error) {
        this.$handleAPIError(error)
      }
      this.submitting = false
    }
  }
}
</script>

<style scoped>
.messenger-buble {
  background-color: #0084ff;
  color: #fff;
  max-width: 180px;
  border-radius: 16px;
  padding: 10px;
  display: inline-block;
}
.messages-examples {
  margin-bottom: 20px;
}
</style>
