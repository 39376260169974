<template lang="pug">
.list-item.is-flex.is-align-items-center.p-3.is-clickable(@click="$emit('click')")
    .is-flex-shrink-0.has-text-centered.p-3.mr-2(style="width: 54px" v-if="showDate")
      p.title.is-4.m-0(style="white-space: nowrap") 
        date-view(:value="value.start" format="D" :timezone="timezone")
      p.subtitle.is-7.m-0 
        date-view(:value="value.start" format="MMM" :timezone="timezone")
      
    .is-flex-grow-1
      .tags.m-0
        AppointmentStatusIcon.is-small.mb-0(:status="value.status" :showText="true" :isTag="true" v-if="showAllStatuses || value.status !== 'new'") 
        .tag.is-primary.mb-0(v-if="showLocation && hasMultipleLocations") 
          span.icon.is-small
            i.mdi.mdi-map-marker
          location-view(:value="value.location")
        .tag.is-warning.mb-0(v-if="showSource && scope === 'business' && value.source === APPOINTMENT_SOURCE.ONLINE")
          span.icon.is-small
            i.mdi.mdi-web
          span online
        
      p.is-size-6.mt-1.mb-1
        span
          date-view(:value="value.start" format="dddd, D MMM YYYY," :timezone="timezone")
          |   
          span.has-text-grey
            span {{formattedStartTime}}
            |  -   
            span {{formattedEndTime}}
        
      AppointmentItem.py-1(v-for="service in value.services" :service="service" :key="service._id" :show-price="showPrice" :scope="scope")
</template>
<script>
import AppointmentItem from './AppointmentItem'
import AppointmentStatusIcon from './AppointmentStatusIcon'
import formatter from '../../../shared/formatter'
import APPOINTMENT_SOURCE from '../../../constants/appointmentSource'
import dayjs from 'dayjs'
import {mapGetters, mapState} from 'vuex'
export default {
  name: 'AppointmentCard',
  components: {
    AppointmentItem,
    AppointmentStatusIcon
  },
  props: {
    value: {type: Object, required: true},
    showAllStatuses: {type: Boolean, default: true},
    showLocation: {type: Boolean, default: false},
    timezone: {type: String, default: undefined},
    showPrice: {type: Boolean, default: true},
    showDate: {type: Boolean, default: true},
    showSource: {type: Boolean, default: true},
    scope: {type: String, default: 'business'}
  },
  data() {
    return {
      APPOINTMENT_SOURCE
    }
  },
  computed: {
    ...mapState('company', ['company']),
    ...mapGetters('location', ['hasMultipleLocations']),
    formattedStartTime() {
      return formatter.formatTime(this.value.start, this.company.timeFormat, this.timezone)
    },
    formattedEndTime() {
      return formatter.formatTime(
        dayjs(this.value.start)
          .add(
            this.scope === 'business' ? this.value.duration : this.value.clientDuration || this.value.duration,
            'minutes'
          )
          .toDate(),
        this.company.timeFormat,
        this.timezone
      )
    }
  }
}
</script>
