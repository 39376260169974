<template lang="pug">
div
  screen-title(title="calendar_settings.title")
  .box
    h2.title.is-4 {{$t('calendar_settings.rebooking_title')}}
    p.subtitle.is-6 {{ $t('calendar_settings.appointment_rebooking_subtitle') }}
    b-field()
      b-switch(v-model="crmSettings.rebooking") {{ $t('calendar_settings.rebooking_enabled') }}
    b-field(:label="$t('calendar_settings.rebooking_window_label')" :message="$t('calendar_settings.rebooking_window_help')")
      b-select(v-model="crmSettings.rebookingWindow" :disabled="!crmSettings.rebooking")
        option(:value="12") 12 {{ $t('misc.hours') }}
        option(:value="24") 24 {{ $t('misc.hours') }} ({{ $t('misc.recommended') }})
        option(:value="36") 36 {{ $t('misc.hours') }}
        option(:value="48") 48 {{ $t('misc.hours') }} 
  .box
    h2.title.is-4 {{$t('calendar_settings.calendar_settings_title')}}
    b-field(grouped)
      b-field(:label="$t('calendar_settings.calendar_from_time_label')")
        timepicker(
          icon="clock-outline" 
          :placeholder="$t('calendar_settings.calendar_from_time_placeholder')" 
          :max-time="crmSettings.calendarToTime" 
          v-model="crmSettings.calendarFromTime")
      b-field(:label="$t('calendar_settings.calendar_to_time_label')")
        timepicker(
          icon="clock-outline" 
          :placeholder="$t('calendar_settings.calendar_to_time_placeholder')"  
          :min-time="crmSettings.calendarFromTime" 
          v-model="crmSettings.calendarToTime" )
    b-field(:label="$t('calendar_settings.default_scope_view')")
      b-select(v-model="crmSettings.defaultView")
        option(value="staff")  {{ $t('calendar_settings.staff_view') }}
        option(value="resource") {{ $t('calendar_settings.resource_view') }}
    
    b-field(:label="$t('calendar_settings.appointment_timezone_label')" :message="$t('calendar_settings.appointment_timezone_description')")
      b-switch(v-model="crmSettings.clientTimezone" type="is-success") {{crmSettings.clientTimezone ? $t('calendar_settings.client_timezone_enabled') : $t('calendar_settings.client_timezone_disabled')}}
    
    b-field(:label="$t('calendar_settings.service_time_auto_adjust_label')" :message="$t('calendar_settings.service_time_auto_adjust_description')")
      b-switch(v-model="crmSettings.disableTimeAutoAdjust" type="is-danger") {{crmSettings.disableTimeAutoAdjust ? $t('misc.disabled') : $t('misc.enabled')}}
  .buttons
    action-button(title="actions.save" @click="submit" :loading="submitting" permission="REMINDERS_MANAGE" scope)
    
    //.box
      .hero
        .hero-body.has-text-centered
          span.is-large
            i.mdi.mdi-bell.mdi-48px
          h2.title.is-3 Status change notifications 
          p.subtitle Coming soon
</template>

<script>
import {mapState, mapActions} from 'vuex'

export default {
  name: 'CalendarSettings',
  data() {
    return {
      submitting: false,
      crmSettings: {
        calendarFromTime: undefined,
        calendarToTime: undefined,
        clientTimezone: undefined,
        disableTimeAutoAdjust: undefined,
        defaultView: undefined
      }
    }
  },
  computed: {
    ...mapState('company', ['company'])
  },
  created() {
    Object.assign(this.crmSettings, this.company.crm)

    this.crmSettings.calendarFromTime = this.crmSettings.calendarFromTime || 0
    this.crmSettings.defaultView = this.crmSettings.defaultView || 'staff'
    this.crmSettings.calendarToTime = this.crmSettings.calendarToTime || 24 * 60
    this.crmSettings.clientTimezone = !!this.crmSettings.clientTimezone
    this.crmSettings.disableTimeAutoAdjust = !!this.crmSettings.disableTimeAutoAdjust
  },
  methods: {
    ...mapActions('company', ['update']),
    async submit() {
      const valid = await this.$validator.validateAll()
      if (!valid) {
        return
      }

      this.submitting = true
      try {
        await this.update({crm: this.crmSettings})
        this.$buefy.toast.open({message: this.$t('status.save_success'), type: 'is-success', queue: false})
      } catch (error) {
        this.$handleAPIError(error)
      }
      this.submitting = false
    }
  }
}
</script>
