<template lang="pug">
div
  screen-title(title="ledger_settings.title")
  .box
    note.is-outlined.mb-5(type="is-info")
      p.subtitle.is-6(v-t="'ledger_settings.description'")

    b-field(:label="$t('ledger_settings.enable_label')")
      b-switch(v-model="enabled") {{enabled ? $t('misc.enabled') : $t('misc.disabled')}}
    b-field(:label="$t('ledger_settings.commission_tracking_label')")
      b-switch(v-model="commissions" :disabled="!enabled") {{commissions ? $t('misc.enabled') : $t('misc.disabled')}}
  .buttons
    action-button(@click="submit" title="actions.save" permission="SETTINGS_CASH_REGISTER_MANAGE" :loading="submitting")
</template>
<script>
import {mapActions, mapState} from 'vuex'

export default {
  data() {
    return {
      enabled: false,
      commissions: false,
      submitting: false
    }
  },
  computed: {
    ...mapState('company', ['company'])
  },
  created() {
    this.enabled = !!this.company.cashRegister
    this.commissions = !!this.company.registerCommissions
  },
  methods: {
    ...mapActions('company', ['update']),
    async submit() {
      if (this.submitting) {
        return
      }
      const valid = await this.$validator.validateAll()
      if (!valid) {
        return
      }
      this.submitting = true
      try {
        await this.update({cashRegister: this.enabled, registerCommissions: this.commissions})
        this.$buefy.toast.open({message: this.$t('status.save_success'), type: 'is-success', queue: false})
      } catch (err) {
        this.$handleAPIError(err)
      }
      this.submitting = false
    }
  }
}
</script>
