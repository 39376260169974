<template lang="pug">
#app
  .wrapper 
    .app-container
      .inner-wrapper
        .side-menu.is-hidden-mobile.is-collapsed(v-if="isReady && !$route.meta.cleanview && !hideUnprintable")
          .menu-holder
            //.menu-toggle
              img(src="@/assets/white_logo_unwrapped.png" style="opacity: 0.9")
              //a(@click="collapsed = !collapsed")
                span.icon.is-medium.has-text-grey
                  i.mdi.mdi-arrow-collapse-left.mdi-24px(aria-hidden="true" v-if="!collapsed")
                  i.mdi.mdi-arrow-expand-right.mdi-24px(aria-hidden="true" v-else)
            scroll-bar.is-height-100
              router-view(name="navbar")
        .right-side(:class="{'has-lightgrey-background' : isReady && !$route.meta.cleanview}" v-if="!hideUnprintable")
            //.is-flex.is-flex-direction-column.is-flex-grow-1
            warning-bar(v-if="isReady && !$route.meta.cleanview && !hideUnprintable")
            top-navbar.top-navbar(v-if="isReady && !$route.meta.cleanview && !hideUnprintable")
            .is-flex.is-flex-grow-1.is-clipped.main-content-wrapper
              router-view(name="menu" )
              .is-flex-grow-1.is-flex.is-flex-direction-column.main-content-holder.is-clipped
                router-view(name="tabsmenu" v-if="!$route.meta.product || $rfSubscribed($route.meta.product)")
                .is-flex.is-flex-grow-1(style="overflow: auto")
                  //(:class="$route.meta.background === 'white' ? '' : 'has-lightgrey-background'")
                  router-view.is-flex-grow-1(v-if="!$route.meta.product || $rfSubscribed($route.meta.product)")
                  ProductTeaser(:product="$route.meta.product" v-else)
                mobile-navbar(v-if="isReady && !$route.meta.cleanview && !hideUnprintable")
        AssistantPopup(v-if="isReady && !$route.meta.cleanview && $rfSubscribed(PRODUCTS.VOICE_ASSIST) && !hideUnprintable")
        CallHandler(v-if="!isMobile()")
</template>

<script>
import {mapState} from 'vuex'
import TopNavbar from './components/shared/TopNavbar'
import WarningBar from './components/shared/WarningBar'
import MobileNavbar from './components/MobileNavbar'
import Details from './components/customers/Details'

import ProductTeaser from './components/billing/ProductTeaser'
import CallHandler from './components/call/CallHandler'
import AssistantPopup from './voi/AssistantPopup'

import dayjs from 'dayjs'
import APPOINTMENT_STATUS from '../constants/appointmentStatus'
import {PRODUCTS} from '../constants/products'
import CustomerDetailsMobile from './components/customers/CustomerDetailsMobile.vue'

export default {
  name: 'App',
  components: {
    TopNavbar,
    WarningBar,
    ProductTeaser,
    MobileNavbar,
    AssistantPopup,
    CallHandler
  },
  data() {
    return {
      collapsed: true,
      internetIssueBar: undefined,
      newVersionBar: undefined,
      hideUnprintable: false,
      PRODUCTS
    }
  },
  computed: {
    ...mapState('auth', ['user']),
    ...mapState('company', ['company']),
    isReady() {
      return this.user && this.company
    }
  },
  sockets: {
    connect() {
      if (this.internetIssueBar) {
        this.internetIssueBar.close()
        this.internetIssueBar = undefined
      }
      console.log('socket connected')
    },
    disconnect() {
      if (!this.user) {
        return
      }
      if (this.internetIssueBar) {
        return
      }
      this.internetIssueBar = this.$buefy.snackbar.open({
        message: this.$t('error.socket_disconnected'),
        type: 'is-warning',
        position: 'is-top',
        actionText: null,
        indefinite: true,
        queue: false
      })
    },
    VERSION(version) {
      console.log('Bundle version:', version)
      if (version !== process.env.VUE_APP_VERSION && !this.newVersionBar) {
        this.newVersionBar = this.$buefy.snackbar.open({
          message: this.$t('notification.new_version'),
          type: 'is-warning',
          position: 'is-top',
          actionText: this.$t('actions.refresh'),
          onAction: () => {
            if ('serviceWorker' in navigator) {
              navigator.serviceWorker.getRegistrations().then(function(registrations) {
                registrations.map(r => {
                  r.unregister()
                })
              })
            }
            window.location.reload()
          },
          indefinite: true,
          queue: false
        })
      }
    },

    NEW_VERIFIED_REVIEW(data) {
      let message = `<b>${data.customer.firstName} ${data.customer.lastName}</b> left a <b>[${data.reviewTitle}]</b> review on ${data.platform}`
      this.$buefy.snackbar.open({
        duration: 5000,
        message: message,
        type: 'is-warning',
        position: 'is-top-right',
        actionText: 'OPEN',
        queue: false,
        onAction: () => {
          this.$router.push('/reviews/verified')
        }
      })
    },
    APPOINTMENT_CREATED(data) {
      if (
        this.user._id === data.user ||
        data.status === APPOINTMENT_STATUS.TIME_BLOCK ||
        data.status === APPOINTMENT_STATUS.DRAFT
      ) {
        return
      }
      let date = dayjs(data.start).format('L LT')
      let filter = data.services.find(s => s.staff) ? 'staff' : 'resource'
      this.$buefy.snackbar.open({
        indefinite: true,
        message: this.$t('notifications.new_appointment', {date}),
        type: 'is-warning',
        position: 'is-top-right',
        actionText: this.$t('actions.view'),
        cancelText: this.$t('actions.close'),
        queue: false,
        onAction: () => {
          this.$router.push({
            name: 'Calendar',
            query: {
              location: data.location,
              date: dayjs(data.start).format('YYYY-MM-DD'),
              filter,
              appointment: data._id
            }
          })
        }
      })
    },
    SURVEY_RESPONSE(data) {
      let message
      if (data.question === 'feedback') {
        message = `<b>${data.customer.firstName} ${data.customer.lastName}</b> rated the visit <b>[${data.value} out of 5]</b>`
      } else if (data.question === 'nps') {
        message = `<b>${data.customer.firstName} ${data.customer.lastName}</b> rated NPS as <b>[${data.value} out of 10]</b>`
      }
      this.$buefy.snackbar.open({
        duration: 5000,
        message: message,
        type: 'is-warning',
        position: 'is-top-right',
        actionText: 'OPEN',
        queue: false,
        onAction: () => {
          this.showCustomerDetails(data.customer._id)
        }
      })
    }
  },
  created() {
    window.onbeforeprint = () => {
      this.hideUnprintable = true
      // let modals = document.getElementsByClassName('modal')
      // for (var i = 0; i < modals.length; i++) {
      //   modals[i].classList.add('is-full-screen')
      // }
    }
    window.onafterprint = () => {
      this.hideUnprintable = false
      // let modals = document.getElementsByClassName('modal')
      // for (var i = 0; i < modals.length; i++) {
      //   modals[i].classList.remove('is-full-screen')
      // }
    }
  },
  methods: {
    showCustomerDetails(customerId) {
      this.$buefy.modal.open({
        parent: this,
        component: this.isMobile() ? CustomerDetailsMobile : Details,
        fullScreen: true,
        hasModalCard: true,
        props: {customerId},
        canCancel: ['escape', 'outside']
      })
    }
  }
}
</script>
<style scoped>
.menu-toggle {
  padding: 1.15rem 2.5rem 1rem 1.5rem;
  text-align: center;
}
.menu-toggle a {
  color: #ffffff;
}
.main-content-wrapper {
  flex-direction: row;
}
@media screen and (max-width: 1024px) {
  .main-content-wrapper {
    flex-direction: column;
  }
}
.main-content-holder {
  /* border-radius: 16px 16px 0 0;
  margin-right: 1rem; */
}
.main-content-holder:first-child {
  /* margin-left: 1rem; */
}
</style>
