<template lang="pug">
  .fullscreen-modal
    header.top-navbar.is-hidden-print 
      h2.title.is-4(style="margin-right: auto;margin-bottom: 0" v-t="title" data-qa="full-screen-header")
      //h2.is-size-5(style="margin-right: auto" v-t="title" data-qa="full-screen-header").is-hidden-tablet
      div.is-hidden-print 
        a.button.is-white(@click="$emit('close')")
          span.icon
            i.mdi.mdi-close.mdi-24px
    .main-content
      //.section
      slot
    slot.is-hidden-print(name="footer")
</template>
<script>
export default {
  props: {
    title: {type: String, required: true}
  }
}
</script>
<style scoped>
.fullscreen-modal {
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  flex-grow: 1;
  overflow: hidden;
}
.section {
  padding: 1.5rem;
}
</style>
