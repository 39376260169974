<template lang="pug">
  span(v-if="value !== undefined") {{percentage}}
</template>

<script>
import numeral from 'numeral'
export default {
  name: 'PercentageView',
  props: {
    value: {type: Number, default: undefined},
    precision: {type: Number, default: 0}
  },
  computed: {
    percentage() {
      let format
      switch (this.precision) {
        case 0:
          format = '0%'
          break
        case 1:
          format = '0.[0]%'
          break
        case 2:
          format = '0.[00]%'
          break
        default:
          format = '0%'
          break
      }

      return numeral(this.value).format(format)
    }
  }
}
</script>
