<template lang="pug">
  div(v-if="ticket")
    form(v-on:submit.prevent="addNote")
      .field
        .control
          textarea.textarea(placeholder="enter your note here", v-model="noteText")
      .field
        .control
          action-button(@click="addNote" title="Add a note" :loading="submitting" permission="CUSTOMER_CARE_MANAGE_TICKETS")
    
    div(v-for="activity in ticket.activities")
      hr
      .level.is-marginless
        .level-left
          .level-item
            label.label {{activity.user.firstName}} {{activity.user.lastName}} 
        .level-right
          .level-item
            em.has-text-grey 
              small {{activity.date | fulldate}}
      .box(v-if="activity.action === 'note'")
        p {{activity.note}}
      p(v-if="activity.action === 'resolve'")
        span.tag.is-success resolved
      p(v-if="activity.action === 'reopen'")
        span.tag.is-warning reopened
</template>

<script>
import {mapActions} from 'vuex'

export default {
  props: {
    ticketId: {type: String, required: true}
  },
  data() {
    return {
      noteText: '',
      ticket: undefined,
      loading: false,
      submitting: false
    }
  },
  watch: {
    ticketId() {
      return this.loadTicket()
    }
  },
  created() {
    return this.loadTicket()
  },
  methods: {
    ...mapActions('tickets', ['fetchTicket', 'addTicketNote']),
    async loadTicket() {
      this.loading = true
      this.ticket = await this.fetchTicket(this.ticketId)
      this.loading = false
    },
    async addNote() {
      if (!this.noteText || this.submitting) {
        return
      }
      this.submitting = true
      try {
        await this.addTicketNote({id: this.ticketId, note: this.noteText})
        this.noteText = ''
      } catch (err) {
        this.$handleAPIError(err)
      }
      this.submitting = false
    }
  }
}
</script>
