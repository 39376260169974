import Vue from 'vue'

export default {
  getNotes(params) {
    return Vue.http.get('notes', {params}).then(res => res.body)
  },
  updateNote(id, data) {
    return Vue.http.put(`notes/${id}`, data).then(res => res.body)
  },
  createNote(data) {
    return Vue.http.post('notes', data).then(res => res.body)
  },
  deleteNote(id) {
    return Vue.http.delete(`notes/${id}`).then(res => res.body)
  },
  getNoteById(id) {
    return Vue.http.get(`notes/${id}`).then(res => res.body)
  }
}
