<template lang="pug">
.modal-card
  header.modal-card-head
    p.modal-card-title
      b(v-t="'ledger.transactions_title'")
    div
      a.button.is-white.is-small(@click="$emit('close')")
        span.icon
          i.mdi.mdi-close.mdi-24px
  section.modal-card-body
    RewardsList(:data="rewards", :loading="loading", date="absolute", :showCustomerColumn="false", :highlightToday="true" @removed="onRemove")
  footer.modal-card-foot
    button.button(@click="$emit('close')") {{$t('actions.close')}}
</template>

<script>
import RewardsApi from '@/api/rewards'
import RewardsList from './RewardsList'

export default {
  name: 'CustomerRewards',
  components: {
    RewardsList
  },
  props: {
    customer: {type: Object, required: true}
  },
  data() {
    return {
      rewards: [],
      loading: false
    }
  },
  watch: {
    'customer.updated'() {
      return this.fetchRewards()
    }
  },
  created() {
    return this.fetchRewards()
  },
  methods: {
    async fetchRewards() {
      this.loading = true
      try {
        const {body} = await RewardsApi.list(this.customer._id)
        this.rewards = body
      } catch (error) {
        this.$handleAPIError(error)
      }
      this.loading = false
    },
    async onRemove() {
      await this.fetchRewards()
      this.$emit('update', this.customer._id)
    }
  }
}
</script>
