<template lang="pug">
  .box(style="height: 100%; overflow: auto")
    h2.title.is-3(data-qa="generic-modal-header")
      span(v-t="title" data-qa="modal-title")
      a.is-pulled-right.button.is-white(@click="$emit('close')" v-if="closable")
          span.icon
            i.mdi.mdi-close.mdi-24px
    .modal-body  
      slot
    .modal-footer
      slot(name="footer")
</template>

<script>
export default {
  name: 'GenericModal',
  props: {
    title: {type: String, required: true},
    closable: {type: Boolean, default: true}
  }
}
</script>

<style scoped>
.modal-footer {
  margin-top: 10px;
}
</style>
