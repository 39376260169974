<template lang="pug">
  SalesList(:data="customerSales", :loading="loading", date="absolute", :showCustomerColumn="false", :highlightToday="true" data-qa="customer-sales")
</template>

<script>
import SalesList from './SalesList'
import {mapActions, mapGetters} from 'vuex'

export default {
  name: 'CustomerSales',
  components: {
    SalesList
  },
  props: {
    customerId: {type: String, required: true}
  },
  data() {
    return {
      sales: [],
      loading: false
    }
  },
  computed: {
    ...mapGetters('sales', ['customerSales'])
  },
  watch: {
    'customer.updated'() {
      return this.fetchSales()
    }
  },
  created() {
    return this.fetchSales()
  },
  methods: {
    ...mapActions('sales', ['fetchCustomerSales']),
    async fetchSales() {
      this.loading = true
      try {
        await this.fetchCustomerSales(this.customerId)
      } catch (err) {
        this.$handleAPIError(err)
      }
      this.loading = false
    }
  }
}
</script>
