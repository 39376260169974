import Navbar from '@/components/Navbar'
const Targets = () => import(/* webpackChunkName: "targets" */ '@/components/targets/Targets')

export default [
  {
    path: '/targets',
    name: 'Targets',
    meta: {
      requiresAuth: true,
      title: 'dashboard.targets_title'
    },
    components: {
      navbar: Navbar,
      default: Targets
    }
  }
]
