import { render, staticRenderFns } from "./EmlyButton.vue?vue&type=template&id=2fe53e7c&lang=pug"
import script from "./EmlyButton.vue?vue&type=script&lang=js"
export * from "./EmlyButton.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports