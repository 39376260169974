<template lang="pug">
  .columns.is-multiline
    .column.is-6
        h3.heading Rating
        avg-feedback-view(:customer="customer" :showUnknown="true")
        br
        small average
        
    .column.is-6
        h3.heading NPS
        nps-view(:customer="customer" :showUnknown="true")
        br
        small latest
</template>
<script>
export default {
  props: {
    customer: {type: Object, required: true}
  }
}
</script>
