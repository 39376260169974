import Vue from 'vue'

export default {
  list(params) {
    return Vue.http.get('assets', {params}).then(res => res.body)
  },
  setAssetSuccess(assetId) {
    return Vue.http.put(`assets/${assetId}/success`).then(res => res.body)
  },
  generateUploadUrl(data) {
    return Vue.http.post('assets/url', data).then(res => res.body)
  },
  resizeImage(file, maxSize = 1000) {
    return new Promise(resolve => {
      let reader = new FileReader()
      reader.onload = function(readerEvent) {
        let image = new Image()
        image.onload = function() {
          let canvas = document.createElement('canvas')
          let width = image.width
          let height = image.height
          if (width > height) {
            if (width > maxSize) {
              height *= maxSize / width
              width = maxSize
            }
          } else {
            if (height > maxSize) {
              width *= maxSize / height
              height = maxSize
            }
          }
          canvas.width = width
          canvas.height = height
          canvas.getContext('2d').drawImage(image, 0, 0, width, height)
          let dataUrl = canvas.toDataURL(file.type, 0.76)
          canvas.toBlob(
            blob => {
              resolve({image: blob, dataUrl})
            },
            file.type,
            0.76
          )
        }
        image.src = readerEvent.target.result
      }
      reader.readAsDataURL(file)
    })
  },
  readImage(file) {
    return new Promise(resolve => {
      let reader = new FileReader()
      reader.onload = () => {
        resolve(reader.result)
      }
      reader.readAsDataURL(file)
    })
  },
  upload(url, image) {
    const params = {
      headers: {
        'Content-Type': image.type,
        'Cache-Control': 'public, max-age=2592000'
      }
    }
    return Vue.http.put(url, image, params).then(res => res.body)
  },
  remove(id) {
    return Vue.http.delete(`assets/${id}`)
  }
}
