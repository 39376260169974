<template lang="pug">
b-datepicker(v-model="dates" range icon="calendar" :date-formatter="dateFormatter" @input="onInput" :mobile-native="false" :month-names="monthNames" :day-names="dayNames" :first-day-of-week="1")
  b-field(expanded)
    b-select(v-model="period" expanded @input="selectPeriod" :placeholder="$t('misc.period_select_placeholder')")
      option(v-for="p in periods" :value="p" :key="p") {{$t(`periods.${p}`)}}

</template>

<script>
import {mapState} from 'vuex'
import dayjs from 'dayjs'
import helpers from '../../helpers'
import isEqual from 'lodash/isEqual'
const periods = [
  'last90',
  'last30',
  'last7',
  'today',
  'yesterday',
  'mtd',
  'ytd',
  'lastWeek',
  'lastMonth',
  'lastYear',
  'alltime'
]
const previousPeriods = ['today', 'yesterday', 'lastWeek', 'lastMonth', 'last7', 'last30', 'last90', 'lastYear']
export default {
  props: {
    value: {
      type: Object,
      default: undefined
    },
    initial: {
      type: String,
      default: 'last30'
    },
    comparableOnly: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      dates: [],
      periods: undefined,
      period: undefined,
      monthNames: dayjs.months(),
      dayNames: dayjs.weekdaysShort()
    }
  },
  computed: {
    ...mapState('company', ['company'])
  },
  watch: {
    value(oldValue, newValue) {
      if (isEqual(oldValue, newValue)) return
      this.init()
    }
  },
  created() {
    if (this.comparableOnly) {
      this.periods = previousPeriods
    } else {
      this.periods = periods
    }
    this.init()
  },
  methods: {
    init() {
      if (this.value) {
        this.dates = [new Date(this.value.from), new Date(this.value.to)]
        this.onInput()
      } else if (this.initial) {
        this.period = this.initial
        this.selectPeriod()
      } else {
        this.period = 'last30'
        this.selectPeriod()
      }
    },
    dateFormatter(dates) {
      if (this.period) {
        return this.$t(`periods.${this.period}`)
      }
      return `${dayjs(dates[0]).format('D MMM, YYYY')} - ${dayjs(dates[1]).format('D MMM, YYYY')}`
    },
    onInput() {
      this.period = undefined
      let to = dayjs(this.dates[1])
        .endOf('day')
        .toDate()
      let previousTo = dayjs(this.dates[0])
        .subtract(1, 'day')
        .endOf('day')
        .toDate()
      let data = {
        from: this.dates[0],
        to,
        previous: {
          to: previousTo,
          from: new Date(this.dates[0] - (to - previousTo))
        }
      }
      data.previous.display = `${dayjs(data.previous.from).format('D MMM, YYYY')} - ${dayjs(data.previous.to).format(
        'D MMM, YYYY'
      )}`
      this.$emit('input', data)
    },
    selectPeriod() {
      let [from, to, previousFrom, previousTo] = helpers.getPeriodToFrom(this.period, this.company)
      this.dates = [from, to]
      let data = {
        from,
        to,
        period: this.period,
        display: this.$t(`periods.${this.period}`)
      }
      if (previousPeriods.includes(this.period)) {
        data.previous = {
          to: previousTo,
          from: previousFrom,
          display: this.$t(`periods_previous.${this.period}`)
        }
      }
      this.$emit('input', data)
    }
  }
}
</script>
