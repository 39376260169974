<template lang="pug">
.modal-card
  header.modal-card-head
    p.modal-card-title(data-qa="modal-header") 
      span.icon.mr-2.is-clickable(v-if="hasPrevious" @click="prevStep()" )
        i.mdi.mdi-arrow-left.mdi-24px
      b(v-t="'gif.title'")
    div
      a.button.is-white.is-small(@click="$emit('close')")
        span.icon
          i.mdi.mdi-close.mdi-24px
  section.modal-card-body
    .tabs
      ul
        li
          a(:class="{'is-active': activeTab === 'giphy'}" @click="activeTab = 'giphy'") {{$t('gif.tabs.giphy')}}
        li
          a(:class="{'is-active': activeTab === 'custom'}" @click="activeTab = 'custom'") {{$t('gif.tabs.custom')}}
    div(v-if="activeTab === 'giphy'")
      form(@submit.prevent="search")
        b-field
          b-input(v-model="query" name="search" :placeholder="$t('gif.giphy_search_placeholder')" type="search" icon="magnify" expanded)
          p.control
            button.button.is-primary(type="submit" :class="loading ? 'is-loading': ''" v-t="'actions.search'")
      p.p-3.has-text-centered.has-text-grey Powered by GIPHY
      scroll-bar.results
        img.gif(v-for="gif in results" :style="{width: `${gif.images.fixed_height_small.width}px`, height: `${gif.images.fixed_height_small.height}px`}" :src="gif.images.fixed_height_small.url" @click="selected = gif" :class="selected === gif ? 'is-selected': ''")
    div(v-if="activeTab === 'custom'")
      b-field
        b-input(v-model="customUrl" name="search" :placeholder="$t('gif.custom_search_placeholder')" icon="file-gif-box" expanded)
        p.control
          button.button.is-primary(type="button" v-t="'actions.preview'")
      .custom-preview
        img(:src="customUrl" @error="onInvalidImage")
  footer.modal-card-foot
    .buttons
      action-button(@click="onSelect" :disabled="!selected && !customUrl" title="actions.select")
      button.button(@click="$emit('close')" v-t="'actions.cancel'")    
</template>

<script>
const apiEndPoint = 'https://api.giphy.com/v1/gifs/search'
const publicKey = 'vzDTQHGVFx9A3kTRzYZSjJ7aQnT43V0P'
const queryExamples = ['happy', 'excited', 'missing you', 'hey there', 'what do you think', 'huh']

export default {
  name: 'GIFSelectModal',
  props: {
    current: {type: Object, default: undefined}
  },
  data() {
    return {
      loading: false,
      query: undefined,
      results: [],
      activeTab: 'giphy',
      selected: undefined,
      customUrl: ''
    }
  },
  created() {
    if (this.current) {
      this.query = this.current.title
    }
    if (!this.query) {
      this.query = queryExamples[Math.floor(Math.random() * queryExamples.length)]
    }
    this.search()
  },
  methods: {
    onSelect() {
      let res
      if (this.activeTab === 'custom') {
        res = {
          url: this.customUrl
        }
      } else {
        res = {
          url: this.selected.images.original.url,
          smallUrl: this.selected.images.fixed_height_small.url,
          query: this.query,
          title: this.selected.title
        }
      }
      this.$emit('success', res)
      this.$emit('close')
    },
    onInvalidImage(event) {
      event.target.src = 'https://media.giphy.com/media/Ph0oIVQeuvh0k/giphy.gif'
    },
    async search() {
      if (!this.query || this.loading) {
        return
      }
      this.loading = true
      let params = {
        api_key: publicKey,
        q: this.query.split(' ').join('+'),
        limit: 50
      }
      try {
        this.results = (await this.$http.get(apiEndPoint, {params})).body.data
      } catch (err) {
        this.$handleAPIError(err)
      }
      this.loading = false
    }
  }
}
</script>

<style scoped>
.results {
  height: 300px;
}
img.gif {
  padding: 3px;
  margin: 4px;
  border: 2px solid #ccc;
  border-radius: 4px;
}
img.gif:hover {
  border: 2px solid #167df0;
  cursor: pointer;
  transition: border linear 200ms;
}
img.is-selected {
  border: 2px solid #23d160 !important;
  transition: border linear 200ms;
}
.custom-preview {
  margin: 0 auto;
  border-radius: 6px;
  width: 280px;
  box-shadow: 0 0.5em 1em -0.125em rgba(10, 10, 10, 0.1), 0 0px 0 1px rgba(10, 10, 10, 0.02);
}
.custom-preview img {
  min-width: 100%;
  border-radius: 6px;
}
</style>
