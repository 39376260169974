<template lang="pug">
  span {{formattedValue}}
</template>

<script>
import saleHelper from '../../../shared/saleHelper'
export default {
  props: {
    value: {type: Number, default: undefined},
    currency: {type: String, required: true},
    unit: {type: String, default: undefined}
  },
  computed: {
    formattedValue() {
      if (this.value === undefined) {
        return ''
      }
      let value = saleHelper.formatCurrency(this.value, this.currency)
      if (this.unit) {
        let unit = this.$t(`units.${this.unit}`)
        return `${value}/${unit}`
      }
      return value
    }
  }
}
</script>
