<template lang="pug">
div
  .columns.has-border-bottom.is-mobile.is-multiline(v-if="$rfHasPermission('CLIENT_SALES_DATA')")
    .column.is-half-mobile.is-one-quarter-desktop
        h3.heading.is-size-7(v-t="'sales_stats.sales'")
        p.title.is-4
          currency-view(:value="customer.sales")
    .column.is-half-mobile.is-one-quarter-desktop
        h3.heading.is-size-7(v-t="'sales_stats.average_sale'")
        p.title.is-4(v-if="customer.visits")
          currency-view(:value="customer.stats && customer.stats.avgSale || customer.sales/customer.visits")
          |   
          small(v-if="averageSaleComparison !== undefined") 
            span.has-text-danger(v-if="averageSaleComparison < 0" :title="$t('sales_stats.average_is_less_tooltip')") ▼ {{ averageSaleComparison| percentify}}
            span.has-text-success(v-else :title="$t('sales_stats.average_is_more_tooltip')") ▲ {{ averageSaleComparison| percentify}}
        p.title.is-4(v-else) -
    .column.is-half-mobile.is-one-quarter-desktop(v-if="$rfSubscribed('rewards') && $rfEnabled('rewards')")
        h3.heading.is-size-7 {{$t('rewards.points_heading')}}
        p.title.is-4
          rewards-view(:value="customer.balance" :currency="true")
    .column.is-half-mobile.is-one-quarter-desktop
        h3.heading.is-size-7 {{$t('rewards.credit_heading')}}
        p.title.is-4
          currency-view(v-if="customer.credit !== undefined" :value="customer.credit")
          span(v-else) -
  .columns.is-mobile.is-multiline
    
    //.level-item
      div
        monthly-sales-line-chart(:customerId="customer._id" ref="salesChart" :style="{width: '140px', height: '40px'}")
    
    //.level-item
      div
          h3.heading.is-size-7 Last visit
          p.title.is-4(v-if="customer.lastVisit") {{customer.lastVisit | fulldate}}
          p.title.is-4(v-else) N/A
    .column.is-half-mobile.is-one-quarter-desktop
        h3.heading.is-size-7(v-t="'sales_stats.visits'")
        p.title.is-4 {{customer.visits}}
    .column.is-half-mobile.is-one-quarter-desktop
        h3.heading.is-size-7(v-t="'appointments.no_shows_heading'")
        p.title.is-4.has-text-danger(v-if="customer.noshows") {{customer.noshows}}
        p.title.is-4(v-else) -
    .column.is-half-mobile.is-one-quarter-desktop
        h3.heading.is-size-7(v-t="'appointments.cancelations_heading'")
        p.title.is-4.has-text-danger(v-if="customer.canceled") {{customer.canceled}}
        p.title.is-4(v-else) -
    .column.is-half-mobile.is-one-quarter-desktop
        h3.heading.is-size-7(v-t="'sales_stats.tbp'")
        p.title.is-4
          span(v-if="customer.stats && customer.stats.tbp") {{customer.stats.tbp}} {{$t('misc.days')}}
          span(v-else) - 

    
</template>

<script>
import ReportsApi from '@/api/reports'
import MonthlySalesLineChart from './MonthlySalesLineChart'
import helpers from '../../helpers'
import {mapState} from 'vuex'
export default {
  name: 'CustomerSalesStats',
  components: {
    MonthlySalesLineChart
  },
  props: {
    customer: {type: Object, required: true}
  },
  data() {
    return {
      averageSale: undefined
    }
  },
  computed: {
    ...mapState('company', ['company']),
    averageSaleComparison() {
      if (!this.averageSale || !this.customer.visits) {
        return
      }
      return (this.customer.sales / this.customer.visits - this.averageSale) / this.averageSale
    }
  },
  async created() {
    const [from, to] = helpers.getPeriodToFrom('alltime', this.company)
    const data = await ReportsApi.getAverageSale({from, to})
    this.averageSale = data.averageSale
  }
}
</script>
