<template lang="pug">
  span {{currencySymbol}}
</template>
<script>
import CURRENCIES from '../../../constants/currencies'
import {mapState} from 'vuex'
export default {
  computed: {
    ...mapState('company', ['company']),
    currencySymbol() {
      return CURRENCIES[this.company.currency].value
    }
  }
}
</script>
