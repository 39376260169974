<template lang="pug">
b-select(v-model="currency" icon="cash-multiple" :placeholder="placeholder" @input="onChange" :expanded="expanded")
  option(v-for="curr in currencies" :value="curr.code" :key="curr.code") {{curr.title}}
</template>

<script>
import {mapState} from 'vuex'
import CURRENCIES from '../../../constants/currencies'
export default {
  name: 'CurrencySelect',
  props: {
    value: {type: String, default: undefined},
    placeholder: {type: String, default: undefined},
    expanded: {type: String, default: undefined}
  },
  inject: ['$validator'],
  data() {
    return {
      currency: null,
      currencies: Object.values(CURRENCIES)
    }
  },
  computed: {
    ...mapState('company', ['company'])
  },
  watch: {
    value(newVal) {
      if (newVal !== this.currency) {
        this.currency = this.value
      }
    }
  },
  async created() {
    if (this.value) {
      this.currency = this.value
    } else if (this.company.currency) {
      this.currency = this.company.currency
    }
    this.onChange()
  },
  methods: {
    onChange() {
      this.$emit('input', this.currency)
    }
  }
}
</script>
