<template lang="pug">
  span(v-if="tax" :class="{'has-text-grey': !tax.active}") {{tax.name}} {{rates}}
  span.has-text-grey(v-else v-t="'tax_settings.no_tax'")
</template>
<script>
import {mapActions} from 'vuex'
import numeral from 'numeral'
export default {
  name: 'TaxView',
  props: {
    value: {type: String, default: undefined}
  },
  data() {
    return {
      tax: undefined,
      items: undefined
    }
  },
  computed: {
    rates() {
      let items = (this.items && this.items.length && this.items) || [this.tax]
      return items.map(i => numeral(i.rate).format('0.[00]%')).join(', ')
    }
  },
  watch: {
    value() {
      this.init()
    }
  },
  created() {
    this.init()
  },
  methods: {
    ...mapActions('tax', ['getById']),
    async init() {
      if (!this.value) {
        return
      }
      this.tax = await this.getById(this.value)
      if (this.tax.items) {
        this.items = []
        for (let taxId of this.tax.items) {
          this.items.push(await this.getById(taxId))
        }
      }
    }
  }
}
</script>
