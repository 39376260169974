import Navbar from '@/components/Navbar'

const Menu = () => import(/* webpackChunkName: "education" */ '@/components/education/Menu')
const Container = () => import(/* webpackChunkName: "education" */ '@/components/education/Container')
const OverviewTab = () => import(/* webpackChunkName: "education" */ '@/components/education/OverviewTab')
const TechnicalTab = () => import(/* webpackChunkName: "education" */ '@/components/education/TechnicalTab')
const BusinessTab = () => import(/* webpackChunkName: "education" */ '@/components/education/BusinessTab')
const StaffTab = () => import(/* webpackChunkName: "education" */ '@/components/education/StaffTab')
const BrandTab = () => import(/* webpackChunkName: "education" */ '@/components/education/BrandTab')
const ExclusiveTab = () => import(/* webpackChunkName: "education" */ '@/components/education/ExclusiveTab')
const HowtosTab = () => import(/* webpackChunkName: "education" */ '@/components/education/HowtosTab')

export default [
  {
    path: '/education',
    name: 'education',
    meta: {
      requiresAuth: true,
      title: 'education'
    },
    components: {
      default: Container,
      navbar: Navbar,
      menu: Menu
    },
    children: [
      {
        path: 'overview',
        component: OverviewTab,
        meta: {
          title: 'education.overview_title'
        }
      },
      {
        path: 'business',
        component: BusinessTab,
        meta: {
          title: 'education.business_title'
        }
      },
      {
        path: 'technical',
        component: TechnicalTab,
        meta: {
          title: 'education.technical_title'
          // background: 'white'
        }
      },
      {
        path: 'staff',
        component: StaffTab,
        meta: {
          title: 'education.staff_title'
          // background: 'white'
        }
      },
      {
        path: 'brand',
        component: BrandTab,
        meta: {
          title: 'education.brand_title'
          // background: 'white'
        }
      },
      {
        path: 'exclusive',
        component: ExclusiveTab,
        meta: {
          title: 'education.exclusive_title'
          // background: 'white'
        }
      },
      {
        path: 'howtos',
        component: HowtosTab,
        meta: {
          title: 'education.howtos_title'
          // background: 'white'
        }
      }
    ]
  }
]
