<template lang="pug">
div
  h3.title.has-text-centered Let's try the chatbot!
  .columns.is-centered
    .column.is-4
      .box
        //h4.title.is-5 Test Lily bot on your page
        p.subtitle Start a conversation with {{company.name}}'s page on Messenger. Try asking about prices, services, appointments or directions and contacts.
        .control
          a.button.is-fullwidth.is-primary(:href="`https://m.me/${company.messenger.fbId}`" target="_blank") 
            span.icon.is-medium
              i.mdi.mdi-facebook-messenger.mdi-24px
            span Start a Conversation
</template>

<script>
import {mapState} from 'vuex'

export default {
  computed: {
    ...mapState('company', ['company'])
  }
}
</script>
