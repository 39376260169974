import {SET_INVENTORY_CATEGORIES, SET_INVENTORY_CATEGORY, REMOVE_INVENTORY_CATEGORY} from './mutationTypes'
import InventoryApi from '@/api/inventory'
import Vue from 'vue'
let __categoriesPromise
export default {
  namespaced: true,
  state: {
    categoriesMap: {},
    categoriesOrder: [],
    initialized: false
  },
  getters: {
    categories(state) {
      return state.categoriesOrder.map(c => state.categoriesMap[c])
    }
  },
  mutations: {
    [SET_INVENTORY_CATEGORIES](state, categories) {
      const mapped = {}
      for (let category of categories) {
        mapped[category._id] = category
      }
      state.categoriesMap = mapped
      state.categoriesOrder = categories.map(c => c._id)
    },
    [SET_INVENTORY_CATEGORY](state, category) {
      let current = state.categoriesMap[category._id] || {}
      for (let prop in category) {
        if (current[prop] === undefined) {
          Vue.set(current, prop, category[prop])
        } else {
          current[prop] = category[prop]
        }
      }
      state.categoriesMap[category._id] = current
    },
    [REMOVE_INVENTORY_CATEGORY](state, id) {
      delete state.categoriesMap[id]
      const idx = state.categoriesOrder.indexOf(id)
      if (idx !== -1) {
        state.categoriesOrder.splice(idx, 1)
      }
    },
    initialized(state, val) {
      state.initialized = val
    }
  },
  actions: {
    async fetchCategories({commit, state}, force) {
      if (__categoriesPromise) {
        return __categoriesPromise
      }
      if (!state.initialized || force) {
        __categoriesPromise = InventoryApi.listCategories()
        const categories = await __categoriesPromise
        __categoriesPromise = undefined
        commit('initialized', true)
        commit(SET_INVENTORY_CATEGORIES, categories)
      }
    },
    async fetchCategory({state, commit}, id) {
      if (!state.categoriesMap[id]) {
        const body = await InventoryApi.getCategoryById(id)
        commit(SET_INVENTORY_CATEGORY, body)
      }
      return state.categoriesMap[id]
    },
    async removeCategory({commit}, {id, moveToCategory}) {
      await InventoryApi.removeCategory({id, moveToCategory})
      commit(REMOVE_INVENTORY_CATEGORY, id)
    },
    async updateCategory({commit}, data) {
      const body = await InventoryApi.updateCategory(data._id, data)
      commit(SET_INVENTORY_CATEGORY, body)
    },
    async createCategory({commit, dispatch}, data) {
      const body = await InventoryApi.createCategory(data)
      await dispatch('fetchCategories', true)
      commit(SET_INVENTORY_CATEGORY, body)
      return body
    }
  }
}
