<template lang="pug">
span {{name}}
</template>
<script>
export default {
  props: {
    value: {
      type: String,
      required: true
    }
  },
  computed: {
    name() {
      if (this.$te(`payment_types.${this.value}`)) {
        return this.$t(`payment_types.${this.value}`)
      }
      return this.value
    }
  }
}
</script>
