import Navbar from '@/components/Navbar'
const CouponTemplates = () => import(/* webpackChunkName: "admin" */ '@/components/admin/CouponTemplates')
const CreateEditCouponTemplate = () =>
  import(/* webpackChunkName: "admin" */ '@/components/admin/CreateEditCouponTemplate')

export default [
  {
    path: '/couponTemplates',
    name: 'couponTemplates',
    components: {
      default: CouponTemplates,
      navbar: Navbar
    },
    props: {default: route => ({query: route.query.q})},
    meta: {
      requiresAuth: true,
      title: '[ADMIN] Coupon Templates'
    }
  },
  {
    path: '/couponTemplates/create',
    name: 'createCouponTemplate',
    components: {
      default: CreateEditCouponTemplate,
      navbar: Navbar
    },
    meta: {
      requiresAuth: true,
      title: '[ADMIN] Create Coupon Template'
    }
  },
  {
    path: '/couponTemplates/edit/:id',
    name: 'editCouponTemplate',
    props: {default: true, navbar: false},
    components: {
      default: CreateEditCouponTemplate,
      navbar: Navbar
    },
    meta: {
      requiresAuth: true,
      title: '[ADMIN] Edit Coupon Template'
    }
  }
]
