<template lang="pug">
  .columns.is-multiline
    .column.is-4(v-for="(image, idx) in images" :key="image.key")
      .is-artwork
        image-preview(v-model="images[idx].url" @input="onUpdate" @remove="onRemove(idx)" :removable="true" :max-size="maxSize")
    .column.is-4
      .is-artwor
        button.button(@click="addImage") 
          span.icon
            i.mdi.mdi-image-outline
          span(v-t="'actions.add_image'")
</template>
<script>
export default {
  props: {
    value: {type: Array, default: () => []},
    maxSize: {type: Number, default: undefined}
  },
  data() {
    return {
      images: [],
      localKey: 0
    }
  },
  watch: {
    value() {
      this.initImages()
    }
  },
  created() {
    this.initImages()
  },
  methods: {
    initImages() {
      this.localKey = 0
      this.images = this.value.map(url => {
        this.localKey += 1
        return {
          url,
          key: this.localKey
        }
      })
    },
    addImage() {
      this.localKey += 1
      this.images.push({
        url: '',
        key: this.localKey
      })
    },
    onUpdate() {
      this.$emit(
        'input',
        this.images.map(i => i.url).filter(i => !!i)
      )
    },
    onRemove(idx) {
      this.images.splice(idx, 1)
      this.$emit(
        'input',
        this.images.map(i => i.url).filter(i => !!i)
      )
    }
  }
}
</script>
