<template lang="pug">
  span.tags(v-if="reasons && reasons.length")
    span.tag.is-primary(v-for="reason in reasons") {{reason}}
  span(v-else)
    span.tag not available
</template>

<script>
export default {
  name: 'FeedbackReasons',
  props: {
    reasons: {type: Array, default: () => []}
  }
}
</script>
