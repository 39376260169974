import Vue from 'vue'

export default {
  list({query, sortBy, sortDir, page, perPage}) {
    return Vue.http.get('coupons/customerCoupons', {
      params: {query, sortBy, sortDir, page, perPage}
    })
  },
  redeemCoupon(customerCouponId) {
    return Vue.http.put(`coupons/customerCoupons/${customerCouponId}/redeem`)
  },
  getCustomerCoupons(customerId) {
    return Vue.http.get(`coupons/customerCoupons/customer/${customerId}`).then(res => res.body)
  },
  getCustomerCouponById(customerCouponId) {
    return Vue.http.get(`coupons/customerCoupons/${customerCouponId}`).then(res => res.body)
  },
  sendGiftCoupon({customerId, coupon, reason}) {
    return Vue.http.post(`coupons/customerCoupons/gift/${customerId}`, {coupon, reason})
  }
}
