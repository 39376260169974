<template lang="pug">
.modal-card
  header.modal-card-head
    p.modal-card-title(data-qa="modal-header") 
      b(v-if="!formId" v-t="'forms.new_form_header'")
      b(v-else v-t="'forms.edit_form_header'")
    div
      a.button.is-white.is-small(@click="$emit('close')")
        span.icon
          i.mdi.mdi-close.mdi-24px
  section.modal-card-body.has-background-light
    
    
    // b-field(:label="$t('forms.location_label')" )
    //   location-select(v-model="location")
    .container
      .section
        b-field(:label="$t('forms.name_label')" :type="errors.has('name') ? 'is-danger': ''" :message="errors.has('name') ? errors.first('name') : ''" )
          b-input(v-model="name" name='name' v-validate="'required'" :placeholder="$t('forms.name_placeholder')")
        .box.py-5
          ContentEditable.title.is-4(v-model="title")
          ContentEditable.subtitle(v-model="subtitle")
        draggable(tag="div" v-model="questions" v-bind="{animation: 150, ghostClass: 'question-ghost'}")
          .box.pb-4.pt-0(v-for="({item, id}, idx ) in questions" :key="id" @click="focusQuestion(idx)")
            .has-text-centered(style="cursor: grab")
              span.icon.has-text-grey
                i.mdi.mdi-drag-horizontal.mdi-24px
            .is-flex.is-align-items-center.mb-3
              ContentEditable.is-size-5.is-flex-grow-1.has-text-weight-semibold(v-model="item.label" :ref="`label${id}`")
              div(v-if="currentItem === item")
                FieldTypeDropdown(v-model="item.type" @input="initFieldsByType(item)")
            template(v-if="hasValueSet(item.type)")
              FormValueEditor(v-model="item.options" :type="item.type" :inFocus="currentItem === item")
            b-field(v-else-if="item.type === FIELD_TYPES.TEXT")
              .p-2.has-background-light.has-text-grey short answer here
            b-field(v-else-if="item.type === FIELD_TYPES.TEXTAREA")
              .p-2.pb-6.has-background-light.has-text-grey long answer paragraph
            b-field(v-else-if="item.type === FIELD_TYPES.IMAGE && currentItem !== item")
              button.button(disabled) 
                span.icon
                  i.mdi.mdi-image
                span Upload image
            .p-2(v-else-if="item.type === FIELD_TYPES.IMAGE")
              b-field(:label="$t('forms.image_upload_max_label')")
                b-select(v-model.number="item.uploadNumber")
                  option(value="1") 1
                  option(value="2") 2
                  option(value="3") 3
                  option(value="4") 4
                  option(value="5") 5

            
            .has-border-top.pt-4.is-flex.is-align-items-center(style="flex-flow: row-reverse" v-if="currentItem === item")
              div
                b-switch(v-model="item.required") 
                  span.has-text-weight-semibold(v-t="'misc.required'")
              .mr-3.is-clickable(@click.stop="removeQuestion(idx)") 
                span.icon.is-medium
                  i.mdi.mdi-trash-can-outline.mdi-24px
              .mr-3.is-clickable(@click.stop="duplicateQuestion(idx)") 
                span.icon.is-medium
                  i.mdi.mdi-content-copy.mdi-24px
        
        .columns.is-multiline.is-mobile.mt-5
          .column.is-2-desktop.is-4-mobile.p-2(v-for="field in fieldTypes")
            .item-button.p-2(@click="addQuestion(field)" ) 
              span.icon
                i.mdi(:class="iconSet[field]")
              span {{ $t(`form_field_types.${field}`) }}
            
  footer.modal-card-foot
    .buttons
      action-button(title="actions.save" @click="submit" :loading="submitting" data-qa="action-confirm" permission="FORMS_MANAGE")
      button.button(@click="$emit('close')" v-t="'actions.cancel'")  
    
</template>

<script>
import {cloneDeep} from 'lodash'
import {mapActions, mapGetters} from 'vuex'
import draggable from 'vuedraggable'
import FIELD_TYPES from '../../../constants/formFieldTypes'
import FormValueEditor from './FormValueEditor'
import ContentEditable from '../shared/ContentEditable'
import FieldTypeDropdown from './FieldTypeDropdown'
export default {
  name: 'CreateEditForm',
  components: {
    ContentEditable,
    FormValueEditor,
    draggable,
    FieldTypeDropdown
  },
  props: {
    formId: {type: String, default: undefined}
  },
  data() {
    return {
      submitting: false,
      name: undefined,
      location: undefined,
      title: undefined,
      subtitle: undefined,
      questions: [],
      trigger: undefined,
      fieldTypes: Object.values(FIELD_TYPES),
      FIELD_TYPES,
      idIndex: 0,
      currentItem: undefined,
      iconSet: {
        [FIELD_TYPES.CHECKBOXES]: 'mdi-checkbox-marked-outline',
        [FIELD_TYPES.IMAGE]: 'mdi-image-outline',
        [FIELD_TYPES.RADIO]: 'mdi-circle-slice-8',
        [FIELD_TYPES.SELECT]: 'mdi-arrow-down-drop-circle-outline',
        [FIELD_TYPES.TEXT]: 'mdi-text-short',
        [FIELD_TYPES.TEXTAREA]: 'mdi-text-long'
      }
    }
  },
  computed: {
    ...mapGetters('forms', ['forms'])
  },
  async created() {
    if (this.formId) {
      let form = await this.getFormById(this.formId)

      this.name = form.name
      this.location = form.location
      this.title = form.title
      this.subtitle = form.subtitle
      this.trigger = form.trigger
      this.questions = cloneDeep(form.questions).map(q => {
        return {
          item: q,
          id: q._id
        }
      })
    } else {
      this.title = this.$t('forms.untitled_form')
    }
  },
  methods: {
    ...mapActions('forms', ['getFormById', 'createForm', 'updateForm']),
    async submit() {
      const valid = await this.$validator.validateAll()
      if (this.submitting || !valid) {
        return
      }

      this.questions.forEach(q => {
        if (!this.hasValueSet(q.type)) {
          delete q.values
        }
        if (q.type !== FIELD_TYPES.IMAGE) {
          delete q.uploadNumber
        }
      })
      this.submitting = true
      const data = {
        name: this.name,
        location: this.location,
        title: this.title,
        subtitle: this.subtitle,
        questions: this.questions.map(q => q.item),
        trigger: this.trigger
      }
      try {
        if (this.formId) {
          await this.updateForm({
            id: this.formId,
            data
          })
        } else {
          await this.createForm(data)
        }

        this.$buefy.toast.open({message: this.$t('status.save_success'), type: 'is-success', queue: false})
        this.$emit('close')
      } catch (error) {
        this.$handleAPIError(error)
      }
      this.submitting = false
    },
    initFieldsByType(item) {
      if (this.hasValueSet(item.type) && (!item.options || !item.options.length)) {
        item.options = [`${this.$t('forms.option')} 1`, `${this.$t('forms.option')} 2`]
      }
      if (item.type === FIELD_TYPES.IMAGE && !item.uploadNumber) {
        item.uploadNumber = 1
      }
    },
    addQuestion(type) {
      let item = {type, label: this.$t('forms.untitled_question')}
      this.initFieldsByType(item)

      this.questions.push({item, id: this.idIndex++})
      this.focusQuestion(this.questions.length - 1)
    },
    hasValueSet(field) {
      return [FIELD_TYPES.CHECKBOXES, FIELD_TYPES.SELECT, FIELD_TYPES.RADIO].includes(field)
    },
    removeQuestion(idx) {
      this.questions.splice(idx, 1)
    },
    duplicateQuestion(idx) {
      let copy = cloneDeep(this.questions[idx].item)
      this.questions.splice(idx + 1, 0, {item: copy, id: this.idIndex++})
      this.focusQuestion(idx + 1)
    },
    async focusQuestion(idx) {
      let {item, id} = this.questions[idx]
      if (this.currentItem === item) {
        return
      }
      this.currentItem = item
      await this.$nextTick()
      this.$refs[`label${id}`][0].focus()
    },
    cancel() {
      this.$emit('close')
    }
  }
}
</script>
<style scoped>
.question-ghost {
  opacity: 0.5;
  background: #ffffff;
}
</style>
