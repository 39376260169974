<template lang="pug">
  div(style="margin-top: 10px")
    .field(v-show="!showCoupon")
      a(@click="showCouponInput") {{ $t('billing.action.have_coupon') }}
    .field(v-show="showCoupon")
      label.label {{ $t('billing.coupon_label') }}
      b-field(grouped group-multiline)
        b-field(expanded :type="invalidCoupon ? 'is-danger': ''" :message="invalidCoupon ? $t('billing.coupon_code_invalid') : ''")
          b-input(expanded ref="couponInput" type="text" :placeholder="$t('billing.coupon_code_placeholder')" icon="ticket-percent" v-model.trim="couponId" name="couponId" :disabled="coupon")
        p.control
          button.button.is-primary(@click="applyCoupon" v-if="!coupon" :class="loading ? 'is-loading': ''") 
            span.icon
              i.mdi.mdi-check
            span {{$t('actions.apply')}}
          button.button.is-danger(@click="removeCoupon" v-else) 
            span.icon
              i.mdi.mdi-close
            span {{$t('actions.remove')}}
        
</template>

<script>
import BillingApi from '@/api/billing'

export default {
  name: 'CouponInput',
  data() {
    return {
      showCoupon: false,
      couponId: undefined,
      coupon: undefined,
      invalidCoupon: false,
      loading: false
    }
  },
  methods: {
    async applyCoupon() {
      if (!this.couponId) {
        return
      }
      if (this.loading) {
        return
      }
      this.loading = true
      try {
        const data = await BillingApi.getCouponById({couponId: this.couponId})

        if (!data) {
          this.invalidCoupon = true
          this.coupon = undefined
        } else {
          this.coupon = data
          this.invalidCoupon = false
        }
        this.$emit('input', this.coupon)
      } catch (error) {
        this.$handleAPIError(error)
      }
      this.loading = false
    },
    removeCoupon() {
      this.couponId = undefined
      this.coupon = undefined
      this.showCoupon = false
      this.invalidCoupon = false
      this.$emit('input', this.coupon)
    },
    showCouponInput() {
      this.showCoupon = true
      this.$refs.couponInput.focus()
    }
  }
}
</script>
