<template lang="pug">
b-datepicker(v-model="date" :icon="icon" 
  :name="name"
  :expanded="expanded" 
  :date-formatter="dateFormatter" 
  :mobile-native="mobileNative" 
  :unselectable-days-of-week="disabledDays" 
  :placeholder="placeholder" 
  @input="onChange" 
  :month-names="monthNames" 
  :day-names="dayNames" 
  :first-day-of-week="company.weekStart" 
  :size="size" 
  :append-to-body="appendToBody" 
  :editable="editable"
  nearby-selectable-month-days
  :selectable-dates="selectableDates") 
  template(v-slot:trigger)
    slot(name="trigger")
  button.button.is-small(@click.prevent="clear()" v-if="clearable")
    span.icon
      i.mdi.mdi-close
    span(v-t="'actions.clear'")
</template>
<script>
import dayjs from 'dayjs'
import {mapState} from 'vuex'
export default {
  props: {
    value: {type: Date, default: undefined},
    dateFormatter: {type: Function, default: undefined},
    icon: {type: String, default: undefined},
    name: {type: String, default: undefined},
    size: {type: String, default: undefined},
    placeholder: {type: String, default: undefined},
    clearable: {type: Boolean, default: false},
    weekStartMode: {type: Boolean, default: false},
    mobileNative: {type: Boolean, default: false},
    expanded: {type: Boolean, default: false},
    editable: {type: Boolean, default: false},
    appendToBody: {type: Boolean, default: false},
    selectableDates: {type: Array, default: undefined}
  },
  data() {
    return {
      date: undefined,
      monthNames: dayjs.months(),
      dayNames: dayjs.weekdaysShort()
    }
  },
  computed: {
    ...mapState('company', ['company']),
    disabledDays() {
      if (!this.weekStartMode) {
        return
      }
      let days = [0, 1, 2, 3, 4, 5, 6]
      days.splice(days.indexOf(this.company.weekStart), 1)
      return days
    }
  },
  watch: {
    value(val) {
      this.date = val
    }
  },
  created() {
    this.date = this.value
  },
  methods: {
    onChange() {
      this.$emit('input', this.date)
    },
    clear() {
      this.date = undefined
      this.$emit('input', this.date)
    }
  }
}
</script>
