<template lang="pug">
b-timepicker.is-shadowless(v-model="dateTime" inline :incrementMinutes="5" :hour-format="company.timeFormat" @input="onChange")
</template>

<script>
import {mapState} from 'vuex'

export default {
  name: 'TimepickerBuefy',
  props: {
    value: {type: Number, default: undefined},
    maxTime: {type: Number, default: 1440},
    minTime: {type: Number, default: 0},
    allowEmpty: {type: Boolean, default: false}
  },

  data() {
    return {
      dateTime: undefined,
      time: undefined
    }
  },
  computed: {
    ...mapState('company', ['company'])
  },
  watch: {
    value(newVal) {
      if (newVal !== this.time) {
        this.setTime(newVal)
      }
    }
  },
  async created() {
    if (this.value !== undefined) {
      this.setTime(this.value)
    } else if (!this.allowEmpty) {
      let now = new Date()
      now.setMinutes(parseInt(now.getMinutes() / 5) * 5, 0, 0)
      this.setTime(now.getHours() * 60 + now.getMinutes())

      this.onChange()
    }
  },
  methods: {
    setTime(val) {
      this.time = val
      let date = new Date()
      date.setHours(Math.floor(val / 60), val % 60, 0, 0)
      this.dateTime = date
    },

    onChange() {
      this.time = this.dateTime.getHours() * 60 + this.dateTime.getMinutes()
      this.$emit('input', this.time)
    }
  }
}
</script>
