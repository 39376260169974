import Vue from 'vue'
import Router from 'vue-router'
import Navbar from '@/components/Navbar'

import Customers from '@/components/customers/List'

const AdminDashboard = () => import(/* webpackChunkName: "admin" */ '@/components/admin/Dashboard')
const AdminPendingSignups = () => import(/* webpackChunkName: "admin" */ '@/components/admin/PendingSignups')
const AdminSales = () => import(/* webpackChunkName: "admin" */ '@/components/admin/Sales')

import MessageTemplateRoutes from './messageTemplate'
import CouponTemplateRoutes from './couponTemplate'

import SettingsRoutes from './settings'
import DashboardRoutes from './dashboard'
import TargetsRoutes from './targets'
import InventoryRoutes from './inventory'
// import FollowupRoutes from './followup'
import AnalyticsRoutes from './analytics'
import AccountRoutes from './account'
import CompanyRoutes from './company'
import SurveysRoutes from './surveys'
import SalesRoutes from './sales'
import EducationRoutes from './education'
import MarketingRoutes from './marketing'
import SMMRoutes from './smm'
import InvoicePrintView from '@/components/sale/InvoicePrintView'
Vue.use(Router)

export default new Router({
  linkActiveClass: 'is-active',
  base: process.env.BASE_URL,
  mode: 'history',
  scrollBehavior() {
    return {x: 0, y: 0}
  },
  routes: [
    {
      path: '/',
      redirect: '/signin'
    },

    {
      path: '/admin',
      name: 'AdminDashboard',
      components: {
        default: AdminDashboard,
        navbar: Navbar
      },
      meta: {
        requiresAuth: true,
        title: '[ADMIN] Customers'
      }
    },
    {
      path: '/admin/pendingSignups',
      name: 'PendingSignups',
      components: {
        default: AdminPendingSignups,
        navbar: Navbar
      },
      meta: {
        requiresAuth: true,
        title: '[ADMIN] Pending Signups'
      }
    },
    {
      path: '/admin/sales',
      name: 'AdminSales',
      components: {
        default: AdminSales,
        navbar: Navbar
      },
      meta: {
        requiresAuth: true,
        title: '[ADMIN] Sales'
      }
    },
    ...SalesRoutes,
    ...TargetsRoutes,
    ...InventoryRoutes,
    ...MessageTemplateRoutes,
    ...CouponTemplateRoutes,
    ...SettingsRoutes,
    ...DashboardRoutes,
    ...AnalyticsRoutes,
    // ...FollowupRoutes,
    ...AccountRoutes,
    ...CompanyRoutes,
    ...SurveysRoutes,
    ...EducationRoutes,
    ...MarketingRoutes,
    ...SMMRoutes,
    {
      path: '/customers/:customerId?',
      name: 'Customers',
      props: {default: true, navbar: false},
      components: {
        default: Customers,
        navbar: Navbar
      },
      meta: {
        requiresAuth: true,
        title: 'customers.title'
      }
    },
    {
      path: '/invoice/:saleId',
      name: 'Invoice',

      meta: {
        requiresAuth: true,
        cleanview: true
      },
      components: {
        default: InvoicePrintView
      },
      props: {default: true}
    }
  ]
})
