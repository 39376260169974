<template lang="pug">
div(v-if="coupons && coupons.length")
  .brand-bordered.p-2
    customer-coupon-view(:value="coupon" v-for="coupon in coupons" :key="coupon._id" detailed)
p.has-text-grey(v-else v-t="'customer_coupons.no_active_coupons'")
</template>
<script>
import {mapActions} from 'vuex'

export default {
  name: 'CustomerCoupons',
  props: {
    customerId: {type: String, required: true}
  },
  data() {
    return {
      coupons: undefined
    }
  },
  created() {
    this.fetchCoupons()
  },
  methods: {
    ...mapActions('customerCoupon', ['getCustomerCoupons', 'redeemCoupon']),
    async fetchCoupons() {
      this.loading = true
      try {
        this.coupons = await this.getCustomerCoupons(this.customerId)
      } catch (err) {
        this.$handleAPIError(err)
      }
      this.loading = false
    },
    async onCouponRedeem(customerCoupon) {
      try {
        await this.redeemCoupon(customerCoupon._id)
        await this.fetchCoupons()
        this.$buefy.toast.open({
          message: this.$t('status.save_success'),
          type: 'is-success',
          queue: false
        })
      } catch (err) {
        this.$handleAPIError(err)
      }
    }
  }
}
</script>
