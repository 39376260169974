import Navbar from '@/components/Navbar'
const MessageTemplates = () => import(/* webpackChunkName: "admin" */ '@/components/admin/MessageTemplates')
const CreateEditMessageTemplate = () =>
  import(/* webpackChunkName: "admin" */ '@/components/admin/CreateEditMessageTemplate')

export default [
  {
    path: '/messageTemplates',
    name: 'messageTemplates',
    components: {
      default: MessageTemplates,
      navbar: Navbar
    },
    props: {default: route => ({query: route.query.q})},
    meta: {
      requiresAuth: true,
      title: '[ADMIN] Message Templates'
    }
  },
  {
    path: '/messageTemplates/create',
    name: 'createMessageTemplate',
    components: {
      default: CreateEditMessageTemplate,
      navbar: Navbar
    },
    meta: {
      requiresAuth: true,
      title: '[ADMIN] Create Message Template'
    }
  },
  {
    path: '/messageTemplates/edit/:id',
    name: 'editMessageTemplate',
    props: {default: true, navbar: false},
    components: {
      default: CreateEditMessageTemplate,
      navbar: Navbar
    },
    meta: {
      requiresAuth: true,
      title: '[ADMIN] Edit Coupon Template'
    }
  }
]
