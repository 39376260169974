<template lang="pug">
.modal-card
  header.modal-card-head
    p.modal-card-title(data-qa="modal-header") 
      b(v-if="!customerId" v-t="'forms.form_preview_header'")
      b(v-else-if="customerFormId" v-t="'forms.form_edit_header'")
      b(v-else v-t="'forms.form_attach_header'")
    div
      a.button.is-white.is-small(@click="$emit('close')")
        span.icon
          i.mdi.mdi-close.mdi-24px
  section.modal-card-body
    FormRenderer(ref="form" :formId="formId" :customerId="customerId" :customerFormId="customerFormId")
  footer.modal-card-foot
    .buttons
      action-button(title="actions.submit" @click="submit" :loading="submitting" v-if="customerId")
      button.button(@click="$emit('close')" v-t="'actions.close'")  

  </template>

<script>
import FormRenderer from './FormRenderer'

export default {
  name: 'FormPreview',
  components: {
    FormRenderer
  },
  props: {
    formId: {type: String, required: true},
    customerFormId: {type: String, default: undefined},
    customerId: {type: String, default: undefined}
  },
  data() {
    return {
      submitting: false
    }
  },
  methods: {
    async submit() {
      this.submitting = true
      try {
        await this.$refs.form.submit()
        this.$emit('close')
        this.$emit('refresh')
      } catch (err) {
        this.$handleAPIError(err)
      }
      this.submitting = false
    }
  }
}
</script>
