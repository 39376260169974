<template lang="pug">
  .modal-card
    header.modal-card-head
      p.modal-card-title.has-text-centered Convert Customer to Member
    section.modal-card-body
      div(style="min-height: 400px")
        form(v-on:submit.prevent="submit")
          b-field(label="Birthday")
            localized-datepicker(placeholder="Select a date..." icon="calendar" :readonly="true" v-model="birthday")
          .field
              label.label Phone
              .control
                phone-input(v-model="phone" @input="onPhoneInput" :fetch-customer="true" style="z-index:10")
                p.help.has-text-danger(v-show="phoneValid === false") phone number is not valid
                p.help.has-text-danger(v-show="phoneExists") phone number is already assigned to other customer
    footer.modal-card-foot
      .field.is-grouped.is-grouped-centered
        .control
          button.button.is-primary(type="submit", @click="submit", :class="submitting ? 'is-loading': ''") Make member
        .control
          button.button.is-outlined(type="button", @click="cancel") Cancel
</template>

<script>
import {mapActions} from 'vuex'
import dayjs from 'dayjs'
import RewardsApi from '@/api/rewards'

export default {
  name: 'MakeMemberForm',
  props: {
    customerId: {type: String, required: true}
  },
  data() {
    return {
      birthday: undefined,
      phone: null,
      phoneExists: false,
      phoneValid: undefined,
      submitting: false,
      customer: undefined
    }
  },
  async created() {
    this.customer = await this.fetchCustomer(this.customerId)
    this.birthday = this.customer.birthday && dayjs(this.customer.birthday.toString(), 'YYYYMMDD').toDate()
    this.phone = this.customer.phone
  },
  methods: {
    ...mapActions('customers', ['fetchCustomer']),
    cancel() {
      this.$emit('close')
    },
    async submit() {
      if (this.phoneExists || this.phoneValid === false) {
        return
      }
      if (this.submitting) {
        return
      }
      this.submitting = true
      try {
        await RewardsApi.makeMember(this.customerId, {
          birthday: this.birthday && parseInt(dayjs(this.birthday).format('YYYYMMDD')),
          phone: this.phone
        })
        this.$buefy.toast.open({message: 'Customer converted to member successfully', type: 'is-success', queue: false})
        this.$emit('close')
      } catch (err) {
        this.$handleAPIError(err)
      }
      this.submitting = false
    },
    onPhoneInput(phone, valid, customer) {
      if (phone) {
        this.phoneValid = valid
      } else {
        this.phoneValid = undefined
      }
      this.phoneExists = customer && this.customerId !== customer._id
    }
  }
}
</script>
