<template lang="pug">
  div
    hr
    h3.title.is-5 😍 Excitement Expressions
    p.subtitle.is-6 One of these GIFs will be sent when a client rate the service by this specialist 5 star (Awesome, Excellent, Amazing)
    .gif-list
      .gif-box(v-for="(gif, idx) in excited")
        img(:src="gif")
        button.delete(@click="remove('excited', idx)")
    button.button.is-primary.is-outlined(@click="add('excited')") Add New
    hr
    h3.title.is-5 🙂 Thanking Expressions
    p.subtitle.is-6 One of these GIFs will be sent when a client rate the service by this specialist 4 star (Good, Alright)
    .gif-list
      .gif-box(v-for="(gif, idx) in thanks")
        img(:src="gif")
        button.delete(@click="remove('thanks', idx)")
    button.button.is-primary.is-outlined(@click="add('thanks')") Add New
    hr
    h3.title.is-5 😐 Sad Expressions
    p.subtitle.is-6 One of these GIFs will be sent when a client rate the service by this specialist 3 star (So-so, Average)
    .gif-list
      .gif-box(v-for="(gif, idx) in sad")
        img(:src="gif")
        button.delete(@click="remove('sad', idx)")
    button.button.is-primary.is-outlined(@click="add('sad')") Add New
    hr
    h3.title.is-5 🥺 Miss You Expressions
    p.subtitle.is-6 One of these GIFs will be sent to a lapsed client
    .gif-list
      .gif-box(v-for="(gif, idx) in misses")
        img(:src="gif")
        button.delete(@click="remove('misses', idx)")
    button.button.is-primary.is-outlined(@click="add('misses')") Add New
</template>
<script>
import GIFSelectModal from '../shared/GIFSelectModal'
export default {
  props: {
    value: {type: Object, default: undefined}
  },
  data() {
    return {
      excited: [],
      thanks: [],
      misses: [],
      sad: []
    }
  },
  watch: {
    value(newVal) {
      this.excited = (newVal.excited && newVal.excited.slice(0)) || []
      this.thanks = (newVal.thanks && newVal.thanks.slice(0)) || []
      this.misses = (newVal.misses && newVal.misses.slice(0)) || []
      this.sad = (newVal.sad && newVal.sad.slice(0)) || []
    }
  },
  created() {
    if (this.value) {
      this.excited = (this.value.excited && this.value.excited.slice(0)) || []
      this.thanks = (this.value.thanks && this.value.thanks.slice(0)) || []
      this.misses = (this.value.misses && this.value.misses.slice(0)) || []
      this.sad = (this.value.sad && this.value.sad.slice(0)) || []
    }
  },
  methods: {
    remove(cat, idx) {
      this[cat].splice(idx, 1)
      this.update()
    },
    add(cat) {
      this.$buefy.modal.open({
        parent: this,
        component: GIFSelectModal,
        hasModalCard: true,
        canCancel: ['outside', 'escape'],
        width: 640,
        events: {
          success: ({url}) => {
            this[cat].push(url)
            this.update()
          }
        }
      })
    },
    update() {
      this.$emit('input', {excited: this.excited, thanks: this.thanks, misses: this.misses, sad: this.sad})
    }
  }
}
</script>
<style scoped>
.gif-box {
  box-shadow: 0 0.5em 1em -0.125em rgba(10, 10, 10, 0.1), 0 0px 0 1px rgba(10, 10, 10, 0.02);
  height: 120px;
  display: inline-block;
  border-radius: 6px;
  position: relative;
  margin: 0 1rem 1rem 0;
}
.gif-box img {
  border-radius: 6px;
  display: inline-block;
  height: 100%;
}
.gif-box .delete {
  position: absolute;
  top: 10px;
  right: 10px;
}
</style>
