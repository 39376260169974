<template lang="pug">
div
  div(v-if="actualSchedule.length")
    .notification.has-text-centered.mb-2(:class="custom ? 'is-warning': 'is-success'")
      p(v-for="schedule in formattedSchedule") {{schedule}}
  .notification.is-warning.has-text-centered.mb-0(v-else-if="custom") 
    span.icon
      i.mdi.mdi-calendar-remove
  .notification.is-light.has-text-centered.mb-0(v-else) 
    span.icon
      i.mdi.mdi-plus
  
</template>
<script>
import {mapState} from 'vuex'
import formatter from '../../../shared/formatter'
export default {
  props: {
    schedule: {type: Array, default: () => []},
    customSchedule: {type: Array, default: () => []},
    custom: {type: Boolean, default: false}
  },
  computed: {
    ...mapState('company', ['company']),
    actualSchedule() {
      return this.custom ? this.customSchedule : this.schedule
    },

    formattedSchedule() {
      return this.actualSchedule.map(schedule => {
        let start = formatter.formatTime(schedule.start, this.company.timeFormat)
        let end = formatter.formatTime(schedule.end, this.company.timeFormat)
        return `${start} - ${end}`
      })
    }
  }
}
</script>
<style scoped>
.notification {
  padding: 0.5rem;
}
.notification:hover {
  background-color: hsl(141, 53%, 53%);
}
</style>
