import {SET_INVENTORY_BRANDS, SET_INVENTORY_BRAND, REMOVE_INVENTORY_BRAND} from './mutationTypes'
import InventoryApi from '@/api/inventory'
import Vue from 'vue'
let __brandsPromise
export default {
  namespaced: true,
  state: {
    brandsOrder: [],
    brandsMap: {},
    initialized: false
  },
  getters: {
    brands(state) {
      return state.brandsOrder.map(c => state.brandsMap[c])
    }
  },
  mutations: {
    [SET_INVENTORY_BRANDS](state, brands) {
      const mapped = {}
      for (let brand of brands) {
        mapped[brand._id] = brand
      }
      state.brandsMap = mapped
      state.brandsOrder = brands.map(c => c._id)
    },
    [SET_INVENTORY_BRAND](state, brand) {
      let current = state.brandsMap[brand._id] || {}
      for (let prop in brand) {
        if (current[prop] === undefined) {
          Vue.set(current, prop, brand[prop])
        } else {
          current[prop] = brand[prop]
        }
      }
      state.brandsMap[brand._id] = current
    },
    [REMOVE_INVENTORY_BRAND](state, id) {
      delete state.brandsMap[id]
      const idx = state.brandsOrder.indexOf(id)
      if (idx !== -1) {
        state.brandsOrder.splice(idx, 1)
      }
    },
    initialized(state, val) {
      state.initialized = val
    }
  },
  actions: {
    async fetchBrands({commit, state}, force) {
      if (__brandsPromise) {
        return __brandsPromise
      }
      if (!state.initialized || force) {
        __brandsPromise = InventoryApi.listBrands()
        const brands = await __brandsPromise
        __brandsPromise = undefined
        commit('initialized', true)
        commit(SET_INVENTORY_BRANDS, brands)
      }
    },

    async fetchBrand({state, commit}, id) {
      if (!state.brandsMap[id]) {
        const body = await InventoryApi.getBrandById(id)
        commit(SET_INVENTORY_BRAND, body)
      }
      return state.brandsMap[id]
    },
    async removeBrand({commit}, {id, moveToBrand}) {
      await InventoryApi.removeBrand({id, moveToBrand})
      commit(REMOVE_INVENTORY_BRAND, id)
    },
    async updateBrand({commit}, data) {
      const body = await InventoryApi.updateBrand(data._id, data)
      commit(SET_INVENTORY_BRAND, body)
    },
    async createBrand({commit, dispatch}, data) {
      const body = await InventoryApi.createBrand(data)
      await dispatch('fetchBrands', true)
      commit(SET_INVENTORY_BRAND, body)
      return body
    }
  }
}
