<template lang="pug">
div
  .buttons.is-centered
    button.button.is-outlined.is-primary.is-rounded(@click="selectService")
      span.icon
        i.mdi.mdi-plus
      span(v-t="'appointment.actions.add_service'")
  .columns.is-marginless
    //.column.is-4.p-2
      .item-button.is-primary(@click="selectService")
        img.is-hidden-mobile(:src="require('../../assets/clipboard.png')")
        p.has-text-primary
          span.icon.is-hidden.is-hidden-tablet
            i.mdi.mdi-plus-circle-outline
          span(v-t="'appointment.actions.add_service'")
    .column
      .columns.is-multiline.is-mobile
        .column.is-4-desktop.is-4-mobile.p-2(v-for="service in services")
          .item-button.py-1.px-2.is-pink(:class="COLOR_CLASS[service.type]" @click="addService(service)" )
            p.is-size-7-mobile 
              service-name-view(:value="{service: service.service, option: service.option}" two-lines)
      
  
</template>
<script>
import ReportsApi from '@/api/reports'
import SALE_ITEM_TYPES from '../../../constants/saleItemTypes'
import ServiceSelectModal from '../services/ServiceSelectModal'
import dayjs from 'dayjs'
import {uniqWith} from 'lodash'
import {mapState} from 'vuex'
export default {
  name: 'AddAppointmentShortcuts',
  props: {
    locationId: {type: String, default: undefined},
    customerId: {type: String, default: undefined},
    staffId: {type: String, default: undefined}
  },
  data() {
    return {
      topServices: [],
      staffTop: [],
      customerTop: [],
      COLOR_CLASS: {
        customer: 'is-pink',
        staff: 'is-orange',
        location: 'is-purple'
      }
    }
  },
  computed: {
    ...mapState('services', ['servicesMap']),
    services() {
      let res = []
      if (this.customerTop.length) {
        res = res.concat(
          this.customerTop.map(s => {
            return {type: 'customer', service: s.service.service, option: s.service.option}
          })
        )
      }
      if (this.staffTop.length) {
        res = res.concat(
          this.staffTop.slice(0, 12 - res.length).map(s => {
            return {type: 'staff', service: s.service.service, option: s.service.option}
          })
        )
      }

      res = res.concat(
        this.topServices.slice(0, Math.max(16 - res.length, 6)).map(s => {
          return {type: 'location', service: s.service.service, option: s.service.option}
        })
      )
      res = uniqWith(res, (a, b) => a.service === b.service && a.option === b.option)
      res = res.filter(
        r => !!(this.servicesMap[r.service] && this.servicesMap[r.service].pricingOptions.find(o => o._id === r.option))
      )
      return res
    }
  },
  watch: {
    locationId(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.fetchTopServices(8)
      }
    },
    customerId(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.fetchCustomerTopServices(5)
      }
    },
    staffId(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.fetchStaffTopServices(5)
      }
    }
  },
  created() {
    this.fetchTopServices(8)
    if (this.customerId) {
      this.fetchCustomerTopServices(5)
    }
    if (this.staffId) {
      this.fetchStaffTopServices(5)
    }
  },
  methods: {
    async fetchTopServices(limit) {
      this.topServices = await ReportsApi.getTopSaleItems(SALE_ITEM_TYPES.SERVICE, {
        location: this.locationId,
        limit
      })
    },
    async fetchCustomerTopServices(limit) {
      this.customerTop = await ReportsApi.getTopSaleItems(SALE_ITEM_TYPES.SERVICE, {
        location: this.locationId,
        customer: this.customerId,
        limit
      })
    },
    async fetchStaffTopServices(limit) {
      this.staffTop = await ReportsApi.getTopSaleItems(SALE_ITEM_TYPES.SERVICE, {
        location: this.locationId,
        staff: this.staffId,
        from: dayjs()
          .subtract(12, 'months')
          .toDate(),
        limit
      })
    },
    selectService() {
      return this.$buefy.modal.open({
        parent: this,
        hasModalCard: true,
        component: ServiceSelectModal,
        width: 960,
        fullScreen: this.isMobile(),
        canCancel: ['escape'],
        props: {
          quickSelect: true
        },
        events: {
          input: data => this.$emit('input', data)
        }
      })
    },
    addService({service, option}) {
      this.$emit('input', {serviceId: service, optionId: option})
    }
  }
}
</script>
