<template lang="pug">
div 
  b-field(v-if="activeLocations.length && !$rfProfileScope()")
    b-select(
      v-model="location" 
      :disabled="activeLocations.length === 1 || $rfLocationScope()"  
      :placeholder="$t('shared.location_input_placeholder')" 
      icon="map-marker-outline" 
      @input="locationChange"
      )
      option(value="all" v-if="allOption") {{$t('shared.location_input_value_all')}}
      option(v-for="loc in activeLocations" :value="loc._id") {{loc.title}}
</template>

<script>
import {mapGetters, mapState} from 'vuex'

export default {
  name: 'LocationSelector',
  props: {
    value: {type: String, default: undefined},
    allOption: {type: Boolean, default: true}
  },
  data() {
    return {
      location: 'all'
    }
  },
  computed: {
    ...mapGetters('location', ['activeLocations']),
    ...mapState('auth', ['user']),
    scopedLocation() {
      return this.$rfProfileScope() || this.$rfLocationScope()
    }
  },
  created() {
    if (this.scopedLocation) {
      this.location = this.user.location
      this.locationChange()
      return
    }
    if (this.value) {
      this.location = this.value
    } else if (!this.allOption) {
      this.location = this.activeLocations[0]._id
      this.locationChange()
    }
  },
  methods: {
    locationChange() {
      if (this.location === 'all') {
        return this.$emit('input', undefined)
      }
      this.$emit('input', this.location)
    }
  }
}
</script>
