module.exports = {
  ORANGE: 'FF8200',
  YELLOW: 'FFE08A',
  GREEN: '679436',
  PURPLE: '7B287D',
  PINK: 'EF476F',
  NAVY: '12355B',
  RUST: 'A63C06',
  PEACH: 'EFBC9B',
  KOBI: 'EE92C2',
  'BLUE GREEN': '16BAC5',
  COOL: '8789C0',
  AERO: 'D0F4DE',
  SUNRAY: 'DDA448'
}
