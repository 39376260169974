<template lang="pug">
div
  b-field
    b-checkbox(:value="allSelected" size="is-medium" @input="switchSelection" :indeterminate="allSelected === undefined") {{$t('actions.select_all')}}
  .columns.is-multiline
    .column.is-6(v-for="member in staffMembers")
      
        b-checkbox(v-model="selected" size="is-medium" :native-value="member._id" @input="input" data-qa="service-staff") 
          .media
            .media-left
              staff-pic.is-48x48(:staff="member._id" )
            .media-content  
              p.title.is-6 
                StaffView(:id="member._id")
              p.subtitle.is-6.has-text-grey {{$t(`roles.${member.role}`)}}
</template>
<script>
import {mapGetters, mapState} from 'vuex'
import StaffView from '../staff/StaffView'
import {isEqual} from 'lodash'
export default {
  components: {
    StaffView
  },
  props: {
    value: {type: Array, required: true}
  },
  data() {
    return {
      selected: []
    }
  },
  computed: {
    ...mapGetters('staff', ['activeServiceMembers', 'activeServiceMembersByLocations']),
    ...mapState('auth', ['user']),
    staffMembers() {
      if (this.$rfLocationScope() || this.$rfProfileScope()) {
        return this.activeServiceMembersByLocations(this.user.location)
      }
      return this.activeServiceMembers
    },
    allSelected() {
      return this.selected.length === this.staffMembers.length ? true : this.selected.length === 0 ? false : undefined
    }
  },
  watch: {
    value() {
      if (isEqual(this.selected, this.value)) {
        return
      }
      this.setSelected()
    }
  },
  async created() {
    this.setSelected()
  },
  methods: {
    setSelected() {
      this.selected = this.value.slice()
      this.selected = this.selected.filter(id => !!this.staffMembers.find(a => a._id === id))
      this.input()
    },
    switchSelection() {
      if (this.allSelected) {
        this.selected = []
      } else {
        this.selected = this.staffMembers.map(l => l._id)
      }
      this.input()
    },
    input() {
      this.$emit('input', this.selected)
    }
  }
}
</script>
