<template lang="pug">
div
  ul
    li.list-item.px-0(v-for="({product, count, qty, last}, idx) in list" ) 
      .is-flex.is-flex-direction-row.is-align-items-center
        div(style="flex: 0 0 32px;word-break: normal")
          p.title.is-marginless.is-5 \#{{idx+1}}
        div(style="flex: 1 1 auto; overflow: hidden;line-height: 1.3")
          p(style="overflow:hidden; text-overflow: ellipsis; white-space: nowrap")
            ProductView(:productId="product" size="is-32x32" )
              p.has-text-grey
                small {{$t('misc.times', {count})}}
                |  ·  
                small {{$t('misc.items', {count: qty})}}
                |  ·  
                small {{last | fromNow}}
        //div(style="flex: 0 0 auto")
          button.button.is-small.is-primary.is-outlined repeat
  p.has-text-right(v-if="expandable")
    a(@click="collapsed = false" v-if="collapsed") 
      span.icon
        i.mdi.mdi-chevron-down
      span(v-t="'actions.see_all'")
    a(@click="collapsed = true" v-if="!collapsed") 
      span.icon
        i.mdi.mdi-chevron-up
      span(v-t="'actions.show_less'")
</template>

<script>
import ProductView from '../inventory/ProductView'
export default {
  name: 'FavoriteProducts',
  components: {
    ProductView
  },
  props: {
    customer: {type: Object, required: true}
  },
  data() {
    return {
      collapsed: true
    }
  },
  computed: {
    expandable() {
      return this.customer.favProducts.length > 3
    },
    list() {
      if (this.collapsed) {
        return this.customer.favProducts.slice(0, 3)
      }
      return this.customer.favProducts
    }
  }
}
</script>
