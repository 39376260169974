<template lang="pug">
.modal-card
  header.modal-card-head
    p.modal-card-title(data-qa="modal-header") 
      b(v-t="'assets_manager.title'")   
    div
      a.button.is-white.is-small(@click="$emit('close')")
        span.icon
          i.mdi.mdi-close.mdi-24px
  section.modal-card-body.is-flex.is-align-items-stretch.p-0.is-clipped    
    .p-4.is-flex-grow-1.has-lightgrey-background(style="overflow: scroll; flex-basis: 0" )
      .menu
        p.menu-label(v-t="'assets_manager.folder_heading'")
        ul.menu-list
          li(v-for="cat in categories" :key="cat")
            a(@click="selectCategory(cat)" :class="cat === currentCategory ? 'is-active': ''")
              span.icon
                i.mdi.mdi-folder-multiple-outline(:class="cat === 'all' ?'mdi-folder-multiple-outline' : 'mdi-folder-outline' ")
              span {{$t(`asset_categories.${cat}`)}} 
              span(v-if="stats") ({{ stats[cat === 'all'? 'total': cat].files }})
      hr.is-narrow
      p.has-text-grey.has-text-weight-semibold.has-text-centered(v-if="stats") {{$t("assets_manager.storage_info") }} 
        number-view(:value="stats.total.storage/(1024 * 1024)")
        span MB
          
    .is-flex-grow-5.is-flex.is-flex-direction-column.is-align-items-stretch.has-border-left
      .has-border-bottom.p-4
        b-field.file(expanded)
          b-upload.is-fullwidth(v-model="files" expanded @input="upload" multiple drag-drop :loading="uploading" accept="image/*")
            .has-text-centered.is-size-5(style="padding: 1rem")
              span.icon
                i.mdi.mdi-upload
              span(v-t="'assets_manager.upload_zone'")
      .is-flex-grow-1.p-4.is-flex.is-flex-direction-column.is-align-items-stretch.is-clipped
        .is-flex-grow-1.is-relative(style="overflow: scroll")
          em-loading(:active="loading" :is-full-page="false")
          template(v-if="assets && assets.length")
            .columns.is-multiline
              .column.is-2(v-for="asset in assets" :key="assets._id")
                .image-wrapper(:class="{selected: asset === selectedAsset}")  
                  .image-container(@click="select(asset)" :style="{'background-image': `url(${asset.url})`}")
                  small {{asset.name}}
                  button.delete(@click.stop="remove(asset)" v-if="!asset.loading")
                  em-loading(v-if="asset.loading" :is-full-page="false" :active="asset.loading")
          
          section.section(v-else)
            p.has-text-centered.has-text-grey-light(v-t="'assets_manager.placeholder'")         
        .p-4
          b-pagination(:total="total" v-model="currentPage" @change="onPageChange" :per-page="perPage")
  footer.modal-card-foot
    .buttons
      action-button(@click="submit" :disabled="!selectedAsset" title="actions.select")
      button.button.is-outlined(type="button" @click="cancel" v-t="'actions.close'")
</template>

<script>
import AssetsApi from '@/api/assets'
import ASSET_CATEGORIES from '../../../constants/uploadAssetCategories'
export default {
  name: 'AssetsManager',
  props: {
    category: {type: String, default: 'assets'},
    maxSize: {type: Number, default: 1000}
  },
  data() {
    return {
      assets: [],
      files: [],
      total: undefined,
      stats: undefined,
      currentPage: 1,
      perPage: 12,
      keyIndex: 0,
      uploading: false,
      loading: false,
      selectedAsset: null,
      categories: ['all', ...Object.values(ASSET_CATEGORIES)],
      currentCategory: 'all'
    }
  },
  created() {
    this.fetchAssets()
  },
  methods: {
    async uploadFile(file) {
      let image, dataUrl
      if (file.type === 'image/gif') {
        image = file
        dataUrl = await AssetsApi.readImage(file)
      } else {
        let res = await AssetsApi.resizeImage(file, this.maxSize)
        image = res.image
        dataUrl = res.dataUrl
      }

      let {uploadUrl, asset} = await AssetsApi.generateUploadUrl({
        filename: file.name,
        mimeType: file.type,
        size: image.size,
        category: this.category
      })
      let finalUrl = asset.url
      asset.url = dataUrl
      asset.loading = true
      this.assets.unshift(asset)
      await AssetsApi.upload(uploadUrl, image)
      await AssetsApi.setAssetSuccess(asset._id)
      asset.url = finalUrl
      asset.loading = false
      delete asset.dataUrl
    },
    async upload() {
      let invalidFormats = this.files.find(f => !!f.type.match(/(png|jpeg|jpg|gif)/gi))
      if (invalidFormats.length) {
        this.$buefy.toast.open({
          message: this.$t('assets_manager.format_error'),
          type: 'is-danger',
          duration: 3000,
          queue: false
        })
        return
      }
      this.uploading = true
      try {
        let uploadPromises = this.files.map(f => this.uploadFile(f))
        await Promise.all(uploadPromises)
        this.selectedAsset = this.assets[this.assets.length - 1]
        this.$buefy.toast.open({message: this.$t('assets_manager.upload_success'), type: 'is-success', queue: false})
      } catch (err) {
        this.$handleAPIError(err)
      }
      this.uploading = false
      this.files = []
    },
    onPageChange(page) {
      this.currentPage = page
      this.fetchAssets()
    },
    selectCategory(cat) {
      this.currentCategory = cat
      this.currentPage = 1
      this.fetchAssets()
    },
    async fetchAssets() {
      try {
        this.loading = true
        const {total, data, stats} = await AssetsApi.list({
          page: this.currentPage,
          perPage: this.perPage,
          category: this.currentCategory === 'all' ? undefined : this.currentCategory
        })
        this.assets = data
        this.total = total
        this.stats = stats
      } catch (err) {
        this.$handleAPIError(err)
      }
      this.loading = false
    },
    select(asset) {
      this.selectedAsset = asset
    },
    submit() {
      this.$emit('success', this.selectedAsset)
      this.$emit('close')
    },
    async remove(asset) {
      this.$set(asset, 'loading', true)
      try {
        await AssetsApi.remove(asset._id)
        this.assets.splice(this.assets.indexOf(asset), 1)
      } catch (err) {
        this.$handleAPIError(err)
        asset.loading = false
      }
    },
    cancel() {
      this.$emit('close')
    }
  }
}
</script>
<style scoped>
.image-wrapper {
  height: 0;
  padding-top: 100%;
  position: relative;
  overflow: hidden;
  border: 1px solid #ddd;
  border-radius: 4px;
}
.image-container {
  cursor: pointer;
  position: absolute;
  top: 5px;
  bottom: 25px;
  right: 5px;
  left: 5px;
  background-color: #f1f1f1;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
}
button.delete {
  display: none;
}
.image-wrapper:hover button.delete {
  display: block;
}
.image-wrapper small {
  display: block;
  position: absolute;
  bottom: 5px;
  left: 5px;
  right: 5px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 0.7em;
  text-align: center;
}
.image-wrapper.selected,
.image-wrapper:hover {
  border-color: rgb(0, 63, 145);
  background-color: rgb(206, 229, 255);
}
</style>
<style lang="scss" scoped>
.upload.is-fullwidth,
.upload.is-fullwidth div {
  width: 100%;
  height: 100%;
}
.menu-list {
  a {
    border-radius: 9999px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
</style>
