<template lang="pug">
.modal-card
  header.modal-card-head.is-hidden-tablet
    p.modal-card-title(data-qa="modal-header") 
      b(v-t="'customer.noname_title'") 
    div
      a.button.is-white.is-small(@click="$emit('close')")
        span.icon
          i.mdi.mdi-close.mdi-24px

  section.modal-card-body.has-background-light.p-0.is-flex.is-flex-align-items-stretch(v-if="customer")
    a.button.is-light.is-small.is-hidden-mobile(@click="$emit('close')" style="position:absolute; top: 16px; right:12px;z-index: 1")
      span.icon
        i.mdi.mdi-close.mdi-24px
    .columns.m-0.is-flex-align-items-stretch.is-flex-grow-1
      .column.is-3.p-4.is-flex.is-flex-direction-column.is-clipped
        Profile.is-flex-grow-1(:customer="customer")

      .column.is-9.p-4.is-flex.is-flex-direction-column.is-align-items-stretch
        .is-flex-shrink-0
          .buttons.mb-4
                //ul
                //li(:class="view === 'overview' ? 'is-active': ''")
                a.button.is-light(@click="view = 'overview'" :class="view === 'overview'?'has-background-info has-text-white': ''") 
                  span.icon
                    i.mdi.mdi-list-box-outline
                  span.has-text-weight-semibold {{$t('customer.overview_heading')}}
                //li(v-if="$rfSettings().calendar && !company.rewardsOnly" :class="view === 'appointments' ? 'is-active': ''")
                a.button.is-light(v-if="$rfSettings().calendar && !company.rewardsOnly" @click="view = 'appointments'" :class="view === 'appointments'?'has-background-info has-text-white': ''") 
                  span.icon
                    i.mdi.mdi-calendar
                  span.has-text-weight-semibold {{$t('customer.appointments_heading')}}
                //li(:class="view === 'sales' ? 'is-active': ''")
                a.button.is-light(@click="view = 'sales'" :class="view === 'sales'?'has-background-info has-text-white': ''")
                  span.icon
                    i.mdi.mdi-basket-outline
                  span.has-text-weight-semibold {{$t('customer.sales_heading')}}
                //li(v-if="$rfSubscribed('payments')" :class="view === 'payments' ? 'is-active': ''")
                a.button.is-light(v-if="$rfSubscribed('payments')" @click="view = 'payments'" :class="view === 'payments'?'has-background-info has-text-white': ''")  
                  span.icon
                    i.mdi.mdi-credit-card-outline
                  span.has-text-weight-semibold {{$t('customer.payments_heading')}}
              
                //li(:class="view === 'sms' ? 'is-active': ''")
                a.button.is-light(@click="view = 'sms'" :class="view === 'sms'?'has-background-info has-text-white': ''")
                  span.icon
                    i.mdi.mdi-send
                  span.has-text-weight-semibold {{$t('customer.messages_heading')}}
        .is-flex-grow-1.is-flex.is-align-items-stretch.is-clipped(v-if="view === 'overview'")
          .columns.is-align-items-stretch.is-clipped.is-flex-grow-1
            .column.is-7(style="overflow: auto")
              h3.title.is-5.mb-2  
                span(v-t="'customer.overview_heading'")
              .box
                CustomerStatsOverview(:customer="customer")
              
              h3.title.is-5.mb-2
                span(v-t="'customer.stats.appointments_header'")
              .box.p-0.is-clipped 
                CustomerUpcomingAppointments(:customerId="customer._id")
                .p-4.has-border-top
                  .columns.is-mobile
                    .column
                      h3.title.is-6
                        span.mr-2(v-t="'customer.est_appt_heading'") 
                        EstNextPurchase(:customer="customer")
                    .column
                      h3.title.is-6
                        span.mr-2(v-t="'customer.last_visit_heading'") 
                        span.tag.is-warning
                          date-view(v-if="customer.lastVisit" :value="customer.lastVisit" format='D MMM')  
                          span(v-else) N/A
              template(v-if="$rfSubscribed(PRODUCTS.FORMS)")
                h3.title.is-5.mb-2
                  span(v-t="'customer.forms_heading'")
                .box 
                  CustomerForms(:customerId="customer._id")
              template(v-if="$rfSubscribed(PRODUCTS.REWARDS_PROGRAM) && $rfHasPermission('CLIENT_SALES_DATA')")
                h3.title.is-5.mb-2
                  span(v-t="'customer.stats.rewards_program_header'")
                .box.p-0
                  CustomerRewardsStats(:customer="customer")    
              
              template(v-if="$rfSubscribed(PRODUCTS.SURVEYS)")
                h3.title.is-5.mb-2
                  span(v-t="'customer.stats.surveys_header'")
                .box
                  CustomerSurveyStats(:customer="customer")
              // h3.title.is-5.mb-2
              //   span.icon
              //     i.mdi.mdi-heart-box-outline
              //   span(v-t="'customer.stats.loyalty_header'")
              // .box
              //   CustomerLoyaltyStats(:customer="customer")
            .column.is-5(style="overflow: auto")
              template(v-if="$rfSubscribed(PRODUCTS.CRM_PRO)")
                h3.title.is-5.mb-2
                  span(v-t="'customer.notes_header'")
                .box.p-4.mb-4
                  NotesList(:entityId="customer._id" entity="customer")
              Stats(:customer="customer" :blocks="['fav_services','fav_products','fav_staff','fav_locations']")
          
        //.is-flex-shrink-0.p-4
          CustomerAppointmentsStats(:customer="customer")
        .is-flex-grow-1.box.p-0(style="overflow:scroll" v-if="view === 'appointments'")
          CustomerAppointments(:customerId="customer._id" )
        .is-flex-grow-1.box.p-0(style="overflow:scroll" v-if="view === 'sales'")
          CustomerSales(:customerId="customer._id")
        .is-flex-grow-1.box.p-0(style="overflow:scroll" v-if="$rfSubscribed('payments') && view === 'payments'")
          CustomerPayments(:customerId="customer._id")
        
        CustomerMessages.is-flex-grow-1.box.p-0(:customer="customer" v-if="view === 'sms'")
  footer.modal-card-foot.is-hidden-tablet
    .buttons
      action-button(v-if="!company.rewardsOnly && $rfSettings().calendar" @click="newAppointment" icon="calendar" title="appointment.actions.new" permission="APPOINTMENT_CREATE") 
      
      
          
          
</template>

<script>
import {mapActions, mapGetters, mapState} from 'vuex'
import {PRODUCTS} from '../../../constants/products'

import Stats from './Stats'
import Profile from './Profile'
import CustomerLoyaltyStats from './CustomerLoyaltyStats'

import CustomerSales from '../sale/CustomerSales'
import CustomerPayments from '../sale/CustomerPayments'
import CustomerAppointments from '../calendar/CustomerAppointments'
import ProfilePic from './ProfilePic'
import CustomerRewards from '../rewards/CustomerRewards'
import CustomerReferralsList from '../referral/CustomerReferralsList'

import RepeatLastAppointmentButton from '../calendar/RepeatLastAppointmentButton'
import CustomerAppointmentsStats from '../calendar/CustomerAppointmentsStats'
import CustomerMessages from './CustomerMessages'
import CustomerStatsOverview from '../sale/CustomerStatsOverview'
import CustomerUpcomingAppointments from '../calendar/CustomerUpcomingAppointments'
import EstNextPurchase from './EstNextPurchase'
import CustomerForms from '../forms/CustomerForms'
import CustomerSurveyStats from '../surveys/CustomerSurveyStats'
import CustomerRewardsStats from '../rewards/CustomerRewardsStats'
import NotesList from '../shared/NotesList'

export default {
  name: 'Details',
  components: {
    Stats,
    Profile,
    CustomerSales,
    CustomerRewards,
    CustomerReferralsList,
    CustomerAppointments,
    ProfilePic,
    CustomerLoyaltyStats,
    RepeatLastAppointmentButton,
    CustomerAppointmentsStats,
    CustomerMessages,
    CustomerPayments,
    CustomerStatsOverview,
    CustomerUpcomingAppointments,
    EstNextPurchase,
    CustomerForms,
    CustomerRewardsStats,
    CustomerSurveyStats,
    NotesList
  },
  props: {
    customerId: {type: String, required: true}
  },
  data() {
    return {
      view: 'overview',
      PRODUCTS,
      loading: false,
      coupons: []
    }
  },
  computed: {
    ...mapGetters('customers', ['customerById']),
    ...mapState('company', ['company']),
    customer: {
      get() {
        return this.customerById(this.customerId)
      }
    }
  },
  // watch: {
  //   $route() {
  //     this.$emit('close')
  //   }
  // },
  async created() {
    this.loading = true

    try {
      await this.fetchCustomer(this.customerId)
    } catch (err) {
      this.$handleAPIError(err)
    }
    this.loading = false
  },
  methods: {
    ...mapActions('customers', ['fetchCustomer']),
    newAppointment() {
      this.$emit('close')
      this.$router.push({name: 'Calendar', query: {customer: this.customer._id}})
    }
  }
}
</script>
