<template lang="pug">
  div
    template(v-if="blocks.includes('sales')") 
      h3.title.is-5.mb-2
        span.icon
          i.mdi.mdi-chart-box-outline
        span(v-t="'customer.stats.sales_header'")
      .box.is-shadowless
        CustomerSalesStats(:customer="customer")
    template(v-if="blocks.includes('appointments') && $rfSettings().calendar && !company.rewardsOnly") 
      h3.title.is-5.mb-2
        span.icon
          i.mdi.mdi-calendar
        span(v-t="'customer.stats.appointments_header'")
      .box.is-shadowless
        CustomerAppointmentsStats(:customer="customer" @rebook="onRebook")
    template(v-if="blocks.includes('loyalty') && $rfSubscribed(PRODUCTS.CHURN_PREDICTION)") 
      h3.title.is-5.mb-2
        span.icon
          i.mdi.mdi-heart-box-outline
        span(v-t="'customer.stats.loyalty_header'")
      .box.is-shadowless
        CustomerLoyaltyStats(:customer="customer")
    .mb-4(v-if="blocks.includes('rewards') && $rfSubscribed(PRODUCTS.REWARDS_PROGRAM)")
      h3.title.is-5.mb-2
        span.icon
          i.mdi.mdi-heart-box-outline
        span(v-t="'customer.stats.rewards_program_header'")
      .box.is-shadowless
        CustomerRewardsStats(:customer="customer")    
    template(v-if="blocks.includes('surveys') && $rfSubscribed(PRODUCTS.SURVEYS)")
      h3.title.is-5.mb-2
        span.icon
          i.mdi.mdi-emoticon-happy-outline
        span(v-t="'customer.stats.surveys_header'")
      .box.is-shadowless
        CustomerSurveyStats(:customer="customer")
    template(v-if="blocks.includes('fav_services') && !$rfRewardsOnly() && customer.favServices && customer.favServices.length")
      h3.title.is-5.mb-2
        span.icon
          i.mdi.mdi-heart-outline
        span(v-t="'customer.stats.favorite_services_header'")
      .box.is-shadowless
        FavoriteServices(:customer="customer")
    template(v-if="blocks.includes('fav_staff') &&  customer.favStaff && customer.favStaff.length")
      h3.title.is-5.mb-2
        span.icon
          i.mdi.mdi-heart-outline
        span(v-t="'customer.stats.favorite_staff_header'")
      .box.is-shadowless
        FavoriteStaff(:customer="customer")
    template(v-if="hasMultipleLocations && blocks.includes('fav_locations') && customer.favLocations && customer.favLocations.length")
      h3.title.is-5.mb-2
        span.icon
          i.mdi.mdi-map-marker-outline
        span(v-t="'customer.preferred_locations_heading'")
      .box.is-shadowless
        FavoriteLocations(:customer="customer")
    template(v-if="blocks.includes('fav_products') && !$rfRewardsOnly() && customer.favProducts && customer.favProducts.length")
      h3.title.is-5.mb-2
        span.icon
          i.mdi.mdi-heart-outline
        span(v-t="'customer.stats.favorite_products_header'")
      .box.is-shadowless
        FavoriteProducts(:customer="customer")
    
    template(v-if="blocks.includes('notes') && customer.notes")
      h3.title.is-5.mb-2
        span.icon
          i.mdi.mdi-pencil
        span(v-t="'customer.stats.notes_header'")
      .box.is-shadowless
        em {{customer.notes}}
</template>

<script>
import CustomerSalesStats from '../sale/CustomerSalesStats'
import CustomerRewardsStats from '../rewards/CustomerRewardsStats'
import CustomerSurveyStats from '../surveys/CustomerSurveyStats'
import CustomerAppointmentsStats from '../calendar/CustomerAppointmentsStats'
import FavoriteServices from './FavoriteServices'
import FavoriteStaff from './FavoriteStaff'
import FavoriteProducts from './FavoriteProducts'
import FavoriteLocations from './FavoriteLocations'
import CustomerLoyaltyStats from './CustomerLoyaltyStats'
import {mapGetters, mapState} from 'vuex'
import {PRODUCTS} from '../../../constants/products'

export default {
  name: 'Stats',
  components: {
    CustomerRewardsStats,
    CustomerSalesStats,
    CustomerSurveyStats,
    FavoriteServices,
    FavoriteStaff,
    FavoriteProducts,
    FavoriteLocations,
    CustomerLoyaltyStats,
    CustomerAppointmentsStats
  },
  props: {
    customer: {type: Object, required: true},
    blocks: {
      type: Array,
      default: () => [
        'sales',
        'appointments',
        'rewards',
        'surveys',
        'fav_services',
        'fav_products',
        'fav_staff',
        'fav_locations'
      ]
    },
    boxed: {type: Boolean, default: true}
  },
  data() {
    return {
      PRODUCTS: PRODUCTS
    }
  },
  computed: {
    ...mapState('company', ['company']),
    ...mapGetters('location', ['hasMultipleLocations'])
  },
  methods: {
    onRebook({customerId, rebookingId}) {
      this.$emit('rebook', {customerId, rebookingId})
    }
  }
}
</script>
