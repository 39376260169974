<template lang="pug">
.is-flex.is-align-items-stretch.upload-container(style="flex-wrap: wrap")
  template(v-if="accept === 'image'")
    .is-flex-grow-1.is-flex.is-flex-align-items-stretch.brand-bordered.image-holder(v-for="(asset, idx) in assets" :class="size" :key="asset.id" style="flex-basis: 0;" ) 
      .is-square-container(v-if="accept === 'image'" :style="{'background-image': `url('${asset.url}')`}")
        button.delete(@click="remove(idx)")
  template(v-else)
    .tags.has-addons.mb-0.mr-2(v-for="(asset, idx) in assets" :key="asset.id")
        span.tag.is-info
          span.icon
            i.mdi.mdi-attachment
          span {{ asset.name.substring(0, 20) }}
        a.tag.is-delete(@click="remove(idx)")
        
  template(v-if="assets.length < uploadNumber")
    div(v-if="size === 'is-small'")
      b-upload.is-borderless.p-0.upload-trigger-small(v-model="files" expanded @input="attach" multiple drag-drop :accept="acceptExpression") 
        a.has-text-primary(style="text-decoration: none") 
          span.icon 
            i.mdi.mdi-attachment
          span(v-if="accept === 'image'" v-t="'assets_manager.upload_image_link'")
          span(v-if="accept === 'doc'" v-t="'assets_manager.upload_doc_link'")
          span(v-if="accept === 'all'" v-t="'assets_manager.upload_image_or_doc_link'")
    .brand-bordered.is-flex-grow-1.is-flex.is-flex-align-items-stretch.image-holder(v-else style="flex-basis: 0;" :class="size")
      .is-square-container
        b-upload.upload-trigger.is-flex.is-flex-align-items-center(v-model="files" expanded @input="attach" multiple drag-drop :accept="acceptExpression")
          .has-text-centered.is-size-7
            div
              span.icon.is-medium.has-text-grey
                i.mdi.mdi-cloud-upload-outline.mdi-36px
            template(v-if="size === 'is-medium'")
              .has-text-grey(v-if="accept === 'image'" v-t="'assets_manager.image_accepted_types'")
              .has-text-grey(v-else-if="accept === 'doc'" v-t="'assets_manager.doc_accepted_types'")
              .has-text-grey(v-else-if="accept === 'all'" v-t="'assets_manager.all_accepted_types'")
              p(v-t="'assets_manager.click_to_attach'")

</template>

<script>
import AssetsApi from '@/api/assets'

export default {
  name: 'SimpleFileUpload',
  props: {
    value: {type: Array, default: () => []},
    uploadNumber: {type: Number, default: 1},
    category: {type: String, default: 'assets'},
    customerId: {type: String, default: undefined},
    maxSize: {type: Number, default: 1000},
    size: {type: String, default: 'is-medium'},
    accept: {type: String, default: 'image'}
  },
  data() {
    return {
      assets: [],
      files: [],
      keyIndex: 0,
      uploading: false
    }
  },
  computed: {
    acceptExpression() {
      if (this.accept === 'image') {
        return 'image/*'
      } else if (this.accept === 'doc') {
        return 'application/pdf,application/vnd.ms-excel,application/msword,.csv,.txt,text/plain'
      } else {
        return 'image/*,application/pdf,application/vnd.ms-excel,application/msword,.csv,.txt,text/plain'
      }
    }
  },
  created() {
    if (this.value && this.value.length) {
      this.assets = this.value.map(v => {
        return {url: v, id: this.keyIndex++}
      })
    }
  },
  methods: {
    async uploadAsset(asst) {
      let {uploadUrl, asset} = await AssetsApi.generateUploadUrl({
        filename: asst.name,
        mimeType: asst.type,
        size: asst.size,
        category: this.category,
        customer: this.customerId
      })
      await AssetsApi.upload(uploadUrl, asst.data)

      await AssetsApi.setAssetSuccess(asset._id)
      return asset.url
    },
    async upload() {
      this.uploading = true
      let uploadPromises = this.assets.filter(a => a.data).map(asset => this.uploadAsset(asset))
      let urls = await Promise.all(uploadPromises)
      let allUrls = this.assets
        .filter(a => !a.data)
        .map(a => a.url)
        .concat(urls)
      this.$emit('input', allUrls)
      //this.$buefy.toast.open({message: this.$t('assets_manager.upload_success'), type: 'is-success', queue: false})
      this.uploading = false
      this.files = []
      //this.assets = []
    },
    async loadFile(file) {
      if (file.type.indexOf('image') !== -1) {
        let {image, dataUrl} = await AssetsApi.resizeImage(file, this.maxSize)
        if (this.assets.find(a => a.url === dataUrl)) {
          return
        }
        return this.assets.push({
          url: dataUrl,
          name: file.name,
          size: image.size,
          type: file.type,
          id: this.keyIndex++,
          data: image
        })
      }

      this.assets.push({name: file.name, type: file.type, size: file.size, id: this.keyIndex++, data: file})
    },
    async attach() {
      if (this.files.length > this.uploadNumber - this.assets.length) {
        this.files.splice(this.uploadNumber - this.assets.length, 1000)
      }
      // let invalidFormats = this.files.find(f => !!f.type.match(/(png|jpeg|jpg)/gi))
      // if (invalidFormats.length) {
      //   this.$buefy.toast.open({
      //     message: this.$t('assets_manager.format_error'),
      //     type: 'is-danger',
      //     duration: 3000,
      //     queue: false
      //   })
      //   return
      // }
      let loadPromises = this.files.map(f => this.loadFile(f))
      await Promise.all(loadPromises)
      this.files = []
    },

    async remove(idx) {
      this.assets.splice(idx, 1)
    },
    reset() {
      this.files = []
      this.assets = []
    }
  }
}
</script>
<style scoped>
.image-wrapper {
  height: 0;
  padding-top: 100%;
  position: relative;
  overflow: hidden;
  border: 1px solid #ddd;
  border-radius: 4px;
}
.upload-trigger {
  cursor: pointer;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: #f1f1f1;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
}
button.delete {
  display: none;
}
.brand-bordered:hover button.delete {
  display: block;
}
.image-wrapper small {
  display: block;
  position: absolute;
  bottom: 5px;
  left: 5px;
  right: 5px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 0.7em;
  text-align: center;
}
.image-wrapper.selected,
.image-wrapper:hover {
  border-color: rgb(0, 63, 145);
  background-color: rgb(206, 229, 255);
}
</style>
<style>
.upload-container .brand-bordered:not(:last-child) {
  margin-right: 1rem;
}

.is-square-container {
  height: 0;
  padding-top: 100%;
  width: 100%;
  position: relative;
  overflow: hidden;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
}

.image-holder.is-medium {
  max-width: 160px;
}
.image-holder.is-small {
  max-width: 60px;
}
</style>
