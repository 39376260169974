module.exports = {
  USD: {value: '$', title: 'USD $', placement: 'left', code: 'USD'},
  AUD: {value: 'AU$', title: 'AUD $', placement: 'left', code: 'AUD'},
  NZD: {value: 'NZ$', title: 'NZD $', placement: 'left', code: 'NZD'},
  EUR: {value: '€', title: 'EURO €', placement: 'left', code: 'EUR'},
  GBP: {value: '£', title: 'GBP £', placement: 'left', code: 'GBP'},
  CAD: {value: 'CA$', title: 'CAD $', placement: 'left', code: 'CAD'},
  ZAR: {value: 'R', title: 'ZAR R', placement: 'left', code: 'ZAR'},
  KES: {value: '/=', title: 'KES /=', placement: 'right', code: 'KES'},
  PHP: {value: '₱', title: 'PHP ₱', placement: 'left', code: 'PHP'},
  RUB: {value: 'p.', title: 'RUB ₽', placement: 'right', code: 'RUB'},
  AMD: {value: 'AMD', title: 'AMD ֏', placement: 'right', code: 'AMD'},
  THB: {value: '฿', title: 'THB ฿', placement: 'left', code: 'THB'},
  JPY: {value: '¥', title: 'JPY ¥', placement: 'left', code: 'JPY'}
}
