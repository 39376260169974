<template lang="pug">
  .modal-card
    header.modal-card-head
      p.modal-card-title(data-qa="modal-header") 
        b(v-if="!paymentTypeId" v-t="'payment_types_settings.new_payment_type_modal_header'")
        b(v-else v-t="'payment_types_settings.edit_payment_type_modal_header'")
      div
        a.button.is-white.is-small(@click="$emit('close')")
          span.icon
            i.mdi.mdi-close.mdi-24px
    section.modal-card-body
      b-field(:label="$t('payment_types_settings.name_label')" :type="errors.has('name') ? 'is-danger': ''" :message="errors.has('name') ? errors.first('name') : ''" )
        b-input(v-model="name" name='name' v-validate="'required'" :placeholder="$t('payment_types_settings.name_placeholder')")
    footer.modal-card-foot
      .buttons
        action-button(title="actions.save" @click="submit" :loading="submitting" data-qa="action-confirm")
        button.button(@click="$emit('close')" v-t="'actions.cancel'")  
    
</template>

<script>
import {mapActions, mapState} from 'vuex'

export default {
  name: 'PaymentTypeCreateEditModal',

  props: {
    paymentTypeId: {type: String, default: undefined}
  },
  data() {
    return {
      submitting: false,
      name: undefined
    }
  },
  computed: {
    ...mapState('company', ['company'])
  },
  async created() {
    if (this.paymentTypeId) {
      this.name = this.company.customPaymentTypes.find(c => c._id === this.paymentTypeId).name
    }
  },
  methods: {
    ...mapActions('company', ['update']),
    async submit() {
      const valid = await this.$validator.validateAll()
      if (this.submitting || !valid) {
        return
      }
      if (this.company.customPaymentTypes && this.company.customPaymentTypes.find(c => c.name === this.name.trim())) {
        this.$buefy.toast.open({
          message: this.$t('payment_types_settings.duplicate_name_error'),
          type: 'is-danger',
          queue: false
        })
        return
      }
      this.submitting = true
      try {
        let customPaymentTypes = [].concat(this.company.customPaymentTypes)

        if (this.paymentTypeId) {
          customPaymentTypes.find(c => c._id === this.paymentTypeId).name = this.name
        } else {
          customPaymentTypes.push({name: this.name})
        }
        await this.update({customPaymentTypes})

        this.$buefy.toast.open({message: this.$t('status.save_success'), type: 'is-success', queue: false})
        this.$emit('close')
      } catch (error) {
        this.$handleAPIError(error)
      }
      this.submitting = false
    },
    cancel() {
      this.$emit('close')
    }
  }
}
</script>
