<template lang="pug">
.warning-bar(v-if="warnings.length")
  .p-2.px-5(v-for="warning in warnings" :class="warning.type") 
    div 
      span.icon 
        i.mdi.mdi-alert-outline
      span.mr-4 {{ warning.text }}
    div
      button.button.is-small.is-rounded.is-outlined.is-black(@click="takeAction(warning.action)" :class="loadingAction === warning.action ? 'is-loading': ''") {{ warning.actionText }}
</template>

<script>
import {mapState} from 'vuex'

import SMS_TYPES from '../../../constants/smsTypes'
import AuthApi from '@/api/auth'
import SMSCenter from '../sms/SMSCenter'

export default {
  name: 'WarningBar',
  data() {
    return {
      loadingAction: undefined
    }
  },
  computed: {
    ...mapState('auth', ['user']),
    ...mapState('company', ['company', 'smsCredits']),

    warnings() {
      let res = []
      if (this.user.emailVerificationPending) {
        res.push({
          text: this.$t('email_verify.warning.message'),
          type: 'is-warning',
          action: 'resend',
          actionText: this.$t('actions.resend')
        })
      }
      if (!this.company.ownSMSProvider && this.smsCredits) {
        if (!this.smsCredits[SMS_TYPES.TRANSACTION]) {
          res.push({
            text: this.$t('sms.no_sms_credits_warning'),
            type: 'is-danger',
            action: 'sms',
            actionText: this.$t('sms.actions.top_up')
          })
        } else if (this.smsCredits[SMS_TYPES.TRANSACTION] < 50) {
          res.push({
            text: this.$t('sms.low_sms_credits_warning'),
            type: 'is-warning',
            action: 'sms',
            actionText: this.$t('sms.actions.top_up')
          })
        }
      }

      return res
    }
  },
  methods: {
    async takeAction(action) {
      switch (action) {
        case 'resend': {
          this.resendVerificationEmail()
          break
        }
        case 'sms':
          this.$buefy.modal.open({
            parent: this,
            component: SMSCenter,
            canCancel: ['outside', 'escape'],
            hasModalCard: true,
            width: 960
          })
          break
      }
    },
    async resendVerificationEmail() {
      if (this.loadingAction) {
        return
      }
      this.loadingAction = 'resend'

      try {
        await AuthApi.resendVerificationEmail()
        this.$buefy.toast.open({
          message: this.$t('account_setup.verification_email_resent'),
          type: 'is-success',
          duration: 5000,
          queue: false
        })
      } catch (err) {
        this.$handleAPIError(err)
      }
      this.loadingAction = undefined
    }
  }
}
</script>
