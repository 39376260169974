<template lang="pug">
.modal-card(style="width: auto")
  header.modal-card-head
    p.modal-card-title(data-qa="modal-header") 
      b(v-t="'trainings.title'")
    div
      a.button.is-white.is-small(@click="$emit('close')")
        span.icon
          i.mdi.mdi-close.mdi-24px
  section.modal-card-body
    //h2.title.is-3 General
    .columns.is-multiline
      .column.is-6(v-for="material in materials")
        .card
          .card-image
            figure.image(style="position: relative; padding-bottom: 58.727569331158236%; height: 0;") 
                iframe(:src="`${material.src}?hide_owner=true&hide_share=true&hide_title=true&hideEmbedTopBar=true`" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen style="position: absolute; top: 0; left: 0; width: 100%; height: 100%;")
          .card-content(style="min-height: 120px;")
            h2.title.is-5
              span {{material.title}}
            p.subtitle.is-6
              span {{material.description}}
            //p
              span Level
              span.tag {{material.level}}
            
  //footer.modal-card-foot
    .buttons
      button.button(@click="$emit('close')" v-t="'actions.close'")
</template>
<script>
export default {
  data() {
    return {
      materials: [
        {
          title: 'Ընդհանուր ծանոթություն',
          description: 'Գրանցում համակարգում, հիմնական մենյու, օգտատիրոջ կարգավորումներ, լեզու, գաղտնաբառի տեղադրում',
          src: 'https://www.loom.com/embed/8f66ca34f204472d9b63596abcf1eeb8',
          level: 1
        },
        {
          title: 'Հիմնական կարգավորումներ',
          description:
            'Կազմակերպության անվանում, կոնտակտային ինֆորմացիա, տարադրամ, օրացույցի կարգավորումներ, շաբաթվա սկիզբ, ժամային գոտի, մասնաճյուղեր, լեզուներ',
          src: 'https://www.loom.com/embed/dd374848d4c247d69e5bfa3b241d7d6f',
          level: 1
        },
        {
          title: 'Հաճախորդներ և CRM',
          description: 'Նոր հաճախորդի ստեղծում, տվյալների խմբագրում, որոնում, հաճախորդների ջնջում',
          src: 'https://www.loom.com/embed/cddb997e2e34499790b1d32de8d77a79',
          level: 1
        },
        {
          title: 'Գրանցումներ և օրացույց',
          description:
            'Աշխատանքային օրացույց, ինչպես կատարել գրանցում, ծառայությունների ընտրություն, հաճախորդի ընտրություն, նշումներ, գրանցման խմբագրում',
          src: 'https://www.loom.com/embed/e37d668180b34f61819784a4ee3a0f92',
          level: 1
        },
        {
          title: 'Վաճառքներ',
          description: 'Գրանցման ավարտ, վաճառքի զամբյուղ, վճարման միջոցներ, զեղչ, հաշիվներ, վաճառք առանց գրանցման',
          src: 'https://www.loom.com/embed/3867be7fc58a488f85e97eb811d7e4cb',
          level: 1
        },

        {
          title: 'Ծառայություններ և գնացուցակ',
          description:
            'Ծառայությունների խմբագրում, նոր ծառայություններ և բաժիներ, գնացուցակ, տևողություն, ծառայությունների վերադասավորում',
          src: 'https://www.loom.com/embed/83c73647a6e446419839ed1b37917991',
          level: 1
        },
        {
          title: 'Անձնակազմ',
          description:
            'Մասնագետներ և ադմինիստրատորներ, նոր աշխատակցի գրանցում, ծառայությունների նշում, ծանուցումների կարգավորում',
          src: 'https://www.loom.com/embed/ecae9f84146c404cb001bad530a66c35',
          level: 1
        },
        {
          title: 'Աշխատանքային գրաֆիկ',
          description: 'Աշխատաքային գրաֆիկի խմբագրում, հերթափոխներ, հատուկ օրերի նշում',
          src: 'https://www.loom.com/embed/d469f093934b433e8174409d06dfba1e',
          level: 1
        },

        {
          title: 'Առցանց գրանցում',
          description: 'Ինչպես է աշխատում առցանց գրանցումը, կարգավորումներ, ծառայություններ, աշխատանքային գրաֆիկ',
          src: 'https://www.loom.com/embed/e9c53766c64d4268a7fd1a91ede574a7',
          level: 1
        },

        {
          title: 'Գրանցումներ. Մաս 2',
          description: 'Նախընտրած մասնագետ, սխալների վերահսկում, գրանցման ավտոմատ ստուգում',
          src: 'https://www.loom.com/embed/f10c32333ee541d287f53447d189bcf8',
          level: 2
        },

        {
          title: 'Հաշվետվություններ և վիճակագրություն',
          description: 'Հիմնական և մանրամասն վիճակագրություն, թրենդեր, վաճառքների հաշվետվություններ',
          src: 'https://www.loom.com/embed/288036a9230d466caaa026152a797541',
          level: 2
        },

        {
          title: 'Հաճախորդի անձնական քարտ',
          description:
            'Հաճախորդի այցելությունների և վաճառքների պատմություն, վերլուծություն, նախընտրած ծառայություններ և մասնագետներ',
          src: 'https://www.loom.com/embed/f0b09ff5a5c94268af46f7f67934d7cb',
          level: 1
        }
      ]
    }
  }
}
</script>
