<template lang="pug">
div
  .p-4.has-border-bottom
    .columns.is-multiline.mb-0
      .column.is-6
          p.heading 
            span.icon.has-text-success
              i.mdi.mdi-arrow-up
            span {{$t('rewards.points_heading')}}
          p.title.is-5
            rewards-view(:value="customer.balance" :currency="true")

      .column.is-6
          p.heading 
            span.icon.has-text-danger
              i.mdi.mdi-arrow-down
            span {{$t('rewards.redeemed_heading')}}
          p.title.is-5
            rewards-view(:value="(customer.discounts && customer.discounts.points || 0) * company.membership.currencyWorth" :currency="true")
      .column.is-6
          p.heading 
            | {{$t('rewards.membership_heading')}} 
            a(@click="changeMembership" v-if="customer.memberSince") 
              i.mdi.mdi-square-edit-outline
          p.title.is-5(v-if="customer.memberSince")
            b-icon(icon="exclamation" v-if="customer.effectiveMembership !== undefined"  size="is-small")
            span {{company.membership.titles[customer.membership]}} 
            small.has-text-primary {{company.membership.pointRates[customer.membership] | percentify}}
            | &nbsp;
            span.tag(v-if="customer.cardId") 
              |  
              small #  
              small 
                b {{customer.cardId}}
          action-button.is-rounded.is-small.is-outlined(v-else @click="makeMember" :bold="false" title="rewards.actions.convert_to_member" product="rewards")
      .column.is-6
          p.heading {{$t('rewards.member_since_heading')}}
          p(v-if="customer.memberSince") {{customer.memberSince | fulldate}}
          p(v-else) N/A
  .has-text-centered.p-4  
    a(@click="showTransactions()") {{ $t('customer.stats.rewards_program_transactions') }}
</template>

<script>
import MembershipEditForm from './MembershipEditForm'
import MakeMemberForm from './MakeMemberForm'
import {mapState} from 'vuex'
import CustomerRewards from './CustomerRewards'

export default {
  name: 'CustomerRewardsStats',
  props: {
    customer: {type: Object, required: true}
  },
  computed: {
    ...mapState('company', ['company'])
  },
  methods: {
    changeMembership() {
      this.$buefy.modal.open({
        parent: this,
        component: MembershipEditForm,
        canCancel: ['outside', 'escape'],
        hasModalCard: true,
        props: {customerId: this.customer._id}
      })
    },
    makeMember() {
      this.$buefy.modal.open({
        parent: this,
        component: MakeMemberForm,
        canCancel: ['outside', 'escape'],
        hasModalCard: true,
        props: {customerId: this.customer._id}
      })
    },
    showTransactions() {
      this.$buefy.modal.open({
        parent: this,
        component: CustomerRewards,
        canCancel: ['outside', 'escape'],
        hasModalCard: true,
        fullScreen: this.isMobile(),
        props: {customer: this.customer}
      })
    }
  }
}
</script>
