<template lang="pug">
.modal-card(style="width: auto")
  header.modal-card-head
    p.modal-card-title(data-qa="modal-header") 
      b(v-t="'payment.link_pay_title'")
    div
      a.button.is-white.is-small(@click="$emit('close')")
        span.icon
          i.mdi.mdi-close.mdi-24px
  section.modal-card-body
    select-box(v-model="type" :data="types")
    p.subtitle.is-6 {{ $t('payment.link_pay_desc') }} 
     span(v-if="type === 'email'") {{ $t('payment.link_pay_email_to') }} 
      b {{ customer.email }}
     span(v-if="type === 'sms'") {{ $t('payment.link_pay_sms_to') }} 
      b
        phone-view(:value="customer.phone" :tag="false" :icon="false")
  footer.modal-card-foot
    .buttons
      action-button(@click="submit" title="actions.confirm")
      button.button( @click="$emit('close')" v-t="'actions.close'") 
</template>
<script>
export default {
  name: 'LinkPayModal',
  props: {
    customer: {type: Object, required: true}
  },
  data() {
    return {
      type: undefined
    }
  },
  computed: {
    types() {
      return [
        {
          title: 'SMS',
          description: this.customer.phone
            ? this.$t('payment.link_option.sms_desc')
            : this.$t('payment.link_option.no_sms_desc'),
          value: 'sms',
          disabled: !this.customer.phone
        },
        {
          title: 'Email',
          description: this.customer.email
            ? this.$t('payment.link_option.email_desc')
            : this.$t('payment.link_option.no_email_desc'),
          value: 'email',
          disabled: !this.customer.email
        }
      ]
    }
  },
  created() {
    if (this.customer.phone) {
      this.type = 'sms'
    } else {
      this.type = 'email'
    }
  },
  methods: {
    submit() {
      this.$emit('input', this.type)
      this.$emit('close')
    }
  }
}
</script>
