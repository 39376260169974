<template lang="pug">
  span(v-if="name") {{name}}
</template>

<script>
import {mapState} from 'vuex'

export default {
  name: 'CategoryNameView',
  props: {
    value: {type: String, required: true}
  },
  computed: {
    ...mapState('auth', ['user']),
    ...mapState('services', ['categoriesMap']),
    name() {
      let cat = this.categoriesMap[this.value]
      if (!cat) {
        return
      }
      let catName = cat.name
      if (this.user.lng) {
        catName = (cat.names && cat.names[this.user.lng]) || catName
      }

      return catName
    }
  }
}
</script>
