import {
  SET_SUBSCRIPTION,
  SET_UPCOMING_INVOICE,
  SET_INVOICES,
  SET_UPCOMING_INVOICE_LOADING,
  SET_INVOICES_LOADING,
  SET_PAYMENT_METHOD,
  RESET_BILLING
} from './mutationTypes'
import BillingApi from '@/api/billing'

let __subscriptionPromise
let __productsPromise

export default {
  namespaced: true,
  state: {
    subscription: null,
    paymentMethod: null,
    invoices: [],
    upcomingInvoice: null,
    upcomingInvoiceLoading: false,
    invoicesLoading: false
  },
  getters: {
    subscriptionIncomplete: state => {
      return (
        state.subscription &&
        (state.subscription.status === 'incomplete' ||
          state.subscription.status === 'past_due' ||
          state.subscription.status === 'unpaid')
      )
    },
    subscriptionInactive: state => {
      return (
        state.subscription &&
        (state.subscription.status === 'canceled' || state.subscription.status === 'incomplete_expired')
      )
    },
    subscriptionActive: state => {
      return state.subscription && (state.subscription.status === 'active' || state.subscription.status === 'trialing')
    }
  },
  mutations: {
    [SET_SUBSCRIPTION](state, subscription) {
      state.subscription = subscription
    },
    [SET_PAYMENT_METHOD](state, paymentMethod) {
      state.paymentMethod = paymentMethod
    },
    [SET_INVOICES](state, invoices) {
      state.invoices = invoices
    },
    [SET_UPCOMING_INVOICE](state, upcomingInvoice) {
      state.upcomingInvoice = upcomingInvoice
    },
    [SET_UPCOMING_INVOICE_LOADING](state, loading) {
      state.upcomingInvoiceLoading = loading
    },
    [SET_INVOICES_LOADING](state, loading) {
      state.invoicesLoading = loading
    },

    [RESET_BILLING](state) {
      state.subscription = null
      state.paymentMethod = null
      state.invoices = []
      state.upcomingInvoice = null
      state.upcomingInvoiceLoading = false
      state.invoicesLoading = false
      __productsPromise = undefined
      __subscriptionPromise = undefined
    }
  },
  actions: {
    async fetch({commit}, force) {
      if (!__subscriptionPromise || force) {
        __subscriptionPromise = Promise.all([BillingApi.getSubscription(), BillingApi.getPaymentMethod()])
      }
      let [subscription, paymentMethod] = await __subscriptionPromise
      commit(SET_SUBSCRIPTION, subscription)
      commit(SET_PAYMENT_METHOD, paymentMethod)
    },
    refresh({dispatch}) {
      dispatch('company/fetch', true, {root: true})
      dispatch('fetch', true)
      dispatch('fetchInvoices')
      dispatch('fetchUpcomingInvoice')
      dispatch('completeness/fetch', true, {root: true})
    },
    async resumeSubscription({dispatch}) {
      await BillingApi.resumeSubscription()
      dispatch('refresh')
    },
    async cancelSubscription({dispatch}, now) {
      await BillingApi.cancelSubscription(now)
      dispatch('refresh')
    },
    async fetchInvoices({commit}) {
      commit(SET_INVOICES_LOADING, true)
      try {
        let data = await BillingApi.listInvoices()
        commit(SET_INVOICES, data)
        commit(SET_INVOICES_LOADING, false)
      } catch (err) {
        commit(SET_INVOICES_LOADING, false)
        throw err
      }
    },
    async fetchUpcomingInvoice({commit}) {
      commit(SET_UPCOMING_INVOICE_LOADING, true)
      try {
        let data = await BillingApi.getUpcomingInvoice()
        commit(SET_UPCOMING_INVOICE, data)
        commit(SET_UPCOMING_INVOICE_LOADING, false)
      } catch (err) {
        commit(SET_UPCOMING_INVOICE_LOADING, false)
        throw err
      }
    },
    async createSubscription({dispatch}, data) {
      await BillingApi.createSubscription(data)
      dispatch('refresh')
    },
    async updatePaymentMethod({dispatch}, data) {
      await BillingApi.updatePaymentMethod(data)
      dispatch('refresh')
    },
    async startFreeTrial({dispatch}, plan) {
      let res = await BillingApi.startFreeTrial(plan)
      dispatch('refresh')
      return res
    },
    fetchProducts(_context, force) {
      if (!__productsPromise || force) {
        __productsPromise = BillingApi.listProducts()
      }
      return __productsPromise
    },
    reset({commit}) {
      commit(RESET_BILLING)
    }
  }
}
