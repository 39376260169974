<template lang="pug">
b-dropdown(v-model="selectedStaffId" @input="onChange" :scrollable="!isMobile()"  :append-to-body="isMobile()")
  template(#trigger)
    .is-flex.is-flex-direction-row.is-align-items-center.is-clickable(v-if="selectedStaffId")
      staff-pic.is-round.mr-3(:staff="selectedStaffId" :class="picSizeClass")
      div
        p.title.is-6.is-cropped-text(style="width: 100px") {{ selectedStaff.firstName }} {{ selectedStaff.lastName }}
        //p.subtitle.is-6.has-text-grey {{ $t(`roles.${selectedStaff.role}`) }}

      .control(v-if="hasPreferred")
        b-tooltip(:label="$t('appointment.mark_as_preferred_tooltip')")
          .pl-2(@click.stop="switchPreferred") 
            span.icon.is-medium(style="vertical-align: -6px")
              i.mdi.mdi-24px.mdi-heart-outline(v-if="!isPreferred")
            
              i.mdi.mdi-heart.mdi-24px.has-text-danger(v-else)
      
  template(v-if="activeLocations.length > 1")
    template(v-for="(loc, idx) in locations")
      .px-3.pt-4.pb-2
        span.icon
          i.mdi.mdi-map-marker-outline
        span {{ loc.title }}
      //hr.dropdown-divider(v-if="idx")
      b-dropdown-item(v-for="member in activeServiceMembersByMainLocation(loc._id)" :value="member._id" :key="member._id") 
        .is-flex.is-flex-direction-row.is-align-items-center
          staff-pic.is-round.mr-3(:staff="member" :class="picSizeClass")
          div
            p.is-size-6.has-text-weight-semibold {{ member.firstName }} {{ member.lastName }}
  template(v-else)
    b-dropdown-item(v-for="member in activeServiceMembers" :value="member._id" :key="member._id") 
      .is-flex.is-flex-direction-row.is-align-items-center
        staff-pic.is-round.mr-3(:staff="member" :class="picSizeClass")
        div
          p.is-size-6.has-text-weight-semibold {{ member.firstName }} {{ member.lastName }}

</template>
<script>
import {mapState, mapGetters} from 'vuex'

export default {
  name: 'StaffSelectDropdown',
  props: {
    staffId: {type: String, default: undefined},
    preferred: {type: Boolean, default: false},
    expanded: {type: String, default: undefined},
    size: {type: String, default: undefined},
    locationId: {type: String, default: undefined},
    serviceId: {type: String, default: undefined},
    hasPreferred: {type: Boolean, default: true}
  },
  data() {
    return {
      selectedStaffId: undefined,
      isPreferred: false
    }
  },
  computed: {
    ...mapGetters('location', ['activeLocations']),
    ...mapState('auth', ['user']),
    ...mapState('staff', ['staffMap']),
    ...mapGetters('staff', ['activeServiceMembers', 'activeServiceMembersByMainLocation']),
    ...mapGetters('services', ['serviceById']),
    selectedStaff() {
      if (!this.selectedStaffId) {
        return ''
      }
      return this.staffMap[this.selectedStaffId]
    },
    locations() {
      let data = this.activeLocations.filter(l => this.activeServiceMembersByMainLocation(l._id).length)
      data.unshift(
        data.splice(
          data.findIndex(l => l._id === this.user.location),
          1
        )[0]
      )
      return data
    },
    picSizeClass() {
      if (this.size === 'is-small') {
        return 'is-24x24'
      }
      return 'is-32x32'
    }
  },
  watch: {
    staffId(newVal) {
      this.selectedStaffId = newVal
    },
    preferred(newVal) {
      this.isPreferred = newVal
    }
  },
  async created() {
    if (this.$rfProfileScope() && this.user.service) {
      this.selectedStaffId = this.user._id
      this.onChange()
    } else if (this.staffId) {
      this.selectedStaffId = this.staffId
      this.isPreferred = this.preferred
    } else {
      this.setRecommendedStaff()
      this.onChange()
    }
  },
  methods: {
    onChange() {
      this.$emit('input', {staffId: this.selectedStaffId, preferred: this.isPreferred})
    },
    setRecommendedStaff() {
      let activeMembers
      if (this.locationId) {
        activeMembers = this.activeServiceMembersByMainLocation(this.locationId)
      } else {
        activeMembers = this.activeServiceMembers
      }
      if (this.serviceId) {
        let serviceProviderMembers = activeMembers.filter(member =>
          this.serviceById(this.serviceId).staff.includes(member._id)
        )
        activeMembers = serviceProviderMembers.length ? serviceProviderMembers : activeMembers
      }

      this.selectedStaffId = (activeMembers.length && activeMembers[0]._id) || undefined
    },
    switchPreferred() {
      this.isPreferred = !this.isPreferred
      this.$emit('input', {staffId: this.selectedStaffId, preferred: this.isPreferred})
    }
  }
}
</script>
