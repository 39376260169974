module.exports = {
  SALE: 'sale',
  SALE_REFUND: 'refund',

  CHANGE_RETURN: 'change',
  RETURN: 'return',
  ADDITION: 'addition',
  TIP: 'tip',

  INITIAL_BALANCE: 'balance',
  EXPENSE: 'expense',
  STOCK_PURCHASE: 'stock',
  PAYOUT: 'payout',
  OUT: 'out',
  COMMISSION: 'commission'
}
