<template lang="pug">
div
  ul(style="width: 100%")
    li.list-item.px-0(v-for="({staff, count, last}, idx) in list" style="") 
      .is-flex.is-flex-direction-row.is-align-items-center
        div(style="flex: 0 0 40px")
          staff-pic.is-32x32.is-round(:staff="staff")
        div(style="flex: 1 1 auto; overflow: hidden")
          p(style="overflow:hidden; text-overflow: ellipsis; white-space: nowrap")
            staff-view(:id="staff")
          p.has-text-grey
            small {{$t('misc.times', {count})}}
            |  ·  
            small {{last | fromNow}}
        //div(style="flex: 0 0 auto")
          button.button.is-small.is-primary.is-outlined repeat
  p.has-text-right(v-if="expandable")
    a(@click="collapsed = false" v-if="collapsed") 
      span.icon
        i.mdi.mdi-chevron-down
      span(v-t="'actions.see_all'")
    a(@click="collapsed = true" v-if="!collapsed") 
      span.icon
        i.mdi.mdi-chevron-up
      span(v-t="'actions.show_less'")

</template>
<script>
export default {
  name: 'FavoriteStaff',
  props: {
    customer: {type: Object, required: true}
  },
  data() {
    return {
      collapsed: true
    }
  },
  computed: {
    expandable() {
      return this.customer.favStaff.length > 3
    },
    list() {
      if (this.collapsed) {
        return this.customer.favStaff.slice(0, 3)
      }
      return this.customer.favStaff
    }
  }
}
</script>
