<template lang="pug">
div
  section-action-title.mb-4(:title="$t('customer.contact_details_heading')" :open="false")
    template(v-slot:actions)
      button.button.is-white.is-small(@click.stop="editCustomer" data-qa="action-edit") 
        span.icon.has-text-primary 
          i.mdi.mdi-pencil-outline.mdi-18px
        
    table(style="width: 100%")
      tr
        td.py-1.has-text-grey(style="width:50%") {{ $t('customer.email_label') }}
        td.py-1 
          a(:href="`mailto:${customer.email}`" v-if="customer.email") {{ customer.email }}
          span(v-else) -
      tr(v-if="$rfHasPermission('PRIVACY_VIEW_PHONE_NUMBERS')")
        td.py-1.has-text-grey {{$t('customer.phone_label')}}
        td.py-1(v-if="customer.phone || customer.altPhone") 
          phone-view(:value="customer.phone" type="international" :tag="false" :icon="false")
          template(v-if="customer.altPhone")
            | ,   
            phone-view(:value="customer.altPhone" type="international" :tag="false" :icon="false")
        td.py-1(v-else) - 
      
      tr(v-if="customer.nickname")
        td.py-1.has-text-grey {{ $t('customer.nickname_label') }}
        td.py-1 {{$t(`genders.${customer.nickname}`)}}
      tr(v-if="customer.birthday")
        td.py-1.has-text-grey {{ $t('customer.birthday_label') }}
        td.py-1 
          date-view(:value="customer.birthday" parse="YYYYMMDD" format="D MMM, YYYY")
      tr(v-if="customer.lng")
        td.py-1.has-text-grey {{ $t('customer.language_label') }}
        td.py-1 {{$t(`languages.${customer.lng}`)}}
      tr(v-if="customer.gender")
        td.py-1.has-text-grey {{ $t('customer.gender_label') }}
        td.py-1 {{$t(`genders.${customer.gender}`)}}
      tr(v-if="formattedAddress")
        td.py-1.has-text-grey {{ $t('customer.address_label') }}
        td.py-1 {{ formattedAddress }}
          
        
      tr
        td.py-1.has-text-grey(v-t="'customer.channel_heading'")
        td.py-1
          AcquisitionChannelView(:customer="customer" )
      tr
        td.py-1.has-text-grey {{ $t('customer.account_created_label') }}
        td.py-1 
          date-view(:value="customer.created" format="D MMM, YYYY")
      tr
        td.py-1.has-text-grey(v-t="'customer.reachable_via_label'")
        td.py-1 
          .tags.mb-0  
            span.tag.is-rounded.is-black(v-if="customer.acceptSMS && customer.phone") SMS
            span.tag.is-rounded.is-black(v-if="customer.acceptEmail && customer.email") Email
            span.tag.is-rounded.is-black(v-if="customer.acceptMessage && customer.sender") Messenger
  section-action-title.mb-4(:title="$t('customer.memberships_label')")
    //template(v-slot:actions)
      button.button.is-white.is-small 
        span.icon 
          i.mdi.mdi-pencil .mdi-18px
        span Edit
    CustomerMemberships.mb-3(:customerId="customer._id")
  section-action-title.mb-4(:title="$t('customer.coupons_label')")
    template(v-slot:actions)
      button.button.is-white.is-small(@click.stop="giveGift")
        span.icon.has-text-primary 
          i.mdi.mdi-plus.mdi-18px
    CustomerCoupons(:customerId="customer._id")

  div(v-if="customer.referredBy || customer.affiliatedBy")
    p.heading {{customer.referredBy ? $t('customer.referred_by_label'): $t('customer.affiliated_by_label')}}
    customer-preview(:customerId="customer.referredBy || customer.affiliatedBy" :detailed="false" :clickable="true")  
  
  section-action-title.mb-4(:title="$t('customer.notes_label')")
    .content.notification.p-4
      p
        em {{customer.notes}}

  template(v-if="customer.instagram || customer.facebook")
    hr.is-narrow
    .is-flex.is-flex-direction-row.is-justify-content-space-around
      a(:href="customer.instagram" target="_blank" v-if="customer.instagram") 
        span.icon.is-medium
          i.mdi.mdi-instagram.mdi-18px
  
      a(:href="customer.facebook" target="_blank"  v-if="customer.facebook") 
        span.icon.is-medium
          i.mdi.mdi-facebook.mdi-18px
</template>
<script>
import CustomerCreateEditForm from './CustomerCreateEditForm'
import AcquisitionChannelView from './AcquisitionChannelView'
import {PRODUCTS} from '../../../constants/products'
import CustomerMemberships from '../membership/CustomerMemberships'
import GiftForm from './GiftForm'
import CustomerCoupons from '../coupon/CustomerCoupons'

export default {
  name: 'CustomerGeneralInfo',
  components: {
    CustomerCreateEditForm,
    AcquisitionChannelView,
    CustomerMemberships,
    CustomerCoupons
  },
  props: {
    customer: {type: Object, required: true}
  },
  data() {
    return {
      PRODUCTS,
      coupons: undefined
    }
  },
  computed: {
    formattedAddress() {
      if (!this.customer.address) {
        return
      }
      let add = this.customer.address
      return [add.street, add.streetAlt, add.city, add.zip, add.state].filter(c => c).join(', ')
    }
  },
  methods: {
    editCustomer() {
      this.$buefy.modal.open({
        parent: this,
        component: CustomerCreateEditForm,
        canCancel: ['escape'],
        hasModalCard: true,
        fullScreen: true,
        props: {customerId: this.customer._id}
      })
    },
    giveGift() {
      this.$buefy.modal.open({
        parent: this,
        props: {customerId: this.customer._id},
        component: GiftForm,
        hasModalCard: true,
        canCancel: ['escape']
      })
    }
  }
}
</script>
