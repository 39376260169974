<template lang="pug">
.is-relative
  em-loading(:active="loading" :is-full-page="false") 
  AppointmentCard(v-for="appt in customerUpcomingAppointments" :key="appt._id" @click="showDetails(appt)" :value="appt" :show-all-statuses="false" show-location) 
  .m-0(v-if="!loading && !customerUpcomingAppointments.length") 
    .px-4.py-5.has-background-warning
      p.subtitle.is-6
        span.icon
          i.mdi.mdi-alert-outline
        span(v-t="'appointment.no_upcoming'")
      
</template>
<script>
import {mapActions, mapGetters, mapState} from 'vuex'
import APPOINTMENT_STATUS from '../../../constants/appointmentStatus'
import AppointmentCard from './AppointmentCard'
import AppointmentDetails from './AppointmentDetails'
import CreateEditAppointment from './CreateEditAppointment'
export default {
  name: 'CustomerUpcomingAppointments',
  components: {
    AppointmentCard
  },
  props: {
    customerId: {type: String, required: true}
  },
  data() {
    return {
      loading: false
    }
  },
  computed: {
    ...mapGetters('appointments', ['customerUpcomingAppointments']),

    ...mapState('company', ['company'])
  },
  watch: {
    'customer.updated'() {
      return this.fetchAppointments()
    }
  },
  created() {
    return this.fetchAppointments()
  },
  methods: {
    ...mapActions('appointments', ['fetchCustomerAppointments']),
    async fetchAppointments() {
      this.loading = true
      try {
        await this.fetchCustomerAppointments(this.customerId)
      } catch (err) {
        this.$handleAPIError(err)
      }
      this.loading = false
    },
    showDetails(appointment) {
      if (
        appointment.status === APPOINTMENT_STATUS.COMPLETED ||
        appointment.status === APPOINTMENT_STATUS.NOSHOW ||
        appointment.status === APPOINTMENT_STATUS.INCOMPLETE ||
        appointment.status === APPOINTMENT_STATUS.CANCELED
      ) {
        this.$buefy.modal.open({
          parent: this.$parent,
          props: {appointmentId: appointment._id},
          component: AppointmentDetails,
          fullScreen: true,
          canCancel: ['escape', 'outside']
        })
      } else {
        this.$buefy.modal.open({
          parent: this.$parent,
          props: {appointmentId: appointment._id, customerId: appointment.customer},
          component: CreateEditAppointment,
          customClass: !this.isMobile() ? 'half-modal' : '',
          animation: !this.isMobile() ? 'slide-right' : 'zoom-out',
          hasModalCard: this.isMobile(),
          fullScreen: true,
          canCancel: ['escape', 'outside']
        })
      }
    }
  }
}
</script>
