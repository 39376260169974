import Vue from 'vue'

export default {
  signin(credentials) {
    return Vue.http.post('accounts/signin', credentials)
  },
  signinWithFacebook(userAccessToken) {
    return Vue.http.post('accounts/signin/facebook', {userAccessToken})
  },
  signup(credentials) {
    return Vue.http.post('accounts/signup', credentials)
  },
  signupUser(data) {
    return Vue.http.post('accounts/user/signup', data)
  },
  createBusiness(data) {
    return Vue.http.post('accounts/business/signup', data)
  },
  requestOTP(data) {
    return Vue.http.post(`otp`, data)
  },
  checkEmail({email, token}) {
    return Vue.http.post('accounts/email', {email, token}).then(res => res.body)
  },
  getCurrentAccount() {
    return Vue.http.get('accounts/my')
  },

  setPassword(key, password) {
    return Vue.http.post('accounts/password', {key, password})
  },

  verifyEmail(key) {
    return Vue.http.post('accounts/verify', {key})
  },
  resendVerificationEmail() {
    return Vue.http.post('accounts/resend')
  },

  requestPasswordReset(data) {
    return Vue.http.post('accounts/password/requestReset', data)
  },

  resetPassword(data) {
    return Vue.http.post('accounts/password/reset', data)
  },

  updateDetails(id, data) {
    return Vue.http.put(`accounts/${id}`, data)
  },

  updatePassword(id, data) {
    return Vue.http.put(`accounts/${id}/password`, data)
  },

  getAPIAccessToken() {
    return Vue.http.get('accounts/apiToken').then(res => res.data)
  },

  joinWaitingList(data) {
    return Vue.http.post('leadgen/waitingList', data).then(res => res.body)
  }
}
