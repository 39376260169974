<template lang="pug">
.modal-card
    header.modal-card-head
      p.modal-card-title
        b(v-t="'services.select_service_title'")
      div
        a.button.is-white.is-small(@click="$emit('close')")
          span.icon
            i.mdi.mdi-close.mdi-24px
    section.modal-card-body.p-0.is-flex.is-align-items-stretch(style="width: 100%")
      ServiceListSelect(:locationId="locationId" @input="select" style="width: 100%")
    footer.modal-card-foot
      .buttons
        action-button(v-if="!quickSelect" title="actions.select" @click="submit" :disabled="!selected")
        button.button.is-outlined(type="button" @click="$emit('close')" ) {{$t('actions.cancel')}}      
</template>
<script>
import ServiceListSelect from './ServiceListSelect'
export default {
  name: 'ServiceSelectModal',
  components: {
    ServiceListSelect
  },
  props: {
    quickSelect: {type: Boolean, default: false},
    locationId: {type: String, default: undefined}
  },
  data() {
    return {
      selected: undefined
    }
  },

  methods: {
    select(service) {
      this.selected = service
      if (this.quickSelect) {
        this.submit()
      }
    },
    submit() {
      this.$emit('input', this.selected)
      this.$emit('close')
    }
  }
}
</script>
