<template lang="pug">
.modal-card
    header.modal-card-head
      p.modal-card-title
        b(v-t="'memberships.select_membership_package_title'")
      div
        a.button.is-white.is-small(@click="$emit('close')")
          span.icon
            i.mdi.mdi-close.mdi-24px
    section.modal-card-body.p-0
    
      .p-5(v-if="!packages.length" style="min-height: 200px")
        p.has-text-centered(v-t="'memberships.no_packages'")
      .list-item.py-4.is-flex.is-flex-direction-row.is-align-items-center.is-clickable(v-for="memPack in packages" :key="memPack._id" @click="select(memPack)") 
        span.icon.is-medium.mr-2
          i.mdi.mdi-24px.mdi-circle-slice-8.has-text-primary(v-if="selected === memPack")
          i.mdi.mdi-24px.mdi-checkbox-blank-circle-outline(v-else)
        .is-flex-grow-1
          MembershipPackageView(:value="memPack._id" :fullview="false")
        
    footer.modal-card-foot
      .buttons
        action-button(title="actions.select" @click="submit" :disabled="!selected")
        button.button.is-outlined(type="button" @click="$emit('close')" ) {{$t('actions.cancel')}}      
</template>
<script>
import {mapGetters, mapState} from 'vuex'
import MembershipPackageView from './MembershipPackageView'
export default {
  components: {
    MembershipPackageView
  },
  props: {
    locationId: {type: String, default: undefined}
  },
  data() {
    return {
      selected: undefined
    }
  },
  computed: {
    ...mapState('auth', ['user']),
    ...mapGetters('membershipPackages', ['membershipPackages']),
    packages() {
      return this.membershipPackages.filter(
        s =>
          (!this.locationId || s.locations.includes(this.locationId)) &&
          (!this.$rfProfileScope() || s.staff.includes(this.user._id))
      )
    }
  },

  methods: {
    select(memPack) {
      this.selected = memPack
    },
    submit() {
      this.$emit('input', this.selected)
      this.$emit('close')
    }
  }
}
</script>
