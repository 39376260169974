<template lang="pug">
  div(style="position: relative")
    button.delete(@click="remove" v-if="productId")
    ProductView(:productId="productId" v-if="productId" :sku="true")
    .is-flex.is-align-items-center(v-else)
      span.icon.is-medium
        i.mdi.mdi-24px.mdi-barcode
      span.is-size-5(v-t="'sales.product_select_prompt'")
      ProductSelector.ml-2(@input="onProductSelect")
</template>
<script>
import ProductView from './ProductView'
import ProductSelector from './ProductSelector'
export default {
  components: {
    ProductView,
    ProductSelector
  },
  props: {
    value: {type: String, default: undefined}
  },
  data() {
    return {
      productId: undefined
    }
  },
  watch: {
    value(newVal) {
      this.productId = newVal
    }
  },
  created() {
    this.productId = this.value
  },
  methods: {
    remove() {
      this.productId = undefined
      this.$emit('input')
    },
    onProductSelect(product) {
      this.productId = product._id
      this.$emit('input', this.productId)
    }
  }
}
</script>
