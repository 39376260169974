<template lang="pug">
div
  screen-title(title="online_booking_settings.heading")
  .box
    b-field()
      b-switch(v-model="enabled" type="is-success") {{enabled ? $t('misc.enabled') : $t('misc.disabled')}}
    template(v-if="enabled")
      b-field(:label="$t('online_booking_settings.booking_url_label')")
        b-input(:value="`https://emly.${company.country === 'AM' ? 'am': 'co'}/b/${shortId || company._id}`" id="bookingUrl" icon="web" readonly expanded)
        p.control
          button.button.is-light(@click="copyToClipboard('bookingUrl')")
            span.icon
              i.mdi.mdi-content-copy  
      b-field(:label="$t('online_booking_settings.short_id_label')" :type="errors.has('shortId') ? 'is-danger': ''" :message="errors.has('shortId') ? errors.first('shortId') : ''")
        b-input(v-model="shortId" expanded v-validate="'min:6|alpha_dash|companyShortId'" name="shortId" data-vv-as="short ID" :placeholder="$t('online_booking_settings.short_id_placeholder')") 
      
      label.label {{$t('online_booking_settings.booking_intervals_label')}}
      b-field
        b-radio(v-model="interval" name="interval" :native-value="ONLINE_BOOKING_INTERVALS.DEFAULT") 
          span {{ $t('online_booking_settings.intervals_default') }} 
          p.help {{ $t('online_booking_settings.intervals_default_help') }}
      b-field
        b-radio(v-model="interval" name="interval" :native-value="ONLINE_BOOKING_INTERVALS.SLOTS") 
          span {{ $t('online_booking_settings.intervals_slots') }}
          p.help {{ $t('online_booking_settings.intervals_slots_help') }}
      b-field(:label="$t('online_booking_settings.booking_before')" :type="errors.has('bookAtLeastHours') ? 'is-danger': ''" :message="errors.has('bookAtLeastHours') ? errors.first('bookAtLeastHours') : ''")
        b-input(v-model="bookAtLeastHours" v-validate="'required|numeric'" name="bookAtLeastHours" data-vv-as="booking at least")
        p.control
          span.button.is-static {{ $t('misc.hours') }}
      b-field(:label="$t('online_booking_settings.allowed_booking_in_advance')" :type="errors.has('bookAtMaxDays') ? 'is-danger': ''" :message="errors.has('bookAtMaxDays') ? errors.first('bookAtMaxDays') : ''")
        b-input(v-model="bookAtMaxDays" v-validate="'required|numeric'" name="bookAtMaxDays" data-vv-as="booking at max")
        p.control
          span.button.is-static {{ $t('misc.days') }}
      hr
      b-field(:label="$t('online_booking_settings.specialist_selection_label')")
        b-switch(v-model="onlineBookingStaff") {{onlineBookingStaff ? $t('misc.enabled') : $t('misc.disabled')}}
      b-field(v-if="$rfSubscribed('surveys') && $rfEnabled('surveys')" :label="$t('online_booking_settings.show_rating_label')" )
        b-switch(v-model="onlineBookingRating" ) {{onlineBookingRating ? $t('misc.enabled') : $t('misc.disabled')}}
      
      hr
      b-field(:label="$t('online_booking_settings.gender_kids_filter_label')" :message="$t('online_booking_settings.gender_kids_filter_description')")
        b-switch(v-model="onlineBookingGenderSelector" ) {{onlineBookingGenderSelector ? $t('misc.enabled') : $t('misc.disabled')}}
      hr 
      label.label(v-t="'online_booking_settings.customer_data'")
      b-checkbox(v-model="onlineBookingEmail") {{ $t('online_booking_settings.collect_email') }}
  .box(v-if="$rfSubscribed('payments')")
    h2.title.is-4 {{$t('online_booking_settings.deposits_title')}}
    //p.subtitle.is-6 {{ $t('online_booking_settings.deposits_subtitle') }}
    b-field
      b-switch(v-model="deposit.active" type="is-success") {{deposit.active ? $t('online_booking_settings.deposit_enabled') : $t('online_booking_settings.deposit_disabled')}}
    b-field(:label="$t('online_booking_settings.deposit_default_ratio')")
      b-select(v-model="deposit.defaultRatio")
        option(v-for="ratio in Object.values(DEPOSIT_GROUP)" :key="ratio" :value="ratio") {{$t(`deposit_ratio.${ratio}`)}}
    b-field(:label="$t('online_booking_settings.cancelation_policy')" :message="$t('online_booking_settings.cancelation_policy_desc')")
      b-radio(v-model="deposit.cancelationPolicy" name="cancelationPolicy" :native-value="4" ) {{$t("cancelation_policy.four_hours")}}
      b-radio(v-model="deposit.cancelationPolicy" name="cancelationPolicy" :native-value="12") {{$t("cancelation_policy.twelve_hours")}}
      b-radio(v-model="deposit.cancelationPolicy" name="cancelationPolicy" :native-value="24") {{$t("cancelation_policy.one_day")}}
      b-radio(v-model="deposit.cancelationPolicy" name="cancelationPolicy" :native-value="48") {{$t("cancelation_policy.two_days")}}
      b-radio(v-model="deposit.cancelationPolicy" name="cancelationPolicy" :native-value="72") {{$t("cancelation_policy.three_days")}}
      b-radio(v-model="deposit.cancelationPolicy" name="cancelationPolicy" :native-value="168")  {{$t("cancelation_policy.one_week")}}
      b-radio(v-model="deposit.cancelationPolicy" name="cancelationPolicy" :native-value="null")  {{$t("cancelation_policy.no_refund")}}
  .buttons.mt-4
    action-button(title="actions.save" @click="submit" :loading="submitting" permission="ONLINE_BOOKING_SETTINGS_MANAGE" scope)
  
</template>

<script>
import {mapState} from 'vuex'
import ONLINE_BOOKING_INTERVALS from '../../../constants/onlineBookingIntervals'
import DEPOSIT_GROUP from '../../../constants/depositGroup'

export default {
  name: 'OnlineBookingSettings',
  data() {
    return {
      ONLINE_BOOKING_INTERVALS,
      DEPOSIT_GROUP,
      submitting: false,
      enabled: undefined,
      shortId: undefined,
      bookAtLeastHours: 2,
      bookAtMaxDays: 30,
      onlineBookingGenderSelector: false,
      onlineBookingRating: true,
      onlineBookingEmail: true,
      onlineBookingStaff: true,
      deposit: {active: false, defaultRatio: DEPOSIT_GROUP.FULL, cancelationPolicy: 24},
      interval: ONLINE_BOOKING_INTERVALS.DEFAULT
    }
  },
  computed: {
    ...mapState('company', ['company'])
  },
  created() {
    this.enabled = this.company.onlineBooking
    this.shortId = this.company.shortId
    this.interval = this.company.onlineBookingInterval || ONLINE_BOOKING_INTERVALS.DEFAULT
    this.bookAtLeastHours = this.company.bookAtLeastHours
    this.bookAtMaxDays = this.company.bookAtMaxDays
    this.onlineBookingEmail = this.company.onlineBookingEmail
    this.onlineBookingGenderSelector = this.company.onlineBookingGenderSelector
    this.onlineBookingRating = this.company.onlineBookingRating
    this.onlineBookingStaff = this.company.onlineBookingStaff
    this.deposit.active = this.company.deposit.active
    this.deposit.defaultRatio = this.company.deposit.defaultRatio
    this.deposit.cancelationPolicy = this.company.deposit.cancelationPolicy
  },
  methods: {
    async submit() {
      this.submitting = true
      const valid = await this.$validator.validateAll()
      if (!valid || this.phoneValid === false) {
        this.submitting = false
        return
      }

      const data = {
        shortId: this.shortId || undefined,
        onlineBooking: this.enabled,
        onlineBookingInterval: this.interval,
        bookAtLeastHours: this.bookAtLeastHours,
        bookAtMaxDays: this.bookAtMaxDays,
        onlineBookingEmail: this.onlineBookingEmail,
        onlineBookingGenderSelector: this.onlineBookingGenderSelector,
        onlineBookingRating: this.onlineBookingRating,
        onlineBookingStaff: this.onlineBookingStaff,
        deposit: this.deposit,
        _updateType: 'online_booking'
      }
      try {
        await this.$store.dispatch('company/update', data)
        this.$buefy.toast.open({message: this.$t('status.save_success'), type: 'is-success', queue: false})
        this.$emit('saved')
      } catch (error) {
        this.$handleAPIError(error)
      }
      this.submitting = false
    },
    copyToClipboard(element) {
      document.getElementById(element).select()
      document.execCommand('copy')
      this.$buefy.toast.open({
        message: this.$t('rewards_program.settings.copied_message'),
        type: 'is-success',
        queue: false
      })
    }
  }
}
</script>
