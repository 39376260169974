<script>
import VueChart from 'vue-chartjs'
import ReportsApi from '@/api/reports'
import CHART_COLORS from '../../helpers/chartColors'
export default {
  name: 'SurveysBarChart',
  extends: VueChart.Bar,
  props: {
    period: {type: Object, default: undefined},
    survey: {type: String, required: true},
    type: {type: String, required: true},
    location: {type: String, default: undefined},
    color: {type: String, default: CHART_COLORS.YELLOW}
  },
  data() {
    return {
      loading: false
    }
  },
  watch: {
    period() {
      return this.fetchData()
    },
    location() {
      return this.fetchData()
    }
  },
  created() {
    if (this.period) {
      return this.fetchData()
    }
  },
  methods: {
    async fetchData() {
      this.loading = true
      try {
        let data
        switch (this.type) {
          case 'avgSale':
            data = await ReportsApi.getSurveysAvgSalesReport(this.survey, {
              from: this.period.from,
              to: this.period.to,
              location: this.location
            })
            break
          case 'spending':
            data = await ReportsApi.getSurveysSpendingReport(this.survey, {
              from: this.period.from,
              to: this.period.to,
              location: this.location
            })
            break
          case 'count':
          default:
            data = await ReportsApi.getSurveysReport(this.survey, {
              from: this.period.from,
              to: this.period.to,
              location: this.location
            })
        }
        this.$data._chart && this.$data._chart.destroy()
        this.renderChart(
          {labels: data.labels, datasets: [{backgroundColor: this.color, data: data.data}]},
          {
            legend: {
              display: false
            },
            tooltips: {
              mode: 'nearest',
              intersect: false
            },
            responsive: true,
            maintainAspectRatio: false,
            scales: {
              xAxes: [
                {
                  barPercentage: 1
                }
              ],
              yAxes: [
                {
                  ticks: {
                    min: 0,
                    precision: 0
                  }
                }
              ]
            }
          }
        )
      } catch (error) {
        this.$handleAPIError(error)
      }
      this.loading = false
    }
  }
}
</script>
