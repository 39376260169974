<template lang="pug">
b-select(v-model="selectedTax" name='selectedTax' :expanded="expanded" @input="onInput")
  option(value="") {{$t('tax_settings.no_tax')}}
  optgroup(:label="$t('tax_settings.location_defaults.options_taxes')")
    option(v-for="tax in activeTaxes" :value="tax._id") 
      tax-view(:value="tax._id")
  optgroup(:label="$t('tax_settings.location_defaults.options_groups')")
    option(v-for="tax in activeTaxGroups" :value="tax._id") 
      tax-view(:value="tax._id")
</template>
<script>
import {mapGetters} from 'vuex'
export default {
  name: 'TaxSelector',
  props: {
    value: {type: String, default: undefined},
    expanded: {type: Boolean, default: undefined}
  },
  data() {
    return {
      selectedTax: undefined
    }
  },
  computed: {
    ...mapGetters('tax', ['activeTaxes', 'activeTaxGroups'])
  },
  watch: {
    value() {
      this.selectedTax = this.value || ''
    }
  },
  created() {
    this.selectedTax = this.value || ''
  },
  methods: {
    onInput() {
      this.$emit('input', this.selectedTax || undefined)
    }
  }
}
</script>
