<template lang="pug">
.modal-card(style="width: auto")
  header.modal-card-head
    p.modal-card-title
      b(v-t="'ledger.session_history_title'")
    div
      a.button.is-white.is-small(@click="$emit('close')")
        span.icon
          i.mdi.mdi-close.mdi-24px
  section.modal-card-body
    b-field(grouped)    
      b-field
        PeriodSelector(v-model="period")
      b-field
        LocationSelector(v-model="location")
    b-table(
        :data="data" 
        :loading="loading" 
        hoverable=true 
        striped=true 
        class="is-vertically-aligned" 
        custom-row-key="_id"
        paginated
        backend-pagination
        :total="total"
        :per-page="perPage"
        @page-change="onPageChange"

        backend-sorting
        :default-sort-direction="defaultSortOrder"
        :default-sort="[sortField, sortOrder]"
        @sort="onSort")
        template(slot="empty")
          empty-content(v-if="!loading" title="ledger.empty_search_result")
          
        
        b-table-column(field="open" :label="$t('ledger.open_on_label')" v-slot="props" sortable )
          date-view(:value="props.row.open" format="LL LT")
          br
          small 
            staff-view(:id="props.row.openBy")
        
        b-table-column(field="openBalance" :label="$t('ledger.open_balance')" v-slot="props" numeric )
          b 
            currency-view(:value="props.row.openBalance")
          template(v-if="props.row.openDifference")
            br
            currency-view.tag.is-medium(:value="props.row.openDifference" :class="props.row.openDifference > 0 ? 'is-warning': 'is-danger'")
        
        //b-table-column(field="openBy" :label="$t('ledger.open_by_label')" v-slot="props" sortable )
          staff-view(:id="props.row.openBy")

      
        b-table-column(field="close" :label="$t('ledger.close_on_label')" v-slot="props" sortable )
          date-view(:value="props.row.close" format="LL LT")
          br
          small 
            staff-view(:id="props.row.closeBy")
        b-table-column(field="closeBalance" :label="$t('ledger.close_balance')" v-slot="props" numeric )
          b 
            currency-view(:value="props.row.closeBalance")
          template(v-if="props.row.closeDifference")
            br
            currency-view.tag.is-medium(:value="props.row.closeDifference" :class="props.row.closeDifference > 0 ? 'is-warning': 'is-danger'")
        
        //b-table-column(field="closeBy" :label="$t('ledger.close_by_label')" v-slot="props" sortable )
          staff-view(:id="props.row.closeBy")
          
        b-table-column(field="notes" :label="$t('ledger.notes_label')" v-slot="props") 
          span {{props.row.notes }}
        b-table-column(field="location" :label="$t('ledger.location_label')" sortable v-slot="props" :visible="hasMultipleLocations") 
          location-view(:value="props.row.location")
        
    
  footer.modal-card-foot
    .buttons
      button.button.is-outlined(type="button" @click="$emit('close')" ) {{$t('actions.close')}}      
        
</template>

<script>
import LedgerApi from '@/api/ledger'
import PeriodSelector from '../helpers/PeriodSelector'
import LocationSelector from '../helpers/LocationSelector'
import {mapGetters} from 'vuex'
export default {
  name: 'LedgerTransaction',
  components: {
    PeriodSelector,
    LocationSelector
  },

  data() {
    return {
      data: [],
      loading: false,
      sortField: 'open',
      sortOrder: 'desc',
      total: 0,
      page: 1,
      perPage: 10,
      defaultSortOrder: 'desc',
      period: undefined,
      location: undefined
    }
  },
  computed: {
    ...mapGetters('location', ['hasMultipleLocations'])
  },
  watch: {
    period() {
      return this.fetchData()
    },
    location() {
      return this.fetchData()
    }
  },
  async created() {
    await this.fetchData()
  },
  methods: {
    async fetchData() {
      if (!this.period) {
        return
      }
      const query = {
        sortBy: this.sortField,
        sortDir: this.sortOrder,
        page: this.page,
        perPage: this.perPage,
        from: this.period.from,
        to: this.period.to,
        location: this.location
      }
      this.loading = true
      try {
        let {sessions, total} = await LedgerApi.getSessions(query)
        this.data = sessions
        this.total = total
      } catch (err) {
        this.$handleAPIError(err)
      }
      this.loading = false
    },
    async onSort(field, order) {
      this.sortField = field
      this.sortOrder = order
      await this.fetchData()
    },
    async onPageChange(page) {
      this.page = page
      await this.fetchData()
    }
  }
}
</script>
