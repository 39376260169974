import {SET_MEMBERS, SET_MEMBER, REMOVE_MEMBER, SET_MEMBERS_ORDER} from './mutationTypes'
import StaffApi from '@/api/staff'
import Vue from 'vue'

let __listPromise
export default {
  namespaced: true,
  state: {
    staffMap: {},
    staffOrder: []
  },
  getters: {
    activeMembers(state) {
      return state.staffOrder
        .map(c => state.staffMap[c])
        .filter(s => s.active)
        .sort((a, b) => (a.order || 0) - (b.order || 0))
    },
    activeServiceMembers(state) {
      return state.staffOrder
        .map(c => state.staffMap[c])
        .filter(s => s.active && s.service)
        .sort((a, b) => (a.order || 0) - (b.order || 0))
    },
    activeServiceMembersByIds(state) {
      return staffIds => {
        return state.staffOrder
          .map(c => state.staffMap[c])
          .filter(s => s.active && s.service && staffIds.includes(s._id))
          .sort((a, b) => (a.order || 0) - (b.order || 0))
      }
    },
    activeServiceMembersByMainLocation: state => locationId => {
      return state.staffOrder
        .map(c => state.staffMap[c])
        .filter(s => s.active && s.service && (!locationId || (s.location && s.location === locationId)))
        .sort((a, b) => (a.order || 0) - (b.order || 0))
    },
    activeServiceMembersByLocationsExtra(state) {
      return ({locationId, staffIds}) => {
        return state.staffOrder
          .map(c => state.staffMap[c])
          .filter(
            s =>
              s.active &&
              s.service &&
              (staffIds.includes(s._id) ||
                !locationId ||
                s.location === locationId ||
                (s.locations && s.locations.includes(locationId)))
          )
      }
    },
    activeServiceMembersByLocations: state => locationId => {
      return state.staffOrder
        .map(c => state.staffMap[c])
        .filter(
          s =>
            s.active &&
            s.service &&
            (!locationId ||
              (s.location && (s.location === locationId || (s.locations && s.locations.includes(locationId)))))
        )
        .sort((a, b) => (a.order || 0) - (b.order || 0))
    },
    activeMembersByLocation: state => locationId => {
      return state.staffOrder
        .map(c => state.staffMap[c])
        .filter(s => s.active && (!locationId || (s.location && s.location === locationId)))
        .sort((a, b) => (a.order || 0) - (b.order || 0))
    },
    nonActiveServiceMembers(state) {
      return state.staffOrder
        .map(c => state.staffMap[c])
        .filter(s => !s.active && s.service)
        .sort((a, b) => (a.order || 0) - (b.order || 0))
    }
  },
  mutations: {
    [SET_MEMBERS](state, members) {
      let mapped = {}
      for (let member of members) {
        mapped[member._id] = member
      }
      state.staffMap = mapped
      state.staffOrder = members.map(c => c._id)
    },
    [SET_MEMBER](state, member) {
      let current = state.staffMap[member._id] || {}
      for (let prop in member) {
        if (current[prop] === undefined) {
          Vue.set(current, prop, member[prop])
        } else {
          current[prop] = member[prop]
        }
      }
      state.staffMap[member._id] = current
    },
    [REMOVE_MEMBER](state, id) {
      delete state.staffMap[id]
      let idx = state.staffOrder.indexOf(id)
      if (idx !== -1) {
        state.staffOrder.splice(idx, 1)
      }
    },
    [SET_MEMBERS_ORDER](state, order) {
      order.forEach((id, idx) => (state.staffMap[id].order = idx))
    }
  },
  actions: {
    async list({commit, state}, force) {
      if (__listPromise) {
        await __listPromise
      }
      if (!__listPromise && (!state.staffOrder.length || force)) {
        __listPromise = StaffApi.list({list: true})
        let data = await __listPromise
        __listPromise = undefined
        commit(SET_MEMBERS, data)
      }
    },
    async updateMembersOrder({commit}, order) {
      commit(SET_MEMBERS_ORDER, order)
      await StaffApi.updateOrder(order)
    },
    async fetchMember({state, commit}, id) {
      if (!state.staffMap[id]) {
        let {body} = await StaffApi.getById(id)
        commit(SET_MEMBER, body)
      }
      return state.staffMap[id]
    },
    async fetchStaffById({dispatch, state}, id) {
      if (!state.staffMap[id]) {
        await dispatch('list', !__listPromise)
      }
      return state.staffMap[id]
    },

    async removeMember({commit}, id) {
      await StaffApi.remove(id)
      commit(REMOVE_MEMBER, id)
    },
    async updateMember({commit, state}, data) {
      let {body} = await StaffApi.update(data._id, data)
      commit(SET_MEMBER, body)
      return state.staffMap[body._id]
    },
    async createMember({dispatch, state}, data) {
      data.order = state.staffOrder.length
      let {body} = await StaffApi.create(data)
      await dispatch('list', true)
      // commit(SET_MEMBER, body)
      return state.staffMap[body._id]
    }
  }
}
