<script>
import dayjs from 'dayjs'
import {mapState} from 'vuex'
import {TYPES as COUPON_TYPES} from '../../../constants/couponTypes'
import saleHelper from '../../../shared/saleHelper'
export default {
  computed: {
    ...mapState('company', ['company']),
    artwork() {
      if (this.coupon && this.coupon.artwork) {
        return this.coupon.artwork
      }
      return require('@/assets/coupon-placeholder.png')
    },
    validity() {
      if (!this.coupon || !this.coupon.validity) {
        return ''
      }
      let issueValidity = dayjs()
        .add(this.coupon.validity.value, this.coupon.validity.unit)
        .endOf('day')
      let totalValidity = this.coupon.validity.until && dayjs(this.coupon.validity.until)
      let validity
      let dateFormat = `D MMM YYYY, ${this.company.timeFormat === '12' ? 'h:mma' : 'H:mm'}`
      if ((totalValidity && issueValidity.isBefore(totalValidity)) || !totalValidity) {
        validity = issueValidity.format(dateFormat)
      } else {
        validity = totalValidity.format(dateFormat)
      }
      return this.$t('coupons.validity', {validity})
    },
    title() {
      if (this.coupon && this.coupon.title) {
        return this.coupon.title
      }
      return this.$t('coupons.no_coupon_label')
    },
    description() {
      if (!this.coupon) {
        return ''
      }
      switch (this.coupon.type) {
        case COUPON_TYPES.DISCOUNT:
        case COUPON_TYPES.CASHBACK:
          return this.$t(`coupons.${this.coupon.type}_preview`, {val: this.coupon.value})
        case COUPON_TYPES.OFF:
          return this.$t(`coupons.${this.coupon.type}_preview`, {
            val: saleHelper.formatCurrency(this.coupon.value, this.company.currency)
          })
        case COUPON_TYPES.SERVICES_COMBO:
          return this.$t(`coupons.${this.coupon.type}_preview`, {
            val: saleHelper.formatCurrency(this.coupon.value, this.company.currency),
            pack: this.coupon.coupon.package.length
          })
        default:
          return this.$t(`coupons.${this.coupon.type}_preview`, {val: this.coupon.value})
      }
    }
  }
}
</script>
