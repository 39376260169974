<template lang="pug">
  span.nps(v-if="value", :class="valueClass" title="Last NPS Score") {{value}}
  span(v-else-if="showUnknown") -
</template>
<script>
export default {
  props: {
    customer: {type: Object, required: true},
    showUnknown: {type: Boolean, default: false}
  },
  computed: {
    value() {
      if (!this.customer.surveys || !this.customer.surveys.nps || !this.customer.surveys.nps.value) {
        return
      }
      return this.customer.surveys.nps.value
    },
    valueClass() {
      if (!this.value) {
        return
      }
      return `nps-${Math.round(this.value)}`
    }
  }
}
</script>
