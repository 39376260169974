<template lang="pug">
  b-table.is-vertically-aligned.is-fullwidth(:data="data" :loading="loading"  hoverable=true @click="showDetails" :row-class="getTodayHighlightClass")
    
    b-table-column(field="type" label="" v-slot="props") 
      b-tooltip(:label="props.row.type", position="is-right")
        RewardTypeIcon(:type="props.row.type")
    b-table-column(:visible="showCustomerColumn" field="customer" :label="$t('rewards.list_customer_heading')" v-slot="props") 
      strong {{props.row.customer | nameShortify}}
    b-table-column(field="points" :label="$t('rewards.list_reward_heading')" numeric v-slot="props") 
      strong(:class="props.row.points > 0? 'has-text-success' : 'has-text-danger'" v-if="props.row.points !== undefined") 
        rewards-view(:value="props.row.points")
    b-table-column(field="base" :label="$t('rewards.list_base_heading')" numeric v-slot="props") 
      currency-view(:value="props.row.base")
    b-table-column(field="time" :label="$t('rewards.list_date_heading')" v-slot="props" numeric) 
      span(v-if="date === 'relative'") {{props.row.created | fromNow}}
      span(v-if="date === 'absolute'") {{props.row.created | fulldate}}  
    template(slot="empty")
      empty-content(icon="close-circle-outline" title="rewards.list_no_recent_activity")
</template>

<script>
import RewardDetails from './RewardDetails'
import RewardTypeIcon from './RewardTypeIcon'
import dayjs from 'dayjs'

export default {
  name: 'RewardsList',
  components: {
    RewardTypeIcon
  },
  props: {
    data: {type: Array, default: () => []},
    loading: {type: Boolean, default: false},
    date: {type: String, default: 'absolute'},
    showCustomerColumn: {type: Boolean, default: false},
    highlightToday: {type: Boolean, default: false}
  },
  methods: {
    showDetails(reward) {
      this.$buefy.modal.open({
        parent: this,
        props: {reward},
        component: RewardDetails,
        hasModalCard: true,
        canCancel: ['escape', 'outside'],
        events: {
          removed: id => {
            this.$emit('removed', id)
          }
        }
      })
    },
    getTodayHighlightClass(reward) {
      if (!this.highlightToday) {
        return ''
      }
      const today = dayjs(reward.created).format('YYYYMMDD') === dayjs().format('YYYYMMDD')
      if (today) {
        return 'is-highlighted'
      }
    }
  }
}
</script>
