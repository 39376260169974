<template lang="pug">
  .timeline
    .timeline-item
      .timeline-marker.is-primary
      .timeline-content
        p.heading.is-size-7.mb-2
          date-view(:value="appointment.created" format="LL LT")
          template(v-if="appointment.user") 
            |  -  
            staff-view(:id="appointment.user")
        p
          p(v-if="appointment.user") {{$t(`appointment_history.appointment_booked`)}}  
          p(v-else-if="appointment.source === APPOINTMENT_SOURCE.ONLINE") {{$t(`appointment_history.online_booked`)}}
        
    .timeline-item(v-for="change in appointment.statusHistory")  
      .timeline-marker.is-primary
      .timeline-content(style="width: 100%")
        p.heading.is-size-7.mb-2
          date-view(:value="change.ts" format="LL LT")
          template(v-if="change.user") 
            |  -  
            staff-view(:id="change.user")
        template(v-if="change.changes && change.changes.length")
          .mb-2(v-for="field in change.changes") 
            div(v-if="field.field === 'customer'")
              p.mb-2 {{$t(`appointment_history.customer_changed_from`)}}  
              customer-name.tag.is-white.is-rounded(:value="field.from")
              span.tag
                span.icon
                  i.mdi.mdi-arrow-right
              customer-name.tag.is-white.is-rounded(:value="field.to")
            div(v-else-if="field.field === 'location'")
              p.mb-2 {{$t(`appointment_history.location_changed_from`)}}
              location-view.tag.is-white.is-rounded(:value="field.from")
              span.tag
                span.icon
                  i.mdi.mdi-arrow-right
              location-view.tag.is-white.is-rounded(:value="field.to")
            div(v-else-if="field.field === 'color'")
              template(v-if="!field.from && field.to")
                span {{$t(`appointment_history.color_set`)}}  
                span.color-button(:style="{'background-color': `#${field.to}`, height: '16px', 'vertical-align':'-3px'}")
              template(v-else-if="field.from && !field.to")
                span {{$t(`appointment_history.color_removed`)}} 
                span.color-button(:style="{'background-color': `#${field.from}`, height: '16px', 'vertical-align':'-3px'}")
              template(v-else)
                span {{$t(`appointment_history.color_changed_from`)}} 
                span.color-button(:style="{'background-color': `#${field.from}`, height: '16px', 'vertical-align':'-3px'}")
                span.tag
                  span.icon
                    i.mdi.mdi-arrow-right
                span.color-button(:style="{'background-color': `#${field.to}`, height: '16px', 'vertical-align':'-3px'}")
            div(v-else-if="field.field === 'start'")
              
              template(v-if="isDifferentDay(field.from, field.to)")
                p.mb-2 {{$t(`appointment_history.rescheduled_from`)}}
                  
                date-view.tag.is-white.is-rounded(:value="field.from" format="LL LT")
                span.tag
                  span.icon
                    i.mdi.mdi-arrow-right
                date-view.tag.is-white.is-rounded(:value="field.to" format="LL LT")
              template(v-else)
                span {{$t(`appointment_history.start_changed_from`)}}
                |  
                date-view(:value="field.from" format="LT")
                span.tag
                  span.icon
                    i.mdi.mdi-arrow-right
                date-view(:value="field.to" format="LT")
            div(v-else-if="field.field === 'notes'")
              template(v-if="!field.from && field.to")
                p.mb-2 {{$t(`appointment_history.notes_added`)}}  
                .notification.p-3.is-white {{ field.to }}
              template(v-else-if="field.from && !field.to")
                p.mb-2 {{$t(`appointment_history.notes_removed`)}}
                .notification.p-3.is-white {{ field.from }}
              template(v-else)
                p.mb-2 {{$t(`appointment_history.notes_changed_from`)}} 
                .notification.p-3.is-white {{ field.from }}
                .notification.p-3.is-white {{ field.to }}
            div(v-else-if="field.field === 'repeat'")
              p.mb-2 {{$t(`appointment_history.repeat_changed_from`)}} 
              RepeatPatternView.tag.is-white.is-rounded(:value="field.from")
              span.tag
                span.icon
                  i.mdi.mdi-arrow-right
              RepeatPatternView.tag.is-white.is-rounded(:value="field.to")
            div(v-else-if="field.field === 'services'")
              //p.mb-2 {{$t(`appointment_history.services_changed_from`)}} 
              //.is-flex.is-justify-content-space-between.box.p-0.is-shadowless.m-0
                .p-3(style="flex: 1 0 0")
                  AppointmentItem(v-for="service in field.from" :service="service" :key="service._id" showTime)
                .p-3(style="flex: 1 0 0")
                  AppointmentItem(v-for="service in field.to" :service="service" :key="service._id" showTime)
              .box.p-3.is-shadowless.m-0
                AppointmentItem(v-for="service in field.from" :service="service" :key="service._id" showTime)
              span.tag
                span.icon
                  i.mdi.mdi-arrow-down
              .box.p-3.is-shadowless.m-0
                AppointmentItem(v-for="service in field.to" :service="service" :key="service._id" showTime)
        p(v-else)
          span {{$t(`appointment_history.status_changed_to`)}}
          |  
          AppointmentStatusIcon(:status="change.status" show-text is-tag style="vertical-align: 1px")
          
        p.is-small(v-if="change.user")
          
      
    
      
</template>
<script>
import dayjs from 'dayjs'
import APPOINTMENT_SOURCE from '../../../constants/appointmentSource'
import AppointmentItem from './AppointmentItem'
import AppointmentStatusIcon from './AppointmentStatusIcon'
import RepeatPatternView from './RepeatPatternView'
export default {
  name: 'AppointmentStatusHistory',
  components: {
    AppointmentItem,
    AppointmentStatusIcon,
    RepeatPatternView
  },
  props: {
    appointment: {type: Object, required: true}
  },
  data() {
    return {
      APPOINTMENT_SOURCE
    }
  },
  methods: {
    isDifferentDay(from, to) {
      return parseInt(dayjs(from).format('YYYYMMDD')) - parseInt(dayjs(to).format('YYYYMMDD'))
    }
  }
}
</script>
<style scoped>
.timeline .timeline-item {
  padding-bottom: 1rem;
}
</style>
