<template lang="pug">
span(v-if="current < comparator")
  span(:class="reverse? 'has-text-success': 'has-text-danger'")  ▼ 
  currency-view(:value="comparator - current" v-if="type === 'currency'")
  span(v-else-if="type === 'number'") {{comparator - current}}
  percentage-view(:value="current === 0 ? 1 : (comparator - current)/comparator" v-else-if="type === 'percent'")
span(v-else-if="current > comparator")
  span(:class="reverse ? 'has-text-danger': 'has-text-success'")  ▲ 
  currency-view(v-if="type === 'currency'" :value="current - comparator" )
  span(v-else-if="type === 'number'") {{current - comparator}}
  percentage-view(v-else-if="type === 'percent'" :value="comparator === 0 ? 1 : (current - comparator)/comparator" )
</template>
<script>
export default {
  props: {
    type: {type: String, default: 'currency'},
    reverse: {type: Boolean, default: false},
    current: {type: Number, required: true},
    comparator: {type: Number, required: true}
  }
}
</script>
