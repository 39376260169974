<template lang="pug">
div.is-relative
  em-loading(:active="loading")
  h3.title.is-6.is-uppercase(v-t="'rewards.members_sales_title'")
  h5.subtitle.is-7 {{period && period.display}}
  p.title.is-2.is-size-3-mobile 
    currency-view(:value="members.sales")
    |   
    comparison-view.is-size-5(v-if="averageSale !== undefined" :current="members.sales" :comparator="averageSale" type="percent")
      
  p.subtitle.is-6(v-t="'rewards.member_avg_sale'")
  //p Livetime Value  
    currency-view(:value="members.ltv")
  .list-item.px-0 {{ $t('rewards.average_visits_label') }}  
    b.is-pulled-right 
      number-view(:value="members.visits" format="0.0")
  .list-item.px-0 {{ $t('rewards.average_freq_label') }}   
    b.is-pulled-right 
      number-view(:value="members.frequency" format="0.0")
</template>

<script>
import ReportsApi from '@/api/reports'

export default {
  name: 'MembersSalesStats',
  props: {
    period: {type: Object, default: undefined},
    location: {type: String, default: undefined}
  },
  data() {
    return {
      loading: false,
      members: {sales: 0, visits: 0, ltv: 0},
      customers: {sales: 0, visits: 0, ltv: 0},
      averageSale: 0
    }
  },
  watch: {
    period() {
      return this.fetchData()
    },
    location() {
      return this.fetchData()
    }
  },
  created() {
    if (this.period) {
      return this.fetchData()
    }
  },
  methods: {
    async fetchData() {
      this.loading = true
      try {
        const {members, customers} = await ReportsApi.getMembersSalesStats({
          from: this.period.from,
          to: this.period.to,
          location: this.location
        })

        const {averageSale} = await ReportsApi.getAverageSale({
          from: this.period.from,
          to: this.period.to,
          location: this.location
        })
        this.averageSale = averageSale
        this.members = members
        this.customers = customers
        // if (this.members.frequency) {
        //   this.members.ltv = Math.round(
        //     this.members.sales * (365 / this.members.frequency) * 3 * 0.6
        //   )
        // }
      } catch (error) {
        this.$handleAPIError(error)
      }
      this.loading = false
    }
  }
}
</script>
