<template lang="pug">
  span.tags(v-if="employees && employees.length", :class="{centered: centered}")
    span.tag.is-warning(v-for="employee in employees", :class="size") {{employee | nameShortify}}
  span(v-else)
    span.tag not available
</template>

<script>
export default {
  name: 'EmployeesTagList',
  props: {
    employees: {type: Array, default: undefined},
    centered: {type: Boolean},
    size: {type: String, default: ''}
  }
}
</script>

<style scoped>
.centered {
  justify-content: center;
}
</style>
