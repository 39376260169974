module.exports = {
  ART_DIRECTOR: 'art_director',
  TOP_MASTER: 'top_master',
  MASTER: 'master',
  SENIOR: 'senior',
  JUNIOR: 'junior',
  APPRENTICE_3Y: 'apprentice_3y',
  APPRENTICE_2Y: 'apprentice_2y',
  APPRENTICE_1Y: 'apprentice_1y',
  APPRENTICE: 'apprentice'
}
