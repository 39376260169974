const ALL_TYPES = ['cashback', 'points_multiplier', 'discount', 'service', 'product', 'points', 'combo', 'off']

const TYPES = {
  CASHBACK: 'cashback',
  POINTS_MULTIPLIER: 'points_multiplier',
  DISCOUNT: 'discount',
  SERVICE: 'service',
  PRODUCT: 'product',
  POINTS: 'points',
  SERVICES_COMBO: 'combo',
  OFF: 'off'
}

module.exports = {
  TYPES,
  ALL_TYPES
}
