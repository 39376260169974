<template lang="pug">
.modal-card
  header.modal-card-head
    p.modal-card-title(data-qa="modal-header") 
      b(v-if="!resourceId" v-t="'resources_settings.new_resource_modal_header'")
      b(v-else v-t="'resources_settings.edit_resource_modal_header'")
    div
      a.button.is-white.is-small(@click="$emit('close')")
        span.icon
          i.mdi.mdi-close.mdi-24px
  section.modal-card-body.has-lightgrey-background
    .columns.is-centered
      .column.is-8-desktop
        .box
          h3.title.is-4 {{$t('resources_settings.details')}}
          b-field(:label="$t('resources_settings.name_label')" :type="errors.has('name') ? 'is-danger': ''" :message="errors.has('name') ? errors.first('name') : ''" )
            b-input(v-model="name" name='name' v-validate="'required'" :placeholder="$t('resources_settings.name_placeholder')")
          b-field(:label="$t('resources_settings.location')" )
            location-select(v-model="location")
          b-field(:label="$t('resources_settings.picture_label')")
            .is-artwork
              image-preview(v-model="picture" :max-size="800" category="assets")
        .box 
          h3.title.is-4 {{$t('resources_settings.services')}}          
          label.label {{ $t('resources_settings.services_subtitle') }}
          ServicesSelector(:id="resourceId" type="resources" @input="onServicesSelect")
  footer.modal-card-foot
    .buttons
      action-button(title="actions.save" @click="submit" :loading="submitting" data-qa="action-confirm" permission="RESOURCES_MANAGE")
      button.button(@click="$emit('close')" v-t="'actions.cancel'")  
</template>

<script>
import {mapActions, mapGetters} from 'vuex'
import ServicesSelector from '../services/ServicesSelector'
import cloneDeep from 'lodash/cloneDeep'
export default {
  name: 'ResourceCreateEditModal',
  components: {
    ServicesSelector
  },
  props: {
    resourceId: {type: String, default: undefined}
  },
  data() {
    return {
      submitting: false,
      name: undefined,
      location: undefined,
      picture: undefined,
      schedule: {}
    }
  },
  computed: {
    ...mapGetters('resources', ['resources'])
  },
  async created() {
    if (this.resourceId) {
      let resource = await this.getById(this.resourceId)

      this.name = resource.name
      this.location = resource.location
      this.picture = resource.picture
      this.schedule = cloneDeep(resource.schedule)
    }
  },
  methods: {
    ...mapActions('resources', ['getById', 'createResource', 'updateResource']),
    ...mapActions('services', ['updateResourceServices']),
    async submit() {
      const valid = await this.$validator.validateAll()
      if (this.submitting || !valid) {
        return
      }
      let existing = this.resources.find(
        r => r._id !== this.resourceId && r.location === this.location && r.name === this.name.trim()
      )
      if (existing) {
        this.$buefy.toast.open({
          message: this.$t('resources_settings.duplicate_name_error'),
          type: 'is-danger',
          queue: false
        })
        return
      }
      this.submitting = true
      const data = {name: this.name, location: this.location, picture: this.picture, schedule: this.schedule}
      try {
        let resource
        if (this.resourceId) {
          resource = await this.updateResource({
            id: this.resourceId,
            data
          })
        } else {
          resource = await this.createResource(data)
        }
        await this.updateResourceServices({
          resourceId: resource._id,
          services: this.selectedServices || []
        })

        this.$buefy.toast.open({message: this.$t('status.save_success'), type: 'is-success', queue: false})
        this.$emit('close')
      } catch (error) {
        this.$handleAPIError(error)
      }
      this.submitting = false
    },
    cancel() {
      this.$emit('close')
    },
    onServicesSelect(services) {
      this.selectedServices = services
    }
  }
}
</script>
