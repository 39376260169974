import Vue from 'vue'

export default {
  getTaxes() {
    return Vue.http.get('tax').then(res => res.body)
  },
  updateTax(id, data) {
    return Vue.http.put(`tax/${id}`, data).then(res => res.body)
  },
  createTax(data) {
    return Vue.http.post('tax', data).then(res => res.body)
  },
  createTaxGroup(data) {
    return Vue.http.post('tax/group', data).then(res => res.body)
  },
  updateTaxCalculation(data) {
    return Vue.http.put('tax/calculation', data).then(res => res.body)
  },
  applyDefaults() {
    return Vue.http.put('tax/applyDefaults').then(res => res.body)
  }
}
