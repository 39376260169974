<template lang="pug">
button.button(@click="addProduct") 
  span.icon
    i.mdi.mdi-tag-plus-outline
  span(v-t="'sale.actions.add_products'")
</template>
<script>
import {mapActions} from 'vuex'
import ProductSelectModal from './ProductSelectModal.vue'
export default {
  name: 'ProductSelector',
  created() {
    this.$barcodeScanner.init(this.onBarcodeScanned)
  },
  destroyed() {
    this.$barcodeScanner.destroy()
  },
  methods: {
    ...mapActions('inventory', ['fetchProductByBarcode']),
    async onBarcodeScanned(barcode) {
      let product = await this.fetchProductByBarcode(barcode)
      if (!product) {
        this.$buefy.toast.open({message: this.$t('sale.barcode_not_found'), type: 'is-warning', queue: false})
        return
      }
      this.onProductSelect(product)
    },
    addProduct() {
      return this.$buefy.modal.open({
        parent: this,
        fullScreen: this.isMobile(),
        hasModalCard: true,
        component: ProductSelectModal,
        width: 960,
        canCancel: ['escape'],
        events: {
          input: this.onProductSelect
        }
      })
    },
    onProductSelect(product) {
      this.$emit('input', product)
    }
  }
}
</script>
