<template lang="pug">
span {{formattedValue}}
</template>

<script>
import saleHelper from '../../../shared/saleHelper'
export default {
  props: {
    value: {type: Number, required: true},
    currencyCode: {type: String, required: true}
  },
  computed: {
    formattedValue() {
      return saleHelper.formatCurrency(this.value / 100, this.currencyCode)
    }
  }
}
</script>
