import Vue from 'vue'

export default {
  list(params) {
    return Vue.http.get('services', {params}).then(res => res.body)
  },
  listCategories() {
    return Vue.http.get('services/categories').then(res => res.body)
  },
  createCategory(data) {
    return Vue.http.post('services/categories', data)
  },
  updateCategory(categoryId, data) {
    return Vue.http.put(`services/categories/${categoryId}`, data)
  },
  removeCategory(categoryId, data) {
    return Vue.http.delete(`services/categories/${categoryId}`, data)
  },
  getCategoryById(categoryId) {
    return Vue.http.get(`services/categories/${categoryId}`)
  },
  update(serviceId, data) {
    return Vue.http.put(`services/${serviceId}`, data)
  },
  create(data) {
    return Vue.http.post('services', data)
  },
  getById(serviceId) {
    return Vue.http.get(`services/${serviceId}`)
  },
  remove(serviceId) {
    this._listPromise = undefined
    return Vue.http.delete(`services/${serviceId}`)
  },
  updateCategoriesOrder(data) {
    return Vue.http.put(`services/categories/order`, data)
  },
  updateServicesOrder(categoryId, data) {
    return Vue.http.put(`services/categories/${categoryId}/order`, data)
  },
  updateStaffServices(data) {
    return Vue.http.put(`services/staff`, data)
  },
  updateResourceServices(data) {
    return Vue.http.put(`services/resource`, data)
  },
  serviceBulkUpdate(data) {
    return Vue.http.put(`services/bulk`, data)
  }
}
