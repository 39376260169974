<template lang="pug">
b-tabs
  b-tab-item(v-for="lng in company.languages" :key="lng" :label="$t(`languages.${lng}`)")
    b-input(:type="type" v-model="data[lng]" :placeholder="placeholder" @input="onChange")
</template>
<script>
import cloneDeep from 'lodash/cloneDeep'
import {mapState} from 'vuex'
export default {
  name: 'LocalizedEditor',
  props: {
    value: {type: Object, required: true},
    placeholder: {type: String, default: undefined},
    type: {type: String, default: undefined}
  },
  data() {
    return {
      data: {},
      activeTab: 0
    }
  },
  computed: {
    ...mapState('company', ['company'])
  },
  watch: {
    value() {
      this.data = cloneDeep(this.value)
    }
  },
  created() {
    this.data = cloneDeep(this.value)
  },
  methods: {
    onChange() {
      this.$emit('input', this.data)
    }
  }
}
</script>
