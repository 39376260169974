<template lang="pug">
  .details-base(v-if="ticket")
    .ticket-actions-bar.has-border-bottom
      div
        customer-tiny-preview(:value="ticket.customer" size="small" :link="true")
      .buttons(v-if="$rfHasPermission('CUSTOMER_CARE_MANAGE_TICKETS')")
        button.button.is-white(@click="togglePriority")
          span.icon.has-text-warning(v-if="ticket.priority")
            i.mdi.mdi-star.mdi-24px
          span.icon(v-else)
            i.mdi.mdi-star-outline.mdi-24px
        button.button.is-white(@click="resolve", v-if="ticket.status !== 'done'") 
          span.icon
            i.mdi.mdi-check.mdi-24px
          span Resolve
        button.button.is-white(@click="reopen", v-else) 
          span.icon
            i.mdi.mdi-inbox.mdi-24px
          span Reopen
    scroll-bar.is-height-100.section 
      hr
      nav.level
        .level-item.has-text-centered
          div
            p.heading Feedback
            p.title.is-5.feedback(:class="'feedback-' + ticket.survey.value") {{ticket.survey.value}} 
        .level-item.has-text-centered
          div
            p.heading Reason
            p.title.is-5
              FeedbackReason(:reasons="ticket.reasons")
        .level-item.has-text-centered
          div
            p.heading Date
            p.title.is-6 {{ticket.created | fulldate}} 

        .level-item.has-text-centered
          div
            p.heading Staff
            p.title.is-5 
              EmployeesTagList(:employees="ticket.employees")

      hr
      h4.title.is-4.has-text-centered Activity
      .columns
        .column.is-10.is-offset-1
          TicketActivity(:ticketId='ticket._id')
</template>

<script>
import {mapActions} from 'vuex'

import TicketActivity from './TicketActivity'
import EmployeesTagList from '../shared/EmployeesTagList'
import FeedbackReason from '../shared/FeedbackReason'

export default {
  name: 'TicketPreview',
  components: {
    TicketActivity,
    EmployeesTagList,
    FeedbackReason
  },
  props: {
    ticketId: {type: String, required: true}
  },
  data() {
    return {
      ticket: undefined,
      loading: false
    }
  },
  watch: {
    ticketId() {
      return this.loadTicket()
    }
  },
  created() {
    return this.loadTicket()
  },
  methods: {
    ...mapActions('tickets', ['fetchTicket', 'resolveTicket', 'reopenTicket', 'setTicketPriority']),
    async loadTicket() {
      this.loading = true
      this.ticket = await this.fetchTicket(this.ticketId)
      this.loading = false
    },
    async resolve() {
      try {
        await this.resolveTicket(this.ticket._id)
      } catch (err) {
        this.$handleAPIError(err)
      }
    },
    async togglePriority() {
      try {
        this.setTicketPriority({id: this.ticket._id, value: !this.ticket.priority})
      } catch (err) {
        this.$handleAPIError(err)
      }
    },
    async reopen() {
      try {
        await this.reopenTicket(this.ticket._id)
      } catch (err) {
        this.$handleAPIError(err)
      }
    }
  }
}
</script>
<style scoped>
.details-base {
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  padding: 0;
}
.ticket-actions-bar {
  background: #ffffff;
  padding: 1rem;
  justify-content: space-between;
  flex-direction: row;
  display: flex;
  align-items: center;
}
</style>
