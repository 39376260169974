import Vue from 'vue'

export default {
  getCredits() {
    return Vue.http.get('sms/credits').then(res => res.body)
  },
  sendMessage({customerId, text}) {
    return Vue.http.post(`sms/${customerId}`, {text}).then(res => res.body)
  },
  getCustomerMessages(customerId) {
    return Vue.http.get(`sms/${customerId}`).then(res => res.body)
  },
  getSMSBundles(params) {
    return Vue.http.get(`sms/bundles`, {params}).then(res => res.body)
  },
  getTemplates({key, lngs}) {
    return Vue.http.get(`sms/templates/${key}`, {params: {lngs}}).then(res => res.body)
  },
  updateTemplate({key, templates}) {
    return Vue.http.post(`sms/templates/${key}`, {templates}).then(res => res.body)
  }
}
