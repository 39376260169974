<template lang="pug">
.columns(:class="disabled ? 'is-disabled': ''")
  .column.is-flex(v-for="item in data" :key="item.value")
    .select-box.p-3.is-flex(@click="toggleSelect(item)" :class="[selected.includes(item.value) ? 'is-selected': '', (item.permission && !$rfSubscribed(item.permission)) || item.disabled ? 'is-disabled': '']" )
      div.pr-3
        span.icon
          i.mdi.mdi-24px(:class="selected.includes(item.value) ? 'mdi-check-circle': 'mdi-circle-outline'")
      div 
        p.title.is-6(v-html="item.title")
        p.subtitle.is-7 {{item.description}}
</template>
<script>
export default {
  name: 'SelectBox',
  props: {
    value: {type: [Object, Boolean, String, Array, Number], default: undefined},
    data: {type: [Array], required: true},
    multichoice: {type: Boolean, default: false},
    disabled: {type: Boolean, default: false}
  },
  data() {
    return {
      selected: []
    }
  },
  watch: {
    value(newVal) {
      if (!this.multichoice) {
        this.selected = [newVal]
      } else {
        this.selected = [...newVal]
      }
    }
  },
  created() {
    if (this.value !== undefined) {
      if (!this.multichoice) {
        this.selected = [this.value]
      } else {
        this.selected = [...this.value]
      }
    }
  },
  methods: {
    toggleSelect(item) {
      if (this.disabled || item.disabled || (item.permission && !this.$rfSubscribed(item.permission))) {
        return
      }
      if (this.multichoice) {
        if (this.selected.includes(item.value)) {
          this.selected.splice(this.selected.indexOf(item.value), 1)
        } else {
          this.selected.push(item.value)
        }
        this.$emit('input', this.selected)
      } else {
        this.selected = [item.value]
        this.$emit('input', item.value)
      }
    }
  }
}
</script>
<style scoped>
.select-box {
  border-radius: 6px;
  border: 2px solid #d1d1d1;
  cursor: pointer;
  align-items: center;
  transition: all ease-in-out 200ms;
  user-select: none;
  flex-grow: 1;
}

.is-disabled .select-box,
.select-box.is-disabled {
  cursor: initial;
}

.select-box .icon {
  color: #999999;
  transition: all ease-in-out 200ms;
}
.select-box .title,
.select-box .subtitle {
  color: #000000;
  transition: all ease-in-out 200ms;
}

.select-box.is-selected {
  border: 2px solid #625bfe;
}
.select-box.is-selected .icon {
  color: #625bfe;
}
.select-box.is-selected .title,
.select-box.is-selected .subtitle {
  color: #000000;
}
.is-disabled {
  opacity: 0.7;
}
</style>
