<template lang="pug">
  .modal-card
    header.modal-card-head
      p.modal-card-title 
        b {{$t('staff.shift_edit.title')}}
      
    section.modal-card-body
      

      
      //b-field(:label="$t('staff.shift_edit.type_label')")
        //select-box(v-model="type" :data="shiftTypes")
        //- b-radio-button(style="width: 40%" v-model="type" native-value="regular" size="is-medium" expanded type="is-success") 
        //-   p(style="font-size: 0.8em" v-html="$t('staff.shift_edit.type.recurring', {day: formattedDay})")  
          
        //- b-radio-button(style="width: 40%" v-model="type" native-value="specific" size="is-medium" expanded type="is-warning") 
        //-   p(style="font-size: 0.8em" v-html="$t('staff.shift_edit.type.specific', {day: formattedDay})")
      
      .notification.p-5
        form(v-on:submit.prevent="submit")
          //b-field(:label="$t('staff.shift_edit.location')" v-if="hasMultipleLocations")
          p.is-size-5.mb-4(v-html="$t('staff.shift_edit.recurring_title', {weekday: formattedDay})") 
          b-field
            b-radio(v-model="workDay" :native-value="true" @input="onWorkDayUpdate") {{$t('staff.shift_edit.working_day')}}
            b-radio(v-model="workDay" :native-value="false" @input="onWorkDayUpdate") {{$t('staff.shift_edit.non_working_day')}}
          template(v-if="workDay")
            location-select(v-model="location")
            label.label {{$t('staff.shift_edit.shifts_label')}}
            ShiftEditor(v-model="shifts")
      .notification.p-5
        template(v-if="customShifts")
          p.is-size-5.mb-4(v-html="$t('staff.shift_edit.specific_title', {day: formattedDate})")
          
          b-field
            b-radio(v-model="customWorkDay" :native-value="true") {{$t('staff.shift_edit.working_day')}}
            b-radio(v-model="customWorkDay" :native-value="false") {{$t('staff.shift_edit.non_working_day')}}
          
          template(v-if="customWorkDay")
            location-select(v-model="customLocation")
            label.label {{$t('staff.shift_edit.shifts_label')}}
            ShiftEditor(v-model="customShifts")
          hr.is-narrow
          .buttons
            button.button.is-outlined.is-danger.is-rounded(@click="removeCustomShifts") 
              span.icon
                i.mdi.mdi-calendar-alert
              span {{ $t('staff.shift_edit.actions.remove_exception') }}            
        template(v-else)
          p.is-size-5.mb-4(v-html="$t('staff.shift_edit.no_specific_title', {day: formattedDate})")
          
          .buttons
            button.button.is-outlined.is-success.is-rounded(@click="setupCustomShifts") 
              span.icon
                i.mdi.mdi-calendar-alert
              span {{ $t('staff.shift_edit.actions.create_exception') }}
        
    footer.modal-card-foot
      .buttons
        action-button(title="actions.save"  @click="submit") 
        button.button(type="button" @click="cancel" v-t="'actions.close'")
</template>

<script>
import dayjs from 'dayjs'
import {mapGetters, mapState} from 'vuex'

import ShiftEditor from '../shared/ShiftEditor'
export default {
  name: 'EditStaffShift',
  components: {
    ShiftEditor
  },
  props: {
    value: {type: Object, required: true},
    // shifts: {type: Array, required: true},
    // location: {type: String, default: undefined},
    // customShifts: {type: Array, default: undefined},
    // customLocation: {type: String, default: undefined},
    suggestedStart: {type: Number, default: 9 * 60},
    suggestedEnd: {type: Number, default: 18 * 60},
    weekday: {type: String, required: true},
    date: {type: Date, required: true},
    staffId: {type: String, required: true}
  },
  data() {
    return {
      shifts: [],
      workDay: true,
      customWorkDay: true,
      location: undefined,
      customShifts: undefined,
      customLocation: undefined,

      custom: false
    }
  },
  computed: {
    ...mapGetters('location', ['hasMultipleLocations']),
    ...mapState('staff', ['staffMap']),
    formattedDay() {
      return dayjs(this.date).format('dddd')
    },
    formattedDate() {
      return dayjs(this.date).format('dddd, D MMMM')
    }
    // shiftTypes() {
    //   return [
    //     {
    //       title: this.$t('staff.shift_edit.type.recurring', {day: this.formattedDay}),
    //       value: 'regular',
    //       description: this.$t('staff.shift_edit.type.recurring_desc', {day: this.formattedDay})
    //     },
    //     {
    //       title: this.$t('staff.shift_edit.type.specific', {day: this.formattedDay}),
    //       value: 'specific',
    //       description: this.$t('staff.shift_edit.type.specific_desc', {day: this.formattedDay})
    //     }
    //   ]
    // }
  },
  // watch: {
  //   value(newVal) {
  //     if (newVal) {
  //       this.shifts = newVal
  //     }
  //   }
  // },
  created() {
    // if (this.value.length) {
    //   this.shifts = this.value.map(shift => {
    //     return {start: shift.start, end: shift.end}
    //   })
    // } else {
    //   this.addShift()
    // }
    this.init()
  },
  methods: {
    init() {
      this.shifts = this.value.shifts.map(shift => {
        return {start: shift.start, end: shift.end}
      })
      this.workDay = !!this.shifts.length

      this.customShifts =
        this.value.customShifts &&
        this.value.customShifts.map(shift => {
          return {start: shift.start, end: shift.end}
        })
      this.customWorkDay = !!(this.customShifts && this.customShifts.length)
      this.location = this.value.location || this.staffMap[this.staffId].location
      this.customLocation = this.value.customLocation
    },
    setupCustomShifts() {
      this.customShifts = [{start: this.suggestedStart, end: this.suggestedEnd}]
      this.customWorkDay = true
    },
    removeCustomShifts() {
      this.customShifts = undefined
    },
    async submit() {
      this.$emit('input', {
        shifts: this.workDay ? this.shifts : [],
        location: this.location,
        customShifts: this.customShifts ? (this.customWorkDay ? this.customShifts : []) : undefined,
        customLocation: this.customShifts ? this.customLocation : undefined
      })
      this.$emit('close')
    },
    cancel() {
      this.$emit('close')
    },
    onWorkDayUpdate() {
      if (this.workDay && !this.shifts.length) {
        this.shifts.push({start: this.suggestedStart, end: this.suggestedEnd})
      }
    }
  }
}
</script>
