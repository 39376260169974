import Vue from 'vue'

export default {
  setTaskComplete(taskId) {
    return Vue.http.put('completeness', {task: taskId})
  },
  getCompletenessTasks() {
    return Vue.http.get('completeness')
  }
}
