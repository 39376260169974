<template lang="pug">
.modal-card
  header.modal-card-head.is-hidden-print
    p.modal-card-title(data-qa="modal-header") 
      b(v-t="'sale.view_sale_title'")
    div
      a.button.is-white.is-small(@click="onClose")
        span.icon
          i.mdi.mdi-close.mdi-24px
  section.modal-card-body.is-height-100.p-0
    .columns.is-height-100.is-marginless(v-if="sale")
      .column.is-9.is-height-100.right-panel.is-fullwidth-print
        .columns.is-centered
          .column.is-5.is-fullwidth-print   
            Invoice(:saleId="saleId" @voided="onPaymentVoid" @close="$emit('close')" :autoprint="autoprint") 
            .notification.mt-3.is-hidden-print(v-if="sale.surveys && sale.surveys.length")
              p.is-size-5 {{$t('sale.surveys_title')}}
              .list-item(v-for="survey in sale.surveys") {{$t(`surveys.${survey.question}`)}}  
                |  
                survey-result-view.is-pulled-right(:type="survey.question" :surveys="sale.surveys" empty="n/a")
      scroll-bar.column.is-3.is-height-100.has-white-background.has-border-left.is-hidden-mobile.is-paddingless.is-flex.is-hidden-print
        div.is-flex.is-flex-direction-column(style="width: 100%" v-if="customer")
          .p-5
            customer-tiny-preview(:value="customer" :closable="false" :link="true")
          .px-5.pb-5
            Stats(:customer="customer" :blocks="['sales', 'rewards', 'loyalty', 'surveys', 'notes']" )
        div(v-else style="width: 100%") 
          .p-5
            customer-tiny-preview(:value="customer" :closable="false" )
      
</template>

<script>
import {mapActions} from 'vuex'
import SaleItem from './SaleItem'
import Invoice from './Invoice'
import Stats from '../customers/Stats'
import Checkout from './Checkout.vue'
export default {
  name: 'SaleDetails',
  components: {
    SaleItem,
    Invoice,
    Stats
  },
  props: {
    saleId: {type: String, required: true},
    autoprint: {type: Boolean, default: false},
    showRebookPrompt: {type: Boolean, default: false}
  },
  data() {
    return {
      loading: false,
      sale: undefined,
      customer: undefined
    }
  },
  async created() {
    this.sale = await this.fetchSaleById({id: this.saleId})

    if (this.sale.customer) {
      this.customer = await this.fetchCustomer(this.sale.customer)
    }
  },

  methods: {
    ...mapActions('sales', ['fetchSaleById']),
    ...mapActions('customers', ['fetchCustomer']),
    onPaymentVoid() {
      if (this.sale.appointment) {
        this.$buefy.modal.open({
          parent: this.$parent,
          props: {
            saleId: this.sale._id
          },
          component: Checkout,
          hasModalCard: true,
          fullScreen: true,
          canCancel: ['outside']
        })
      }

      this.$emit('close')
    },
    onClose() {
      if (
        this.sale &&
        this.sale.appointment &&
        this.customer &&
        this.showRebookPrompt &&
        this.$rfCRMSettings().rebooking
      ) {
        this.$buefy.dialog.confirm({
          message: this.$t('appointment.rebook_prompt.message'),
          title: this.$t('appointment.rebook_prompt.title'),
          type: 'is-black',
          confirmText: this.$t('appointment.actions.rebook'),
          cancelText: this.$t('actions.close'),
          onConfirm: () => {
            this.$router.push({
              name: 'Calendar',
              query: {customer: this.customer._id, rebooking: this.sale.appointment}
            })
            /// TODO implement customer based date prediction using nextVisitDue and last appointment date
          }
        })
      }
      this.$emit('close')
    }
  }
}
</script>
