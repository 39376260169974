import Vue from 'vue'

export default {
  list(lng) {
    return Vue.http.get('followups', {params: {lng}})
  },
  getById(followupId) {
    return Vue.http.get(`followups/${followupId}`)
  },
  updateOrCreate(followupId, data) {
    if (followupId) {
      return Vue.http.put(`followups/${followupId}`, data)
    } else {
      return Vue.http.post('followups', data)
    }
  },
  remove(followupId) {
    return Vue.http.delete(`followups/${followupId}`)
  },
  sendTest(followupId) {
    return Vue.http.post(`followups/${followupId}/test`)
  }
}
