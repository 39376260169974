<template lang="pug">
  b-autocomplete(v-model="query" :data="customers" :expanded="expanded" :open-on-focus="true" :placeholder="placeholder" icon="magnify" field="fullName" :loading="isFetching" @keyup.native="searchCustomer" @select="selectCustomer" ref="customerSearchInput" :size="size")
    template(slot-scope="props")
      customer-tiny-preview(:value="props.option")
    template(slot="empty")
      empty-content(v-if="isFetching" title="customer_select.searching" size="is-small")
      empty-content(v-else title="customers.search_no_results" size="is-small" icon="magnify-remove-outline")

    template(#header v-if="addNew")
      .has-text-left.py-3(style="border-bottom: 1px solid #ccc")
        a.is-size-6(@click="addNewCustomer")
          span.icon
            i.mdi.mdi-account-plus 
          span {{addNewTitle}}
</template>

<script>
import CustomersApi from '@/api/customers'
import debounce from 'lodash/debounce'
import CustomerCreateEditForm from '../customers/CustomerCreateEditForm'
import {mapGetters} from 'vuex'

export default {
  name: 'CustomerSearch',
  props: {
    value: {type: Object, default: undefined},
    size: {type: String, default: undefined},
    category: {type: String, default: undefined},
    placeholder: {type: String, default: 'e.g. Joanna Perkins'},
    expanded: {type: Boolean, default: undefined},
    addNew: {type: Boolean, default: false},
    addNewTitle: {type: String, default: 'Add new ...'}
  },
  data() {
    return {
      customer: null,
      isFetching: false,
      customers: [],
      query: undefined
    }
  },
  computed: {
    ...mapGetters('customers', ['customerById'])
  },
  watch: {
    value(val) {
      this.customer = val
      this.query = val && [val.firstName, val.middleName, val.lastName].filter(c => c).join(' ')
    }
  },
  created() {
    this.searchCustomer()
  },
  methods: {
    searchCustomer: debounce(async function() {
      // if (!this.query.length) {
      //   this.customers = []
      //   return
      // }
      this.isFetching = true
      try {
        const {body} = await CustomersApi.search({query: this.query, category: this.category})
        body.data.forEach(c => {
          c.fullName = [c.firstName, c.middleName, c.lastName].filter(c => c).join(' ')
        })
        this.customers = body.data
      } catch (error) {
        this.customers = []
        this.$handleAPIError(error)
      }

      this.isFetching = false
    }, 300),
    selectCustomer(option) {
      this.$store.commit('customers/SET_CUSTOMER', option)
      this.customer = this.customerById(option._id)
      this.$emit('input', this.customer)
    },
    focus() {
      this.$refs.customerSearchInput.focus()
    },
    addNewCustomer() {
      this.$buefy.modal.open({
        parent: this,
        component: CustomerCreateEditForm,
        hasModalCard: true,
        canCancel: ['escape'],
        fullScreen: true,
        props: {
          oneOnly: true
        },
        events: {
          success: this.selectCustomer
        }
      })
    }
  }
}
</script>
