<template lang="pug">
div(style="width: 100%")
  table.table.is-fullwidth.has-transparent-background
    tbody
      tr
        td.is-size-5(v-t="'sale.subtotal_price'")
        td.has-text-right.is-size-5
          currency-view(:value="totals.subtotal")
      tr(v-if="totals.discount")
        td.is-size-5
          p(v-t="'sale.discount_label'")
          p.is-size-6.has-text-grey(v-if="totals.discounts.items")  {{ $t('sale.discount_type.items_discount') }}
          p.is-size-6.has-text-grey(v-if="totals.discounts.itemsCoupon")  {{ $t('sale.discount_type.items_coupon') }}
          p.is-size-6.has-text-grey(v-if="totals.discounts.points") {{ $t('sale.discount_type.points') }}
          p.is-size-6.has-text-grey(v-if="totals.discounts.membership") {{ $t('sale.discount_type.membership') }}
          p.is-size-6.has-text-grey(v-if="totals.discounts.voucher") {{ $t('sale.discount_type.voucher') }}
          p.is-size-6.has-text-grey(v-if="totals.discounts.coupon") {{ $t('sale.discount_type.coupon') }}
        td.has-text-right.is-size-5 
          currency-view(:value="totals.discount")
          p.is-size-6.has-text-grey(v-if="totals.discounts.items") 
            currency-view(:value="totals.discounts.items")
          p.is-size-6.has-text-grey(v-if="totals.discounts.itemsCoupon") 
            currency-view(:value="totals.discounts.itemsCoupon")
          p.is-size-6.has-text-grey(v-if="totals.discounts.points") {{rewardsPayment.redeem}} 
            span  / 
            currency-view(:value="totals.discounts.points")
          p.is-size-6.has-text-grey(v-if="totals.discounts.coupon")
            currency-view(:value="totals.discounts.coupon")
      tr
        td.is-size-5
          span(v-t="'sale.total_tax'")
          p.is-size-7.has-text-grey(v-for="(tax, idx) in totals.taxes")
              tax-view(:value="tax.tax")
              |  
              currency-view(:value="tax.value")
        td.has-text-right.is-size-5
          currency-view(:value="totals.tax")  
      tr
        td 
          strong.is-size-5(v-t="'sale.total_price'")
        td.has-text-right
          strong.is-size-5
            currency-view(:value="totals.total" data-qa="total-price")
      tr.is-relative.is-borderless(v-if="customerCoupon")
        td
          span.is-size-5.has-text-primary(v-t="'sale.coupon_label'")
        td.has-text-right.is-size-5
          a(@click="removeCoupon()")
            span.icon.has-text-danger
              i.mdi.mdi-close-circle-outline
          customer-coupon-view(:value="customerCoupon" :detailed="false")
      tr.is-relative.is-borderless(v-for="payment in payments")
        td
          payment-type-view.is-size-5.has-text-primary(:value="payment.type")
          template(v-if="payment.voucher")
            br
            small 
              voucher-name-view(:value="payment.voucher" code) 
          template(v-if="payment.payment") 
            br
            small
              payment-view(:value="payment.payment")

        td.has-text-right
          span.is-size-5
            a(@click="removePayment(payment)" v-if="payment.type !== 'deposit'")
              span.icon.has-text-danger
                i.mdi.mdi-close-circle-outline
            currency-view(:value="payment.paid")
      
      tr
        td(colspan="2")
          a.has-text-underline(@click="addEditNote" v-if="!notes") 
            span.icon
              i.mdi.mdi-note-edit-outline
            span(v-t="'sale.actions.add_payment_note'")     
          a(@click="addEditNote" v-else) 
            em {{notes}} 
    tfoot.is-borderless
      tr
        td 
          strong.is-size-5.has-text-primary(v-t="'sale.due_payment'")
        td.has-text-right
          strong.is-size-5.has-text-primary
            currency-view(:value="totals.due" data-qa="total-price")
  
  div(v-show="totals.due")
    b-field(:type="errors.has('payment') ? 'is-danger': 'is-primary'" :message="errors.has('payment') ? errors.first('payment') : ''")
      b-input(v-model.number="paymentAmount" ref="paymentInput" size="is-large" type="number" expanded data-vv-as="payment" name="payment" numeric v-validate="'required|decimal:2|min_value:0'" :use-html5-validation="false")
    
    
    
    .columns.is-multiline.is-mobile
      .column.is-6(v-for="paymentType in paymentTypes" :key="paymentType")
        button.button.payment-button.is-dark.is-medium.is-fullwidth(@click="pay(paymentType)" :data-qa="`action-${paymentType}`") 
          b
            payment-type-view(:value="paymentType")
      .column.is-6(v-if="$rfSubscribed('payments')")
        button.button.payment-button.is-primary.is-medium.is-fullwidth(@click="linkPay" data-qa="action-link-pay" )
          b(v-t="'payment_types.link_pay'")
      .column.is-6
        button.button.payment-button.is-warning.is-medium.is-fullwidth(@click="selectVoucher" data-qa="action-select-voucher")
          b(v-t="'payment_types.voucher'")      
      .column.is-6(v-if="customer")
        button.button.payment-button.is-warning.is-medium.is-fullwidth(@click="selectCoupon" data-qa="action-select-coupon")
          b(v-t="'payment_types.coupon'")
      .column.is-6(v-if="customer")
        button.button.payment-button.is-warning.is-medium.is-fullwidth(@click="payWithRewards" :disabled="availableForRedeem === 0") 
          div(style="line-height: 1")
            b {{$t('payment_types.points')}}
            br
            small.is-size-6(v-if="!hasLessThanMinimum") 
              rewards-view(:value="customer.balance")
              span  ≈  
              b 
                currency-view(:value="availableForRedeem")
            small.is-size-6(v-else) < redeem min
      .column.is-6(v-for="paymentType in company.customPaymentTypes" :key="paymentType._id")
        button.button.payment-button.is-info.is-medium.is-fullwidth(@click="pay(paymentType.name)" :data-qa="`action-${paymentType.name}`") 
          b {{paymentType.name}}
  .has-text-centered.mt-5(v-if="totals.due === totals.total && !payments.length && saleItems.length")
    a.button.is-rounded.is-black.is-small.is-outlined(@click="finalizeUnpaid") {{ $t('sale.actions.finalize_unpaid') }}
  transition(name="slide-fade")
    .has-text-centered(v-show="totals.due === 0 && saleItems.length")
      .mb-6(v-if="!isMobile()")
        span.icon.has-text-success.payment-check
          i.mdi.mdi-cart-check
      
      p.title.is-5.has-text-centered(v-t="'sale.payment_collected_title'")
      
      .mb-3.has-text-left(v-if="company.haysell && company.haysell.profileId")
        b-checkbox(v-model="printFiscal" type="is-dark") {{ $t('sale.print_fiscal') }}
      
      .is-flex.is-align-items-center.is-justify-content-space-between.mb-4(v-if="customer && $rfSubscribed('rewards') && $rfEnabled('rewards')")
        div
          b-checkbox(v-model="collectRewards" type="is-success" :disabled="!totals.rewardsBase") {{ $t('sale.collect_rewards') }}
        div
          ContentEditable.has-text-weight-semibold.is-size-5(v-model="rewardsBase" :disabled="!totals.rewardsBase" v-validate="rewardsBaseRules" name="rewardsBase" placeholder="rewards base" :formatter="currencyFormatter" :class="errors.has('rewardsBase') ? 'has-text-danger': ''")
      
      .field
        action-button.is-fullwidth(title="sale.actions.complete" @click="complete" cash-register :loading="checkingOut" icon="check" size="is-medium"  type="is-success"  permission="SALE_CREATE" data-qa="action-complete")     
</template>

<script>
import {mapState} from 'vuex'
import PAYMENT_TYPES from '../../../constants/paymentTypes'
import VoucherRedeem from '../voucher/VoucherRedeem'
import SelectCoupon from './SelectCoupon'
import LinkPayModal from './LinkPayModal'
import ContentEditable from '../shared/ContentEditable'
import saleHelper from '../../../shared/saleHelper'
export default {
  name: 'PaymentCollect',
  components: {
    ContentEditable
  },

  props: {
    value: {type: Object, default: () => {}},
    totals: {type: Object, required: true},
    saleItems: {type: Array, default: () => []},
    customer: {type: Object, default: undefined}
  },
  data() {
    return {
      payments: [],
      paymentAmount: undefined,
      paymentTypes: [PAYMENT_TYPES.CASH, PAYMENT_TYPES.CARD],
      pointsPayment: 0,
      notes: undefined,
      customerCoupon: undefined,
      printFiscal: true,
      collectRewards: true,
      rewardsBase: 0
    }
  },
  computed: {
    ...mapState('company', ['company']),
    ...mapState('sales', ['checkingOut']),
    currencyFormatter() {
      return t => saleHelper.formatCurrency(t, this.company.currency)
    },
    availableForRedeem() {
      if (this.hasLessThanMinimum) {
        return 0
      }
      const existing = this.payments.find(p => p.type === PAYMENT_TYPES.POINTS)
      const consumed = (existing && existing.redeem) || 0
      return Math.floor((this.customer.balance - consumed || 0) / this.company.membership.currencyWorth)
    },
    hasLessThanMinimum() {
      return this.company.membership.redeemMin && this.company.membership.redeemMin > this.customer.balance
    },
    rewardsPayment() {
      return this.payments.find(p => p.type === PAYMENT_TYPES.POINTS)
    },
    rewardsBaseRules() {
      return `required|decimal:2|min_value:0|max_value:${this.totals.rewardsBase}`
    }
  },
  watch: {
    totals(newVal) {
      this.paymentAmount = newVal.due
      this.rewardsBase = newVal.rewardsBase
      //this.focus()
    },
    value() {
      this.payments = this.value && this.value.payments && this.value.payments.slice()
      this.customerCoupon = this.value && this.value.customerCoupon
      this.notes = this.value && this.value.notes
    }
  },
  async created() {
    this.payments = this.value && this.value.payments && this.value.payments.slice()
    this.customerCoupon = this.value && this.value.customerCoupon
    this.notes = this.value && this.value.notes

    this.paymentAmount = this.totals.due
    this.rewardsBase = this.totals.rewardsBase
    this.collectRewards = true
    this.printFiscal = !!(this.company.haysell && this.company.haysell.profileId)
  },
  mounted() {
    this.$refs.paymentInput.focus()
  },
  methods: {
    update() {
      this.$emit('input', {
        customerCoupon: this.customerCoupon,
        payments: this.payments,
        notes: this.notes
      })
    },
    finalizeUnpaid() {
      this.$buefy.dialog.confirm({
        title: this.$t('sale.finalize_unpaid_confirm.title'),
        message: this.$t('sale.finalize_unpaid_confirm.body'),
        type: 'is-black',
        confirmText: this.$t('misc.yes'),
        cancelText: this.$t('actions.cancel'),
        onConfirm: () => {
          this.complete()
        }
      })
    },
    pay(paymentType) {
      this.focus()
      if (!this.paymentAmount) {
        return
      }
      let existing = this.payments.find(p => p.type === paymentType)
      if (existing) {
        existing.paid = existing.paid + this.paymentAmount
      } else {
        this.payments.push({type: paymentType, paid: this.paymentAmount})
      }
      this.update()
    },
    linkPay() {
      if (!this.customer || (!this.customer.phone && !this.customer.email)) {
        this.$buefy.dialog.alert({
          title: this.$t('sale.customer_required.title'),
          message: this.$t('sale.customer_required.body'),
          type: 'is-danger',
          trapFocus: true
        })
        return
      }
      this.$buefy.modal.open({
        parent: this,
        component: LinkPayModal,
        hasModalCard: true,
        props: {customer: this.customer},
        canCancel: ['escape'],
        events: {
          input: means => {
            this.payWithLink(means)
          }
        }
      })
    },
    payWithRewards() {
      this.focus()
      if (!this.paymentAmount) {
        return
      }
      let due = this.totals.due
      if (!this.company.priceIncludesTax) {
        due = this.totals.dueBT
      }
      let payment = Math.min(due, this.availableForRedeem, this.paymentAmount)
      if (payment > 0) {
        let existing = this.payments.find(p => p.type === PAYMENT_TYPES.POINTS)
        if (existing) {
          existing.paid = existing.paid + payment
          existing.redeem = Math.ceil((existing.paid + payment) * this.company.membership.currencyWorth)
        } else {
          this.payments.push({
            type: PAYMENT_TYPES.POINTS,
            paid: payment,
            redeem: Math.ceil(payment * this.company.membership.currencyWorth)
          })
        }
        this.update()
      }
    },
    payWithCoupon(customerCoupon) {
      this.focus()
      this.customerCoupon = customerCoupon
      this.update()
    },
    payWithVoucher({voucher, redeem}) {
      this.focus()
      let vouchers = this.payments.filter(p => p.type === PAYMENT_TYPES.VOUCHER && p.voucher !== voucher._id)
      vouchers.push({
        type: PAYMENT_TYPES.VOUCHER,
        paid: redeem,
        voucher: voucher._id
      })
      this.payments = vouchers

      this.update()
    },
    payWithLink(means) {
      this.focus()
      if (!this.paymentAmount) {
        return
      }
      let existing = this.payments.find(p => p.type === PAYMENT_TYPES.LINK_PAY)
      if (existing) {
        existing.paid = existing.paid + this.paymentAmount
      } else {
        this.payments.push({type: PAYMENT_TYPES.LINK_PAY, paid: this.paymentAmount, means})
      }

      this.update()
    },
    removePayment(payment) {
      this.focus()
      this.payments.splice(this.payments.indexOf(payment), 1)
      this.update()
    },
    removeCoupon() {
      this.focus()
      this.customerCoupon = undefined
      this.update()
    },
    async complete() {
      const valid = await this.$validator.validateAll()
      if (!valid) {
        return
      }
      this.$emit('paid', {
        payments: this.payments,
        printFiscal: this.printFiscal || undefined,
        rewardsBase: this.collectRewards ? this.rewardsBase : 0
      })
    },
    selectVoucher() {
      this.$buefy.modal.open({
        parent: this,
        hasModalCard: true,
        props: {saleItems: this.saleItems, due: this.totals.due},
        component: VoucherRedeem,
        width: 960,
        canCancel: ['escape', 'outside'],
        events: {
          input: this.payWithVoucher
        }
      })
    },
    selectCoupon() {
      this.$buefy.modal.open({
        parent: this,
        props: {customerId: this.customer._id, saleItems: this.saleItems},
        component: SelectCoupon,
        width: 960,
        canCancel: ['escape', 'outside'],
        events: {
          input: this.payWithCoupon
        }
      })
    },
    focus() {
      this.$refs.paymentInput.focus()
    },
    addEditNote() {
      this.$buefy.dialog.prompt({
        message: this.$t('sale.payment_note_prompt.body'),
        inputAttrs: {
          type: 'text',
          placeholder: this.$t('sale.payment_note_prompt.placeholder'),
          value: this.notes
        },
        type: 'is-success',
        trapFocus: true,
        confirmText: this.$t('actions.submit'),
        cancelText: this.$t('actions.cancel'),
        onConfirm: value => {
          this.notes = value
          this.update()
        }
      })
    }
  }
}
</script>

<style scoped>
.payment-check {
  font-size: 8rem;
  height: 8rem;
}
.payment-button {
  line-height: 1.2;
  height: 4em;
}
</style>
<style>
.slide-fade-enter-active {
  transition: all 0.3s ease;
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
