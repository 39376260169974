<script>
import VueChart from 'vue-chartjs'
import ReportsApi from '@/api/reports'
import CHART_COLORS from '../../helpers/chartColors'
import saleHelper from '../../../../shared/saleHelper'
import {mapState} from 'vuex'
export default {
  name: 'SurveysPieChart',
  extends: VueChart.Pie,
  props: {
    period: {type: Object, default: undefined},
    survey: {type: String, required: true},
    type: {type: String, required: true},
    location: {type: String, default: undefined}
  },
  data() {
    return {
      loading: false
    }
  },
  computed: {
    ...mapState('company', ['company'])
  },
  watch: {
    period() {
      return this.fetchData()
    },
    location() {
      return this.fetchData()
    }
  },
  created() {
    if (this.period) {
      return this.fetchData()
    }
  },
  methods: {
    async fetchData() {
      this.loading = true
      try {
        let data
        switch (this.type) {
          case 'avgSale':
            data = await ReportsApi.getSurveysAvgSalesReport(this.survey, {
              from: this.period.from,
              to: this.period.to,
              location: this.location
            })
            break
          case 'spending':
            data = await ReportsApi.getSurveysSpendingReport(this.survey, {
              from: this.period.from,
              to: this.period.to,
              location: this.location
            })
            break
          case 'count':
          default:
            data = await ReportsApi.getSurveysReport(this.survey, {
              from: this.period.from,
              to: this.period.to,
              location: this.location
            })
        }
        this.$data._chart && this.$data._chart.destroy()
        this.loading = false
        this.renderChart(
          {
            labels: data.labels,
            datasets: [
              {
                data: data.data,
                backgroundColor: [
                  CHART_COLORS.RED,
                  CHART_COLORS.GREEN,
                  CHART_COLORS.YELLOW,
                  CHART_COLORS.BLUE,
                  CHART_COLORS.ORANGE
                ]
              }
            ]
          },
          {
            tooltips: {
              mode: 'nearest',
              intersect: false,
              callbacks: {
                label: (tooltipItem, data) => {
                  var label = data.labels[tooltipItem.index] || ''
                  let value = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index] || 0
                  if (label) {
                    label += ': '
                  }
                  if (this.type === 'avgSale' || this.type === 'spending') {
                    label += saleHelper.formatCurrency(value, this.company.currency)
                  } else {
                    label += value
                  }
                  return label
                }
              }
            },
            responsive: true,
            maintainAspectRatio: false
          }
        )
      } catch (error) {
        this.$handleAPIError(error)
      }
      this.loading = false
    }
  }
}
</script>
