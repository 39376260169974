import Vue from 'vue'
import './registerServiceWorker'
import App from './App'
import router from './router'
import store from './store'
import {i18n, loadLanguageAsync} from './i18n'
import './styles/bulma.scss'

import VueResource from 'vue-resource'
import VeeValidate from 'vee-validate'
import Buefy from 'buefy'
//import {VueReCaptcha} from 'vue-recaptcha-v3'

import VueBarcodeScanner from 'vue-barcode-scanner'
import FacebookApi from './api/facebook'
import VueTelInput from 'vue-tel-input'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import VueSocketIO from 'vue-socket.io-extended'
//import VueFbCustomerChat from 'vue-fb-customer-chat'
import ErrorHandler from './plugins/errorHandler'
import CompanySettings from './plugins/settings'
import Filters from './filters'
import SocketInstance from './sockets'
import './styles/main.css'
import './styles/common.css'
import Note from './components/shared/Note'
import EmptyContent from './components/shared/EmptyContent'
import RewardsView from './components/shared/RewardsView'
import PhoneView from './components/shared/PhoneView'
import CurrencyView from './components/shared/CurrencyView'
import ComparisonView from './components/shared/ComparisonView'
import PaymentTypeView from './components/shared/PaymentTypeView'
import PaymentView from './components/sale/PaymentView'
import InvoiceView from './components/sale/InvoiceView'

import TaxView from './components/taxes/TaxView'
import TaxMappingView from './components/taxes/TaxMappingView'

import Loading from './components/shared/Loading'
import PercentageView from './components/shared/PercentageView'
import CurrencySymbol from './components/shared/CurrencySymbol'
import StockView from './components/shared/StockView'
import DurationView from './components/shared/DurationView'
import TimeView from './components/shared/TimeView'
import DateView from './components/shared/DateView'
import ImagePreview from './components/shared/ImagePreview'
import FormView from './components/forms/FormView'
import NumberView from './components/shared/NumberView'
import LocationView from './components/shared/LocationView'
import ResourceView from './components/resources/ResourceView'
import CustomerNameView from './components/customers/CustomerNameView'
import LocationSelect from './components/location/LocationSelect'
import LocationAddressView from './components/location/LocationAddressView'
import ProductView from './components/inventory/ProductView'
import ServiceNameView from './components/services/ServiceNameView'
import ProductNameView from './components/inventory/ProductNameView'
import MembershipPackageNameView from './components/membership/MembershipPackageNameView'
import VoucherNameView from './components/voucher/VoucherNameView'
import CategoryNameView from './components/services/CategoryNameView'
import CustomerCouponView from './components/coupon/CustomerCouponView'
import StaffView from './components/staff/StaffView'
import StaffPic from './components/staff/StaffPic'
import ResourcePic from './components/resources/ResourcePic'
import ExperienceView from './components/staff/ExperienceView'
import LocalizedDatepicker from './components/shared/LocalizedDatepicker'
import Timepicker from './components/shared/Timepicker'
import ScreenTitle from './components/shared/ScreenTitle'
import SelectBox from './components/shared/SelectBox'
import HRText from './components/shared/HRText'
import SuccessScreen from './components/shared/SuccessScreen'
import SectionActionTitle from './components/shared/SectionActionTitle'

import CustomerSimplePreview from './components/customers/CustomerSimplePreview'
import CustomerTinyPreview from './components/shared/CustomerTinyPreview'
import MainActionButton from './components/shared/MainActionButton'
import DurationInput from './components/shared/DurationInput'
import AvgFeedbackView from './components/shared/AvgFeedbackView'
import NPSView from './components/shared/NPSView'
import SurveyResultView from './components/shared/SurveyResultView'
import FullscreenModal from './components/shared/FullscreenModal'
import GenericModal from './components/shared/GenericModal'
import PhoneInput from './components/shared/PhoneInput'
import * as VueGoogleMaps from 'vue2-google-maps'
import dayjs from 'dayjs'
import localeData from 'dayjs/plugin/localeData'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import timezone from 'dayjs/plugin/timezone'
import localizedFormat from 'dayjs/plugin/localizedFormat'
dayjs.extend(localizedFormat)
dayjs.extend(localeData)
dayjs.extend(customParseFormat)
dayjs.extend(timezone)

require('dayjs/locale/hy-am')
require('dayjs/locale/ru')

import './validators'

Vue.use(VueGoogleMaps, {load: {key: 'AIzaSyCVKvv1MghYrZDfbyiupesoaVeQZif6ZDI'}, installComponents: true})
//Vue.use(VueReCaptcha, {siteKey: process.env.VUE_APP_RECAPTCHA_KEY, loaderOptions: {autoHideBadge: true}})
Vue.use(Buefy, {defaultProgrammaticPromise: true})
Vue.use(VeeValidate)
Vue.use(VueResource)
Vue.use(ErrorHandler)
Vue.use(CompanySettings)

Vue.use(VueSocketIO, SocketInstance.init(store), {store})
Vue.use(Filters)
// Vue.use(VueFbCustomerChat, {
//   page_id: process.env.VUE_APP_FB_PAGE_ID,
//   locale: 'en_US',
//   greeting_dialog_display: 'hide'
// })
Vue.use(VueTelInput)
Vue.use(VueBarcodeScanner, {
  sound: true, // default is false
  soundSrc: '/sounds/beep.wav' // default is blank
  // callbackAfterTimeout: true // default is false
})
Vue.component('note', Note)
Vue.component('empty-content', EmptyContent)
Vue.component('rewards-view', RewardsView)
Vue.component('tax-view', TaxView)
Vue.component('tax-mapping-view', TaxMappingView)
Vue.component('phone-input', PhoneInput)
Vue.component('phone-view', PhoneView)
Vue.component('duration-view', DurationView)
Vue.component('date-view', DateView)
Vue.component('number-view', NumberView)
Vue.component('location-view', LocationView)
Vue.component('location-select', LocationSelect)
Vue.component('resource-view', ResourceView)
Vue.component('image-preview', ImagePreview)
Vue.component('form-view', FormView)
Vue.component('payment-type-view', PaymentTypeView)
Vue.component('location-address-view', LocationAddressView)
Vue.component('product-view', ProductView)
Vue.component('service-name-view', ServiceNameView)
Vue.component('customer-name', CustomerNameView)
Vue.component('product-name-view', ProductNameView)
Vue.component('category-name-view', CategoryNameView)
Vue.component('customer-coupon-view', CustomerCouponView)
Vue.component('membership-package-name-view', MembershipPackageNameView)
Vue.component('voucher-name-view', VoucherNameView)
Vue.component('staff-view', StaffView)
Vue.component('staff-pic', StaffPic)
Vue.component('resource-pic', ResourcePic)
Vue.component('hr-text', HRText)
Vue.component('success-screen', SuccessScreen)
Vue.component('experience-view', ExperienceView)
Vue.component('payment-view', PaymentView)
Vue.component('invoice-view', InvoiceView)
Vue.component('currency-view', CurrencyView)
Vue.component('comparison-view', ComparisonView)
Vue.component('percentage-view', PercentageView)
Vue.component('time-view', TimeView)
Vue.component('stock-view', StockView)
Vue.component('screen-title', ScreenTitle)
Vue.component('currency', CurrencySymbol)
Vue.component('action-button', MainActionButton)
Vue.component('section-action-title', SectionActionTitle)
Vue.component('customer-preview', CustomerSimplePreview)
Vue.component('customer-tiny-preview', CustomerTinyPreview)
Vue.component('scroll-bar', VuePerfectScrollbar)
Vue.component('duration-input', DurationInput)
Vue.component('timepicker', Timepicker)
Vue.component('select-box', SelectBox)
Vue.component('avg-feedback-view', AvgFeedbackView)
Vue.component('nps-view', NPSView)
Vue.component('survey-result-view', SurveyResultView)
Vue.component('fullscreen-modal', FullscreenModal)
Vue.component('generic-modal', GenericModal)
Vue.component('localized-datepicker', LocalizedDatepicker)
Vue.component('em-loading', Loading)

FacebookApi.initSDK(process.env.VUE_APP_FB_APP_ID)
if (process.env.NODE_ENV === 'production') {
  Vue.url.options.root = '/api/v1/'
} else if (process.env.NODE_ENV === 'test') {
  Vue.url.options.root = 'http://localhost:7777/api/v1/'
} else {
  Vue.url.options.root = 'http://localhost:3000/api/v1/'
}

// Auth token attaching interceptor for every request
Vue.http.interceptors.push((request, next) => {
  if (request.url.indexOf('http') === 0) {
    return
  }
  if (store.state.auth.token) {
    request.headers.set('X-AUTH-TOKEN', store.state.auth.token)
  }
  if (request.method === 'GET') {
    for (let param in request.params) {
      if (request.params[param] instanceof Date) {
        request.params[param] = request.params[param].toISOString()
      }
    }
  }
  next(response => {
    if (response.status === 401 && router.path !== '/signin') {
      store.dispatch('signout')
      router.push('/signin')
    }
  })
})

window.popStateDetected = false
window.addEventListener('popstate', () => {
  window.popStateDetected = true
})

router.beforeEach((to, from, next) => {
  const IsItABackButton = window.popStateDetected
  window.popStateDetected = false
  let modals = document.querySelectorAll('.modal')
  if (IsItABackButton && modals.length) {
    modals[modals.length - 1].__vue__?.$vnode?.context?.close()
    return next(false)
  }
  next()
})

// Authentication guard
router.beforeEach(async (to, from, next) => {
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth)
  const doNotRedirect = to.matched.some(record => record.meta.doNotRedirect)

  let lng = to.query.lng
  if (lng) {
    await loadLanguageAsync(lng)
  } else if (!store.state.auth.token) {
    await loadLanguageAsync(window.location.hostname.endsWith('.am') ? 'hy' : 'en')
    //|| window.location.hostname.endsWith('localhost')
  } else if (localStorage.getItem('ui-locale')) {
    await loadLanguageAsync(localStorage.getItem('ui-locale'))
  }

  if (requiresAuth && !store.state.auth.token) {
    next(`/signin?returnUrl=${encodeURIComponent(to.fullPath)}`)
  } else if (doNotRedirect) {
    next()
  } else if (store.state.auth.token) {
    await store.dispatch('fetchMainData')
    if (!store.state.auth.user.company && to.name !== 'BusinessSetup') {
      return next('/signup/business')
    }
    if (store.state.auth.user.company && to.name === 'BusinessSetup') {
      return next('/')
    }
    if (store.state.company.company.wizardStep && to.name !== 'Onboarding') {
      next('/onboarding')
    } else if (!requiresAuth) {
      if (router.app.$rfSettings().calendar && !router.app.$rfRewardsOnly()) {
        next('/calendar')
      } else {
        next('/customers')
      }
    } else {
      next()
    }
  } else {
    next()
  }
})

Vue.config.productionTip = false

/* eslint-disable no-new */
new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
