const PERMISSIONS_SCOPE = [
  'email',
  'pages_messaging',
  'pages_show_list',
  'pages_manage_metadata',
  'pages_read_engagement',
  'pages_read_user_content'
]

export default {
  expireDate: null,
  userAccessToken: null,
  loggedIn: false,
  botAppId: null,

  initSDK(appId) {
    this.initialized = new Promise((resolve, reject) => {
      console.log('FB Initializing...', appId)
      window.fbAsyncInit = () => {
        this.__init(appId)
          .then(() => {
            console.log('FB Initialized')
            resolve()
          })
          .catch(err => {
            reject(err)
          })
      }
      this.__loadSDKAsync(document)
    })
  },

  __loadSDKAsync(d) {
    let js
    let id = 'facebook-jssdk'
    let ref = d.getElementsByTagName('script')[0]
    if (d.getElementById(id)) {
      return
    }
    js = d.createElement('script')
    js.id = id
    js.async = true
    js.src = '//connect.facebook.net/en_US/sdk.js'
    ref.parentNode.insertBefore(js, ref)
  },

  __init(botAppId) {
    return new Promise(resolve => {
      if (botAppId === this.botAppId) {
        return resolve()
      }

      this.userAccessToken = null
      this.expireDate = null
      window.FB.init({
        appId: botAppId,
        xfbml: true,
        version: 'v8.0',
        cookie: true
      })

      if (this.botAppId && this.loggedIn) {
        window.FB.logout(() => {
          this.loggedIn = false
          resolve()
        })
      } else {
        resolve()
      }
      this.botAppId = botAppId
    })
  },

  getLoginState() {
    return new Promise((resolve, reject) => {
      this.initialized.then(() => {
        window.FB.getLoginStatus(response => {
          if (response && response.error) {
            return reject(response.error)
          }
          if (response.status === 'connected') {
            this.loggedIn = true
            this._checkPagePermissions(response.authResponse)
              .then(missingPermissions => {
                resolve({auth: 'connected', missingPermissions: missingPermissions, userData: response.authResponse})
              })
              .catch(err => {
                reject(err)
              })
          } else if (response.status === 'not_authorized') {
            resolve({auth: 'not_authorized'})
          } else {
            resolve({auth: 'not_loggedin'})
          }
        })
      })
    })
  },
  _checkPagePermissions() {
    return new Promise((resolve, reject) => {
      window.FB.api('/me/permissions', response => {
        if (response && response.error) {
          return reject(response.error)
        }
        const res = []
        if (!this._isGranted(response.data, 'pages_manage_metadata')) {
          res.push('missing_pages_manage_metadata')
        }
        if (!this._isGranted(response.data, 'pages_messaging')) {
          res.push('missing_pages_messaging')
        }

        if (!this._isGranted(response.data, 'pages_show_list')) {
          res.push('missing_pages_show_list')
        }
        if (!this._isGranted(response.data, 'pages_read_engagement')) {
          res.push('missing_pages_read_engagement')
        }
        if (!this._isGranted(response.data, 'pages_read_user_content')) {
          res.push('missing_pages_read_user_content')
        }
        // if (!this._isGranted(response.data, 'email')) {
        //   res.push('missing_email')
        // }
        resolve(res)
      })
    })
  },
  login(scope) {
    return new Promise((resolve, reject) => {
      window.FB.login(
        response => {
          if (response && response.error) {
            return reject(response.error)
          }
          resolve(this.getLoginState())
        },
        {scope: scope || PERMISSIONS_SCOPE}
      )
    })
  },

  getPageData(pageId, fields) {
    return new Promise((resolve, reject) => {
      window.FB.api('/' + pageId + '?fields=' + fields.join(','), response => {
        if (response && response.error) {
          return reject(response.error)
        }
        resolve(response)
      })
    })
  },

  getUserAccessToken() {
    return new Promise((resolve, reject) => {
      const nowInSeconds = new Date().getTime() / 1000
      if (!this.expireDate || this.expireDate < nowInSeconds) {
        window.FB.getLoginStatus(response => {
          if (response && response.error) {
            return reject(response.error)
          }
          this.expireDate = nowInSeconds + response.authResponse.expiresIn - 150
          this.userAccessToken = response.authResponse.accessToken
          resolve({userAccessToken: this.userAccessToken})
        })
      } else {
        resolve({userAccessToken: this.userAccessToken})
      }
    })
  },

  _isGranted(permissions, permission) {
    for (let i = 0; i < permissions.length; i++) {
      if (permissions[i].permission === permission && permissions[i].status === 'granted') {
        return true
      }
    }
    return false
  },

  getFBPages() {
    return new Promise((resolve, reject) => {
      this.getLoginState().then(state => {
        if (state.auth !== 'connected') {
          return resolve('not-connected')
        }
        if (state.missingPermissions.length) {
          return resolve('missing-permissions')
        }
        window.FB.api(
          '/me/accounts',
          {fields: 'picture.height(256).width(256),name,id,access_token,about,category,category_list,tasks'},
          response => {
            if (response && response.error) {
              return reject(response.error)
            }
            resolve(response.data)
          }
        )
      })
    })
  }
}
