<template lang="pug">
  span.tag(v-if="satisfaction" :class="satisfaction.className")
    b {{$t(`satisfaction.${satisfaction.status}`)}}
</template>

<script>
export default {
  props: {
    customer: {type: Object, required: true}
  },
  computed: {
    satisfaction() {
      let avgFeedback = this.customer.surveys && this.customer.surveys.feedback && this.customer.surveys.feedback.value
      if (!avgFeedback) {
        return {status: 'unknown'}
      }
      if (this.customer.visits >= 2 && avgFeedback >= 4.5) {
        return {status: 'happy', className: 'is-success'}
      }
      if (avgFeedback < 4) {
        return {status: 'unhappy', className: 'is-danger'}
      }
      return {status: 'neutral', className: 'is-info'}
    }
  }
}
</script>
