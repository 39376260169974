<template lang="pug">
b-dropdown(v-model="selectedStaffId" @input="onChange" :scrollable="!isMobile()"  :append-to-body="isMobile()")
  template(#trigger)
    
    .is-flex.is-flex-direction-row.is-align-items-center.is-clickable
      b-tooltip(:label="$t('appointment.mark_as_rebooking_tooltip')")
        .pr-2(@click.stop="switchRebooking") 
          span.icon.is-medium(style="vertical-align: -6px")
            i.mdi.mdi-refresh.mdi-24px(v-if="!rebooking")
            i.mdi.mdi-refresh-circle.mdi-24px.has-text-success(v-else)
      //.control
        b-tooltip(:label="$t('appointment.mark_as_preferred_tooltip')")
          .pr-2(@click.stop="switchPreferred") 
            span.icon.is-medium(style="vertical-align: -6px")
              i.mdi.mdi-24px.mdi-heart-outline(v-if="!rebooking")
            
              i.mdi.mdi-heart.mdi-24px.has-text-danger(v-else)
      template(v-if="selectedStaffId")
        staff-pic.is-32x32.is-round.mr-3(:staff="selectedStaffId")
        div
          p.title.is-6.is-cropped-text(style="width: 100px") {{ selectedStaff.firstName }} {{ selectedStaff.lastName }}
      template(v-else)
        
        div(style="height: 32px") &nbsp;
        div
          p.has-text-grey {{ $t('staff.not_rebooked_placeholder') }}
      
      
  template(v-if="activeLocations.length > 1")
    template(v-for="(loc, idx) in locations")
      .px-3.pt-4.pb-2
        span.icon
          i.mdi.mdi-map-marker-outline
        span {{ loc.title }}
      //hr.dropdown-divider(v-if="idx")
      b-dropdown-item(v-for="member in activeServiceMembersByMainLocation(loc._id)" :value="member._id" :key="member._id") 
        .is-flex.is-flex-direction-row.is-align-items-center
          staff-pic.is-32x32.is-round.mr-3(:staff="member")
          div
            p.is-size-6.has-text-weight-semibold {{ member.firstName }} {{ member.lastName }}
  template(v-else)
    b-dropdown-item(v-for="member in activeServiceMembers" :value="member._id" :key="member._id") 
      .is-flex.is-flex-direction-row.is-align-items-center
        staff-pic.is-32x32.is-round.mr-3(:staff="member")
        div
          p.is-size-6.has-text-weight-semibold {{ member.firstName }} {{ member.lastName }}
</template>
<script>
import {mapState, mapGetters} from 'vuex'

export default {
  name: 'StaffSelect',
  props: {
    value: {type: String, default: undefined},
    expanded: {type: String, default: undefined},
    size: {type: String, default: undefined},
    locationId: {type: String, default: undefined}
  },
  data() {
    return {
      selectedStaffId: undefined,
      rebooking: false
    }
  },
  computed: {
    ...mapGetters('location', ['activeLocations']),
    ...mapState('auth', ['user']),
    ...mapState('staff', ['staffMap']),
    ...mapGetters('staff', ['activeServiceMembers', 'activeServiceMembersByMainLocation']),
    ...mapGetters('services', ['serviceById']),
    selectedStaff() {
      if (!this.selectedStaffId) {
        return ''
      }
      return this.staffMap[this.selectedStaffId]
    },
    locations() {
      let data = this.activeLocations.filter(l => this.activeServiceMembersByMainLocation(l._id).length)
      data.unshift(
        data.splice(
          data.findIndex(l => l._id === this.user.location),
          1
        )[0]
      )
      return data
    }
  },
  watch: {
    staffId(newVal) {
      this.selectedStaffId = newVal
    },
    preferred(newVal) {
      this.rebooking = newVal
    }
  },
  async created() {
    if (this.value) {
      this.selectedStaffId = this.value
      this.serviceStaffId = this.value
      this.rebooking = true
    } else {
      this.rebooking = false
    }
  },
  methods: {
    onChange() {
      if (this.selectedStaffId) {
        this.rebooking = true
      }
      this.$emit('input', this.selectedStaffId)
    },
    switchRebooking() {
      this.rebooking = !this.rebooking
      if (!this.rebooking) {
        this.selectedStaffId = undefined
      } else if (!this.selectedStaffId) {
        this.selectedStaffId = this.serviceStaffId || this.user._id
      }

      this.$emit('input', this.selectedStaffId)
    }
  }
}
</script>
