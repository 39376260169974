module.exports = {
  INCOMPLETE: 'incomplete',
  DRAFT: 'draft',
  NEW: 'new',
  CONFIRMED: 'confirmed',
  ARRIVED: 'arrived',
  STARTED: 'started',
  COMPLETED: 'completed',
  CANCELED: 'canceled',
  NOSHOW: 'noshow',
  TIME_BLOCK: 'time_block'
}
