<template lang="pug">
.modal-card
  header.modal-card-head(v-if="currentPlan")
    p.modal-card-title(data-qa="modal-header") 
      b(v-t="'billing.plans_title'")
    div
      a.button.is-white.is-small(@click="$emit('close')")
        span.icon
          i.mdi.mdi-close.mdi-24px
  section.modal-card-body.hero.py-5(:class="{'is-primary': !currentPlan, 'is-light': currentPlan}")
    em-loading(:active="loading" is-full-page)
    .hero-body(v-if="!loading")
      
      h2.title.is-2.has-text-centered.has-text-uppercase(v-t="'billing.plans_title'") 
      p.subtitle.has-text-centered(v-t="'billing.plans_subtitle'") 
      //p.mb-3.has-text-centered
        span.has-text-secondary(v-t="'billing.annual_plans_discount_subtitle'") 
      //b-field.is-grouped-centered.is-grouped(position="is-centered" )
        b-switch(v-model="annual" type="is-success" passive-type='is-dark')
          b(v-if="annual" v-t="'billing.annual_plans_label'")
          b(v-else v-t="'billing.monthly_plans_label'")

      .columns.is-centered.mt-6.is-multiline
        .column.is-4-desktop.is-12-tablet.is-3-widescreen(v-for="(plan, idx) in plans" :key="plan.plan")
          .box.is-clipped.p-0.pt-3.is-relative  
            .p-5
              span.tag(v-if="plan.recommended" style="position: absolute; top: 14px; right: 14px")
                span.has-text-primary.is-uppercase.title.is-7(v-t="'billing.recommended_plan_label'") 
              h3.title.is-4.has-text-black.has-text-uppercase {{$t(`products.${plan.plan}`)}}
              p.subtitle.is-size-6.has-text-grey {{ $t(`billing.plans.${plan.plan}`) }}
              .mb-3(v-if="annual && !plan.free")
                p.is-size-3(style="line-height: 24px")
                  small(style="text-decoration: line-through") 
                    invoice-currency(:value="plan.prices.monthly[0].unit_amount" :currency-code="plan.prices.monthly[0].currency")
                  br
                  b.is-size-5.has-text-danger 
                    invoice-currency(:value="plan.prices.annual[0].unit_amount/12" :currency-code="plan.prices.annual[0].currency") 
                  |  
                  small.has-text-grey(style="font-size: 1rem;")
                    span(v-t="'billing.per_month_label'") 
                    br
                    span(v-t="'billing.due_payment_label'") 
                    invoice-currency(:value="plan.prices.annual[0].unit_amount" :currency-code="plan.prices.annual[0].currency") 
                  
                  
              .mb-3(v-else)
                p.is-size-3(v-if="!plan.free") 
                  invoice-currency(:value="plan.prices.monthly[0].unit_amount" :currency-code="plan.prices.monthly[0].currency")
                  |  
                  small.has-text-grey(style="font-size: 1rem;" v-t="'billing.per_month_label'") 
                p.is-size-3(v-else) 
                  span(v-t="'billing.free_plan_title'")
                  |  
                  small.has-text-grey(style="font-size: 1rem;" v-t="'billing.per_month_label'") 
              .mb-3.is-size-5(v-if="idx")
                span.icon.has-text-success
                  i.mdi.mdi-check
                b(v-html="$t('billing.everything_from_plus', {plan: $t(`products.${plans[idx-1].plan}`)})")
              .mb-3(v-for="feat in plan.highlights")
                span.icon.has-text-success
                  i.mdi.mdi-check
                span {{$t(`products.${feat}`)}}
              
              .buttons.is-centered.mt-6
                template(v-if="currentPlan")
                  button.button.is-primary.is-fullwidth.is-medium(v-if="plan.plan === currentPlan" disabled)
                    span(v-t="'billing.actions.current_plan'")                 

                  a.button.is-primary.is-fullwidth.is-outlined.is-medium(v-else-if="plan.contactOnly" href="mailto:sales@emly.co") 
                    span(v-t="'billing.actions.contact_sales'")             
                    span.icon
                      i.mdi.mdi-chevron-right    

                  button.button.is-primary.is-fullwidth.is-outlined.is-medium(v-else-if="!plan.free && hasFreeTrial" @click="confirmTrial(plan.plan)")
                    span(v-t="'billing.actions.start_trial'")
                      span.icon
                        i.mdi.mdi-chevron-right

                  button.button.is-primary.is-fullwidth.is-outlined.is-medium(v-else-if="plan.free" @click="confirmCancelSubscription()")
                    span(v-t="'billing.actions.change_plan'")
                    span.icon
                      i.mdi.mdi-chevron-right

                  button.button.is-primary.is-fullwidth.is-outlined.is-medium(v-else @click="subscribe(plan.plan)")
                    span(v-t="'billing.actions.change_plan'")
                    span.icon
                      i.mdi.mdi-chevron-right

                template(v-else)
                  a.button.is-primary.is-fullwidth.is-outlined.is-medium(v-if="plan.contactOnly" href="mailto:sales@emly.co") 
                    span(v-t="'billing.actions.contact_sales'")             
                    span.icon
                      i.mdi.mdi-chevron-right
                
                  button.button.is-primary.is-fullwidth.is-medium(v-else-if="plan.free" @click="selectPlan(plan.plan)")
                    span(v-t="'billing.actions.signup_for_free'")
                    span.icon
                      i.mdi.mdi-chevron-right
                
                  button.button.is-primary.is-fullwidth.is-medium(v-else @click="selectPlan(plan.plan)")
                      span(v-t="'billing.actions.start_trial'")
                      span.icon
                        i.mdi.mdi-chevron-right
        
      .columns.is-centered.mt-6(v-if="!currentPlan")
        .column.is-6
          .has-text-centered
            .p-5      
              h3.title.is-4.has-text-white(v-t="'billing.sms_pricing_title'")
              p.subtitle.is-6.has-text-white(v-html="$t('billing.sms_terms')")
            
            img(src="@/assets/logo_white.svg", style="height:36px;")
</template>

<script>
import BillingApi from '@/api/billing'
import InvoiceCurrency from './InvoiceCurrency'
import SubscribeToPaidPlan from './SubscribeToPaidPlan'
import BILLING_CYCLES from '../../../constants/billingCycles'
import {mapActions, mapGetters, mapState} from 'vuex'
export default {
  name: 'Pricing',
  components: {
    InvoiceCurrency
  },
  props: {
    country: {type: String, required: true},
    industry: {type: String, required: true}
  },
  data() {
    return {
      annual: false,
      loading: false,
      plans: undefined
    }
  },
  computed: {
    ...mapState('company', ['company']),
    ...mapGetters('company', ['currentPlan']),

    hasFreeTrial() {
      return !this.company || !this.company.plan.trialStart
    },
    isOnFreePlan() {
      return this.plans.find(el => el.plan === this.currentPlan).free
    },
    hasFreePlan() {
      return !!this.plans.find(p => p.free)
    }
  },
  created() {
    this.init()
  },
  methods: {
    ...mapActions('billing', ['startFreeTrial', 'cancelSubscription']),
    async init() {
      this.loading = true
      try {
        this.plans = await BillingApi.listProducts({country: this.country, industry: this.industry})
      } catch (err) {
        this.$handleAPIError(err)
      }
      this.loading = false
    },
    selectPlan(plan) {
      this.$emit('input', {plan, billingCycle: this.annual ? BILLING_CYCLES.ANNUAL : BILLING_CYCLES.MONTHLY})
      this.$emit('close')
    },
    confirmTrial(plan) {
      this.$buefy.dialog.confirm({
        title: this.$t('product_teaser.trial_confirm.title'),
        message: this.$t('product_teaser.trial_confirm.body'),
        cancelText: this.$t('actions.cancel'),
        confirmText: this.$t('actions.confirm'),
        type: 'is-success',
        onConfirm: async () => {
          try {
            await this.startFreeTrial(plan)
            this.$buefy.toast.open({
              message: this.$t('product_teaser.trial_confirm.success'),
              type: 'is-success',
              duration: 5000,
              queue: false
            })
            this.$emit('close')
          } catch (err) {
            this.$handleAPIError(err)
          }
        }
      })
    },
    subscribe(plan) {
      this.$buefy.modal.open({
        parent: this,
        component: SubscribeToPaidPlan,
        width: 960,
        hasModalCard: true,
        props: {
          planCode: plan
        },
        events: {
          success: () => {
            this.$emit('close')
          }
        },
        canCancel: ['escape']
      })
    },
    confirmCancelSubscription() {
      this.$buefy.dialog.confirm({
        title: this.$t('billing.cancel.title'),
        message: this.$t('billing.cancel.body'),
        confirmText: this.$t('billing.actions.cancel'),
        cancelText: this.$t('actions.close'),
        type: 'is-danger',
        onConfirm: async () => {
          try {
            this.loading = true
            await this.cancelSubscription()
            this.$buefy.toast.open({
              message: this.$t('billing.cancel.success'),
              type: 'is-success',
              duration: 5000,
              queue: false
            })
            this.$emit('close')
          } catch (error) {
            this.$handleAPIError(error)
          }
          this.loading = false
        }
      })
    }
  }
}
</script>
<style scoped>
.list-item {
  padding: 1em;
}
</style>
