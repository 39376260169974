<template lang="pug">
div.is-relative
  em-loading(:active="loading" :is-full-page="false")
  
  .media
    .media-left
      staff-pic.is-48x48(:staff="user")
    .media-body.is-flex-grow-1
      
      content-editable.p-2.notification.mb-2(v-model="text" :placeholder="$t('notes.add_note_placeholder')")
      SimpleFileUpload.mb-2(v-model="attachments" ref="upload"  size="is-small" accept="all" :uploadNumber="5")
      .buttons.is-right
        action-button.is-small(title="actions.save" :bold="false" @click="submit" :loading="submitting")
        
  .media.is-hoverable(v-for="(note, idx) in notes" :key="note._id")
    .media-left
      staff-pic.is-48x48(:staff="note.user")
    .media-body.is-flex-grow-1
      p 
        staff-view.has-text-weight-semibold(:id="note.user")
        |  
        date-view.has-text-grey.is-size-7(:value="note.created" format="H:mm D MMM, YYYY")
        |  
        a.has-text-danger.is-size-7.hoveree(@click="removeNote(note, idx)" v-t="'actions.delete'")
      p.mb-2 {{ note.text }}
      div(v-if="note.attachments && note.attachments.length")
        a(:href="url" target="_blank" v-for="(url, idx) in note.attachments") 
          span.icon
            i.mdi.mdi-attachment
          span {{ $t('notes.attachment') }} {{ idx+1 }}

</template>
<script>
import NotesApi from '@/api/notes'
import ContentEditable from './ContentEditable'
import SimpleFileUpload from './SimpleFileUpload.vue'
import {mapState} from 'vuex'
export default {
  name: 'NotesList',
  components: {
    ContentEditable,
    SimpleFileUpload
  },
  props: {
    entityId: {type: String, required: true},
    entity: {type: String, required: true}
  },
  data() {
    return {
      notes: [],
      loading: false,
      text: undefined,
      attachments: undefined,
      submitting: false
    }
  },
  computed: {
    ...mapState('auth', ['user'])
  },
  created() {
    this.fetchNotes()
  },
  methods: {
    async fetchNotes() {
      this.loading = true
      try {
        this.notes = await NotesApi.getNotes({entityId: this.entityId})
      } catch (err) {
        this.$handleAPIError(err)
      }
      this.loading = false
    },
    async removeNote(note, idx) {
      this.notes.splice(idx, 1)
      try {
        await NotesApi.deleteNote(note._id)
      } catch (err) {
        this.$handleAPIError(err)
        this.notes.splice(idx, 0, [note])
      }
    },
    async submit() {
      if (this.submitting) {
        return
      }
      if (!this.text || !this.text.trim()) {
        return
      }
      this.submitting = true
      try {
        await this.$refs.upload.upload()
        await this.$nextTick()
        let note = await NotesApi.createNote({
          entityId: this.entityId,
          entity: this.entity,
          text: this.text,
          attachments: this.attachments
        })
        this.notes.unshift(note)
        this.text = undefined
        this.$refs.upload.reset()
      } catch (err) {
        this.$handleAPIError(err)
      }
      this.submitting = false
    }
  }
}
</script>
<style scoped>
.is-hoverable .hoveree {
  display: none;
}
.is-hoverable:hover .hoveree {
  display: inline;
}
</style>
