<template lang="pug">
.is-relative.is-flex.is-flex-direction-column.is-align-items-stretch.is-clipped.box.p-0(data-qa="customer-details")
  div.is-flex-shrink-0.has-border-bottom.p-5
    .is-flex.mb-4.is-align-items-center.is-flex-direction-column.has-text-centered
      .mr-3.is-flex-shrink-0
        ProfilePic.is-96x96(:customer="customer" style="margin: 0 auto")
      .mr-3.is-flex-grow-1
        p.title.is-5 
          customer-name(:value="customer") 
          span(v-if="customer.lng")  
            span(v-if="customer.lng === 'en_US'")  🇬🇧
            span(v-else-if="customer.lng === 'hy_AM'")  🇦🇲
            span(v-else-if="customer.lng === 'ru_RU'")  🇷🇺
        p.subtitle.is-6.mb-2
          phone-view(:value="customer.phone" v-if="customer.phone")
          template(v-if="customer.altPhone")
            |    
            phone-view(:value="customer.altPhone")
        CustomerTagsPreview.mb-0(:customer="customer" v-if="$rfSubscribed(PRODUCTS.CRM_PRO)")
        p
          CustomerLoyalty.mr-3(:customer="customer" v-if="$rfSubscribed(PRODUCTS.CHURN_PREDICTION)")
          DepositGroupView(:customer="customer" v-if="$rfSubscribed(PRODUCTS.PAYMENTS)")
      //.is-flex-shrink-0
        button.button.is-light.is-small(@click="editCustomer")
          span.icon
            i.mdi.mdi-pencil
    .buttons.is-centered.mt-6
      action-button(v-if="!company.rewardsOnly && $rfSettings().calendar" @click="newAppointment" icon="calendar" title="appointment.actions.new" permission="APPOINTMENT_CREATE" :bold="false") 
      RepeatLastAppointmentButton.is-primary.is-outlined(:customer="customer" @click="onRebook" v-if="customer.visits && $rfSettings().calendar && !company.rewardsOnly" )
        span(v-t="'appointment.actions.repeat_last_appt'")
      action-button(v-if="company.rewardsOnly" @click="createSale" title="sale.actions.new_checkout" icon="basket" permission="SALE_CREATE")
      action-button.is-outlined.is-success(v-else @click="createSale" icon="basket" permission="SALE_CREATE")  
  div.is-flex-grow-1.py-5.pr-5.pl-3(style="overflow:auto")
    CustomerGeneralInfo(:customer="customer")
</template>

<script>
import {mapState} from 'vuex'
import CustomerCreateEditForm from './CustomerCreateEditForm'
import ProfilePic from './ProfilePic'
import CustomerGeneralInfo from './CustomerGeneralInfo'
import CustomerTagsPreview from './CustomerTagsPreview'

import CustomerLoyalty from './CustomerLoyalty'
import {PRODUCTS} from '../../../constants/products'

import RepeatLastAppointmentButton from '../calendar/RepeatLastAppointmentButton'
import DepositGroupView from './DepositGroupView'

import Checkout from '../sale/Checkout'
import CheckoutPopup from '../checkout/CheckoutPopup'

export default {
  name: 'Profile',
  components: {
    CustomerCreateEditForm,
    ProfilePic,
    CustomerTagsPreview,
    CustomerLoyalty,
    CustomerGeneralInfo,
    RepeatLastAppointmentButton,
    DepositGroupView
  },
  props: {
    customer: {type: Object, required: true}
  },
  data() {
    return {
      PRODUCTS
    }
  },
  computed: {
    ...mapState('company', ['company'])
  },

  methods: {
    editCustomer() {
      this.$buefy.modal.open({
        parent: this,
        component: CustomerCreateEditForm,
        canCancel: ['escape'],
        hasModalCard: true,
        fullScreen: true,
        props: {customerId: this.customer._id}
      })
    },
    createSale() {
      if (this.company.rewardsOnly) {
        this.$buefy.modal.open({
          parent: this,
          component: CheckoutPopup,
          props: {customerId: this.customer._id},
          fullScreen: true,
          canCancel: ['escape']
        })
      } else {
        this.$buefy.modal.open({
          parent: this,
          component: Checkout,
          props: {customerId: this.customer._id},
          fullScreen: true,
          hasModalCard: true,
          canCancel: ['escape']
        })
      }
    },
    onRebook({customerId, rebookingId, date}) {
      this.$parent.$emit('close')
      this.$router.push({name: 'Calendar', query: {customer: customerId, rebooking: rebookingId, date}})
    },
    newAppointment() {
      this.$parent.$emit('close')
      this.$router.push({name: 'Calendar', query: {customer: this.customer._id}})
    }
  }
}
</script>
