<template lang="pug">
.modal-card(style="width: auto")
  header.modal-card-head
    p.modal-card-title(data-qa="modal-header") 
      b(v-t="'services.details.custom_pricing_options_title'")
      
    div
      a.button.is-white.is-small(@click="$emit('close')")
        span.icon
          i.mdi.mdi-close.mdi-24px
  section.modal-card-body
    .lst(v-if="staff.length")
      .list-item(v-for="member in eligibleStaffMembers" :key="member._id")
        .columns
          .column.is-4.is-flex.is-align-items-center
            .media
              .media-left 
                staff-pic.is-48x48.is-round(:staff="member")
              .media-content
                p.title.is-6 
                  staff-view(:id="member._id")
                p.subtitle.is-6 {{member.role && $t('roles.' + member.role)}}
                
          .column.is-3(v-if="$rfSettings().services.duration")
            b-field(:label="$t('services.details.option_duration_label')" expanded)
              duration-input(v-model="override[member._id].duration" expanded data-qa="duration" :placeholder="durationPlaceholder")
          .column
            b-field(:label="$t('services.details.option_price_label')" expanded :type="errors.has('price' + member._id) || errors.has('priceUpto' + member._id) ? 'is-danger': ''" :message="errors.has('price' + member._id) || errors.has('priceUpto' + member._id) ? errors.first('price' + member._id) || errors.first('priceUpto' + member._id) : ''")
              b-field
                p.control
                  span.button.is-static 
                    currency
                b-input(v-model.number="override[member._id].price" expanded type="number" data-vv-as="price" :name="'price' + member._id" numeric v-validate="'decimal:2|min_value:0'" :use-html5-validation="false" :placeholder="pricePlaceholder" data-qa="price-up")
                p.control
                  span.button.is-static -
                b-input(v-model.number="override[member._id].priceUpto"  expanded type="number" data-vv-as="up to price" :name="'priceUpto' + member._id" numeric v-validate="'decimal:2|min_value:0'" :use-html5-validation="false" :placeholder="priceUptoPlaceholder"  data-qa="price-to")
    empty-content(v-else icon="alert-outline" title="services.details.custom_pricing_no_member_title" subtitle="services.details.custom_pricing_no_member_subtitle")
  footer.modal-card-foot
    .buttons
      action-button(@click="save" title="actions.save" permission="SERVICES_EDIT" data-qa="action-save")  
      button.button(@click="$emit('close')") {{$t('actions.cancel')}}
</template>

<script>
import cloneDeep from 'lodash/cloneDeep'

import {mapGetters, mapState} from 'vuex'
import durationMixin from '@/mixins/duration'
import numeral from 'numeral'

export default {
  name: 'AdvancedPriceOptionsForm',
  mixins: [durationMixin],
  props: {
    value: {type: Object, default: undefined},
    option: {type: Object, required: true},
    staff: {type: Array, required: true}
  },
  data() {
    return {
      override: undefined
    }
  },
  computed: {
    ...mapState('company', ['company']),
    ...mapGetters('staff', ['activeServiceMembers']),
    eligibleStaffMembers() {
      return this.activeServiceMembers.filter(s => this.staff.includes(s._id))
    },
    durationPlaceholder() {
      return this.formatDuration(this.option.duration)
    },
    pricePlaceholder() {
      return numeral(this.option.price).format('0,0.[00]')
    },
    priceUptoPlaceholder() {
      if (this.option.priceUpto) {
        return numeral(this.option.priceUpto).format('0,0.[00]')
      }
      return ''
    }
  },
  async created() {
    this.override = (this.value && cloneDeep(this.value)) || {}

    for (let member of this.activeServiceMembers) {
      if (!this.staff.includes(member._id)) {
        delete this.override[member._id]
      } else {
        this.override[member._id] = this.override[member._id] || {}
      }
    }
  },

  methods: {
    save() {
      let res = {}
      for (let key in this.override) {
        if (!this.override[key].duration && this.override[key].duration !== 0) {
          delete this.override[key].duration
        }
        if (!this.override[key].price && this.override[key].price !== 0) {
          delete this.override[key].price
        }
        if (!this.override[key].priceUpto && this.override[key].priceUpto !== 0) {
          delete this.override[key].priceUpto
        }
        if (this.override[key].duration || this.override[key].price || this.override[key].priceUpto) {
          res[key] = this.override[key]
        }
      }
      if (!Object.keys(res).length) {
        this.$emit('input', undefined)
      } else {
        this.$emit('input', res)
      }
      this.$emit('close')
    }
  }
}
</script>
