<template lang="pug">
  div  
    label.label(v-t="'billing.update_payment_method.card_label'")
    .field
      card.stripe-card(:class='{ complete }' :stripe="stripePK" :options='stripeOptions' @change='onChange')
      p.help.has-text-danger(v-if="errorMsg") {{errorMsg}}
</template>

<script>
import {Card} from 'vue-stripe-elements-plus'

export default {
  name: 'CreditCardInput',
  components: {
    Card
  },
  data() {
    return {
      complete: false,
      errorMsg: undefined,
      stripeOptions: {},
      stripePK: process.env.VUE_APP_STRIPE_PK
    }
  },
  methods: {
    onChange(event) {
      this.complete = event.complete
      this.errorMsg = event.error && event.error.message
      this.$emit('complete', this.complete)
    }
  }
}
</script>

<style>
.security-badge {
  height: 80px;
}

.StripeElement {
  box-sizing: border-box;
  padding: 10px 12px;

  border: 1px solid #e4e4e4;
  border-radius: 4px;
  background-color: white;

  box-shadow: 0 1px 3px 0 #e6ebf1;
  -webkit-transition: box-shadow 150ms ease;
  transition: box-shadow 150ms ease;
}

.StripeElement--focus {
  box-shadow: 0 1px 3px 0 #cfd7df;
}

.StripeElement--invalid {
  border-color: #fa755a;
}

.StripeElement--webkit-autofill {
  background-color: #fefde5 !important;
}
</style>
