<template lang="pug">
div
  screen-title(title="forms.title")
    action-button(@click="createEditForm" title="forms.actions.add" icon="plus" permission="FORMS_MANAGE")
  .box
    b-table(:data="forms" hoverable=true class="is-vertically-aligned" @click="createEditForm" custom-row-key="_id" :loading="loading" )
      b-table-column(field="name" :label="$t('forms.name_heading')" v-slot="props") {{props.row.name}}
      b-table-column(field="trigger" :label="$t('forms.trigger_heading')" v-slot="props") {{props.row.trigger}}
      b-table-column(field="created" :label="$t('forms.created_heading')" v-slot="props") 
        date-view(:value="props.row.created" format="L LT")
      b-table-column(field="actions" v-slot="props") 
        .buttons.is-right
          button.button.is-success.is-rounded(@click.stop="preview(props.row._id)") 
            span.icon
              i.mdi.mdi-play-outline 
            span {{ $t('actions.preview') }}
      template(slot="empty")
        empty-content(title="forms.no_forms_message")
          action-button(@click="createEditForm" title="forms.actions.add" icon="plus" permission="FORMS_MANAGE")
</template>
<script>
import {mapActions, mapGetters, mapState} from 'vuex'
import CreateEditForm from './CreateEditForm'
import FormPreview from './FormPreview.vue'
export default {
  name: 'Forms',
  data() {
    return {
      data: [],
      loading: false
    }
  },
  computed: {
    ...mapState('company', ['company']),
    ...mapGetters('forms', ['forms'])
  },
  created() {
    this.load()
  },
  methods: {
    ...mapActions('forms', ['fetchForms']),
    async load() {
      this.loading = true
      try {
        await this.fetchForms()
      } catch (err) {
        this.$handleAPIError(err)
      }
      this.loading = false
    },
    createEditForm(row) {
      this.$buefy.modal.open({
        parent: this,
        component: CreateEditForm,
        hasModalCard: true,
        fullScreen: true,
        props: {formId: row && row._id},
        canCancel: ['escape']
      })
    },
    preview(formId) {
      this.$buefy.modal.open({
        parent: this,
        component: FormPreview,
        hasModalCard: true,
        fullScreen: false,
        props: {formId},
        canCancel: ['escape']
      })
    }
  }
}
</script>
