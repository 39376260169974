<template lang="pug">
b-dropdown(v-model="status" aria-role="list"  @input="onChange" position="is-bottom-left" size="is-small")
  a.button.is-outlined.is-rounded.is-small(slot="trigger"  slot-scope="{active}" :class="[buttonColor, loading ? 'is-loading': '']") 
    //span.icon
      i.mdi(:class="[`mdi-${icon}`]") 
    span(v-t="`appointment_status.${status}`")
    span.icon
      i.mdi(:class="active ? `mdi-chevron-up`: `mdi-chevron-down`")
  b-dropdown-item(v-for="st in statuses" :key="st" :value="st" aria-role="listitem") 
    p.is-size-6
      span.icon
        i.mdi(:class="[`mdi-${APPT_STATUS_VISUALS[st].icon}`]")
      span(v-t="`appointment_status.${st}`")
</template>

<script>
import APPT_STATUS_VISUALS from './appointmentStatusVisuals'
import APPOINTMENT_STATUS from '../../../constants/appointmentStatus'

import {mapActions, mapGetters} from 'vuex'
export default {
  name: 'AppointmentStatusDropdown',
  props: {
    appointmentId: {type: String, required: true},

    buttonColor: {type: String, default: 'is-black'}
  },
  data() {
    return {
      status: undefined,
      loading: false,
      statuses: [
        APPOINTMENT_STATUS.NEW,
        APPOINTMENT_STATUS.CONFIRMED,
        APPOINTMENT_STATUS.ARRIVED,
        APPOINTMENT_STATUS.STARTED
      ],
      APPT_STATUS_VISUALS
    }
  },
  computed: {
    ...mapGetters('appointments', ['appointmentById']),
    icon() {
      if (this.status) {
        return APPT_STATUS_VISUALS[this.status].icon
      }
      return ''
    },
    color() {
      if (this.status) {
        return APPT_STATUS_VISUALS[this.status].color
      }
      return ''
    },
    appointment() {
      return this.appointmentById(this.appointmentId)
    }
  },
  watch: {
    appointment() {
      this.status = this.appointment.status
    }
  },

  created() {
    this.status = this.appointment.status
  },
  methods: {
    ...mapActions('appointments', ['changeStatus']),
    async onChange() {
      if (this.loading || this.appointment.status === this.status) {
        return
      }
      this.loading = true
      try {
        await this.changeStatus({appointmentId: this.appointmentId, status: this.status})
      } catch (e) {
        this.$handleAPIError(e)
      }
      this.loading = false
      this.$emit('input', this.status)
    }
  }
}
</script>
