import Vue from 'vue'

export default {
  listProducts(params) {
    return Vue.http.get('billing/plans', {params}).then(res => res.body)
  },
  listBundles(params) {
    return Vue.http.get('billing/bundles', {params}).then(res => res.body)
  },
  getCouponById({couponId, planId}) {
    return Vue.http.get(`billing/coupons/${couponId}`, {params: {planId}}).then(res => res.body)
  },
  startFreeTrial(plan) {
    return Vue.http.put('billing/freetrial', {plan})
  },
  createSubscription(data) {
    return Vue.http.post('billing/subscription', data)
  },
  purchaseBundle(data) {
    return Vue.http.post('billing/bundle', data)
  },
  resumeSubscription() {
    return Vue.http.put('billing/subscription/resume')
  },
  listInvoices() {
    return Vue.http.get('billing/invoices').then(res => res.body)
  },
  getUpcomingInvoice() {
    return Vue.http.get('billing/upcomingInvoice').then(res => res.body)
  },
  getSubscription() {
    return Vue.http.get('billing/subscription').then(res => res.body)
  },
  updatePaymentMethod(data) {
    return Vue.http.put('billing/paymentMethod', data)
  },
  getPaymentMethod() {
    return Vue.http.get('billing/paymentMethod').then(res => res.body)
  },
  cancelSubscription(now) {
    return Vue.http.put('billing/subscription/cancel', {now})
  }
}
