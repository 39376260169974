import numeral from 'numeral'
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
dayjs.extend(relativeTime)

export default {
  install(Vue) {
    Vue.filter('pointify', value => {
      if (value === undefined || value === null) {
        return ''
      }
      return numeral(value).format('0,0.[00]')
    })

    Vue.filter('ratify', value => {
      if (value === undefined || value === null) {
        return ''
      }
      return numeral(value).format('0.0')
    })

    Vue.filter('dayOfMonth', value => {
      if (!value) {
        return ''
      }
      return dayjs(value).format('D')
    })
    Vue.filter('month', value => {
      if (!value) {
        return ''
      }
      return dayjs(value).format('MMM')
    })
    Vue.filter('percentify', value => {
      return Math.round(value * 100) + '%'
    })

    Vue.filter('shortDate', value => {
      if (!value) {
        return ''
      }
      return dayjs(value).format('D MMM YYYY')
    })

    Vue.filter('messengerWindow', value => {
      if (!value) {
        return ''
      }
      let diff = 24 * 60 * 60 * 1000 - (new Date().valueOf() - new Date(value).valueOf())
      if (diff < 0) {
        return ''
      }
      let minutes = parseInt(diff / (1000 * 60))
      let hours = parseInt(minutes / 60)
      minutes = minutes % 60
      if (hours) {
        return `${hours}h`
      }
      return `${minutes}m`
    })

    Vue.filter('timestampToDate', value => {
      if (!value) {
        return ''
      }
      return dayjs(value * 1000).format('MMM D, H:mm')
    })

    Vue.filter('timestampToDay', value => {
      if (!value) {
        return ''
      }
      return dayjs(value * 1000).format('MMM D')
    })

    Vue.filter('fulldate', value => {
      if (!value) {
        return ''
      }
      return dayjs(value).format('LT L')
    })
    Vue.filter('longdate', value => {
      if (!value) {
        return ''
      }
      return dayjs(value).format('H:mm, D MMMM YYYY')
    })

    Vue.filter('fromNow', value => {
      if (!value) {
        return ''
      }
      return dayjs(value).fromNow()
    })

    Vue.filter('fromNowShort', value => {
      if (!value) {
        return ''
      }
      return dayjs(value).fromNow(true)
    })

    Vue.filter('nameShortify', value => {
      if (value === undefined || value === null || !value.firstName) {
        return ''
      }
      return value.firstName + ((value.lastName && ` ${value.lastName[0]}.`) || '')
    })

    Vue.filter('formatBirthday', value => {
      return dayjs(value.toString()).format('D MMMM, YYYY')
    })
  }
}
