<template lang="pug">
.modal-card
    header.modal-card-head
      p.modal-card-title
        b(v-t="'vouchers.voucher_details_title'")
      div
        a.button.is-white.is-small(@click="$emit('close')")
          span.icon
            i.mdi.mdi-close.mdi-24px
    section.modal-card-body
      .columns
        .column
          voucher-view(:value="voucher")
        .column
          p.title.is-5(v-t="'vouchers.redemption_history_title'")
          .list(v-if="voucher.transactions && voucher.transactions.length")
            .list-item.is-flex.is-justify-content-space-between(v-for="transaction in voucher.transactions")    
              div
                invoice-view(:value="transaction.sale" :invoice="transaction.invoice")
              div 
                date-view(:value="transaction.ts" format="L LT")
          p.has-text-grey(v-else v-t="'vouchers.not_redeemed_yet'")
          hr
          b-field(:label="$t('vouchers.notes_label')")
            b-input(v-model="notes" type="textarea" :placeholder="$t('vouchers.notes_placeholder')")
    footer.modal-card-foot
      .buttons
        action-button(title="actions.update" @click="update" :loading="submitting")
</template>
<script>
import cloneDeep from 'lodash/cloneDeep'
import VoucherView from './VoucherView'
import VouchersApi from '@/api/vouchers'

export default {
  name: 'VoucherDetails',
  components: {
    VoucherView
  },
  props: {
    value: {type: Object, required: true}
  },
  data() {
    return {
      voucher: undefined,
      notes: undefined,
      submitting: false
    }
  },
  created() {
    this.voucher = cloneDeep(this.value)
    this.notes = this.voucher.notes
  },
  methods: {
    async update() {
      if (this.submitting) {
        return
      }
      this.submitting = true
      try {
        let voucher = await VouchersApi.updateVoucher(this.voucher._id, {
          notes: this.notes
        })
        Object.assign(this.voucher, voucher)
        this.$buefy.toast.open({
          message: this.$t('status.save_success'),
          queue: false,
          type: 'is-success',
          position: 'is-top'
        })
        this.$emit('change', this.voucher)
        this.$emit('close')
      } catch (err) {
        this.$handleAPIError(err)
      }
      this.submitting = false
    }
  }
}
</script>
