import Navbar from '@/components/Navbar'
import Sales from '@/components/sale/Index'
import AppointmentsList from '@/components/calendar/AppointmentsList'
import Payments from '@/components/sale/Payments'
import Vouchers from '@/components/voucher/Vouchers'
import MembershipPackages from '@/components/membership/MembershipPackages'
import Memberships from '@/components/membership/Memberships'
import Menu from '@/components/sale/Menu'
import Container from '@/components/sale/Container'
const Calendar = () => import(/* webpackChunkName: "calendar" */ '@/components/calendar/Index')

import {PRODUCTS} from '../../constants/products'

export default [
  {
    path: '/sales',
    // meta: {
    //   requiresAuth: true,
    //   title: 'navbar.sales'
    // },
    components: {
      default: Container,
      navbar: Navbar,
      menu: Menu
    },
    children: [
      {
        name: 'Sales',
        path: 'list',
        components: {
          default: Sales
        },
        meta: {
          requiresAuth: true,
          title: 'navbar.sales',
          pageTitle: 'sale.sales_title'
        },
        props: {
          default: route => ({
            location: route.query.location,
            from: route.query.from,
            to: route.query.to,
            status: route.query.status,
            paymentStatus: route.query.paymentStatus
          })
        }
      },
      {
        name: 'Appointments',
        path: 'appointments',
        components: {
          default: AppointmentsList
        },
        meta: {
          requiresAuth: true,
          title: 'navbar.appointments',
          pageTitle: 'appointments.list_title'
        },
        props: {
          default: route => ({
            location: route.query.location,
            from: route.query.from,
            to: route.query.to,
            staff: route.query.staff,
            resource: route.query.resource,
            status: route.query.status
          })
        }
      },
      {
        name: 'Payments',
        path: 'payments',
        components: {
          default: Payments
        },
        meta: {
          product: PRODUCTS.PAYMENTS,
          requiresAuth: true,
          title: 'navbar.sales',
          pageTitle: 'payment.payments_title'
        },
        props: {
          default: route => ({
            location: route.query.location,
            from: route.query.from,
            to: route.query.to,
            status: route.query.status
          })
        }
      },
      {
        path: 'vouchers',
        component: Vouchers,
        meta: {
          product: PRODUCTS.VOUCHERS,
          requiresAuth: true,
          title: 'navbar.sales',
          pageTitle: 'vouchers.title'
        }
      },
      {
        path: 'memberships/packages',
        component: MembershipPackages,
        meta: {
          product: PRODUCTS.MEMBERSHIPS,
          requiresAuth: true,
          title: 'navbar.sales',
          pageTitle: 'memberships.membership_packages_title'
        }
      },
      {
        path: 'memberships',
        component: Memberships,
        meta: {
          product: PRODUCTS.MEMBERSHIPS,
          requiresAuth: true,
          title: 'navbar.sales',
          pageTitle: 'memberships.memberships_title'
        }
      }
    ]
  },
  {
    path: '/calendar',
    name: 'Calendar',
    components: {
      default: Calendar,
      navbar: Navbar
    },
    props: {
      default: route => ({
        location: route.query.location,
        date: route.query.date,
        period: route.query.period,
        filter: route.query.filter,
        scale: route.query.scale
      })
    },
    meta: {
      requiresAuth: true,
      title: 'appointment.appointments_title'
    }
  }
]
