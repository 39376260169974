import dayjs from 'dayjs'
import {SET_TARGETS, SET_CURRENT_TARGET_VIEW, SET_PERIOD_DATA, SET_PERIOD_TYPE} from './mutationTypes'
import TargetsApi from '@/api/targets'
//import ReportsApi from '@/api/reports'
import PERIOD_TYPES from '../../constants/periods'

import Vue from 'vue'

let __fetchPromise
export default {
  namespaced: true,
  state: {
    targetsMap: {},
    year: undefined,
    periodType: PERIOD_TYPES.WEEK,
    periodTargetsMap: {},
    locationId: undefined,
    _currentKey: undefined
  },
  getters: {
    periods(state) {
      if (!state.targetsMap[state._currentKey]) {
        return
      }
      return state.targetsMap[state._currentKey][state.periodType]
    },
    annualTarget(state) {
      if (!state.targetsMap[state._currentKey]) {
        return
      }
      return state.targetsMap[state._currentKey].target
    },
    currentPeriod(state) {
      if (!state.targetsMap[state._currentKey]) {
        return
      }
      return getCurrentPeriod(state.targetsMap[state._currentKey][state.periodType])
    },
    maxTotalTarget(state) {
      if (!state.targetsMap[state._currentKey]) {
        return 0
      }
      return state.targetsMap[state._currentKey].periods
        .map(p => p.service.target + p.retail.target)
        .reduce((max, val) => Math.max(max, val), 0)
    }
  },
  mutations: {
    [SET_PERIOD_TYPE](state, type) {
      state.periodType = type
    },
    [SET_PERIOD_DATA](state, period) {
      processPeriod(period)
      Object.assign(state.periodTargetsMap[period._id], period)
    },
    [SET_CURRENT_TARGET_VIEW](state, {key, year, locationId}) {
      state._currentKey = key
      state.year = year
      state.locationId = locationId
    },
    [SET_TARGETS](state, {key, target, periods}) {
      if (!target || !periods) {
        delete state.targetsMap[key]
        return
      }

      const weekPeriods = periods.filter(p => p.type === PERIOD_TYPES.WEEK)
      const monthPeriods = periods.filter(p => p.type === PERIOD_TYPES.MONTH)
      const quarterPeriods = periods.filter(p => p.type === PERIOD_TYPES.QUARTER)
      const annualPeriods = periods.filter(p => p.type === PERIOD_TYPES.YEAR)
      // const currents = {
      //   [PERIOD_TYPES.WEEK]: getCurrentPeriod(weekPeriods),
      //   [PERIOD_TYPES.MONTH]: getCurrentPeriod(monthPeriods),
      //   [PERIOD_TYPES.QUARTER]: getCurrentPeriod(quarterPeriods)
      // }
      const periodMax = {
        [PERIOD_TYPES.WEEK]: weekPeriods
          .map(p => p.service.target + p.retail.target)
          .reduce((max, val) => Math.max(max, val), 0),
        [PERIOD_TYPES.MONTH]: monthPeriods
          .map(p => p.service.target + p.retail.target)
          .reduce((max, val) => Math.max(max, val), 0),
        [PERIOD_TYPES.QUARTER]: quarterPeriods
          .map(p => p.service.target + p.retail.target)
          .reduce((max, val) => Math.max(max, val), 0),
        [PERIOD_TYPES.YEAR]: annualPeriods
          .map(p => p.service.target + p.retail.target)
          .reduce((max, val) => Math.max(max, val), 0)
      }
      periods.forEach(p => {
        p.totalRatio = (p.service.target + p.retail.target) / periodMax[p.type]
        processPeriod(p)
        state.periodTargetsMap[p._id] = p
      })
      Vue.set(state.targetsMap, key, {
        target,
        periods,
        [PERIOD_TYPES.WEEK]: weekPeriods,
        [PERIOD_TYPES.MONTH]: monthPeriods,
        [PERIOD_TYPES.QUARTER]: quarterPeriods,
        [PERIOD_TYPES.YEAR]: annualPeriods
      })
    }
  },
  actions: {
    async fetch({commit, state}, {year, locationId, force}) {
      let key = `${year}_${locationId}`
      commit(SET_CURRENT_TARGET_VIEW, {key, year, locationId})
      if (__fetchPromise && __fetchPromise[key]) {
        await __fetchPromise[key]
      }

      if ((!__fetchPromise || !__fetchPromise[key]) && (!state.targetsMap[key] || force)) {
        __fetchPromise = __fetchPromise || {}
        __fetchPromise[key] = TargetsApi.fetch({year, locationId})
        const res = await __fetchPromise[key]
        delete __fetchPromise[key]

        commit(SET_TARGETS, {key, target: res && res.target, periods: res && res.periods})
      }

      return state.targetsMap[key]
    },
    // async fetchPeriodDataById({commit, state}, {periodId, staffId, force}) {
    //   const period = state.targetsMap[state._currentKey].periods.find(p => p._id === periodId)
    //   const target = state.targetsMap[state._currentKey].target

    //   if (staffId) {
    //     if (period.staff[staffId] && !force) {
    //       return period
    //     }
    //   } else {
    //     if (period._data_loaded && !force) {
    //       return period
    //     }
    //   }

    //   const targetData = staffId ? period.staffTargets.find(s => s.staff === staffId) : period

    //   const data = {
    //     serviceRevenue: avgSale.servicesTotal,
    //     serviceTarget: targetData.service.target,

    //     retailRevenue: avgSale.productsTotal,
    //     retailTarget: targetData.retail.target,
    //     retailPurchaseQty: retailStats.qty,
    //     retailCustomerTarget: Math.round(targetData.retail.target / target.retailAvgSale),
    //     avgRetailSaleTarget: target.retailAvgSale,
    //     bookedServices: appointments.notCompleted.value,
    //     avgServicesTarget: target.avgServices,
    //     avgServicesSaleTarget: Math.round(target.avgSale),

    //     serviceCustomerTarget: targetData.customersTarget,

    //     timeline: period.timeline,
    //     periodType: period.type
    //   }
    //   data.totalAndBookedRevenue = data.serviceRevenue + data.retailRevenue + data.bookedServices
    //   data.serviceResult = (data.service.target && data.serviceRevenue / data.service.target) || 0
    //   data.retailResult = (data.retail.target && data.retailRevenue / data.retail.target) || 0
    //   if (data.retail.target + data.service.target) {
    //     data.result = (data.retailRevenue + data.serviceRevenue) / (data.retail.target + data.service.target)
    //   } else {
    //     data.result = 0
    //   }

    //   // if (period.timeline === 'past' || (avgSale.servicesPerSale && avgSale.avgServicesSale)) {
    //   //   data.avgServices = Math.round(100 * avgSale.servicesPerSale) / 100
    //   //   data.avgServicesSale = Math.round(avgSale.avgServicesSale)
    //   //   data.avgRetail = Math.round(100 * avgSale.productsPerSale) / 100
    //   //   data.avgRetailSale = Math.round(avgSale.avgProductsSale)
    //   //   data.avgType = period.timeline === 'past' ? AVERAGES_CALC_TYPES.ACTUAL : AVERAGES_CALC_TYPES.REALTIME
    //   // } else if (period.timeline === 'current' ) {
    //   //   let previousPeriodData = getPreviousPeriodData({
    //   //     period,
    //   //     periods: state.targetsMap[state._currentKey][period.type]
    //   //   })
    //   //   if (previousPeriodData) {
    //   //     data.avgServicesSale = previousPeriodData.avgServicesSale
    //   //     data.avgRetailSale = previousPeriodData.avgRetailSale
    //   //     data.avgRetail = previousPeriodData.avgRetail
    //   //     data.avgServices = previousPeriodData.avgServices
    //   //     data.avgType = AVERAGES_CALC_TYPES.PREVIOUS_PERIOD
    //   //   } else {
    //   //     const avg = await ReportsApi.getAverageSale({
    //   //       from: dayjs(period.start)
    //   //         .subtract(30, 'days')
    //   //         .toDate(),
    //   //       to: dayjs(period.end)
    //   //         .subtract(30, 'days')
    //   //         .toDate(),
    //   //       location: period.location,
    //   //       staff: staffId
    //   //     })

    //   //     data.avgServicesSale = Math.round(avg.avgServicesSale)
    //   //     data.avgRetailSale = Math.round(avg.avgProductsSale)
    //   //     data.avgRetail = Math.round(100 * avg.productsPerSale) / 100
    //   //     data.avgServices = Math.round(100 * avg.servicesPerSale) / 100
    //   //     data.avgType = AVERAGES_CALC_TYPES.LAST_30_DAYS
    //   //   }
    //   // } else {
    //   //   //future
    //   //   let {avgSale, avgServices} = await ReportsApi.getAverageAppointments({
    //   //     from: period.start,
    //   //     to: period.end,
    //   //     location: period.location
    //   //   })
    //   //   if (avgSale) {
    //   //     data.avgServicesSale = Math.round(avgSale)
    //   //     data.avgServices = Math.round(100 * avgServices) / 100
    //   //     data.avgType = AVERAGES_CALC_TYPES.PROJECTION
    //   //   }
    //   // }

    //   // if (!data.avgServices && !data.avgServicesSale) {
    //   //   data.avgServices = target.avgServices
    //   //   data.avgServicesSale = Math.round(target.avgSale)
    //   //   data.avgType = AVERAGES_CALC_TYPES.TARGET
    //   // }

    //   // commit(SET_PERIOD_DATA, {period, staffId, data})
    //   return period
    // },

    async generateTargets({dispatch}, {year, locationId, data}) {
      await TargetsApi.generate({year, locationId, data})
      return dispatch('fetch', {locationId, year, force: true})
    },
    async onPeriodUpdate({commit, state}, {periodId}) {
      if (!state.periodTargetsMap[periodId]) {
        return
      }
      let period = await TargetsApi.getById(periodId)
      commit(SET_PERIOD_DATA, period)
    }
  }
}

function getCurrentPeriod(periods) {
  return periods.find(c => new Date(c.start) <= new Date() && new Date(c.end) > new Date())
}

function processPeriod(period) {
  if (new Date(period.start) > new Date()) {
    period.timeline = 'future'
  } else if (new Date(period.start) <= new Date() && new Date(period.end) > new Date()) {
    period.timeline = 'current'
  } else {
    period.timeline = 'past'
  }

  period.result = (period.retail.actual + period.service.actual) / (period.retail.target + period.service.target)
  period.service.result = period.service.actual / period.service.target
  period.retail.result = period.retail.actual / period.retail.target
  period.staffTargets.forEach(s => {
    s.service.result = s.service.actual / s.service.target
    s.retail.result = s.retail.actual / period.retail.target
  })
  period.previousPeriod = {
    start: dayjs(period.start)
      .subtract(1, 'week')
      .toDate(),
    end: dayjs(period.end)
      .subtract(1, 'week')
      .toDate()
  }
}

// function getPreviousPeriodData({periods, period}) {
//   let periodIdx = periods.indexOf(period)
//   if (periodIdx > 0) {
//     return periods[periodIdx - 1].data
//   }
// }
