<template lang="pug">
  span(v-if="value !== undefined") {{formatted}}
</template>
<script>
import numeral from 'numeral'
export default {
  props: {
    value: {type: Number, default: undefined},
    format: {type: String, default: '0,0.[00]'}
  },
  computed: {
    formatted() {
      return numeral(this.value).format(this.format)
    }
  }
}
</script>
