<template lang="pug">
    div
      .box
        screen-title(title="tax_settings.taxes_list_title")
          action-button(@click="openAddTaxModal" title="tax_settings.actions.add" icon="plus" permission="COMPANY_SETTINGS_EDIT")
                
        b-table(:data="activeTaxes" :loading="loading" hoverable=true class="is-vertically-aligned" @click="edit" custom-row-key="_id")
          b-table-column(field="name" width="50%" :label="$t('tax_settings.name_heading')" v-slot="props") {{props.row.name}}
          b-table-column(field="rate" width="50%" :label="$t('tax_settings.rate_heading')" v-slot="props") 
            percentage-view(:value="props.row.rate")
      .box
        screen-title(title="tax_settings.tax_groups_list_title")
          action-button(@click="openAddTaxGroupModal" title="tax_settings.actions.add_group" icon="plus" permission="COMPANY_SETTINGS_EDIT")
       
        
        b-table(:data="activeTaxGroups" :loading="loading" hoverable=true class="is-vertically-aligned" @click="editGroup" custom-row-key="_id")
          b-table-column(field="name" width="50%" :label="$t('tax_settings.name_heading')" v-slot="props") {{props.row.name}}
          b-table-column(field="rate"  width="50%" :label="$t('tax_settings.tax_items_heading')" v-slot="props") 
            p(v-for="taxId in props.row.items")
              tax-view(:value="taxId")
            
      .box
        screen-title(title="tax_settings.tax_defaults_title")
          action-button(@click="confirmApplyDefaults" title="tax_settings.actions.apply_defaults" icon="check" permission="COMPANY_SETTINGS_EDIT" scope)
       
        b-table(:data="activeLocations" @click="editLocationDefaults" custom-row-key="_id" hoverable=true class="is-vertically-aligned") 
          b-table-column(field="title" :label="$t('tax_settings.location_defaults.location_header')" v-slot="props") 
            div
              p.title.is-6 {{props.row.title}}
              p.subtitle.is-6.has-text-grey 
                location-address-view(:location-id="props.row._id")

          b-table-column(field="service" :label="$t('tax_settings.location_defaults.service_header')" v-slot="props")
            tax-view(:value="props.row.taxDefaults && props.row.taxDefaults.services")
          b-table-column(field="product" :label="$t('tax_settings.location_defaults.product_header')" v-slot="props")
            tax-view(:value="props.row.taxDefaults && props.row.taxDefaults.products")
      .box
        h4.title.is-5 {{$t('tax_settings.tax_calculation.title')}}
        note.is-outlined(type='is-info')
          p(v-if="company.priceIncludesTax") 
            span(v-html="$t('tax_settings.tax_calculation.prices_include_tax_description')") 
            |  
            a(@click="openTaxCalculationEditModal") {{$t('tax_settings.tax_calculation.change_settings_action')}}
          p(v-else) 
            span(v-html="$t('tax_settings.tax_calculation.prices_exclude_tax_description')")
            |  
            a(@click="openTaxCalculationEditModal") {{$t('tax_settings.tax_calculation.change_settings_action')}}

</template>

<script>
import {mapActions, mapGetters, mapState} from 'vuex'
import LocationTaxDefaultModal from './LocationTaxDefaultModal'
import TaxCalculationModal from './TaxCalculationModal'
import TaxCreateEditModal from './TaxCreateEditModal'
import TaxGroupCreateEditModal from './TaxGroupCreateEditModal'
export default {
  name: 'TaxSettings',
  data() {
    return {
      loading: false
    }
  },
  computed: {
    ...mapGetters('tax', ['activeTaxes', 'activeTaxGroups']),
    ...mapState('company', ['company']),
    ...mapGetters('location', ['activeLocations'])
  },
  async created() {
    await this.fetch()
  },
  methods: {
    ...mapActions('tax', ['fetchTaxes', 'applyDefaults']),
    async fetch() {
      this.loading = true
      try {
        await this.fetchTaxes(true)
      } catch (error) {
        this.$handleAPIError(error)
      }
      this.loading = false
    },
    confirmApplyDefaults() {
      this.$buefy.dialog.confirm({
        title: this.$t('tax_settings.default_apply_confirm.title'),
        message: this.$t('tax_settings.default_apply_confirm.body'),
        cancelText: this.$t('actions.cancel'),
        confirmText: this.$t('actions.apply'),
        type: 'is-black',
        onConfirm: async () => {
          try {
            await this.applyDefaults()
            this.$buefy.toast.open({
              message: this.$t('status.save_success'),
              type: 'is-success'
            })
          } catch (error) {
            this.$handleAPIError(error)
          }
        }
      })
    },

    edit(tax) {
      this.$buefy.modal.open({
        parent: this,
        component: TaxCreateEditModal,
        hasModalCard: true,
        canCancel: ['outside', 'escape'],
        props: {taxId: tax._id}
      })
    },
    openAddTaxModal() {
      this.$buefy.modal.open({
        parent: this,
        component: TaxCreateEditModal,
        hasModalCard: true,
        canCancel: ['outside', 'escape']
      })
    },
    editGroup(taxGroup) {
      this.$buefy.modal.open({
        parent: this,
        component: TaxGroupCreateEditModal,
        hasModalCard: true,
        canCancel: ['outside', 'escape'],
        props: {taxGroupId: taxGroup._id}
      })
    },
    openAddTaxGroupModal() {
      this.$buefy.modal.open({
        parent: this,
        component: TaxGroupCreateEditModal,
        hasModalCard: true,
        canCancel: ['outside', 'escape']
      })
    },
    editLocationDefaults(location) {
      this.$buefy.modal.open({
        parent: this,
        component: LocationTaxDefaultModal,
        hasModalCard: true,
        canCancel: ['outside', 'escape'],
        props: {location}
      })
    },
    openTaxCalculationEditModal() {
      this.$buefy.modal.open({
        parent: this,
        component: TaxCalculationModal,
        hasModalCard: true,
        canCancel: ['outside', 'escape'],
        props: {location}
      })
    }
  }
}
</script>
