<template lang="pug">
  figure.image(:title="name")
    img.is-rounded(:src="picture || require('@/assets/user.jpg')" @error="onInvalidImage")
</template>

<script>
import {mapActions} from 'vuex'
export default {
  name: 'StaffPic',
  props: {
    staff: {type: [Object, String], required: true}
  },
  data() {
    return {
      staffObj: undefined
    }
  },
  computed: {
    picture() {
      if (typeof this.staff === 'string') {
        return (this.staffObj && this.staffObj.picture) || undefined
      }
      return this.staff.picture
    },
    name() {
      if (!this.staffObj) {
        return
      }
      return `${this.staffObj.firstName} ${this.staffObj.lastName}`
    }
  },
  watch: {
    async staff() {
      if (typeof this.staff === 'string') {
        this.staffObj = await this.fetchStaffById(this.staff)
      }
    }
  },
  async created() {
    if (typeof this.staff === 'string') {
      this.staffObj = await this.fetchStaffById(this.staff)
    }
  },
  methods: {
    ...mapActions('staff', ['fetchStaffById']),
    onInvalidImage(event) {
      event.target.src = require('@/assets/user.jpg')
    }
  }
}
</script>
