import { render, staticRenderFns } from "./CategoryServices.vue?vue&type=template&id=a2616d34&scoped=true&lang=pug"
import script from "./CategoryServices.vue?vue&type=script&lang=js"
export * from "./CategoryServices.vue?vue&type=script&lang=js"
import style0 from "./CategoryServices.vue?vue&type=style&index=0&id=a2616d34&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a2616d34",
  null
  
)

export default component.exports