<template lang="pug">
div
  screen-title(title="staff.title_permissions") 
  .box(v-for="(permissions, product) in relevantPermissions") 
    h3.title.is-5 {{$t(`products.${product}`)}}
    table.table.is-fullwidth.is-bordered
      tr
        th {{ $t('staff.permission_label') }}
        th(v-for="(val, role) in roles" style="width: 15%") {{$t(`access.${role}`)}}
        th(style="width: 15%")  {{$t('access.owner')}}
      tr(v-for="perm in permissions")
        td {{$t(`permissions.${perm}`)}}
        td
          b-checkbox(v-model="roles.low"  :native-value="perm" :disabled="!$rfHasPermission('STAFF_PERMISSIONS_EDIT')")
        td
          b-checkbox(v-model="roles.med"  :native-value="perm" :disabled="!$rfHasPermission('STAFF_PERMISSIONS_EDIT')")
        td
          b-checkbox(v-model="roles.high"  :native-value="perm" :disabled="!$rfHasPermission('STAFF_PERMISSIONS_EDIT')")
        td
          b-checkbox(:value="true" :disabled="true")
  .buttons
    action-button(@click="save" title="actions.save" :loading="loading" permission="STAFF_PERMISSIONS_EDIT" scope)
    button.button(@click="reset") {{$t('actions.reset_to_defaults')}} 
</template>
<script>
import ROLES from '../../../constants/roles'
import PERMISSIONS from '../../../constants/permissions'
import {mapActions, mapState} from 'vuex'
import {difference} from 'lodash'
export default {
  data() {
    return {
      roles: {
        low: undefined,
        med: undefined,
        high: undefined
      },
      loading: false
    }
  },
  computed: {
    ...mapState('company', ['company']),
    relevantPermissions() {
      let res = {core: PERMISSIONS.core}
      for (const product in PERMISSIONS) {
        if (this.$rfSubscribed(product)) {
          res[product] = PERMISSIONS[product]
        }
      }
      return res
    }
  },
  created() {
    this.roles = {
      low: [...ROLES.low],
      med: [...ROLES.med],
      high: [...ROLES.high]
    }
    if (this.company.roles) {
      for (let level in this.roles) {
        let toAdd = this.company.roles[level].filter(p => p.add).map(p => p.key)
        let toRemove = this.company.roles[level].filter(p => !p.add).map(p => p.key)
        this.roles[level] = this.roles[level].filter(perm => toRemove.indexOf(perm) === -1).concat(toAdd)
      }
    }
  },
  methods: {
    ...mapActions('company', ['update']),
    reset() {
      this.$buefy.dialog.confirm({
        message: this.$t('staff.permission_reset_confirm.body'),
        title: this.$t('staff.permission_reset_confirm.title'),
        cancelText: this.$t('actions.cancel'),
        confirmText: this.$t('actions.reset'),
        type: 'is-black',
        onConfirm: () => {
          this.roles = {
            low: [...ROLES.low],
            med: [...ROLES.med],
            high: [...ROLES.high]
          }
        }
      })
    },
    async save() {
      if (this.loading) {
        return
      }
      this.loading = true
      try {
        let diff = {
          low: [],
          med: [],
          high: []
        }
        for (let level in diff) {
          let added = difference(this.roles[level], ROLES[level]).map(r => {
            return {key: r, add: true}
          })
          let removed = difference(ROLES[level], this.roles[level]).map(r => {
            return {key: r, add: false}
          })
          diff[level] = added.concat(removed)
        }
        await this.update({roles: diff})
        this.$buefy.toast.open({message: this.$t('status.save_success'), type: 'is-success', queue: false})
      } catch (err) {
        this.$handleAPIError(err)
      }
      this.loading = false
    }
  }
}
</script>
