<template lang="pug">
div
  .list-item
    b-field
      b-checkbox(:value="allSelected" size="is-medium" @input="switchSelection" :indeterminate="allSelected === undefined") {{$t('actions.select_all')}}
  .list-item.py-5(v-for="category in nonEmptyCategories" :key="category._id")
    h4.title.is-4 {{category.name}}
    .columns.is-multiline
      .column.is-6(v-for="service in getServicesByCategory(category._id)")
        p
          b-checkbox(v-model="selected" size="is-medium" :native-value="service._id" @input="input") 
            p {{service.name}}
  .notification(v-if="!nonEmptyCategories.length")
    p.subtitle.is-6 {{$t('staff.details.services.empty_list')}} 
      router-link(to="/services" target="_blank") {{$t('staff.details.services.empty_list_action')}}
</template>
<script>
import {mapGetters, mapState} from 'vuex'
export default {
  props: {
    id: {type: String, default: undefined},
    type: {type: String, default: 'staff'}
  },
  data() {
    return {
      selected: []
    }
  },
  computed: {
    ...mapGetters('services', ['nonEmptyCategories']),
    ...mapState('services', ['servicesMap']),
    allSelected() {
      return this.selected.length === Object.keys(this.servicesMap).length
        ? true
        : this.selected.length === 0
        ? false
        : undefined
    }
  },
  async created() {
    await this.$store.dispatch('services/list')
    if (this.id) {
      this.selected = Object.values(this.servicesMap)
        .filter(s => {
          return s[this.type].indexOf(this.id) !== -1
        })
        .map(s => s._id)
      this.$emit('input', this.selected)
    }
  },
  methods: {
    getServicesByCategory(id) {
      return this.$store.getters['services/servicesByCategoryId'](id)
    },
    input() {
      this.$emit('input', this.selected)
    },
    switchSelection() {
      if (this.allSelected) {
        this.selected = []
      } else {
        this.selected = Object.keys(this.servicesMap)
      }
      this.input()
    }
  }
}
</script>
<style scoped>
.category {
  margin-bottom: 1.5rem;
}
.category .column.is-6 {
  padding-bottom: 0px;
}
</style>
