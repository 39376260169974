<template lang="pug">
a.is-rounded.is-no-break(:href="isMobile() && $rfHasPermission(PERMISSIONS.core.PRIVACY_VIEW_PHONE_NUMBERS)? `tel:${value}` : undefined" @click.stop v-if="value" :title="tooltip" :class="cssClasses" style="white-space: nowrap") 
  span.icon.is-small(v-if="icon")
    i.mdi.mdi-phone
  span {{national}}
</template>
<script>
import PERMISSIONS from '../../../constants/permissions'
import PhoneNumber from 'awesome-phonenumber'
export default {
  name: 'PhoneView',
  props: {
    value: {
      type: String,
      default: undefined
    },
    size: {
      type: String,
      default: undefined
    },
    tag: {
      type: Boolean,
      default: true
    },
    icon: {
      type: Boolean,
      default: true
    },
    type: {
      type: String,
      default: 'national'
    }
  },
  data() {
    return {
      PERMISSIONS
    }
  },
  computed: {
    cssClasses() {
      let res = []
      if (this.tag) {
        res.push('tag')
      }
      if (this.size) {
        res.push(this.size)
      }
      return res
    },
    tooltip() {
      if (!this.$rfHasPermission(PERMISSIONS.core.PRIVACY_VIEW_PHONE_NUMBERS)) {
        return undefined
      }
      return this.value
    },
    national() {
      if (!this.value) {
        return undefined
      }
      if (!this.$rfHasPermission(PERMISSIONS.core.PRIVACY_VIEW_PHONE_NUMBERS)) {
        return '********'
      }
      let pn = new PhoneNumber(this.value)
      return pn.getNumber(this.type)
    }
  }
}
</script>
