import Vue from 'vue'

export default {
  searchProducts(params) {
    return Vue.http.get('inventory/products', {params}).then(res => res.body)
  },

  createProduct(data) {
    return Vue.http.post(`inventory/products`, data).then(res => res.body)
  },
  updateProduct(productId, data) {
    return Vue.http.put(`inventory/products/${productId}`, data).then(res => res.body)
  },
  updateProductsBulk(data) {
    return Vue.http.put(`inventory/products/bulk`, data)
  },
  removeProduct(productId) {
    return Vue.http.delete(`inventory/products/${productId}`)
  },
  getProductById(productId) {
    return Vue.http.get(`inventory/products/${productId}`).then(res => res.body)
  },

  listCategories() {
    return Vue.http.get(`inventory/categories`).then(res => res.body)
  },
  getCategoryById(categoryId) {
    return Vue.http.get(`inventory/categories/${categoryId}`).then(res => res.body)
  },
  createCategory(data) {
    return Vue.http.post(`inventory/categories`, data).then(res => res.body)
  },
  updateCategory(categoryId, data) {
    return Vue.http.put(`inventory/categories/${categoryId}`, data).then(res => res.body)
  },
  removeCategory({id, moveToCategory}) {
    return Vue.http.put(`inventory/categories/${id}/remove`, {moveToCategory})
  },

  listBrands() {
    return Vue.http.get(`inventory/brands`).then(res => res.body)
  },
  getBrandById(brandId) {
    return Vue.http.get(`inventory/brands/${brandId}`).then(res => res.body)
  },
  createBrand(data) {
    return Vue.http.post(`inventory/brands`, data).then(res => res.body)
  },
  updateBrand(brandId, data) {
    return Vue.http.put(`inventory/brands/${brandId}`, data).then(res => res.body)
  },
  removeBrand({id, moveToBrand}) {
    return Vue.http.put(`inventory/brands/${id}/remove`, {moveToBrand}).then(res => res.body)
  },

  getInventoryHistory({productId, locationId, filter}) {
    return Vue.http
      .get(`inventory/${productId}/history`, {params: {location: locationId, ...filter}})
      .then(res => res.body)
  },
  increaseStock(productId, data) {
    return Vue.http.post(`inventory/${productId}/increase`, data).then(res => res.body)
  },
  decreaseStock(productId, data) {
    return Vue.http.post(`inventory/${productId}/decrease`, data).then(res => res.body)
  },
  getInventoryReport(params) {
    return Vue.http.get(`inventory/inventoryReport`, {params}).then(res => res.body)
  }
}
