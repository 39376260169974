<template lang="pug">
div.pl-2.mb-1(style="border-left: 3px solid #625bfe" @click="$emit('click')")
  p.title(:class="size === 'medium' ? 'is-6' : 'is-6'") 
    service-name-view(:value="{service: service.service, option: service.option}") 
  p.subtitle.is-contrasted(:class="size === 'medium' ? 'is-6' : 'is-7'")
    span(v-if="showTime") 
      date-view(:value="service.start" format="LT")
      |  • 
    span(v-if="priceDuration")
      duration-view(:value="finalDuration") 
    span.has-text-weight-semibold(v-if="service.staff")
      |  •  
      staff-view(:id="service.staff" :preferred="service.preferred")
    span.has-text-weight-semibold(v-if="service.resource && scope === 'business'")
      |  •  
      resource-view(:value="service.resource" :preferred="service.preferredResource")
    strong(v-if="showPrice && priceDuration")
      |  •  
      currency-view(:value="priceDuration.minPrice")
      template(v-if="priceDuration.maxPrice !== undefined && priceDuration.minPrice !== priceDuration.maxPrice")
        span  - 
        currency-view(:value="priceDuration.maxPrice")
      //- currency-view(:value="service.price")
</template>

<script>
import {mapActions, mapState} from 'vuex'
import {getPricingOption} from '../../../shared/saleHelper'
export default {
  name: 'AppointmentItem',
  props: {
    service: {type: Object, required: true},
    size: {type: String, default: 'small'},
    showPrice: {type: Boolean, default: true},
    scope: {type: String, default: 'business'},
    showTime: {type: Boolean, default: false}
  },
  data() {
    return {
      option: undefined
    }
  },
  computed: {
    ...mapState('services', ['servicesMap']),
    finalDuration() {
      if (this.scope === 'business') {
        return this.priceDuration.duration + (this.service.blockedTimeAfter || 0)
      }
      return this.priceDuration.duration + (this.service.processingTimeAfter || 0)
    },
    priceDuration() {
      if (!this.option) {
        return
      }
      if (this.service.staff) {
        const {price, priceUpto, duration} = getPricingOption(this.option, this.service.staff)
        return {minPrice: price, maxPrice: priceUpto, duration}
      }

      return getPricingOption(this.option)
    }
  },
  created() {
    let service = this.servicesMap[this.service.service] //await this.fetchServiceById(this.service.service)
    if (!service) {
      this.$logWarning('SERVICE DOES NOT EXIST', this.service.service)
      return
    }
    this.option = service.pricingOptions.find(o => o._id === this.service.option)
  },
  methods: {
    ...mapActions('services', ['fetchServiceById'])
  }
}
</script>
<style scoped>
.title,
.subtitle {
  color: inherit;
}
</style>
