<template lang="pug">
button.button.is-outlined.is-dark(@click="openRegister")  
  span.icon.has-text-success.is-hidden-tablet(v-if="isOpen")
    i.mdi.mdi-cash-register.mdi-24px
  span.icon.has-text-black.is-hidden-tablet(v-else)
    i.mdi.mdi-cash-register.mdi-24px
  

  span.icon.is-hidden-mobile
    i.mdi.mdi-cash-register.mdi-24px
  span.is-hidden-mobile(v-t="'ledger.cash_register_title'")
  span.px-3.is-hidden-mobile |
  b.has-text-success.is-hidden-mobile(v-if="session && !session.close" v-t="'ledger.status_open'")
  b.has-text-danger.is-hidden-mobile(v-else v-t="'ledger.status_closed'")
  template(v-if="session && (session.openDifference || session.closeDifference)")
    span.px-3 |
    span.icon.has-text-danger
      i.mdi.mdi-alert-circle-outline.mdi-24px
</template>
<script>
import {mapState} from 'vuex'
import CashRegister from './ledger/CashRegister'

export default {
  name: 'CashRegisterButton',
  computed: {
    ...mapState('company', ['company']),
    ...mapState('ledger', ['session']),
    isOpen() {
      return this.session && !this.session.close
    }
  },
  methods: {
    openRegister() {
      this.$buefy.modal.open({
        parent: this,
        component: CashRegister,
        fullScreen: true,
        canCancel: ['escape']
      })
    }
  }
}
</script>
