<template lang="pug">
  article.msg.msg-left.image-card(style="position: relative")
    img.is-clickable(:src="artwork" @click="changeCoupon()")
    button.delete(v-if="coupon" @click="remove")
    .card-text-body 
      p.title.is-6 {{title}}
      p.subtitle.is-6(v-if="validity") {{validity}}
      p.subtitle.is-6 {{description}}
</template>

<script>
import {mapActions} from 'vuex'
import CouponTerms from './CouponTerms'
import SelectCouponModal from './SelectCouponModal'
import CouponMixin from './CouponMixin'

export default {
  name: 'CouponElement',
  components: {
    CouponTerms
  },
  mixins: [CouponMixin],
  props: {
    value: {type: String, default: undefined}
  },
  data() {
    return {
      coupon: undefined
    }
  },
  watch: {
    value() {
      return this.fetch()
    }
  },
  created() {
    return this.fetch()
  },
  methods: {
    ...mapActions('coupon', ['getById']),
    async fetch() {
      try {
        if (this.value) {
          this.coupon = await this.getById(this.value)
        }
      } catch (error) {
        this.$handleAPIError(error)
      }
    },
    changeCoupon() {
      this.$buefy.modal.open({
        parent: this,
        component: SelectCouponModal,
        width: 960,
        canCancel: ['outside', 'escape'],
        events: {
          success: coupon => {
            this.$emit('input', coupon._id)
            this.coupon = coupon
          }
        }
      })
    },
    remove() {
      this.coupon = undefined
      this.$emit('input', undefined)
    }
  }
}
</script>
