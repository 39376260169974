<template lang="pug">
  div.is-flex.is-flex-direction-column(style="width: 100%" )
    template(v-if="!customer")
      .p-4
        b-field(:label="noLabel? '' : $t('customer_select.search_help')")
          b-input(v-model="query" @keyup.native="searchCustomer" size="is-medium" :placeholder="$t('customer_select.search_client_placeholder')" icon="magnify" expanded ref="customerSearchInput" data-qa="customer-search")
      .px-4.pb-4.has-border-bottom
        .has-text-centered.py-3
          button.button.is-outlined.is-primary.is-rounded(@click="addNewCustomer")
            span.icon
              i.mdi.mdi-plus-circle-outline.mdi-24px
            span.is-size-6 {{ $t('customer_select.actions.add_new') }}
          
      div(style="flex: 1 1 auto; overflow: auto")
        template(v-if="!isFetching && customers.length")
          .px-4.py-3.hoverable(v-for="cust in customers" :key="cust._id" @click="selectCustomer(cust)")
            customer-tiny-preview(:value="cust" tags) 
        template(v-else-if="isFetching")
          .hero
            .hero-body
              h3.is-size-6.has-text-centered(v-t="'customer_select.searching'")
          em-loading(:active="true" :is-full-page="false")
        template(v-else-if="!isFetching && !query && !customers.length")
          .hero
            .hero-body
              h3.is-size-6.has-text-centered(v-t="'customer_select.no_customers'")
          
        template(v-else-if="!isFetching && !customers.length")
          .hero
            .hero-body
              h3.is-size-6.has-text-centered(v-t="'customer_select.no_results'")

    template(v-else)
      .p-4
        customer-tiny-preview(:value="customer" :closable="true" @close="removeSelection" :link="true")
      .px-4.pb-5
        Stats(:customer="customer" :blocks="statBlocks" )
      
        
</template>

<script>
import CustomersApi from '@/api/customers'
import debounce from 'lodash/debounce'
import CustomerCreateEditForm from '../customers/CustomerCreateEditForm'
import {mapGetters} from 'vuex'
import Stats from '../customers/Stats'

export default {
  name: 'CustomerSelect',
  components: {
    Stats
  },
  props: {
    value: {type: Object, default: undefined},
    size: {type: String, default: undefined},
    category: {type: String, default: undefined},
    placeholder: {type: String, default: 'e.g. Joanna Perkins'},
    expanded: {type: Boolean, default: undefined},
    noLabel: {type: Boolean, default: false},
    statBlocks: {type: Array, default: () => ['sales', 'loyalty', 'fav_services', 'fav_staff', 'notes']}
  },
  data() {
    return {
      customer: null,
      isFetching: false,
      customers: [],
      query: undefined,
      searchMode: false
    }
  },
  computed: {
    ...mapGetters('customers', ['customerById'])
  },
  watch: {
    value(val) {
      this.customer = val
    }
  },
  created() {
    this.customer = this.value
    if (!this.customer) {
      this.searchCustomer()
    }
  },
  mounted() {
    this.focus()
  },
  methods: {
    searchCustomer: debounce(async function() {
      this.isFetching = true
      try {
        const {body} = await CustomersApi.search({query: this.query, category: this.category})

        this.customers = body.data
      } catch (error) {
        this.customers = []
        this.$handleAPIError(error)
      }

      this.isFetching = false
    }, 300),
    selectCustomer(option) {
      this.$store.commit('customers/SET_CUSTOMER', option)
      this.customer = this.customerById(option._id)
      this.$emit('input', this.customer)
    },
    removeSelection() {
      this.customer = undefined
      this.$emit('input', this.customer)
      this.$nextTick(() => {
        this.focus()
      })
    },
    focus() {
      this.$refs.customerSearchInput.focus()
    },
    addNewCustomer() {
      this.$buefy.modal.open({
        parent: this,
        component: CustomerCreateEditForm,
        canCancel: ['escape'],
        fullScreen: true,
        hasModalCard: true,
        props: {
          oneOnly: true,
          initialPhone: (this.query && this.query.replace(/[^\d]/g, '')) || undefined
        },
        events: {
          success: this.selectCustomer
        }
      })
    }
  }
}
</script>
<style scoped>
.hoverable {
  cursor: pointer;
  transition: background-color 200ms ease-in-out;
}
.hoverable:hover {
  background: #f1f1f1;
}
</style>
