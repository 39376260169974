<template lang="pug">
  generic-modal(title="coupons.available_coupons_title" @close="$emit('close')")
    template(v-slot:footer)
      .field.is-grouped
        p.control
          button.button(@click="$emit('close')" v-t="'actions.cancel'")
    .columns.is-multiline
      .column.is-3
        .box.coupon-container
          .template-artwork()
          .text-container
            p.title.is-6.is-ellipsis {{$t('coupons.new_coupon')}}
            p.subtitle.is-6.is-ellipsis {{$t('coupons.add_new_coupon')}}
          p.control
            button.button.is-fullwidth.is-primary(@click="create()") {{$t('actions.create')}}
      .column.is-3(v-for="coupon in coupons")
        .box.coupon-container
          .template-artwork(:style="{'background-image' : `url(${coupon.artwork})`}")
          .text-container
            p.title.is-6.is-ellipsis {{coupon.title}}
            p.subtitle.is-6.is-ellipsis 
              coupon-terms(:coupon="coupon")
          p.control
            button.button.is-fullwidth.is-primary.is-outlined(@click="select(coupon)") {{$t('actions.select')}}
    br        
    
</template>

<script>
import {mapActions, mapGetters} from 'vuex'
import CouponTemplatesLibrary from './CouponTemplatesLibrary'
import CreateEditCoupon from './CreateEditCoupon'
import CouponTerms from './CouponTerms'

export default {
  name: 'SelectCouponModal',
  components: {
    CouponTerms
  },
  computed: {
    ...mapGetters('coupon', ['coupons'])
  },
  async created() {
    await this.fetchData()
  },
  methods: {
    ...mapActions('coupon', ['list']),
    async fetchData() {
      try {
        await this.list()
      } catch (error) {
        this.$handleAPIError(error)
      }
    },
    create() {
      this.$buefy.modal.open({
        parent: this,
        component: CouponTemplatesLibrary,
        canCancel: ['escape', 'outside'],
        events: {
          success: template => {
            this.$buefy.modal.open({
              parent: this,
              component: CreateEditCoupon,
              fullScreen: true,
              props: {template},
              hasModalCard: true,
              canCancel: ['escape'],
              events: {
                success: coupon => {
                  this.$emit('success', coupon)
                  this.$emit('close')
                }
              }
            })
          }
        }
      })
    },
    cancel() {
      this.$emit('close')
    },
    select(coupon) {
      this.$emit('success', coupon)
      this.$emit('close')
    }
  }
}
</script>

<style scoped>
.coupon-container {
  padding: 1rem;
}
.template-artwork {
  margin-bottom: 10px;
  width: 100%;
  padding-top: 100%;
  background-size: cover;
  background-position: center center;
  border-radius: 16px;
  border: 1px solid #dedede;
  background-color: #f1f1f1;
}
.text-container {
  padding: 0 0.5rem;
  margin-bottom: 0.5rem;
}
</style>
