const dayjs = require('dayjs')

class Formatter {
  formatTime(value, timeFormat, timezone) {
    if (typeof value === 'number') {
      let hours = parseInt(value / 60)
      let minutes = value % 60
      minutes = minutes < 10 ? '0' + minutes : minutes
      if (timeFormat === '12') {
        let period = hours >= 12 ? 'pm' : 'am'
        hours = hours % 12
        hours = hours === 0 ? 12 : hours
        return `${hours}:${minutes}${period}`
      }
      return `${hours}:${minutes}`
    }

    if (timezone) {
      return dayjs(value)
        .tz(timezone)
        .format(timeFormat === '12' ? 'h:mma' : 'H:mm')
    }
    return dayjs(value).format(timeFormat === '12' ? 'h:mma' : 'H:mm')
  }
}

module.exports = new Formatter()
