import Navbar from '@/components/Navbar'

import Menu from '@/components/company/Menu'
import Container from '@/components/company/Container'

import StaffMembers from '@/components/staff/StaffMembers'
import Permissions from '@/components/staff/Permissions'
import StaffShifts from '@/components/staff/Shifts'
import Resources from '@/components/resources/Resources'
import ResourceSchedules from '@/components/resources/Schedules'
import Forms from '@/components/forms/Forms'
import Services from '@/components/services/Services'
import ServicesBulkEdit from '@/components/services/ServicesBulkEdit'
import ServicesHSCodeEdit from '@/components/services/ServicesHSCodeEdit'
import BusinessLocations from '@/components/settings/BusinessLocations'

export default [
  {
    path: '/company',
    name: 'Company',
    meta: {
      requiresAuth: true
    },
    components: {
      default: Container,
      navbar: Navbar,
      menu: Menu
    },
    children: [
      {
        path: 'team',
        component: StaffMembers,
        meta: {
          title: 'company_data.title',
          pageTitle: 'staff.title_members',
          requiresAuth: true
        }
      },
      {
        path: 'team/shifts',
        component: StaffShifts,
        meta: {
          title: 'company_data.title',
          requiresAuth: true
        }
      },
      {
        path: 'team/permissions',
        component: Permissions,
        meta: {
          title: 'company_data.title',
          requiresAuth: true
        }
      },
      {
        path: 'resources',
        component: Resources,
        meta: {
          title: 'company_data.title',
          pageTitle: 'resources_settings.title',
          requiresAuth: true
        }
      },
      {
        path: 'resources/schedules',
        component: ResourceSchedules,
        meta: {
          title: 'company_data.title',
          requiresAuth: true
        }
      },
      {
        path: 'forms',
        component: Forms,
        meta: {
          title: 'forms.title',
          pageTitle: 'forms.title',
          requiresAuth: true
        }
      },
      {
        path: 'services',
        component: Services,
        meta: {
          title: 'company_data.title',
          pageTitle: 'services.title',
          requiresAuth: true
        }
      },
      {
        path: 'services/edit',
        component: ServicesBulkEdit,
        meta: {
          title: 'company_data.title',
          requiresAuth: true
        }
      },
      {
        path: 'services/hsCode',
        component: ServicesHSCodeEdit,
        meta: {
          title: 'company_data.title',
          requiresAuth: true
        }
      },

      {
        path: 'locations',
        component: BusinessLocations,
        meta: {
          title: 'company_data.title',
          pageTitle: 'locations_settings.title',
          requiresAuth: true
        }
      }
    ]
  }
]
