<template lang="pug">
div
  screen-title(title="vouchers.title")
  b-field(grouped)    
    b-field
      PeriodSelector(v-model="period")
    b-field
      LocationSelector(v-model="location")
  .columns
    .column
      form(v-on:submit.prevent="onSearch")
        button.is-hidden(type="submit")
        b-field(expanded)
          b-input(v-model="queryString" ref="search" expanded icon="magnify" :placeholder="$t('vouchers.search_placeholder')" icon-right="close-circle-outline"
            icon-right-clickable
            @icon-right-click="clearSearch")
            
  .box
      b-table(
        :data="vouchers"
        :loading="searching" 
        class="is-vertically-aligned" 
        custom-row-key="_id"
        paginated
        backend-pagination
        :total="total"
        :per-page="perPage"
        @page-change="onPageChange"

        backend-sorting
        :default-sort-direction="defaultSortOrder"
        :default-sort="[sortField, sortOrder]"
        @sort="onSort"
        hoverable=true 
        @click="openDetails")
        b-table-column(field="name" :label="$t('vouchers.name_heading')" v-slot="props" sortable)
          span {{props.row.name}}
        b-table-column(field="code" :label="$t('vouchers.code_heading')" v-slot="props" sortable)
          b {{props.row.code}}
        b-table-column(field="invoice" :label="$t('vouchers.invoice_heading')" v-slot="props" sortable)
          invoice-view(:value="props.row.sale" :invoice="props.row.invoice")
        
        b-table-column(field="status" :label="$t('vouchers.status_heading')" v-slot="props" centered)
          .tag.is-warning(v-if="props.row.remaining === 0") {{$t('validity.used')}}
          .tag.is-danger(v-else-if="new Date(props.row.expire) < new Date()") {{$t('validity.expired')}}
          .tag.is-success(v-else) {{$t('validity.valid')}}
        b-table-column(field="value" :label="$t('vouchers.value_heading')" v-slot="props" sortable numeric)
          currency-view(:value="props.row.value")
        b-table-column(field="remaining" :label="$t('vouchers.remaining_heading')" v-slot="props" sortable numeric)
          b
            currency-view(:value="props.row.remaining")
        b-table-column(field="eligible" :label="$t('vouchers.eligible_heading')" v-slot="props" )
          span(v-if="props.row.services.length") {{$tc('vouchers.number_of_services', props.row.services.length)}}
          span(v-else)  -  
        b-table-column(field="expire" :label="$t('vouchers.expire_heading')" v-slot="props" sortable numeric)
          date-view(:value="props.row.expire" format="L")
        b-table-column(field="location" :label="$t('vouchers.location_heading')" v-slot="props" sortable :visible="hasMultipleLocations")
          location-view(:value="props.row.location")
        b-table-column(field="created" :label="$t('vouchers.created_heading')" v-slot="props" sortable numeric)
          date-view(:value="props.row.created" format="L LT")

        template(slot="empty")
          empty-content(v-if="!queryString && !searching" title="vouchers.no_vouchers" )
          empty-content(v-else-if="!searching" title="vouchers.search_no_results" icon="magnify-remove-outline")
            
</template>
<script>
import VouchersApi from '@/api/vouchers'

import PeriodSelector from '../helpers/PeriodSelector'
import LocationSelector from '../helpers/LocationSelector'
import {mapGetters} from 'vuex'
import VoucherDetails from './VoucherDetails.vue'
export default {
  name: 'VouchersList',
  components: {
    PeriodSelector,
    LocationSelector
  },
  data() {
    return {
      vouchers: [],
      total: 0,
      sortField: 'created',
      sortOrder: 'desc',
      page: 1,
      perPage: 10,
      defaultSortOrder: 'desc',
      queryString: null,
      searching: false,
      period: undefined,
      location: undefined
    }
  },
  computed: {
    ...mapGetters('location', ['hasMultipleLocations'])
  },
  watch: {
    period() {
      this.fetchData()
    },
    location() {
      this.fetchData()
    }
  },

  created() {
    this.$barcodeScanner.init(this.onBarcodeScanned)
    this.fetchData()
  },
  destroyed() {
    this.$barcodeScanner.destroy()
  },
  methods: {
    async fetchData() {
      if (!this.period) {
        return
      }
      this.searching = true
      try {
        const {total, data} = await VouchersApi.search({
          code: this.queryString,
          sortBy: this.sortField,
          sortDir: this.sortOrder,
          page: this.page,
          perPage: this.perPage,
          from: this.period.from,
          to: this.period.to,
          location: this.location
        })
        this.vouchers = data
        this.total = total
      } catch (err) {
        this.$handleAPIError(err)
      }
      this.searching = false
    },
    onSort(field, order) {
      this.sortField = field
      this.sortOrder = order
      this.fetchData()
    },
    onPageChange(page) {
      this.page = page
      this.fetchData()
    },
    onSearch() {
      this.page = 1
      this.fetchData()
    },
    clearSearch() {
      this.queryString = undefined
      this.fetchData()
    },
    onBarcodeScanned(barcode) {
      this.queryString = barcode
      this.onSearch()
    },

    openDetails(voucher) {
      this.$buefy.modal.open({
        parent: this,
        props: {value: voucher},
        hasModalCard: true,
        fullScreen: this.isMobile(),
        component: VoucherDetails,
        events: {
          change: () => {
            this.fetchData()
          }
        },
        canCancel: ['outside', 'escape', 'x']
      })
    }
  }
}
</script>
