import Vue from 'vue'

export default {
  getResources() {
    return Vue.http.get('resources').then(res => res.body)
  },
  updateResource(id, data) {
    return Vue.http.put(`resources/${id}`, data).then(res => res.body)
  },
  createResource(data) {
    return Vue.http.post('resources', data).then(res => res.body)
  },
  getResourceById(id) {
    return Vue.http.get(`resources/${id}`).then(res => res.body)
  },
  updateOrder(data) {
    return Vue.http.put(`resources/order`, data)
  },
  getSchedules(params) {
    return Vue.http.get(`resources/schedules`, {params}).then(res => res.data)
  },
  getScheduleRanges(params) {
    return Vue.http.get(`resources/scheduleRanges`, {params}).then(res => res.data)
  },
  setResourceSchedule(id, data) {
    return Vue.http.post(`resources/schedules/${id}`, data).then(res => res.data)
  },
  removeResourceSchedule(id, day) {
    return Vue.http.delete(`resources/schedules/${id}/${day}`).then(res => res.data)
  }
}
