<template lang="pug">
CurrencyViewNoState(:value="value" :currency="company.currency" :unit="unit")
</template>

<script>
import {mapState} from 'vuex'
import CurrencyViewNoState from './CurrencyViewNoState'
export default {
  components: {
    CurrencyViewNoState
  },
  props: {
    value: {type: Number, default: undefined},
    unit: {type: String, default: undefined}
  },
  computed: {
    ...mapState('company', ['company'])
  }
}
</script>
