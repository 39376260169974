export default {
  RED: 'rgb(255, 99, 132)',
  ORANGE: 'rgb(255, 159, 64)',
  ORANGE_TR: '#ff9f3f88',
  YELLOW: 'rgb(255, 205, 86)',
  GREEN: '#3bbf09',
  GREEN_TR: '#3bbf0944',
  BLUE: '#3298dc',
  BLUE_TR: '#3298dc88',
  PURPLE: 'rgb(153, 102, 255)',
  GREY: 'rgb(201, 203, 207)',
  TRANSPARENT: 'rgba(255, 255, 255, 0)'
}
