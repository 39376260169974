<template lang="pug">
  .modal-card
    header.modal-card-head
      p.modal-card-title(v-if="action === 'open'") 
        b {{ $t('ledger.open_register_title') }}
      p.modal-card-title(v-else) 
        b {{ $t('ledger.close_register_title') }}
      div
        a.button.is-white.is-small(@click="$emit('close')")
          span.icon
            i.mdi.mdi-close.mdi-24px
    section.modal-card-body
      b-field(:label="$t('ledger.current_cash_label')" :type="errors.has('actual') ? 'is-danger': ''" :message="errors.has('actual') ? errors.first('actual') : ''")
        b-field
          p.control
            span.button.is-static 
              currency
          b-input(v-model.number="actual" ref="amount" expanded v-validate="'required'" name="actual" :placeholder="$t('ledger.cash_remainder_placeholder')")
      note(type="is-warning") 
        p(v-t="'ledger.transaction_note'")
    footer.modal-card-foot
      .buttons
        action-button.is-success(v-if="action === 'open'" @click="submit" title="actions.open" icon="cash-register" :loading="loading")
        action-button.is-danger(v-else @click="submit" title="actions.close" icon="cash-register" :loading="loading")
        button.button.is-outlined(type="button" @click="$emit('close')" ) {{$t('actions.close')}}      
</template>

<script>
import {mapActions, mapState} from 'vuex'

export default {
  data() {
    return {
      actual: undefined,
      loading: false
    }
  },
  computed: {
    ...mapState('ledger', ['session']),
    action() {
      return this.session && !this.session.close ? 'close' : 'open'
    }
  },
  mounted() {
    this.$refs.amount.focus()
  },
  methods: {
    ...mapActions('ledger', ['openRegister', 'closeRegister']),
    async submit() {
      let valid = await this.$validator.validateAll()
      if (!valid) {
        return
      }
      if (this.loading) {
        return
      }
      this.loading = true
      try {
        if (this.action === 'open') {
          await this.openRegister(this.actual)
        } else {
          await this.closeRegister(this.actual)
        }
        this.$emit('close')
        this.$emit('success')
      } catch (err) {
        this.$handleAPIError(err)
      }
      this.loading = false
    }
  }
}
</script>
