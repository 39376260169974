<script>
export default {
  methods: {
    formatDuration(value, granulize) {
      if (value === undefined) {
        return ''
      }
      let days = parseInt(value / 1440)
      let hours = granulize ? parseInt((value % 1440) / 60) : parseInt(value / 60)
      let mins = value % 60
      let res = []
      if (days && granulize) {
        res.push(days + this.$t('misc.day_symbol'))
      }
      if (hours) {
        res.push(hours + this.$t('misc.hour_symbol'))
      }
      if (mins) {
        res.push(mins + this.$t('misc.min_symbol'))
      }
      if (!res.length) {
        res.push('0' + this.$t('misc.min_symbol'))
      }
      return res.join(' ')
    }
  }
}
</script>
