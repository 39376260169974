<template lang="pug">
.modal-card
  header.modal-card-head
    p.modal-card-title(data-qa="modal-header") 
      b(v-t="'sale.checkout_title'")
      br
      b
        location-select.is-size-6(v-model="location" :dropdown="false") 
    div
      a.button.is-white.is-small(@click="$emit('close')")
        span.icon
          i.mdi.mdi-close.mdi-24px
  section.modal-card-body.is-height-100.p-0
    em-loading(:active="saving" :is-full-page="true")
    .columns.is-height-100.is-marginless
      .column.is-9.is-height-100.is-flex.is-flex-direction-column.is-paddingless(style="background: #f1f1f1")
        .columns.is-centered.is-marginless
          .column.is-8.is-paddingless.pt-3
            .box.is-radiusless.is-clickable.p-3(v-if="customer" style="position: sticky; top: 0; z-index: 10;" @click="openCustomerSelectModal")
              customer-tiny-preview(:value="customer" :closable="editable" @close="removeCustomer()")
            .box.is-radiusless.is-clickable.p-3.has-text-centered(v-else-if="editable" style="position: sticky; top: 0; z-index: 10;" @click="openCustomerSelectModal") 
                button.button.is-outlined.is-primary.is-rounded.is-medium
                  span.icon
                    i.mdi.mdi-account-circle-outline.mdi-24px
                  span.is-size-5 {{ $t('actions.select_customer') }}
            .mb-5(v-if="memberships && memberships.length")
              h4.title.is-6.mb-2 {{$t('memberships.customer_memberships')}}
              .tags
                MembershipNameView(:value="membership" v-for="membership in memberships" :key="membership._id")  
            note.is-outlined.mb-4(type="is-info" icon="clipboard-edit-outline" v-if="notes" size="small") {{notes}}
            .p-5.has-text-centered(v-if="!saleItems.length")
              span.icon.payment-check
                i.mdi.mdi-cart-minus
              
              p.is-size-5.has-text-centered(v-t="'sale.empty_cart'")
            SaleItemEdit(
              v-for="(saleItem, idx) in saleItems" 
              :key="saleItem._id" 
              v-model="saleItem.item" 
              @remove="removeItem(idx)" 
              :removable="editable" 
              :disabled="!editable"
              :locationId="location" 
              @click="currentEdit = saleItem._id" 
              :view-mode.sync="saleItem._id !== currentEdit"
              :class="isMobile() ? 'is-shadowless is-radiusless mb-1': ''"
              :customerCoupon="paymentData.customerCoupon"
              :saleItems="items"
              :memberships="memberships"
              :collapsed="isMobile() && !!idx"
              )
            .columns.mt-4.is-multiline.is-mobile.is-paddingless.is-marginless(v-if="editable")
              .column.is-6-mobile  
                .item-button(@click="addService" :class="mode === INVOICE_MODES.DEPOSIT ? 'disabled':''")  
                  img(:src="require('../../assets/clipboard.png')")
                  p.heading.is-size-7-mobile
                    b(v-t="'sale_item_types.service'")
              .column.is-6-mobile  
                .item-button(@click="addProduct" :class="mode === INVOICE_MODES.DEPOSIT ? 'disabled':''") 
                  img(:src="require('../../assets/retail.png')")
                  p.heading.is-size-7-mobile
                    b(v-t="'sale_item_types.product'")
              .column.is-6-mobile  
                .item-button(@click="createVoucher" :class="mode === INVOICE_MODES.REGULAR ? 'disabled':''") 
                  img(:src="require('../../assets/voucher.png')")
                  p.heading.is-size-7-mobile
                    b(v-t="'sale_item_types.voucher'")
              .column.is-6-mobile
                .item-button(@click="addMembership" :class="mode === INVOICE_MODES.DEPOSIT ? 'disabled':''")  
                  img(:src="require('../../assets/membership.png')")
                  p.heading.is-size-7-mobile
                    b(v-t="'sale_item_types.membership'")
              

      scroll-bar.column.is-3.is-height-100.has-white-background.has-border-left.is-hidden-mobile(v-if="!isMobile()")
        .section(style="padding: 1rem")
          PaymentCollect(:customer="customer" @paid="checkAppointmentDate" :saleItems="items" :totals="totals" v-model="paymentData" ref="paymentCollector")
          
      b-modal(v-model="paymentModal" :full-screen="true" v-else :has-modal-card="true")
        .modal-card
          header.modal-card-head
            div.mr-5
              a.button.is-white.is-small(@click="paymentModal = false")
                span.icon
                  i.mdi.mdi-chevron-left.mdi-24px
            p.modal-card-title
              b(v-t="'sales.payment_modal_title'")
            
          section.modal-card-body
            PaymentCollect(:customer="customer" @paid="checkAppointmentDate" :saleItems="items" :totals="totals" v-model="paymentData" ref="paymentCollector")
          footer.modal-card-foot
            button.button(@click="paymentModal = false") {{$t('actions.back')}} 
  footer.modal-card-foot(v-if="isMobile()")
      button.button.is-dark.is-medium.is-fullwidth(@click="openPaymentCollectModal" :disabled="saleItems.length === 0") 
        b {{$t('sale.actions.charge')}} 
          currency-view(:value="totals.total")    
</template>

<script>
import {mapActions, mapState} from 'vuex'
import PaymentCollect from './PaymentCollect'
import SALE_ITEM_TYPES from '../../../constants/saleItemTypes'
import SaleItemEdit from './SaleItemEdit'
import SaleDetails from './SaleDetails'

import ServiceSelectModal from '../services/ServiceSelectModal'
import MembershipsApi from '@/api/memberships'
import CustomerSelectModalWrapper from '../shared/CustomerSelectModalWrapper'
import saleHelper from '../../../shared/saleHelper'
import dayjs from 'dayjs'
import CreateVoucher from '../voucher/CreateVoucher'
import MembershipNameView from '../membership/MembershipNameView'
import MembershipPackageSelectVue from '../membership/MembershipPackageSelect'
import PAYMENT_TYPES from '../../../constants/paymentTypes'
import INVOICE_MODES from '../../../constants/invoiceModes'
import SALE_STATUS from '../../../constants/saleStatus'

import cardPaymentStatus from '../../../constants/cardPaymentStatus'
import ProductSelectModal from '../inventory/ProductSelectModal'

import cloneDeep from 'lodash/cloneDeep'
export default {
  name: 'Checkout',
  components: {
    SaleItemEdit,
    PaymentCollect,
    MembershipNameView
  },
  props: {
    saleId: {type: String, default: undefined},
    appointmentId: {type: String, default: undefined},
    customerId: {type: String, default: undefined},
    locationId: {type: String, default: undefined}
  },
  data() {
    return {
      currentEdit: undefined,
      itemId: 0,
      customer: undefined,
      saleItems: [],
      paymentData: {payments: []},
      location: undefined,
      saving: false,
      sale: undefined,
      paymentModal: false,
      memberships: undefined,
      INVOICE_MODES,
      notes: undefined,
      appointmentEnd: undefined
    }
  },
  computed: {
    ...mapState('company', ['company']),
    ...mapState('auth', ['user']),
    totals() {
      return saleHelper.recalculateTotals({
        items: this.items,
        payments: this.paymentData.payments,
        priceIncludesTax: this.company.priceIncludesTax,
        customerCoupon: this.paymentData.customerCoupon
      })
    },
    items() {
      return this.saleItems.map(t => t.item)
    },
    editable() {
      return !this.saleId || (this.sale && this.sale.status === SALE_STATUS.VOID)
    },
    mode() {
      if (!this.company.haysell || !this.company.haysell.profileId) {
        return
      }
      if (!this.items.length) {
        return
      }
      let nonVouchers = this.items.filter(i => i.type !== SALE_ITEM_TYPES.VOUCHER)
      if (nonVouchers.length) {
        return INVOICE_MODES.REGULAR
      }
      return INVOICE_MODES.DEPOSIT
    }
  },
  watch: {
    async customer() {
      await this.fetchCustomerMemberships()
    }
  },
  async created() {
    let items = []
    if (this.appointmentId) {
      this.appointment = await this.fetchAppointmentById({id: this.appointmentId})
      if (this.appointment.customer) {
        this.customer = await this.fetchCustomer(this.appointment.customer)
      }
      items = this.appointment.services.map(s => {
        return {...s, type: SALE_ITEM_TYPES.SERVICE}
      })
      this.location = this.appointment.location
      this.notes = this.appointment.notes
      this.processAppointmentEnd()
      if (this.appointment.payment) {
        let deposit = await this.fetchPaymentById(this.appointment.payment)
        if (deposit.status === cardPaymentStatus.SUCCESS) {
          this.paymentData = {
            payments: [
              {
                type: PAYMENT_TYPES.DEPOSIT,
                payment: this.paymentId,
                paid: deposit.amount
              }
            ]
          }
        }
      }
    } else if (this.saleId) {
      let sale = await this.fetchSaleById({id: this.saleId, force: true})

      this.sale = cloneDeep(sale)
      if (this.editable && this.sale.appointment) {
        this.appointment = await this.fetchAppointmentById({id: this.sale.appointment})
        this.processAppointmentEnd()
      }
      this.location = this.sale.location
      if (this.sale.customer) {
        this.customer = await this.fetchCustomer(this.sale.customer)
      }
      let paymentData = {payments: []}
      if (this.sale.customerCoupon) {
        paymentData.customerCoupon = await this.getCustomerCouponById(this.value)
      }
      paymentData.customerCoupon = this.sale.customerCoupon
      items = this.sale.items
      paymentData.notes = this.sale.notes
      if (this.editable) {
        paymentData.payments = this.sale.payments
      }
      this.paymentData = paymentData
    } else {
      if (this.customerId) {
        this.customer = await this.fetchCustomer(this.customerId)
      }
      this.location = this.locationId
    }
    this.saleItems = items
      .filter(i => {
        if (!this.editable) {
          return true
        }
        if (i.type === SALE_ITEM_TYPES.VOUCHER && !i.voucherData) {
          return false
        }
        return true
      })
      .map(s => {
        return {
          item: {
            product: s.product,
            service: s.service,
            option: s.option,
            voucher: s.voucher,
            membershipPackage: s.membershipPackage,
            voucherData: s.voucherData,
            type: s.type,
            qty: s.qty || 1,
            staff: (s.staff && s.staff._id) || s.staff,
            resource: s.resource,
            preferred: s.preferred,
            preferredResource: s.preferredResource,

            customerCoupon: s.customerCoupon,
            membership: s.membership,

            price: s.price,
            subtotal: s.subtotal,
            discount: s.discount,

            itemDiscount: s.itemDiscount,
            manualDiscount: s.manualDiscount,
            couponDiscount: s.couponDiscount,
            membershipDiscount: s.membershipDiscount,
            tax: s.tax,
            commission: s.commission,
            total: s.total,
            rewardsBase: s.rewardsBase,

            manualPrice: s.price + (s.priceAdjustment || 0),
            duration: s.duration,

            blockedTimeAfter: s.blockedTimeAfter,
            processingTimeAfter: s.processingTimeAfter
          },
          _id: s._id
        }
      })
    if (this.editable) {
      this.$barcodeScanner.init(this.onBarcodeScanned)
    }
  },
  destroyed() {
    if (this.editable) {
      this.$barcodeScanner.destroy()
    }
  },
  mounted() {
    if (!this.isMobile() && this.saleItems.length) {
      this.$refs.paymentCollector.focus()
    }
  },
  methods: {
    ...mapActions('customers', ['fetchCustomer']),
    ...mapActions('sales', ['checkout', 'fetchSaleById']),
    ...mapActions('customerCoupon', ['getCustomerCouponById']),
    ...mapActions('payments', ['fetchPaymentById']),
    ...mapActions('appointments', ['fetchAppointmentById']),

    processAppointmentEnd() {
      this.appointmentEnd = dayjs(this.appointment.start)
        .add(this.appointment.duration, 'minutes')
        .toDate()
    },
    checkAppointmentDate({printFiscal, rewardsBase}) {
      const appointmentEndDay =
        (this.appointmentEnd && parseInt(dayjs(this.appointmentEnd).format('YYYYMMDD'))) || undefined
      const today = parseInt(dayjs().format('YYYYMMDD'))
      if (this.editable && appointmentEndDay && appointmentEndDay !== today) {
        this.$buefy.dialog.confirm({
          message: this.$t('sale.confirm_invoice_date.body', [dayjs(this.appointmentEnd).format('LL')]),
          title: this.$t('sale.confirm_invoice_date.title'),
          cancelText: this.$t('sale.confirm_invoice_date.cancel'),
          confirmText: this.$t('sale.confirm_invoice_date.confirm'),
          canCancel: ['button'],
          type: 'is-black',
          onConfirm: () => {
            this.onPaid({printFiscal, rewardsBase})
          },
          onCancel: () => {
            this.onPaid({invoiceDate: this.appointmentEnd, printFiscal, rewardsBase})
          }
        })
      } else {
        this.onPaid({printFiscal, rewardsBase})
      }
    },
    async onPaid({invoiceDate, printFiscal, rewardsBase}) {
      try {
        let totals = this.totals
        let data = {
          _id: !this.editable ? this.saleId : undefined,
          customer: this.customer && this.customer._id,
          appointment: (this.sale && this.sale.appointment) || this.appointmentId,
          items: this.saleItems.map(s => {
            return {...s.item}
          }),
          payments: this.paymentData.payments,
          customerCoupon: this.paymentData.customerCoupon && this.paymentData.customerCoupon._id,
          total: totals.total,
          balance: totals.balance,
          paid: totals.paid,
          subtotal: totals.subtotal,
          discount: totals.discount,
          tax: totals.tax,
          discounts: totals.discounts,
          taxes: totals.taxes,
          location: this.location,
          notes: this.paymentData.notes,
          printFiscal,
          mode: this.mode,
          rewardsBase,
          created: invoiceDate
        }

        if (!data.items.length) {
          this.$buefy.toast.open({message: this.$t('sale.no_sale_items'), type: 'is-warning', queue: false})
          return
        }
        if (this.saving) {
          return
        }
        this.saving = true
        let sale = await this.checkout(data)

        this.$buefy.toast.open({
          message: this.$t('sale.save_success'),
          type: 'is-success',
          queue: false
        })
        if (data.payments.find(p => p.type === PAYMENT_TYPES.LINK_PAY)) {
          this.$buefy.toast.open({
            message: this.$t('sale.payment_link_pay_sent'),
            type: 'is-success',
            queue: false,
            duration: 5000
          })
        }

        this.$emit('close')
        this.showDetails(sale._id)
      } catch (err) {
        this.$handleAPIError(err)
      }
      this.saving = false
    },

    showDetails(saleId) {
      this.$buefy.modal.open({
        parent: this.$parent,
        props: {saleId, showRebookPrompt: true},
        component: SaleDetails,
        fullScreen: true,
        hasModalCard: true,
        canCancel: ['escape', 'outside']
      })
    },
    removeItem(idx) {
      this.saleItems.splice(idx, 1)
      this.paymentData = {payments: []}
    },
    createVoucher() {
      if (this.mode === INVOICE_MODES.REGULAR) {
        return
      }
      this.$buefy.modal.open({
        parent: this,
        component: CreateVoucher,
        hasModalCard: true,
        fullScreen: this.isMobile(),
        events: {
          input: this.onVoucherSelect
        },
        canCancel: ['escape', 'outside']
      })
    },
    onVoucherSelect({name, notes, code, value, price, validity, validityUnit, services}) {
      this.itemId += 1
      this.saleItems.push({
        item: {
          type: SALE_ITEM_TYPES.VOUCHER,
          qty: 1,
          voucherData: {
            name,
            notes,
            code,
            validity,
            validityUnit,
            value,
            price,
            services
          },
          staff: this.user._id,
          price,
          commission: 0,
          manualPrice: price,

          itemPriceBT: price,
          itemPrice: price,
          subtotal: price,

          discount: 0,
          couponDiscount: 0,
          itemDiscount: 0,
          manualDiscount: 0,
          tax: 0,
          total: price,
          rewardsBase: 0
        },
        _id: this.itemId
      })
    },
    onMembershipSelect(membershipPackage) {
      this.itemId += 1
      this.saleItems.push({
        item: {
          type: SALE_ITEM_TYPES.MEMBERSHIP,
          qty: 1,
          membershipPackage: membershipPackage._id,
          staff: this.user._id,
          price: membershipPackage.price,
          commission: 0,
          manualPrice: membershipPackage.price,

          itemPriceBT: membershipPackage.price,
          itemPrice: membershipPackage.price,
          subtotal: membershipPackage.price,
          discount: 0,
          couponDiscount: 0,
          manualDiscount: 0,
          tax: 0,
          total: membershipPackage.price,
          rewardsBase: 0
        },
        _id: this.itemId
      })
    },
    onServiceSelect({serviceId, optionId, price, duration}) {
      this.itemId += 1
      let suggestedStaffId =
        (this.saleItems.length && this.saleItems[this.saleItems.length - 1].item.staff) || undefined
      this.saleItems.push({
        item: {
          service: serviceId,
          option: optionId,
          type: SALE_ITEM_TYPES.SERVICE,
          price,
          manualPrice: price,
          duration,
          staff: suggestedStaffId,
          commission: 0,
          qty: 1,

          itemPriceBT: price,
          itemPrice: price,
          subtotal: price,
          itemDiscount: 0,
          discount: 0,
          couponDiscount: 0,
          manualDiscount: 0,
          tax: 0,
          total: price,
          rewardsBase: 0
        },
        _id: this.itemId
      })
    },
    onProductSelect(product) {
      let existing = this.saleItems.find(s => s.item.product === product._id)
      if (existing) {
        existing.item = {...existing.item, qty: existing.item.qty + 1}
      } else {
        let suggestedStaffId =
          (this.saleItems.length && this.saleItems[this.saleItems.length - 1].item.staff) || undefined
        this.itemId += 1
        this.saleItems.push({
          item: {
            product: product._id,
            type: SALE_ITEM_TYPES.PRODUCT,
            qty: 1,
            price: product.retailPrice,
            manualPrice: product.retailPrice,
            itemPriceBT: product.retailPrice,
            itemPrice: product.retailPrice,
            subtotal: product.retailPrice,
            itemDiscount: 0,
            discount: 0,
            staff: suggestedStaffId,
            commission: 0,
            couponDiscount: 0,
            manualDiscount: 0,
            tax: 0,
            total: product.retailPrice,
            rewardsBase: 0
          },
          _id: this.itemId
        })
      }
      this.$buefy.toast.open({message: this.$t('inventory.added_to_cart'), type: 'is-success', queue: false})
    },

    openCustomerSelectModal() {
      this.$buefy.modal.open({
        parent: this,
        hasModalCard: true,
        fullScreen: this.isMobile(),
        component: CustomerSelectModalWrapper,
        events: {
          input: customer => {
            this.customer = customer
          }
        },
        canCancel: ['escape']
      })
    },
    removeCustomer() {
      this.customer = undefined
      this.saleItems = this.saleItems.filter(s => s.item.type !== SALE_ITEM_TYPES.MEMBERSHIP)
    },
    async fetchCustomerMemberships() {
      if (!this.customer) {
        this.memberships = undefined
        return
      }
      this.memberships = await MembershipsApi.getCustomerMemberships(this.customer._id)
      if (this.sale) {
        this.memberships = this.memberships.filter(m => new Date(m.created) < new Date(this.sale.created))
      }
    },
    openPaymentCollectModal() {
      this.paymentModal = true
    },
    ...mapActions('inventory', ['fetchProductByBarcode']),
    async onBarcodeScanned(barcode) {
      let product = await this.fetchProductByBarcode(barcode)
      if (!product) {
        this.$buefy.toast.open({message: this.$t('sale.barcode_not_found'), type: 'is-warning', queue: false})
        return
      }
      this.onProductSelect(product)
    },
    addProduct() {
      if (this.mode === INVOICE_MODES.DEPOSIT) {
        return
      }
      return this.$buefy.modal.open({
        parent: this,
        fullScreen: this.isMobile(),
        hasModalCard: true,
        component: ProductSelectModal,
        props: {locationId: this.location},
        width: 960,
        canCancel: ['escape'],
        events: {
          input: this.onProductSelect
        }
      })
    },
    addService() {
      if (this.mode === INVOICE_MODES.DEPOSIT) {
        return
      }
      return this.$buefy.modal.open({
        parent: this,
        hasModalCard: true,
        component: ServiceSelectModal,
        width: 960,
        fullScreen: this.isMobile(),
        canCancel: ['escape'],
        events: {
          input: this.onServiceSelect
        }
      })
    },
    addMembership() {
      if (this.mode === INVOICE_MODES.DEPOSIT) {
        return
      }
      if (!this.customer) {
        this.$buefy.dialog.alert({
          title: this.$t('memberships.customer_required.title'),
          message: this.$t('memberships.customer_required.body'),
          type: 'is-danger',
          trapFocus: true
        })
        return
      }
      return this.$buefy.modal.open({
        parent: this,
        hasModalCard: true,
        component: MembershipPackageSelectVue,
        width: 960,
        fullScreen: this.isMobile(),
        canCancel: ['escape'],
        events: {
          input: this.onMembershipSelect
        }
      })
    }
  }
}
</script>

<style scoped>
.payment-check {
  font-size: 4rem;
  height: 4rem;
}
.right-panel {
  display: flex;
  flex-direction: column;
  padding: 1.5rem;
}
.timeline .timeline-item {
  padding-bottom: 0;
}
.table tfoot.is-borderless td,
.table tfoot.is-borderless th {
  border: none;
}
</style>
