import Rollbar from 'vue-rollbar'

export default {
  install(Vue) {
    if (process.env.NODE_ENV === 'production') {
      Vue.use(Rollbar, {
        accessToken: '70751f87bd95467d91a5dd4f50f27ed8',
        captureUncaught: true,
        captureUnhandledRejections: true,
        enabled: true,
        payload: {
          environment: process.env.NODE_ENV
        },
        environment: process.env.NODE_ENV
      })
    }
    Vue.config.errorHandler = function(err) {
      if (process.env.NODE_ENV === 'production') {
        Vue.rollbar.error(err)
      } else {
        console.error(err)
      }
    }
    Vue.prototype.$logWarning = function(err, params) {
      Vue.rollbar.warning(err, params)
    }
    Vue.prototype.$handleAPIError = function(err) {
      console.log(err)
      let user = {}
      if (this.$store.state.auth.token) {
        let company = this.$store.state.company.company
        let userState = this.$store.state.auth.user
        user = {
          id: company && company._id,
          name: company && company.name,
          user_id: userState && userState._id,
          user_email: userState && userState.email
        }
      }
      if (process.env.NODE_ENV === 'production') {
        Vue.rollbar.error(err, {user})
      }
      if (!err) {
        console.log('unknown error')
      } else if (err.status === 500) {
        this.$buefy.toast.open({
          duration: 5000,
          position: 'is-bottom',
          message: this.$t('error.500'),
          type: 'is-danger',
          queue: false
        })
      } else if (err.status === 400) {
        let errorKey = err.body.message || err.body.code || err.body.error
        this.$buefy.toast.open({
          duration: 5000,
          position: 'is-bottom',
          message:
            (errorKey && this.$te(`error.${errorKey}`) && this.$t(`error.${errorKey}`)) ||
            errorKey ||
            this.$t('error.400'),
          type: 'is-danger',
          queue: false
        })
      } else if (err.message) {
        this.$buefy.toast.open({
          duration: 5000,
          position: 'is-bottom',
          message: `Oops... ${err.message}`,
          type: 'is-danger',
          queue: false
        })
      }
    }
  }
}
