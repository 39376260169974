<template lang="pug">
  navigation-menu(:items="items")
</template>

<script>
import NavigationMenu from '../shared/NavigationMenu'
import {PRODUCTS} from '../../../constants/products'
export default {
  components: {
    NavigationMenu
  },
  data() {
    return {
      items: [
        {path: '/sales/list', icon: 'format-list-checks', title: 'sale.sales_title'},
        {path: '/sales/appointments', icon: 'calendar', title: 'appointments.list_title'},
        {
          path: '/sales/payments',
          icon: 'credit-card-outline',
          title: 'payment.payments_title',
          product: PRODUCTS.PAYMENTS
        },
        {
          path: '/sales/vouchers',
          icon: 'wallet-giftcard',
          title: 'vouchers.title'
        },
        {
          path: '/sales/memberships',
          icon: 'wallet-membership',
          title: 'memberships.memberships_title',
          //product: PRODUCTS.MEMBERSHIPS,
          nested: [
            {
              path: '/sales/memberships/packages',
              icon: 'card-plus-outline',
              title: 'memberships.membership_packages_title'
              //product: PRODUCTS.MEMBERSHIPS
            }
          ]
        }
      ]
    }
  }
}
</script>
