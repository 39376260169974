<template lang="pug">
  div(data-qa="pricing-options")
    .box(v-for="(option, idx) in options" :key="idx")
      h4.title.is-4 
        span(v-if="!option.name") {{$t('services.details.pricing_option', {count: idx + 1})}}
        span(v-else) {{$t('services.details.pricing_option_named', {name: option.name})}}
      //- p.subtitle.is-6.has-text-grey-dark(v-if="!option.override") Standard pricing
      //- p.subtitle.is-6.has-text-grey-dark(v-else) Advanced pricing
      button.delete(@click="removeOption(option)" v-if="options.length > 1")
      p.title.is-5.mb-2(v-if="option.override") {{ $t('services.details.default_pricing_title') }}
      b-field(grouped)
      
          b-field(:label="$t('services.details.option_duration_label')" expanded v-if="$rfSettings().services.duration")
            duration-input(v-model="option.duration" expanded data-qa="duration" @input="update")
        
          b-field(:label="$t('services.details.option_price_label')" expanded :type="errors.has('price' + idx) || errors.has('priceUpto' + idx) ? 'is-danger': ''" :message="errors.has('price' + idx) || errors.has('priceUpto' + idx) ? errors.first('price' + idx) || errors.first('priceUpto' + idx) : ''")
            b-field
              p.control
                span.button.is-static 
                  currency
              b-input(v-model.number="option.price" @input="update" expanded type="number" data-vv-as="price" :name="'price' + idx" numeric v-validate="'required|decimal:2|min_value:0'" :use-html5-validation="false" data-qa="price-up")
              p.control
                span.button.is-static -
              b-input(v-model.number="option.priceUpto" @input="update" expanded type="number" data-vv-as="up to price" :name="'priceUpto' + idx" numeric v-validate="'decimal:2|min_value:0'" :use-html5-validation="false" :disabled="!option.price" :placeholder="$t('services.details.option_price_upto_placeholder')" data-qa="price-to")
        
          b-field(:label="$t('services.details.option_name_label')" expanded)
            b-input(v-model="option.name" @input="update" :placeholder="$t('services.details.option_name_placeholder')" data-qa="option-name")
      
          //b-field(label="‏‏‎ ‎")
            //a.button.is-white.is-small(@click="removeOption(option)" v-if="options.length > 1")
              span.icon.has-text-danger
                i.mdi.mdi-close.mdi-24px
            //button.delete(@click="removeOption(option)" v-if="options.length > 1")
      //hr.is-narrow(v-if="idx !== options.length - 1")
      .mb-5.mt-5(v-if="option.override")
        p.title.is-5.mb-2 {{$t('services.details.custom_pricing_title', {count: Object.keys(option.override).length})}}
        table.table.is-fullwidth
          tr
            th {{ $t('services.details.option_team_member_label') }}
            th {{$t('services.details.option_duration_label')}}
            th {{$t('services.details.option_price_label')}}
          tr(v-for="(val, memberId) in option.override" :key="memberId" :class="staff.includes(memberId) ? '': 'is-disabled'")
            td
              .media.p-0
                .media-left
                  staff-pic.is-rounded.is-24x24(:staff="memberId" )
                .media-body
                  p.title.is-6
                    staff-view(:id="memberId") 
                  //p.subtitle.is-6
                    experience-view(:staff-id="memberId")
            td
              b(v-if="val.duration !== undefined")
                duration-view( :value="val.duration")
              duration-view(v-else :value="option.duration")
            td
              b(v-if="val.price !== undefined")
                currency-view(:value="val.price")
              currency-view(v-else :value="option.price")
              span(v-if="val.priceUpto !== undefined || option.priceUpto!== undefined")
                |  -  
                b(v-if="val.priceUpto !== undefined")
                  currency-view(:value="val.priceUpto")
                currency-view(v-else :value="option.priceUpto")

      b-field
        p.is-size-5
          a(@click="customize(option)") 
            span.icon
              i.mdi.mdi-cog-outline
            span {{ $t('services.details.actions.customize_price') }}
        //.is-flex
          staff-pic.is-rounded.is-32x32(v-for="(val, memberId) in option.override" tooltip :staff="memberId" :key="memberId")
    .field.mt-5
      .buttons
        a.button.is-outlined.is-primary.is-rounded(@click="addOption" data-qa="action-add-pricing-option") 
          span.icon
            i.mdi.mdi-plus-circle-outline.mdi-24px
          span {{$t('services.details.add_option')}}
</template>
<script>
import cloneDeep from 'lodash/cloneDeep'
import AdvancedPriceOptionsForm from './AdvancedPriceOptionsForm'
export default {
  name: 'PricingOptions',
  props: {
    value: {type: Array, required: true},
    staff: {type: Array, required: true}
  },
  data() {
    return {
      options: []
    }
  },
  watch: {
    value() {
      this.options = cloneDeep(this.value)
    }
  },
  provide() {
    return {
      $validator: this.$validator
    }
  },
  created() {
    this.options = cloneDeep(this.value)
  },
  methods: {
    addOption() {
      this.options.push({duration: this.$rfSettings().services.duration ? 60 : undefined, price: 0})
      this.update()
    },
    removeOption(option) {
      let idx = this.options.indexOf(option)
      this.options.splice(idx, 1)
      this.update()
    },
    update() {
      let res = this.options.map(o => {
        let prices = ((o.override && Object.values(o.override)) || []).reduce((res, ov) => {
          if (ov.price !== undefined) {
            res.push(ov.price)
          }
          if (ov.priceUpto || ov.priceUpto === 0) {
            res.push(ov.priceUpto)
          } else {
            delete ov.priceUpto
          }
          return res
        }, [])
        prices.push(o.price)
        if (o.priceUpto || o.priceUpto === 0) {
          prices.push(o.priceUpto)
        } else {
          delete o.priceUpto
        }

        let minPrice = Math.min(...prices)
        let maxPrice = Math.max(...prices)
        o.minPrice = minPrice
        if (maxPrice !== minPrice) {
          o.maxPrice = maxPrice
        } else {
          delete o.maxPrice
        }
        if (o.priceUpto === o.price) {
          delete o.priceUpto
        }

        return o
      })
      this.$emit('input', res)
    },
    customize(option) {
      this.$buefy.modal.open({
        parent: this,
        component: AdvancedPriceOptionsForm,
        props: {value: option.override, option, staff: this.staff},
        events: {
          input: override => {
            this.$set(option, 'override', override)
            this.update()
          }
        },
        hasModalCard: true,
        width: 960,
        fullScreen: this.isMobile()
      })
    }
  }
}
</script>
<style scoped>
.box {
  position: relative;
  box-shadow: none;
  border: 1px solid #625bfe;
}
</style>
