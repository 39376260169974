<template lang="pug">
  .modal-card
    header.modal-card-head
      p.modal-card-title(data-qa="modal-header") 
        b(v-t="'tax_settings.tax_mapping_title'")
      div
        a.button.is-white.is-small(@click="$emit('close')")
          span.icon
            i.mdi.mdi-close.mdi-24px
    section.modal-card-body
      .level.is-mobile(v-for="location in activeLocations")
        .level-left
          .level-item
            div
              p.title.is-6.mb-5 {{location.title}}
              p.subtitle.is-6.has-text-grey 
                location-address-view(:location-id="location._id")
        .level-right
          .level-item
            TaxSelector(v-model="maps[location._id]" expanded)

        
    footer.modal-card-foot
      .buttons
        action-button(title="actions.save" @click="submit" data-qa="action-save")
        button.button(@click="$emit('close')" v-t="'actions.cancel'")  
    
</template>

<script>
import {mapGetters} from 'vuex'
import TaxSelector from './TaxSelector'
export default {
  name: 'LocationTaxMapper',
  components: {
    TaxSelector
  },
  props: {
    value: {type: Object, required: true}
  },
  data() {
    return {
      maps: {}
    }
  },
  computed: {
    ...mapGetters('location', ['activeLocations'])
  },
  async created() {
    this.maps = Object.assign({}, this.value)
  },

  methods: {
    async submit() {
      const valid = await this.$validator.validateAll()
      if (!valid) {
        return
      }
      this.$emit('input', this.maps)
      this.$emit('close')
    },
    cancel() {
      this.$emit('close')
    }
  }
}
</script>
