<template lang="pug">
  span.tag.membership-tag.is-clickable(@click.stop="openDetails") {{value.name}}
</template>
<script>
import MembershipPackageView from './MembershipPackageView'
export default {
  props: {
    value: {type: Object, required: true}
  },
  methods: {
    openDetails() {
      this.$buefy.modal.open({
        parent: this,
        width: 640,
        props: {value: this.value},
        component: MembershipPackageView,
        canCancel: ['outside', 'escape', 'x']
      })
    }
  }
}
</script>
<style scoped>
.membership-tag {
  background-image: linear-gradient(-20deg, #2b5876 0%, #4e4376 100%);
  color: white !important;
  font-weight: bold !important;
}
</style>
