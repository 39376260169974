<template lang="pug">
.modal-card
  header.modal-card-head
    p.modal-card-title(data-qa="modal-header") 
      b(v-t="'billing.update_payment_method.title'")
  section.modal-card-body(v-if="!success")
    b-notification(type="is-danger" :closable="false" :has-icon="true" v-if="subscriptionIncomplete")
      p(v-t="`billing.status.${subscription.status}`")
    .notification.is-danger(v-if="errorMsg")
      p {{errorMsg}}
    b-field(:label="$t('billing.update_payment_method.card_holder_label')" :type="errors.has('name') ? 'is-danger': ''" :message="errors.has('name') ? errors.first('name') : ''")
      b-input(v-model="name" ref="nameInput" v-validate="'required'" size="is-medium" name="name" :placeholder="$t('billing.update_payment_method.card_holder_placeholder')")
    credit-card-input(@complete="onComplete")
    br
    .field.help.has-text-centered
      p
        img(src="/img/powered_by_stripe@2x.png" style="height:20px")
  
  section.modal-card-body(v-else)
    success-screen
      p.title.is-3(v-t="'misc.thank_you'")
      p.subtitle.is-5(v-t="'billing.update_payment_method.success'")
  footer.modal-card-foot
    .buttons
      action-button(:loading="loading" @click="submit"  title="actions.submit" icon="lock" v-if="!success")
      button.button(type="button" @click="$emit('close')") {{$t('actions.close')}}      
</template>

<script>
import {mapActions, mapGetters, mapState} from 'vuex'
import {createToken} from 'vue-stripe-elements-plus'
import CreditCardInput from './CreditCardInput'

export default {
  components: {
    CreditCardInput
  },
  data() {
    return {
      name: undefined,
      complete: false,
      errorMsg: undefined,
      loading: false,
      success: false
    }
  },
  computed: {
    ...mapGetters('billing', ['subscriptionIncomplete']),
    ...mapState('billing', ['subscription'])
  },
  mounted() {
    this.$refs.nameInput.focus()
  },
  methods: {
    ...mapActions('billing', ['updatePaymentMethod']),
    async submit() {
      let valid = await this.$validator.validateAll()
      if (!valid) {
        return
      }
      if (this.loading || !this.complete) {
        return
      }
      this.loading = true
      try {
        let data = await createToken({name: this.name})
        if (!data.token) {
          throw new Error('Unknown error occured with the card')
        }
        await this.updatePaymentMethod({token: data.token})
        this.success = true
      } catch (err) {
        if (err.body && err.body && err.body.code === 'INVALID_CARD') {
          this.errorMsg = err.body.message
          if (err.body.additionalMessage) {
            this.errorMsg += ' ' + err.body.additionalMessage
          }
        } else {
          this.$handleAPIError(err)
        }
      }
      this.loading = false
    },
    onComplete(complete) {
      this.complete = complete
    }
  }
}
</script>
