import {Validator} from 'vee-validate'

import StaffApi from '@/api/staff'
import CompanyApi from '@/api/company'
import VouchersApi from '@/api/vouchers'

Validator.extend('emailAvailable', {
  getMessage: () => `This email is already in use`,
  validate: async value => {
    const {body} = await StaffApi.checkEmail(value)
    return body.available
  }
})

Validator.extend('voucherCodeAvailable', {
  getMessage: () => `This code is already in use`,
  validate: async value => {
    const {body} = await VouchersApi.checkCode(value)
    return body.available
  }
})

Validator.extend('companyShortId', {
  getMessage: () => `This short ID is already taken`,
  validate: async value => {
    const {available} = await CompanyApi.checkShortId(value)
    return available
  }
})

Validator.extend('phone', {
  getMessage: () => `Invalid phone number`,
  validate: value => {
    const phoneRegExp = /^[+][0-9]*$/
    return phoneRegExp.test(value)
  }
})

Validator.extend('hasLink', {
  getMessage: () => `{{url}} should be included in the text`,
  validate: value => {
    return !!value.match(/\{\{-?\s*url\s*}}/)
  }
})

Validator.extend('password', {
  getMessage: () => `password should contain at least one digit, one lower case, one upper case, `,
  validate: value => {
    //const regExp = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}$/
    const regExp = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d\w\W]{8,}$/
    return regExp.test(value)
  }
})
