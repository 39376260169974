<template lang="pug">
  span(v-if="survey && survey.value")
    span.feedback(v-if="type === 'feedback'" :class="'feedback-' + survey.value") {{survey.value}}
    span.nps(v-else-if="type === 'nps'" :class="'nps-' + survey.value") {{survey.value}}
    span.tag.is-info(v-else) {{survey.value}}
  span(v-else) {{empty}}
</template>
<script>
export default {
  props: {
    type: {type: String, required: true},
    surveys: {type: Array, default: () => []},
    empty: {type: String, default: undefined}
  },
  computed: {
    survey() {
      return this.surveys && this.surveys.find(s => s.question === this.type)
    }
  }
}
</script>
