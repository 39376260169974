import dayjs from 'dayjs'
export default {
  getPeriodToFrom(period, company) {
    let from, to
    period = period || 'last30'
    switch (period) {
      case 'last90':
        to = dayjs()
          .endOf('day')
          .toDate()
        from = dayjs(to)
          .subtract(90, 'days')
          .startOf('day')
          .toDate()
        break
      case 'last30':
        to = dayjs()
          .endOf('day')
          .toDate()
        from = dayjs(to)
          .subtract(30, 'days')
          .startOf('day')
          .toDate()
        break

      case 'last7':
        to = dayjs()
          .endOf('day')
          .toDate()
        from = dayjs(to)
          .subtract(7, 'days')
          .startOf('day')
          .toDate()
        break
      case 'last28':
        to = dayjs()
          .endOf('day')
          .toDate()
        from = dayjs(to)
          .subtract(4, 'weeks')
          .startOf('day')
          .toDate()
        break
      case 'last24h':
        to = dayjs().toDate()
        from = dayjs()
          .subtract(24, 'hours')
          .toDate()
        break
      case 'next30':
        from = dayjs()
          .startOf('day')
          .toDate()
        to = dayjs(from)
          .add(30, 'days')
          .toDate()
        break
      case 'next7':
        from = dayjs()
          .startOf('day')
          .toDate()
        to = dayjs(from)
          .add(1, 'week')
          .toDate()
        break
      case 'next90':
        from = dayjs()
          .startOf('day')
          .toDate()
        to = dayjs(from)
          .add(90, 'days')
          .toDate()
        break
      case 'next28':
        from = dayjs()
          .startOf('day')
          .toDate()
        to = dayjs(from)
          .add(4, 'weeks')
          .toDate()
        break
      case 'today':
        to = dayjs()
          .endOf('day')
          .toDate()
        from = dayjs()
          .startOf('day')
          .toDate()
        break
      case 'yesterday':
        from = dayjs()
          .subtract(1, 'day')
          .startOf('day')
          .toDate()
        to = dayjs()
          .subtract(1, 'day')
          .endOf('day')
          .toDate()
        break
      case 'tomorrow':
        from = dayjs()
          .add(1, 'day')
          .startOf('day')
          .toDate()
        to = dayjs()
          .subtract(2, 'day')
          .endOf('day')
          .toDate()
        break
      case 'lastWeek':
        from = dayjs()
          .subtract(7, 'days')
          .startOf('week')
          .add(company && company.weekStart, 'day')
          .toDate()
        to = dayjs()
          .subtract(7, 'days')
          .endOf('week')
          .add(company && company.weekStart, 'day')
          .toDate()
        break
      case 'mtd':
        from = dayjs()
          .startOf('month')
          .toDate()
        to = dayjs()
          .endOf('day')
          .toDate()
        break
      case 'ytd':
        from = dayjs()
          .startOf('year')
          .toDate()
        to = dayjs()
          .endOf('day')
          .toDate()
        break
      case 'lastMonth':
        from = dayjs()
          .subtract(1, 'month')
          .startOf('month')
          .toDate()
        to = dayjs()
          .subtract(1, 'month')
          .endOf('month')
          .toDate()
        break
      case 'lastYear':
        from = dayjs()
          .subtract(1, 'year')
          .startOf('year')
          .toDate()
        to = dayjs()
          .subtract(1, 'year')
          .endOf('year')
          .toDate()
        break
      case 'alltime':
        to = dayjs()
          .endOf('day')
          .toDate()
        from = dayjs(company.created)
          .startOf('day')
          .toDate()
        break
    }
    let previousFrom = new Date(from - (to - from))
    let previousTo = from
    return [from, to, previousFrom, previousTo]
  }
}
