<template lang="pug">
nav.navbar.is-hidden-tablet.is-flex-shrink-0
  .navbar-menu.is-active.is-flex.is-flex-direction-row.pb-4.is-align-items-center.is-justify-content-space-between
    
    .navbar-item.px-2.py-1(v-if="!company.rewardsOnly && $rfHasPermission('APPOINTMENT_VIEW') && $rfSettings().calendar")
      router-link.mobile-nav-button.has-text-centered.py-3.is-block(to='/calendar') 
        span.icon
          i.mdi.mdi-24px.mdi-calendar-outline(aria-hidden="true")
    
    .navbar-item.px-2.py-1(v-if="$rfHasPermission('DASHBOARD_ANALYTICS')")
      router-link.mobile-nav-button.has-text-centered.py-3.is-block(to='/dashboard') 
        span.icon
          i.mdi.mdi-24px.mdi-view-dashboard-outline(aria-hidden="true")
    
    .navbar-item.px-2.py-1(v-if="$rfHasPermission('CLIENTS_VIEW')")
      router-link.mobile-nav-button.has-text-centered.py-3.is-block(to='/customers') 
        span.icon
          i.mdi.mdi-24px.mdi-account-multiple-outline(aria-hidden="true")
    
    .navbar-item.px-2.py-1(v-if="company.rewardsOnly && $rfHasPermission('SALE_VIEW')")
        a.mobile-nav-button.has-text-centered.py-3.is-block(@click='checkout' data-qa="navbar-checkout-popup") 
          span.icon
            i.mdi.mdi-24px.mdi-basket-outline(aria-hidden="true")

    .navbar-item.px-2.py-1(v-if="$rfHasPermission('SALE_VIEW')")
        router-link.mobile-nav-button.has-text-centered.py-3.is-block(to='/sales/list') 
          span.icon
            i.mdi.mdi-24px.mdi-format-list-checks(aria-hidden="true")
    
    .navbar-item.px-2.py-1(v-if="$rfHasPermission('COMPANY_SETTINGS_VIEW')")
      router-link.mobile-nav-button.has-text-centered.py-3.is-block(to='/settings/business') 
        span.icon
          i.mdi.mdi-24px.mdi-cog-outline(aria-hidden="true")
</template>
<script>
import {mapState} from 'vuex'
import CheckoutPopup from './checkout/CheckoutPopup'
import Checkout from './sale/Checkout'

export default {
  name: 'MobileNavbar',
  computed: {
    ...mapState('company', ['company'])
  },
  methods: {
    checkout() {
      if (this.company.rewardsOnly) {
        this.$buefy.modal.open({
          parent: this,
          component: CheckoutPopup,
          fullScreen: true,
          canCancel: ['escape']
        })
      } else {
        this.$buefy.modal.open({
          parent: this,
          component: Checkout,
          fullScreen: true,
          hasModalCard: true,
          canCancel: ['escape']
        })
      }
    }
  }
}
</script>
<style scoped>
.navbar-item {
  flex-grow: 1;
}
.navbar {
  border-top: 1px solid #efefef;
}
.mobile-nav-button.is-active {
  color: white !important;
  background: #625bfe;
  border-radius: 8px;
}
</style>
