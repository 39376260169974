import SALE_STATUS from '../../../constants/saleStatus'
export default {
  [SALE_STATUS.DRAFT]: {
    icon: 'pencil',
    color: 'grey'
  },
  [SALE_STATUS.REFUNDED]: {
    icon: 'undo-variant',
    color: 'danger'
  },
  [SALE_STATUS.VOID]: {
    icon: 'delete-outline',
    color: 'danger'
  },
  [SALE_STATUS.COMPLETED]: {
    icon: 'basket-outline',
    color: 'success'
  }
}
