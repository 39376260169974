<template lang="pug">
.modal-card
  header.modal-card-head
    p.modal-card-title
      b(v-if="!id" v-t="'memberships.create_membership_package_title'")
      b(v-else v-t="'memberships.update_membership_package_title'")
    div
      a.button.is-white.is-small(@click="$emit('close')")
        span.icon
          i.mdi.mdi-close.mdi-24px
  section.modal-card-body
    note.mb-3(type="is-warning" v-if="id") {{ $t('memberships.update_not_affect_existings') }}  
    b-field(:label="$t('memberships.name_label')" :type="errors.has('name') ? 'is-danger': ''" :message="errors.has('name') ? errors.first('name') : ''" expanded )
      b-input(:placeholder="$t('memberships.name_placeholder')" ref="nameinput" v-model=" name" name="name" data-vv-as="name" expanded v-validate="'required'")

    b-field(:label="$t('memberships.price_label')" :type="errors.has('price') ? 'is-danger': ''" :message="errors.has('price') ? errors.first('price') : ''" expanded )
      p.control
        span.button.is-static 
          currency
      b-input(:placeholder="$t('memberships.price_placeholder')" v-model.number="price" name="price" v-validate="'required|decimal:2|min_value:0'" data-vv-as="price" expanded)
    
    p(v-t="'memberships.select_services_explanation'")
    label.label {{$tc('memberships.number_of_services',  servicesList.length)}} 
    
    .mb-3(v-for="(item, idx) in servicesList" style="position: relative;padding-right: 3rem;")
      button.delete(@click="removeService(item)" v-if="servicesList.length > 1")              
      b-field(grouped)
        ServiceAutocomplete(v-model="item.service" :autocomplete="false" expanded)
        b-field
          p.control
            span.button.is-static 
              span.icon
                i.mdi.mdi-close
        b-field
          b-select(v-model="item.qty")
            option(:value="null") {{$t('misc.unlimited')}}
            option(v-for="qty in [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,25,30,35,40,45,50,60,70,80,90,100, 200]" :value="qty") {{qty}}
    .field
      button.button.is-success(@click="servicesList.push({service: {}})" type="button") {{$t('memberships.actions.add_service')}}
    b-field(:message="$t('memberships.expiration_description')")
      b-field(:label="$t('memberships.valid_label')" grouped group-multiline)
        b-field( :type="errors.has('validity') ? 'is-danger': ''" :message="errors.has('validity') ? errors.first('validity') : ''")
          b-input(v-model="validity.value"  v-validate="'required|numeric'" name="validity" :placeholder="$t('memberships.valid_placeholder')")
        b-field( :type="errors.has('validityUnit') ? 'is-danger': ''" :message="errors.has('validityUnit') ? errors.first('validityUnit') : ''")
          b-select(v-model="validity.unit" v-validate="'required'" name="validityUnit" data-vv-as="unit" )
            option(v-for="value in validityUnits" :value="value") {{$t(`misc.${value}`)}}
    b-field(:label="$t('memberships.tc_label')")
      b-input(type="textarea" v-model="tc" :placeholder="$t('memberships.tc_placeholder')")  
    
    b-field(:label="$t('rewards_program.collect_rewards_on_sale')")
      b-switch(v-model="collectRewards") {{ collectRewards ? $t('misc.yes') : $t('misc.no') }}
    b-switch(v-model="overrideCommission") 
      b(v-t="'services.details.commission_override_label'")
      
    template(v-if="overrideCommission")
      b-field(:label="$t('services.details.commission_label')", :type="errors.has('commissionRate') ? 'is-danger': ''" :message="errors.has('commissionRate') ? errors.first('commissionRate') : ''")
        b-input(v-model="commissionRate" icon="percent" v-validate="'numeric'" name="commissionRate" :placeholder="$t('services.details.commission_placeholder')" data-vv-as="membership commission" )
      b-field(:label="$t('services.details.commission_type_label')")
        b-radio(v-model="commissionType" name="commissionType" :native-value="COMMISSION_BASE_TYPES.BEFORE_DISCOUNT") {{$t('commission_base_type.before_discount')}}
        b-radio(v-model="commissionType" name="commissionType" :native-value="COMMISSION_BASE_TYPES.AFTER_DISCOUNT") {{$t('commission_base_type.after_discount')}}
          |  
          small
            i.has-text-primary(v-t="'misc.recommended'")
  footer.modal-card-foot
    .buttons
      action-button(:title="id ? 'actions.save':'actions.create'" @click="submit" :loading="loading" permission="MEMBERSHIP_PACKAGES_MANAGE")
      button.button.is-outlined(type="button" @click="$emit('close')" ) {{$t('actions.close')}}      
      action-button.is-danger(@click="onArchive" v-if="id" :loading="archiving" title="actions.archive" permission="MEMBERSHIP_PACKAGES_MANAGE")
</template>
<script>
import {mapActions} from 'vuex'
import ServiceAutocomplete from '../services/ServiceAutocomplete'
import COMMISSION_BASE_TYPES from '../../../constants/commissionBaseTypes'

export default {
  name: 'CreateEditMembershipPackage',
  components: {
    ServiceAutocomplete
  },
  props: {
    id: {type: String, default: undefined}
  },
  data() {
    return {
      COMMISSION_BASE_TYPES,
      loading: false,
      archiving: false,
      name: undefined,
      price: undefined,
      tc: undefined,
      overrideCommission: false,
      commissionRate: undefined,
      commissionType: undefined,
      collectRewards: true,
      validity: {unit: 'months', value: 6},
      validityUnits: ['days', 'weeks', 'months'],
      servicesList: [{service: {}, qty: 5}]
    }
  },
  async created() {
    if (this.id) {
      let pack = await this.getMembershipPackageById(this.id)
      this.name = pack.name
      this.price = pack.price
      this.tc = pack.tc
      this.commissionRate = pack.commissionRate
      this.commissionType = pack.commissionType
      this.collectRewards = pack.collectRewards
      this.validity = {...pack.validity}
      this.servicesList = pack.services.map(s => {
        return {
          service: {serviceId: s.service, optionId: s.option},
          qty: s.qty || null
        }
      })
      this.overrideCommission = pack.commissionRate !== undefined
    }
  },
  mounted() {
    this.$refs.nameinput.focus()
  },
  methods: {
    ...mapActions('membershipPackages', [
      'getMembershipPackageById',
      'createMembershipPackage',
      'updateMembershipPackage',
      'archiveMembershipPackage'
    ]),
    removeService(item) {
      let idx = this.servicesList.indexOf(item)
      this.servicesList.splice(idx, 1)
    },
    onArchive() {
      this.$buefy.dialog.confirm({
        message: this.$t('memberships.archive_confirm.body'),
        title: this.$t('memberships.archive_confirm.title'),
        type: 'is-black',
        confirmText: this.$t('actions.archive'),
        cancelText: this.$t('actions.cancel'),
        onConfirm: () => {
          this.archive()
        }
      })
    },
    async archive() {
      this.archiving = true
      try {
        await this.archiveMembershipPackage(this.id)
        this.$buefy.toast.open({
          message: this.$t('status.save_success'),
          type: 'is-success',
          queue: false
        })
        this.$emit('close')
      } catch (err) {
        this.$handleAPIError(err)
      }
      this.archiving = false
    },
    onValueInput() {
      this.price = this.value
    },
    async submit() {
      this.loading = true
      const valid = await this.$validator.validateAll()

      if (!valid) {
        this.loading = false
        return
      }
      let services = this.servicesList
        .filter(s => s.service && s.service.serviceId)
        .map(s => {
          return {service: s.service.serviceId, option: s.service.optionId, qty: s.qty || undefined}
        })
      if (!services.length) {
        this.$buefy.toast.open({
          type: 'is-warning',
          message: this.$t('memberships.services_cant_be_empty'),
          duration: 5000,
          queue: false,
          position: 'is-bottom'
        })
        this.loading = false
        return
      }
      const data = {
        name: this.name,
        commissionRate: undefined,
        commissionType: undefined,
        collectRewards: this.collectRewards,
        value: this.value,
        price: this.price,
        services,
        validity: this.validity,
        tc: this.tc
      }
      if (this.overrideCommission) {
        data.commissionRate = this.commissionRate
        data.commissionType = this.commissionType
      }

      try {
        if (this.id) {
          await this.updateMembershipPackage({id: this.id, data})
        } else {
          await this.createMembershipPackage(data)
        }
        this.$buefy.toast.open({
          message: this.$t('status.save_success'),
          type: 'is-success',
          queue: false
        })
        this.$emit('close')
      } catch (err) {
        this.$handleAPIError(err)
      }

      this.loading = false
    }
  }
}
</script>
