import Vue from 'vue'
import {SET_TAXES, UPDATE_TAX, RESET_TAX, SET_TAXES_INITIALIZED} from './mutationTypes'
import TaxApi from '@/api/tax'
let __listPromise

export default {
  namespaced: true,
  state: {
    taxesMap: {},
    taxesOrder: [],
    taxGroupsOrder: [],
    initialized: false
  },
  getters: {
    taxes(state) {
      return state.taxesOrder.map(c => state.taxesMap[c])
    },
    taxGroups(state) {
      return state.taxGroupsOrder.map(c => state.taxesMap[c])
    },
    activeTaxes(state) {
      return state.taxesOrder.map(c => state.taxesMap[c]).filter(c => c.active === true)
    },
    activeTaxGroups(state) {
      return state.taxGroupsOrder.map(c => state.taxesMap[c]).filter(c => c.active === true)
    }
  },
  mutations: {
    [SET_TAXES_INITIALIZED](state, val) {
      state.initialized = val
    },
    [SET_TAXES](state, data) {
      state.taxesMap = {}
      for (const tax of data) {
        state.taxesMap[tax._id] = tax
      }
      state.taxesOrder = data.filter(d => !d.group).map(c => c._id)
      state.taxGroupsOrder = data.filter(d => d.group).map(c => c._id)
    },
    [UPDATE_TAX](state, tax) {
      if (state.taxesMap[tax._id]) {
        Object.assign(state.taxesMap[tax._id], tax)
      } else {
        Vue.set(state.taxesMap, tax._id, tax)
      }
    },
    [RESET_TAX](state) {
      state.taxesMap = {}
      state.taxesOrder = []
      state.taxGroupsOrder
      state.initialized = false
    }
  },
  actions: {
    async fetchTaxes({commit, state}, force) {
      if (__listPromise) {
        return __listPromise
      }
      if (!state.initialized || force) {
        __listPromise = TaxApi.getTaxes()
        const taxes = await __listPromise
        __listPromise = undefined
        commit(SET_TAXES, taxes)
        commit(SET_TAXES_INITIALIZED, true)
      }
    },
    async createTax({dispatch}, data) {
      await TaxApi.createTax(data)
      await dispatch('fetchTaxes', true)
    },
    async createTaxGroup({dispatch}, data) {
      await TaxApi.createTaxGroup(data)
      await dispatch('fetchTaxes', true)
    },
    async updateTax({commit}, {id, data}) {
      const tax = await TaxApi.updateTax(id, data)
      commit(UPDATE_TAX, tax)
    },
    async updateTaxGroup({commit}, {id, data}) {
      const tax = await TaxApi.updateTax(id, data)
      commit(UPDATE_TAX, tax)
    },
    async applyDefaults({dispatch}) {
      await TaxApi.applyDefaults()
      await dispatch('services/list', true, {root: true})
      await dispatch('inventory/reset', undefined, {root: true})
    },
    async getById({state, dispatch}, id) {
      if (!state.taxesMap[id]) {
        await dispatch('fetchTaxes', true)
      }
      return state.taxesMap[id]
    },
    reset({commit}) {
      commit(RESET_TAX)
    }
  }
}
