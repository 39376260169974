<template lang="pug">
  b-taginput(
    v-model="selectedCategories"
    :data="filteredCategories"
    autocomplete
    :open-on-focus="true"
    :allow-new="false"
    field="title"
    maxtags="3"
    icon="label"
    :placeholder="placeholder"
    @typing="getFilteredTags" 
    type="is-primary"
    @input="onInput"
    )
</template>
<script>
import StaticApi from '@/api/static'

export default {
  name: 'CategoriesSelector',
  props: {
    value: {type: Array, default: () => []},
    placeholder: {type: String, default: undefined}
  },
  data() {
    return {
      categories: [],
      filteredCategories: [],
      selectedCategories: []
    }
  },
  async created() {
    this.categories = (await StaticApi.getBusinessCategories()).map(cat => {
      return {
        value: cat,
        title: this.$t(`business_categories.${cat}`)
      }
    })
    this.filteredCategories = [...this.categories]
    if (this.value && this.value.length) {
      this.selectedCategories = this.categories.filter(option => {
        return this.value.indexOf(option.value) !== -1
      })
    }
  },
  methods: {
    getFilteredTags(text) {
      this.filteredCategories = this.categories.filter(option => {
        return (
          option.title
            .toString()
            .toLowerCase()
            .indexOf(text.toLowerCase()) >= 0
        )
      })
    },
    onInput() {
      this.$emit(
        'input',
        this.selectedCategories.map(c => c.value)
      )
    }
  }
}
</script>
