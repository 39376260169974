<template lang="pug">
  doctype html
  fullscreen-modal(title="appointment.details_title" @close="$emit('close')" v-if="appointment")

    .columns.is-height-100.is-marginless.has-white-background.is-printable
      .column.is-9.is-height-100.right-panel.is-fullwidth-print
        .section
          .columns.is-centered
            .column.is-7.is-hidden-print    
              h2.title.is-4.is-capitalized
                date-view(:value="appointment.start" format="dddd, D MMM YYYY")
                AppointmentStatusIcon.is-pulled-right(:status="appointment.status" showText=true isTag=true tagSize="is-medium" )
              p.subtitle.has-text-grey
                span.icon
                  i.mdi.mdi-map-marker
                location-view(:value="appointment.location")
              table.table.is-fullwidth.is-borderless.has-transparent-background
                tbody
                  tr(v-for="service in appointment.services")
                    td      
                      p.title.is-5.is-marginless.is-no-break 
                        time-view(:value="service.start")
                    td
                      AppointmentItem(:service="service" size="medium")
                    td.has-text-right
                      p.title.is-5.is-marginless.is-no-break
                          currency-view(:value="service.price")
                tfoot
                  tr
                    th.is-hidden-mobile
                    th.is-hidden-mobile
                      p.title.is-5
                        duration-view(:value="appointment.duration")
                    th.is-hidden-mobile.has-text-right
                      p.title.is-5.is-no-break
                        currency-view.is-size-5(:value="appointment.total")
              .notification.mt-3(v-if="appointment.surveys && appointment.surveys.length")
                p.is-size-5 {{$t('sale.surveys_title')}}
                .list-item(v-for="survey in appointment.surveys") {{$t(`surveys.${survey.question}`)}}  
                  |  
                  survey-result-view.is-pulled-right(:type="survey.question" :surveys="appointment.surveys" empty="n/a")
              .buttons
                action-button(@click="repeatAppointment" icon="refresh" title="appointment.actions.repeat_appt") 
                action-button(@click="recover" icon="undo" :loading="recovering" title="appointment.actions.recover" v-if="appointment.status === APPOINTMENT_STATUS.CANCELED || appointment.status === APPOINTMENT_STATUS.NOSHOW")
              template(v-if="appointment.notes")
                label.label(v-t="'appointment.notes_title'")
                .notification.p-4 {{ appointment.notes }}
              //hr
              label.label(v-t="'appointment_history.title'")
              .p-4.has-background-light
                AppointmentStatusHistory(:appointment="appointment")
            .column.is-5.is-fullwidth-print(v-if="appointment.sale")
              Invoice(:saleId="appointment.sale" @voided="onPaymentVoid")
              
      
      scroll-bar.column.is-3.is-height-100.has-white-background.has-border-left.is-hidden-mobile.is-paddingless.is-flex.is-hidden-print 
        div.is-flex.is-flex-direction-column(style="width: 100%" v-if="customer")
          .p-5
            customer-tiny-preview(:value="customer" :closable="false" :link="true")
          .px-5.pb-5
            Stats(:customer="customer" :blocks="['sales', 'appointments', 'rewards', 'loyalty', 'surveys', 'notes']" )
        div(v-else style="width: 100%") 
          .p-5
            customer-tiny-preview(:value="customer" :closable="false" )
</template>

<script>
import {mapActions, mapState} from 'vuex'
import Stats from '../customers/Stats'
import AppointmentItem from './AppointmentItem'
import Invoice from '../sale/Invoice'
import APPOINTMENT_STATUS from '../../../constants/appointmentStatus'
import AppointmentStatusIcon from './AppointmentStatusIcon'
import AppointmentStatusHistory from './AppointmentStatusHistory'

export default {
  name: 'AppointmentDetails',
  components: {
    AppointmentItem,
    AppointmentStatusIcon,
    Invoice,
    AppointmentStatusHistory,
    Stats
  },
  props: {
    appointmentId: {type: String, required: true}
  },
  data() {
    return {
      loading: false,
      appointment: undefined,
      customer: undefined,
      APPOINTMENT_STATUS,
      recovering: false
    }
  },
  computed: {
    ...mapState('auth', ['user'])
  },
  async created() {
    this.appointment = await this.fetchAppointmentById({id: this.appointmentId})

    if (this.appointment.customer) {
      this.customer = await this.fetchCustomer(this.appointment.customer)
    }

    // //workaround for cases when appointment was not successfully returned to the status of new
    // if (!this.appointment.sale && this.appointment.status === appointmentStatus.COMPLETED) {
    //   this.onPaymentVoid()
    // }
  },
  methods: {
    ...mapActions('appointments', ['fetchAppointmentById', 'recoverAppointment']),
    ...mapActions('customers', ['fetchCustomer']),
    onPaymentVoid() {
      this.$emit('close')
      this.openAppointmentEdit()
    },
    repeatAppointment() {
      this.$router.push({name: 'Calendar', query: {rebooking: this.appointmentId}})
      /// TODO implement customer based date prediction using nextVisitDue and last appointment date

      this.$emit('close')
    },
    recover() {
      this.$buefy.dialog.confirm({
        message: this.$t('appointment.recover_confirm.body'),
        title: this.$t('appointment.recover_confirm.title'),
        cancelText: this.$t('misc.no'),
        confirmText: this.$t('misc.yes'),
        type: 'is-black',
        canCancel: ['button'],
        onConfirm: async () => {
          this.recovering = true
          try {
            await this.recoverAppointment(this.appointmentId)
            this.$emit('close')
            this.openAppointmentEdit()
          } catch (err) {
            this.$handleAPIError(err)
          }
          this.recovering = false
        }
      })
    },
    openAppointmentEdit() {
      this.$router.push({
        name: 'Calendar',
        query: {appointment: this.appointmentId, customer: this.customer && this.customer._id, forceFetch: true}
      })
    }
  }
}
</script>
