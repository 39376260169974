<template lang="pug">
  span.tag.is-rounded.is-small.is-danger(v-if="$rfSubscribed('rewards') && !customer.memberSince" v-t="'customer.no_member_tag'")
  span.tag.is-rounded.is-small(v-else-if="$rfSubscribed('rewards')" :class="{'is-dark': customer.effectiveMembership === undefined, 'is-warning': customer.effectiveMembership !== undefined}" :title="title") 
    span.icon(v-if="customer.effectiveMembership !== undefined")
      i.mdi.mdi-alert-outline
    span {{company.membership.titles[customer.membership]}} 
</template>

<script>
import {mapState} from 'vuex'

export default {
  name: 'MembershipTag',
  props: {
    customer: {type: Object, required: true}
  },
  computed: {
    ...mapState('company', ['company']),
    title() {
      return this.customer.effectiveMembership === undefined
        ? this.$t('customer.current_membership_tooltip')
        : this.$t('customer.upgraded_membership_tooltip')
    }
  }
}
</script>
