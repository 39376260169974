<template lang="pug">
  b-select(v-model="country" icon="earth" :placeholder="placeholder" @input="onChange" :expanded="expanded" :disabled="disabled")
    option(value='AF') Afghanistan
    option(value='AX') &angst;land Islands
    option(value='AL') Albania
    option(value='DZ') Algeria
    option(value='AS') American Samoa
    option(value='AD') Andorra
    option(value='AO') Angola
    option(value='AI') Anguilla
    option(value='AG') Antigua and Barbuda
    option(value='AR') Argentina
    option(value='AM') Armenia
    option(value='AW') Aruba
    option(value='AU') Australia
    option(value='AT') Austria
    option(value='AZ') Azerbaijan
    option(value='BS') Bahamas
    option(value='BH') Bahrain
    option(value='BD') Bangladesh
    option(value='BB') Barbados
    option(value='BY') Belarus
    option(value='BE') Belgium
    option(value='BZ') Belize
    option(value='BJ') Benin
    option(value='BM') Bermuda
    option(value='BT') Bhutan
    option(value='BO') Bolivia
    option(value='BQ') Bonaire, Sint Eustatius and Saba
    option(value='BA') Bosnia and Herzegovina
    option(value='BW') Botswana
    option(value='BR') Brazil
    option(value='IO') British Indian Ocean Territory
    option(value='BN') Brunei Darussalam
    option(value='BG') Bulgaria
    option(value='BF') Burkina Faso
    option(value='BI') Burundi
    option(value='CV') Cabo Verde
    option(value='KH') Cambodia
    option(value='CM') Cameroon
    option(value='CA') Canada
    option(value='KY') Cayman Islands
    option(value='CF') Central African Republic
    option(value='TD') Chad
    option(value='CL') Chile
    option(value='CN') China
    option(value='CX') Christmas Island
    option(value='CC') Cocos (Keeling) Islands
    option(value='CO') Colombia
    option(value='KM') Comoros
    option(value='CG') Congo
    option(value='CD') Congo (Democratic Republic)
    option(value='CK') Cook Islands
    option(value='CR') Costa Rica
    option(value='CI') C&ocirc;te d&apos;Ivoire
    option(value='HR') Croatia
    option(value='CU') Cuba
    option(value='CW') Cura&ccedil;ao
    option(value='CY') Cyprus
    option(value='CZ') Czech Republic
    option(value='DK') Denmark
    option(value='DJ') Djibouti
    option(value='DM') Dominica
    option(value='DO') Dominican Republic
    option(value='EC') Ecuador
    option(value='EG') Egypt
    option(value='SV') El Salvador
    option(value='GQ') Equatorial Guinea
    option(value='ER') Eritrea
    option(value='EE') Estonia
    option(value='ET') Ethiopia
    option(value='FK') Falkland Islands (Malvinas)
    option(value='FO') Faroe Islands
    option(value='FJ') Fiji
    option(value='FI') Finland
    option(value='FR') France
    option(value='GF') French Guiana
    option(value='PF') French Polynesia
    option(value='GA') Gabon
    option(value='GM') Gambia
    option(value='GE') Georgia
    option(value='DE') Germany
    option(value='GH') Ghana
    option(value='GI') Gibraltar
    option(value='GR') Greece
    option(value='GL') Greenland
    option(value='GD') Grenada
    option(value='GP') Guadeloupe
    option(value='GU') Guam
    option(value='GT') Guatemala
    option(value='GG') Guernsey
    option(value='GN') Guinea
    option(value='GW') Guinea-Bissau
    option(value='GY') Guyana
    option(value='HT') Haiti
    option(value='VA') Holy See (Vatican City State)
    option(value='HN') Honduras
    option(value='HK') Hong Kong
    option(value='HU') Hungary
    option(value='IS') Iceland
    option(value='IN') India
    option(value='ID') Indonesia
    option(value='IR') Iran, Islamic Republic of
    option(value='IQ') Iraq
    option(value='IE') Ireland
    option(value='IM') Isle of Man
    option(value='IL') Israel
    option(value='IT') Italy
    option(value='JM') Jamaica
    option(value='JP') Japan
    option(value='JE') Jersey
    option(value='JO') Jordan
    option(value='KZ') Kazakhstan
    option(value='KE') Kenya
    option(value='KI') Kiribati
    option(value='KP') North Korea
    option(value='KR') Korea, Republic of
    option(value='KW') Kuwait
    option(value='KG') Kyrgyzstan
    option(value='LA') Lao People&apos;s Democratic Republic
    option(value='LV') Latvia
    option(value='LB') Lebanon
    option(value='LS') Lesotho
    option(value='LR') Liberia
    option(value='LY') Libya
    option(value='LI') Liechtenstein
    option(value='LT') Lithuania
    option(value='LU') Luxembourg
    option(value='MO') Macao
    option(value='MK') Macedonia,
    option(value='MG') Madagascar
    option(value='MW') Malawi
    option(value='MY') Malaysia
    option(value='MV') Maldives
    option(value='ML') Mali
    option(value='MT') Malta
    option(value='MH') Marshall Islands
    option(value='MQ') Martinique
    option(value='MR') Mauritania
    option(value='MU') Mauritius
    option(value='YT') Mayotte
    option(value='MX') Mexico
    option(value='FM') Micronesia, Federated States of
    option(value='MD') Moldova
    option(value='MC') Monaco
    option(value='MN') Mongolia
    option(value='ME') Montenegro
    option(value='MS') Montserrat
    option(value='MA') Morocco
    option(value='MZ') Mozambique
    option(value='MM') Myanmar
    option(value='NA') Namibia
    option(value='NR') Nauru
    option(value='NP') Nepal
    option(value='NL') Netherlands
    option(value='NC') New Caledonia
    option(value='NZ') New Zealand
    option(value='NI') Nicaragua
    option(value='NE') Niger
    option(value='NG') Nigeria
    option(value='NU') Niue
    option(value='NF') Norfolk Island
    option(value='MP') Northern Mariana Islands
    option(value='NO') Norway
    option(value='OM') Oman
    option(value='PK') Pakistan
    option(value='PW') Palau
    option(value='PS') Palestine, State of
    option(value='PA') Panama
    option(value='PG') Papua New Guinea
    option(value='PY') Paraguay
    option(value='PE') Peru
    option(value='PH') Philippines
    option(value='PL') Poland
    option(value='PT') Portugal
    option(value='PR') Puerto Rico
    option(value='QA') Qatar
    option(value='RE') R&eacute;union
    option(value='RO') Romania
    option(value='RU') Russian Federation
    option(value='RW') Rwanda
    option(value='BL') Saint Barth&eacute;lemy
    option(value='SH') Saint Helena
    option(value='KN') Saint Kitts and Nevis
    option(value='LC') Saint Lucia
    option(value='MF') Saint Martin (French part)
    option(value='PM') Saint Pierre and Miquelon
    option(value='VC') Saint Vincent and the Grenadines
    option(value='WS') Samoa
    option(value='SM') San Marino
    option(value='ST') Sao Tome and Principe
    option(value='SA') Saudi Arabia
    option(value='SN') Senegal
    option(value='RS') Serbia
    option(value='SC') Seychelles
    option(value='SL') Sierra Leone
    option(value='SG') Singapore
    option(value='SX') Sint Maarten (Dutch part)
    option(value='SK') Slovakia
    option(value='SI') Slovenia
    option(value='SB') Solomon Islands
    option(value='SO') Somalia
    option(value='ZA') South Africa
    option(value='SS') South Sudan
    option(value='ES') Spain
    option(value='LK') Sri Lanka
    option(value='SD') Sudan
    option(value='SR') Suriname
    option(value='SJ') Svalbard and Jan Mayen
    option(value='SZ') Swaziland
    option(value='SE') Sweden
    option(value='CH') Switzerland
    option(value='SY') Syrian Arab Republic
    option(value='TW') Taiwan, Province of China
    option(value='TJ') Tajikistan
    option(value='TZ') Tanzania, United Republic of
    option(value='TH') Thailand
    option(value='TL') Timor-Leste
    option(value='TG') Togo
    option(value='TK') Tokelau
    option(value='TO') Tonga
    option(value='TT') Trinidad and Tobago
    option(value='TN') Tunisia
    option(value='TR') Turkey
    option(value='TM') Turkmenistan
    option(value='TC') Turks and Caicos Islands
    option(value='TV') Tuvalu
    option(value='UG') Uganda
    option(value='UA') Ukraine
    option(value='AE') United Arab Emirates
    option(value='GB') United Kingdom
    option(value='US') United States
    option(value='UY') Uruguay
    option(value='UZ') Uzbekistan
    option(value='VU') Vanuatu
    option(value='VE') Venezuela
    option(value='VN') Vietnam
    option(value='VG') Virgin Islands, British
    option(value='VI') Virgin Islands, U.S.
    option(value='WF') Wallis and Futuna
    option(value='EH') Western Sahara
    option(value='YE') Yemen
    option(value='ZM') Zambia
    option(value='ZW') Zimbabwe

</template>

<script>
import {mapState} from 'vuex'

export default {
  name: 'CountrySelect',
  props: {
    value: {type: String, default: undefined},
    placeholder: {type: String, default: undefined},
    expanded: {type: String, default: undefined},
    disabled: {type: Boolean, default: false}
  },
  inject: ['$validator'],
  data() {
    return {
      country: null
    }
  },
  computed: {
    ...mapState('company', ['company'])
  },
  watch: {
    value(newVal) {
      if (newVal !== this.country) {
        this.country = this.value
      }
    }
  },
  async created() {
    if (this.value) {
      this.country = this.value
    } else if (this.company.country) {
      this.country = this.company.country
    }
    this.onChange()
  },
  methods: {
    onChange() {
      this.$emit('input', this.country)
    }
  }
}
</script>
