<template lang="pug">
  span(v-if="value") {{formattedTime}}
</template>

<script>
import {mapState} from 'vuex'
import formatter from '../../../shared/formatter'
export default {
  name: 'TimeView',
  props: {
    value: {type: [Date, String, Number], default: undefined}
  },
  computed: {
    ...mapState('company', ['company']),
    formattedTime() {
      return formatter.formatTime(this.value, this.company.timeFormat)
    }
  }
}
</script>
