<template lang="pug">
  .is-flex.is-flex-direction-row.is-align-items-center.box.membershipPackage.p-3(v-if="data && !fullview")
    .is-flex-grow-1
      p.is-size-6 
        b {{data.name}}
      p.is-size-7.is-6.has-text-almost-white
        span(v-t="'memberships.multiple_use_label'")  
    .is-flex-grow-1
      div(v-for="{service, option, qty} in services")
        p.is-size-7 
          service-name-view(:value="{service, option}")
          b(v-if="qty")  x {{qty}}
          b(v-else)  - {{$t('misc.unlimited')}}
    .is-flex-shrink-0.p-3.has-text-right
      p.title.is-size-4
        b
          currency-view(:value="data.price")  
      p.subtitle.is-size-7
        span(v-if="data.expire")
          template(v-if="new Date(data.expire) > new Date()")
            span(v-t="'misc.valid_til'")  
            |  
            date-view(:value="data.expire" format="D MMM YYYY")
          span.tag.is-danger(v-else v-t="'validity.expired'")
        span(v-else)
          span(v-t="'misc.valid_for'")  
          |  
          span {{data.validity.value}}  
          span  {{$t(`misc.${data.validity.unit}`)}}
  .box.membershipPackage.has-text-centered(v-else-if="data && fullview")
    p.title.is-4 {{data.name}}
    p.subtitle.has-text-almost-white.is-6.mb-3(v-t="'memberships.multiple_use_label'")
    p.mb-3.is-size-3 
      b
        currency-view(:value="data.price")  
  
    .has-text-almost-white.mb-3
      .list-item(v-for="{service, option, qty} in services")
        p 
          service-name-view(:value="{service, option}")
          span(v-if="qty")  x {{qty}}
          span(v-else)  {{$t('misc.unlimited')}}
    p.mb-3.has-text-almost-white.is-size-7(v-if="data.tc") {{data.tc}}
    p.has-text-almost-white(v-if="data.expire")
      template(v-if="new Date(data.expire) > new Date()")
        span(v-t="'misc.valid_til'")  
        |  
        date-view(:value="data.expire" format="D MMM YYYY")
      span.tag.is-danger(v-else v-t="'validity.expired'")
    p.mt-3.has-text-almost-white(v-else)
      span(v-t="'misc.valid_for'")  
      |  
      span {{data.validity.value}}  
      span  {{$t(`misc.${data.validity.unit}`)}}

</template>
<script>
import isString from 'lodash/isString'
import {mapActions} from 'vuex'
export default {
  props: {
    value: {type: [String, Object], required: true},
    expandServices: {type: Boolean, default: false},
    fullview: {type: Boolean, default: true}
  },
  data() {
    return {
      data: undefined
    }
  },
  computed: {
    services() {
      return this.data.remainingServices || this.data.services
    }
  },
  async created() {
    if (isString(this.value)) {
      this.data = await this.getMembershipPackageById(this.value)
    } else {
      this.data = this.value
    }
  },
  methods: {
    ...mapActions('membershipPackages', ['getMembershipPackageById'])
  }
}
</script>
<style scoped>
.membershipPackage {
  min-width: 300px;
  border-radius: 16px;
  /* background-color: #21d4fd;
  background-image: linear-gradient(19deg, #21d4fd 0%, #b721ff 100%); */
  /* background-image: radial-gradient(
    circle farthest-corner at 10% 20%,
    rgba(0, 152, 155, 1) 0.1%,
    rgba(0, 94, 120, 1) 94.2%
  ); */
  /* background: linear-gradient(to bottom, rgba(255, 255, 255, 0.15) 0%, rgba(0, 0, 0, 0.15) 100%),
    radial-gradient(at top center, rgba(255, 255, 255, 0.4) 0%, rgba(0, 0, 0, 0.4) 120%) #989898;
  background-blend-mode: multiply, multiply; */
  background-image: linear-gradient(-20deg, #2b5876 0%, #4e4376 100%);
}
.membershipPackage hr {
  background-color: rgba(255, 255, 255, 0.5);
}
.membershipPackage p {
  color: white;
}
</style>
