<template lang="pug">
  div.is-relative
    p.list-item
      span.is-size-5 {{ $t('billing.locations_label') }}
      span.is-size-5.is-pulled-right {{activeLocations.length}}
    p.list-item
      span.is-size-5 {{ $t('billing.subtotal_label') }}
      span.is-size-5.is-pulled-right(v-if="currency")
        invoice-currency(:value="subtotalPrice" :currency-code="currency")
    p.list-item(v-if="coupon")
      span {{ $t('billing.coupon_label') }}  
      span.is-pulled-right
        span {{coupon.name}} 
        em ({{coupon.id}})
    p.list-item(v-if="coupon && coupon.duration !== 'forever'")
      span {{ $t('billing.coupon_validity_label') }}
      span.is-pulled-right
        span(v-if="coupon.duration === 'repeating'")
          span {{coupon.durationInMonths}} 
          span {{ $t('misc.months') }}
        span(v-if="coupon.duration === 'once'")
          span {{ $t('misc.one_time_only') }}
    p.list-item(v-if="discount") 
      span {{ $t('billing.discount_label') }}
      span.is-pulled-right
        invoice-currency(:value="discount" :currency-code="currency")
    p.list-item
      strong.is-size-5 {{ $t('billing.total_label') }} 
      strong.is-size-5.is-pulled-right(v-if="currency")
        invoice-currency(:value="totalPrice" :currency-code="currency")  
</template>

<script>
import {mapGetters} from 'vuex'
import InvoiceCurrency from './InvoiceCurrency'

export default {
  components: {
    InvoiceCurrency
  },
  props: {
    coupon: {type: Object, default: undefined},
    products: {type: Array, required: true},
    checkoutLimit: {type: Number, default: 0}
  },
  computed: {
    ...mapGetters('location', ['activeLocations']),
    currency() {
      if (!this.products.length) {
        return
      }
      return this.products[0].prices.monthly[0].currency
    },
    subtotalPrice() {
      return (
        this.products.reduce((sum, product) => {
          if (product.prices.monthly[0].billing_scheme === 'tiered' && this.checkoutLimit) {
            return sum + product.prices.monthly[0].tiers.find(t => t.up_to === this.checkoutLimit).flat_amount
          }
          return sum + (product.prices.monthly[0].unit_amount || 0)
        }, 0) * this.activeLocations.length
      )
    },
    discount() {
      if (this.coupon) {
        if (this.coupon.amountOff) {
          return this.coupon.amountOff
        } else {
          return (this.subtotalPrice * this.coupon.percentOff) / 100
        }
      }
      return 0
    },
    totalPrice() {
      return this.subtotalPrice - this.discount
    }
  }
}
</script>

<style scoped>
.is-strikeout {
  text-decoration: line-through;
  font-size: 0.8em;
}
</style>
