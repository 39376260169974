<template lang="pug">
b-dropdown(v-model="color" scrollable :max-height="280" @input="onChange" :position="position")
  template(#trigger)
    .color-button.is-relative.is-clickable(:style="{'background-color': `#${color}`}" :class="!color ? 'has-background-striped' : ''")
  b-dropdown-item.px-3.py-2(:value="undefined" aria-role="listitem")
    .is-flex.is-align-items-center
      .is-flex-shrink-0.pr-3
        .color-button.has-background-striped
      .is-flex-grow
        h3.heading.is-5.m-0 
          b(v-t="'appointments.color_default_label'")
  b-dropdown-item.px-3.py-2(v-for="(col, name) in colors" :key="col" :value="col" aria-role="listitem")
    .is-flex.is-align-items-center
      .is-flex-shrink-0.pr-3
        .color-button(:style="{'background-color': `#${col}`}")
      .is-flex-grow
        h3.heading.is-5.m-0 
          b {{name}}
</template>
<script>
import COLORS from '../../../constants/appointmentColors'

export default {
  name: 'ColorPicker',
  props: {
    value: {type: String, default: undefined},
    position: {
      type: String,
      default: 'is-bottom-right'
    }
  },
  data() {
    return {
      color: undefined,
      colors: COLORS
    }
  },
  watch: {
    value(newVal) {
      this.color = newVal
    }
  },
  created() {
    this.color = this.value
  },
  methods: {
    onChange() {
      this.$emit('input', this.color || undefined)
    }
  }
}
</script>
