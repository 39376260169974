<template lang="pug">
.modal-card
  header.modal-card-head
    p.modal-card-title(data-qa="modal-header") 
      b(v-if="isNew" v-t="'locations_settings.details.title_new'")
      b(v-else v-t="'locations_settings.details.title_edit'")
    div
      a.button.is-white.is-small(@click="$emit('close')")
        span.icon
          i.mdi.mdi-close.mdi-24px
  section.modal-card-body.has-lightgrey-background        
    .columns.is-centered
      .column.is-8
        .box
          b-field(:label="$t('locations_settings.details.title_label')" :type="errors.has('title') ? 'is-danger': ''" :message="errors.has('title') ? errors.first('title') : ''")
            b-input(:placeholder="$t('locations_settings.details.title_placeholder')" data-vv-as="title" name="title" v-validate="{required: true, regex:/^[\x00-\x7F]*$/ }" icon="map-marker-outline" v-model="title")
          b-field(:label="$t('locations_settings.details.phone_label')", :type="errors.has('phone') || phoneValid === false ? 'is-danger': ''" :message="errors.has('phone') ? errors.first('phone') : (phoneValid === false ? 'The phone number is invalid': '')")
            phone-input(v-model="phone" @input="(phone, valid) => phone ? phoneValid = valid : phoneValid = undefined" name="phone" :placeholder="$t('business_settings.phone_placeholder')")
          
          multiple-images-upload(v-model="images" :max-size="1200")
          hr
          b-field
            b-switch(v-model="active" type="is-success") 
              strong {{ active ? $t('locations_settings.details.active') : $t('locations_settings.details.inactive') }}
      
          
          b-field(:label="$t('locations_settings.details.street_label')" :type="errors.has('street') ? 'is-danger': ''" :message="errors.has('street') ? errors.first('street') : ''")
            b-input(:placeholder="$t('locations_settings.details.street_placeholder')" data-vv-as="street" name="street" v-validate="{required: true, regex:/^[\x00-\x7F]*$/ }" icon="domain" v-model="street")
          b-field(:label="$t('locations_settings.details.street_localization_label')" v-if="company.languages.length > 1")
            LocalizedEditor(v-model="streets" :placeholder="$t('locations_settings.details.street_placeholder')")
          
          //b-field(:type="errors.has('streetAlt') ? 'is-danger': ''" :message="errors.has('streetAlt') ? errors.first('streetAlt') : ''")
            b-input(:placeholder="$t('locations_settings.details.street_2_placeholder')" data-vv-as="a" name="streetAlt" icon="domain" v-model="streetAlt")
          b-field( :label="$t('locations_settings.details.city_label')" :type="errors.has('city') ? 'is-danger': ''" :message="errors.has('city') ? errors.first('city') : ''")
            b-input(:placeholder="$t('locations_settings.details.city_placeholder')"  data-vv-as="city" name="city" v-validate="{regex:/^[\x00-\x7F]*$/ }" icon="domain" v-model="city")
          b-field(grouped)
            b-field(:label="$t('locations_settings.details.zip_label')" :type="errors.has('zip') ? 'is-danger': ''" :message="errors.has('zip') ? errors.first('zip') : ''")
              b-input(:placeholder="$t('locations_settings.details.zip_placeholder')" data-vv-as="zip" name="zip" v-validate="{regex:/^[\x00-\x7F]*$/ }" icon="domain" v-model="zip")  
              
            b-field(expanded :label="$t('locations_settings.details.state_label')" :type="errors.has('state') ? 'is-danger': ''" :message="errors.has('state') ? errors.first('state') : ''")
              b-input(:placeholder="$t('locations_settings.details.state_placeholder')" expanded data-vv-as="state" name="state" icon="domain" v-validate="{regex:/^[\x00-\x7F]*$/ }"  v-model="state")

          
          b-field(:label="$t('locations_settings.details.timezone_label')" grouped)
            b-checkbox(v-model="hasTimezone") {{$t('location_settings.details.has_different_timezone_label')}} 
          b-field(v-if="hasTimezone" :type="errors.has('timezone') ? 'is-danger': ''" :message="errors.has('timezone') ? errors.first('timezone') : ''")
            TimezoneSelect(v-model="timezone" data-vv-as="timezone" name="timezone" v-validate="'required'")
          GmapMap(:center="{lat: latitude, lng: longitude}" :zoom="10" :options="{mapTypeControl: false, scaleControl: false, streetViewControl: false, rotateControl: false} " map-type-id="roadmap" style="width: 100%; height: 250px")
            GmapMarker(:position="{lat: latitude, lng: longitude}" :clickable="true" :draggable="true" @dragend="onLocationUpdate")
          p.help(v-t="'location_settings.details.map_pointer_drag_help'")
        .box  
          h2.title.is-4 
            span.icon
              i.mdi.mdi-clock
            span  {{$t('locations_settings.working_hours_title')}}
          LocationWorkingHours(v-model="workingHours")
        .box
          h2.title.is-4 
            span.icon
              i.mdi.mdi-car-side
            span  {{$t('locations_settings.parking_title')}}
          LocationParkingDetails(v-model="parking")
        .box
          h2.title.is-4 
            span.icon
              i.mdi.mdi-format-list-checks
            span  {{$t('locations_settings.amenities_title')}}
          LocationAmenitiesDetails(v-model="selectedAmenities")
  footer.modal-card-foot
    .buttons
      action-button(:loading="submitting" @click="update" title="actions.save" permission="SETTINGS_LOCATIONS_MANAGE" :scope="!locationId || locationId !== user.location")
      button.button.is-outlined(type="button" @click="cancel" v-t="'actions.cancel'")
</template>

<script>
import {mapActions, mapState} from 'vuex'
import {cloneDeep} from 'lodash'
import MultipleImagesUpload from '../shared/MultipleImagesUpload'
import LocationWorkingHours from './LocationWorkingHours'
import LocationAmenitiesDetails from './LocationAmenitiesDetails'
import LocationParkingDetails from './LocationParkingDetails'
import TimezoneSelect from '../shared/TimezoneSelect'
import LocalizedEditor from '../shared/LocalizedEditor'
export default {
  name: 'LocationCreateEditForm',
  components: {
    MultipleImagesUpload,
    LocationWorkingHours,
    LocationParkingDetails,
    LocationAmenitiesDetails,
    TimezoneSelect,
    LocalizedEditor
  },
  props: {
    locationId: {type: String, default: undefined}
  },
  data() {
    return {
      isNew: false,
      title: undefined,
      phone: undefined,
      phoneValid: undefined,
      street: undefined,
      streets: {},
      streetAlt: undefined,
      city: undefined,
      state: undefined,
      zip: undefined,
      timezone: undefined,
      hasTimezone: false,
      latitude: 34,
      longitude: -118,
      active: true,
      images: [],
      submitting: false,
      workingHours: undefined,
      parking: undefined,
      selectedAmenities: []
    }
  },
  computed: {
    ...mapState('auth', ['user']),
    ...mapState('company', ['company'])
  },
  async created() {
    this.isNew = !this.locationId
    if (this.locationId) {
      await this.fetchLocation()
    }
  },
  methods: {
    ...mapActions('location', ['getById', 'createLocation', 'updateLocation']),
    async fetchLocation() {
      try {
        const location = await this.getById(this.locationId)

        this.id = location._id
        this.title = location.title
        this.phone = location.phone
        this.images = location.images && location.images.slice()
        if (location.address) {
          this.streets = cloneDeep(location.address.streets || {})
          this.street = location.address.street
          this.streetAlt = location.address.streetAlt
          this.city = location.address.city
          this.zip = location.address.zip
          this.state = location.address.state
        }
        if (location.location && location.location.coordinates) {
          this.latitude = location.location.coordinates[1]
          this.longitude = location.location.coordinates[0]
        }
        this.timezone = location.timezone
        this.hasTimezone = !!this.timezone

        this.latitude = this.latitude || 34
        this.longitude = this.longitude || -118
        this.active = this.isNew || location.active || false
        this.workingHours = location.workingHours
        this.selectedAmenities = location.amenities
        this.parking = location.parking
      } catch (error) {
        this.$handleAPIError(error)
        this.$emit('close')
      }
    },
    onLocationUpdate(location) {
      this.latitude = location.latLng.lat()
      this.longitude = location.latLng.lng()
    },
    async update() {
      let valid = await this.$validator.validateAll()
      if (!valid || this.phoneValid === false) {
        return
      }
      this.submitting = true
      let data = {
        title: this.title,
        phone: this.phone,
        images: this.images,
        address: {
          street: this.street,
          streets: this.streets,
          streetAlt: this.streetAlt,
          city: this.city,
          zip: this.zip,
          state: this.state
        },
        timezone: this.hasTimezone ? this.timezone : null,
        location: {type: 'Point', coordinates: [this.longitude, this.latitude]},
        active: this.active,
        workingHours: this.workingHours,
        amenities: this.selectedAmenities,
        parking: this.parking
      }
      try {
        if (this.locationId) {
          await this.updateLocation({id: this.locationId, data})
        } else {
          await this.createLocation(data)
        }
        this.$buefy.toast.open({message: this.$t('status.save_success'), type: 'is-success', queue: false})
        this.$emit('close')
      } catch (err) {
        this.$handleAPIError(err)
      }
      this.submitting = false
    },
    cancel() {
      this.$emit('close')
    }
  }
}
</script>
