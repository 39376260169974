<template lang="pug">
  div
      screen-title(title="platforms_settings.title")
      .columns
        .column.is-6
          .box
            .has-text-centered
              h2.title.is-4 Facebook Messenger
              img.platform-logo(src="../../assets/messenger_logo.svg")
              h4.heading {{$t('platforms_settings.status_label')}}
            .has-text-centered(v-if="company.messenger.active === true")
              h3.title.is-4.has-text-success.is-uppercase {{$t('platforms_settings.status_connected')}}
              label.label 
                span Page ID 
                strong {{company.messenger.fbId}}

              span.has-text-danger(@click="advancedMode = !advancedMode") {{$t('platforms_settings.actions.advanced_mode')}}
            .mt-3(v-if="advancedMode && company.messenger.active === true")
              .field.is-grouped.is-grouped-centered
                p.control
                  button.button.is-danger.is-outlined(@click="disconnect", :class="submitting ? 'is-loading': ''") 
                    span.icon
                      i.mdi.mdi-alert-outline
                    span {{$t('platforms_settings.actions.disconnect')}}
              FacebookConnect(@select="connect" :submitting="submitting")
            div(v-if="company.messenger.active === false")
              h3.title.is-3.has-text-danger.is-uppercase.has-text-centered {{$t('platforms_settings.status_disconnected')}}
              FacebookConnect(@select="connect" :submitting="submitting")
              br
</template>

<script>
import {mapState} from 'vuex'
import FacebookConnect from '../account/FacebookConnect'
import CompanyApi from '@/api/company'

export default {
  name: 'Platforms',
  components: {
    FacebookConnect
  },
  data() {
    return {
      submitting: false,
      advancedMode: false
    }
  },
  computed: {
    ...mapState('company', ['company'])
  },
  methods: {
    async connect(selectedPage) {
      this.submitting = true
      const data = {pageAccessToken: selectedPage.pageAccessToken, userAccessToken: selectedPage.userAccessToken}
      try {
        await CompanyApi.reconnectMessenger(data)
        await this.$store.dispatch('company/fetch', true)
        this.$buefy.toast.open({
          message: this.$t('platforms_settings.connection_success'),
          type: 'is-success',
          queue: false
        })
      } catch (error) {
        this.$handleAPIError(error)
      }
      this.submitting = false
    },
    disconnect() {
      this.$buefy.dialog.confirm({
        title: this.$t('platforms_settings.disconnect_confirm.title'),
        message: this.$t('platforms_settings.disconnect_confirm.body'),
        cancelText: this.$t('platforms_settings.disconnect_confirm.cancel'),
        confirmText: this.$t('platforms_settings.disconnect_confirm.action'),
        type: 'is-danger',
        onConfirm: async () => {
          this.submitting = true
          try {
            await CompanyApi.disconnectMessenger()
          } catch (error) {
            this.$handleAPIError(error)
          }
          this.submitting = false
        }
      })
    }
  }
}
</script>

<style scoped>
.platform-logo {
  height: 96px;
  margin-bottom: 20px;
}
.scan-code {
  height: 128px;
}
</style>
