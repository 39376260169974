const numeral = require('numeral')
const {flatten} = require('lodash')
const COUPON_TYPES = require('../constants/couponTypes').TYPES
const CURRENCIES = require('../constants/currencies')
const PAYMENT_TYPES = require('../constants/paymentTypes')
const COMMISSION_TYPES = require('../constants/commissionTypes')
const COMMISSION_BASE_TYPES = require('../constants/commissionBaseTypes')
const REWARD_UNIT = require('../constants/rewardUnit')
const SALE_ITEM_TYPES = require('../constants/saleItemTypes')
const {roundDecimal} = require('./utils')
class SaleHelper {
  recalculateTotals({items, payments, priceIncludesTax, customerCoupon}) {
    let total = 0
    let subtotal = 0
    let itemsTotalTax = 0
    let itemsDiscount = 0
    let itemsCouponDiscount = 0
    let itemsManualDiscount = 0
    let itemsMembershipDiscount = 0

    for (let item of items) {
      total += item.total
      subtotal += item.subtotal
      itemsTotalTax += item.tax
      itemsCouponDiscount += item.couponDiscount
      itemsManualDiscount += item.manualDiscount
      itemsMembershipDiscount += item.membershipDiscount
      itemsDiscount += item.discount
    }

    const effectiveTaxRate = !subtotal || subtotal === itemsDiscount ? 0 : itemsTotalTax / (subtotal - itemsDiscount)

    const pointsDiscount = this.calculatePointsPaymentDiscount(payments)
    let pointsDiscountBT = pointsDiscount
    if (priceIncludesTax) {
      pointsDiscountBT = roundDecimal(pointsDiscount / (1 + effectiveTaxRate))
    }

    const voucherDeduction = this.calculateVoucherPaymentDeduction(payments)
    let voucherDeductionBT = voucherDeduction
    if (priceIncludesTax) {
      voucherDeductionBT = roundDecimal(voucherDeduction / (1 + effectiveTaxRate))
    }

    const couponDiscount = this.calculateSaleCouponDiscount({
      items,
      priceIncludesTax,
      customerCoupon
    })
    let couponDiscountBT = couponDiscount
    if (priceIncludesTax) {
      couponDiscountBT = roundDecimal(couponDiscount / (1 + effectiveTaxRate))
    }

    const saleDiscount = pointsDiscount + couponDiscount + voucherDeduction
    let saleDiscountBT = pointsDiscountBT + couponDiscountBT + voucherDeductionBT

    let taxDiscountAdjustment = roundDecimal(saleDiscountBT * effectiveTaxRate)
    if (priceIncludesTax) {
      taxDiscountAdjustment = roundDecimal(saleDiscount - saleDiscountBT)
    }

    let saleTotal = roundDecimal(total - saleDiscount - taxDiscountAdjustment)

    if (priceIncludesTax) {
      saleTotal = total - saleDiscount
    }
    const effectiveDiscount = (total && saleTotal / total) || 0
    const taxes = this.aggregateTaxes(items).map(tax => {
      return {
        tax: tax.tax,
        value: roundDecimal(tax.value * effectiveDiscount)
      }
    })
    const due = Math.max(roundDecimal(saleTotal - this.calculatePayment(payments)), 0)
    let dueBT = due
    if (!priceIncludesTax) {
      dueBT = roundDecimal(due / (1 + effectiveTaxRate))
    }
    const asyncPayment = this.calculateAsyncPayment(payments)

    const rewardsBase =
      roundDecimal(items.reduce((sum, i) => sum + (i.rewardsBase || 0), 0)) -
      (priceIncludesTax ? saleDiscount : saleDiscountBT)
    return {
      total: saleTotal,
      balance: -due,
      paid: roundDecimal(saleTotal - due),
      asyncPayment,
      // paymentDue: Math.max(roundDecimal(due - asyncPayment), 0),
      due: Math.max(roundDecimal(due - asyncPayment), 0),
      dueBT: Math.max(roundDecimal(dueBT - asyncPayment), 0),
      subtotal,
      discount: roundDecimal(itemsDiscount + saleDiscountBT),
      tax: roundDecimal(itemsTotalTax - taxDiscountAdjustment),
      discounts: {
        itemsCoupon: itemsCouponDiscount,
        items: itemsManualDiscount,
        membership: itemsMembershipDiscount,
        points: pointsDiscountBT,
        coupon: couponDiscountBT,
        voucher: voucherDeductionBT
      },
      taxes,
      rewardsBase
    }
  }

  aggregateTaxes(items) {
    let taxes = flatten(items.filter(s => s.taxes && s.taxes.length).map(s => s.taxes))
    let taxesMap = {}
    for (let tax of taxes) {
      taxesMap[tax.tax] = taxesMap[tax.tax] || {value: 0, tax: tax.tax}
      taxesMap[tax.tax].value += tax.value
    }
    return Object.values(taxesMap)
  }

  recalculate({
    item,
    items,
    taxes,
    priceIncludesTax,
    customerCoupon,
    commissionType,
    commissionRate,
    commissionBase,
    memberships,
    rewardsEligible
  }) {
    const totalTaxRate = taxes.reduce((total, {rate}) => total + rate, 0)

    item.priceAdjustment = item.manualPrice - item.price
    item.finalPrice = item.manualPrice

    let couponDiscount = this.getCouponDiscountOnItem({customerCoupon, items, item})
    if (couponDiscount) {
      item.customerCoupon = customerCoupon._id
    }

    let eligibleMembership = this.getMembershipDiscountOnItem({memberships, item})
    let membershipDiscount = 0
    if (eligibleMembership) {
      membershipDiscount = eligibleMembership.discount
      item.membership = eligibleMembership.membership._id
    }
    let itemManualDiscount = item.price >= item.manualPrice ? item.price - item.manualPrice : 0
    let manualDiscount = itemManualDiscount * (item.qty || 1)

    if (priceIncludesTax) {
      manualDiscount = manualDiscount / (1 + totalTaxRate)
      couponDiscount = couponDiscount / (1 + totalTaxRate)
      membershipDiscount = membershipDiscount / (1 + totalTaxRate)
    }

    let itemPrice = Math.max(item.price, item.manualPrice)
    let itemPriceBT = itemPrice
    if (priceIncludesTax) {
      itemPriceBT = itemPrice / (1 + totalTaxRate)
    }

    const discount = manualDiscount + couponDiscount + membershipDiscount
    const itemDiscount = itemManualDiscount + couponDiscount + membershipDiscount

    const subtotal = itemPriceBT * (item.qty || 1)
    const tax = (subtotal - discount) * totalTaxRate

    item.taxes = taxes.map(tax => {
      return {
        tax: tax.tax,
        rate: tax.rate,
        name: tax.name,
        value: roundDecimal((subtotal - discount) * tax.rate)
      }
    })

    item.itemPriceBT = roundDecimal(itemPriceBT)
    item.itemPrice = itemPrice
    item.subtotal = roundDecimal(subtotal)

    item.discount = roundDecimal(discount)
    item.itemDiscount = roundDecimal(itemDiscount)
    item.couponDiscount = roundDecimal(couponDiscount)
    item.membershipDiscount = roundDecimal(membershipDiscount)
    item.manualDiscount = roundDecimal(manualDiscount)
    item.tax = roundDecimal(tax)

    let total

    if (priceIncludesTax) {
      total = roundDecimal(subtotal - discount + tax)
    } else {
      total = roundDecimal((subtotal - discount) * (1 + totalTaxRate))
    }

    total = (total * 100) % 10 === 1 ? roundDecimal(total - 0.01) : total

    item.total = total

    if (rewardsEligible) {
      item.rewardsBase = priceIncludesTax ? item.total : roundDecimal(item.subtotal - item.discount)
    } else {
      item.rewardsBase = 0
    }
    item.commissionRate = commissionRate

    const taxableSubtotal = commissionType === COMMISSION_TYPES.BEFORE_TAX ? subtotal : subtotal + tax
    let base
    if (commissionBase === COMMISSION_BASE_TYPES.AFTER_DISCOUNT) {
      base = taxableSubtotal - (manualDiscount + couponDiscount + membershipDiscount)
    } else {
      base = taxableSubtotal - membershipDiscount
    }
    item.commission = Math.round(base * commissionRate) / 100
  }

  calculatePayment(payments) {
    return payments.reduce(
      (sum, payment) =>
        sum +
        ([PAYMENT_TYPES.POINTS, PAYMENT_TYPES.VOUCHER, PAYMENT_TYPES.LINK_PAY].includes(payment.type)
          ? 0
          : payment.paid),
      0
    )
  }
  calculatePointsPaymentDiscount(payments) {
    let pointsPayment = payments.find(p => p.type === PAYMENT_TYPES.POINTS)
    if (!pointsPayment) {
      return 0
    }
    return pointsPayment.paid
  }
  calculateAsyncPayment(payments) {
    let asyncPayment = payments.find(p => p.type === PAYMENT_TYPES.LINK_PAY)
    if (!asyncPayment) {
      return 0
    }
    return asyncPayment.paid
  }
  calculateVoucherPaymentDeduction(payments) {
    return payments.reduce((sum, p) => sum + (p.type === PAYMENT_TYPES.VOUCHER && p.paid) || 0, 0)
  }

  getMembershipDiscountOnItem({memberships, item}) {
    if (!memberships || !memberships.length) {
      return undefined
    }
    let eligibleMembership = this.findEligibleMembership({memberships, item})
    if (eligibleMembership) {
      return {membership: eligibleMembership, discount: item.manualPrice}
    }
    return undefined
  }

  findEligibleMembership({memberships, item}) {
    return memberships.find(mem => {
      return !!mem.remainingServices.find(
        r => r.service === item.service && r.option === item.option && (r.qty > 0 || r.qty === undefined)
      )
    })
  }
  findEligibleVoucherItems({voucher, items}) {
    let it = items.filter(s => s.type === SALE_ITEM_TYPES.SERVICE || s.type === SALE_ITEM_TYPES.PRODUCT)
    if (!voucher.services.length) {
      return it
    }
    return it.filter(s => voucher.services.find(vs => vs.service === s.service && vs.option === s.option))
  }

  getCouponDiscountOnItem({customerCoupon, items, item}) {
    if (!customerCoupon || (customerCoupon && !this.isEligibleCoupon({coupon: customerCoupon.coupon, items}))) {
      return 0
    }

    const coupon = customerCoupon.coupon
    if (
      (coupon.service &&
        coupon.service.service &&
        item.service === coupon.service.service &&
        coupon.service.option === item.option) ||
      (coupon.product && coupon.product === item.product)
    ) {
      switch (coupon.type) {
        case COUPON_TYPES.DISCOUNT:
          return Math.round((item.manualPrice * coupon.value) / 100)
        case COUPON_TYPES.OFF:
          return Math.min(item.manualPrice, coupon.value)
        case COUPON_TYPES.SERVICE:
          return item.manualPrice
        case COUPON_TYPES.PRODUCT:
          return item.manualPrice
      }
    }
    return 0
  }

  getSaleTotal(items) {
    return items.reduce((sum, item) => sum + item.total, 0)
  }
  getSaleSubtotal(items) {
    return items.reduce((sum, item) => sum + item.subtotal, 0)
  }
  calculateSaleCouponDiscount({customerCoupon, priceIncludesTax, items = []}) {
    if (!customerCoupon || (customerCoupon && !this.isEligibleCoupon({coupon: customerCoupon.coupon, items}))) {
      return 0
    }
    const coupon = customerCoupon.coupon
    if ((coupon.service && coupon.service.service) || coupon.product) {
      return 0
    }

    let discount = 0
    switch (coupon.type) {
      case COUPON_TYPES.DISCOUNT:
        if (priceIncludesTax) {
          discount = Math.round(this.getSaleTotal(items) * coupon.value) / 100
        } else {
          discount = Math.round(this.getSaleSubtotal(items) * coupon.value) / 100
        }
        break
      case COUPON_TYPES.OFF:
        if (priceIncludesTax) {
          discount = Math.min(coupon.value, this.getSaleTotal(items))
        } else {
          discount = Math.min(coupon.value, this.getSaleSubtotal(items))
        }
        break
    }
    return discount
  }
  calculateRewardsSaleDiscount({customerCoupon, totalSale}) {
    if (!customerCoupon) {
      return 0
    }
    const coupon = customerCoupon.coupon
    if ((coupon.service && coupon.service.service) || coupon.product) {
      return 0
    }
    let discount = 0
    switch (coupon.type) {
      case COUPON_TYPES.DISCOUNT:
        discount = Math.round(totalSale * coupon.value) / 100
        break
      case COUPON_TYPES.OFF:
        discount = Math.min(coupon.value, totalSale)
        break
    }
    return discount
  }

  isEligibleCoupon({items = [], coupon}) {
    if (coupon.service && coupon.service.service) {
      return !!items.find(
        s =>
          s.service &&
          s.service.valueOf().toString() === coupon.service.service.valueOf().toString() &&
          s.option.valueOf().toString() === coupon.service.option.valueOf().toString()
      )
    }
    if (coupon.product) {
      return !!items.find(s => s.product && s.product.valueOf().toString() === coupon.product.valueOf().toString())
    }
    if (coupon.package && coupon.package.length) {
      for (const packageItem of coupon.package) {
        let contains = !!items.find(
          s =>
            s.service &&
            s.service.valueOf().toString() === packageItem.service.valueOf().toString() &&
            s.option.valueOf().toString() === packageItem.option.valueOf().toString()
        )
        if (!contains) {
          return false
        }
      }
    }
    return true
  }

  getPricingOption(option, staffId) {
    let res = {
      minPrice: option.minPrice !== undefined ? option.minPrice : option.price,
      maxPrice:
        option.maxPrice !== undefined ? option.maxPrice : option.priceUpto !== undefined ? option.priceUpto : undefined
    }
    if (staffId && option.override && option.override[staffId] !== undefined) {
      res.price = option.override[staffId].price
      res.priceUpto = option.override[staffId].priceUpto
      res.duration = option.override[staffId].duration
    }
    if (res.price === undefined) {
      res.price = option.price
    }
    if (res.priceUpto === undefined) {
      res.priceUpto = option.priceUpto
    }
    if (res.duration === undefined) {
      res.duration = option.duration
    }
    return res
  }

  formatCurrency(value, currencyCode) {
    if (value === undefined || value === null) {
      return ''
    }
    let currency = CURRENCIES[currencyCode.toUpperCase()]

    let absValue = (value && numeral(Math.abs(value)).format('0,0.[00]')) || '0'
    let res
    if (currency.placement === 'right') {
      res = `${absValue}${currency.value}`
    } else {
      res = `${currency.value}${absValue}`
    }
    if (value < 0) {
      return `(${res})`
    }
    return res
  }
  formatPoints(value, unit, currencyCode) {
    if (unit === REWARD_UNIT.CURRENCY) {
      return this.formatCurrency(value, currencyCode)
    }
    return (value && numeral(value).format('0,0')) || '0'
  }
}

module.exports = new SaleHelper()
