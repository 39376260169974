<template lang="pug">
div
  screen-title(title="appointments.list_title")
    a.button.is-uppercase.is-hidden-mobile(:href="exportLink" target="_blank" v-if="$rfSubscribed(PRODUCTS.CRM_PRO) && $rfHasPermission('APPOINTMENT_EXPORT')") 
      span.icon
        i.mdi.mdi-download
      b {{$t('actions.export_data')}}
  
  
              
  .columns
    .column
      b-field(grouped group-multiline)
        b-field 
          PeriodSelector(v-model="period" @input="updateRouteParams")
        b-field
          LocationSelector(v-model="currentLocation" @input="updateRouteParams")
        b-field
          StaffSelect(
            :staffId="currentStaff" 
            expanded 
            @input="updateStaff" 
            :locationId="currentLocation" 
            :hasPreferred="false"
            allowEmpty
            showArchived
            )
        b-field
          ResourceSelect(
            v-model="currentResource"
            expanded 
            @input="updateRouteParams" 
            :locationId="currentLocation" 
            allowEmpty
            )
      .box
        b-table.is-vertically-aligned(
          :data="appointments" 
          :loading="loading" 
          hoverable=true 
          @click="showDetails"
          custom-row-key="serviceId"
          paginated
          backend-pagination
          :total="total"
          :per-page="perPage"
          @page-change="onPageChange"
          :mobile-cards="false"
          backend-sorting
          default-sort-direction="desc"
          :default-sort="[sortField, sortOrder]"
          @sort="onSort"
          )
          template(slot="empty")
            empty-content(v-if="!loading"  title="appointment.no_appointments")
              //action-button(
                @click="createSale",
                icon='basket-outline',
                title='sale.actions.new_sale',
                permission='SALE_CREATE'
                data-qa="action-new-sale")
          
          b-table-column(field="customer" :label="$t('appointment.client_heading')" v-slot="props" sortable)
            //customer-tiny-preview(:value="props.row.customer" size="small")
            a(@click.stop="showCustomerDetails(props.row.customer)" v-if="props.row.customer")
              customer-name(:value="props.row.customer" v-if="props.row.customer")
            span(v-else) {{$t('customer.walk_in')}}
          b-table-column(field="status" :label="$t('appointment.status_heading')" v-slot="props" sortable)
            p 
              AppointmentStatusIcon(:status="props.row.status" show-text is-tag)
          b-table-column(field="services.service" :label="$t('appointment.service_heading')" v-slot="props" sortable)
            p 
              service-name-view(:value="props.row.services" v-if="props.row.services")
          b-table-column(field="services.start" :label="$t('appointment.start_heading')" v-slot="props" sortable)
            p 
              date-view(:value="props.row.services.start" format="L LT")
          
          b-table-column(field="services.duration" :label="$t('appointment.duration_heading')" v-slot="props" sortable numeric)
            p 
              duration-view(:value="props.row.services.duration")
          b-table-column(field="location" :label="$t('appointment.location_heading')" v-slot="props" sortable :visible="hasMultipleLocations")
            location-view(:value="props.row.location")

          b-table-column(field="services.staff" :label="$t('appointment.staff_heading')" v-slot="props" sortable)
            p 
              staff-view(:id="props.row.services.staff" v-if="props.row.services.staff")
          
          b-table-column(field="services.resource" :label="$t('appointment.resource_heading')" v-slot="props" sortable)
            p 
              resource-view(:value="props.row.services.resource" v-if="props.row.services.resource")
          
          
          
          b-table-column(field="services.price" :label="$t('appointment.price_heading')" v-slot="props" sortable numeric)
            p 
              currency-view(:value="props.row.services.price")

          b-table-column(field="user" :label="$t('appointment.booked_by_heading')" v-slot="props" sortable)
            staff-view(:id="props.row.user" v-if="props.row.user")
            .tag.is-warning.mb-0(v-else-if="props.row.source === APPOINTMENT_SOURCE.ONLINE")
              span.icon.is-small
                i.mdi.mdi-web
              span online
          b-table-column(field="created" :label="$t('appointment.created_heading')" v-slot="props" sortable)
            date-view(:value="props.row.created" format="L LT")
          
              
            
</template>
<script>
import {mapGetters, mapState} from 'vuex'
//import dayjs from 'dayjs'
import AppointmentStatusIcon from '../calendar/AppointmentStatusIcon'
import AppointmentsApi from '@/api/appointments'
import LocationSelector from '../helpers/LocationSelector'
import {PRODUCTS} from '../../../constants/products'
import PeriodSelector from '../helpers/PeriodSelector'
import CustomerDetailsMobile from '../customers/CustomerDetailsMobile.vue'
import Details from '../customers/Details.vue'
import CreateEditAppointment from './CreateEditAppointment.vue'
import StaffSelect from '../staff/StaffSelect'
import ResourceSelect from '../resources/ResourceSelect'
import APPOINTMENT_SOURCE from '../../../constants/appointmentSource'

export default {
  name: 'AppointmentsListView',
  components: {
    LocationSelector,
    AppointmentStatusIcon,
    PeriodSelector,
    StaffSelect,
    ResourceSelect
  },
  props: {
    location: {type: String, default: undefined},
    staff: {type: String, default: undefined},
    resource: {type: String, default: undefined},
    from: {type: String, default: undefined},
    to: {type: String, default: undefined}
  },
  data() {
    return {
      appointments: undefined,
      total: 0,
      PRODUCTS,
      APPOINTMENT_SOURCE,
      loading: false,
      sortOrder: 'desc',
      sortField: 'services.start',
      page: 1,
      perPage: 10,
      currentLocation: undefined,
      currentStaff: undefined,
      currentResource: undefined,
      period: undefined,
      apptModal: undefined
    }
  },
  computed: {
    ...mapState('company', ['company']),
    ...mapGetters('location', ['hasMultipleLocations']),
    exportLink() {
      let url = this.$url('appointments/exportExpanded', {
        location: this.location,
        staff: this.staff,
        resource: this.resource,
        from: this.from,
        to: this.to,
        sortBy: this.sortField,
        sortDir: this.sortOrder,
        page: this.page,
        perPage: this.perPage
      })
      return url
    }
  },
  watch: {
    from(newValue, oldValue) {
      if (this.from && this.to) {
        this.period = {from: this.from, to: this.to}
      } else {
        this.period = undefined
      }

      if (newValue !== oldValue) {
        this.fetchData()
      }
    },
    to(newValue, oldValue) {
      if (this.from && this.to) {
        this.period = {from: this.from, to: this.to}
      } else {
        this.period = undefined
      }
      if (newValue !== oldValue) {
        this.fetchData()
      }
    },
    location() {
      this.currentLocation = this.location
      this.fetchData()
    },
    staff() {
      this.currentStaff = this.staff
      this.fetchData()
    },
    resource() {
      this.currentResource = this.resource
      this.fetchData()
    }
  },

  async created() {
    this.currentLocation = this.location
    this.currentStaff = this.staff
    this.currentResource = this.resource
    if (this.from && this.to) {
      this.period = {from: this.from, to: this.to}
    }

    await this.fetchData()
  },
  methods: {
    updateRouteParams() {
      let query = {
        location: this.currentLocation,
        staff: this.currentStaff,
        resource: this.currentResource,
        from: this.period && this.period.from.toISOString(),
        to: this.period && this.period.to.toISOString()
      }
      this.$router.push({name: 'Appointments', query}).catch(() => {})
    },
    async fetchData() {
      this.loading = true
      try {
        let data = await AppointmentsApi.listExpanded({
          location: this.location,
          staff: this.staff,
          resource: this.resource,
          from: this.from,
          to: this.to,
          sortBy: this.sortField,
          sortDir: this.sortOrder,
          page: this.page,
          perPage: this.perPage
        })

        this.appointments = data.appointments
        this.total = data.total
      } catch (err) {
        this.$handleAPIError(err)
      }
      this.loading = false
    },
    onSort(field, order) {
      this.sortField = field
      this.sortOrder = order
      this.fetchData()
    },
    onPageChange(page) {
      this.page = page
      this.fetchData()
    },
    async updateStaff({staffId}) {
      this.currentStaff = staffId || undefined
      this.updateRouteParams()
    },
    showDetails(appointment) {
      if (this.apptModal) {
        this.apptModal.close()
      }
      this.apptModal = this.$buefy.modal.open({
        parent: this.$parent,
        props: {appointmentId: appointment._id},
        component: CreateEditAppointment,
        customClass: !this.isMobile() ? 'half-modal' : '',
        animation: !this.isMobile() ? 'slide-right' : 'zoom-out',
        hasModalCard: this.isMobile(),
        fullScreen: true,
        canCancel: ['escape', 'outside']
      })
    },
    showCustomerDetails(customerId) {
      this.$buefy.modal.open({
        parent: this,
        component: this.isMobile() ? CustomerDetailsMobile : Details,
        hasModalCard: true,
        fullScreen: true,
        props: {customerId},
        canCancel: ['escape', 'outside']
        // onCancel: () => this.$router.push({name: 'Customers'}).catch(() => {}),
        // events: {
        //   close: () => this.$router.push({name: 'Customers'}).catch(() => {})
        // }
      })
    }
  }
}
</script>
