<template lang="pug">
.notification.mb-1.mt-1(:class="[type, paddingClass]" @click="$emit('click')")
  .media(style="align-items: center")
    .media-left(v-if="hasIcon")
      span.icon(:class="`is-medium`")
        i.mdi(:class="[noteIcon, size === 'medium' ? 'mdi-36px': 'mdi-24px']")
    .media-content(style="white-space:wrap")
      slot
    .media-right
      slot(name="right")
</template>
<script>
export default {
  props: {
    type: {type: String, default: undefined},
    size: {type: String, default: 'medium'},
    hasIcon: {type: Boolean, default: true},
    icon: {type: String, default: undefined}
  },
  computed: {
    noteIcon() {
      if (this.icon) {
        return `mdi-${this.icon}`
      }
      switch (this.type) {
        case 'is-warning':
          return 'mdi-alert-circle-outline'
        case 'is-danger':
          return 'mdi-alert-outline'
        case 'is-success':
          return 'mdi-check-circle-outline'
        default:
          return 'mdi-alert-circle-outline'
      }
    },
    paddingClass() {
      switch (this.size) {
        case 'medium':
          return 'p-4'
        case 'small':
          return 'px-3 py-2'
        default:
          return 'p-4'
      }
    }
  }
}
</script>
