<script>
import VueChart from 'vue-chartjs'
import ReportsApi from '@/api/reports'
import dayjs from 'dayjs'
import COLORS from '../../helpers/chartColors'
export default {
  name: 'DailyMemberships',
  extends: VueChart.Line,
  props: {
    period: {type: Object, default: undefined},
    location: {type: String, default: undefined}
  },
  data() {
    return {
      loading: false
    }
  },
  watch: {
    period() {
      return this.fetchData()
    },
    location() {
      return this.fetchData()
    }
  },
  created() {
    if (this.period) {
      return this.fetchData()
    }
  },
  methods: {
    async fetchData() {
      this.loading = true
      try {
        const data = await ReportsApi.getDailyMemberships({
          from: this.period.from,
          to: this.period.to,
          location: this.location
        })
        const dataset = {
          labels: data.days.map(d => dayjs(d, 'D-M-YYYY').format('MMM D')),
          datasets: [
            {
              backgroundColor: COLORS.BLUE,
              borderColor: COLORS.BLUE,
              label: this.$t('rewards.new_signups'),
              data: data.data,
              lineTension: 0.1,
              showLine: true,
              pointRadius: 0,
              fill: true
            }
          ]
        }
        this.$data._chart && this.$data._chart.destroy()
        this.renderChart(dataset, {
          legend: {
            display: false
          },
          title: {
            display: false,
            text: 'Checkouts'
          },
          tooltips: {
            mode: 'nearest',
            intersect: false
          },
          responsive: true,
          maintainAspectRatio: false,
          scales: {
            xAxes: [
              {
                display: true
              }
            ],
            yAxes: [
              {
                display: true,
                scaleLabel: {
                  display: false,
                  labelString: 'Checkouts'
                },
                ticks: {
                  min: 0,
                  beginAtZero: true,
                  precision: 0
                }
              }
            ]
          }
        })
      } catch (error) {
        this.$handleAPIError(error)
      }
      this.loading = false
    }
  }
}
</script>
