import Navbar from '@/components/Navbar'

import Container from '@/components/surveys/Container'
import Menu from '@/components/surveys/Menu'
import Dashboard from '@/components/surveys/Dashboard'
import Settings from '@/components/surveys/Settings'
import CustomerCare from '@/components/customercare/CustomerCare'

import {PRODUCTS} from '../../constants/products'

export default [
  {
    path: '/surveys',
    name: 'Surveys',
    meta: {
      requiresAuth: true,
      title: 'navbar.surveys'
    },
    components: {
      default: Container,
      navbar: Navbar,
      menu: Menu
    },
    children: [
      {
        path: 'dashboard',
        component: Dashboard,
        meta: {
          product: PRODUCTS.SURVEYS,
          title: 'navbar.surveys',
          pageTitle: 'surveys.dashboard.title',
          requiresAuth: true
        }
      },
      {
        path: 'issues',
        component: CustomerCare,
        meta: {
          product: PRODUCTS.SURVEYS,
          title: 'navbar.surveys',
          pageTitle: 'navbar.unhappy_visits',
          requiresAuth: true
        }
      },

      {
        path: 'settings',
        component: Settings,
        meta: {
          product: PRODUCTS.SURVEYS,
          title: 'navbar.surveys',
          pageTitle: 'surveys.settings.title',
          requiresAuth: true
        }
      }
    ]
  }
]
