<template lang="pug">
  .modal-card
    header.modal-card-head
      p.modal-card-title(data-qa="modal-header") 
        b(v-t="'tax_settings.tax_calculation.title'")
      div
        a.button.is-white.is-small(@click="$emit('close')")
          span.icon
            i.mdi.mdi-close.mdi-24px
    section.modal-card-body
      note(type="is-warning") {{$t('tax_settings.tax_calculation.warning')}}
      b-field(:label="$t('tax_settings.tax_calculation.tax_calculation_label')")
        b-radio(v-model="priceIncludesTax" :native-value="true") {{$t('tax_settings.tax_calculation.prices_include_tax')}} 
        b-radio(v-model="priceIncludesTax" :native-value="false") {{$t('tax_settings.tax_calculation.prices_exclude_tax')}} 
      b-field(:label="$t('tax_settings.tax_calculation.commission_calculation_label')")
        b-radio(v-model="commissionType" :native-value="COMMISSION_TYPES.BEFORE_TAX") {{$t('tax_settings.tax_calculation.commission_before_tax')}} 
        b-radio(v-model="commissionType" :native-value="COMMISSION_TYPES.AFTER_TAX") {{$t('tax_settings.tax_calculation.commission_after_tax')}} 
    footer.modal-card-foot
      .buttons
        action-button(title="actions.save" @click="submit" :loading="submitting" data-qa="action-save")
        button.button(@click="$emit('close')" v-t="'actions.cancel'")  
    
</template>

<script>
import {mapActions, mapState} from 'vuex'
import COMMISSION_TYPES from '../../../constants/commissionTypes'
export default {
  name: 'TaxCalculationModal',
  data() {
    return {
      submitting: false,
      priceIncludesTax: undefined,
      commissionType: COMMISSION_TYPES.BEFORE_TAX,
      COMMISSION_TYPES
    }
  },
  computed: {
    ...mapState('company', ['company'])
  },
  async created() {
    this.priceIncludesTax = this.company.priceIncludesTax
    this.commissionType = this.company.commissionType
  },
  methods: {
    ...mapActions('company', ['updateTaxCalculation']),
    async submit() {
      const valid = await this.$validator.validateAll()
      if (this.submitting || !valid) {
        return
      }
      this.submitting = true
      let data = {
        priceIncludesTax: this.priceIncludesTax,
        commissionType: this.commissionType
      }
      try {
        await this.updateTaxCalculation(data)
        this.$buefy.toast.open({message: this.$t('status.save_success'), type: 'is-success', queue: false})
        this.$emit('close')
      } catch (error) {
        this.$handleAPIError(error)
      }
      this.submitting = false
    },
    cancel() {
      this.$emit('close')
    }
  }
}
</script>
