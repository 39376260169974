<template lang="pug">
  div.is-flex.is-align-items-center(v-if="product")
    ProductPic(:product="product" :class="size") 
    .ml-2
      p
        span {{product.name}} 
        span.has-text-grey(v-if="sku && product.sku")  · {{product.sku}}
        span.has-text-grey(v-if="price")  · 
          currency-view(:value="product.retailPrice")
      slot

</template>
<script>
import {mapActions} from 'vuex'
import ProductPic from './ProductPic'
export default {
  components: {
    ProductPic
  },
  props: {
    productId: {type: String, required: true},
    sku: {type: Boolean, default: false},
    price: {type: Boolean, default: false},
    size: {type: String, default: 'is-48x48'}
  },
  data() {
    return {
      product: undefined
    }
  },
  watch: {
    productId() {
      this.fetchData()
    }
  },
  created() {
    this.fetchData()
  },
  methods: {
    ...mapActions('inventory', ['fetchProduct']),
    async fetchData() {
      this.loading = true
      try {
        this.product = await this.fetchProduct(this.productId)
      } catch (err) {
        this.$handleAPIError(err)
      }
      this.loading = false
    }
  }
}
</script>
