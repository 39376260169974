<template lang="pug">
div(ref="contentEl" :name="name" :contenteditable="editable && !disabled" :class="disabled ? 'has-text-grey': ''" @input="onInput" @focus="selectContent" @blur="onBlur" :placeholder="placeholder || $t('misc.content_editable_placeholder')")
</template>
<script>
export default {
  props: {
    value: {type: [String, Number], default: ''},
    placeholder: {type: String, default: undefined},
    editable: {type: Boolean, default: true},
    disabled: {type: Boolean, default: false},
    formatter: {type: Function, default: undefined},
    name: {type: String, default: undefined}
  },
  data() {
    return {
      content: undefined
    }
  },
  watch: {
    value(newV) {
      if (this.content === newV) {
        return
      }
      this.content = newV
      this.$el.innerText = this.formatContent()
    }
  },
  mounted() {
    this.content = this.value
    this.$el.innerText = this.formatContent()
  },
  methods: {
    formatContent() {
      if (this.formatter) {
        return this.formatter(this.content)
      }
      return this.content
    },
    onInput() {
      this.content = this.$el.innerText
      this.$emit('input', this.content)
    },
    focus() {
      this.$refs.contentEl.focus()
    },
    onBlur() {
      this.$el.innerText = this.formatContent()
    },
    selectContent() {
      this.$el.innerText = this.content
      var range = document.createRange()
      range.selectNodeContents(this.$refs.contentEl)
      var sel = window.getSelection()
      sel.removeAllRanges()
      sel.addRange(range)
    }
  }
}
</script>
