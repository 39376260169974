<template lang="pug">
  b-field
    b-select(v-model="status" name="status" :placeholder="$t('sale.status_select_placeholder')" icon="label-variant-outline" @input="onChange" )
      option(value) {{$t('sale_status.all')}}
      option(v-for="st in statuses" :value="st") {{$t(`sale_status.${st}`)}}
</template>

<script>
import SALE_STATUS from '../../../constants/saleStatus'
export default {
  name: 'StatusSelect',
  props: {
    value: {type: String, default: undefined}
  },
  data() {
    return {
      status: undefined,
      statuses: [SALE_STATUS.DRAFT, SALE_STATUS.COMPLETED, SALE_STATUS.REFUNDED, SALE_STATUS.VOID]
    }
  },
  watch: {
    value(newVal) {
      this.status = newVal
    }
  },
  created() {
    this.status = this.value
  },
  methods: {
    onChange() {
      this.$emit('input', this.status)
    }
  }
}
</script>
