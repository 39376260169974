<template lang="pug">
.modal-card(style="width: auto")
  header.modal-card-head
    p.modal-card-title(data-qa="modal-header") 
      b(v-t="'sms.center_title'")
    div
      a.button.is-white.is-small(@click="$emit('close')")
        span.icon
          i.mdi.mdi-close.mdi-24px
  section.modal-card-body
    em-loading(:active="loading" :is-full-page="false")
    p.title.is-5.mb-3(v-t="'sms.balance_header'")
    .columns
      .column
        .card
          .card-content
              p.heading(v-t="'sms_type.transactional'")
              h3.title.is-4.tag(:class="getClassForCredit(smsCredits.transactional)") 
                number-view(:value="smsCredits.transactional")
              p.subtitle.is-6(v-t="'sms.disclaimer_transactional'")
          footer.card-footer
            a.card-footer-item(@click="buyCredits('transactional')")
              span.icon
                i.mdi.mdi-plus-circle-outline
              span(v-t="'sms.actions.top_up'")
      .column
        .card
          .card-content
            p.heading(v-t="'sms_type.promo'")
            h3.title.is-4.tag(:class="getClassForCredit(smsCredits.promo)") 
              number-view(:value="smsCredits.promo")
            p.subtitle.is-6(v-t="'sms.disclaimer_promo'")
          footer.card-footer
            a.card-footer-item(@click="company.smsProvider && buyCredits('promo')" :disabled="!company.smsProvider")
              span.icon
                i.mdi.mdi-plus-circle-outline
              span(v-t="'sms.actions.top_up'")
    
    hr
    p.title.is-5.mb-3(v-t="'sms.purchased_bundles_title'")
    BundleTransactions
  footer.modal-card-foot
    .buttons
      button.button(@click="$emit('close')" v-t="'actions.close'") 
  </template>
<script>
import {mapActions, mapState} from 'vuex'
import BundleTransactions from './BundleTransactions'
import BundlesStore from '../billing/BundlesStore'

export default {
  name: 'SMSCenter',
  components: {
    BundleTransactions
  },
  data() {
    return {
      loading: false
    }
  },
  computed: {
    ...mapState('company', ['company', 'smsCredits'])
  },
  async created() {
    this.fetchCredits()
  },
  methods: {
    ...mapActions('company', ['fetchSMSCredits']),
    getClassForCredit(credits) {
      if (credits > 250) {
        return 'is-success'
      }
      if (credits > 50) {
        return 'is-warning'
      }
      return 'is-danger'
    },
    async fetchCredits() {
      try {
        this.loading = true
        await this.fetchSMSCredits()
      } catch (err) {
        this.$handleAPIError(err)
      }
      this.loading = false
    },
    buyCredits(type) {
      this.$buefy.modal.open({
        parent: this,
        component: BundlesStore,
        hasModalCard: true,
        canCancel: ['escape', 'outside'],
        width: 640,
        props: {type},
        events: {
          success: () => {
            this.$buefy.toast.open({message: this.$t('status.purchase_success'), type: 'is-success', queue: false})
            this.fetchCredits()
          }
        }
      })
    }
  }
}
</script>
