import Vue from 'vue'

export default {
  list() {
    return Vue.http.get('reviews').then(res => res.body)
  },
  listVerified() {
    return Vue.http.get('reviews/verified').then(res => res.body)
  },
  updateReview(id, data) {
    return Vue.http.put(`reviews/${id}`, data)
  }
}
