<template lang="pug">
.modal-card
    header.modal-card-head
      p.modal-card-title
        b(v-t="'product_directory.title'")
      div
        a.button.is-white.is-small(@click="$emit('close')")
          span.icon
            i.mdi.mdi-close.mdi-24px
    section.modal-card-body.p-0.is-flex.is-flex-direction-column.is-align-items-stretch.is-relative
        .p-4.is-flex-shrink-0.has-background-light
          form(v-on:submit.prevent="search")
            button.is-hidden(type="submit")
            b-field(expanded)
              b-input(v-model="query" size="is-medium" ref="searchInput" @input="search" expanded icon="magnify" :placeholder="$t('product_directory.search_placeholder')" icon-right="close-circle-outline"
                icon-right-clickable
                @icon-right-click="clearSearch")
                
              //- .control
              //-     button.button.is-primary(type="submit", :class="searching ? 'is-loading': ''") 
              //-       span.icon
              //-         i.mdi.mdi-magnify
              //-       span(v-t="'actions.search'")
        .is-flex-grow-1(style="overflow: auto")
          .p-6(v-if="!categories.length" style="min-height: 200px")
            p.has-text-centered(v-t="'services.selector.no_results'")
          
          div(v-if="query" style="min-height: 300px")
            em-loading(:active="searching" :is-full-page="false")
            .p-6(v-if="!searching && (!searchResult || !searchResult.length)")
              p.has-text-centered(v-t="'services.selector.no_results'")
            template(v-if="!searching && searchResult && searchResult.length") 
              .list-item.py-4.is-flex.is-flex-direction-row.is-align-items-center.is-clickable(v-for="product in searchResult" :key="product._id" @click="select(product)") 
                  span.icon.is-medium.mr-1
                    i.mdi.mdi-24px.mdi-circle-slice-8.has-text-primary(v-if="selected.includes(product)")
                    i.mdi.mdi-24px.mdi-checkbox-blank-circle-outline(v-else)
                  ProductPic.mr-2(:product="product" style="max-width: 64px; height: 64px")
                  .is-flex-grow-1
                    p.title.is-6 {{ product.name }}
                      span.icon(:class="product.type === 'retail' ? 'has-text-info': 'has-text-black'")
                        i.mdi(:class="product.type === 'retail' ? 'mdi-cart-outline': 'mdi-test-tube-empty'")
                    p.subtitle.is-6
                      template(v-if="product.manageStock && locationId")
                        small 
                          StockLabel(:stockOnHand="product.stockOnHand[locationId] || 0" :lowStockThreshold="product.lowStockThreshold" :unit="product.unit" :tag="false") 
                        |  -  
                      small.has-text-grey  {{product.sku || $t('product_directory.no_sku_label')}}
                  strong.is-size-5.is-flex-shrink-0
                      strong(v-if="product.type === 'retail' || product.isAddon")
                        currency-view(:value="product.retailPrice")
                      span(v-else) - 
          b-collapse.list-item.p-0(v-show="!query" v-for="cat in productCategories" :key="cat._id" animation="slide" :open="!!query") 
            template(#trigger="props")
              .card-header.is-shadowless.is-marginless.p-2.has-background-white(role="button" aria-controls="contentIdForA11y3" :aria-expanded="props.open" @click="selectCategory(cat._id)" style="position: sticky; top: 0; z-index: 10;")
                p.card-header-title.is-size-5(:class="cat._id === 'uncategorized' ? 'has-text-grey': ''") {{cat.name}}
                a.card-header-icon
                  span.icon
                    i.mdi(:class="props.open ? 'mdi-chevron-down' : 'mdi-chevron-up'")
            template(v-if="categoryProducts[cat._id]")
              .list-item.py-4.is-flex.is-flex-direction-row.is-align-items-center.is-clickable(v-for="product in categoryProducts[cat._id]" :key="product._id" @click="select(product)") 
                
                span.icon.is-medium.mr-1
                  i.mdi.mdi-24px.mdi-circle-slice-8.has-text-primary(v-if="selected.includes(product)")
                  i.mdi.mdi-24px.mdi-checkbox-blank-circle-outline(v-else)
                ProductPic.mr-2(:product="product" style="max-width: 64px; height: 64px")
                .is-flex-grow-1
                  p.title.is-6 {{ product.name }}
                    span.icon(:class="product.type === 'retail' ? 'has-text-info': 'has-text-black'")
                      i.mdi(:class="product.type === 'retail' ? 'mdi-cart-outline': 'mdi-test-tube-empty'")
                  p.subtitle.is-6
                    template(v-if="product.manageStock && locationId")
                      small 
                        StockLabel(:stockOnHand="product.stockOnHand[locationId] || 0" :lowStockThreshold="product.lowStockThreshold" :unit="product.unit" :tag="false" no-text) 
                      |  -  
                    small.has-text-grey  {{product.sku || $t('product_directory.no_sku_label')}}
                strong.is-size-5.is-flex-shrink-0
                    strong(v-if="product.type === 'retail' || product.isAddon")
                      currency-view(:value="product.retailPrice")
                    span(v-else) - 
            .p-4.is-relative(v-else)
              em-loading(active :is-full-page="false")
            

    footer.modal-card-foot
      .buttons
        action-button(title="actions.select" @click="submit" :disabled="!selected" v-if="multiselect")
        button.button.is-outlined(type="button" @click="$emit('close')" ) {{$t('actions.cancel')}}      
</template>
<script>
import {mapActions, mapGetters, mapState} from 'vuex'
import debounce from 'lodash/debounce'
import ProductPic from './ProductPic'
import StockLabel from './StockLabel'
export default {
  name: 'ProductSelectModal',
  components: {
    ProductPic,
    StockLabel
  },
  props: {
    locationId: {type: String, default: undefined},
    multiselect: {type: Boolean, default: false}
  },
  data() {
    return {
      selected: [],
      categoryProducts: {},
      loadingCategory: undefined,
      searching: false,
      query: undefined,
      searchResult: undefined
    }
  },
  computed: {
    ...mapState('auth', ['user']),
    ...mapGetters('inventoryCategories', ['categories']),
    productCategories() {
      return [{_id: 'uncategorized', name: this.$t('product_directory.uncategorized_label')}, ...this.categories]
    }
  },
  async created() {
    this.searching = true
    try {
      await this.fetchCategories()
    } catch (err) {
      this.$handleAPIError(err)
    }
    this.searching = false
  },
  mounted() {
    this.$refs.searchInput.focus()
  },
  methods: {
    ...mapActions('inventoryCategories', ['fetchCategories']),
    ...mapActions('inventory', ['fetchProductsByCategory', 'searchDirectory']),

    async selectCategory(catId) {
      this.loadingCategory = catId
      this.$set(this.categoryProducts, catId, await this.fetchProductsByCategory(catId))
      this.loadingCategory = undefined
    },

    search: debounce(async function() {
      if (!this.query) {
        this.searchResult = undefined
        return
      }
      this.searching = true
      try {
        this.searchResult = await this.searchDirectory({query: this.query})
      } catch (err) {
        this.$handleAPIError(err)
      }
      this.searching = false
    }, 200),
    clearSearch() {
      this.query = undefined
    },
    select(product) {
      if (this.multiselect) {
        if (this.selected.includes(product)) {
          this.selected.splice(this.selected.indexOf(product), 1)
        } else {
          this.selected.push(product)
        }
      } else {
        this.selected = [product]
      }

      if (!this.multiselect) {
        this.$emit('close')
        this.$emit('input', this.selected[0])
      }
    },
    submit() {
      this.$emit('input', this.selected)
      this.$emit('close')
    }
  }
}
</script>
