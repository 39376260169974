<template lang="pug">
.modal-card
  header.modal-card-head
    p.modal-card-title(data-qa="modal-header") 
      b(v-t="'sms.title_transactional'" v-if="type === 'transactional'") 
      b(v-t="'sms.title_promo'" v-else) 
    div
      a.button.is-white.is-small(@click="$emit('close')")
        span.icon
          i.mdi.mdi-close.mdi-24px
  section.modal-card-body(v-if="!success")
    em-loading(:active="loading" :is-full-page="false")
    
    
    note.mb-6.is-outlined(type="is-info") 
      p(v-if="type === 'transactional'" v-t="'sms.disclaimer_transactional'") 
      p(v-else v-t="'sms.disclaimer_promo'") 
    
    h3.title.is-5.mb-3 Bundles
    .list.mb-6
      template(v-for="pack in packs")
        .list-item(v-for="bundle in bundles")
          b-radio(v-model="selected" :native-value="pack" name="selectedBundle")
            | credits: 
            number-view.mr-3(:value="bundle.price.transform_quantity.divide_by * pack") 
            |  
            b 
              invoice-currency.is-pulled-right(:value="bundle.price.unit_amount * pack" :currencyCode="bundle.price.currency")
    h3.title.is-5.mb-3 {{ $t('billing.payment_method_title') }}
    PaymentMethods(ref="paymentMethod" @input="onCardToken")
  section.modal-card-body(v-else)
    success-screen
      p.title.is-3(v-t="'misc.thank_you'")
      p.subtitle.is-5(v-t="'sms.purchase_success'")
  footer.modal-card-foot
    .buttons
      action-button.is-primary(@click="purchase" :loading="submitting" title="actions.buy_now" v-if="!success")
      button.button(@click="$emit('close')") {{$t('actions.close')  }}
</template>

<script>
import InvoiceCurrency from './InvoiceCurrency'
import BillingApi from '@/api/billing'
import SMS_TYPES from '../../../constants/smsTypes'
import {BUNDLES} from '../../../constants/products'
import PaymentMethods from './PaymentMethods'
export default {
  name: 'BundlesStore',
  components: {
    InvoiceCurrency,
    PaymentMethods
  },
  props: {
    type: {type: String, required: true}
  },
  data() {
    return {
      loading: false,
      bundles: undefined,
      productType: undefined,
      packs: [1, 2, 5, 10],
      submitting: false,
      selected: 2,
      success: false
    }
  },
  created() {
    this.fetchBundles()
  },
  methods: {
    async fetchBundles() {
      this.loading = true
      try {
        let res = await BillingApi.listBundles()
        this.productType = this.type === SMS_TYPES.PROMO ? BUNDLES.PROMO_SMS_BUNDLE : BUNDLES.TRANS_SMS_BUNDLE
        this.bundles = res.filter(r => r.product === this.productType)
      } catch (err) {
        this.$handleAPIError(err)
      }
      this.loading = false
    },
    async purchase() {
      if (this.submitting) {
        return
      }
      this.submitting = true
      try {
        await this.$refs.paymentMethod.submit()
      } catch (err) {
        this.submitting = false
      }
    },
    async onCardToken(data) {
      let product = this.bundles[0].price.metadata.product
      let qty = this.bundles[0].price.transform_quantity.divide_by * this.selected

      try {
        let {status} = await BillingApi.purchaseBundle({product, qty, token: data && data.token})
        if (status) {
          this.success = true
        } else {
          this.$buefy.toast.open({message: this.$t('status.purchase_failed'), type: 'is-warning', queue: false})
        }
      } catch (err) {
        this.$handleAPIError(err)
      }
      this.submitting = false
    }
  }
}
</script>
