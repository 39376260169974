<template lang="pug">
span(v-if="resource") {{resource.name}}
  b-tooltip(:label="$t('resource.preferred_tooltip')" v-if="preferred")
    span.icon.has-text-danger.is-small
      i.mdi.mdi-heart
</template>

<script>
import {mapActions} from 'vuex'

export default {
  name: 'ResourceView',
  props: {
    value: {type: String, required: true},
    preferred: {type: Boolean, default: false}
  },
  data() {
    return {
      resource: undefined
    }
  },
  watch: {
    value() {
      this.fetch()
    }
  },
  async created() {
    await this.fetch()
  },
  methods: {
    ...mapActions('resources', ['getById']),
    async fetch() {
      this.resource = await this.getById(this.value)
    }
  }
}
</script>
