import Vue from 'vue'

export default {
  checkShortId(shortId) {
    return Vue.http.post('company/checkShortId', {shortId}).then(res => res.body)
  },
  getSettings() {
    return Vue.http.get('company').then(res => res.body)
  },
  updateSettings(data) {
    return Vue.http.put('company', data).then(res => res.body)
  },
  reconnectMessenger(data) {
    return Vue.http.post('platforms/messenger', data)
  },
  disconnectMessenger() {
    return Vue.http.delete('platforms/messenger')
  }
}
