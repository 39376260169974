import ChatBotAPI from '@/api/chatbot'

export default {
  namespaced: true,
  actions: {
    async fetchData({rootState}) {
      const {messenger} = rootState.company.company
      const body = await ChatBotAPI.getAllPersistentMenu()
      const allPersistentMenuItems = body.filter(menuItem => {
        return !messenger.persistentMenu.includes(menuItem.key)
      })
      const persistentMenuItems = body.filter(menuItem => {
        return messenger.persistentMenu.includes(menuItem.key)
      })
      return [persistentMenuItems, allPersistentMenuItems]
    },
    async updatePersistentMenu(_, data) {
      await ChatBotAPI.updatePersistentMenu(data)
    }
  }
}
