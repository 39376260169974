<template lang="pug">
.popup-container
  .brand-bordered.box.has-background-white.px-4.py-1(v-for="call of calls" :key="call.phone") 
    .is-flex.is-align-items-center
      .mr-3.is-flex-shrink-0
        span.icon.has-text-success.is-medium(v-if="disposition === 'ringing'")
          i.mdi.mdi-24px.mdi-phone
        span.icon.has-text-success.is-medium(v-else-if="disposition === 'ANSWERED'")
          i.mdi.mdi-24px.mdi-phone-off
        span.icon.has-text-danger.is-medium(v-else-if="disposition === 'NO ANSWER'")
          i.mdi.mdi-24px.mdi-phone-off
        span.icon.has-text-success.is-medium(v-else)
          i.mdi.mdi-24px.mdi-phone
      .mr-3.is-flex-grow-1(v-if="call.customer")
          customer-tiny-preview.is-48x48(:value="call.customer" :closable="false" link)
      .mr-3.is-flex-grow-1(v-else)
        .p-3
          p.heading.has-text-grey(v-t="'customer.new_client_title'")
          p.title.is-5 
            phone-view(:value="call.phone" :tag="false" :icon="false")
      .is-flex-shrink-0
        .buttons
          //button.button.is-warning(@click="showCustomerDetails") 
            span.icon 
              i.mdi.mdi-account-outline.mdi-24px
          button.button.is-success(@click="createAppointment(call)") 
            span.icon 
              i.mdi.mdi-calendar-plus-outline.mdi-24px
</template>
<script>
import {mapState} from 'vuex'
import CustomerCreateEditForm from '../customers/CustomerCreateEditForm'

export default {
  name: 'CallHandler',
  data() {
    return {calls: []}
  },
  sockets: {
    CALL_ENDED({caller, disposition}) {
      console.log('CALL ENDED')
      let currentCallIdx = this.calls.findIndex(c => c.phone === caller.phone)
      if (currentCallIdx === -1) {
        return
      }

      this.calls[currentCallIdx].disposition = disposition
      setTimeout(() => {
        this.calls.splice(currentCallIdx, 1)
      }, 1000)
    },
    CALL_STARTED({customer, caller, receiver}) {
      console.log('CALL STARTED')
      this.onCallStarted({customer, caller, receiver})
    }
  },
  computed: {
    ...mapState('auth', ['user'])
  },
  created() {
    //this.onCallStarted({caller: {phone: '+37455520005'}, receiver: {phone: '23423423'}})
  },
  methods: {
    onCallStarted({customer, caller, receiver, disposition}) {
      if (this.user.callAgentId && this.user.callAgentId !== receiver.phone) {
        return
      }
      if (this.calls.find(c => c.phone === caller.phone)) {
        return
      }
      this.calls.push({phone: caller.phone, disposition, customer, caller})
    },
    createAppointment(call) {
      if (call.customer) {
        this.openNewAppointment(call.customer._id)
      } else {
        this.showAddNewCustomer(call.phone)
      }
    },
    // showCustomerDetails(customerId) {
    //   this.$buefy.modal.open({
    //     parent: this,
    //     component: this.isMobile() ? CustomerDetailsMobile : Details,
    //     fullScreen: true,
    //     hasModalCard: true,
    //     props: {customerId},
    //     canCancel: ['escape', 'outside']
    //   })
    // },
    openNewAppointment(customerId) {
      this.$router.push({name: 'Calendar', query: {customer: customerId}})
    },
    showAddNewCustomer(phone) {
      this.$buefy.modal.open({
        parent: this,
        component: CustomerCreateEditForm,
        canCancel: ['escape'],
        props: {
          oneOnly: true,
          initialPhone: phone
        },
        fullScreen: true,
        hasModalCard: true,
        events: {
          success: customer => {
            this.openNewAppointment(customer._id)
          }
        }
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.popup-container {
  position: fixed;
  bottom: 2rem;
  left: 2rem;
  z-index: 2;
  width: 360px;

  transition: all 200ms ease-in-out;
}

@media screen and (max-width: 768px) {
  .popup-container {
    bottom: 6rem;
    left: 1rem;
  }
}
</style>
