<template lang="pug">
  span.feedback(v-if="rating", :class="ratingClass" title="Average rating") {{rating | ratify}}
  span(v-else-if="showUnknown") -
</template>
<script>
export default {
  props: {
    customer: {type: Object, required: true},
    showUnknown: {type: Boolean, default: false}
  },
  computed: {
    rating() {
      if (!this.customer.surveys || !this.customer.surveys.feedback || !this.customer.surveys.feedback.value) {
        return
      }
      return this.customer.surveys.feedback.value
    },
    ratingClass() {
      if (!this.rating) {
        return
      }
      return `feedback-${Math.round(this.rating)}`
    }
  }
}
</script>
