import {
  RESET_LEDGER,
  SET_REGISTER_SESSION,
  SET_REGISTER_BALANCE,
  SET_REGISTER_LIABILITIES,
  SET_REGISTER_INITIALIZED
} from './mutationTypes'
import LedgerApi from '@/api/ledger'

let __sessionPromise
export default {
  namespaced: true,
  state: {
    session: undefined,
    balance: undefined,
    liabilities: undefined,
    initialized: false
  },
  getters: {
    ledger(state) {
      return state.ledgerOrder.map(c => state.ledgerMap[c])
    },
    totalDue(state) {
      return state.liabilities && state.liabilities.reduce((sum, c) => sum + c.due, 0)
    }
  },
  mutations: {
    [SET_REGISTER_INITIALIZED](state, val) {
      state.initialized = val
    },
    [SET_REGISTER_SESSION](state, val) {
      state.session = val
    },
    [SET_REGISTER_BALANCE](state, val) {
      state.balance = val
    },
    [SET_REGISTER_LIABILITIES](state, val) {
      state.liabilities = val
    },

    [RESET_LEDGER](state) {
      state.session = undefined
      state.balance = undefined
      state.liabilities = undefined
    }
  },
  actions: {
    async fetchSession({commit, state}, force) {
      if (__sessionPromise) {
        await __sessionPromise
      }
      if (!state.initialized || force) {
        __sessionPromise = LedgerApi.getRegisterSession()
        let data = await __sessionPromise
        __sessionPromise = undefined
        commit(SET_REGISTER_INITIALIZED, true)
        commit(SET_REGISTER_SESSION, data)
      }
    },
    async fetchBalance({commit, rootState}) {
      let data = await LedgerApi.getBalance({location: rootState.auth.user.location})
      commit(SET_REGISTER_BALANCE, data)
    },
    async fetchLiabilities({commit, rootState}) {
      let data = await LedgerApi.getLiabilities({location: rootState.auth.user.location})
      commit(SET_REGISTER_LIABILITIES, data)
    },

    async openRegister({commit, dispatch}, actual) {
      let session = await LedgerApi.openRegister({actual})
      await dispatch('fetchBalance')
      commit(SET_REGISTER_SESSION, session)
    },
    async closeRegister({commit, dispatch}, actual) {
      let session = await LedgerApi.closeRegister({actual})
      await dispatch('fetchBalance')
      commit(SET_REGISTER_SESSION, session)
    },
    async fetch(_ctx, params) {
      return LedgerApi.getLedger(params)
    },
    async registerIn({dispatch}, data) {
      await LedgerApi.registerIn(data)
      await dispatch('fetchBalance')
    },
    async registerOut({dispatch}, data) {
      await LedgerApi.registerOut(data)
      await dispatch('fetchBalance')
    },
    async registerPayout({dispatch}, data) {
      await LedgerApi.registerPayout(data)
      await Promise.all([dispatch('fetchBalance'), dispatch('fetchLiabilities')])
    },

    reset({commit}) {
      commit(RESET_LEDGER)
    }
  }
}
