<template lang="pug">
span(v-if="level") 
  span.icon(v-if="icon")
    i.mdi(:class="icon")
  span(v-t="`staff_levels.${level}`")
</template>
<script>
import {mapState} from 'vuex'
import SPECIALIST_LEVELS from '../../../constants/specialistLevels'
export default {
  name: 'ExperienceView',
  props: {
    staffId: {type: String, default: undefined},
    value: {type: String, default: undefined}
  },
  computed: {
    ...mapState('staff', ['staffMap']),
    icon() {
      switch (this.level) {
        case SPECIALIST_LEVELS.APPRENTICE:
          return 'mdi-school-outline'
        case SPECIALIST_LEVELS.APPRENTICE_1Y:
          return 'mdi-school-outline'
        case SPECIALIST_LEVELS.APPRENTICE_2Y:
          return 'mdi-school-outline'
        case SPECIALIST_LEVELS.APPRENTICE_3Y:
          return 'mdi-school-outline'
        case SPECIALIST_LEVELS.JUNIOR:
          return 'mdi-chevron-up'
        case SPECIALIST_LEVELS.SENIOR:
          return 'mdi-chevron-double-up'
        case SPECIALIST_LEVELS.MASTER:
          return 'mdi-chevron-triple-up'
        case SPECIALIST_LEVELS.TOP_MASTER:
          return 'mdi-star-outline'
        case SPECIALIST_LEVELS.ART_DIRECTOR:
          return 'mdi-shield-star-outline'
        default:
          return undefined
      }
    },
    level() {
      if (this.staffId) {
        return this.staffMap[this.staffId].level
      }
      return this.value
    }
  }
}
</script>
