import Vue from 'vue'

export default {
  fetch({year, locationId}) {
    return Vue.http.get(`targets/${locationId}/${year}`).then(res => res.body)
  },
  generate({year, locationId, data}) {
    return Vue.http.post(`targets/${locationId}/${year}`, data).then(res => res.body)
  },
  remove({year, locationId}) {
    return Vue.http.delete(`targets/${locationId}/${year}`).then(res => res.body)
  },
  getById(periodId) {
    return Vue.http.get(`targets/${periodId}`).then(res => res.body)
  }
}
