<template lang="pug">
div
  screen-title(title="resources_settings.title")
    action-button(@click="addEditResource" title="resources_settings.actions.add" icon="plus" permission="RESOURCES_MANAGE")
  .box
    note.is-outlined.mb-5(type="is-info")
      p(v-t="'resources_settings.description'")
    b-table(
      :data="resources" 
      draggable
      @dragstart="dragstart"
      @drop="drop"
      @dragover="dragover"
      @dragleave="dragleave"
      :loading="loading" 
      hoverable 
      class="is-vertically-aligned" 
      @click="addEditResource" 
      custom-row-key="_id")
      b-table-column(field="name" width="50%" :label="$t('resources_settings.name_heading')" v-slot="props") 
        .is-flex.is-align-items-center
          div 
            span.icon.is-draggable.has-text-grey-light.is-small.mr-2
              i.mdi.mdi-drag.mdi-16px
          div.mr-4 
            resource-pic.is-48x48.is-rounded(:value="props.row")
          div
            p.title.is-6
              span {{props.row.name}}
      b-table-column(field="location" width="50%" :label="$t('resources_settings.location_heading')" v-slot="props") 
        location-view(:value="props.row.location")
      template(slot="empty")
        empty-content(icon="close-circle-outline" title="resources_settings.no_resources")
</template>
<script>
import {mapActions, mapGetters, mapState} from 'vuex'
import ResourceCreateEditModal from './ResourceCreateEditModal'
export default {
  name: 'Resources',
  computed: {
    ...mapGetters('resources', ['resources', 'resourcesByLocation']),
    ...mapState('resources', ['loading', 'resourcesMap']),
    availableResources() {
      if (this.$rfLocationScope() || this.$rfProfileScope()) {
        return this.resourcesByLocation(this.user.location)
      }
      return this.resources
    }
  },
  methods: {
    ...mapActions('resources', ['updateResourcesOrder']),
    addEditResource(row) {
      this.$buefy.modal.open({
        parent: this,
        component: ResourceCreateEditModal,
        hasModalCard: true,
        fullScreen: true,
        props: {resourceId: row && row._id},
        canCancel: ['escape']
      })
    },
    dragstart(payload) {
      this.draggingRowIndex = payload.index
      payload.event.dataTransfer.effectAllowed = 'copy'
    },
    dragover(payload) {
      payload.event.dataTransfer.dropEffect = 'copy'
      payload.event.target.closest('tr').classList.add('is-selected')
      payload.event.preventDefault()
    },
    dragleave(payload) {
      payload.event.target.closest('tr').classList.remove('is-selected')
      payload.event.preventDefault()
    },
    drop(payload) {
      payload.event.target.closest('tr').classList.remove('is-selected')
      if (this.draggingRowIndex === payload.index) return
      let order = this.availableResources.map(s => s._id)
      let draggingId = order[this.draggingRowIndex]

      order.splice(this.draggingRowIndex, 1)
      order.splice(payload.index, 0, draggingId)

      this.updateResourcesOrder(order)
    }
  }
}
</script>
