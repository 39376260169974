export const SET_USER = 'SET_USER'
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS'
export const LOGOUT = 'LOGOUT'

export const SET_COMPANY = 'SET_COMPANY'
export const UPDATE_COMPANY = 'UPDATE_COMPANY'
export const RESET_COMPANY = 'RESET_COMPANY'

export const SET_LOCATIONS = 'SET_LOCATIONS'
export const SET_LOCATION = 'SET_LOCATION'
export const UPDATE_LOCATION = 'UPDATE_LOCATION'
export const RESET_LOCATION = 'RESET_LOCATION'
export const SET_LOCATIONS_INITIALIZED = 'SET_LOCATIONS_INITIALIZED'

export const SET_RESOURCES = 'SET_RESOURCES'
export const UPDATE_RESOURCE = 'UPDATE_RESOURCE'
export const RESET_RESOURCE = 'RESET_RESOURCE'
export const SET_RESOURCES_INITIALIZED = 'SET_RESOURCES_INITIALIZED'
export const SET_RESOURCE = 'SET_RESOURCE'
export const SET_RESOURCES_LOADING = 'SET_RESOURCES_LOADING'
export const SET_RESOURCES_ORDER = 'SET_RESOURCES_ORDER'

export const SET_TAXES = 'SET_TAXES'
export const UPDATE_TAX = 'UPDATE_TAX'
export const RESET_TAX = 'RESET_TAX'
export const SET_TAXES_INITIALIZED = 'SET_TAXES_INITIALIZED'

export const SET_SUBSCRIPTION = 'SET_SUBSCRIPTION'
export const SET_PAYMENT_METHOD = 'SET_PAYMENT_METHOD'
export const SET_INVOICES = 'SET_INVOICES'
export const SET_UPCOMING_INVOICE = 'SET_UPCOMING_INVOICE'
export const SET_INVOICES_LOADING = 'SET_INVOICES_LOADING'
export const SET_UPCOMING_INVOICE_LOADING = 'SET_UPCOMING_INVOICE_LOADING'
export const RESET_BILLING = 'RESET_BILLING'

export const SET_CUSTOMERS = 'SET_CUSTOMERS'
export const SET_TOTAL_CUSTOMERS = 'SET_TOTAL_CUSTOMERS'
export const SET_CUSTOMER = 'SET_CUSTOMER'
export const REMOVE_CUSTOMER = 'REMOVE_CUSTOMER'
export const SET_CUSTOMERS_FILTER = 'SET_CUSTOMERS_FILTER'

export const SET_PRODUCTS = 'SET_PRODUCTS'
export const SET_TOTAL_PRODUCTS = 'SET_TOTAL_PRODUCTS'
export const SET_PRODUCT = 'SET_PRODUCT'
export const REMOVE_PRODUCT = 'REMOVE_PRODUCT'
export const SET_CATEGORY_PRODUCTS = 'SET_CATEGORY_PRODUCTS'
export const RESET_CATEGORY_PRODUCTS = 'RESET_CATEGORY_PRODUCTS'

export const SET_PRODUCTS_FILTER = 'SET_PRODUCTS_FILTER'
export const SET_INVENTORY_LISTS_INITIALIZED = 'SET_INVENTORY_LISTS_INITIALIZED'

export const SET_INVENTORY_CATEGORIES = 'SET_INVENTORY_CATEGORIES'
export const SET_INVENTORY_CATEGORY = 'SET_INVENTORY_CATEGORY'
export const REMOVE_INVENTORY_CATEGORY = 'REMOVE_INVENTORY_CATEGORY'

export const SET_INVENTORY_BRANDS = 'SET_INVENTORY_BRANDS'
export const SET_INVENTORY_BRAND = 'SET_INVENTORY_BRAND'
export const REMOVE_INVENTORY_BRAND = 'REMOVE_INVENTORY_BRAND'

export const SET_SERVICES = 'SET_SERVICES'
export const SET_CATEGORIES = 'SET_CATEGORIES'
export const SET_TOTAL_SERVICES = 'SET_TOTAL_SERVICES'
export const SET_SERVICE = 'SET_SERVICE'
export const SET_CATEGORY = 'SET_CATEGORY'
export const REMOVE_CATEGORY = 'REMOVE_CATEGORY'
export const SET_SERVICES_IN_CATEGORY = 'SET_SERVICES_IN_CATEGORY'
export const SET_SERVICES_INITIALIZED = 'SET_SERVICES_INITIALIZED'
export const SET_SERVICES_SEARCH_DIRECTORY = 'SET_SERVICES_SEARCH_DIRECTORY'

export const SET_MEMBERS = 'SET_MEMBERS'
export const SET_MEMBER = 'SET_MEMBER'
export const REMOVE_MEMBER = 'REMOVE_MEMBER'
export const SET_MEMBERS_ORDER = 'SET_MEMBERS_ORDER'

export const SET_TASKS = 'SET_TASKS'
export const REMOVE_TASK = 'REMOVE_TASK'
export const SET_COMPLETENESS = 'SET_COMPLETENESS'

export const SET_OPEN_TICKETS = 'SET_OPEN_TICKETS'
export const SET_DONE_TICKETS = 'SET_DONE_TICKETS'
export const SET_TICKET = 'SET_TICKET'
export const SET_SORT_BY = 'SET_SORT_BY'

export const SET_SALE = 'SET_SALE'
export const SET_SALES = 'SET_SALES'
export const SET_CUSTOMER_SALES = 'SET_CUSTOMER_SALES'
export const SET_CHECKING_OUT = 'SET_CHECKING_OUT'

export const SET_PAYMENTS = 'SET_PAYMENTS'
export const SET_PAYMENT = 'SET_PAYMENT '
export const SET_CUSTOMER_PAYMENTS = 'SET_CUSTOMER_PAYMENTS'

export const SET_APPOINTMENT = 'SET_APPOINTMENT'
export const SET_APPOINTMENTS = 'SET_APPOINTMENTS'
export const SET_CUSTOMER_APPOINTMENTS = 'SET_CUSTOMER_APPOINTMENTS'
export const SET_APPOINTMENTS_BY_DATE = 'SET_APPOINTMENTS_BY_DATE'
export const REMOVE_APPOINTMENT = 'REMOVE_APPOINTMENT'

export const SET_REVIEWS = 'SET_REVIEWS'
export const SET_COUPON = 'SET_COUPON'
export const SET_COUPONS = 'SET_COUPONS'
export const SET_TOTAL_COUPONS = 'SET_TOTAL_COUPONS'
export const REMOVE_COUPON = 'REMOVE_COUPON'

export const SET_CUSTOMER_COUPONS = 'SET_CUSTOMER_COUPONS'
export const SET_CUSTOMER_COUPON = 'SET_CUSTOMER_COUPON'
export const SET_TOTAL_CUSTOMER_COUPONS = 'SET_TOTAL_CUSTOMER_COUPONS'
export const SET_CUSTOMER_COUPONS_FILTER = 'SET_CUSTOMER_COUPONS_FILTER'

export const SET_TARGETS = 'SET_TARGETS'
export const SET_CURRENT_TARGET_VIEW = 'SET_CURRENT_TARGET_VIEW'
export const SET_PERIOD_DATA = 'SET_PERIOD_DATA'
export const SET_PERIOD_TYPE = 'SET_PERIOD_TYPE'

export const RESET_LEDGER = 'RESET_LEDGER'
export const SET_REGISTER_SESSION = 'SET_REGISTER_SESSION'
export const SET_REGISTER_BALANCE = 'SET_REGISTER_BALANCE'
export const SET_REGISTER_LIABILITIES = 'SET_REGISTER_LIABILITIES'
export const SET_REGISTER_INITIALIZED = 'SET_REGISTER_INITIALIZED'

export const SET_MEMBERSHIP_PACKAGES = 'SET_MEMBERSHIP_PACKAGES'
export const UPDATE_MEMBERSHIP_PACKAGE = 'UPDATE_MEMBERSHIP_PACKAGE'
export const RESET_MEMBERSHIP_PACKAGE = 'RESET_MEMBERSHIP_PACKAGE'
export const SET_MEMBERSHIP_PACKAGES_INITIALIZED = 'SET_MEMBERSHIP_PACKAGES_INITIALIZED'
export const SET_MEMBERSHIP_PACKAGE = 'SET_MEMBERSHIP_PACKAGE'

export const SET_SMS_CREDITS = 'SET_SMS_CREDITS'

export const SET_FORMS = 'SET_FORMS'
export const UPDATE_FORM = 'UPDATE_FORM'
export const RESET_FORMS = 'RESET_FORMS'
export const SET_FORMS_INITIALIZED = 'SET_FORMS_INITIALIZED'
export const SET_FORM = 'SET_FORM'
