<template lang="pug">
  .tags(v-if="memberships && memberships.length")
    MembershipNameView(:value="membership" v-for="membership in memberships" :key="membership._id")
  .tags(v-else) -   
</template>
<script>
import MembershipsApi from '@/api/memberships'
import MembershipNameView from './MembershipNameView'
export default {
  name: 'CustomerMemberships',
  components: {
    MembershipNameView: MembershipNameView
  },
  props: {
    customerId: {type: String, required: true}
  },
  data() {
    return {
      memberships: undefined
    }
  },
  async created() {
    this.memberships = await MembershipsApi.getCustomerMemberships(this.customerId)
  }
}
</script>
