import Vue from 'vue'

export default {
  refundPayment(paymentId) {
    return Vue.http.put(`payments/${paymentId}/refund`).then(res => res.body)
  },
  cancelPayment(paymentId) {
    return Vue.http.put(`payments/${paymentId}/cancel`).then(res => res.body)
  },
  list(params) {
    return Vue.http.get('payments', {params}).then(res => res.body)
  },
  getById(paymentId) {
    return Vue.http.get(`payments/${paymentId}`).then(res => res.body)
  },
  refreshPaymentStatus(paymentId) {
    return Vue.http.get(`payments/${paymentId}/status`).then(res => res.body)
  },
  getPayoutStats() {
    return Vue.http.get('payments/stats').then(res => res.body)
  }
}
