<template lang="pug">
div(v-if="content" @click="editTemplate")
  ContentEditable.p-2.brand-bordered.is-clickable( :value="content[lng] || content[`${lng}_default`]" :editable="false" v-for="lng in company.languages" :key="lng" v-show="active === lng")
  p.help(v-if="company.languages.length > 1")
    a.mr-2.has-text-black(v-for="(lng, idx) in company.languages" :key="lng" @click.stop="active = lng" :class="active===lng ? 'is-active': ''") {{ $t(`languages.${lng}`) }}

</template>
<script>
import {mapState} from 'vuex'
import SMSApi from '@/api/sms'
import ContentEditable from './ContentEditable'
import SMSTemplateEditor from './SMSTemplateEditor'
export default {
  name: 'SMSTemplatePreview',
  components: {
    ContentEditable
  },
  props: {
    templateKey: {type: String, required: true}
  },
  data() {
    return {
      content: undefined,
      loading: false,
      active: undefined
    }
  },
  computed: {
    ...mapState('company', ['company'])
  },
  async created() {
    this.loading = true
    try {
      this.content = await SMSApi.getTemplates({key: this.templateKey, lngs: this.company.languages})
      this.active = this.company.defaultLanguage
    } catch (err) {
      this.$handleAPIError(err)
    }
    this.loading = false
  },
  methods: {
    editTemplate() {
      this.$buefy.modal.open({
        parent: this,
        component: SMSTemplateEditor,
        hasModalCard: true,
        canCancel: ['escape'],
        events: {
          input: tm => {
            this.content = tm
          }
        },
        props: {
          templateKey: this.templateKey
        }
      })
    }
  }
}
</script>
<style scoped>
a.is-active {
  text-decoration: underline;
}
</style>
