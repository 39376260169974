<template lang="pug">
aside.menu.is-dark-menu
  ul.menu-list
    li(v-if="!company.rewardsOnly && $rfHasPermission('SALE_VIEW') && $rfSettings().calendar")
      b-tooltip(style="width: 100%" :label="$t('navbar.appointments')" position="is-right" size="is-large" append-to-body)
        router-link(to='/calendar' data-qa="navbar-calendar") 
          span.icon
            i.mdi.mdi-calendar-outline(aria-hidden="true")
          span(v-t="'navbar.appointments'")
    li(v-if="$rfHasPermission('DASHBOARD_ANALYTICS')")
      b-tooltip(style="width: 100%" :label="$t('navbar.dashboard')" position="is-right" size="is-large" append-to-body)
        router-link(to='/dashboard') 
          span.icon
            i.mdi.mdi-view-dashboard-outline(aria-hidden="true")
          span(v-t="'navbar.dashboard'")
    
    li(v-if="company.rewardsOnly && $rfHasPermission('SALE_CREATE')")
      b-tooltip(style="width: 100%" :label="$t('navbar.checkout')" position="is-right" size="is-large" append-to-body)
        a(@click='checkout' data-qa="navbar-checkout-popup") 
          span.icon
            i.mdi.mdi-basket-outline(aria-hidden="true")
          span(v-t="'navbar.checkout'")

    li(v-if="$rfHasPermission('SALE_VIEW')")
      b-tooltip(style="width: 100%" :label="$t('navbar.sales')" position="is-right" size="is-large" append-to-body)
        router-link(to='/sales/list' data-qa="navbar-sales" :class="{'is-active': $route.path.startsWith('/sales')}") 
          span.icon
            i.mdi.mdi-tag-check-outline(aria-hidden="true")
          span(v-t="'navbar.sales'")
    
    li(v-if="$rfHasPermission('CLIENTS_VIEW')")
      b-tooltip(style="width: 100%" :label="$t('navbar.clients')" position="is-right" size="is-large" append-to-body)
        router-link(to='/customers' data-qa="navbar-customers") 
          span.icon
            i.mdi.mdi-account-multiple-outline(aria-hidden="true")
          span(v-t="'navbar.clients'")
    li(v-if="$rfHasPermission('ANALYTICS')")
      b-tooltip(style="width: 100%" :label="$t('navbar.analytics')" position="is-right" size="is-large" append-to-body)
        router-link(to='/analytics/overview' :class="{'is-active': $route.path.startsWith('/analytics')}") 
          span.icon
            i.mdi.mdi-chart-bar(aria-hidden="true")
          span(v-t="'navbar.analytics'")

    li(v-if="$rfHasPermission('TARGETS_VIEW') && $rfSubscribed('targets')")
      b-tooltip(style="width: 100%" :label="$t('navbar.targets')" position="is-right" size="is-large" append-to-body)
        router-link(to='/targets') 
          span.icon
            i.mdi.mdi-target(aria-hidden="true")
          span(v-t="'navbar.targets'")
    
    li(v-if="marketingAvailable")
      b-tooltip(style="width: 100%" :label="$t('navbar.marketing')" position="is-right" size="is-large" append-to-body)
        router-link(to='/marketing/rewards-program' :class="{'is-active': $route.path.startsWith('/marketing')}") 
          span.icon
            i.mdi.mdi-chart-timeline-variant-shimmer(aria-hidden="true")
          span(v-t="'navbar.marketing'")
    
    li(v-if="$rfHasPermission('SMM_VIEW')")
      b-tooltip(style="width: 100%" :label="$t('navbar.smm')" position="is-right" size="is-large" append-to-body)
        router-link(to='/social/dashboard' :class="{'is-active': $route.path.startsWith('/social')}")
          span.icon
            i.mdi.mdi-shimmer(aria-hidden="true")
          span(v-t="'navbar.smm'")


    li(v-if="$rfHasPermission('SURVEYS_VIEW')")
      b-tooltip(style="width: 100%" :label="$t('navbar.surveys')" position="is-right" size="is-large" append-to-body)
        router-link(to='/surveys/dashboard' :class="{'is-active': $route.path.startsWith('/surveys')}")
          span.icon
            i.mdi.mdi-comment-quote-outline(aria-hidden="true")
          span(v-t="'navbar.surveys'")
    
    li(v-if="!company.rewardsOnly && $rfHasPermission('INVENTORY_VIEW')")
      b-tooltip(style="width: 100%" :label="$t('navbar.inventory')" position="is-right" size="is-large" append-to-body)
        router-link(to='/inventory/products' :class="{'is-active': $route.path.startsWith('/inventory')}") 
          span.icon
            i.mdi.mdi-cart-outline(aria-hidden="true")
          span(v-t="'navbar.inventory'")
    li(v-if="$rfHasPermission('STAFF_VIEW')")
      b-tooltip(style="width: 100%" :label="$t('navbar.company')" position="is-right" size="is-large" append-to-body)
        router-link(to='/company/team' data-qa="navbar-company" :class="{'is-active': $route.path.startsWith('/company')}") 
          span.icon
            i.mdi.mdi-office-building-cog-outline(aria-hidden="true")
          span(v-t="'navbar.company'")
    
    li(v-if="$rfHasPermission('COMPANY_SETTINGS_VIEW')")
      b-tooltip(style="width: 100%" :label="$t('navbar.settings')" position="is-right" size="is-large" append-to-body)
        router-link(to='/settings/business' :class="{'is-active': $route.path.startsWith('/settings')}") 
          span.icon
            i.mdi.mdi-cog-outline(aria-hidden="true")
          span(v-t="'navbar.settings'")
  template(v-if="$rfSubscribed('education')")
    hr.always.is-narrow
    ul.menu-list
      li(v-if="$rfSubscribed('education')" )
        b-tooltip(style="width: 100%" :label="$t('navbar.education')" position="is-right" size="is-large" append-to-body)
          router-link(to='/education/overview' :class="{'is-active': $route.path.startsWith('/education')}") 
            span.icon
              i.mdi.mdi-school-outline(aria-hidden="true")
            span(v-t="'navbar.education'")
      
      //li(v-if="!company.ownSMSProvider")
        b-tooltip(style="width: 100%" :label="$t('navbar.checkout_usage', {remaining: company.checkoutLimit - (company.checkoutsMTD || 0), usage: company.checkoutsMTD || 0, limit: company.checkoutLimit})" position="is-right" size="is-large" append-to-body multilined)
          router-link.has-text-grey-light(to='/settings/billing' style="background-color: transparent" ) 
            span.icon
              i.mdi.mdi-speedometer
            span Usage {{company.checkoutsMTD || 0}} out {{company.checkoutLimit}}
</template>

<script>
import {mapState, mapGetters, mapActions} from 'vuex'
import CheckoutPopup from './checkout/CheckoutPopup'
import UpdatePaymentMethod from './billing/UpdatePaymentMethod'
import SubscribeToPaidPlan from './billing/SubscribeToPaidPlan'

export default {
  name: 'Navbar',
  data() {
    return {
      marketingShow: false,
      customerCareShow: false
    }
  },
  computed: {
    ...mapState('auth', ['user']),
    ...mapState('company', ['company']),
    ...mapState('billing', ['subscription']),
    ...mapGetters('billing', ['subscriptionIncomplete', 'subscriptionInactive']),
    ...mapGetters('company', ['trialHasEnded']),
    settingsAvailable() {
      return (
        this.$rfHasPermission('STAFF_VIEW') ||
        this.$rfHasPermission('COMPANY_SETTINGS_VIEW') ||
        this.$rfHasPermission('SERVICES_VIEW')
      )
    },
    marketingAvailable() {
      return (
        this.$rfHasPermission('REWARDS_VIEW') ||
        this.$rfHasPermission('REFERRALS_VIEW') ||
        this.$rfHasPermission('REVIEWS_VIEW') ||
        this.$rfHasPermission('AUTOMATIONS_VIEW') ||
        this.$rfHasPermission('COUPONS_VIEW') ||
        this.$rfHasPermission('CAMPAIGNS_VIEW') ||
        this.$rfHasPermission('FOLLOWUPS_VIEW')
      )
    }
  },
  created() {
    this.checkBilling()
    this.checkPlatforms()
  },
  methods: {
    ...mapActions('billing', ['cancelSubscription']),
    checkout() {
      this.$buefy.modal.open({
        parent: this,
        component: CheckoutPopup,
        fullScreen: true,
        canCancel: ['escape']
      })
    },
    checkPlatforms() {
      if (this.company.messenger.fbId && !this.company.messenger.active) {
        this.$buefy.snackbar.open({
          duration: 10000,
          message: this.$t('error.facebook_disconnected'),
          type: 'is-danger',
          position: 'is-bottom',
          actionText: this.$t('error.actions.fix_now'),
          cancelText: this.$t('actions.close'),
          queue: false,
          onAction: () => {
            this.$router.push('/settings/platforms')
          }
        })
      }
    },
    confirmSubscribe() {
      this.$buefy.modal.open({
        parent: this,
        component: SubscribeToPaidPlan,
        width: 960,
        hasModalCard: true,
        props: {
          planCode: this.company.plan.product
        },
        canCancel: ['escape']
      })
    },

    checkBilling() {
      if (this.company.manualPayment) {
        return
      }
      if (this.trialHasEnded) {
        this.$buefy.dialog.confirm({
          message:
            'Your Free Trial Period has ended. Please subscribe to the paid plan in case you liked it, or downgrade to the Free plan.',
          title: 'Trial Period Has Ended',
          type: 'is-black',
          confirmText: 'Subscribe',
          cancelText: 'Downgrade to Free Plan',
          canCancel: ['button'],
          onConfirm: () => {
            this.confirmSubscribe()
          },
          onCancel: () => {
            this.cancelSubscription(true)
          }
        })
      }

      if (this.subscriptionIncomplete) {
        return this.$buefy.modal.open({
          parent: this,
          hasModalCard: true,
          component: UpdatePaymentMethod,
          width: 640,
          canCancel: []
        })
      }
    }
  }
}
</script>
