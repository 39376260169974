<template lang="pug">
b-dropdown(v-model="selectedResourceId" @input="onChange" :scrollable="!isMobile()"  :append-to-body="isMobile()")
  template(#trigger)
    .is-flex.is-flex-direction-row.is-align-items-center.is-clickable
      resource-pic.is-32x32.is-rounded.mr-3(:value="selectedResourceId")
      div
        p.title.is-6.is-cropped-text(style="width: 100px") {{ selectedResource.name }}
      .control(v-if="hasPreferred")
        b-tooltip(:label="$t('appointment.mark_as_resource_preferred_tooltip')")
          .pl-2(@click.stop="switchPreferred") 
            span.icon.is-medium(style="vertical-align: -6px")
              i.mdi.mdi-24px.mdi-heart-outline(v-if="!isPreferred")
            
              i.mdi.mdi-heart.mdi-24px.has-text-danger(v-else)
  b-dropdown-item(v-for="resource in availableResources" :value="resource._id" :key="resource._id") 
    .is-flex.is-flex-direction-row.is-align-items-center
      resource-pic.is-32x32.is-rounded.mr-3(:value="resource")
      div
        p.is-size-6.has-text-weight-semibold {{ resource.name }}
</template>
<script>
import {mapState, mapGetters} from 'vuex'

export default {
  name: 'ResourceSelectDropdown',
  props: {
    resourceId: {type: String, default: undefined},
    preferredResource: {type: Boolean, default: false},
    expanded: {type: String, default: undefined},
    size: {type: String, default: undefined},
    locationId: {type: String, required: true},
    service: {type: Object, default: undefined},
    hasPreferred: {type: Boolean, default: true}
  },
  data() {
    return {
      selectedResourceId: undefined,
      isPreferred: false
    }
  },
  computed: {
    ...mapState('resources', ['resourcesMap']),
    ...mapGetters('resources', ['resourcesByLocation']),
    selectedResource() {
      if (!this.selectedResourceId) {
        return ''
      }
      return this.resourcesMap[this.selectedResourceId]
    },
    availableResources() {
      if (!this.service) {
        return this.resourcesByLocation(this.locationId)
      }
      if (!this.service.resources || !this.service.resources.length) {
        return []
      }
      return this.resourcesByLocation(this.locationId).filter(l => this.service.resources.includes(l._id))
    }
  },
  watch: {
    resourceId(newVal) {
      this.selectedResourceId = newVal
    },
    preferredResource(newVal) {
      this.isPreferred = newVal
    }
  },
  async created() {
    if (this.resourceId) {
      this.selectedResourceId = this.resourceId
      this.isPreferred = this.preferredResource
    } else {
      this.selectedResourceId = this.availableResources[0]._id
      this.onChange()
    }
  },
  methods: {
    onChange() {
      this.$emit('input', {resourceId: this.selectedResourceId, preferredResource: this.isPreferred})
    },

    switchPreferred() {
      this.isPreferred = !this.isPreferred
      this.onChange()
    }
  }
}
</script>
