<template lang="pug">
b-select(v-model="selected" @input="select" :expanded="expanded" :disabled="disabled" :placeholder="placeholder || $t('misc.duration_placeholder')" :name="name" style="text-align:right")
  option(v-for="option in options" :value="option") {{ option }} {{$t('misc.min_symbol')}}
</template>
<script>
export default {
  name: 'DurationInput',
  props: {
    name: {type: String, default: undefined},
    value: {type: Number, default: undefined},
    expanded: {type: String, default: undefined},
    disabled: {type: Boolean},
    undefinedValue: {type: Boolean, default: true},
    placeholder: {type: String, default: undefined}
  },
  data() {
    return {
      options: [],
      selected: undefined
    }
  },
  watch: {
    value(newVal) {
      this.selected = newVal
    }
  },
  async created() {
    this.selected = this.value
    this.options = [].concat(
      Array.from({length: 24}, (v, i) => i * 5),
      Array.from({length: 12}, (v, i) => 120 + i * 10),
      Array.from({length: 4}, (v, i) => 240 + i * 30)
    )
    this.options[0] = undefined
    if (!this.undefinedValue) {
      this.options = this.options.filter(o => o)
    }
  },
  inject: ['$validator'],
  methods: {
    select() {
      this.$emit('input', this.selected)
    }
  }
}
</script>
