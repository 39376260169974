<template lang="pug">
  b-table.is-vertically-aligned(:data="data" :loading="loading"  hoverable=true @click="showDetails")
  
    b-table-column(field="name" :label="$t('referrals.name_heading')" v-slot="props") 
      customer-tiny-preview(:value="props.row" )
    b-table-column(field="visited" :label="$t('referrals.visited_heading')" v-slot="props") 
      span.icon.has-text-success(v-if="props.row.used")
        i.mdi.mdi-check
    b-table-column(field="date" :label="$t('referrals.date_heading')" v-slot="props") 
      span {{props.row.created | fulldate}}

    template(slot="empty")
      empty-content(icon="close-circle-outline" title="referrals.list_no_referrals")
</template>

<script>
import CustomerDetailsMobile from '../customers/CustomerDetailsMobile.vue'
import Details from '../customers/Details'
import ReferralsApi from '@/api/referrals'

export default {
  name: 'CustomerReferralsList',
  props: {
    customerId: {type: String, required: true}
  },
  data() {
    return {
      data: undefined,
      loading: false
    }
  },
  async created() {
    this.loading = true
    try {
      this.data = await ReferralsApi.getCustomerReferrals(this.customerId)
    } catch (err) {
      this.$handleAPIError(err)
    }
    this.loading = false
  },
  methods: {
    showDetails(customer) {
      this.$buefy.modal.open({
        parent: this,
        component: this.isMobile() ? CustomerDetailsMobile : Details,
        hasModalCard: true,
        fullScreen: true,
        props: {customerId: customer._id},
        canCancel: ['escape', 'outside']
      })
    }
  }
}
</script>
