const INDUSTRIES = require('./industries')
const APPOINTMENT_STATUS = require('./appointmentStatus')
module.exports = {
  [INDUSTRIES.BEAUTY]: {
    appointment_statuses: {
      initial: [
        APPOINTMENT_STATUS.NEW,
        APPOINTMENT_STATUS.CONFIRMED,
        APPOINTMENT_STATUS.ARRIVED,
        APPOINTMENT_STATUS.STARTED
      ],
      abort: [APPOINTMENT_STATUS.CANCELED, APPOINTMENT_STATUS.NOSHOW]
    },

    services: {
      duration: true
    },
    calendar: true
  },
  [INDUSTRIES.DELIVERY]: {
    services: {
      duration: false
    },
    calendar: false
  },
  [INDUSTRIES.RETAIL]: {
    services: {
      duration: false
    },
    calendar: false
  },
  default: {
    appointment_statuses: {
      initial: [
        APPOINTMENT_STATUS.NEW,
        APPOINTMENT_STATUS.CONFIRMED,
        APPOINTMENT_STATUS.ARRIVED,
        APPOINTMENT_STATUS.STARTED
      ],
      abort: [APPOINTMENT_STATUS.CANCELED, APPOINTMENT_STATUS.NOSHOW]
    },

    services: {
      duration: true
    },
    calendar: true
  }
}
