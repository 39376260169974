const TYPES = require('./notificationTypes')
const MEANS = {
  EMAIL: 'email',
  MESSENGER: 'messenger'
}

const STAFF_TYPES = {
  ADMIN: 'admin',
  SPECIALIST: 'specialist'
}

module.exports = {
  [TYPES.APPOINTMENT_CREATE]: {
    means: [MEANS.EMAIL, MEANS.MESSENGER],
    recipient: [STAFF_TYPES.ADMIN, STAFF_TYPES.SPECIALIST]
  },
  [TYPES.APPOINTMENT_EDIT]: {
    means: [MEANS.EMAIL, MEANS.MESSENGER],
    recipient: [STAFF_TYPES.ADMIN, STAFF_TYPES.SPECIALIST]
  }
  // [TYPES.APPOINTMENT_REMINDER]: {
  //   means: [MEANS.EMAIL, MEANS.MESSENGER],
  //   recipient: [STAFF_TYPES.ADMIN, STAFF_TYPES.SPECIALIST]
  // },
  // [TYPES.CUSTOMER_CARE_TICKET]: {
  //   means: [MEANS.EMAIL],
  //   recipient: [STAFF_TYPES.ADMIN]
  // },
  // [TYPES.DAILY_REPORT]: {
  //   means: [MEANS.EMAIL],
  //   recipient: [STAFF_TYPES.ADMIN]
  // },
  // [TYPES.WEEKLY_REPORT]: {
  //   means: [MEANS.EMAIL],
  //   recipient: [STAFF_TYPES.ADMIN]
  // }
}
