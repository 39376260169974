<template lang="pug">
.modal-card
    header.modal-card-head
      p.modal-card-title
        b(v-t="'location.select_location_title'")
      div
        a.button.is-white.is-small(@click="$emit('close')")
          span.icon
            i.mdi.mdi-close.mdi-24px
    section.modal-card-body.p-0
      LocationListSelect(@input="onSelect")
    footer.modal-card-foot
      .buttons
        button.button.is-outlined(type="button" @click="$emit('close')" ) {{$t('actions.cancel')}}      
</template>
<script>
import LocationListSelect from './LocationListSelect'
export default {
  components: {
    LocationListSelect
  },
  methods: {
    onSelect(locationId) {
      this.$emit('input', locationId)
      this.$emit('close')
    }
  }
}
</script>
