<template lang="pug">
.modal-card
  header.modal-card-head
    p.modal-card-title(data-qa="modal-header") 
      b(v-if="serviceId" v-t="'services.details.title_edit'")
      b(v-else v-t="'services.details.title_new'")
    div
      a.button.is-white.is-small(@click="$emit('close')")
        span.icon
          i.mdi.mdi-close.mdi-24px
  section.modal-card-body.has-lightgrey-background
    .columns.is-centered(data-qa="service-form")
      .column.is-8-desktop
        .box  
          h2.title.is-4 {{$t('services.details.general_info_title')}}
          b-field(grouped)
            b-field(expanded :label="$t('services.details.name_label')" :type="errors.has('name') ? 'is-danger': ''" :message="errors.has('name') ? errors.first('name') : ''")
              b-input(:placeholder="$t('services.details.name_placeholder')" ref="nameInput" data-vv-as="name" name="name" v-validate="'required'" v-model="service.name" data-qa="name")
            b-field(:label="$t('services.details.color_label')")
              ColorPicker(v-model="service.color")
          b-field(:label="$t('services.details.category_label')" :type="errors.has('category') ? 'is-danger': ''" :message="errors.has('category') ? errors.first('category') : ''")
            b-select(v-model="service.category" expanded v-validate="'required'" name="category" :placeholder="$t('services.details.category_placeholder')")
              option(v-for="cat in categories" :value="cat._id" :key="cat._id") {{cat.name}}
          b-field(:label="$t('services.details.description_label')" :type="errors.has('description') ? 'is-danger': ''" :message="errors.has('description') ? errors.first('description') : ''")
            b-input(type="textarea" :placeholder="$t('services.details.description_placeholder')" data-vv-as="description" name="description" v-validate="'max:500'" v-model="service.description" data-qa="description")
          
          b-field(grouped)
            b-field(:label="$t('services.details.gender_label')")
              b-select(v-model="service.gender") 
                option(:value="null") {{$t('genders.anyone')}}
                option(:value="'f'") {{$t('genders.female')}}
                option(:value="'m'") {{$t('genders.male')}}
            b-field(:label="$t('services.details.age_restriction_label')")
              b-checkbox(v-model="service.kids") {{$t('services.details.children_service_label')}}

        .box  
          h2.title.is-4 {{$t('services.details.prices_duration_title')}}
          p.subtitle.is-6 {{$t('services.details.prices_duration_subtitle')}} 
          PricingOptions(v-model="service.pricingOptions" :staff="service.staff")
          hr
          h3.title.is-5 {{$t('services.details.extra_time_title')}}
          p.subtitle.is-6.has-text-grey {{$t('services.details.extra_time_subtitle')}}
          //b-field(grouped)
            b-field(label="Before the procedure")
              b-select(v-model="service.extraTime.beforeType")
                option() No extra time
                option(value="resource") Block resource only
                option(value="staff") Block staff only
                option(value="resourceStaff") Block resource and staff
            b-field(label="Before")
              duration-input(v-model="service.extraTime.before")
          b-field(grouped)
            b-field(:label="$t('services.details.after_procedure_label')")
              b-select(v-model="service.extraTime.afterType")
                option(:value="EXTRA_TYPE_TYPES.NO") {{ $t('services.details.extra_after_no') }}
                option(:value="EXTRA_TYPE_TYPES.PROCESSING") {{ $t('services.details.extra_after_processing') }}
                option(:value="EXTRA_TYPE_TYPES.BLOCK") {{ $t('services.details.extra_after_block') }}
            b-field(:label="$t('services.details.after_procedure_duration_label')" v-if="service.extraTime.afterType !== EXTRA_TYPE_TYPES.NO" :type="errors.has('extraTimeDuration') ? 'is-danger': ''" :message="errors.has('extraTimeDuration') ? errors.first('extraTimeDuration') : ''")
              duration-input(v-model="service.extraTime.after" :undefined-value="false" v-validate="'required'" data-vv-name="extraTimeDuration" name="extraTimeDuration")
          p.help(v-if="service.extraTime.afterType === EXTRA_TYPE_TYPES.PROCESSING") {{ $t('services.details.extra_after_processing_explanation') }}
          p.help(v-if="service.extraTime.afterType === EXTRA_TYPE_TYPES.BLOCK") {{ $t('services.details.extra_after_block_explanation') }}
          
        .box(v-if="$rfSettings().calendar && !company.rewardsOnly")
          h2.title.is-4 {{$t('services.details.online_booking_title')}}
          p.subtitle.is-6 {{$t('services.details.online_booking_subtitle')}} 
          note(type="is-warning" v-if="!service.staff || !service.staff.length") 
            b {{$t('services.details.online_booking_no_staff_warning')}} 
          note(type="is-warning" v-if="!service.locations || !service.locations.length") 
            b {{$t('services.details.online_booking_no_location_warning')}} 
          b-field(:label="$t('services.details.online_booking_label')")
            b-switch(v-model="service.online" size="is-medium") {{ service.online ? $t('misc.yes') : $t('misc.no') }}
        .box 
          h2.title.is-4 {{$t('services.details.chatbot_showcase_title')}}
          p.subtitle.is-6 {{$t('services.details.chatbot_showcase_subtitle')}}
           br
           b {{$t('services.details.messenger_showcase_limits')}}
          b-field(:label="$t('services.details.messenger_preview_label')")
            b-switch(v-model="service.showInChat" size="is-medium") {{ service.showInChat ? $t('misc.yes') : $t('misc.no') }} 
          label.label {{$t('services.details.picture_title')}}  
          .is-artwork
            image-preview(v-model="service.picture" category="services")
          p.help(v-t="'services.details.image_upload_help'")
        .box(v-if="hasMultipleLocations")
          h2.title.is-4 {{$t('services.details.locations_title')}}
          p.subtitle.is-6 {{$t('services.details.locations_subtitle')}}
          MultipleLocationSelector(v-model="service.locations")
        .box(v-if="hasResources")
          h2.title.is-4 {{$t('services.details.resources_title')}}
          p.subtitle.is-6 {{$t('services.details.resources_subtitle')}}
          MultipleResourceSelector(v-model="service.resources" :locations="service.locations")
        .box
          h2.title.is-4 {{$t('services.details.staff_title')}}
          p.subtitle.is-6 {{$t('services.details.staff_subtitle')}}
          staff-selector(v-model="service.staff")
        .box
          h2.title.is-4 {{$t('services.details.sales_title')}}
          p.subtitle.is-6 {{$t('services.details.sales_subtitle')}}
          
          b-field(:label="$t('rewards_program.collect_rewards_on_sale')")
            b-switch(v-model="service.collectRewards") {{ service.collectRewards ? $t('misc.yes') : $t('misc.no') }}

          b-field(:label="$t('services.service_hs_code_label')" v-if="company.country === 'AM'")
            b-input(v-model="service.hsCode" :placeholder="$t('services.service_hs_code_placeholder')")

          b-field(:label="$t('inventory.product_details.tax_label')")
            p {{ $t('inventory.product_details.item_tax_settings') }} 
              b
                tax-mapping-view(:value="service.taxes" type="services")
          b-field
            a.button.is-outlined.is-primary(@click="editTaxes") {{$t('services.details.set_tax_rates')}}
          
          b-switch(v-model="overrideCommission") 
            b(v-t="'services.details.commission_override_label'")
            //TaxSelector(v-model="service.tax")
          template(v-if="overrideCommission")
            b-field(:label="$t('services.details.commission_label')", :type="errors.has('commissionRate') ? 'is-danger': ''" :message="errors.has('commissionRate') ? errors.first('commissionRate') : ''")
              b-input(v-model="service.commissionRate" icon="percent" v-validate="'numeric'" name="commissionRate" :placeholder="$t('services.details.commission_placeholder')" data-vv-as="services commission" )
            b-field(:label="$t('services.details.commission_type_label')")
              b-radio(v-model="service.commissionType" name="commissionType" :native-value="COMMISSION_BASE_TYPES.BEFORE_DISCOUNT") {{$t('commission_base_type.before_discount')}}
              b-radio(v-model="service.commissionType" name="commissionType" :native-value="COMMISSION_BASE_TYPES.AFTER_DISCOUNT") {{$t('commission_base_type.after_discount')}}
                |  
                small
                  i.has-text-primary(v-t="'misc.recommended'")
  footer.modal-card-foot
    .buttons.is-flex-grow-1
      .is-flex-grow-1
        action-button(:loading="submitting" @click="save(false)" title="actions.save" permission="SERVICES_EDIT" data-qa="action-save")
        action-button(:loading="submitting" @click="save(true)" v-if="!serviceId" title="actions.save_and_add" permission="SERVICES_EDIT" data-qa="action-save-and-new")
        button.button(@click="$emit('close')") {{$t('actions.cancel')}}
      action-button(v-if="serviceId" type="is-danger" @click="confirmRemove" title="actions.delete" permission="SERVICES_REMOVE" data-qa="action-delete-service")          
</template>

<script>
import {mapActions, mapState, mapGetters} from 'vuex'
import {cloneDeep, fromPairs} from 'lodash'
import PricingOptions from './PricingOptions'
import StaffSelector from './StaffSelector'
import MultipleLocationSelector from './MultipleLocationSelector'
import MultipleResourceSelector from './MultipleResourceSelector'

import LocationTaxMapper from '../taxes/LocationTaxMapper'
import EXTRA_TYPE_TYPES from '../../../constants/serviceExtraTimeTypes'
import COMMISSION_BASE_TYPES from '../../../constants/commissionBaseTypes'
import ColorPicker from '../calendar/ColorPicker'
export default {
  name: 'ServiceCreateEditForm',
  components: {
    PricingOptions,
    StaffSelector,
    MultipleLocationSelector,
    MultipleResourceSelector,
    ColorPicker
  },
  props: {
    serviceId: {type: String, default: undefined},
    categoryId: {type: String, default: undefined}
  },
  data() {
    return {
      submitting: false,
      loading: false,
      EXTRA_TYPE_TYPES,
      COMMISSION_BASE_TYPES,
      overrideCommission: false,
      service: {
        name: undefined,
        description: undefined,
        color: undefined,
        picture: undefined,
        category: undefined,
        kids: undefined,
        gender: null,
        online: true,
        taxes: {},
        showInChat: true,
        pricingOptions: [{price: 0, duration: this.$rfSettings().services.duration ? 60 : undefined}],
        staff: [],
        locations: [],
        resources: [],
        collectRewards: true,

        commissionRate: undefined,
        commissionType: undefined,
        hsCode: undefined,
        extraTime: {
          before: 0,
          after: 10,
          afterType: 'no'
        }
      }
    }
  },
  computed: {
    ...mapState('company', ['company']),
    ...mapGetters('location', ['activeLocations', 'hasMultipleLocations']),
    ...mapGetters('staff', ['activeServiceMembers']),
    ...mapGetters('resources', ['hasResources']),
    ...mapGetters('services', ['categories'])
  },
  async created() {
    if (this.serviceId) {
      this.loading = true
      try {
        let service = await this.fetchService(this.serviceId)
        this.service = cloneDeep(service)
        if (!this.service.taxes) {
          this.service.taxes = this.getTaxDefaults()
        }
        this.overrideCommission = this.service.commissionRate !== undefined
      } catch (err) {
        this.$handleAPIError(err)
      }
      this.loading = false
    } else {
      this.service.category = this.categoryId
      this.service.locations = this.activeLocations.map(l => l._id)

      this.service.taxes = this.getTaxDefaults()
      this.service.staff = this.activeServiceMembers.map(s => s._id)
    }
  },
  mounted() {
    this.$refs.nameInput.focus()
  },
  methods: {
    ...mapActions('services', ['fetchService', 'createService', 'updateService', 'removeService']),
    async save(addAnother) {
      let valid = await this.$validator.validateAll()

      if (!valid || this.submitting) {
        return
      }
      this.submitting = true
      if (this.service.extraTime.afterType === 'no') {
        this.service.extraTime.after = 0
      }
      if (this.service.gender === null) {
        this.service.gender = undefined
      }
      if (!this.overrideCommission) {
        this.service.commissionRate = undefined
        this.service.commissionType = undefined
      }

      let action = this.serviceId ? this.updateService(this.service) : this.createService(this.service)
      try {
        await action
        this.$buefy.toast.open({message: this.$t('services.details.save_success'), type: 'is-success', queue: false})
        if (addAnother) {
          this.reset()
        } else {
          this.$emit('close')
        }
      } catch (err) {
        this.$handleAPIError(err)
      }
      this.submitting = false
    },
    editTaxes() {
      this.$buefy.modal.open({
        parent: this,
        props: {value: this.service.taxes},
        component: LocationTaxMapper,
        hasModalCard: true,
        events: {
          input: value => {
            this.service.taxes = value
          }
        },
        canCancel: ['escape']
      })
    },

    getTaxDefaults() {
      return fromPairs(this.activeLocations.map(l => [l._id, l.taxDefaults && l.taxDefaults.services]))
    },
    reset() {
      this.service = {
        name: undefined,
        description: undefined,
        color: undefined,
        picture: undefined,
        online: true,
        gender: null,
        kids: undefined,
        showInChat: true,
        category: this.service.category,
        pricingOptions: [{price: 0, duration: 60}],
        locations: this.activeLocations.map(l => l._id),
        staff: this.activeServiceMembers.map(s => s._id),
        taxes: this.getTaxDefaults(),
        commissionRate: undefined,
        commissionType: COMMISSION_BASE_TYPES.AFTER_DISCOUNT,
        hsCode: undefined,
        collectRewards: true,
        extraTime: {
          before: 0,
          after: 10,
          afterType: 'no'
        }
      }
      this.overrideCommission = false
      this.$refs.nameInput.focus()
    },
    confirmRemove() {
      this.$buefy.dialog.confirm({
        message: this.$t('services.details.remove_confirm.body'),
        title: this.$t('services.details.remove_confirm.title'),
        type: 'is-danger',
        confirmText: this.$t('services.details.remove_confirm.action'),
        cancelText: this.$t('services.details.remove_confirm.cancel'),
        onConfirm: () => {
          this.remove()
        }
      })
    },
    async remove() {
      try {
        await this.removeService(this.serviceId)
        this.$buefy.toast.open({
          message: this.$t('services.details.remove_confirm.success'),
          type: 'is-success',
          queue: false
        })
        this.$emit('close')
      } catch (err) {
        this.$handleAPIError(err)
      }
    }
  }
}
</script>
