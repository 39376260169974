import Navbar from '@/components/Navbar'

const Tabs = () => import(/* webpackChunkName: "stats" */ '@/components/analytics/Tabs')
const Container = () => import(/* webpackChunkName: "stats" */ '@/components/analytics/Container')
const OverviewTab = () => import(/* webpackChunkName: "stats" */ '@/components/analytics/OverviewTab')
const ReportsTab = () => import(/* webpackChunkName: "stats" */ '@/components/analytics/ReportsTab')
const PerformanceReport = () => import(/* webpackChunkName: "stats" */ '@/components/analytics/PerformanceReport')
const StaffPerformance = () => import(/* webpackChunkName: "stats" */ '@/components/staff/StaffPerformance')

export default [
  {
    path: '/analytics',
    name: 'Analytics',
    meta: {
      requiresAuth: true,
      title: 'Analytics'
    },
    components: {
      default: Container,
      navbar: Navbar,
      tabsmenu: Tabs
    },
    children: [
      {
        path: 'overview',
        component: OverviewTab,
        meta: {
          title: 'analytics.overview_title'
        }
      },
      {
        path: 'reports',
        component: ReportsTab,
        meta: {
          title: 'analytics.reports_title'
        }
      },
      {
        path: 'performance',
        component: PerformanceReport,
        meta: {
          title: 'analytics.performance_title'
          // background: 'white'
        }
      },
      {
        path: 'team/performance',
        component: StaffPerformance,
        meta: {
          requiresAuth: true,
          title: 'staff.title_performance'
        }
      }
    ]
  }
]
