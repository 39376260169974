<template lang="pug">
  navigation-menu(:items="items")
</template>

<script>
import NavigationMenu from '../shared/NavigationMenu'
import PERMISSIONS from '../../../constants/permissions'

export default {
  components: {
    NavigationMenu
  },
  data() {
    return {
      items: [
        {
          path: '/surveys/dashboard',
          icon: 'chart-box-outline',
          title: 'surveys.dashboard.title',
          permission: PERMISSIONS.surveys.VIEW
        },
        {
          path: '/surveys/issues',
          icon: 'emoticon-sad-outline',
          title: 'navbar.unhappy_visits',
          permission: PERMISSIONS.surveys.VIEW_TICKETS
        },
        {
          path: '/surveys/settings',
          icon: 'cog-outline',
          title: 'surveys.settings.title',
          permission: PERMISSIONS.surveys.MANAGE_SETTINGS
        }
      ]
    }
  }
}
</script>
