<template lang="pug">
div
  screen-title(title="sale.sales_title")
    action-button.is-hidden-mobile(
        @click="createSale",
        icon='basket-outline',
        title='sale.actions.new_sale',
        permission='SALE_CREATE'
        data-qa="action-new-sale"
      )
    a.button.is-uppercase.is-hidden-mobile(:href="exportLink" target="_blank" v-if="$rfSubscribed(PRODUCTS.CRM_PRO) && $rfHasPermission('SALE_EXPORT')") 
      span.icon
        i.mdi.mdi-download
      b {{$t('actions.export_data')}}
  
  
              
  .columns
    .column
      b-field(grouped group-multiline)
        b-field 
          PeriodSelector(v-model="period" @input="updateRouteParams")
          //p.control
            localized-datepicker(
              v-model="currentDate",
              :clearable="true",
              @input="updateRouteParams",
              :placeholder="$t('sale.datepicker_placeholder')"
              icon="calendar"
            )
        b-field
          StatusSelect(v-model="currentStatus" @input="updateRouteParams")
        b-field
          PaymentStatusSelect(v-model="currentPaymentStatus" @input="updateRouteParams")
        b-field
          LocationSelector(v-model="currentLocation" @input="updateRouteParams")
      //h3 {{$t('customers.found_title', {total})}}
      .box
        b-table.is-vertically-aligned(
          :data="sales" 
          :loading="loading" 
          hoverable=true 
          @click="showDetails"
          custom-row-key="_id"
          paginated
          backend-pagination
          :total="total"
          :per-page="perPage"
          @page-change="onPageChange"
          :mobile-cards="false"
          backend-sorting
          :default-sort-direction="defaultSortOrder"
          :default-sort="[sortField, sortOrder]"
          @sort="onSort"
          )
          template(slot="empty")
            empty-content(v-if="!loading"  title="sale.no_sales")
              action-button(
                @click="createSale",
                icon='basket-outline',
                title='sale.actions.new_sale',
                permission='SALE_CREATE'
                data-qa="action-new-sale")
          b-table-column(field="invoice" :label="$t('sale.list_invoice_id_heading')" v-slot="props" sortable)
            SaleStatusIcon(:status="props.row.status")
            span {{$t('sale.invoice_number', {invoice: props.row.invoice})}} 
            p.is-size-7(v-if="isMobile()") {{props.row.created | fulldate}}  
          
          b-table-column(field="total" :label="$t('sale.list_total_heading')" v-slot="props" sortable numeric)
            strong
              currency-view(:value="props.row.total")
            p(v-if="isMobile()")
              PaymentStatusIcon(:status="props.row.paymentStatus" :show-text="true" :is-tag="true" v-if="props.row.status === SALE_STATUS.COMPLETED" tag-size="is-small")
          b-table-column(field="discount" :label="$t('sale.list_discount_heading')" v-slot="props" sortable numeric :visible="!isMobile()")
            currency-view(:value="props.row.discount" v-if="props.row.discount")
          //b-table-column(field="paid" :label="$t('sale.list_paid_heading')" v-slot="props" sortable numeric :visible="!isMobile()")
            currency-view(:value="props.row.paid")

          b-table-column(field="paymentStatus" :label="$t('sale.list_payment_status_heading')" v-slot="props" sortable :visible="!isMobile()")
            PaymentStatusIcon(v-if="props.row.status === SALE_STATUS.COMPLETED" :status="props.row.paymentStatus" :show-text="true" :is-tag="true" )
            SaleStatusIcon(v-else :status="props.row.status" is-tag show-text)
          b-table-column(field="item" :label="$t('sale.list_items_heading')" v-slot="props" :visible="!isMobile()")
            .mb-1(v-for="item in props.row.items")
              SaleItem(:item="item")
          b-table-column(field="customer" :label="$t('sale.list_customer_heading')" v-slot="props")
            customer-preview(:customerId="props.row.customer" v-if="props.row.customer" :detailed="false")
          b-table-column(field="created" :label="$t('sale.list_created_heading')" v-slot="props" sortable numeric :visible="!isMobile()")
            date-view(:value="props.row.created" format="L LT")
</template>

<script>
import {mapActions, mapGetters, mapState} from 'vuex'
//import dayjs from 'dayjs'
import Checkout from './Checkout'
import LocationSelector from './../helpers/LocationSelector'
import StatusSelect from './StatusSelect'
import PaymentStatusSelect from './PaymentStatusSelect'
import SaleDetails from './SaleDetails'
import SaleItem from './SaleItem'
import SaleStatusIcon from './SaleStatusIcon'
import PaymentStatusIcon from './PaymentStatusIcon'
import SALE_STATUS from '../../../constants/saleStatus'
import CheckoutPopup from '../checkout/CheckoutPopup'
import {PRODUCTS} from '../../../constants/products'
import PeriodSelector from '../helpers/PeriodSelector'

export default {
  name: 'SalesListView',
  components: {
    LocationSelector,
    StatusSelect,
    PaymentStatusSelect,
    SaleItem,
    SaleStatusIcon,
    PaymentStatusIcon,
    PeriodSelector
  },
  props: {
    location: {type: String, default: undefined},
    from: {type: String, default: undefined},
    to: {type: String, default: undefined},
    status: {type: String, default: undefined},
    paymentStatus: {type: String, default: undefined}
  },
  data() {
    return {
      PRODUCTS,
      SALE_STATUS,
      loading: false,
      sortOrder: 'desc',
      page: 1,
      perPage: 10,
      defaultSortOrder: 'desc',
      currentStatus: undefined,
      currentPaymentStatus: undefined,
      currentLocation: undefined,
      period: undefined
    }
  },
  computed: {
    ...mapState('sales', ['total']),
    ...mapState('company', ['company']),
    ...mapGetters('sales', ['sales']),
    exportLink() {
      let url = this.$url('sales/export', {
        status: this.status,
        paymentStatus: this.paymentStatus,
        location: this.location,
        sortBy: this.sortField,
        sortDir: this.sortOrder,
        from: this.from,
        to: this.to
      })
      return url
    }
  },
  watch: {
    from(newValue, oldValue) {
      if (this.from && this.to) {
        this.period = {from: this.from, to: this.to}
      } else {
        this.period = undefined
      }

      if (newValue !== oldValue) {
        this.fetchData()
      }
    },
    to(newValue, oldValue) {
      if (this.from && this.to) {
        this.period = {from: this.from, to: this.to}
      } else {
        this.period = undefined
      }
      if (newValue !== oldValue) {
        this.fetchData()
      }
    },
    location() {
      this.fetchData()
    },
    status() {
      this.fetchData()
    },
    paymentStatus() {
      this.fetchData()
    }
  },
  async created() {
    this.sortField = 'created'
    this.currentPaymentStatus = this.paymentStatus
    this.currentStatus = this.status
    this.currentLocation = this.location
    if (this.from && this.to) {
      this.period = {from: this.from, to: this.to}
    }

    //await this.fetchData()
  },
  methods: {
    ...mapActions('sales', ['fetchSales']),
    updateRouteParams() {
      let query = {
        status: this.currentStatus,
        paymentStatus: this.currentPaymentStatus,
        location: this.currentLocation,
        from: this.period && this.period.from.toISOString(),
        to: this.period && this.period.to.toISOString()
      }
      this.$router.push({name: 'Sales', query}).catch(() => {})
    },
    async fetchData() {
      this.loading = true
      try {
        await this.fetchSales({
          location: this.location,
          from: this.from,
          to: this.to,
          status: this.status,
          paymentStatus: this.paymentStatus,
          sortBy: this.sortField,
          sortDir: this.sortOrder,
          page: this.page,
          perPage: this.perPage
        })
      } catch (err) {
        this.$handleAPIError(err)
      }
      this.loading = false
    },
    createSale() {
      if (this.company.rewardsOnly) {
        this.$buefy.modal.open({
          parent: this,
          component: CheckoutPopup,
          fullScreen: true,
          canCancel: ['escape']
        })
      } else {
        this.$buefy.modal.open({
          parent: this,
          component: Checkout,
          props: {locationId: this.location},
          fullScreen: true,
          canCancel: ['escape']
        })
      }
    },

    onSort(field, order) {
      this.sortField = field
      this.sortOrder = order
      this.fetchData()
    },
    onPageChange(page) {
      this.page = page
      this.fetchData()
    },
    showDetails(sale) {
      if (
        sale.status === SALE_STATUS.COMPLETED ||
        sale.status === SALE_STATUS.REFUNDED ||
        sale.status === SALE_STATUS.VOID
      ) {
        this.$buefy.modal.open({
          parent: this,
          props: {saleId: sale._id},
          component: SaleDetails,
          fullScreen: true,
          hasModalCard: true,
          canCancel: ['escape', 'outside']
        })
      } else {
        this.$buefy.modal.open({
          parent: this,
          props: {saleId: sale._id, customerId: sale.customer},
          fullScreen: true,
          hasModalCard: true,
          canCancel: ['escape', 'outside']
        })
      }
    }
  }
}
</script>
