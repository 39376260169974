<template lang="pug">
  span(v-if="membershipPackage") {{membershipPackage.name}}
</template>
<script>
import {mapActions} from 'vuex'

export default {
  name: 'MembershipPackageNameView',
  props: {
    value: {type: String, required: true}
  },
  data() {
    return {
      membershipPackage: undefined
    }
  },
  async created() {
    this.membershipPackage = await this.getMembershipPackageById(this.value)
  },
  methods: {
    ...mapActions('membershipPackages', ['getMembershipPackageById'])
  }
}
</script>
