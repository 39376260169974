<template lang="pug">
div.is-relative
  em-loading(:active="loading" :is-full-page="false")
  .columns.is-multiline(v-if="forms")
    .column.is-6.p-1(v-for="customerForm in customerForms" :key="customerForm._id")
      form-view(:formId="forms.find(i => i._id === customerForm.form)._id" @click="preview(customerForm._id)" large)
    .column.is-6.p-1
      .brand-bordered.p-4.is-clickable(@click="newForm") 
        .is-flex.is-align-items-center
          div.is-flex-shrink-0.mr-3
            span.icon.is-large
              i.mdi.mdi-note-plus-outline.mdi-36px
          div.is-flex-grow-1
            
            p.title.is-6(v-t="'forms.actions.attach_to_customer'")
        
        
  
</template>
<script>
import {mapActions} from 'vuex'
import CustomerForm from './CustomerForm.vue'
import FormPreview from './FormPreview.vue'
import SelectFormModal from './SelectFormModal.vue'
export default {
  name: 'CustomerForms',
  props: {
    customerId: {type: String, required: true}
  },
  data() {
    return {
      customerForms: undefined,
      forms: undefined,
      loading: false
    }
  },
  created() {
    this.load()
  },
  methods: {
    ...mapActions('forms', ['fetchCustomerForms', 'getFormById']),
    async load() {
      this.loading = true
      try {
        this.forms = undefined
        this.customerForms = await this.fetchCustomerForms(this.customerId)

        this.forms = await Promise.all(this.customerForms.map(f => this.getFormById(f.form)))
      } catch (err) {
        this.$handleAPIError(err)
      }
      this.loading = false
    },
    preview(customerFormId) {
      this.$buefy.modal.open({
        parent: this,
        component: CustomerForm,
        hasModalCard: true,
        fullScreen: this.isMobile(),
        props: {
          customerId: this.customerId,
          customerFormId
        },
        events: {
          input: this.loadForm,
          refresh: this.load
        },
        canCancel: ['escape']
      })
    },
    newForm() {
      this.$buefy.modal.open({
        parent: this,
        component: SelectFormModal,
        hasModalCard: true,
        fullScreen: this.isMobile(),
        events: {
          input: this.loadForm,
          refresh: this.load
        },
        canCancel: ['escape']
      })
    },
    loadForm(formId) {
      this.$buefy.modal.open({
        parent: this,
        component: FormPreview,
        props: {
          customerId: this.customerId,
          formId
        },
        hasModalCard: true,
        fullScreen: this.isMobile(),
        events: {
          input: this.loadForm,
          refresh: this.load
        },
        canCancel: ['escape']
      })
    }
  }
}
</script>
