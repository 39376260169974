<template lang="pug">
.modal-card
  header.modal-card-head
    p.modal-card-title(data-qa="modal-header") 
      b(v-t="'appointment.notes_title'")
    div
      a.button.is-white.is-small(@click="$emit('close')")
        span.icon
          i.mdi.mdi-close.mdi-24px
  section.modal-card-body
    b-input.is-fullwidth(v-model="notes" type="textarea" ref="notes" :placeholder="$t('appointment.notes_placeholder')")
  footer.modal-card-foot
    .buttons
      action-button(title="actions.save" @click="submit" :loading="submitting" permission="APPOINTMENT_EDIT_NOTES")
      button.button(@click="$emit('close')" v-t="'actions.cancel'")
</template>
<script>
import {mapActions, mapGetters} from 'vuex'
export default {
  name: 'EditNotes',
  props: {
    appointmentId: {type: String, default: undefined},
    value: {type: String, default: undefined}
  },
  data() {
    return {
      submitting: false,
      notes: undefined
    }
  },
  computed: {
    ...mapGetters('appointments', ['appointmentById']),
    appointment() {
      if (this.appointmentId) {
        return this.appointmentById(this.appointmentId)
      }
      return undefined
    }
  },
  async created() {
    if (this.appointment) {
      this.notes = this.appointment.notes
    } else {
      this.notes = this.value
    }
  },
  mounted() {
    this.$refs.notes.focus()
  },
  methods: {
    ...mapActions('appointments', ['editNotes']),
    async submit() {
      if (!this.appointmentId) {
        this.$emit('input', (this.notes && this.notes.trim()) || undefined)
        return this.$emit('close')
      }
      if (this.submitting) {
        return
      }
      this.submitting = true
      try {
        await this.editNotes({appointmentId: this.appointmentId, notes: (this.notes && this.notes.trim()) || undefined})
      } catch (e) {
        this.$handleAPIError(e)
        this.submitting = false
      }

      this.$emit('close')
    }
  }
}
</script>
