import Navbar from '@/components/Navbar'

const Menu = () => import(/* webpackChunkName: "inventory" */ '@/components/inventory/Menu')
const Container = () => import(/* webpackChunkName: "inventory" */ '@/components/inventory/Container')
const Products = () => import(/* webpackChunkName: "inventory" */ '@/components/inventory/Products')
const Categories = () => import(/* webpackChunkName: "inventory" */ '@/components/inventory/Categories')
const Brands = () => import(/* webpackChunkName: "inventory" */ '@/components/inventory/Brands')
const InventoryReport = () => import(/* webpackChunkName: "inventory" */ '@/components/inventory/InventoryReport')
const InventoryHSCodeEdit = () =>
  import(/* webpackChunkName: "inventory" */ '@/components/inventory/InventoryHSCodeEdit')
export default [
  {
    path: '/inventory',
    name: 'Inventory',
    components: {
      default: Container,
      navbar: Navbar,
      menu: Menu
    },
    children: [
      {
        path: 'products',
        component: Products,
        meta: {
          title: 'navbar.inventory',
          pageTitle: 'inventory.title_products',
          requiresAuth: true
        }
      },
      {
        path: 'products/hsCode',
        component: InventoryHSCodeEdit,
        meta: {
          title: 'navbar.inventory',
          pageTitle: 'inventory.title_products_hs_code',
          requiresAuth: true
        }
      },
      {
        path: 'categories',
        component: Categories,
        meta: {
          title: 'navbar.inventory',
          pageTitle: 'inventory.title_categories',
          requiresAuth: true
        }
      },
      {
        path: 'brands',
        component: Brands,
        meta: {
          title: 'navbar.inventory',
          pageTitle: 'inventory.title_brands',
          requiresAuth: true
        }
      },
      {
        path: 'report',
        component: InventoryReport,
        meta: {
          title: 'navbar.inventory',
          pageTitle: 'inventory.title_brands',
          requiresAuth: true
        }
      }
    ]
  }
]
