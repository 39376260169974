<template lang="pug">
  span.icon
    i.mdi.mdi-24px(:class="['mdi-' + icon, 'has-text-' + color]")
</template>

<script>
import REWARD_TYPES from '../../../constants/rewardTypes'

export default {
  props: {
    type: {type: String, required: true}
  },
  computed: {
    icon() {
      switch (this.type) {
        case REWARD_TYPES.COLLECT:
          return 'arrow-down-bold-box-outline'
        case REWARD_TYPES.REDEEM:
          return 'arrow-up-bold-box-outline'
        case REWARD_TYPES.GIFT:
          return 'gift-outline'
        case REWARD_TYPES.REFERENT_SIGNUP:
          return 'account-plus'
        case REWARD_TYPES.PROMO_SIGNUP:
          return 'star'
        case REWARD_TYPES.REFERRER_REWARD:
          return 'account-check'
        case REWARD_TYPES.ADJUST:
          return 'plus-box'
        case REWARD_TYPES.COUPON_REDEEM:
          return 'ticket-percent'
        case REWARD_TYPES.AFFILIATE_REWARD:
          return 'account-multiple-plus'
        default:
          return ''
      }
    },
    color() {
      switch (this.type) {
        case REWARD_TYPES.COLLECT:
          return 'success'
        case REWARD_TYPES.REDEEM:
          return 'danger'
        case REWARD_TYPES.GIFT:
          return 'warning'
        case REWARD_TYPES.REFERENT_SIGNUP:
          return 'success'
        case REWARD_TYPES.PROMO_SIGNUP:
          return 'success'
        case REWARD_TYPES.REFERRER_REWARD:
          return 'success'
        case REWARD_TYPES.ADJUST:
          return 'info'
        case REWARD_TYPES.COUPON_REDEEM:
          return 'success'
        case REWARD_TYPES.AFFILIATE_REWARD:
          return 'success'
        default:
          return ''
      }
    }
  }
}
</script>
