import Vue from 'vue'

export default {
  list(params) {
    return Vue.http.get('coupons', {params})
  },
  create(data) {
    return Vue.http.post('coupons', data)
  },
  update(couponId, data) {
    return Vue.http.put(`coupons/${couponId}`, data)
  },
  getById(couponId) {
    return Vue.http.get(`coupons/${couponId}`).then(res => res.body)
  },
  remove(couponId) {
    return Vue.http.delete(`coupons/${couponId}`)
  },
  getTypes() {
    return new Promise(resolve => {
      resolve([
        {
          value: 'cashback',
          title: 'Cashback',
          description: 'Cashback coupons override current points rate (preferred than Discount coupons)',
          icon: 'percent'
        },
        {
          value: 'discount',
          title: 'Percentage Discount',
          description: 'Give discounts coupons as a gift to you customers',
          icon: 'percent'
        },
        {
          value: 'service',
          title: 'Free Service',
          description: 'Define a list of services you would like to offer to your customers on a complimentary basis',
          icon: 'heart-box-outline'
        },
        {
          value: 'product',
          title: 'Free Product',
          description: 'Complimentary product as a gift for you customers',
          icon: 'gift'
        },
        {
          value: 'points',
          title: 'Gift Points',
          description: 'Complimentary points as a gift for you customers (redeemed immediately)',
          icon: 'currency-usd'
        },
        {
          value: 'combo',
          title: 'Combo Package',
          description: 'Define combo packages that will cross sell your services or products',
          icon: 'package-variant'
        },
        {
          value: 'off',
          title: 'Fixed Amount Discount',
          description: 'An amount to be reduced from a specific services',
          icon: 'minus-circle-outline'
        }
      ])
    })
  }
}
