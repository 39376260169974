<template lang="pug">
span.tag(:class="{'is-danger': !isDefaultDepositGroup}") {{$t(`deposit_ratio.${effectiveDeposit}`)}}
</template>
<script>
import {mapState} from 'vuex'
export default {
  name: 'DepositGroupView',
  props: {
    customer: {type: Object, required: true}
  },
  computed: {
    ...mapState('company', ['company']),
    effectiveDeposit() {
      return this.customer.depositGroup || this.company.deposit.defaultRatio
    },
    isDefaultDepositGroup() {
      return !this.customer.depositGroup || this.customer.depositGroup === this.company.deposit.defaultRatio
    }
  }
}
</script>
