<template lang="pug">
  .card.is-clickable(@click="$emit('click')")
    .media
      .media-left
        figure.image(:class="{'is-128x128': size === 'large', 'is-96x96': size === 'small'}")
          img(:src="location.images && location.images[0] || require('@/assets/location.png')")
      .media-content.p-3
        h2.title.is-5(:class="{'has-text-grey': !location.active}") {{location.title}}
        p.subtitle(:class="{'has-text-grey': !location.active, 'is-size-6': size === 'small'}") 
          location-address-view(:location-id="location._id")
        p(v-if="company.country === 'AM' && location.active && size === 'large'") 
          a(@click.stop :href="`https://www.emly.am/preview/${location._id}`" target="_blank") {{ $t('location_settings.preview_emly_am_page') }}
          
          
          template(v-if="company.listing")
            span.icon.has-text-success
              i.mdi.mdi-circle
            span(v-t="'misc.active'")
          template(v-else)
            span.icon.has-text-danger
              i.mdi.mdi-circle
            span(v-t="'misc.inactive'")
</template>

<script>
import {mapState} from 'vuex'
export default {
  props: {
    size: {type: String, default: 'large'},
    location: {type: Object, required: true}
  },
  computed: {
    ...mapState('company', ['company'])
  }
}
</script>
<style scoped>
.card,
.card img {
  transition: all 200ms ease-in-out;
}
.card:hover {
  background: #f1f1f1;
}
.card:hover img {
  filter: brightness(70%);
}
</style>
