<template lang="pug"> 
  table.table.is-fullwidth.is-vertically-centered
    thead
      tr
        th(v-t="'working_hours.open_heading'")
        th(v-t="'working_hours.day_heading'")
        th(v-t="'locations_settings.opening_hours_heading'")
    tbody
      tr(v-for="day in days" :key="day.name")
        td
          b-switch(v-model="day.open" type="is-primary" @input="onChange")
        td {{$t(`working_hours.weekdays.${day.name}`)}}
        td
          b-field(grouped :type="errors.has(`workingHours-${day.name}`) ? 'is-danger': ''" :message="errors.has(`workingHours-${day.name}`) ? errors.first(`workingHours-${day.name}`) : ''")
            timepicker(icon="clock" v-model="day.start" @input="onChange" :max-time="day.end" :name="`workingHours-${day.name}`" data-vv-as="Start time")
            timepicker(icon="clock" v-model="day.end" @input="onChange" :min-time="day.start" :name="`workingHours-${day.name}`" data-vv-as="End time") 
</template>

<script>
export default {
  name: 'LocationWorkingHours',
  props: {
    value: {type: Object, default: undefined}
  },
  data() {
    return {
      days: {mon: {}, tue: {}, wed: {}, thu: {}, fri: {}, sat: {}, sun: {}}
    }
  },
  watch: {
    value(value) {
      if (value) {
        this.setData()
      }
    }
  },
  created() {
    this.setData()
  },
  methods: {
    setData() {
      for (const day in this.days) {
        this.days[day] = {
          name: day,
          start: this.getHourValue(day, 'start'),
          end: this.getHourValue(day, 'end'),
          open: !!(this.value && this.value[day] && this.value[day].open)
        }
      }
    },
    getHourValue(day, type) {
      return (
        (this.value && this.value[day] && this.value[day][type] !== undefined && this.value[day][type]) ||
        (type === 'start' ? 9 * 60 : 18 * 60)
      )
    },
    async onChange() {
      const workingHours = {}
      for (const day in this.days) {
        workingHours[day] = {
          open: this.days[day].open,
          start: this.days[day].start,
          end: this.days[day].end
        }
      }
      this.$emit('input', workingHours)
    }
  }
}
</script>
