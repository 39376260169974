<template lang="pug">
div
  screen-title(title="payment.payments_title")
    a.button.is-uppercase.is-hidden-mobile(:href="exportLink" target="_blank" v-if="$rfHasPermission('PAYMENT_EXPORT')") 
      span.icon
        i.mdi.mdi-download
      b {{$t('actions.export_data')}}
  .columns
    .column
        .columns(v-if="stats")
          .column
            .box
              p.title.is-6.is-uppercase.has-text-darkgrey {{ $t('payment.payout_pending') }}
              h3.title.is-2.is-size-3-mobile
                currency-view(:value="stats.due.payoutAmount + stats.pending.payoutAmount")
          .column
            .box
              p.title.is-6.is-uppercase.has-text-darkgrey {{ $t('payment.payout_completed') }}
              h3.title.is-2.is-size-3-mobile
                currency-view(:value="stats.done.payoutAmount")
        .columns
          .column
            form(v-on:submit.prevent="onSearch")
              .field.is-grouped
                .control.is-expanded
                  .field.has-addons.is-expanded
                    .control.is-expanded
                      input.input(type="text" v-model="query" :placeholder="$t('payment.search_placeholder')" autofocus)
                    .control
                      button.button.is-primary(type="submit", :class="loading ? 'is-loading': ''") 
                        span.icon
                          i.mdi.mdi-magnify
                        span(v-t="'actions.search'")
        .columns
          .column
            b-field(grouped group-multiline)
              b-field 
                PeriodSelector(v-model="period" @input="updateRouteParams")
                //p.control
                  localized-datepicker(
                    v-model="currentDate",
                    :clearable="true",
                    @input="updateRouteParams",
                    :placeholder="$t('payment.datepicker_placeholder')"
                    icon="calendar"
                  )
              b-field
                CardPaymentStatusSelect(v-model="currentStatus" @input="updateRouteParams")
              
              b-field
                LocationSelector(v-model="currentLocation" @input="updateRouteParams")
        //h3 {{$t('customers.found_title', {total})}}
        .box
          b-table.is-vertically-aligned(
            :data="payments" 
            :loading="loading" 
            hoverable=true 
            @click="showDetails"
            custom-row-key="_id"
            paginated
            backend-pagination
            :total="total"
            :per-page="perPage"
            @page-change="onPageChange"
            :mobile-cards="false"
            backend-sorting
            :default-sort-direction="defaultSortOrder"
            :default-sort="[sortField, sortOrder]"
            @sort="onSort"
            )
            template(slot="empty")
              empty-content(v-if="!loading" title="payment.no_payments")
                    
            b-table-column(field="invoice" :label="$t('payment.list_status_heading')" v-slot="props" sortable)
              CardPaymentStatusIcon(:status="props.row.status" is-tag) 
              date-view.is-size-7(v-if="isMobile()" :value="props.row.created" format="L LT") 
            
            b-table-column(field="type" :label="$t('payment.list_type_heading')" v-slot="props" )
              span.tag.is-black(v-if="props.row.appointment") {{ $t('payment.type_deposit') }}
              span.tag.is-primary(v-else)  {{ $t('payment.type_payment') }}
              template(v-if="props.row.sale")
                br
                invoice-view(:value="props.row.sale" :invoice="props.row.invoice") 
              template(v-if="props.row.appointment")
                br
                a(@click.stop="showAppointmentDetails(props.row.appointment)" v-t="'payment.actions.view_appointment'") 
            b-table-column(field="amount" :label="$t('payment.list_amount_heading')" v-slot="props" sortable numeric)
              strong
                currency-view(:value="props.row.payoutAmount")
              br
              strong.is-size-7
                |  (
                currency-view(:value="props.row.amount")
                | )
            
            //b-table-column(field="payoutAmount" :label="$t('payment.list_actual_amount_heading')" v-slot="props" sortable numeric)
              
            b-table-column(field="payoutScheduled" :label="$t('payment.payout_heading')" v-slot="props" sortable)
              p(v-if="props.row.status === PAYMENT_STATUS.SUCCESS") 
                template(v-if="props.row.payoutStatus === PAYOUT_STATUS.DUE")
                  span.tag.is-info(v-t="`payout_status.${PAYOUT_STATUS.DUE}`")
                  br
                  date-view(:value="props.row.payoutScheduled" format="L")
                template(v-if="props.row.payoutStatus === PAYOUT_STATUS.DONE")
                  span.tag.is-success(v-t="`payout_status.${PAYOUT_STATUS.DONE}`")
                  br
                  date-view(:value="props.row.payout" format="L LT")
                template(v-if="props.row.payoutStatus === PAYOUT_STATUS.PENDING")
                  span.tag.is-warning(v-t="`payout_status.${PAYOUT_STATUS.PENDING}`")
                template(v-if="props.row.payoutStatus === PAYOUT_STATUS.FAILED")
                  span.tag.is-warning(v-t="`payout_status.${PAYOUT_STATUS.FAILED}`")

            //b-table-column(field="name" :label="$t('payment.list_customer_heading')" v-slot="props")
              span {{props.row.cardHolder}}
            
            b-table-column(field="phone" :label="$t('payment.phone_heading')" v-slot="props")
              phone-view(:value="props.row.phone")

            b-table-column(field="card" :label="$t('payment.list_card_heading')" v-slot="props")
              span(v-if="props.row.card") •••• {{props.row.card}}
            b-table-column(field="refundableUntil" :label="$t('payment.list_refundable_heading')" sortable v-slot="props")
              template(v-if="props.row.refundableUntil && props.row.status === PAYMENT_STATUS.SUCCESS")
                date-view.tag.is-info(v-if="new Date(props.row.refundableUntil) > new Date()" :value="props.row.refundableUntil" format="L LT")
                date-view.tag.is-danger(v-else :value="props.row.refundableUntil" format="L LT")
            b-table-column(field="created" :label="$t('payment.list_created_heading')" v-slot="props" sortable numeric :visible="!isMobile()")
              date-view(:value="props.row.created" format="L LT")
          
            
</template>

<script>
import {mapActions, mapGetters, mapState} from 'vuex'
import LocationSelector from './../helpers/LocationSelector'
import CardPaymentStatusIcon from './CardPaymentStatusIcon'
import CardPaymentStatusSelect from './CardPaymentStatusSelect'
import CardPaymentDetails from './CardPaymentDetails'
import PeriodSelector from '../helpers/PeriodSelector'
import CreateEditAppointment from '../calendar/CreateEditAppointment'
import AppointmentDetails from '../calendar/AppointmentDetails'
import PaymentsApi from '@/api/payments'
import PAYOUT_STATUS from '../../../constants/payoutStatus'
import PAYMENT_STATUS from '../../../constants/cardPaymentStatus'
import APPOINTMENT_STATUS from '../../../constants/appointmentStatus'
export default {
  name: 'PaymentsListView',
  components: {
    LocationSelector,
    PeriodSelector,
    CardPaymentStatusIcon,
    CardPaymentStatusSelect
  },
  props: {
    location: {type: String, default: undefined},
    from: {type: String, default: undefined},
    to: {type: String, default: undefined},
    status: {type: String, default: PAYMENT_STATUS.SUCCESS}
  },
  data() {
    return {
      loading: false,
      sortOrder: 'desc',
      page: 1,
      perPage: 10,
      defaultSortOrder: 'desc',
      currentStatus: undefined,
      currentLocation: undefined,
      period: undefined,
      query: undefined,
      stats: undefined,
      PAYOUT_STATUS,
      PAYMENT_STATUS
    }
  },
  computed: {
    ...mapState('payments', ['total']),
    ...mapState('company', ['company']),
    ...mapGetters('payments', ['payments']),
    exportLink() {
      let url = this.$url('payments/export', {
        status: this.status,
        location: this.location,
        sortBy: this.sortField,
        sortDir: this.sortOrder,
        from: this.from,
        to: this.to
      })
      return url
    }
  },
  watch: {
    from() {
      this.fetchData()
    },
    to() {
      this.fetchData()
    },
    location() {
      this.fetchData()
    },
    status() {
      this.fetchData()
    }
  },
  async created() {
    this.sortField = 'created'
    this.currentStatus = this.status
    this.currentLocation = this.location
    if (this.from && this.to) {
      this.period = {from: this.from, to: this.to}
    }
    await this.fetchData()
    await this.fetchStats()
  },
  methods: {
    ...mapActions('payments', ['fetchPayments']),
    ...mapActions('appointments', ['fetchAppointmentById']),

    updateRouteParams() {
      let query = {
        status: this.currentStatus,
        location: this.currentLocation,
        from: this.period && this.period.from.toISOString(),
        to: this.period && this.period.to.toISOString()
      }
      this.$router.push({name: 'Payments', query}).catch(() => {})
    },
    async fetchStats() {
      this.stats = await PaymentsApi.getPayoutStats()
    },
    async fetchData() {
      this.loading = true
      try {
        await this.fetchPayments({
          location: this.location,
          from: this.from,
          to: this.to,
          status: this.status,
          sortBy: this.sortField,
          sortDir: this.sortOrder,
          page: this.page,
          perPage: this.perPage,
          query: this.query
        })
      } catch (err) {
        this.$handleAPIError(err)
      }
      this.loading = false
    },

    onSearch() {
      this.fetchData()
    },
    onSort(field, order) {
      this.sortField = field
      this.sortOrder = order
      this.fetchData()
    },
    onPageChange(page) {
      this.page = page
      this.fetchData()
    },
    showDetails(payment) {
      this.$buefy.modal.open({
        parent: this,
        props: {paymentId: payment._id},
        component: CardPaymentDetails,
        hasModalCard: true,
        width: 960,
        canCancel: ['escape', 'outside']
      })
    },
    async showAppointmentDetails(appointmentId) {
      let appt = await this.fetchAppointmentById({id: appointmentId})
      if (
        appt.status === APPOINTMENT_STATUS.COMPLETED ||
        appt.status === APPOINTMENT_STATUS.NOSHOW ||
        appt.status === APPOINTMENT_STATUS.CANCELED ||
        appt.status === APPOINTMENT_STATUS.INCOMPLETE
      ) {
        return this.$buefy.modal.open({
          parent: this,
          component: AppointmentDetails,
          props: {appointmentId},
          fullScreen: true,
          canCancel: ['escape']
        })
      }
      this.$buefy.modal.open({
        parent: this,
        props: {
          appointmentId
        },
        component: CreateEditAppointment,
        customClass: !this.isMobile() ? 'half-modal' : '',
        animation: !this.isMobile() ? 'slide-right' : 'zoom-out',
        hasModalCard: this.isMobile(),
        fullScreen: true,
        canCancel: ['escape', 'outside']
      })
    }
  }
}
</script>
