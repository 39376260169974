<template lang="pug">
  .progress-container.is-clickable(v-if="subscriptionIncomplete" @click="updatePaymentMethod")
    div.has-text-centered(style="margin:0 auto; padding: 0.4rem;")  
      p.title.is-5.has-text-danger
        span.icon
          i.mdi.mdi-alert
        span(v-t="'billing.payment_failure'")
      p.subtitle.is-6.has-text-danger(v-t="'billing.payment_failure_subtitle'")
  .progress-container.is-clickable(v-else-if="donePercentage !== 100" @click="onClick")
    div(style="max-width: 300px;margin:0 auto; padding: 0.4rem;")  
      p.heading.is-marginless.has-text-centered(v-t="'account_completeness.heading'")
      b-progress(:value="donePercentage" show-value type="is-dark" size="is-medium" format="percent" )
</template>

<script>
import {mapState, mapGetters} from 'vuex'

export default {
  name: 'AccountCompleteness',
  data() {
    return {
      task: undefined
    }
  },
  computed: {
    ...mapState('completeness', ['completeness']),
    ...mapGetters('completeness', ['topTask']),
    ...mapGetters('company', ['subscriptionIncomplete']),
    donePercentage() {
      return this.completeness === null ? 100 : this.completeness
    }
  },
  watch: {
    async topTask() {
      if (!this.topTask && this.task) {
        this.task.close()
        this.task = undefined
        return
      }
      if (this.topTask && this.task) {
        this.task.close()
        this.task = undefined
        return this.showSnackbar()
      }
      return this.showSnackbar()
    }
  },
  async created() {
    await this.$store.dispatch('completeness/fetch')
    this.showSnackbar()
  },
  methods: {
    taskClick() {
      if (this.topTask && this.$router.currentRoute.fullPath !== this.topTask.url) {
        this.$router.push(this.topTask.url)
      }
    },
    updatePaymentMethod() {
      this.$router.push('/settings/billing')
    },
    onClick() {
      if (this.$router.currentRoute.name !== 'Dashboard') {
        this.$router.push({name: 'Dashboard'})
      }
    },
    showSnackbar() {
      // if (this.topTask && !this.task) {
      //   this.task = this.$buefy.snackbar.open({
      //     message:
      //       '<b>' +
      //       this.$t(`completeness_tasks.${this.topTask._id}.title`) +
      //       '</b>' +
      //       '<br>' +
      //       this.$t(`completeness_tasks.${this.topTask._id}.description`),
      //     type: this.topTask.priority === 'high' ? 'is-warning' : 'is-info',
      //     position: 'is-bottom',
      //     queue: false,
      //     actionText: this.$t(`actions.lets_do`),
      //     indefinite: true,
      //     onAction: () => {
      //       return this.taskClick()
      //     }
      //   })
      // }
    }
  }
}
</script>

<style scoped>
.progress-container {
  padding-left: 0.5em;
  width: 100%;
}
</style>
