<template lang="pug">
  span(:class="classes") 
    b 
      stock-view(:amount="stockOnHand" :short="noText" :unit="unit")
</template>
<script>
export default {
  name: 'StockLabel',
  props: {
    stockOnHand: {type: Number, required: true},
    unit: {type: String, required: true},
    lowStockThreshold: {type: Number, default: undefined},

    tag: {type: Boolean, default: true},
    size: {type: String, default: undefined},
    noText: {type: Boolean, default: false}
  },
  computed: {
    classes() {
      if (!this.tag) {
        if (this.lowStockThreshold === undefined) {
          return
        }
        if (this.stockOnHand >= this.lowStockThreshold) {
          return ['has-text-success-dark']
        }
        if (this.stockOnHand < this.lowStockThreshold && this.stockOnHand > 0) {
          return ['has-text-warning-dark']
        }
        return ['has-text-danger-dark']
      }
      if (this.lowStockThreshold === undefined) {
        return ['tag', this.size]
      }
      if (this.stockOnHand >= this.lowStockThreshold) {
        return ['tag', 'is-success', this.size]
      }
      if (this.stockOnHand < this.lowStockThreshold && this.stockOnHand > 0) {
        return ['tag', 'is-warning', this.size]
      }
      return ['tag', 'is-danger', this.size]
    }
  }
}
</script>
