<template lang="pug">
  .modal-card
    header.modal-card-head
      p.modal-card-title
        b(v-t="'ledger.payout_title'")
      div
        a.button.is-white.is-small(@click="$emit('close')")
          span.icon
            i.mdi.mdi-close.mdi-24px
      
    section.modal-card-body
      label.label(v-t="'ledger.beneficiary_label'")
      p.title.is-4
        staff-view(:id="staff")
      b-field(:label="$t('ledger.payout_amount_label')" :type="errors.has('amount') ? 'is-danger': ''" :message="errors.has('amount') ? errors.first('amount') : ''")
        b-field
          p.control
            span.button.is-static 
              currency
          b-input(v-model.number="amount" v-validate="amountRules" expanded ref="amount" name="amount" :placeholder="$t('ledger.amount_placeholder')")
      note(type="is-warning") 
        p(v-t="'ledger.transaction_note'")
    footer.modal-card-foot
      .buttons
        action-button(@click="submit" title="ledger.actions.pay" :loading="loading")
        button.button.is-outlined(type="button" @click="$emit('close')" ) {{$t('actions.close')}}      
</template>

<script>
import {mapActions} from 'vuex'

export default {
  props: {
    staff: {type: String, required: true},
    due: {type: Number, required: true}
  },
  data() {
    return {
      amount: undefined,
      loading: false
    }
  },
  computed: {
    amountRules() {
      return `required|decimal:2|min_value:0|max_value:${this.due}`
    }
  },
  created() {
    this.amount = this.due
  },
  mounted() {
    this.$refs.amount.focus()
  },
  methods: {
    ...mapActions('ledger', ['registerPayout']),
    async submit() {
      let valid = await this.$validator.validateAll()
      if (!valid) {
        return
      }
      if (this.loading) {
        return
      }
      this.loading = true
      try {
        await this.registerPayout({amount: this.amount, beneficiary: this.staff})
        this.$emit('close')
      } catch (err) {
        this.$handleAPIError(err)
      }
      this.loading = false
    }
  }
}
</script>
