<template lang="pug">
  .screen-title.mb-5
    .is-flex-grow-1.title-container.is-hidden-mobile
      h3.title.is-4(v-t="title")
    .is-flex-shrink-0
      .buttons
        slot
</template>
<script>
export default {
  props: {
    title: {type: String, required: true}
  }
}
</script>
<style lang="scss" scoped>
.screen-title {
  display: flex;
  flex-direction: row;
  align-items: center;
}
// @media screen and (max-width: 768px) {
//   .screen-title {
//     flex-direction: column;
//     align-items: flex-start;
//     .title-container {
//       margin-bottom: 1rem;
//     }
//   }
// }
</style>
