<template lang="pug">
  generic-modal(title="customer_coupons.title" @close="$emit('close')")
    em-loading(:active="loading" :is-full-page="false")
    CustomerCouponsTable(v-model="customerCoupon" @input="onCouponSelect" :coupons="eligibleCoupons" :sticky-header="false")
</template>

<script>
import {mapActions} from 'vuex'
import CustomerCouponsTable from '../coupon/CustomerCouponsTable'
import SaleHelper from '../../../shared/saleHelper'

export default {
  name: 'SelectCoupon',
  components: {
    CustomerCouponsTable
  },
  props: {
    customerId: {type: String, required: true},
    saleItems: {type: Array, default: undefined}
  },
  data() {
    return {
      loading: false,
      availableCoupons: [],
      customerCoupon: undefined
    }
  },
  computed: {
    eligibleCoupons() {
      if (!this.availableCoupons.length) {
        return []
      }
      return this.availableCoupons.filter(customerCoupon => {
        return SaleHelper.isEligibleCoupon({coupon: customerCoupon.coupon, items: this.saleItems})
      })
    }
  },
  async created() {
    await this.fetchCoupons()
  },
  methods: {
    ...mapActions('customerCoupon', ['getCustomerCoupons']),
    async fetchCoupons() {
      this.loading = true
      try {
        this.availableCoupons = await this.getCustomerCoupons(this.customerId)
      } catch (err) {
        this.$handleAPIError(err)
      }
      this.loading = false
    },
    onCouponSelect(customerCoupon) {
      this.$emit('input', customerCoupon)
      this.$emit('close')
    }
  }
}
</script>
