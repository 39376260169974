<template lang="pug">
.modal-card(style="width: auto")
  header.modal-card-head
    p.modal-card-title(data-qa="modal-header") 
      b(v-t="'payment.details_title'")
    div
      a.button.is-white.is-small(@click="$emit('close')")
        span.icon
          i.mdi.mdi-close.mdi-24px
  section.modal-card-body
    em-loading(:active="loading" :is-full-page="false")
    div(v-if="payment")
      .list
        .list-item
          customer-preview(:customerId="payment.customer")
      template(v-if="hasActions") 
        h4.title.is-5.mb-3(v-t="'payment.actions_title'")
        .buttons
          button.button.is-danger.is-outline(@click="refund")
            span.icon
              i.mdi.mdi-refresh
            span {{ $t('payment.actions.refund') }}
          button.button.is-danger(@click="cancel")
            span.icon
              i.mdi.mdi-close
            span {{ $t('payment.actions.cancel') }}
          button.button.is-success(v-if="payment.appointment && !payment.invoice" @click="deposit")
            span.icon
              i.mdi.mdi-tag-check-outline
            span {{ $t('payment.actions.deposit') }}
        
      table.table.is-fullwidth.mt-3
        tr
          th(v-t="'payment.list_status_heading'")
          td 
            CardPaymentStatusIcon(:status="payment.status" is-tag) 
        tr 
          th(v-t="'payment.list_type_heading'")
          td
            span.tag.is-black(v-if="payment.appointment") {{ $t('payment.type_deposit') }}
            span.tag.is-primary(v-else)  {{ $t('payment.type_payment') }}
        tr(v-if="appointment")
          th(v-t="'payment.appointment_status'")
          td
            AppointmentStatusIcon(:status="appointment.status" show-text is-tag)
        tr(v-if="sale")
          th(v-t="'payment.sale_status'")
          td
            SaleStatusIcon(:status="sale.status" show-text is-tag)
        tr
          th(v-t="'payment.list_amount_heading'")
          td 
            currency-view(:value="payment.amount") 
        
        tr(v-if="payment.payoutAmount")
          th(v-t="'payment.list_actual_amount_heading'")
          td 
            currency-view(:value="payment.payoutAmount") 
        tr(v-if="payment.status === PAYMENT_STATUS.SUCCESS")
          th(v-t="'payment.payout_heading'")
          td 
            template(v-if="payment.payoutStatus === PAYOUT_STATUS.DUE")
              span.tag.is-info(v-t="`payout_status.${PAYOUT_STATUS.DUE}`")
              br
              date-view(:value="payment.payoutScheduled" format="LL LT")
            template(v-if="payment.payoutStatus === PAYOUT_STATUS.DONE")
              span.tag.is-success(v-t="`payout_status.${PAYOUT_STATUS.DONE}`")
              br
              date-view(:value="payment.payout" format="L LT")
            template(v-if="payment.payoutStatus === PAYOUT_STATUS.PENDING")
              span.tag.is-warning(v-t="`payout_status.${PAYOUT_STATUS.PENDING}`")
            template(v-if="payment.payoutStatus === PAYOUT_STATUS.FAILED")
              span.tag.is-warning(v-t="`payout_status.${PAYOUT_STATUS.FAILED}`")
        tr(v-if="payment.description")
          th(v-t="'payment.payment_error_heading'")
          td {{payment.description}}
        tr(v-if="payment.cardHolder")
          th(v-t="'payment.list_customer_heading'")
          td {{payment.cardHolder}}
        tr(v-if="payment.card")
          th(v-t="'payment.list_card_heading'")
          td •••• {{payment.card}}
        tr
          th(v-t="'payment.list_created_heading'")
          td 
            date-view(:value="payment.created" format="LL LT") 
        tr(v-if="payment.refundableUntil && payment.status === PAYMENT_STATUS.SUCCESS")
          th(v-t="'payment.list_refundable_heading'")
          td 
            date-view.tag.is-info(v-if="new Date(payment.refundableUntil) > new Date()" :value="payment.refundableUntil" format="L LT")
            date-view.tag.is-danger(v-else :value="payment.refundableUntil" format="L LT")
        tr(v-if="payment.refunded")
          th(v-t="'payment.list_refunded_date_heading'")
          td 
            date-view(:value="payment.refunded" format="LL LT") 
      p.title.is-6.mb-3(v-t="'payment.status_history_label'")
      .list
        .list-item(v-for="status in payment.statusHistory" :key="status._id")
          CardPaymentStatusIcon(:status="status.status" is-tag) 
          date-view.is-pulled-right(:value="status.ts" format="LL LT")
        
  footer.modal-card-foot 
    .buttons(v-if="payment")
      
      button.button( @click="$emit('close')" v-t="'actions.close'") 
    
</template>
<script>
import CardPaymentStatusIcon from './CardPaymentStatusIcon'
import AppointmentStatusIcon from '../calendar/AppointmentStatusIcon'
import SaleStatusIcon from '../sale/SaleStatusIcon'
import PAYMENT_STATUS from '../../../constants/cardPaymentStatus'
import PAYOUT_STATUS from '../../../constants/payoutStatus'
import {mapActions, mapState} from 'vuex'
import {formatCurrency} from '../../../shared/saleHelper'
export default {
  name: 'CardPaymentDetails',
  components: {
    CardPaymentStatusIcon,
    AppointmentStatusIcon,
    SaleStatusIcon
  },
  props: {
    paymentId: {type: String, required: true}
  },
  data() {
    return {
      payment: undefined,
      appointment: undefined,
      sale: undefined,
      loading: false,
      PAYMENT_STATUS,
      PAYOUT_STATUS
    }
  },
  computed: {
    ...mapState('company', ['company']),
    hasActions() {
      return (
        this.payment.status === PAYMENT_STATUS.SUCCESS &&
        !this.payment.invoice &&
        !this.payment.payoutStatus !== PAYOUT_STATUS.DONE
      )
    }
  },
  async created() {
    this.loading = true
    try {
      this.payment = await this.fetchPaymentById(this.paymentId)

      if (this.payment.appointment) {
        this.appointment = await this.fetchAppointmentById({id: this.payment.appointment})
      }

      if (this.payment.sale) {
        this.sale = await this.fetchSaleById({id: this.payment.sale})
      }

      if (this.payment.status === PAYMENT_STATUS.PENDING || this.payment.status === PAYMENT_STATUS.STATUS_CHECK) {
        await this.refreshPaymentStatus(this.paymentId)
      }
    } catch (err) {
      this.$handleAPIError(err)
    }
    this.loading = false
  },
  methods: {
    ...mapActions('payments', ['fetchPaymentById', 'refundPayment', 'cancelPayment', 'refreshPaymentStatus']),
    ...mapActions('sales', ['depositDeposit', 'fetchSaleById']),
    ...mapActions('appointments', ['fetchAppointmentById']),
    cancel() {
      let formattedCurrency = formatCurrency(this.payment.amount, this.company.currency)
      this.$buefy.dialog.confirm({
        title: this.$t('payment.cancel_prompt.title'),
        message: this.$t('payment.cancel_prompt.body', {name: this.payment.cardHolder, amount: formattedCurrency}),
        cancelText: this.$t('actions.cancel'),
        confirmText: this.$t('actions.confirm'),
        type: 'is-black',
        onConfirm: async () => {
          this.loading = true
          try {
            await this.cancelPayment(this.payment._id)
          } catch (error) {
            this.$handleAPIError(error)
          }
          this.loading = false
        }
      })
    },
    deposit() {
      this.$buefy.dialog.confirm({
        title: this.$t('payment.deposit_prompt.title'),
        message: this.$t('payment.deposit_prompt.body', {
          name: this.payment.cardHolder,
          amount: this.formattedCurrency
        }),
        cancelText: this.$t('actions.cancel'),
        confirmText: this.$t('actions.confirm'),
        type: 'is-black',
        onConfirm: async () => {
          this.loading = true
          try {
            await this.depositDeposit(this.payment._id)
          } catch (error) {
            this.$handleAPIError(error)
          }
          this.loading = false
        }
      })
    },
    refund() {
      let formattedCurrency = formatCurrency(this.payment.amount, this.company.currency)
      this.$buefy.dialog.confirm({
        title: this.$t('payment.refund_prompt.title'),
        message: this.$t('payment.refund_prompt.body', {name: this.payment.cardHolder, amount: formattedCurrency}),
        cancelText: this.$t('actions.cancel'),
        confirmText: this.$t('actions.confirm'),
        type: 'is-black',
        onConfirm: async () => {
          this.loading = true
          try {
            await this.refundPayment(this.payment._id)
          } catch (error) {
            this.$handleAPIError(error)
          }
          this.loading = false
        }
      })
    }
  }
}
</script>
