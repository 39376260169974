<template lang="pug">
  .modal-card
    header.modal-card-head
      p.modal-card-title(data-qa="modal-header") 
        b(v-t="'tax_settings.location_defaults.set_defaults_title'")
        br
        span.has-text-grey.is-size-6 {{location.title}}
      div
        a.button.is-white.is-small(@click="$emit('close')")
          span.icon
            i.mdi.mdi-close.mdi-24px
    section.modal-card-body
      b-field(:label="$t('tax_settings.location_defaults.service_header')")
        TaxSelector(v-model="services" expanded)
      b-field(:label="$t('tax_settings.location_defaults.product_header')")
        TaxSelector(v-model="products" expanded)
        
    footer.modal-card-foot
      .buttons
        action-button(title="actions.save" @click="submit" :loading="submitting" data-qa="action-save")
        button.button(@click="$emit('close')" v-t="'actions.cancel'")  
    
</template>

<script>
import {mapActions} from 'vuex'
import TaxSelector from './TaxSelector'
export default {
  name: 'LocationTaxDefaultModal',
  components: {
    TaxSelector
  },
  props: {
    location: {type: Object, required: true}
  },
  data() {
    return {
      submitting: false,
      services: undefined,
      products: undefined
    }
  },
  async created() {
    this.services = (this.location.taxDefaults && this.location.taxDefaults.services) || ''
    this.products = (this.location.taxDefaults && this.location.taxDefaults.products) || ''
  },

  methods: {
    ...mapActions('location', ['updateTaxDefaults']),
    async submit() {
      const valid = await this.$validator.validateAll()
      if (this.submitting || !valid) {
        return
      }
      this.submitting = true
      let data = {
        services: this.services || undefined,
        products: this.products || undefined
      }
      try {
        await this.updateTaxDefaults({id: this.location._id, data})
        this.$buefy.toast.open({message: this.$t('status.save_success'), type: 'is-success', queue: false})
        this.$emit('close')
      } catch (error) {
        this.$handleAPIError(error)
      }
      this.submitting = false
    },
    cancel() {
      this.$emit('close')
    }
  }
}
</script>
