<template lang="pug">
.modal-card
  header.modal-card-head
    p.modal-card-title 
      b {{$t('resources_settings.schedule_edit.title')}}
    
  section.modal-card-body
    
    .notification.p-5
      form(v-on:submit.prevent="submit")
        
        p.is-size-5.mb-4(v-html="$t('resources_settings.schedule_edit.recurring_title', {weekday: formattedDay})") 
        b-field
          b-radio(v-model="workDay" :native-value="true" @input="onWorkDayUpdate") {{$t('resources_settings.schedule_edit.working_day')}}
          b-radio(v-model="workDay" :native-value="false" @input="onWorkDayUpdate") {{$t('resources_settings.schedule_edit.non_working_day')}}
        template(v-if="workDay")
          label.label {{$t('resources_settings.schedule_edit.schedule_label')}}
          ShiftEditor(v-model="schedule")
    .notification.p-5
      template(v-if="customSchedule")
        p.is-size-5.mb-4(v-html="$t('resources_settings.schedule_edit.specific_title', {day: formattedDate})")
        
        b-field
          b-radio(v-model="customWorkDay" :native-value="true") {{$t('resources_settings.schedule_edit.working_day')}}
          b-radio(v-model="customWorkDay" :native-value="false") {{$t('resources_settings.schedule_edit.non_working_day')}}
        
        template(v-if="customWorkDay")
          label.label {{$t('resources_settings.schedule_edit.schedule_label')}}
          ShiftEditor(v-model="customSchedule")
        hr.is-narrow
        .buttons
          button.button.is-outlined.is-danger.is-rounded(@click="removeCustomShifts") 
            span.icon
              i.mdi.mdi-calendar-alert
            span {{ $t('resources_settings.schedule_edit.actions.remove_exception') }}            
      template(v-else)
        p.is-size-5.mb-4(v-html="$t('resources_settings.schedule_edit.no_specific_title', {day: formattedDate})")
        
        .buttons
          button.button.is-outlined.is-success.is-rounded(@click="setupCustomShifts") 
            span.icon
              i.mdi.mdi-calendar-alert
            span {{ $t('resources_settings.schedule_edit.actions.create_exception') }}
      
  footer.modal-card-foot
    .buttons
      action-button(title="actions.save"  @click="submit") 
      button.button(type="button" @click="cancel" v-t="'actions.close'")
</template>

<script>
import dayjs from 'dayjs'

import ShiftEditor from '../shared/ShiftEditor'
export default {
  name: 'EditResourceSchedule',
  components: {
    ShiftEditor
  },
  props: {
    value: {type: Object, required: true},
    suggestedStart: {type: Number, default: 9 * 60},
    suggestedEnd: {type: Number, default: 18 * 60},
    weekday: {type: String, required: true},
    date: {type: Date, required: true}
  },
  data() {
    return {
      schedule: [],
      workDay: true,
      customWorkDay: true,
      customSchedule: undefined,
      custom: false
    }
  },
  computed: {
    formattedDay() {
      return dayjs(this.date).format('dddd')
    },
    formattedDate() {
      return dayjs(this.date).format('dddd, D MMMM')
    }
  },
  created() {
    this.init()
  },
  methods: {
    init() {
      this.schedule = this.value.schedule.map(schedule => {
        return {start: schedule.start, end: schedule.end}
      })
      this.workDay = !!this.schedule.length

      this.customSchedule =
        this.value.customSchedule &&
        this.value.customSchedule.map(schedule => {
          return {start: schedule.start, end: schedule.end}
        })
      this.customWorkDay = !!(this.customSchedule && this.customSchedule.length)
    },
    setupCustomShifts() {
      this.customSchedule = [{start: this.suggestedStart, end: this.suggestedEnd}]
      this.customWorkDay = true
    },
    removeCustomShifts() {
      this.customSchedule = undefined
    },
    async submit() {
      this.$emit('input', {
        schedule: this.workDay ? this.schedule : [],
        customSchedule: this.customSchedule ? (this.customWorkDay ? this.customSchedule : []) : undefined
      })
      this.$emit('close')
    },
    cancel() {
      this.$emit('close')
    },
    onWorkDayUpdate() {
      if (this.workDay && !this.schedule.length) {
        this.schedule.push({start: this.suggestedStart, end: this.suggestedEnd})
      }
    }
  }
}
</script>
