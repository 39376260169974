<template lang="pug">
  .modal-card
    header.modal-card-head
      p.modal-card-title(data-qa="modal-header") 
        b(v-if="!taxGroupId" v-t="'tax_settings.new_tax_group_modal_header'")
        b(v-else v-t="'tax_settings.edit_tax_group_modal_header'")
      div
        a.button.is-white.is-small(@click="$emit('close')")
          span.icon
            i.mdi.mdi-close.mdi-24px
    section.modal-card-body
      b-field(:label="$t('tax_settings.add_tax.group_name_label')" :type="errors.has('name') ? 'is-danger': ''" :message="errors.has('name') ? errors.first('name') : ''" )
        b-input(v-model="name" name='name' :placeholder="$t('tax_settings.tax_group_name_placeholder')" v-validate="'required'")
      
      label.label(v-t="'tax_settings.add_tax.group_items_label'")
      b-checkbox(v-model="items" v-for="tax in activeTaxes" :native-value="tax._id" :key="tax._id") 
        tax-view(:value="tax._id")
      
    footer.modal-card-foot
      .buttons
        action-button(title="actions.confirm" @click="submit" :loading="submitting" :disabled="!items || !items.length" data-qa="action-confirm")
        button.button(@click="$emit('close')" v-t="'actions.cancel'")  
    
</template>

<script>
import {mapActions, mapGetters} from 'vuex'
export default {
  name: 'TaxCreateEditModal',
  props: {
    taxGroupId: {type: String, default: undefined}
  },
  data() {
    return {
      submitting: false,
      name: undefined,
      items: []
    }
  },
  computed: {
    ...mapGetters('tax', ['activeTaxes'])
  },
  async created() {
    if (this.taxGroupId) {
      let tax = await this.getById(this.taxGroupId)
      this.name = tax.name
      this.items = tax.items.slice()
    }
  },

  methods: {
    ...mapActions('tax', ['updateTaxGroup', 'createTaxGroup', 'getById']),
    async submit() {
      const valid = await this.$validator.validateAll()
      if (this.submitting || !valid) {
        return
      }
      this.submitting = true
      let data = {
        name: this.name,
        items: this.items
      }
      try {
        if (this.taxGroupId) {
          await this.updateTaxGroup({id: this.taxGroupId, data})
        } else {
          await this.createTaxGroup(data)
        }

        this.$buefy.toast.open({message: this.$t('status.save_success'), type: 'is-success', queue: false})
        this.$emit('close')
      } catch (error) {
        this.$handleAPIError(error)
      }
      this.submitting = false
    },
    cancel() {
      this.$emit('close')
    }
  }
}
</script>
