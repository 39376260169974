<template lang="pug">
  div(v-if="editable || (buttons && buttons.length)")
    .msg-button(v-for='(button, index) in buttons' :key="index" :class="!button.title ? 'is-invalid' : ''")
      ContentEditable(v-model="button.title" @input="update" :editable="editable || titleEditable")
    .msg-button.msg-button-add(v-if="editable && (!buttons || buttons.length < 3)" @click="addNewButton")
      div.has-text-centered + add a button
</template>
<script>
import ContentEditable from '../ContentEditable'
import SelectButtonTypeModal from '../SelectButtonTypeModal'
export default {
  components: {
    ContentEditable
  },
  props: {
    value: {type: Array, default: () => []},
    editable: {type: Boolean, default: false},
    titleEditable: {type: Boolean, default: false}
  },
  data() {
    return {
      buttons: []
    }
  },
  watch: {
    value(val) {
      this.buttons = val || []
    }
  },
  created() {
    this.buttons = this.value || []
  },
  methods: {
    update() {
      this.$emit('input', this.buttons)
    },
    addNewButton() {
      this.$buefy.modal.open({
        parent: this,
        component: SelectButtonTypeModal,
        canCancel: ['outside', 'escape'],
        width: 960,
        events: {
          selected: button => {
            this.buttons.push(button)
            this.update()
          }
        }
      })
    }
  }
}
</script>
