const dayjs = require('dayjs')
class CustomerHelper {
  calculateChurnProb(customer, company) {
    if (!customer.lastVisit) {
      return 0
    }
    if (customer.nextVisit && customer.nextVisit > new Date()) {
      return 0
    }
    let stats = customer.stats || {}
    let tbp = stats.tbp || company.avgTBP
    if (
      dayjs(customer.lastVisit)
        .add(tbp, 'days')
        .isAfter(dayjs())
    ) {
      return 0
    }

    const conf = stats.tbp_confidence || 0.7

    return conf * (1 - tbp / Math.pow(dayjs(customer.lastVisit).diff(new Date(), 'days'), 2))
  }
}

module.exports = new CustomerHelper()
