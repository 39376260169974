<template lang="pug">
.brand-bordered.is-primary.is-clickable(v-if="form" @click="onClick" :class="large? 'p-4': 'p-2'") 
  .is-flex.is-align-items-center(v-if="large") 
    div.is-flex-shrink-0.mr-3
      span.icon.has-text-primary.is-large
        i.mdi.mdi-list-box-outline.mdi-36px
    div.is-flex-grow-1
      p.heading() {{ form.name }}
      p.title.is-6 {{ form.title }}  
  .is-ellipsis(style="max-width: 120px" v-else)
    span.icon.has-text-primary
      i.mdi.mdi-list-box-outline
    span {{ form.name }}
</template>
<script>
import {mapActions} from 'vuex'
export default {
  name: 'FormView',
  props: {
    formId: {type: String, required: true},
    large: {type: Boolean, default: false}
  },
  data() {
    return {
      form: undefined
    }
  },
  async created() {
    this.form = await this.getFormById(this.formId)
  },
  methods: {
    ...mapActions('forms', ['getFormById']),
    onClick() {
      this.$emit('click', this.formId)
    }
  }
}
</script>
