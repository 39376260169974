<template lang="pug">
.modal-card
  header.modal-card-head
    p.modal-card-title
      b(v-t="'vouchers.create_voucher_title'")
    div
      a.button.is-white.is-small(@click="$emit('close')")
        span.icon
          i.mdi.mdi-close.mdi-24px
  section.modal-card-body
    //select-box(v-model="type" :data="types")
    b-field(:label="$t('vouchers.name_label')" :type="errors.has('name') ? 'is-danger': ''" :message="errors.has('name') ? errors.first('name') : ''" expanded )
      b-input(:placeholder="$t('vouchers.name_placeholder')" ref="nameinput" v-model=" name" name="name" data-vv-as="name" expanded v-validate="'required'")
    b-field(:label="$t('vouchers.custom_code_label')" :type="errors.has('code') ? 'is-danger': ''" :message="errors.has('code') ? errors.first('code') : ''" expanded )
      b-input(:placeholder="$t('vouchers.custom_code_placeholder')"  v-model="customCode" name="code" data-vv-as="custom code" expanded v-validate="customCodeRules")

    b-field(grouped)
      b-field(:label="$t('vouchers.value_label')" :type="errors.has('value') ? 'is-danger': ''" :message="errors.has('value') ? errors.first('value') : ''" expanded )
        b-field
          p.control
            span.button.is-static 
              currency
          b-input(:placeholder="$t('vouchers.value_placeholder')" v-model.number="value" @input="onValueInput" name="value" v-validate="'required|decimal:2|min_value:0'" data-vv-as="value" expanded)
      b-field(:label="$t('vouchers.price_label')" :type="errors.has('price') ? 'is-danger': ''" :message="errors.has('price') ? errors.first('price') : ''" expanded )
        b-field
          p.control
            span.button.is-static 
              currency
          b-input(:placeholder="$t('vouchers.price_placeholder')" v-model.number="price" name="price" v-validate="'required|decimal:2|min_value:0'" data-vv-as="price" expanded)
    
    b-field(:label="$t('vouchers.eligible_label')")
      b-radio(v-model="specificServices" name="specificServices" @input="onEligibilityChange" :native-value="false") {{$tc('vouchers.eligible_all')}}
      b-radio(v-model="specificServices" name="specificServices" :native-value="true") {{$t('vouchers.eligible_specific')}}
    template(v-if="specificServices")
      label.label {{$tc('vouchers.number_of_services',  servicesList.length)}} 
      .mb-3(v-for="(item, idx) in servicesList" style="position: relative;padding-right: 3rem;")
        button.delete(@click="removeService(item)" v-if="servicesList.length > 1")              
        ServiceAutocomplete(v-model="item.service" :autocomplete="false" expanded)

      .field
        button.button.is-success(@click="servicesList.push({service: {}})" type="button") {{$t('vouchers.actions.add_service')}}
    b-field(:message="$t('vouchers.expiration_description')")
      b-field(:label="$t('vouchers.valid_label')" grouped group-multiline)
        b-field( :type="errors.has('validity') ? 'is-danger': ''" :message="errors.has('validity') ? errors.first('validity') : ''")
          b-input(v-model="validity"  v-validate="'required|numeric'" name="validity" :placeholder="$t('vouchers.valid_placeholder')")
        b-field( :type="errors.has('validityUnit') ? 'is-danger': ''" :message="errors.has('validityUnit') ? errors.first('validityUnit') : ''")
          b-select(v-model="validityUnit" v-validate="'required'" name="validityUnit" data-vv-as="unit" )
            option(v-for="value in validityUnits" :value="value") {{$t(`misc.${value}`)}}
    b-field(:label="$t('vouchers.notes_label')")
      b-input(v-model="notes" type="textarea" :placeholder="$t('vouchers.notes_placeholder')")
  footer.modal-card-foot
    .buttons
      action-button(title="actions.create" @click="submit" :loading="loading")
      button.button.is-outlined(type="button" @click="$emit('close')" ) {{$t('actions.close')}}      
</template>
<script>
import ServiceAutocomplete from '../services/ServiceAutocomplete'

export default {
  name: 'CreateVoucher',
  components: {
    ServiceAutocomplete
  },
  data() {
    return {
      loading: false,
      code: undefined,
      name: undefined,
      value: undefined,
      price: undefined,
      validity: 3,
      notes: undefined,
      specificServices: false,
      validityUnit: 'months',
      validityUnits: ['days', 'weeks', 'months'],
      servicesList: [{service: {}}]
    }
  },
  computed: {
    customCode: {
      get() {
        return this.code
      },
      set(val) {
        this.code = val
          .trim()
          .toUpperCase()
          .replace(/\s/g, '')
      }
    },
    customCodeRules() {
      return 'alpha_num|voucherCodeAvailable'
    }
  },
  created() {
    this.name = this.$t('vouchers.name_initial_value')
  },
  mounted() {
    this.$refs.nameinput.focus()
  },
  methods: {
    removeService(item) {
      let idx = this.servicesList.indexOf(item)
      this.servicesList.splice(idx, 1)
    },
    onValueInput() {
      this.price = this.value
    },
    async submit() {
      this.loading = true
      const valid = await this.$validator.validateAll()
      if (!valid) {
        this.loading = false
        return
      }
      this.$emit('input', {
        name: this.name,
        code: this.code,
        value: this.value,
        price: this.price,
        notes: this.notes,
        services: this.servicesList
          .filter(s => s.service && s.service.serviceId)
          .map(s => {
            return {service: s.service.serviceId, option: s.service.optionId}
          }),
        validity: this.validity,
        validityUnit: this.validityUnit
      })

      this.$emit('close')
      this.loading = false
    },
    onEligibilityChange() {
      if (!this.specificServices) {
        this.servicesList = [{service: {}}]
      }
    }
  }
}
</script>
