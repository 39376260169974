import Navbar from '@/components/Navbar'

import Container from '@/components/settings/Container'
import Menu from '@/components/settings/Menu'
import BusinessSettings from '@/components/settings/BusinessSettings'
import LanguagesSettings from '@/components/settings/LanguagesSettings'
import OnlineBookingSettings from '@/components/settings/OnlineBookingSettings'
import TaxSettings from '@/components/taxes/TaxSettings'
import PaymentTypes from '@/components/pos/paymentTypes/PaymentTypes'
import CashRegisterSettings from '@/components/ledger/CashRegisterSettings'

import BillingSettings from '@/components/settings/BillingSettings'
import Platforms from '@/components/settings/Platforms'
import CRMSettings from '@/components/settings/CRMSettings'
import Notifications from '@/components/settings/Notifications'
import Calendar from '@/components/settings/Calendar'

import APIAccess from '@/components/settings/APIAccess'

//const ChatbotDashboard = () => import(/* webpackChunkName: "chatbot" */ '@/components/chatbot/Dashboard')
const ChatbotSettings = () => import(/* webpackChunkName: "chatbot" */ '@/components/chatbot/Settings')

export default [
  {
    path: '/settings',
    name: 'Settings',
    meta: {
      title: 'navbar.settings'
    },
    components: {
      default: Container,
      navbar: Navbar,
      menu: Menu
    },
    children: [
      {
        path: 'business',
        component: BusinessSettings,
        meta: {
          requiresAuth: true,
          title: 'navbar.settings',
          pageTitle: 'business_settings.title'
        }
      },
      {
        path: 'languages',
        component: LanguagesSettings,
        meta: {
          requiresAuth: true,
          title: 'navbar.settings',
          pageTitle: 'languages_settings.title'
        }
      },
      {
        path: 'online',
        component: OnlineBookingSettings,
        meta: {
          requiresAuth: true,
          title: 'navbar.settings',
          pageTitle: 'online_booking_settings.title'
        }
      },
      {
        path: 'taxes',
        component: TaxSettings,
        meta: {
          requiresAuth: true,
          title: 'navbar.settings',
          pageTitle: 'tax_settings.title'
        }
      },
      {
        path: 'paymentTypes',
        component: PaymentTypes,
        meta: {
          requiresAuth: true,
          title: 'navbar.settings',
          pageTitle: 'payment_types_settings.title'
        }
      },
      {
        path: 'cashregister',
        component: CashRegisterSettings,
        meta: {
          requiresAuth: true,
          title: 'navbar.settings',
          pageTitle: 'ledger_settings.title'
        }
      },
      {
        path: 'platforms',
        component: Platforms,
        meta: {
          requiresAuth: true,
          title: 'navbar.settings',
          pageTitle: 'platforms_settings.title'
        }
      },
      {
        path: 'billing',
        component: BillingSettings,
        meta: {
          requiresAuth: true,
          title: 'navbar.settings',
          pageTitle: 'billing.title'
        }
      },
      {
        path: 'crm',
        component: CRMSettings,
        meta: {
          requiresAuth: true,
          title: 'navbar.settings',
          pageTitle: 'crm_settings.customer_data_title'
        }
      },
      {
        path: 'notifications',
        component: Notifications,
        meta: {
          requiresAuth: true,
          title: 'navbar.settings',
          pageTitle: 'client_notifications.title'
        }
      },
      {
        path: 'calendar',
        component: Calendar,
        meta: {
          requiresAuth: true,
          title: 'navbar.settings',
          pageTitle: 'calendar_settings.title'
        }
      },

      {
        path: 'api',
        component: APIAccess,
        meta: {
          requiresAuth: true,
          title: 'navbar.settings',
          pageTitle: 'api_access.title'
        }
      },
      // {
      //   path: 'chatbot',
      //   component: ChatbotDashboard,
      //   meta: {
      //     requiresAuth: true,
      //     title: 'navbar.settings',
      //     pageTitle: 'navbar.chatbot'
      //   }
      // },
      {
        path: 'chatbot/settings',
        component: ChatbotSettings,
        meta: {
          requiresAuth: true,
          title: 'navbar.settings'
        }
      }
    ]
  }
]
