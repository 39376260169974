module.exports = {
  CARD: 'card',
  CASH: 'cash',
  POINTS: 'points',
  VOUCHER: 'voucher',
  DEPOSIT: 'deposit',
  PROCESSING: 'processing',
  LINK_PAY: 'link_pay',
  WIRE: 'wire'
}
